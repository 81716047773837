import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation } from 'react-router-dom';
// import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { listWorkstationResults, applyWorkstationActions, getCatalogFilters, disableLifecyclePolicy } from '../../../actions/CatalogAction'
import { convertBytes, convertSeconds, convertBits, countFormater, getCommonInformationMessageFilter, getPaginationShowingDetails } from '../../../utils/utility'
import windows from '../../../assets/images/windows-pngwing.png'
import linux from '../../../assets/images/linux-pngwing.png'
import aws from '../../../assets/images/aws-black-pngwing.png'
import gcp from '../../../assets/images/gcp-pngwing.png'
// import ubuntu from '../../../assets/images/ubuntu-pngwing.png'
// import * as ServiceController  from "windows-service-controller";

import ApexListBarChart from '../../common/charts/listCharts/ApexListBarChart'
import ApexListLineChart from '../../common/charts/listCharts/ApexListLineChart'

import WorkstationSidePanel from './WorkstationSidePanel'
import { Store as CommonNotification } from 'react-notifications-component';

// import ConfirmationScreen from '../../common/ConfirmationScreen';
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';
import AlertConfirmation from '../../common/AlertConfirmation';
let searchWorstation = null

const Workstation = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        workstationResponse: {},
        currentPage: 1,
        perPage: 5,
        catalogTypes: [],
        selectedAction: ''
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].workstation_action
                    }
				} else if(row.workstation_action) {
                    actionsAllowed = row.workstation_action
                }
			})

            let actionOptions = []

            if(actionsAllowed.includes('start')) { actionOptions.push('start') }
            if(actionsAllowed.includes('stop')) { actionOptions.push('stop') }
            if(actionsAllowed.includes('modify')) { actionOptions.push('modify') }
            if(actionsAllowed.includes('terminate')) { actionOptions.push('terminate') }
            if(actionsAllowed.includes('hibernate')) { actionOptions.push('hibernate') }

            setState(prevState => ({ ...prevState, actionsAllowed, actionOptions }))
		}
    }, [userMenu, currentPath])

    const handleCatalogTypeChange = (value) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], catalogType: value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.resetFilter) {
            setState(prevState => ({ ...prevState, resetFilter: false }));
        }
    }, [state.resetFilter]);

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false, showLoading: true }))
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }

            if(state.token === 'next') {
                params.next_token = state.workstationResponse && state.workstationResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.workstationResponse && state.workstationResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.workstationResponse && state.workstationResponse.current_token
            }

            params.size = state.perPage
    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let results = response && response.results ? response.results : []

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, workstationResponse: response, workstationList: results, filterdWorkStationList: results, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, state.workstationList, props, state.perPage, state.catalogType, state.searchText, state.token, state.workstationResponse])

    const getGrapUnitValue = useCallback((val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                if(valueType === "round") {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
                } else {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                }
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit, valueType)
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {          
            if(valueType === "round") { 
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
            } else {
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            }
        }
        return val
    }, [])

    useEffect(() => {
        if(state.callFormGraph) {
            let graphDetails = state.filterdWorkStationList ? state.filterdWorkStationList : []
            if(graphDetails && graphDetails.length) {
                graphDetails.forEach(row => {
                    if(row.metrics && Object.entries(row.metrics).length) {
                        let graph = row.metrics
                        let graphData = graph.data
                        let graphLabels = graph.dates                

                        let cpuRow = {}
                        let cpuSum = 0

                        let memoryRow = {}
                        let memorySum = 0
                        
                        let diskRow = {}
                        let diskSum = 0

                        let networkRow = []
                        let networkAvg = []

                        Object.entries(graphData).forEach(([key, item]) => {                    
                            let avgData = {}
                            if(item.statistics) {
                                if(item.statistics.avg) {
                                    if(item.unit) {
                                        // avgData.avg = item.statistics.avg 
                                        avgData.avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                    } else {
                                        avgData.avg = item.statistics.avg
                                    }
                                }
                                if(item.statistics.max) {
                                    if(item.unit) {
                                        avgData.max = getGrapUnitValue(item.statistics.max, item.unit, "round")
                                    } else {
                                        avgData.max = item.statistics.max
                                    }
                                }
                            }
                            if(item.category === "CPU") {
                                let data = []                        
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        cpuSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                cpuRow.data = data
                                cpuRow.labels = graphLabels
                                
                                setState(prevState => ({ ...prevState, ['statistics_cpu_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Memory") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        memorySum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                memoryRow.data = data
                                memoryRow.labels = graphLabels
                                setState(prevState => ({ ...prevState, ['statistics_memory_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Disk") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        diskSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                diskRow.data = data
                                diskRow.labels = graphLabels

                                setState(prevState => ({ ...prevState, ['statistics_disk_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Network") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                let newData = networkRow && networkRow.data ? networkRow.data : {}
                                newData[key] = data
                                
                                networkRow.data = newData
                                networkRow.labels = graphLabels
        
                                if(item.statistics) {
                                    if(item.statistics.avg) {
                                        let avg = 0
                                        if(item.unit) {
                                            avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                        } else {
                                            avg = item.statistics.avg
                                        }
                                        networkAvg.push(avg)
                                    }
                                }
        
                                setState(prevState => ({ ...prevState, ['statistics_network_'+row.workstation_id]: networkAvg }))
                            }
                        })
                        if(cpuSum > 0 && cpuRow && Object.entries(cpuRow).length) {
                            setState(prevState =>  ({ ...prevState, ['cpu_'+row.workstation_id]: cpuRow }))
                        }
                        if(memorySum > 0 && memoryRow && Object.entries(memoryRow).length) {
                            setState(prevState =>  ({ ...prevState, ['memory_'+row.workstation_id]: memoryRow }))
                        } 
                        if(diskSum > 0 && diskRow && Object.entries(diskRow).length) {
                            setState(prevState =>  ({ ...prevState,['disk_'+row.workstation_id]: diskRow }))
                        }
                        if(networkRow && Object.entries(networkRow).length) {
                            setState(prevState =>  ({ ...prevState,['network_'+row.workstation_id]: networkRow }))
                        }
                    }
                })
            }

        }
    }, [state.callFormGraph, state.filterdWorkStationList, getGrapUnitValue])

    const onChangeAction = (row, action) => {
        let obj = {
            message: 'Are you sure you want to '+action+' the workstation' +(row.workstation_name ? ' "'+row.workstation_name+'"' : ''),
            buttonLabel: "Yes, I'm sure",
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, selectedAction: action, actionWorksatationDetails: row, confirmAction: true }))

        // if(option === 'stop' || option === 'start' || option === 'terminate') {
        //     setState(prevState => ({ ...prevState, terminateModal: true, selectedAction: option, selectedTerminateDetails: row }))
        // } else if(option === "details") {
        //     setState(prevState => ({ ...prevState, selectedDetails: row }))
        // } else {
        //     setState(prevState => ({ ...prevState, selectedDetails: row, selectedAction: option }))
        // }
    }

    // const onChangeActionNew = () => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row, showConfirmationScreen: true }))
    // }

    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row }))
    // }, [state.updateSelectedAction])

    const deleteWorkstationFunction = () => {
        let params = {}
        params.workstation_id = state.actionWorksatationDetails && state.actionWorksatationDetails.workstation_id
        params.action = 'unsubscribe'
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState,  showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false, workstationResponse: {}, token: '', workstationList: [], filterdWorkStationList: [], callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const onApplyAction = () => {
        let params = {}
        params.workstation_id = state.actionWorksatationDetails && state.actionWorksatationDetails.workstation_id
        if(state.selectedAction === 'delete') {
            params.action = 'unsubscribe'
        } else {
            params.action = state.selectedAction
        }
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState, showTerminateLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, terminateModal: false, showTerminateLoading: false, callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const callDisableFuction = (row, disabled_policy) => {

		let params = {
			disable: disabled_policy ? true : false,			
			workstation_id: row && row.workstation_id
		}
		
		dispatch(disableLifecyclePolicy(params))
			.then((response) => {
				let messageType = ""
				if(response.status) {
					messageType = 'success'
					// setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
				} else {
					messageType = 'danger'
				}
				setState(prevState => ({ ...prevState, submitLoading: false }))
	
				let message = response && response.message ? response.message : 'Error in disabling policy '
	
				CommonNotification.addNotification({
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					}
				});
			})
	}

    const getStatusColor = (status) => {     
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'hibernated') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'approved') { color = 'bg-green-50 border-2 border-green-600 text-green-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } 
        return color;
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <LoadingCircle />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", token: null, callListWorkstationResults: string === 'refresh' ? true : false, showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='p-2' onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='flex justify-between pb-8'>
                    <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                        Showing <span className='mx-1'>{getPaginationShowingDetails(state.filterdWorkStationList && state.filterdWorkStationList.length, state.totalRecords, state.currentPage, state.perPage)}</span> out of total {state.totalRecords} workstation(s)
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <span className='ml-1'>obtained from the</span>
                            <span className='ml-1'>{state.selectedFilters.provider.toUpperCase()}</span>
                            <span className='ml-1'>provider</span>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <span className='ml-1'>with the account</span>
                            <span className='ml-1'>{state.selectedFilters.account_id[0]}</span>
                            {state.selectedFilters.account_id.length > 1 ?
                                <span className='ml-2 text-slateBlue-600'>more</span>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <span className='ml-1'>in the</span>
                            <span className='ml-1'>{state.selectedFilters.region[0]}&nbsp;</span>
                            {state.selectedFilters.region.length > 1 ?
                                <span className='ml-2  text-slateBlue-600'>more</span>
                            : null}
                            <span className='ml-1'>region</span>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex justify-end lg:w-1/2 w-full'>
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={state.catalogTypes}
                            selectedValues={state.catalogType ? state.catalogType : ''}
                            callbackMultiSelect={(value) => {
                                handleCatalogTypeChange(value)
                            }}
                            singleSelection={true}
                            classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                        />
                        <div className='ml-4 justify-end self-center'>
                            {/* <div className='flex py-2.5 pr-4 pl-3 bg-whtie rounded-full text-black'>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`self-center`} width={25} height={25} />
                                    <input
                                        value={state.searchText}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                            clearTimeout(searchWorstation)
                                            if(e.target.value.length > 2) {
                                                searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, workstationResponse: {}, workstationList: [], showLoading: true, callListWorkstationResults: true, currentPage: 1 }))}, 1000); 
                                            } else if(!e.target.value.length) {
                                                setState(prevState => ({ ...prevState, workstationResponse: {}, workstationList: [], showLoading: true, callListWorkstationResults: true, currentPage: 1 }))
                                            }
                                        }}
                                        type='text'
                                        className={`text-md w-full`}
                                        placeholder='Search'
                                    />
                            </div> */}
                            <div class="flex w-[394px] p-2.5 pl-3 pr-4 items-start border border-NeutralGray-600 bg-white text-lg rounded-full">
                                <span class="self-center ml-2">
                                    <Icon icon="bitcoin-icons:search-outline" className="text-gray-500" width={24} height={24} />
                                </span>
                                <input
                                    type="text"
                                    placeholder='Search'
                                    className="flex-1 bg-transparent outline-none self-center"
                                    value={state.searchText}
                                    onChange={e => {
                                        setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                        clearTimeout(searchWorstation)
                                        if(e.target.value.length > 2) {
                                            searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, workstationResponse: {}, workstationList: [], showLoading: true, callListWorkstationResults: true, currentPage: 1 }))}, 1000); 
                                        } else if(!e.target.value.length) {
                                            setState(prevState => ({ ...prevState, workstationResponse: {}, workstationList: [], showLoading: true, callListWorkstationResults: true, currentPage: 1 }))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                    state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full py-2'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.workstationResponse && state.workstationResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.workstationResponse && state.workstationResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callListWorkstationResults: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 ${state.workstationResponse && state.workstationResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.workstationResponse && state.workstationResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callListWorkstationResults: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null
                : null}
                {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                    state.filterdWorkStationList.map((row, index) => {
                        return(
                            <div className="flex flex-col self-strech justify-center mb-4 border border-NeutralGray-600 bg-NeutralGray-200 rounded-2xl text-sm" key={"row_"+index}>
                                <div className='flex flex-wrap px-3 pb-2 bg-NeutralGray-100 rounded-t-2xl py-4'>
                                    <div className='w-full pb-2 flex'>
                                        <p className='text-xl font-bold'>{row.workstation_name}</p>
                                        {row.compute_name ?
                                            <p className='text-xl font-bold text-NeutralGray-600 ml-3'>({row.compute_name})</p>
                                        : null}
                                    </div>
                                    <div className='w-full lg:w-1/12 md:2/12 self-center'>
                                        <div className='self-center justify-center'>
                                            <div className="mb-4 flex justify-center">
                                                <p className={`self-center rounded-full text-xs px-2.5 py-[5px] text-black ${getStatusColor(row.status)}`}>{row.status}</p>
                                            </div>
                                            <div className='flex justify-center'>
                                                {row.platform === "windows" ?
                                                    <img src={windows} width={42} height={52} className="img-fluid mb-3" alt="WINDOWS" />
                                                :
                                                    <img src={linux} width={42} height={52} className="img-fluid mb-3" alt="LINUX"/>
                                                }
                                            </div>
                                            <div className='flex justify-center'>
                                                {row.provider === "aws" || row.provider === "AWS" ?
                                                    <img src={aws} width={42} height={52} className="img-fluid mb-3" alt="AWS" />
                                                :
                                                    <img src={gcp} width={42} height={52} className="img-fluid mb-3" alt="GCP" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full lg:w-3/12 md:3/12 self-start px-2  min-h-[115px]'>
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Catalog:</p>
                                            <p className="break-all text-black">{row.image_name}</p>
                                        </div>
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Os:</p>
                                            <p className="break-all text-black">{row.platform}</p>
                                        </div>
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Resources:</p>
                                            <p className="break-all text-black">
                                                {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                            </p>
                                        </div>
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Description:</p>
                                            <p className="break-all text-black">{row.description}</p>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 md:w-3/12 md:mb-0 sm:mb-0 lg:mb-0 mb-3 self-start pl-3">
                                        {/* <p className="mb-1 flex">
                                            <span className='text-NeutralGray-600 pr-2'>Name: </span>
                                            <span className='text-black ml-2'>{row.workstation_name} </span>
                                        </p> */}
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Duration:</p>
                                            <p className="break-all text-black">{row.status_datetime}</p>
                                        </div>
                                        <div class="flex items-start pb-3">
                                            <p className="min-w-[100px] text-NeutralGray-600">Capacity:</p>
                                            <span className='text-black'>{row.instance_type} </span>
                                            <span className='text-black pl-2'>(
                                                {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                {row.vcpus} vCPU,
                                                <span className='pl-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                            )</span>
                                        </div>
                                        {/* {row.id_name_map ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Asset:</p>
                                                <span className='text-black truncate'>{(row.id_name_map.value ? row.id_name_map.value : "")}
                                                {row.id_name_map && row.id_name_map.key ?
                                                    (<span className={`ml-1 text-info mb-0 truncate`}>
                                                    {row.id_name_map.key }</span>)
                                                : null}
                                                </span>
                                            </div>
                                        : row.asset_name ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Asset:</p>
                                                <p className="break-all text-black">{row.asset_name}</p>
                                            </div>
                                        : null} */}
                                        {row.compute_name && row.compute_domain ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Private DNS:</p>
                                                <p className="break-all text-black">{row.compute_name+'.'+row.compute_domain}</p>
                                            </div>
                                        : null}
                                        {row.public_ip_address ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Public IP:</p>
                                                <p className="break-all text-black">{row.public_ip_address}</p>
                                            </div>
                                        : row.private_ip_address ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Private IP:</p>
                                                <p className="break-all text-black">{row.private_ip_address}</p>
                                            </div>
                                        : null}
                                        {row.volume_details && row.volume_details.length ?
                                            <div class="flex items-start pb-3">
                                                <p className="min-w-[100px] text-NeutralGray-600">Volume: </p>
                                                <span className='text-black'>
                                                    {row.volume_details[0].volume_id ? row.volume_details[0].volume_id : ''} 
                                                </span>
                                                {row.volume_details[0].volume_size ?
                                                    <span className='text-black pl-2'>({row.volume_details[0].volume_size} GB)</span>
                                                : null}
                                            </div>
                                        : null}
                                        {row.user_name ?
                                            Array.isArray(row.user_name) && row.user_name.length ?
                                                <div class="flex items-start pb-3">
                                                    <p className="min-w-[100px] text-NeutralGray-600">User: </p>
                                                    <span className='text-black'>{row.user_name[0]} </span>
                                                    {row.user_name.length > 1 ?
                                                        <small className='self-center ml-2 text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>more</small>
                                                    : null}
                                                </div>
                                            :
                                                <div class="flex items-start pb-3">
                                                    <p className="min-w-[100px] text-NeutralGray-600">User: </p>
                                                    <span className='text-black'>{row.user_name} </span>
                                                </div>
                                        : null}
                                        {(row.status.toLowerCase() === 'running' && row.jupyter_link) ?
                                            <div className='self-center text-blue-600 flex justify-center pb-2'>
                                                <Link to={row.jupyter_link} className='text-blue-600 text-decoration-none' target="_blank" rel="noopener noreferrer">
                                                    Connect to Jupyter Notebook
                                                </Link>
                                            </div>
                                        : (row.status.toLowerCase() === 'running' && row.nicedcv_link) ?
                                            <div className='self-center text-blue-600 flex justify-center pb-2'>
                                                <Link to={row.nicedcv_link} className='text-blue-600 text-decoration-none' target="_blank" rel="noopener noreferrer">
                                                    Connect via NiceDCV
                                                </Link>
                                            </div>
                                        : null}
                                    </div>
                                    <div className="w-full lg:w-2/12 md:w-3/12 pr-0 flex flex-wrap self-center min-h-[115px] lg:flex">
                                        <div className="flex flex-wrap mb-4 w-full">
                                            <div className="w-1/2 pb-9">
                                                <p className='text-black font-semibold text-[28px]'>
                                                    <span className='pr-1'>{row.price_unit && row.price_unit.toLowerCase() === 'usd' ? '$' : (row.price_unit && row.price_unit.toLowerCase() === 'gbp' ? '£' : '')}</span>
                                                    {row.billing_total_cost && row.billing_total_cost ? row.billing_total_cost : 0}
                                                </p>
                                                <p className='text-NeutralGray-600 text-sm'>Total Cost</p>
                                            </div>
                                            <div className="w-1/2">
                                                <p className='text-black font-semibold text-[28px]'>{row.total_hours ? row.total_hours : 0} Hrs</p>
                                                <p className='text-NeutralGray-600 text-sm'>Total Hours</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap mb-4 w-full">
                                            <div className="w-1/2">
                                                <p className='text-black font-semibold text-[28px]'>
                                                <span className='pr-1'>{row.price_unit && row.price_unit.toLowerCase() === 'usd' ? '$' : (row.price_unit && row.price_unit.toLowerCase() === 'gbp' ? '£' : '')}</span>
                                                    {row.billing_monthly_cost ? row.billing_monthly_cost : 0}
                                                </p>
                                                <p className='text-NeutralGray-600 text-sm'>Current Month</p>
                                            </div>
                                            <div className="w-1/2">
                                                <p className='text-black font-semibold text-[28px]'>{row.current_month_total_hours ? row.current_month_total_hours: 0} Hrs</p>
                                                <p className='text-NeutralGray-600 text-sm'>Current Month</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-2/12 md:mb-0 sm:mb-0 lg:mb-0 mb-3 self-center pl-3">
                                        <div className='flex justify-center pb-[17px]'>
                                            <p className='text-NeutralGray-600 border border-DeepPurpleTints-600 rounded-full py-1.5 px-3 self-center text-xs cursor-pointer justify-center' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>View Details</p>
                                        </div>

                                        {state.actionsAllowed && state.actionsAllowed.includes('start') && row.status.toLowerCase() === 'stopped' ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`} onClick={() => {
                                                    onChangeAction(row, "start")
                                                }}> Start</p>
                                            </div>
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('stop') && row.status.toLowerCase() === 'running' ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`} onClick={() => {
                                                    onChangeAction(row, "stop")
                                                }}> Stop</p>
                                            </div>
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('terminate') && (row.status.toLowerCase() === 'running' || row.status.toLowerCase() === 'stopped') ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`} onClick={() => {
                                                    onChangeAction(row, "terminate")
                                                }}> Terminate</p>
                                            </div>
                                        : null}
                                        {row.status.toLowerCase() === 'approved' ?
                                            <div className='flex justify-center'>
                                                {state.showButtonLoading && row.workstation_id === state.selectedDetails && state.selectedDetails.workstation_id ?
                                                    <button className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full text-PrimaryLightOrange-600 px-2 py-1 text-PrimaryLightOrange-600 `}>
                                                        <LoadingCircle />
                                                        Launching
                                                    </button>
                                                :
                                                    <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full text-PrimaryLightOrange-600 px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`} onClick={() => {
                                                        onChangeAction(row, "launch")
                                                    }}>Launch</p>
                                                }
                                            </div>
                                        : null}
                                        {row.status.toLowerCase() === 'initiated' ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full text-PrimaryLightOrange-600 px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`}> Launch Initiated</p>
                                            </div>
                                        : null}
                                        {(row.status.toLowerCase() === 'requested' || row.status.toLowerCase() === 'approved' || row.status.toLowerCase() === 'failed' || row.status.toLowerCase() === 'rejected') ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full text-PrimaryLightOrange-600 px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`}
                                                    onClick={() => {
                                                        onChangeAction(row, "delete")
                                                    }}  
                                                > Delete</p>
                                            </div>
                                        : null}
                                        {(row.status.toLowerCase() === 'running' || row.status.toLowerCase() === 'stopped') ?
                                            <div className='flex justify-center'>
                                                <p className={`min-w-20 text-center mb-4 border border-PrimaryLightOrange-600 rounded-full text-PrimaryLightOrange-600 px-2 py-1 text-PrimaryLightOrange-600 cursor-pointer`} onClick={() => {
                                                    let disabled_policy = !state['disabled_policy_'+row.workstation_id]
                                                    setState(prevState => ({ ...prevState, ['disabled_policy_'+row.workstation_id]: disabled_policy }))
                                                    callDisableFuction(row, disabled_policy)
                                                }}>
                                                    {typeof state['disabled_policy_'+row.workstation_id] === 'undefined' ?
                                                        (row.disable ? 'Enable' : (state['disabled_policy_'+row.workstation_id] ? "Enable" : "Disable")) 
                                                    :
                                                        (state['disabled_policy_'+row.workstation_id] ? "Enable" : "Disable")
                                                    }  Lifecycle
                                                </p>
                                            </div>
                                        : null}

                                        {/* {row.asset_id ?
                                            <React.Fragment>
                                                <div className='flex justify-center'>
                                                    <div className='relative inline-block'>
                                                        <select className="bg-lavender-200 border border-primaryPurple-600 text-xs rounded-full py-1.5 pl-3 pr-[24.69px] text-primaryPurple-600 truncate `appearance-none" onChange={(e) => onChangeAction(row, e.target.value)}>
                                                            <option key="select" className="text-black bg-white" value=''>Select</option>
                                                            {state.actionsAllowed && state.actionsAllowed.includes('start') ?
                                                                <option className="text-black bg-white" key={"start"} defaultValue={state.selectedAction === 'start' ? true :  false} value={"start"}>Start</option>
                                                            : null}
                                                            {state.actionsAllowed && state.actionsAllowed.includes('stop') ?
                                                                <option className="text-black bg-white" key={"stop"} defaultValue={state.selectedAction === 'stop' ? true :  false} value={"stop"}>Stop</option>
                                                            : null}
                                                            {state.actionsAllowed && state.actionsAllowed.includes('modify') ?
                                                                <option className="text-black bg-white" key={"modify"} defaultValue={state.selectedAction === 'modify' ? true :  false} value={"modify"}>Modify</option>
                                                            : null}
                                                            {state.actionsAllowed && state.actionsAllowed.includes('terminate') ?
                                                                <option className="text-black bg-white" key={"terminate"} defaultValue={state.selectedAction === 'terminate' ? true :  false} value={"terminate"}>Terminate</option>
                                                            : null}
                                                            {state.actionsAllowed && state.actionsAllowed.includes('hibernate') && row.hibernate ?
                                                                <option className="text-black bg-white" key={"hibernate"} defaultValue={state.selectedAction === 'hibernate' ? true :  false} value={"hibernate"}>Hibernate</option>
                                                            : null}
                                                        </select>
                                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <Icon icon="icon-park-outline:down" className={`text-xs text-black`} width={18} height={18}></Icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        : null} */}
                                    </div>
                                </div>
                                <div className='flex flex-wrap px-3 border-t border-davysGray-600 py-[18px]'>
                                    <div className="w-full lg:w-3/12 md:6/12 px-2.5 border-r border-NeutralGray-600">
                                        <div className='flex'>
                                            <p className='text-[15px]'>CPU</p>
                                            {state["statistics_cpu_"+row.workstation_id] && Object.entries(state["statistics_cpu_"+row.workstation_id]).length ?
                                                <div className="w-1/4 pl-1 pt-1 mb-0 self-center text-xs gont-semibold">
                                                    <p className="flex mb-2 text-NeutralGray-600">Avg <span className="pl-1 text-black">{state["statistics_cpu_"+row.workstation_id].avg && state["statistics_cpu_"+row.workstation_id].avg ? state["statistics_cpu_"+row.workstation_id].avg : 0}</span></p>
                                                    <p className="flex mb-0 text-NeutralGray-600">Max <span className="pl-1 text-black">{state["statistics_cpu_"+row.workstation_id].max && state["statistics_cpu_"+row.workstation_id].max}</span></p>
                                                </div>
                                            : null}
                                        </div>
                                        {state["cpu_"+row.workstation_id] ? 
                                            <ApexListBarChart
                                                graphData={state["cpu_"+row.workstation_id]}
                                                name={'Line'}
                                                xaxis={true}
                                                xaxisLabel={true}
                                                axisLabelColor={['#000000']}
                                                xaxisFormat={'datetime'}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                markerSize={0}
                                                hideMarkerLength={30}
                                                colors={['#285695']}
                                                // colors={['#7551C2', '#285695']}
                                                sparkline={true}
                                                zoom={false}
                                                height={100}
                                                width={'100%'}
                                                grid={false}
                                                className={"transparentTooltip mb-n2"}
                                                tooltip={true}
                                                stacked={false}
                                                animations={false}
                                            />
                                        :
                                            <p className='mb-0 mt-2 text-black'>NA</p>
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/12 md:6/12 px-2.5 border-r border-NeutralGray-600">
                                        <div className='flex'>
                                            <p className='text-[15px]'>Memory</p>
                                            {state["statistics_memory_"+row.workstation_id] && Object.entries(state["statistics_memory_"+row.workstation_id]).length ?
                                                <div className="w-1/4 pl-1 pt-1 mb-0 self-center text-xs gont-semibold">
                                                    <p className="flex mb-2 text-NeutralGray-600">Avg <span className="pl-1 text-black">{state["statistics_memory_"+row.workstation_id].avg && state["statistics_memory_"+row.workstation_id].avg ? state["statistics_memory_"+row.workstation_id].avg : 0}</span></p>
                                                    <p className="flex mb-0 text-NeutralGray-600">Max <span className="pl-1 text-black">{state["statistics_memory_"+row.workstation_id].max && state["statistics_memory_"+row.workstation_id].max}</span></p>
                                                </div>
                                            : null}
                                        </div>
                                        {state["memory_"+row.workstation_id] ? 
                                            <ApexListBarChart
                                                graphData={state["memory_"+row.workstation_id]}
                                                name={'Line'}
                                                xaxis={false}
                                                xaxisFormat={'datetime'}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                markerSize={0}
                                                hideMarkerLength={30}
                                                colors={['#285695']}          
                                                // colors={['#7551C2', '#285695']}                                      
                                                sparkline={true}
                                                zoom={false}
                                                height={100}
                                                width={'100%'}
                                                grid={false}
                                                className={"transparentTooltip mb-n2"}
                                                tooltip={true}
                                                stacked={false}
                                                animations={false}
                                            />
                                        :
                                            <p className='mb-0 mt-2 text-black'>NA</p>
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/12 md:6/12 px-2.5 border-r border-NeutralGray-600">
                                        <div className='flex'>
                                            <p className='text-[15px]'>Network</p>
                                            {state["statistics_network_in_"+row.workstation_id] && Object.entries(state["statistics_network_in_"+row.workstation_id]).length ?
                                                <div className="w-1/4 pl-1 pt-1 mb-0 self-center text-xs gont-semibold">
                                                    <p className="flex mb-2 text-NeutralGray-600">Avg <span className="pl-1 text-black">{state["statistics_network_in_"+row.workstation_id].avg && state["statistics_network_in_"+row.workstation_id].avg ? state["statistics_network_in_"+row.workstation_id].avg : 0}</span></p>
                                                    <p className="flex mb-0 text-NeutralGray-600">Max <span className="pl-1 text-black">{state["statistics_network_in_"+row.workstation_id].max && state["statistics_network_in_"+row.workstation_id].max}</span></p>
                                                </div>
                                            : null}
                                        </div>
                                        {state["network_"+row.workstation_id] ? 
                                            <ApexListLineChart 
                                                graphData={state["network_"+row.workstation_id]}
                                                name={'Line'}
                                                xaxis={false}
                                                xaxisFormat={'datetime'}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                markerSize={0}
                                                hideMarkerLength={30}
                                                colors={['#7551C2', '#285695']}
                                                sparkline={true}
                                                zoom={false}
                                                height={120}
                                                width={'100%'}
                                                grid={false}
                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                tooltip={true}
                                                stacked={true}
                                                axisBorder={true}
                                            />
                                        :
                                            <p className='mb-0 mt-2 text-black'>NA</p>
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/12 md:6/12 px-2.5">
                                        <div className='flex'>
                                            <p className='text-[15px]'>Disk</p>
                                            {state["statistics_disk_"+row.workstation_id] && Object.entries(state["statistics_disk_"+row.workstation_id]).length ?
                                                <div className="w-1/4 pl-1 pt-1 mb-0 self-center text-xs gont-semibold">
                                                    <p className="flex mb-2 text-NeutralGray-600">Avg <span className="pl-1 text-black">{state["statistics_disk_"+row.workstation_id].avg && state["statistics_disk_"+row.workstation_id].avg ? state["statistics_disk_"+row.workstation_id].avg : 0}</span></p>
                                                    <p className="flex mb-0 text-NeutralGray-600">Max <span className="pl-1 text-black">{state["statistics_disk_"+row.workstation_id].max && state["statistics_disk_"+row.workstation_id].max}</span></p>
                                                </div>
                                            : null}
                                        </div>
                                        {state["disk_"+row.workstation_id] ? 
                                            <ApexListLineChart 
                                                graphData={state["disk_"+row.workstation_id]}
                                                name={'Line'}
                                                xaxis={false}
                                                xaxisFormat={'datetime'}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                markerSize={0}
                                                hideMarkerLength={30}
                                                colors={['#7551C2', '#285695']}
                                                sparkline={true}
                                                zoom={false}
                                                height={120}
                                                width={'100%'}
                                                grid={false}
                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                tooltip={true}
                                                stacked={true}
                                                axisBorder={true}
                                            />
                                        :
                                            <p className='mb-0 mt-2 text-black'>NA</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center m-4 w-full'>
                        {getCommonInformationMessageFilter('workstation')}
                    </div>
                }

                {state.confirmDetails && state.confirmAction ?
                    <AlertConfirmation 
                        confirmDetails={state.confirmDetails}
                        confirmation={(action) => {
                            if(action) {
                                onApplyAction()
                            }
                            setState(prevState => ({ ...prevState, confirmAction: false, confirmDetails: false, actionWorksatationDetails: {} }))
                        }}
                    />
                : null}
            </div>
            </React.Fragment>
    );
};
export default Workstation