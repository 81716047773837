import ApiCalls from "../../components/ApiCalls";
import {
  GET_USER_FILES,
  UPLOAD_USER_FILES,
  CREATE_FOLDER,
  DOWNLOAD_FILE,
  GET_STATS,
  DELETE_FILE,
  GET_FILE_CONTENT,
  SAVE_FILE_CONTENT,
  RENAME_FILE,
  EXTRACT_FILE,
  GET_USER_QUOTA_DETAILS,
  REQUEST_STORAGE,
  CHANGE_REQUEST_STATUS,
  LIST_REQUESTS,
  LIST_REQUESTS_HISTORY,
  GET_UPLOAD_URL,
  UPLOAD_FILE_V3,
  UPLOAD_FILE_V4,
  UPLOAD_FILE_V4_STATUS,
  DOWNLOAD_FILE_V1,
  LIST_DOWNLOADDS,
  GET_DOWNLOAD_URL
  // APPLY_STORAGE_ACTIONS
} from "../../config"
// import { ACTION_TYPES } from "../types"

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserFiles = (params) => {
  return async () => {
    try {
      if(params.path) {
        params.path = params.path.replace(/\\/g, '/')
      }

      
      const response = await ApiCalls.post(GET_USER_FILES, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;      
    }
  }
}

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const uploadUserFiles = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(UPLOAD_USER_FILES, params, { formContentType: true });
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const createFolder = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(CREATE_FOLDER, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const downloadFile = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(DOWNLOAD_FILE, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const getStats = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(GET_STATS, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const getUserQuotaDetails = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(GET_USER_QUOTA_DETAILS, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const requestStorage = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(REQUEST_STORAGE, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const changeRequestStatus = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(CHANGE_REQUEST_STATUS, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

// export const applyStorageActions = (params) => {
//   return async () => {
//     try {
//       const response = await ApiCalls.post(APPLY_STORAGE_ACTIONS, params);
//       return response.data;
//     } catch (error) {
//       return error?.response?.data || error;
//     }
//   }
// }

export const listRequests = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(LIST_REQUESTS, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const listRequestsHistory = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(LIST_REQUESTS_HISTORY, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}


export const deleteFile = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(DELETE_FILE, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const getFileContent = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(GET_FILE_CONTENT, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const saveFileContent = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(SAVE_FILE_CONTENT, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const renameFile = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(RENAME_FILE, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const extractFile = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(EXTRACT_FILE, params);
      return response.data;
    } catch (error) {
      return error?.response?.data || error;
    }
  }
}

export const getUploadUrl = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(GET_UPLOAD_URL, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const uploadFilev3 = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(UPLOAD_FILE_V3, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const uploadFilev4 = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(UPLOAD_FILE_V4, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const uploadFilev4Status = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(UPLOAD_FILE_V4_STATUS, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const downloadFileV1 = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(DOWNLOAD_FILE_V1, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const listDownloads = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(LIST_DOWNLOADDS, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};

export const getDownloadUrl = (params) => async (dispatch) => {
  try {
    const response = await ApiCalls.post(GET_DOWNLOAD_URL, params);
    return response.data; // Properly return the response
  } catch (error) {
    // Handle the error properly
    throw error.response?.data || error;
  }
};