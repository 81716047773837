/*************************************************
 * Tvastar
 * @exports
 * @file CreateUpdateGroup.js
 * @author Prakash // on 08/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';

import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath'

import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { qosCreateAccout, qosUpdateAccount, getAccountPolicy } from '../../../actions/Collider/AccountAction'
import { noSpace } from '../../../utils/utility';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import GroupAssocationDetails from './GroupAssocationDetails';
import Button from '../../common/Form/Button';
import PageLoading from '../../common/PageLoading';
import Textarea from '../../common/Form/Textarea';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';

const CreateUpdateGroup = () => {
	const [state, setState] = useState({
		listLoading: false,
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)

	const navigate = useNavigate();
	const location = useLocation();
    const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])	

    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
                selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ''
            }));			
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
						
						setState(prevState => ({
							...prevState,
							providers: response,
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
							callClusterList: true,
							callQueueList: true,
							callUserList: true
						}));						
					}
                })

        }
    }, [dispatch, providers, handleInputChange]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {	
			handleInputChange('provider', state.selectedProvider.toLowerCase())
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, handleInputChange]);

    useEffect(() => {
        if(state.selectedProvider) {
			let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: receivedData.selectedData ? receivedData.selectedData : '',
				pageType: receivedData.pageType ? receivedData.pageType : '',
				selectedprovider: receivedData.selectedData && receivedData.selectedData.provider ? receivedData.selectedData.provider : '',
				selectedAccount: receivedData.selectedData && receivedData.selectedData.account_id ? receivedData.selectedData.account_id : '',
				selectedRegion: receivedData.selectedData && receivedData.selectedData.region ? receivedData.selectedData.region : '',
				cluster_name: receivedData.selectedData && receivedData.selectedData.cluster_name ? receivedData.selectedData.cluster_name : '',
				description: receivedData.selectedData && receivedData.selectedData.description ? receivedData.selectedData.description : ''
			}))
		} else {
			setState(prevState => ({ ...prevState, pageType: 'Create' }))
		}
	}, [receivedData, dispatch])

	useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
			params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])
	
	const onFinish = () => {
		let hasError = false
		let data = qosCreateEditInputs

		if (!data.provider || !data.account_id || !data.region || !data.cluster_name) {
			hasError = true
		}

		setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

		if(!hasError) {
			if(!state.pageType || state.pageType !== 'edit') {
				setState(prevState => ({ ...prevState, callCreate: true, saveLoading: true }))
			} else {
				setState(prevState => ({ ...prevState, callUpdate: true, saveLoading: true }))
			}
		}
	}

	useEffect(() => {
		if(state.callCreate) {
			setState(prevState => ({ ...prevState, callCreate: false }))
			let params = qosCreateEditInputs
			
			dispatch(qosCreateAccout(params))
				.then((response) => {
					if(response) {
						let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in saving Qos'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Qos saved successfully'
							dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
							navigate(URL_PATH.MANAGE_QOS, { state: { selectedScreen: 'Group' } });
                        } else {
							setState(prevState => ({ ...prevState, saveLoading: false }))
						}
						CommonNotification.addNotification({
							//title: 'Wonderful!',
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ['animate__animated', 'animate__fadeIn'],
							// animationOut: ['animate__animated', 'animate__fadeOut'],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});

						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callCreate, qosCreateEditInputs, dispatch, navigate])

	// To update the state
	const updateLocationState = useCallback(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.account = state.selectedData.account
			dispatch(getAccountPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						navigate(URL_PATH.CREATE_GROUP, { 
							state: {
								selectedData: response.account_policy ? response.account_policy : {},
								pageType: 'edit',
							}
						})
					}
				})
		}
	}, [state.selectedData, dispatch, navigate])

	useEffect(() => {
		if(state.callUpdate) {
			setState(prevState => ({ ...prevState, callUpdate: false }))
			let params = qosCreateEditInputs
			delete params.user_name;

			dispatch(qosUpdateAccount(params))
				.then((response) => {
					if (response) {
						let messageType = 'danger'
						let message = response.message ? response.message : 'Error in updating Qos'
						if(response.status) {
							messageType = 'success'
							message = response.message ? response.message : 'Qos updated successfully'
							setTimeout(() => { updateLocationState() }, 500); 
						}
						setState(prevState => ({ ...prevState, saveLoading: false }))
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							},
						})
						
						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callUpdate, qosCreateEditInputs, dispatch, updateLocationState])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div className='' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
			<div className="bg-NeutralGray-200 px-8 py-10 text-NeutralGray-800 shadow-xl">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center ">
                        <p className="font-bold text-2xl">{state.pageType === 'edit' ? 'Modify' : 'Create'} Group</p>
					</div>
                </div>
            </div>
			
			
			<div className='m-6 overflow-y-auto h-[calc(100vh-290px)] bg-white rounded-2xl p-6'>
				<div className='pb-6'>
                    <div className={`flex flex-wrap`}> 
						<div className='lg:w-1/2 w-full lg:pr-4'>
							<Textbox
								label={"Qos Name"}
								type="text"
								selectedValue={qosCreateEditInputs && qosCreateEditInputs.account ? qosCreateEditInputs.account : ''}
								callback={(value) => {
									setState(prevState => ({ ...prevState, account: noSpace(value)}))
									handleInputChange('account', noSpace(value))
								}}
								placeholder={'Enter group'}
								manditory={true}
								hasError={state.hasError}
								disabled={state.pageType === 'edit'}
							/>
							<p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.category}</p>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='w-full pt-4 lg:pt-0'>
							<Textarea
								label={'Description'}
								placeholder={'Enter description'}
								rows={4}
								wordLength={1000}
								callback={(value) => {
									setState(prevState => ({ ...prevState, description: value }))
									handleInputChange("description", value)
								}}
							/>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='flex justify-start w-1/2 pr-4'>
							<SelectOption
								label={"Provider"}
								fields={["provider_name", "provider_name"]}
								options={state.providers}
								selectedValues={state.selectedProvider ? state.selectedProvider : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState,  selectedProvider: value, selectedAccount: '', selectedRegion: '', selectedCluster: '' }))
									handleInputChange('provider', value.toLowerCase())
								}}
								manditory={true}
								hasError={state.hasError}
								singleSelection={true}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
						<div className='flex justify-start w-1/2 pl-4'>
							<SelectOption
								label={"Account"}
								fields={["account_id", "account_name"]}
								options={state.accounts}
								selectedValues={state.selectedAccount ? state.selectedAccount : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({  ...prevState,  selectedAccount: value, selectedCluster: '' }))
									handleInputChange('account_id', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='flex justify-start w-1/2 pr-4'>
							<SelectOption
								label={"Region"}
								fields={["region", "name"]}
								options={state.regions}
								selectedValues={state.selectedRegion ? state.selectedRegion : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: '' }))
									handleInputChange('region', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
						<div className='flex justify-start w-1/2 pl-4'>
							<SelectOption
								label={"Cluster Name"}
								options={state.clusters}
								selectedValues={state.selectedCluster ? state.selectedCluster : ""}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedCluster: value }))
									handleInputChange('cluster_name', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
					</div>
				</div>
							
				<div className='rounded-md bg-GhostWhite p-3 mt-3'>
					<GroupAssocationDetails 
						onChildPageClick={(event) => handleChildClick(event, 'parent')}
					/>
				</div>
			</div>
							
			<div className="w-full p-8 shadow-lg bg-NeutralGray-700">
				<div className={`flex justify-center`}>
					{state.hasError ?
						<p className='text-white text-lg mr-3 self-center'>Please fill all the required fields</p>
					: null}
					<div className={`self-center flex justify-end`}>
						{state.pageType !== 'View' ?
							state.saveLoading ?
								<Button
									classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
									label={state.pageType === 'edit' ? 'Updating' : 'Submitting'}
									loading={true}
								/>
							:
								<Button
									classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
									label={state.pageType === 'edit' ? 'Update' : 'Submit'}
									callback={() => {
										onFinish()
									}}
								/>
						:null}
						<Button
							classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
							label={'Cancel'}
							callback={() => 
								navigate(URL_PATH.MANAGE_QOS, { state: { selectedManage: 'Group' } })
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateUpdateGroup
