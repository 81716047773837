import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import ExistingDatabaseSection from "./ExistingDatabaseSection";
import NewDatabaseSection from "./NewDatabaseSection";
import RadioOption from '../../../common/Form/RadioOption';

const DatabaseSection = (props) => {
	const [state, setState] = useState({});
	const createUpdateCluster = useSelector((state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false);

	const handleDatabaseOptionChange = (value) => {
		if (window.confirm(
					"If you change the database option, the values already entered in the database fields will be lost. Are you sure you want to proceed ?"
				)
			) {
				setState((prevState) => ({ ...prevState, databaseOption: value }));
				let obj = createUpdateCluster ? createUpdateCluster : {};
				obj["database_configuration"] = {};
			}
	};

	const callPageVist = useCallback(() => {
		let databaseConfiguration =
			createUpdateCluster && createUpdateCluster.database_configuration
				? createUpdateCluster.database_configuration
				: {};
		if (!state.databaseOption) {
			setState((prevState) => ({ ...prevState, databaseOption: Object.keys(databaseConfiguration).includes("vpc_id") ? "new" : "existing" }));
		}
	}, [createUpdateCluster, state.databaseOption]);

	useEffect(() => callPageVist(), [callPageVist]);

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

		return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			<p className="text-base font-medium text-black self-center w-full">Database Options</p>
			<div className='flex pt-3'>
				<RadioOption
					label="Use Existing"
					value="existing"
					selected={state.databaseOption === "existing"}
					callback={() => handleDatabaseOptionChange("existing")}
				/>
				<RadioOption
					label="Create New"
					value="new"
					selected={state.databaseOption === "new"}
					callback={() => handleDatabaseOptionChange("new")}
					className="pl-8"
				/>
			</div>
			{state.databaseOption === "existing" ?
				<ExistingDatabaseSection 
					pageMode={props.pageMode} 
					hasError={props.hasError}
				/>
			:
				<NewDatabaseSection 
					pageMode={props.pageMode} 
					hasError={props.hasError}
				/>
			}
		</div>
		)
}


export default DatabaseSection