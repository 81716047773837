/*************************************************
 * Tvastar
 * @exports
 * @file DynamicServices.js
 * @author Prakash // on 21/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import _ from 'lodash'
import {Icon} from "@iconify/react";

import { setGovernancePropsDetails, setNewEditRuleDetails } from '../../../actions/governance/GovernanceAction'
import { DynamicApiToDevCall, getAllTagsKeys } from '../../../actions/commonActionNew'
import ObjectListInputs from './ObjectListInputs'
import { onlyNumeric } from '../../../utils/utility';
import MultiSelectSection from '../../common/MultiSelectSection';
import Checkbox from '../../common/Form/Checkbox';
import SelectOption from '../../common/Form/SelectOption';
import Textbox from '../../common/Form/Textbox';

const DynamicServices = (props) => {
	const [state, setState] = useState({
		policyArray: [0],
		day_options: [
			{label: "Sunday", value: "Sun"},
			{label: "Monday", value: "Mon"},
			{label: "Tuesday", value: "Tue"},
			{label: "Wednesday", value: "Wed"},
			{label: "Thursday", value: "Thu"},
			{label: "Fridayday", value: "Fri"},
			{label: "Saturday", value: "Sat"},
		],
		timeTypeOptions: [
			{value: "h", label: "Hour(s)"},
			{value: "m", label: "Minute(s)"}
		],
		time_type_0: "h"
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
	const onClickFinish = useSelector(state => state?.governance?.governancePropsDetails?.onClickFinish||false)
	const tagKeys = useSelector(state => state?.filters?.tagKeys);	

	const formLabelBuilder = useCallback((item) => {
		let querybuilder = []
		let query = item.label
		let splitString = query && query !== "" ?query.split(" ") : []
		splitString.forEach(str => {
			let result = str.split("$");
			if(result.length === 1) {
				let dataRow = {}
				dataRow.input = false
				dataRow.string = result[0]				
				querybuilder.push(dataRow)
			} else {
				result.forEach((resRow, index) => {
					if(resRow !== "") {
						if(index === 1) {
							let dataRow = {}
							dataRow.input = true
							dataRow.field = item.variable
							dataRow.component = item.component
							dataRow.string = result[0]
							querybuilder.push(dataRow)
						} else {
							let dataRow = {}
							dataRow.input = false
							dataRow.string = resRow
							querybuilder.push(dataRow)
						}
					}
				})
			}
		})

		setState(prevState => ({ ...prevState, [item.variable+"_label"]: querybuilder }))

	}, [])

	const apiToCall = useCallback((apiDetails) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
			let dynamicValues = item.body && item.body.dynamic_values
			dynamicValues && dynamicValues.forEach(req => {
				if(editNewRuleDetails && editNewRuleDetails[req.key]) {
					params[req.key] = editNewRuleDetails[req.key]
				}

				if(editNewRuleDetails && editNewRuleDetails.applies_to && editNewRuleDetails.applies_to.length) {
					let dat = []
					editNewRuleDetails.applies_to.forEach(app => {
						// if(app[req.key] === "account_id") {
						// 	dat.push(req.key)
						// } else {
						// 	dat = [...dat, ...app[req.key]]
						// }
						// if(req.key === "account_id") {
						// 	dat.push(app[req.key])
						// 	if(!dat.includes(app[req.key])) {
						// 		dat.push(app[req.key])
						// 	}
						// } else {
							if(Array.isArray(app[req.key])) {
								dat = [...dat, ...app[req.key]]
								dat = [...new Set(dat)];
							} else {
								if(!dat.includes(app[req.key])) {
									dat.push(app[req.key])
								}
							}
						// }
					})
					params[req.key] = dat
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			let responseMapping = item.response_mapping
			
			dispatch(DynamicApiToDevCall(API_END_POINT, apiMethod, url, params))
				.then((response) => {
					if(response) {
						let results = response
						responseMapping && responseMapping.forEach(res => {
							if(res.index >= 0) {
								results = results[res.index]
							}
							
							let array = results
							if(res.path && res.path !== "") {
								array = results[res.path]
							}
	
							let options = []
							if(Array.isArray(array)) {
								array.forEach(item => {
									if(res.type && res.type === 'list_of_strings') {
										let checkType = res.label && res.label !== "" ? item[res.label] : []
										if(checkType && checkType.length) {
											checkType.forEach(val => {
												let optionRow = {
													label: val,
													value: val
												}
												options.push(optionRow)
											})
										}

									} else {
										let optionRow = {
											label: res.label && res.label !== "" ? item[res.label] : item,
											value: res.value && res.value !== "" ? item[res.value] : item
										}
										options.push(optionRow)
									}
								})
							}
	
							setState(prevState => ({ ...prevState, [res.variable+"_options"]: options }))
							if(props.viewMode) {
								setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
							} else {
								if(editNewRuleDetails && editNewRuleDetails.policies) {
									if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
										let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
										if(savedData.length) {
											setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
										}
									}
								}
							}
						})
					}
				})
		})
	}, [dispatch, editNewRuleDetails, props.masterData, props.viewMode])
	
	const getAllTagsKeysFunction = useCallback(() => {
		if(!tagKeys) {
			let params = {}
			dispatch(getAllTagsKeys(params))
		}
	}, [tagKeys, dispatch])

	useEffect(() => {		
		if(props.masterData && !_.isEqual(props.masterData, state.masterData)) {
			setState(prevState => ({ ...prevState, masterData: props.masterData }))
			
			let masterData = props.masterData
			let actionDetails = []
			if(masterData.actions && masterData.actions.length) {
				actionDetails = masterData.actions
			}
			let variables = masterData.variables
			variables.forEach(item => {
				if(item.component === "select" || item.component === "multiselect") {
					if(item.options) {
						setState(prevState => ({ ...prevState, [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" }))
					} else {
						setState(prevState => ({ ...prevState, apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" }))
					}
				} else if(item.component === "tagging_component") {
					getAllTagsKeysFunction()
				}

				if(item.depends_on) {
					let dependsOnFieldFullfiled = false
					let paddingLeft = 0
					if(item.depends_on && item.depends_on.length) {
						item.depends_on.forEach(dep => {
							let checkVariableDetails = variables.filter(e => e.variable === dep.key).length ? variables.filter(e => e.variable === dep.key) : {}
							if(checkVariableDetails.length && checkVariableDetails[0].default && checkVariableDetails[0].default === dep.value) {
								dependsOnFieldFullfiled = true
							}
						})
						paddingLeft = parseInt(2 + item.depends_on.length)+"rem"
						
					}
					setState(prevState => ({ ...prevState, [item.variable+"_depends_on"]: dependsOnFieldFullfiled, [item.variable+"_paddingLeft"]: paddingLeft }))
				}
	
				formLabelBuilder(item)
	
				setState(prevState => ({ ...prevState, ["mode_"+item.variable]: item.mode ? item.mode : "" }))
			})
			
			let groupedVariables = _.chain(variables).groupBy("step").map((value, key) => ({ label: key, data: value })).value();		
			
			setState(prevState => ({ ...prevState, tags: masterData.tags, groupedVariables, variables, template: false, description: masterData.description ? masterData.description : "", actionDetails }))
	
			if(masterData.api_to_call) {
				apiToCall(masterData.api_to_call)
			} else {					
				if(props.viewMode) {
					setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
				} else {
					if(editNewRuleDetails && editNewRuleDetails.policies) {
						if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
							let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
							if(savedData.length) {
								setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
							}
						}
					}
				}
			}
		}
	}, [apiToCall, editNewRuleDetails, getAllTagsKeysFunction, formLabelBuilder, props.viewMode, props.masterData, state.masterData])

	useEffect(() => {
		if(state.callSavedData) {
			setState(prevState => ({ ...prevState, callSavedData: false }))
			let savedData = state.savedData
			let policyArray = []
			savedData.forEach((row, index) => {
				policyArray.push(index)
				setState(prevState => ({ ...prevState, ["policy_id_"+index]: row.policy_id }))
				if(row.action && Object.entries(row.action)) {
					Object.entries(row.action).forEach(([key, value]) => {
						if(key === "schedule") {
							let timeSplit = value.substring(0, value.length - 1)
							let timeTypeSplit = value.substring(value.length - 1, value.length)
							if(timeTypeSplit === "h" || timeTypeSplit === "m") {
								setState(prevState => ({ ...prevState, ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit }))
							} else {
								setState(prevState => ({ ...prevState, [key+"_"+index]: value, ["time_type_"+index]: "" }))
							}
						} else {
							if(key === "name") {
								key = "selected_action"
							}
							setState(prevState => ({ ...prevState, [key+"_"+index]: value }))
						}
					})
				}
				row.variables.forEach(item => {
					setState(prevState => ({ ...prevState, [item.variable+"_"+index]: item.value_object_list ? item.value_object_list : (item.value_list ? item.value_list : item.value), inputDetected: true }))

					let dependsOnFieldFullfiled = true
					let paddingLeft = 0
					if(item.depends_on && item.depends_on.length) {
						item.depends_on.forEach(dep => {
							if(!state[dep.key+"_"+index] || state[dep.key+"_"+index] !== dep.value) {
								dependsOnFieldFullfiled = false
							}
						})
						paddingLeft = parseInt(2 + item.depends_on.length)+"rem"
					}

					if(item.component === "daytimerange") {
						let timeRange = ""
						if(state[item.variable+"_"+index] && state[item.variable+"_"+index] !== "") {
							let splitTime = state[item.variable+"_"+index].split("-")
							if(splitTime.length > 1) {
								splitTime.forEach((dT, dTindex) => {
									let dayTime = dT.split(":")
									setState(prevState => ({ ...prevState, ["day_"+index]: dayTime[0] }))
									if(dTindex) {
										timeRange += "-"	
									}
									timeRange += dayTime[1]+":"+dayTime[2]
								})
							}
						}
						setState(prevState => ({ ...prevState, ["time_"+index]: timeRange }))
					}

					setState(prevState => ({ ...prevState, [item.variable+"_depends_on"]: dependsOnFieldFullfiled, [item.variable+"_paddingLeft"]: paddingLeft }))
				})
	
				if(row.enabled) {
					setState(prevState => ({ ...prevState, ["enabled_"+index]: true }))
				}
			})
			setState(prevState => ({ ...prevState, policyArray }))
		}
	}, [state.callSavedData, state.savedData, state])

	useEffect(() => {
		if(onClickFinish) {
			let hasError = false
			let inputDetected = false
		    if(state.variables && state.variables.length) {
				state.policyArray.forEach(item => {
					state.variables.forEach((varb, varbIndex) => {
						if(varb.value) {
							if(state[varb.variable+"_"+item] || state[varb.variable+"_"+item] !== "") {
								inputDetected = true
   						    }
					    } else if(varb.value_list) {
							if(state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length) {
								inputDetected = true
						    }
					    }

					    if(!hasError && (!varb.manditory || varb.manditory === "undefined")) {
							hasError = false
					    } else if(!hasError && varb.manditory) {
							if(varb.value) {
								if(!state[varb.variable+"_"+item] || state[varb.variable+"_"+item] === "") {
									hasError = true
						    	}
						    } else if(varb.value_list) {
		    					if(!state[varb.variable+"_"+item] && !state[varb.variable+"_"+item].length) {
									hasError = true
							    }
			   			    }
					    }
				    })
			    })
		    }

		    dispatch(setGovernancePropsDetails('onClickFinish', false))
		
		    props.validateInput(hasError, inputDetected)
	    }

	}, [onClickFinish, props, state, dispatch])

	const showPolicy = (array, showField, index) => {		
		state[array].forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: !state[showField+"_"+item] }))
			}
		})
	}

	const removeField = (field, reg, options) => {
		let filteredResult = state[field].filter(e => e !== reg)
        setState(prevState => ({ ...prevState, [field]: filteredResult, onChangeInput: true }))
	}

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let data = []
			let inputDetected = false
			state.policyArray.forEach(item => {
				let obj = {}
				if(state.edit) {
					obj.policy_id = "id"
				}
				if(state["show_child_policy_"+item] && state["enabled_"+item]) {
					inputDetected = true
				}
				obj.rule_category = props.masterData.rule_category
				obj.tags = props.masterData.tags
				obj.mode =  props.masterData.mode
				obj.enabled = state["enabled_"+item] ? true : false
				// obj.disabled = state["disable_"+item] ? true : false
				// obj.enabled = false
				obj.rule_id = props.masterData.rule_id
				obj.resource_type = props.resource_type
				obj.rule_category = props.rule_category
				obj.template = false
				obj.governance_type = props.governance_type
	
				if(state["policy_id_"+item]) {
					obj.policy_id = state["policy_id_"+item]
				}
	
				let variables = []
				let masterVaiables = props.masterData.variables ? props.masterData.variables : []
				let inputMissing = false
				masterVaiables.forEach(itmVar => {
					let dependsOnFieldFullfiled = true
					let paddingLeft = 0
					if(itmVar.depends_on && itmVar.depends_on.length) {
						itmVar.depends_on.forEach(dep => {
							if(!state[dep.key+"_"+item] || state[dep.key+"_"+item] !== dep.value) {
								dependsOnFieldFullfiled = false
							}
						})
						paddingLeft = parseInt(2 + itmVar.depends_on.length)+"rem"
					}
					setState(prevState => ({ ...prevState, [itmVar.variable+"_depends_on"]: dependsOnFieldFullfiled, [itmVar.variable+"_paddingLeft"]: paddingLeft }))
	
					let inputValue = state[itmVar.variable+"_"+item]
					if(itmVar.component === "daytimerange") {
						let dayTimeRange = ""
						if(state["day_"+item] && state["time_"+item] && state["time_"+item] !== "") {
							let splitTime = state["time_"+item].split("-")
							if(splitTime.length > 1) {
								splitTime.forEach((dT, index) => {
									if(index) {
										dayTimeRange += "-"	
									}
									dayTimeRange += state["day_"+item]+":"+dT
								})
							}
						}
						setState(prevState => ({ ...prevState, [itmVar.variable+"_"+item]: dayTimeRange }))
	
						inputValue = dayTimeRange
					}
					
					if(itmVar.component === "tagging_component") {
						inputValue = state[itmVar.variable+"_"+item] ? state[itmVar.variable+"_"+item].filter(e => e.value !== "All") : []
					}
	
					if(itmVar.component === "value_object_list") {
						if(state["obj_list_"+itmVar.variable+"_"+item]) {
							inputValue = state["obj_list_"+itmVar.variable+"_"+item]
						} else {
							inputValue = itmVar.value_object_list
						}
					}					
					
					let dataRow = {}
					Object.entries(itmVar).forEach(([key, value]) => {
						if(key === "value_list" || key === "value" || key === "value_object_list") {							
							dataRow[key] = inputValue
						} else {
							dataRow[key] = value
						}

						if(typeof inputValue !== 'boolean') {
							if(!inputValue || (inputValue && Array.isArray(inputValue) && !inputValue.length)) {
								inputMissing = true
							}
						}
					})
					variables.push(dataRow)
				})
				obj.description = state.description
				obj.variables = variables
	
				if(state.actionDetails && state.actionDetails.length) {
					let actionObj = {}
					actionObj.variables = []
					if(state["selected_action_"+item]) {
						actionObj.processor_rule_id = state["action_processor_rule_id_"+item]
						actionObj.name = state["selected_action_"+item]				
						if(state["apply_method_"+item]) {
							actionObj.apply_method = state["apply_method_"+item]					
							if(state["schedule_"+item] && state["schedule_"+item] !== "") {
								if(state["apply_method_"+item] === "run_after") {
									actionObj.schedule = state["schedule_"+item]+""+state["time_type_"+item]
								} else if(state["apply_method_"+item] === "run_at") {
									actionObj.schedule = state["schedule_"+item]
								}
							}
						}
						obj.action = actionObj
					}
				}

				if(!inputMissing) {
					data.push(obj)
				}
			})
	
			let allPolicies = editNewRuleDetails && editNewRuleDetails.policies ? editNewRuleDetails.policies : []
	
			let currentPolicies = []
			let policies = []
	
			if(allPolicies.length) {
				allPolicies.forEach(row => {
					if(row.rule_id === props.masterData.rule_id) {
						currentPolicies.push(row)
					} else {
						policies.push(row)
					}
				})
			}
			
			if(data && data.length) {
				policies = [...data, ...policies]
			} else {
				policies = policies.filter(e => e.rule_id !== props.masterData.rule_id)
			}
			
			setTimeout(() => { dispatch(setNewEditRuleDetails('policies', policies)) }, 500);
	
			setState(prevState => ({ ...prevState, inputDetected }))
		}
	}, [dispatch, editNewRuleDetails, props, state])

	const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTimeRange(str, state)
		} else {
			validateTimeRange(time, state)
		}
	}

	const validateTimeRange = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00-"
			} else if(strLength > 3 && parseInt(firstMinute) > 10) {
				data += firstMinute+"-"
			} else {
				data += firstMinute
			}
		}

		let secondHour = str.substring(4, 6)
		if(secondHour.length) {
			if(strLength > 4 && parseInt(secondHour) > 23) {
				data += "00:"
			} else if(strLength > 4 && parseInt(secondHour) < 10) {
				if(secondHour.length === 2) {
					data += secondHour+":"
				} else {
					data += secondHour
				}
			} else if(strLength > 4 && parseInt(secondHour) <= 23) {
				data += secondHour+":"
			}
		}

		let secondMinute = str.substring(6, 8)
		
		if(secondMinute.length) {
			if(strLength > 6 && parseInt(secondMinute) > 59) {
				data += "00"
			} else if(strLength > 6 && parseInt(secondMinute) > 10) {
				data += secondMinute
			} else {
				data += secondMinute
			}
		}
		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
	}

	const resetPolicy = (item) => {
		let masterVaiables = props.masterData.variables ? props.masterData.variables : []
		masterVaiables.forEach(itmVar => {
			setState(prevState => ({ ...prevState, [itmVar.variable+"_"+item]: itmVar.value_list ? [] : "", onChangeInput: true }))
		})
	}

	const addNewTextList = (variable, item) => {
		let array = state[variable+"_"+item] ? state[variable+"_"+item] : []
        if(state[["input_"+variable+"_"+item]] && state[["input_"+variable+"_"+item]] !== "") {
			array.push(state[["input_"+variable+"_"+item]])
		}

		setState(prevState => ({ ...prevState, [variable+"_"+item]: array, ["input_"+item]: "", onChangeInput: true }))
	}

	const removeTextDetails = (variable, item, value) => {
		let filteredResult = state[variable+"_"+item].filter(e => e !== value)
        setState(prevState => ({ ...prevState, [variable+"_"+item]: filteredResult, onChangeInput: true }))
    }

	const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

	const validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00:"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
	}

	const addNewTag = (variable, item) => {
		let selectedTags = state[variable+"_"+item] ? state[variable+"_"+item] : []

		if(state["tag_key_"+item] && state["tag_key_"+item] !== "") {
			if(state["tagValue_"+item] && state["tagValue_"+item].length) {
				state["tagValue_"+item].forEach(tag => {
					if(tag !== "All" && (!(selectedTags.filter(e => e.key === state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== state.tag_key))) {
						let dataRow = {}
						dataRow.key = state["tag_key_"+item]
						dataRow.value = tag
						selectedTags.push(dataRow)
					}
				})
			} else {
				let dataRow = {}
				dataRow.key = state["tag_key_"+item]
				selectedTags.push(dataRow)
			}
				
			// let dataRow = {
			// 	key: state["tag_key_"+item],
			// 	value: state["tagValue_"+item],
			// }
			// selectedTags.push(dataRow)
		}

		setState(prevState => ({ ...prevState, [variable+"_"+item]: selectedTags, ["tag_key_"+item]: "", ["tagValue_"+item]: [], tagValues: [], onChangeInput: true }))
	}

	const removeTagSection = (variable, item, tag) => {
		// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = state[variable+"_"+item].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, [variable+"_"+item]: filteredResult, onChangeInput: true }))
	}

	const valueObjListFunction = (inputValues, varb, item) => {
		setState(prevState => ({ ...prevState, ["obj_list_"+varb.variable+"_"+item]: inputValues, onChangeInput: true }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.policyArray && state.policyArray.map((item, index) => {
				return(
					<React.Fragment key={"pol_"+index}>
					<div key={'policyrArray_'+index} className={`bg-white border border-NeutralGray-600 rounded-2xl p-4 mt-3`}>
						{!props.viewMode ?
							<React.Fragment>
							{state.hasError ?
								<p className='text-ferrariRed-600 text-base'>Please check the input</p>
							: null}
							<div className={`flex ${!props.viewMode ? "justify-between" : "justify-end"} pl-2`}>
								{!state.inputDetected ?
									<p className={`font-medium text-base self-center mb-1 cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>{props.masterData && props.masterData.description ? props.masterData.description : "Choose policy details"}</p>
								:
									<p className={`font-medium text-base self-center mb-1 flex flex-wrap`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>
										{state.variables ?
											state.variables.map((varb, varbIndex) => {
												return(
													<React.Fragment key={'variable_'+varbIndex}>
													{state[varb.variable+"_label"] ?
														state[varb.variable+"_label"].map((row, rowIndex) => {
															return(
																<React.Fragment key={'Var_'+rowIndex}>
																{row.input ?
																	row.component === "select" ?
																		<span className={`mb-0 mr-1 mt-2 text-base self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>
																			{state[varb.variable+"_"+item] !== "undefined" ? (state[varb.variable+"_options"] && state[varb.variable+"_options"].filter(e => e.value === state[varb.variable+"_"+item]).length ? state[varb.variable+"_options"].filter(e => e.value === state[varb.variable+"_"+item])[0].label : state[varb.variable+"_"+item]) : ""}
																		</span>
																	: row.component === "text" || row.component === "timerange" ?
																		varb.value_list ?
																			state[varb.variable+"_"+item] && state[varb.variable+"_"+item].map((lst, lstIndex) => {
																				return(
																					<span key={'varList_'+lstIndex} className="flex border border-NeutralGray-600 px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{state[varb.variable+"_options"] && state[varb.variable+"_options"].filter(e => e.value === lst).length ? state[varb.variable+"_options"].filter(e => e.value === lst)[0].label : lst}</span>
																				)
																			})
																		:
																			<span className={`mb-0 mr-1 mt-2 text- self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>{state[varb.variable+"_"+item]}</span>
																	: row.component === "daytimerange" ?
																		<React.Fragment>
																			<span className={`mb-0 mr-1 mt-2 text- self-center ${!rowIndex && varbIndex ? "ml-2" : ""}`}>{state[varb.variable + "_" + item] ? state[varb.variable+"_"+item] : ""}</span>
																		</React.Fragment>
																	: typeof state[varb.variable+"_"+item] === 'boolean' ?
																		null
																	: row.component === "tagging_component" ?
																		state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length ? 
																			state[varb.variable+"_"+item].filter(e => e.value !== "All").map((tag, tagIndex) => {
																				return(
																					<span key={'tagging_'+tagIndex} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{tag.key + (tag.value ? " : "+tag.value : "")}</span>
																				)
																			})
																		: null
																	: row.component === "value_object_list" ?
																		// <p className={`mb-0 mx-2 self-center mb-1 text-lightGray cursor-pointer`}>{props.masterData && props.masterData.description ? props.masterData.description : ""}</p>
																		null
																	:
																		state[varb.variable+"_"+item] && state[varb.variable+"_"+item].map((lst, lsIndex) => {
																			return(
																				<span key={'variabl_'+lsIndex} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{state[varb.variable+"_options"] && state[varb.variable+"_options"].filter(e => e.value === lst).length ? state[varb.variable+"_options"].filter(e => e.value === lst)[0].label : lst}</span>
																			)
																		})
																:
																	<span className={`mb-0 mr-1 mt-2 ${!rowIndex && varbIndex ? "ml-2" : ""} self-center`}>{!rowIndex ? row.string : (row.string !== "" ? row.string.toLowerCase(): "")}</span>
																}
																</React.Fragment>
															)
														})
													: null}
													</React.Fragment>
												)
											})
										: null}
									</p>
								}
								<div className={`flex`}>
									{state["tags"] && state["tags"].length ?
										state["tags"].map((tag, ttIndex) => {
											return(
												tag !== "" ?
													<span key={'tag_'+ttIndex} className={`bg-PrimaryLightOrangeTints px-4 py-1.5 mr-2 self-center text-black rounded-full text-sm`} > {tag} </span>
												: null
											)
										})
									: null}
									{!state["show_child_policy_"+index] ?
										<React.Fragment>
											{/* <Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-black" onClick={() => showPolicy("policyArray", "show_child_policy", item)} /> */}
											<Icon icon={`icon-park-outline:${state["show_child_policy_"+item] ? 'up' : 'down'}`} width={24} height={24} className='bg-white h-6 w-6 text-black rounded-full cursor-pointer border border-DeepPurpleTints-600 self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)}/>
											{/* <Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center'  /> */}
										</React.Fragment>
									: 
										<div className="flex">
											{/* <Icon icon="mdi:reload" className="text-black self-center cursor-pointer" width="18" height="18" onClick={() => resetPolicy(item)} /> */}
											{/* <span className={`fal fa-trash cursor-pointer mr-3 text-lightGray f15`} onClick={() => deletePolicy(item)}></span> */}
											{/* <Icon icon={`fa:angle- ${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} /> */}

											<Icon icon={`icon-park-outline:${state["show_child_policy_"+item] ? 'up' : 'down'}`} width={24} height={24} className='bg-white h-6 w-6 text-black rounded-full cursor-pointer border border-DeepPurpleTints-600 self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)}/>
										</div>
									}
								</div>
							</div>
							</React.Fragment>
						: null}
						{state["show_child_policy_"+item] || props.viewMode ?
							<div className={`${props.viewMode ? "pl-3" : "pl-5"}`}>
								<div className="flex justify-between">
									<div className={`${props.viewMode ? "w-4/5" : "w-full"}`}>
										{state.groupedVariables ?
											state.groupedVariables.map((grp, index) => {
												return(
													<div key={'grpVar_'+index} className={`flex flex-wrap mb-4 ${parseInt(grp.label) > 1 ? "" : "pl-1"}`}>
														{((!index && grp.label === "undefined") || parseInt(grp.label) === 1) && !props.viewMode ?
															<React.Fragment>
															{/* <div className='lg:w-1/2 lg:pl-4 w-full self-center pt-4'> */}
															<Checkbox
																label=""
																name={state["enabled_"+item]}
																selectedValue={state["enabled_"+item]}
																callback={() => {
																	setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))
																}}
															/>

															{/* // <div className={`form-check form-check-inline self-center mt-2 mr-2 ${state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
															// 	<input
															// 		className='form-check-input m-0'
															// 		type='checkbox'
															// 		checked={state["enabled_"+item]}
															// 		onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}	
															// 		title={state["enabled_"+item] ? "enabled" : "not enabled"}
															// 	/>
															// 	{!state["enabled_"+item] ?
															// 		<span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}></span>
															// 	: null}
															// </div> */}
															</React.Fragment>
														: null}
														{grp.data.length ?
															grp.data.map((varb, varbIndex) => {
																return(
																	<React.Fragment key={'varb_'+varbIndex}>
																	{!varb.depends_on || !varb.depends_on.length || state[varb.variable+"_depends_on"] ?
																		<div className={`flex flex-wrap font-base font-medium ${parseInt(grp.label) === 1 ? "w-10/12" : "w-full"} ${!state[varb.variable+"_paddingLeft"] && parseInt(grp.label) > 1 ? "pl-4 ml-1" : ""}`} style={{paddingLeft: state[varb.variable+"_paddingLeft"] ? state[varb.variable+"_paddingLeft"] : ""}}>
																			{state[varb.variable+"_label"] ?
																				state[varb.variable+"_label"].map((row, rIndex) => {
																					return(
																						<React.Fragment key={'app_'+rIndex}>
																						{row.input ?
																							varb.component === "multiselect" ?
																								<React.Fragment>
																								{!props.viewMode ?
																									<div className='ml-2 self-end'>
																										<SelectOption
																											label={""}
																											fields={["value", "label"]}
																											options={state[varb.variable+"_options"]}
																											selectedValues={state[varb.variable+"_"+item] ? state[varb.variable+"_"+item] : []}
																											callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [varb.variable+"_"+item]: value, onChangeInput: true }))}
																											dropdownWidth={'min-w-40'}
																											classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
																										/>
																									</div>
																								: null}
																								
																								{state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length ? 
																									state[varb.variable+"_"+item].includes("All") ?
																										<span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">All
																											{/* <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => setState(prevState => ({ ...prevState, ["selected_region_"+item]: [] }))} /> */}
																										</span>
																									: 
																										state[varb.variable+"_"+item].map((reg, index) => {
																											return(
																												<span key={'reg_'+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{state[varb.variable+"_options"] && state[varb.variable+"_options"].filter(e => e.value === reg).length ? state[varb.variable+"_options"].filter(e => e.value === reg)[0].label : reg}
																												
																													<Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center' onClick={() => removeField(varb.variable+"_"+item, reg, state[varb.variable+"_options"])} />
																												</span>
																											)
																										})
																								: null}
																								</React.Fragment>
																							: varb.component === "select" ?
																								!props.viewMode ?
																									<div className='ml-2 self-end'>
																										<SelectOption
																											label={""}
																											fields={["value", "label"]}
																											options={state[varb.variable+"_options"] ? state[varb.variable+"_options"] : []}
																											selectedValues={state[varb.variable+"_"+item] ? state[varb.variable+"_"+item] : ''}
																											callbackMultiSelect={(value) => {
																												setState(prevState => ({ ...prevState, [varb.variable+"_"+item]: value, onChangeInput: true }))
																											}}
																											singleSelection={true}
																											dropdownWidth={'min-w-40'}
																											classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
																										/>
																									</div>
																								: 
																									<span className={`mx-1 mt-2 text-`}>
																										{state[varb.variable+"_"+item] !== "undefined" && state[varb.variable+"_options"] && state[varb.variable+"_options"].filter(e => e.value === (state[varb.variable+"_"+item])).length ? state[varb.variable+"_options"].filter(e => e.value === (state[varb.variable+"_"+item]))[0].label : <span className="placeholder">Select</span>}
																									</span>
																							: varb.component === "text" ?
																								!props.viewMode ?
																									varb.value_list ?
																										<div className='md:w-1/3 md:pr-3'>
																											<Textbox
																												label={''}
																												selectedValue={state["input_"+varb.variable+"_"+item]}
																												callback={(value) => {
																													setState(prevState => ({ ...prevState, ["input_"+varb.variable+"_"+item]: value }))
																												}}
																												placeholder={'Enter'}
																												classDetails={{border: "border-b border-NeutralGray-600", padding: 'p-1', width:'min-w-32', rounded: ' '}}
																											/>
																											<Icon icon={`${state.showPolicySection ? 'iconoir:minus' :'iconoir:plus'}`} className='ml-2' width={24} height={24} onClick={() => addNewTextList(varb.variable, item)} />
																											{state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length ? 
																												state[varb.variable+"_"+item].map((row, index) => {
																													return(
																														<span key={'varbb_'+index} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{row}
																															<Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center' onClick={() => removeTextDetails(varb.variable, item, row)} />
																														</span>
																													)
																												})
																											: null}
																										</div>
																									:
																										<Textbox
																											label={''}
																											selectedValue={state[varb.variable+"_"+item]}
																											callback={(value) => {
																												let dataValue = (varb.data_type === 'integer' && value ? onlyNumeric(value) : value) 
																												setState(prevState => ({ ...prevState, [varb.variable+"_"+item]: dataValue }))
																											}}
																											onBlurAction={true}
																											onBlurCallback={() => {
																												setState(prevState => ({ ...prevState, onChangeInput: true }))
																											}}
																											placeholder={'Enter'}
																											classDetails={{border: "border-b border-NeutralGray-600", padding: 'p-1', width:'min-w-32', rounded: ' '}}
																										/>
																								: 
																									<span className={`mx-1 mt-2 text-`}>{state[varb.variable+"_"+item]}</span>
																							: varb.component === "value_object_list" ?
																								<React.Fragment>
																								<div className="break"></div>
																								<ObjectListInputs
																									masterData={props.masterData}
																									item={item}
																									varb={varb}
																									viewMode={props.viewMode}
																									objInputChange={(inputValues) => valueObjListFunction(inputValues, varb, item)}
																								/>
																								</React.Fragment>
																							: varb.component === "timerange" ?
																								!props.viewMode ?
																									<input 
																										type="text" 
																										className={`bg-transparent border-b border-NeutralGray-600 px-2 py-1 text-black min-w-32`}
																										style={{minHeight: "38px"}}
																										placeholder={"HH:MM-HH-MM"}
																										value={state[varb.variable+"_"+item]}
																										maxLength="11"
																										onKeyDown={e => onKeyDownTimeRange(e, e.target.value, varb.variable+"_"+item)}
																										onChange={e => validateTimeRange(e.target.value, varb.variable+"_"+item)}
																									/>
																								: 
																									<span className={`mx-1 mt-2 text-`}>{state[varb.variable+"_"+item]}</span>
																							: varb.component === "daytimerange" ?
																								!props.viewMode ?
																									<React.Fragment>
																									<div className='ml-2 self-end'>
																										<SelectOption
																											label={""}
																											fields={["value", "label"]}
																											options={state.day_options ? state.day_options : []}
																											selectedValues={state["day_"+item] ? state["day_"+item] : ''}
																											callbackMultiSelect={(value) => {
																													setState(prevState => ({ ...prevState, ["day_"+item]: value, onChangeInput: true }))
																											}}
																											singleSelection={true}
																											dropdownWidth={'min-w-40'}
																											classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
																										/>
																									</div>
																									<input 
																										type="text" 
																										className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																										style={{minHeight: "38px"}}
																										placeholder={"HH:MM-HH-MM"}
																										value={state["time_"+item]}
																										maxLength="11"
																										onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "time_"+item)}
																										onChange={e => validateTimeRange(e.target.value, "time_"+item)}
																									/>
																									</React.Fragment>
																								: 
																									<React.Fragment>
																										<span className={`mx-1 mt-2 text-`}>
																											{state["day_"+item] && state.day_options && state.day_options.filter(e => e.value === (state["day_"+item])).length ? state.day_options.filter(e => e.value === (state["day_"+item]))[0].label : <span className="placeholder">Select</span>}
																										</span>
																										<span className={`mx-1 mt-2 text-`}>{state["time_"+item]}</span>
																									</React.Fragment>
																							: varb.component === "tagging_component" ?
																								!props.viewMode ?
																									<React.Fragment>
																										<div className='min-w-32 mr-2' 
																											onClick={(event) => {
																												if(!state["showTagKey_"+index+'_'+rIndex]) {
																													event.preventDefault();
																													handleChildClick(event, 'child', 'singleDropDown', "showTagKey_"+index+'_'+rIndex)
																												}
																											}}
																										>
																											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["tag_key_"+item] ? 'text-black' : 'text-lightGray'}`}>
																												{state["tag_key_"+item] ? state["tag_key_"+item] : 'Select'}
																												<Icon icon="icon-park-solid:down-one" className={`${state["tag_key_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																											</p>
																											{state["showTagKey_"+index+'_'+rIndex] ?
																												<MultiSelectSection
																													fields={["value", "label"]}
																													options={tagKeys ? tagKeys : []}
																													selectedValues={state["tag_key_"+item] ? state["tag_key_"+item] : ''}
																													callbackMultiSelect={(value) => {
																														if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																															setState(prevState => ({ ...prevState, ["tag_key_"+item]: value, ["tagValues_"+item]: [], ["tagValue_"+item]: [] }))
																														} else {
																															value.preventDefault()
																															handleChildClick(value, "search", 'singleDropDown', "")
																														}
																													}}
																													singleSelection={true}
																													hideSearch={false}
																													topClass={'auto'}
																													widthClass={'minWidth220'}
																													removeTopOptions={true}
																												/>
																											: null}
																										</div>
																										<div className='min-w-32 mr-2'
																											onClick={(event) => {
																												event.preventDefault();
																												handleChildClick(event, 'child', 'muliselectDropDown', "tag_doprdown_"+index+'_'+rIndex)
																											}}
																										>
																											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["tagValue_"+item] && state["tagValue_"+item].length ? 'text-black' : 'text-lightGray'}`}>
																											{state["tagValue_"+item] && state["tagValue_"+item].length ? state["tagValue_"+item].length+' Selected' : 'Select'}
																												<Icon icon="icon-park-solid:down-one" className={`${state["tagValue_"+item] && state["tagValue_"+item].length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																											</p>
																											{state['tag_doprdown_'+index+'_'+rIndex] ?
																												<MultiSelectSection
																													// fields={["value", "label"]}
																													options={state["tagValues_"+item] ? state["tagValues_"+item] : []}
																													selectedValues={state["tagValue_"+item] ? state["tagValue_"+item] : ''}
																													callbackMultiSelect={(value) =>  {
																														setState(prevState => ({ ...prevState, ["tagValue_"+item]: value }))
																													}}
																													singleSelection={false}
																													hideSearch={false}
																													topClass={'auto'}
																													widthClass={'minWidth220'}
																													removeTopOptions={true}
																												/>
																											: null}
																										</div>
																										<span className={`far fa-plus cursor-pointer f18 mt-4 mb-1 self-center mr-4`} onClick={() => addNewTag(varb.variable, item)}></span>
																										{state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length ? 
																											state[varb.variable+"_"+item].filter(e => e.value !== "All").map((tag, tggIndex) => {
																												return(
																													<span key={'tgg_'+tggIndex} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{tag.key + (tag.value ? " : "+tag.value : "")}
																														<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeTagSection(varb.variable, item, tag) } />
																													</span>
																												)
																											})
																										: null}
																									</React.Fragment>
																								: 
																									state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length ? 
																									state[varb.variable+"_"+item].filter(e => e.value !== "All").map((tag, tagIndex) => {
																										return(
																											<span key={'tagg_'+tagIndex} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-">{tag.key +" : "+tag.value}</span>
																										)
																									})
																								: null
																							: null
																						:
																							!props.viewMode ?
																								<p className={`mb-0 mr-1 self-center text-base font-medium ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-issues" : "text-black"}`}>{row.string}</p>
																							:
																								<p className={`mb-0 mr-1 self-center ${props.selectedModeType === varb.mode ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>{row.string}</p>
	
																						}
																						</React.Fragment>
																					)
																				})
																			: null}
																		</div>
																	: null}
																	</React.Fragment>
																)
															})
														: null}
													</div>
												)
											})
										: null}
							
										{state.actionDetails && state.actionDetails.length ?
											<div className="rounded bg-GhostWhite p-3 mt-5">
												<p className="mb-0 text-lg text-purple3 cursor-pointer">Rule Action</p>
												<div className={`flex flex-wrap mb-2`}>
													<p className={`mb-0 mt-2 self-center`}>Select Action</p>
													<div className='min-w-32 mr-2' 
														onClick={(event) => {
															if(!state["showAction_"+item]) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "showAction_"+item)
															}
														}}
													>
														<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["selected_action_"+item] ? 'text-black' : 'text-lightGray'}`}>
															{state["selected_action_"+item] ? state["selected_action_"+item] : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state["selected_action_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
														</p>
														{state["showAction_"+item] ?
															<MultiSelectSection
																fields={["action", "action"]}
																options={state.actionDetails ? state.actionDetails : []}
																selectedValues={state["selected_action_"+item] ? state["selected_action_"+item] : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																		setState(prevState => ({ ...prevState, 
																			["selected_action_"+item]: value,
																			["action_processor_rule_id_"+item]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "",
																			onChangeInput: true
																		}))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}
																singleSelection={true}
																hideSearch={false}
																topClass={'auto'}
																widthClass={'minWidth220'}
																removeTopOptions={true}
															/>
														: null}
													</div>
												</div>
												
												{state["selected_action_"+item] ?
													<div className={`flex flex-wrap mb-2 ml-5`}>
														<p className={`mb-0 mt-2 self-center`}>Apply Method</p>
														<div className='min-w-32 mr-2' 
															onClick={(event) => {
																if(!state["showMethod_"+item]) {
																	event.preventDefault();
																	handleChildClick(event, 'child', 'singleDropDown', "showMethod_"+item)
																}
															}}
														>
															<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["apply_method_"+item] ? 'text-black' : 'text-lightGray'}`}>
																{state["apply_method_"+item] && props.actionMethods ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : 'Select'}
																<Icon icon="icon-park-solid:down-one" className={`${state["apply_method_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
															</p>
															{state["showMethod_"+item] ?
																<MultiSelectSection
																	fields={["value", "label"]}
																	options={props.actionMethods ? props.actionMethods : []}
																	selectedValues={state["apply_method_"+item] ? state["apply_method_"+item] : ''}
																	callbackMultiSelect={(value) => {
																		if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																			setState(prevState => ({ ...prevState, ["apply_method_"+item]: value, ["schedule_"+item]: "", onChangeInput: true }))
																		} else {
																			value.preventDefault()
																			handleChildClick(value, "search", 'singleDropDown', "")
																		}
																	}}
																	singleSelection={true}
																	hideSearch={false}
																	topClass={'auto'}
																	widthClass={'minWidth220'}
																	removeTopOptions={true}
																/>
															: null}
														</div>
														{state["apply_method_"+item] && state["apply_method_"+item] !== "immediately" ?
															<React.Fragment>
															<p className={`mb-0 ml-1 mr-2 self-center`}>Schedule</p>
															{state["apply_method_"+item] === "run_at" ?
																<input 
																	type="text" 
																	className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																	style={{minHeight: "38px"}}
																	placeholder={"HH:MM:SS"}
																	value={state["schedule_"+item]}
																	maxLength="8"
																	onKeyDown={e => onKeyDownTime(e, e.target.value, "schedule_"+item)}
																	onChange={e => validateTime(e.target.value, "schedule_"+item)}
																/>
															: state["apply_method_"+item] === "run_after" ?
																<div className="flex">
																	<input 
																		type="text" 
																		className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																		style={{minHeight: "38px"}}
																		placeholder={""}
																		value={state["schedule_"+item]}
																		maxLength="2"
																		onChange={e => setState(prevState => ({ ...prevState, ["schedule_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
																	/>
																	<div className='min-w-32 mr-2' 
																		onClick={(event) => {
																			if(!state["showTimeType_"+item]) {
																				event.preventDefault();
																				handleChildClick(event, 'child', 'singleDropDown', "showTimeType_"+item)
																			}
																		}}
																	>
																		<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["time_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
																			{state["time_type_"+item] && state.timeTypeOptions ? state.timeTypeOptions.filter(e => e.value === state["time_type_"+item])[0].label : 'Select'}
																			<Icon icon="icon-park-solid:down-one" className={`${state["time_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																		</p>
																		{state["showTimeType_"+item] ?
																			<MultiSelectSection
																				fields={["value", "label"]}
																				options={state.timeTypeOptions ? state.timeTypeOptions : []}
																				selectedValues={state["time_type_"+item] ? state["time_type_"+item] : ''}
																				callbackMultiSelect={(value) => {
																					if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																						setState(prevState => ({ ...prevState, ["time_type_"+item]: value, onChangeInput: true }))
																					} else {
																						value.preventDefault()
																						handleChildClick(value, "search", 'singleDropDown', "")
																					}
																				}}
																				singleSelection={true}
																				hideSearch={false}
																				topClass={'auto'}
																				widthClass={'minWidth220'}
																				removeTopOptions={true}
																			/>
																		: null}
																	</div>
																</div>
	
															: null}
															</React.Fragment>
														: null}
													</div>
												: null}
											</div>
										: null}
									</div>
									{props.viewMode && !index ?
										<React.Fragment>
											<div className={`flex flex-wrap w-20 self-start justify-end mt-2`}>
												{state["tags"] && state["tags"].length ?
													state["tags"].map((tag, taIndex) => {
														return(
															tag !== "" ?
																<span key={'tag_view_'+taIndex} className={`flex border border-lightGray px-2 py-1 mr-2 mt-2 self-center text- mb-2`} > {tag} </span>
															: null
														)
													})
												: null}
											</div>
										</React.Fragment>
									: null}
								</div>
								{props.viewMode && state["selected_action_"+item] && state["selected_action_"+item] !== "" ?
									<div className="rounded bg-GhostWhite p-3 ml-4 mt-2">
										<div className={`flex flex-wrap mb-2`}>
											<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}>Selected Action <span className="text-black mx-1">{state["selected_action_"+item]}</span></span>
											{state["apply_method_"+item] && state["apply_method_"+item] ?
												<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{props.actionMethods.filter(e => e.value === state["apply_method_"+item]) && props.actionMethods.filter(e => e.value === state["apply_method_"+item]).length ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : ""}</span></span>
											: null}
											{state["schedule_"+item] && state["schedule_"+item] ?
												<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{state["schedule_"+item]} {state["time_type_"+item] !== "" ? <span className="ml-1">{state["time_type_"+item]}</span>: null}</span></span>
											: null}
										</div>
									</div>
								: null}
							</div>
						: null}
					</div>
					</React.Fragment>
				)
			})}
		</div>
	)
}

export default DynamicServices
