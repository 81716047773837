/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getAlertsDailyTrend } from '../../../actions/Collider/AlertAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime } from '../../../utils/utility'
import ApexBarChart from '../../common/charts/ApexBarChart';
import AlertsDashboard from './AlertsDashboard';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import FilterSection from '../../common/FiltersSection';
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef() 
    
    const [state, setState] = useState({
        pageType: "Events",
        selectedDuration: 'currentMonth',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'duration'],
        statusColor: {
            "Critical": "#A42117",
            "High": '#2D60A6',
            "Medium": '#7551C2'
        }
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true,
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, alertSeries: [],  alertOptions: {}, showLoading: true, callAlertsSection: true, callSearch: false
            }))
    
            let obj = {}
            obj.start_time = state.datePickerStartDate
            obj.end_time = state.datePickerEndDate
            obj.selectedProvider = state.selectedProvider
            obj.selectedProjectTags = state.selectedProjectTags
            obj.selectedAccount = state.selectedAccount
            obj.selectedRegion = state.selectedRegion
            dispatch(setHpcPropsDetails('hpcAlertPageFilters', obj))
            dispatch(setHpcPropsDetails('alertDetails', {}))

            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)

        }
    }, [dispatch, state.callSearch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        if(state.callAlertsSection) {
            setState(prevState => ({ ...prevState, callAlertsSection: false }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase();    
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            dispatch(getAlertsDailyTrend(params))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}
                        setState(prevState => ({ ...prevState, alertsDailyTrend: results }))
                    }
                }) 
            }
    }, [dispatch, state.callAlertsSection, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        let graphDetails = state.alertsDailyTrend
        let eventCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            eventCount = graphDetails.data.count.reduce((a, b) => a + b, 0)   
        }

        let graphData = {}
        if(graphDetails && graphDetails.labels) {
            graphData.labels = graphDetails.labels
            graphData.data = graphDetails.data.count ? graphDetails.data.count : []
        }

        setState(prevState => ({ ...prevState, eventCount, graphData }))
    }, [state.alertsDailyTrend, state.datePickerStartDate, state.datePickerEndDate])
    
    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold pl-4">Alerts</p>
                        <p className="text-white text-base pl-4">Consolidated view of hpc alerts</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            
            <div className='p-2 min-h-screen overflow-auto'>
                <div className='bg-white shadow p-6 rounded-2xl border border-DeepPurpleTints-600'>
                    <div className='flex pb-4'>
                        <p className='text-[22px] font-bold uppercase'>Overview</p>
                        <p className='mb-0 self-center font-light text-sm ml-2'>Showing data for
                            <span className="font-bold ml-1">
                                {state.datePickerStartDate ?  momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY HH:mm')  : ""}
                            </span>
                            <span className="ml-2">(local time)</span>
                        </p>
                    </div>
                    <div className={`${!state.minimizeGraphSection && state.alertSeries && state.alertSeries.length ? '' : 'mt-4'} ${(state.minimizeGraphSection && state.pageType !== "Events" ? "displayNone" : "") }`}>

                                <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 shadow rounded-2xl h-full'>
                                <div className="flex justify-between items-center mt-4">
                                    <p className='text-lg font-medium text-black'>Alerts Trend</p>
                                </div>
                                        {state.graphData && Object.entries(state.graphData).length ?
                                            <ApexBarChart
                                                graphData={state.graphData}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={false}
                                                hideYaxisLine={true}
                                                xaxis={true}
                                                xaxisFormat={'date'}
                                                xaxisLabel={true}
                                                gridXaxisBorder={true}
                                                // showTitle={true} //xaxis title
                                                axisLabelColor={'#495057'}
                                                paddingLeft={0}
                                                legend={false}
                                                legendPostion={'bottom'}
                                                legendHorizontalAlign={'center'}
                                                legendUseSeriesColor={true}
                                                stacked={true}
                                                height={350}
                                                horizontal={false}
                                                barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={'25%'}
                                                gradient={false}
                                                gradientColor={['#009fdf', '#CD5A9F']}
                                                hideTooltipValue={false}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#E6E6E6']}
                                                showBackgroundBarColors={true}
                                                className={'transparentTooltip'}
                                                dataLabels= {false}
                                                dataLabelsTextAnchor = {'end'}
                                                colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                dataLabelsOffsetX={0}
                                                chartDistributed={true}
                                                dataPointSelection={false}
                                            />
                                        : null}
                                </div>
                    </div>
                </div>
                <AlertsDashboard 
                    loading={() => this.setState({ showLoading: false })}
                    onChangePageType={(pageType) => this.onChangePageType(pageType)}
                    pageTypes={'Alerts'}
                />
            </div>
        </div>
    )
}

export default LandingPage