
import React, { useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../common/Form/Button';
import { Store as CommonNotification } from 'react-notifications-component';
import { getUploadUrl, uploadFilev3, uploadFilev4, uploadFilev4Status } from '../../../../actions/files/FileAction';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';

const FileUploadModal = ({ isOpen, toggle, setModalIsOpen, handleFileBrowserRefresh, state }) => {  
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadLoading, setShowUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  const dispatch = useDispatch();

  // Limit state selection to necessary data
  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);

  const CHUNK_SIZE = 128 * 1024 * 1024; // 128MB per chunk

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getUploadFilev4Status = async (response, params) => {
    if (!response.upload_id) {
      return response.message;
    }
  
    params.upload_id = response.upload_id;
  
    while (true) {
      const v4Response = await dispatch(uploadFilev4Status(params)); // Await response
  
      if (!v4Response) {
        throw new Error('Failed to fetch upload status.');
      }
  
      // Check the upload status
      if (v4Response.upload_status === 'Completed' || v4Response.upload_status === 'Failed') {
        return v4Response; // Exit the loop and return the response
      }
  
      // Optional: Add a delay to avoid rapid polling
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  const handleMultiFileUpload = async () => {
    if (!file) {
      setUploadMessage("Please select a file to upload.");
      setShowUploadLoading(false);
      return;
    }

    try {
      setUploadMessage("Starting multipart upload...");
      setShowUploadLoading(false);

      const totalParts = Math.ceil(file.size / CHUNK_SIZE);

      const uploadIdParams = {
        provider: state.selectedProvider.toLowerCase(),
        account_id: state.selectedAccount,
        region: state.selectedRegion,
        cluster_name: state.selectedClusters,
        initiate_upload: true,
        file_name: file.name,
      };

      const uploadIdResponse = await dispatch(getUploadUrl(uploadIdParams));
      const UploadId = uploadIdResponse?.upload_id;


      if (!UploadId) {
        setUploadMessage("Uploading failed...");
        throw new Error("Upload ID missing from response.");
      }

      setUploadMessage("Uploading parts...");
      setShowUploadLoading(true);

      const uploadedParts = [];
      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const presignedParams = {
          provider: state.selectedProvider.toLowerCase(),
          account_id: state.selectedAccount,
          region: state.selectedRegion,
          cluster_name: state.selectedClusters,
          upload_id: UploadId,
          part_number: partNumber,
          file_name: file.name,
        };

        const presignedUrlsResponse = await dispatch(getUploadUrl(presignedParams));
        const presignedUrl = presignedUrlsResponse?.url;

        if (!presignedUrl) {
          setUploadMessage("Uploading failed in presignedUrls...");
          throw new Error("Presigned URL missing from response.");
        }

        const response = await axios.put(presignedUrl, chunk, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        const eTag = response.headers.etag;

        if (!eTag) {
          throw new Error("ETag missing from response.");
        }

        uploadedParts.push({ ETag: eTag.replace(/"/g, ""), PartNumber: partNumber });

        const progress = Math.round((partNumber / totalParts) * 100);
        setUploadProgress(progress);
      }

      const uploadParams = {
        provider: state.selectedProvider.toLowerCase(),
        account_id: state.selectedAccount,
        region: state.selectedRegion,
        cluster_name: state.selectedClusters,
        upload_id: UploadId,
        parts: uploadedParts,
        file_name: file.name,
        complete_upload: true,
      };
      await dispatch(getUploadUrl(uploadParams));

      setUploadMessage("Completing multipart upload...");

      //transfer file to the user selected path
      let currentPath = currentLocation.path ? currentLocation.path : ''
      let uploadTransferParams = {
        provider: state.selectedProvider.toLowerCase(),
        account_id: state.selectedAccount,
        region: state.selectedRegion,
        cluster_name: state.selectedClusters,
        file_name: file.name,
        path: currentPath
      }
      let v4Response = await dispatch(uploadFilev4(uploadTransferParams))
      let v4StatusResponse = await getUploadFilev4Status(v4Response, uploadTransferParams)

      let messageInfo = v4StatusResponse && v4StatusResponse.message ? v4StatusResponse.message : (v4StatusResponse.upload_status === 'Completed' ? 'Upload Completed' : 'Upload Failed')
      let messageType = v4StatusResponse && v4StatusResponse.status ? 'success' : 'danger'

      setUploadMessage("Upload Completed...");
      setShowUploadLoading(false);
      setModalIsOpen(false)
      setUploadProgress(0);
      setFile(null);
      setUploadMessage(false);
      document.getElementById("fileInput").value = "";
      handleFileBrowserRefresh();

      CommonNotification.addNotification({
        //title: 'Wonderful!',
        message: messageInfo,
        type: messageType,
        insert: 'top',
        container: 'top-center',
        // animationIn: ['animate__animated', 'animate__fadeIn'],
        // animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
        }
      })
    } catch (error) {
      setUploadMessage("Upload failed. Please try again.");
      setUploadProgress(0);
      setShowUploadLoading(false);
    }
  };

  const handleSingleFileUpload = async () => {
    setShowUploadLoading(true)
    setUploadMessage("")
    if (!file) {
      setShowUploadLoading(false)
      setUploadMessage("Please select a file to upload")
      return;
    }

    setUploadMessage("Starting upload...");

    try {
      // Generate pre-signed URL
      let params = {
        provider: state.selectedProvider.toLowerCase(),
        account_id: state.selectedAccount,
        region: state.selectedRegion,
        cluster_name: state.selectedClusters,
        file_name: file.name
      }

      //getting presignedUrl to upload
      // const url = endPointUrl + GET_UPLOAD_URL;
      const response = await dispatch(getUploadUrl(params));
      // const response = await axios.post(url, params, config)

      if (response?.url) {
        //upload file to to s3
        const presignedUrl = response.url;
        try {
          // Upload the file using the presigned URL
          await axios.put(presignedUrl, file, {
            headers: {},
            
            onUploadProgress: (progressEvent) => {
              // Update progress state
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
              dispatch(setCommonPropsDetails('uploadProgressBar', progress))
            },
          });

          //transfer file to the user selected path
          // const uploadUrl = endPointUrl + UPLOAD_FILE_V3;
          let currentPath = currentLocation.path ? currentLocation.path : ''
          let uploadParams = {
            provider: state.selectedProvider.toLowerCase(),
            account_id: state.selectedAccount,
            region: state.selectedRegion,
            cluster_name: state.selectedClusters,
            file_name: file.name,
            path: currentPath
          }
          let uploadResponse = await dispatch(uploadFilev3(uploadParams));
          // let uploadResponse = await axios.post(uploadUrl, uploadParams, config)

          let messageInfo = uploadResponse?.data && uploadResponse.data.message ? uploadResponse.data.message : ''
          let messaeType = uploadResponse && uploadResponse.data && uploadResponse.data.status ? 'success' : 'danger'
          
          //reseting the states
          setShowUploadLoading(false)
          setUploadMessage("")
          setUploadProgress(0); // Reset progress after successful upload
          setModalIsOpen(false)
          document.getElementById("fileInput").value = "";
          setFile(null); // Reset file after upload
          handleFileBrowserRefresh()
          dispatch(setCommonPropsDetails('uploadProgressBar', 0))

          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: messageInfo,
            type: messaeType,
            insert: 'top',
            container: 'top-center',
            dismiss: {
              duration: 5000,
            }
          });
        } catch (error) {
          setShowUploadLoading(false)
          setUploadMessage("File upload failed. Please try again.")
          setUploadProgress(0); // Reset progress on error
          // alert("File upload failed. Please try again.");
        }
      } else {
        setShowUploadLoading(false)
        setUploadMessage("File upload failed. Please try again.")
        throw new Error("Failed to generate pre-signed URL.");
      }
    } catch (error) {
      setShowUploadLoading(false)
      setUploadMessage("File upload failed. Please try again.")
    }
  };

  return (
     <div id="popup-modal" tabIndex="-1" className={`flex ${isOpen ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
      <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow-lg border border-NeutralGray-400 dark:bg-gray-700">
              <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => {
                  setUploadProgress(false)
                  setModalIsOpen(!isOpen)
                  setShowUploadLoading(false)
                  setUploadMessage("")
                  setFile(null)
                  document.getElementById("fileInput").value = "";
              }}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
              </button>
              
              <div className="p-4 md:p-5 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
                
                <div>
                  <p className='text-lg font-semibold pb-2'>Upload File</p>
                  
                  {uploadMessage && <p className='text-base text-ferrariRed-600 pb-2'>{uploadMessage}</p>}

                  <input type="file" id="fileInput" onChange={handleFileChange} />

                  <div className='flex justify-center mt-5'>
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                        label={'Upload'}
                        loading={showUploadLoading ? true : false}
                        callback={() => {
                          if(!showUploadLoading) {
                            let size = file && file.size ? Math.ceil(file.size/(1024 * 1024)) : 0
                            if(size > 1024) {
                              handleMultiFileUpload()
                            } else {
                              handleSingleFileUpload()
                            }
                          }
                        }}
                    />
                  </div>
                  {uploadProgress > 0 && <p className='text-sm text-center mt-2'>Upload Progress: {uploadProgress}%</p>}
                  <div className='flex justify-center'>
                    {uploadProgress > 0 && (
                      <div className="relative w-1/2  mt-2 h-4 bg-gray-300 rounded-lg">
                        {/* Bar */}
                        <div
                          className="absolute top-0 h-4 bg-green-600 rounded-lg"
                          style={{ width: uploadProgress+"%" }} // Adjust based on progress
                        ></div>
                      </div>
                    )}
                     {/* {uploadProgress > 0 && (
                      <div className='mt-5'>
                        <p className='text-sm'>Upload Progress: {uploadProgress}%</p>
                        <progress value={uploadProgress} max="100" />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
};

export default FileUploadModal;