/**
 * @exports
 * @file metricsAction.js
 * @author prakash
*/
import ApiCalls from '../components/ApiCalls'
import {
    GET_MONITORING_METRICS_BY_TREND,
	GET_MONITORING_METRICS_BY_TREND_V2,
	GET_SRE_DETAILS,
	GET_PEAK_DIP_DETECTION_RESULTS
} from '../config'


/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} return
 * @author Prakash 
*/
export const getMonitoringMetricsByTrend = (params) => {
	return async () => {		
		try {
			const url = GET_MONITORING_METRICS_BY_TREND;
			const response = await ApiCalls.post(url, params);
			return response && response.data
		} catch (error) {
			return error?.response?.data || error;
		}
	}
}

/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} return
 * @author Prakash 
*/
 export const getMonitoringMetricsByTrendV2 = (params) => {
	return async () => {		
		try {
			const url = GET_MONITORING_METRICS_BY_TREND_V2;
			const response = await ApiCalls.post(url, params);
			return response && response.data
		} catch (error) {
			return error?.response?.data || error;
		}
	}
}

/**
 * Action to get-peak-dip-detection-results
 * @param {Object} body
 * @param {Function} return
 * @author Prakash 
*/
export const getPeakDipDetectionResults = (params) => {
	return async () => {		
		try {
			const url = GET_PEAK_DIP_DETECTION_RESULTS;
			const response = await ApiCalls.post(url, params);
			return response && response.data
		} catch (error) {
			return error?.response?.data || error;
		}
	}
}

/**
 * Action to get sre details 
 * @param {Object} body
 * @param {Function} return
 * @author Prakash 
*/
 export const getSreDetails = (params) => {
	return async () => {		
		try {
			const url = GET_SRE_DETAILS;
			const response = await ApiCalls.post(url, params);
			return response && response.data
		} catch (error) {
			return error?.response?.data || error;
		}
	}
}