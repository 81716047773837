/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 26/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { getAccountNameFromId, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility';
import { listLifecyclePolicies, deleteLifecyclePolicies } from '../../../actions/CatalogAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchWithHiddenInput';
import Button from '../../common/Form/Button';
import ViewSidePanel from './ViewSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import FilterSection from '../../common/FiltersSection';
import PageLoading from '../../common/PageLoading';
import { CSVLink } from 'react-csv';

const LandingPage = ()  => {
    const clickOutside = useRef()

    const [state, setState] = useState({
        showLoading: true,
        topSectionFilters: ['provider', 'account', 'region', 'lifecycle_policy_type'],
        selectedPolicy: "Asset",
        dynamicHeaders: [],
    });

    const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation()
    const currentPath = location.pathname;

    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedPolicy: filter.selectedPolicy ? filter.selectedPolicy : '',
            callSearch: true,
            showLoading: true
        }))
    }

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }


    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, dynamicHeaders: [], lifeCyclePolicies: [], filteredPolicies: [] }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            if(state.selectedPolicy) {
                params.policy_type = state.selectedPolicy
            }
            dispatch(listLifecyclePolicies(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, lifeCyclePolicies: response && response.results ? response.results : [], filteredPolicies: response && response.results ? response.results : [], showLoading: false, formHeaders: true }))
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedPolicy, state.selectedProvider, state.selectedRegion, state.selectedPolicy])

    const onClickDelete = useCallback((row) => {
        let params = {}
        params.policy_id = row.policy_id
        dispatch(deleteLifecyclePolicies(params))
            .then((response) => { 
                let messageType = "danger"
                if(response.status) {
                    messageType = 'success'
                }
                if(response.status) {
                    setState(prevState => ({ ...prevState, disableSubmit: false, callSearch: true }))
                }

                let message = response && response.message ? response.message : 'Error in deleting lifecycle policy '
                
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }, [dispatch])

    useEffect(() => {
        if(state.formHeaders) {
            setState(prevState => ({ ...prevState, formHeaders: false }))
            let columns = []
            let dataRow = {}
            dataRow.Header = 'Date'
            // dataRow.accessor = 'created_at'
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                    <span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, "DD MMM YYYY HH:mm") : cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MMM YYYY HH:mm") : ""}</span>
                </React.Fragment>
            )
            dataRow.width = 180
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Provider'
            dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                <span className=''>
                    {cellInfo.row.original.provider.toUpperCase() + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
                </span>
                </React.Fragment>
            )
            dataRow.width = 250
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Resource'
            dataRow.accessor = 'resource_type'
            // dataRow.accessor = d => d.resource_type + ' : ' + d.service_name
            // dataRow.Cell = cellInfo => (
            //     <span className=''>{cellInfo.row.original.resource_type + ' : ' + cellInfo.row.original.service_name}
            //     </span>
            // )
            dataRow.width = 250
            columns.push(dataRow)

            if(state.selectedPolicy === 'Asset') {
                dataRow = {}
                dataRow.Header = 'Asset'
                dataRow.accessor = 'asset_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Service') {
                dataRow = {}
                dataRow.Header = 'Service'
                dataRow.accessor = 'service_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Tags') {
                dataRow = {}
                dataRow.Header = 'Tags'
                dataRow.accessor = 'tags'
                dataRow.Cell = cellInfo => (
                    <div className='flex'>
                        <span className=''>{cellInfo.row.original.tags && cellInfo.row.original.tags.length ? cellInfo.row.original.tags[0].key + ' : ' + cellInfo.row.original.tags[0].value : ''}
                        </span>
                        {cellInfo.row.original.tags && cellInfo.row.original.tags.length > 1 ?
                            <small className='ml-1 text-info'>more</small>
                        : null}
                    </div>
                )
                dataRow.width = 300
                columns.push(dataRow)
            }

            // if(state.actionsAllowed && (state.actionsAllowed.includes('update') || state.actionsAllowed.includes('delete'))) {
                dataRow  = {}
                dataRow.Header = 'Action'
                // dataRow.accessor = 'description'
                dataRow.Cell = cellInfo => (
                    <div className="flex">
                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                            <Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-black" onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))} />
                        : null}
                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                            <Icon icon="mdi:trash" width='16' height='16' className="mr-1 self-center cursor-pointer" onClick={(event) => {
                                event.stopPropagation();
                                onClickDelete(cellInfo.row.original)
                            }} />
                        : null}
                    </div>
                )
                dataRow.width = 150
                columns.push(dataRow)
            // }

            dataRow = {}
            dataRow.Header = 'Description'
            dataRow.accessor = 'description'		
            dataRow.width = 320
            columns.push(dataRow)
            

            setState(prevState => ({ ...prevState, dynamicHeaders: columns }))
        }
    }, [state.formHeaders, state.accounts, state.selectedPolicy, onClickDelete, state.actionsAllowed])

    useEffect(() => {
        let dataResult = state.filteredPolicies && state.filteredPolicies.length ? state.filteredPolicies : [];
        if(dataResult.length) {
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })

            setState(prevState => ({ ...prevState, tableHeaders: headers }))
        }
    }, [state.filteredPolicies])

    return (
        <div className='px-6'>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            {(state.selectedDetails && Object.entries(state.selectedDetails).length) || state.createLifecycle ? 
                <ViewSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showLoading: false, createLifecycle: false }))}
                    selectedDetails={state.createLifecycle ? {} : state.selectedDetails}
                    refreshlist={() => {
                        setState(prevState => ({...prevState, callSearch: true }))
                    }}
                    pageType={state.createLifecycle ? "Create" : "Modify"}
                />
            : null}
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="w-full text-white text-[28px] text-semibold">Manage Lifecycle</p>
						<p className="text-white text-base">Unified view of Metrics, Alerts, and Events in your Cloud HPC</p>
					</div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                            selectedPolicy={'Asset'}
                        />
                    </div>
                </div>
            </div>
            <div className='min-h-screen overflow-auto'>
                {!state.showLoading ?
                <div>
                    <p className='self-center text-black text-[28px] font-semibold'>Policy List</p>
                    <div className={`flex flex-wrap pb-6 justify-between`}> 
                        <div className="flex lg:w-3/5 w-full text-sm">
                            <p className='justify-start self-center text-sm'>Showing {state.filteredPolicies && state.filteredPolicies.length} of total {state.lifeCyclePolicies && state.lifeCyclePolicies.length} policies</p>
                        </div>
                        <div className='self-center'>
                            <div className='flex justify-end'>
                                {state.lifeCyclePolicies && state.lifeCyclePolicies.length ?
                                    <div className='self-center mr-2'>
                                        <Search
                                            data={state.lifeCyclePolicies ? state.lifeCyclePolicies : []}
                                            topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                            className={'rounded-full'}
                                            filteredData={(filteredPolicies) => setState(prevState => ({ ...prevState, filteredPolicies }))}
                                        />
                                    </div>
                                : null}
                                {state.actionsAllowed.includes('create')?
                                    <Button
                                        classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', margin: 'mx-4', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer'}}
                                        label={'Create Policy'}
                                        callback={() => {
                                            setState(prevState => ({ ...prevState, createLifecycle: true }))
                                        }}
                                    />
                                : null}
                                {state.filteredPolicies && state.filteredPolicies.length ?
                                    <div className='self-center' title="download">
                                        <CSVLink 
                                            data={state.filteredPolicies ? state.filteredPolicies : []} 
                                            headers={state.tableHeaders ? state.tableHeaders : []}
                                            filename={'policy-lists.csv'}
                                            className={'self-center'}
                                            target='_blank'
                                        >
                                            <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                        </CSVLink>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
                <div>
                    {state.filteredPolicies && state.filteredPolicies.length && !state.showLoading ? (
                        <ResizeableDarkThemeTable
                            columns={state.dynamicHeaders}
                            perPage={20}
                            dashboard={state.filteredPolicies && state.filteredPolicies.length ? true : false}
                            sortByColumn={''}
                            riskTooltip={[0]}
                            selectedColor={'bg-backGroundGray'}
                            onClickRow={tableRow => {}}
                            data={state.filteredPolicies}
                            selectedRecord={state.selectedTableRow}
                        />
                    ) : (!state.showLoading && state.filteredPolicies && !state.filteredPolicies.length) ||
                    (state.filteredPolicies && !state.filteredPolicies.length && !state.showLoading) ? (
                        <div className='flex justify-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    ) : null}
                    {state.showLoading ? (
                        <div className='flex justify-center m-4'>
                            <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default LandingPage