import React, { useState, useEffect, useRef } from 'react';
import { capitalizeAllLetter, capitalizeFirstLetter } from '../../../utils/utility';
import { Icon } from '@iconify/react';
import Search from '../../common/Search';

const ObjectKeyValueSelectOption = ({ fields, options, selectedValues, callbackMultiSelect, singleSelection, makeLabelUpperCase, dropdownWidth, addAllField, parentClass, classDetails, placeholder, hideSearch, disabled, deSelect }) => {
    const dropdownRef = useRef()

    const [state, setState] = useState({})

    useEffect(() => {
        setState(prevState => ({ ...prevState, options, filteredOptions: options, selectedValues }))
    }, [options, selectedValues, singleSelection])

    useEffect(() => {
        // if(addAllField) {
        //     let filteredOptions = options
        //     // if(fields && fields.length) {
        //     //     if(!filteredOptions.filter(e => e[fields[0]] === 'All').length) {
        //     //         let dataRow = {}
        //     //         dataRow[fields[0]] = 'All'
        //     //         dataRow[fields[1]] = 'All'
        //     //         filteredOptions.unshift(dataRow)
        //     //     }
        //     // } else {
        //     //     if(!filteredOptions.filter(e => e === 'All').length) {
        //     //         filteredOptions.unshift('All')
        //     //     }
        //     // }
        //     setState(prevState => ({ ...prevState, filteredOptions, addAllField: false }))
        // }
    }, [addAllField, options, fields, selectedValues])

    const handleOptionChange = (key, value) => {
        if(singleSelection) {
            selectedValues = value
            setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: false }))
        } else {
            if (selectedValues && selectedValues.includes(value)) {
                selectedValues = selectedValues.filter((item) => item !== value);
            } else {
                selectedValues.push(value);
            }

            setState(prevState => ({ ...prevState, selectedValues }));
        }

        callbackMultiSelect(selectedValues)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, dropdownOpen: false }))
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block h-fit flex flex-col cursor-pointer 
        ${parentClass && parentClass.margin ? parentClass.margin : ''} 
        ${parentClass && parentClass.selfOption ? parentClass.selfOption : 'self-end'} 
        ${dropdownWidth ? dropdownWidth : 'w-full'}`} ref={dropdownRef}>
            {/* <p className={`flex justify-between w-full shadow-sm text-left cursor-default focus:outline-none sm:text-sm
                ${classDetails && classDetails.common ? classDetails.common : ''}
                ${classDetails && classDetails.bg ? classDetails.bg : 'bg-white'} 
                ${classDetails && classDetails.rounded ? classDetails.rounded : 'rounded-lg'} 
                ${classDetails && classDetails.border ? classDetails.border : 'border border-NeutralGray-400'} 
                ${classDetails && classDetails.padding ? classDetails.padding : 'py-2.5 pl-3 pr-4'} 
                ${classDetails && classDetails.selectedFieldClass ? classDetails.selectedFieldClass : ''}                
            `} 
            onClick={toggleDropdown}>
                <span className={`truncate text-base text-NeutralGray-600`}>
                    {selectedValues && Array.isArray(selectedValues) ?
                        selectedValues.length > 0 ? selectedValues.length + ' Selected' : (placeholder ? placeholder : 'Select')
                    : selectedValues ?
                        options && fields && fields.length && fields[0] !== fields[1] ?
                            options.filter(e => e[fields[0]] === state.selectedValues).length ? 
                                options.filter(e => e[fields[0]] === state.selectedValues)[0][fields[1]] 
                            : (placeholder ? placeholder : 'Select')
                        :
                            state.selectedValues
                    : (placeholder ? placeholder : 'Select')}
                </span>
                <Icon icon="icon-park-outline:down" className={`text-NeutralGray-600 self-center`} width={24} height={24} />
            </p> */}
            <div className={`relative`}>
                <div className={`absolute bg-white mt-1 border border-byzantineBlue rounded-md shadow-[0_4px_6px_rgba(33,37,41,0.2),_0_0_1px_rgba(33,37,41,0.32)] z-10 
                    ${classDetails && classDetails.optionWidth ? classDetails.optionWidth : 'w-full'}
                    ${classDetails && classDetails.optionPosition}`}>
                    <div className="max-h-80 overflow-auto">
                        {!hideSearch && state.options && Object.entries(state.options).length > 5 ? 
                            <div className="p-4">
                                <Search
                                    data={state.options ? state.options : []}
                                    topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                    className={'w-full rounded-full'}
                                    filteredData={(filteredArray, searchText) => {
                                        setState(prevState => ({ ...prevState, filteredOptions: searchText !== "" ? filteredArray : state.options }))
                                    }}
                                />
                            </div>
                        : null}
                        <ul className="">
                            {state.filteredOptions && Object.entries(state.filteredOptions).length ?
                                Object.entries(state.filteredOptions).map(([key, value], index) => {
                                    return(
                                        <React.Fragment>
                                        {singleSelection && !index && deSelect ?
                                            <div className={`text-base hover:bg-water-600`} key={'drop_select_'+index}>
                                                <li className={`px-4 py-2 text-black`} onClick={(event) => handleOptionChange('')}>
                                                    <label className="inline-flex items-center">
                                                        <span className="ml-2 text-CadetGrey-600">Select</span>
                                                    </label>
                                                </li>
                                            </div>
                                        : null}
                                        <div className={`text-base hover:bg-water-600 ${!index ? '' : ''}`} key={'drop_'+index}>
                                            {!singleSelection ?
                                                <li className={`px-4 py-2 text-black ${state.selectedValues.includes(value) ? 'bg-water-600' : ''}`} onClick={() => handleOptionChange(key, value)}> 
                                                    <label className="inline-flex items-center">
                                                        <div className='w-4'>
                                                            <input
                                                                type="checkbox"
                                                                checked={state.selectedValues && state.selectedValues.includes(value) ? true : false}
                                                                onChange={() => {}}
                                                                className="form-checkbox cursor-pointer h-4 w-4 text-blue-600 option-checkbox"
                                                            />
                                                        </div>
                                                        <span className="ml-2 mb-1" onClick={() => handleOptionChange(key, value)}>
                                                            {makeLabelUpperCase === 'capitalizeFirstLetter'
                                                                ? capitalizeFirstLetter(key)
                                                                : makeLabelUpperCase === 'capitalizeAllLetter'
                                                                ? capitalizeAllLetter(key)
                                                                : key}
                                                        </span>
                                                    </label>
                                                </li>
                                            :
                                                <li className={`px-4 py-2 text-black ${state.selectedValues === value ? 'bg-water-600' : ''}`} onClick={(event) => handleOptionChange(key, value)}>
                                                    <label className="inline-flex items-center">
                                                        <span className="ml-2">
                                                            {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                                                capitalizeFirstLetter(key)
                                                            : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                                                capitalizeAllLetter(key)
                                                            :  
                                                                key
                                                            }
                                                        </span>
                                                    </label>
                                                </li>
                                            }
                                        </div>
                                        </React.Fragment>
                                    )
                                })
                            : 
                                <li className={`px-4 py-2 text-black`}>No Options</li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectKeyValueSelectOption