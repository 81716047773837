/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file RecommendationsLeftSection.js
 * @author Prakash // on 04/02/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import Search from '../../common/SearchWithHiddenInput'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId, getRegionName } from '../../../utils/utility'
import { setCostPropsDetails, getCostRecommendations } from  '../../../actions/cost/CostAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { useCallback } from 'react';
import { Icon } from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';
import PageLoading from '../../common/PageLoading';

const RecommendationsLeftSection = ({callSearch, selectedProvider, selectedAccount, selectedRegion}) => {
    const [state, setState] = useState({
        showLoading: true,
        expandMenuId: "",
        activeCollapseMenu: "",
        recommendationType: "All",

        startRecord: 0,
        perPage: 1,
        currentPage: 1,

        showBy: "Resource",
        selectedTab: "Consolidated",
        tabs: ["Consolidated", "Table"],
        callSearch: callSearch
    })
	const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(callSearch && state.callSearch === undefined){
            setState(prevState => ({ ...prevState, callSearch: callSearch }))
        }

    }, [callSearch])

    useEffect(() => {
        if (state.filteredObservedRecommendation && state.filteredObservedRecommendation.length > 0) {
            const firstRecommendation = state.filteredObservedRecommendation[0];
            if(firstRecommendation.detailView){
                expandCollapse(0, firstRecommendation.label, firstRecommendation.tag)
            }
        }
    }, [state.filteredObservedRecommendation]);

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
            params.provider = selectedProvider.toLowerCase();
            if(selectedAccount && selectedAccount.length && !selectedAccount.includes("All")) {
                params.account_id = selectedAccount
            }
            if(selectedRegion && selectedRegion.length && !selectedRegion.includes("All")) {
                params.region = selectedRegion
            }
            if(selectedProvider) {
                dispatch(getCostRecommendations(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, recommendedDetails: response, showLoading: false, expandMenuId: "", activeCollapseMenu: "", filteredResouceArray: response.checks ? response.checks : [], callSearch: false, formRecommendedData: true }))
                        }
                    })
                }
        }
    }, [state.callSearch, dispatch, selectedAccount, selectedProvider, selectedRegion]);

    const filterRecommendations = useCallback((type, selectedArray) => {
        let selectedRecommendationGroup = ""
        if(type !== "All") {
            if(type === state.selectedRecommendationGroup) {
                selectedArray = state.totalRecommendedData
            } else {
                selectedRecommendationGroup = type
            }
        }

        let observedRecommendations = []
        selectedArray.forEach((item, index) => {
            setState(prevState => ({ ...prevState, ["recommendation_label_"+index]: item.label, ["recommendation_data_"+index]: item.data, ["filtered_recommendation_data_"+index]: item.data }))
            let detailView = false
            if(item.data && item.data.length) {
                detailView = true
            }
            item.detailView = detailView
            observedRecommendations.push(item)
        })

        // observedRecommendations = _.orderBy(observedRecommendations, ["orderBy"], ["asc"])

        observedRecommendations = [
            ...observedRecommendations.filter(e => e.flag),
            ...observedRecommendations.filter(e => !e.flag && e.detailView),
            ...observedRecommendations.filter(e => !e.flag && !e.detailView),
        ];

        setState(prevState => ({ ...prevState, selectedRecommendationGroup, observedRecommendations, searchData: observedRecommendations, filteredObservedRecommendation: observedRecommendations }))
    }, [state.selectedRecommendationGroup, state.totalRecommendedData])

    useEffect(() => {
        if(state.formRecommendedData) {
            let groupedRecommendations = []
            let totalRecommendedData = []
            let tableViewData = []
            if(state.recommendedDetails && Object.entries(state.recommendedDetails).length) {
                let filteredData = state.recommendedDetails.checks.filter(e => e.data.length)  //remove the diagnotstics which has now data
                if(filteredData && filteredData.length) {
                    groupedRecommendations = _.chain(filteredData).groupBy("tag")
                    .map((value, key) => ({ 
                        label: key ? key : "Not Grouped", 
                        sub: _.orderBy(value, ["flag"], ["desc"]),
                        observedFlag: value.filter(e => e.flag).length 
                    })).value()

                    groupedRecommendations = _.orderBy(groupedRecommendations, ["observedFlag"], ["desc"])    
                }
        
                //for table View section
                state.recommendedDetails && state.recommendedDetails.checks && state.recommendedDetails.checks.forEach(row => {
                    if(row.data && row.data.length) {
                        row.data.forEach(item => {
                            item.label = row.label
                            item.tag = row.tag
                            // item.percentage_savings = row.percentage_savings
                            // item.price_unit = row.price_unit
                            // item.savings = row.savings
                            tableViewData.push(item)
                        })
                    }
                })
        
                //for table ViewSection
                if(groupedRecommendations && groupedRecommendations.length) {
                    totalRecommendedData = _.orderBy(filteredData, ["flag"], ["desc"])
                }
            }

            setState(prevState => ({ ...prevState, totalRecommendedData, groupedRecommendations, tableViewData, filteredTableArray: tableViewData, formRecommendedData: false }))

            filterRecommendations("All", totalRecommendedData)  
        }

    }, [state.formRecommendedData, state.recommendedDetails, filterRecommendations])

    useEffect(() => {
        if(state.selectedTab === "Table") {
            filterTableResults()
        }
    }, [state.selectedTab])

    const filterTableResults = (event) => {
        let filteredTableArray = state.tableViewData

        if(state.selectedRecommendationGroup && state.selectedRecommendationGroup !== "All") {
            filteredTableArray = filteredTableArray.filter(e => e.category === state.selectedRecommendationGroup)
        }

        if(state.searchTableText && state.searchTableText !== "") {
			filteredTableArray =
				filteredTableArray &&
				filteredTableArray.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        filteredTableArray = _.orderBy(filteredTableArray, ['start_time'], ['desc'])

        setState(prevState => ({ ...prevState, filteredTableArray }))
    }

    useEffect(() => {
        filterTableResults()
        filteredResourceTableResults()
    }, [state.selectedRecommendationGroup, state.searchTableText])

    useEffect(() => {
        if(state.showBy === "Resource" || state.showBy === "Service" ) {
            filterTableResults()
            filteredResourceTableResults()
        }
    }, [state.showBy])

    useEffect(() => {
        if(state.filteredTableArray && state.filteredTableArray.length && state.selectedTab === "Table" && state.showBy === "Resource") {
            onChangeRecommendationEvent(state.filteredTableArray[state.filteredTableArray.length-1].label, state.filteredTableArray[state.filteredTableArray.length-1].tag, state.filteredTableArray[state.filteredTableArray.length-1], 0, "", state.filteredTableArray[state.filteredTableArray.length-1].asset_id)
        }
    }, [state.filteredTableArray, state.selectedTab, state.showBy])

    const filteredResourceTableResults = (event) => {
        let filteredResouceArray = state.recommendedDetails && state.recommendedDetails.checks ? state.recommendedDetails.checks : []

        if(state.selectedRecommendationGroup && state.selectedRecommendationGroup !== "All") {
            filteredResouceArray = filteredResouceArray.filter(e => e.tag === state.selectedRecommendationGroup)
        }

        if(state.searchTableText && state.searchTableText !== "") {
			filteredResouceArray =
				filteredResouceArray &&
				filteredResouceArray.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        setState(prevState => ({ ...prevState, filteredResouceArray }))
    }

    useEffect(() => {
        if(state.filteredResouceArray && state.filteredResouceArray.length && state.selectedTab === "Table" && state.showBy === "Service") {
            onChangeServiceRecommendationEvent(state.filteredResouceArray[state.filteredResouceArray.length-1].label, state.filteredResouceArray[state.filteredResouceArray.length-1].tag, state.filteredResouceArray[state.filteredResouceArray.length-1])
        }
    }, [state.filteredResouceArray, state.selectedTab, state.showBy])

    useEffect(() => {
        filterResults()
    }, [state.searchText])

    const filterResults = () => {
        let recommendationType = state.recommendationType
        if(state.searchText) {
            recommendationType = state.searchText
        }
        let observedRecommendations = state.observedRecommendations

        if(recommendationType !== "All") {
            if(recommendationType === "observed") {
                observedRecommendations = observedRecommendations.filter(e => e.flag)
            } else {
                observedRecommendations = observedRecommendations.filter(e => !e.flag)
            }
        }

        if(state.searchText && state.searchText !== "") {
			observedRecommendations =
				observedRecommendations &&
				observedRecommendations.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        setState(prevState => ({ ...prevState, recommendationType, filteredObservedRecommendation: observedRecommendations }))
    }

	// function to search every object in array by recurseive
	const recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == "object") {
				return recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === "array") {
				return item.map((arrayElement, i) => recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

    const expandCollapse = (expandMenuId, label, tag) => {
        if(state.activeCollapseMenu !== expandMenuId) {
            onClickExpandMenu(expandMenuId)
        } else {
            let obj = {}
            obj.label = "assets_list"
            obj.eventGroup = ""
            // obj.data = state.recommendedDetails ? state.recommendedDetails : []
            obj.data = []
            dispatch(setCostPropsDetails("selectedRecommendedEvent", obj))
        }
        setState(prevState => ({ 
            ...prevState, 
            expandMenuId: expandMenuId === state.expandMenuId ? "" : expandMenuId, 
            activeCollapseMenu: expandMenuId === state.expandMenuId ? "" : expandMenuId,
            startRecord: 0,
            currentPage: 1,
        }))
    }

    const onClickExpandMenu = (expandMenuId) => {
        if(state.observedRecommendations && Object.entries(state.observedRecommendations).length) {
            state.observedRecommendations && state.observedRecommendations.forEach((item, index) => {
                setState(prevState => ({ 
                    ...prevState, 
                    ["recommendation_label_"+index]: item.category, 
                    ["recommendation_data_"+index]: item.data, 
                    ["filtered_recommendation_data_"+index]: item.data 
                }))

                if(index === expandMenuId) {
                    onChangeRecommendationEvent(item.label, item.tag, item.data[0], index, "recomm_"+expandMenuId+"_0", item.data[0].asset_id)
                }
            })
        }
    }

    const onChangeRecommendationEvent = (label, tag, data, index, selectedEvent, assetId) => {
        let assetDetails = {}

        if(data.asset_id && assetDetails.asset_id !== "") {
            if(!data.resource_type || data.resource_type === "") {
                data.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!data.service_name || data.service_name === "") {
                data.service_name = data.service_name ? data.service_name : ""
            }
            if(!data.provider || data.provider === "") {
                data.provider = data.provider ? data.provider : ""
            }
            if(!data.account_id || data.account_id === "") {
                data.account_id = data.account_id ? data.account_id : ""
            }
            if(!data.region || data.region === "") {
                data.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails
        obj.showBy = state.showBy
        dispatch(setCostPropsDetails("selectedRecommendedEvent", obj));

        setState(prevState => ({
            ...prevState,
            selectedRecommandation: selectedEvent
        }))
    }

    const onChangeServiceRecommendationEvent = (label, tag, checks) => {
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = checks.data
        obj.showBy = state.showBy

        dispatch(setCostPropsDetails("selectedResourceRecommendedEvent", obj))
    }

	const handleInput = e => {
		const { name, value } = e.target;

		setState(prevState => ({ ...prevState, [name]: value, showLoginError: false }))
	}
    return (
        <React.Fragment>
             <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>

            <div>
                <div className={`flex justify-between pt-8`}>
                    <ul className="flex flex-wrap gap-2.5 text-lg">
                        {state.tabs.map(tab => {
                            return(
                                <li key={tab} className={`flex px-8 py-5 border border-NeutralGray-600 rounded-t-xl ${state.selectedTab === tab ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: tab }))}>{tab}</li>
                            )
                        })}
                    </ul>
                    
                    {state.activeTab !== "Table" ? 
                        state.totalRecommendedData && state.totalRecommendedData.length ?
                            // <Search
                            //     // ID={"search_id_"+index}
                            //     data={state.filteredObservedRecommendation}
                            //     topClassName={'border border-NeutralGray-600 rounded-full self-center bg-white'}
                            //     className={'rounded-full'}
                            //     filteredData={(filteredArray) => {
                            //         setState(prevState => ({ ...prevState, 
                            //             ["filtered_recommendation_data_"+index]: filteredArray,
                            //             ["recommendation_count_"+index]: filteredArray.length   
                            //         }))
                            //     }}
                            // />
                            <div className={`flex w-2/5 h-1/2 self-center border border-NeutralGray-600 rounded-full bg-white`}>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`pl-2 text-black self-center`} width={40} height={40}/>
                                <div className={`w-full text-base items-center self-center`}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        name="searchText"
                                        className={`w-full self-center py-2.5 pl-2 rounded-full text-black`}
                                        value={state.searchText ? state.searchText : ""}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                        : null
                    :
                        state.tableViewData && state.tableViewData.length ?
                            <div className={`flex w-1/3 h-1/2 self-center border border-NeutralGray-600 rounded-full bg-white`}>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`pl-2 text-black self-center`} width={40} height={40}/>
                                <div className={`w-full text-base items-center self-center`}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className={`w-full self-center py-2.5 pl-2 rounded-full text-black`}
                                        value={state.searchTableText}
                                        onChange={e => 
                                            setState(prevState => ({ ...prevState, searchTableText: e.target.value }))
                                        }
                                    />
                                </div>
                            </div>
                        : null
                    }
                </div>
                <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-600 bg-white rounded-b-2xl rounded-tr-2xl py-8 px-6">
                    {state.groupedRecommendations && state.groupedRecommendations.length ?
                        <React.Fragment>
                        <div className="flex flex-wrap justify-between">
                            <p className='mb-0 text-base self-center w-2/3'>
                                Showing the observerd recommendations with
                                <span className={`ml-1 text-green-600`}>Savings </span>
                                <span className="ml-1">{state.recommendedDetails && state.recommendedDetails.price_unit ? " ("+ state.recommendedDetails.price_unit+")" : ""}</span> of {state.recommendedDetails && state.recommendedDetails.savings && state.recommendedDetails.savings}
                                <span className="ml-1 text-green-600">({state.recommendedDetails && state.recommendedDetails.percentage_savings && state.recommendedDetails.percentage_savings}%)</span> 
                            </p>
                            
                            {state.selectedTab === "Table" ? 
                                <div className="flex justify-end w-1/3">
                                    {/* <p className="mb-0 mr-2 mt-2 text-muted">show by</p> */}
                                    <div className='flex flex-col justify-start w-full pl-2'>
                                        <SelectOption
                                            label={"Show by"}
                                            options={["Resource", "Service"]}
                                            selectedValues={state.showBy ? state.showBy : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState,  showBy: value, searchTableText: "", selectedRecommendationGroup: "All"}))
                                            }}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}
                                            classDetails={{optionPosition: "right-0"}}
                                        />
                                    </div>
                                </div>
                            : null}
                        </div>
                        <div className="flex flex-wrap mt-4 gap-4 items-center lg:w-auto md:w-auto w-full">
                            {state.groupedRecommendations.map(item => {
                                    return(
                                        <div className={`mb-2 cursor-pointer pr-2`}>
                                            <div className={`p-2 rounded-lg border ${state.selectedRecommendationGroup === item.label ? "bg-green-600" : item.observedFlag ? "bg-white border-green-600" : "border-red-600"}`} onClick={() => filterRecommendations(item.label, item.sub)}>
                                                <p className={`text-base font-semibold w-full ${state.selectedRecommendationGroup === item.label ? "text-white" : item.observedFlag ? "text-green-600" : "border-red-600"}`}>{item.label && item.label !== "undefined" ? item.label : "Others"} 
                                                    <span className={`ml-2`}>({item.sub && item.sub.length})</span>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                        </React.Fragment>
                    : null}
                    <div className="flex w-full">
                        <div className={`overflow-y-auto w-full`}>
                            {state.alertListLoader ? 
                                <div className="flex justify-center m-4">
                                    {/* <Spinner className="text-center" color='dark' size="lg" /> */}
                                </div>
                            : state.selectedTab !== "Table" ? 
                                state.filteredObservedRecommendation && state.filteredObservedRecommendation.length ?
                                    state.filteredObservedRecommendation.map((item, index) => {
                                        return(
                                            <React.Fragment>
                                            {/* <div className={`${index ? "mt-4 p-4 border border-NeutralGray-800 rounded-2xl" : "mt-4 p-4 border border-NeutralGray-800 rounded-2xl"} ${state.expandMenuId === index ? "bg-NeutralGray-200" : ""}`}>  */}
                                            <div className={`mt-4 p-4 border border-NeutralGray-800 rounded-2xl bg-NeutralGray-200`}> 
                                                <div className={`px-2 flex justify-between self-center ${item.detailView ? "cursor-pointer" : ""}`} onClick={() => expandCollapse(index, item.label, item.tag)} data-toggle="collapse" data-target={"#collapse_"+index} aria-expanded="true" aria-controls={"collapse_"+index}>
                                                    <div className="font-semibold text-base flex">
                                                        <span>
                                                            {state.expandMenuId === index ?
                                                                <Icon icon={`iconoir:minus`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                            :
                                                                <Icon icon={`stash:plus-solid`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                            }
                                                        </span>
                                                        {item.label}
                                                    </div>
                                                    <div className="text-base flex">
                                                        <span className={`${item.flag ? "text-green-600" : "text-red-600"}`}>Savings <span className="ml-2 text-black">({item.price_unit ? item.price_unit : "USD"})</span> of {item.savings ? item.savings : 0} <span className="ml-2 text-black">({(item.percentage_savings ? item.percentage_savings : 0)}%)</span> </span>
                                                        {/* <span className={`far fa-exclamation-triangle ${item.flag ? "text-success" : "text-red-600"} ml-3`}></span> */}
                                                    </div>
                                                </div>

                                                {state.expandMenuId === index ?
                                                    <div className="mt-2">
                                                    
                                                            <div className={`p-3 bg-white rounded-2xl ${index !== state.observedRecommendations.length-1 ? "" : ""}`}>
                                                                <div id={"collapse_"+index} className={`${state.expandMenuId === index ? "flex" : "hidden"}`} aria-labelledby={"heading_"+index} data-parent="#accordionExample">
                                                                    <div className='flex flex-col'>
                                                                        <div className="flex justify-between my-4">
                                                                            <div className="flex self-center">
                                                                                <p className="text-small">Showing {state["filtered_recommendation_data_"+index] && state["filtered_recommendation_data_"+index].length} {item.label} </p>
                                                                            </div>
                                                                            <div className="w-1/2">
                                                                                <Search
                                                                                    ID={"search_id_"+index}
                                                                                    data={state["recommendation_data_"+index]}
                                                                                    topClassName={'border border-NeutralGray-600 rounded-full self-center bg-white'}
                                                                                    className={'rounded-full'}
                                        
                                                                                    filteredData={(filteredArray) => {
                                                                                        setState(prevState => ({ ...prevState, 
                                                                                            ["filtered_recommendation_data_"+index]: filteredArray,
                                                                                            ["recommendation_count_"+index]: filteredArray.length   
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            {state["filtered_recommendation_data_"+index] && state["filtered_recommendation_data_"+index].length ?
                                                                                state["filtered_recommendation_data_"+index].map((dig, i) => {
                                                                                    return(
                                                                                        <div className={`p-4 rounded-lg ${i ? "border-t border-NeutralGray-900" : ""} ${state.selectedRecommandation === "recomm_"+index+"_"+i ? "bg-NeutralGray-200 text-white disabled" : " text-white cursor-pointer"}`} onClick={() => onChangeRecommendationEvent(item.label, item.tag, dig, index, "recomm_"+index+"_"+i, dig.asset_id)}>
                                                                                            <p className="mb-0">
                                                                                                {dig.severity ?
                                                                                                    <span className={`mr-2 risk-${dig.severity ? dig.severity.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.severity)}</span>
                                                                                                : null}
                                                                                                {dig.event_time ?
                                                                                                    <span className="mb-0 mr-2 font-bold text-NeutralGray-800">{dig.event_time ? momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                                : null}
                                                                                                {dig.asset_name ?
                                                                                                    <span className="mb-0 mr-2 font-bold">{dig.asset_name}</span>
                                                                                                : null}
                                                                                                <span className="mb-0 small mr-1 text-NeutralGray-800">Resource</span>
                                                                                                {dig.account_id ?
                                                                                                    <span className="mb-0 text-NeutralGray-800">{' '+dig.account_id}</span>
                                                                                                : null}
                                                                                                {dig.region ?
                                                                                                    <span className="mb-0 text-NeutralGray-800">{' : '+dig.region}</span>
                                                                                                : null}
                                                                                                {dig.resource_type ?
                                                                                                    <span className="mb-0 text-NeutralGray-800">{' : '+(Array.isArray(dig.resource_type) ? dig.resource_type.join(', ') : dig.resource_type)}</span>
                                                                                                : null}
                                                                                                {dig.service_name ?
                                                                                                    <span className="mb-0 text-NeutralGray-800 mr-2">{' : '+dig.service_name}</span>
                                                                                                : null}
                                                                                                {dig.description ?
                                                                                                    <span className="mb-0 font-bold">{dig.description}</span>
                                                                                                : null}
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                    </div>
                                                : null}
                                            </div>
                                            </React.Fragment>
                                        )
                                    })
                                :
                                    <div className="flex justify-center m-4">
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                            :
                                state.showBy === "Resource" ?
                                    state.filteredTableArray && state.filteredTableArray.length ?
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: "Service",
                                                    accessor: d => d.resource_type+" : "+d.service_name,
                                                    width: 200
                                                },
                                                {
                                                    Header: "Category",
                                                    accessor: "category",
                                                    width: 150
                                                },
                                                {
                                                    Header: "Savings",
                                                    // accessor: d => d.additional_details && d savings+" ("+d.price_unit && d.price_unit+"%)"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                    Cell: cellInfo => (
                                                        <div className='flex'>
                                                            <p className="f12 m-0 text-succe1/2 self-center mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.savings_billing_prev_month ? cellInfo.row.original.additional_details.current_features.savings_billing_prev_month : ''}</p>
                                                            <p className="mb-0 text-lightGray f11 mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.price_unit ? cellInfo.row.original.additional_details.current_features.price_unit : ''}</p>
                                                            <p className="mb-0 text-lightGray f11">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month ? "("+cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</p>
                                                        </div>
                                                    ),
                                                    width: 150
                                                },
                                                {
                                                    Header: "Asset",
                                                    accessor: "asset_name",
                                                    width: 200
                                                },
                                                {
                                                    Header: "Resources",
                                                    Cell: cellInfo => (
                                                        (cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, state.regions)) : '')
                                                    ),
                                                    width: 200
                                                },
                                                {
                                                    Header: "Description",
                                                    accessor: "description",
                                                    width: 600
                                                }
                                            ]}
                                            data={state.filteredTableArray}
                                            perPage={20}
                                            dashboard={state.filteredTableArray && state.filteredTableArray.length ? true : false}
                                            sortByColumn={"Date"}
                                            riskTooltip={[0]}
                                            onClickRow={tableRow => {
                                                onChangeRecommendationEvent(tableRow.label, tableRow.tag, tableRow, 0, "", tableRow.asset_id)
                                            }}
                                            // defaultSelectedRecord={""}
                                            // tableSize={"table-sm"}
                                            // tableHead={"bg-dark"}
                                            // //tooltipFor={}
                                        />
                                    :
                                        <div className="flex justify-center m-4">
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                : 
                                    state.filteredResouceArray && state.filteredResouceArray.length ?
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: "Service",
                                                    accessor: d => d.resource_type,
                                                    width: 200
                                                },
                                                {
                                                    Header: "Category",
                                                    accessor: "tag",
                                                    width: 150
                                                },
                                                {
                                                    Header: "Savings",
                                                    accessor: d => d.savings+" ("+d.price_unit && d.price_unit+")  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                    Cell: cellInfo => (
                                                        <div className='flex'>
                                                            <p className="f12 m-0 text-succe1/2 self-center mr-1">{cellInfo.row.original.savings ? cellInfo.row.original.savings : ''}</p>
                                                            <p className="mb-0 text-lightGray f11 mr-1">{cellInfo.row.original.price_unit ? cellInfo.row.original.price_unit : ''}</p>
                                                            <p className="mb-0 text-lightGray f11">{cellInfo.row.original.percentage_savings ? "("+cellInfo.row.original.percentage_savings+"%)" : ''}</p>
                                                        </div>
                                                    ),
                                                    width: 150
                                                },
                                                {
                                                    Header: "Description",
                                                    accessor: "description",
                                                    width: 600
                                                }
                                            ]}
                                            data={state.filteredResouceArray}
                                            perPage={20}
                                            dashboard={state.filteredResouceArray && state.filteredResouceArray.length ? true : false}
                                            sortByColumn={"Date"}
                                            riskTooltip={[0]}
                                            onClickRow={tableRow => {
                                                onChangeServiceRecommendationEvent(tableRow.label, tableRow.tag, tableRow)
                                            }}
                                            // defaultSelectedRecord={""}
                                            // tableSize={"table-sm"}
                                            // tableHead={"bg-dark"}
                                            // //tooltipFor={[1,2]}
                                        />
                                    :
                                        <div className="flex justify-center m-4">
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RecommendationsLeftSection