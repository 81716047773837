/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file AnomalyDashboard.js
 * @author Prakash // on 27/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listCostAnomalyResults} from '../../../actions/cost/CostAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage, getPaginationShowingDetails } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';


const AnomalyDashboard = () => {
    const[state, setState] = useState({
        showLoading: true,
        details: {},
        dynamicTableColumn: [],
    
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
    
        groupType: 'All',
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        pageType: "Anomalies",
        showRightSide: true,
        anomalyTypes: [
            {'label':'All', 'value':'All'},
            {'label':'Account', 'value':'account_id'},
            {'label':'Region', 'value':'region'},
            {'label':'Service', 'value':'resource_type'},
            {'label':'Operation', 'value':'operation'}
        ],
        groupOptions: [
            {'label':'Severity', 'value':'severity'},
            {'label':'Account', 'value':'account_id'},
            {'label':'Region', 'value':'region'},
            {'label':'Service', 'value':'resource_type'}
        ]
    })
    const costCommonPageFilter = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.costCommonPageFilter);

    const dispatch = useDispatch(); // Create a dispatch function

    
    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    useEffect(() => {
        if(costCommonPageFilter && Object.entries(costCommonPageFilter).length) {
            setState(prevState => ({
                ...prevState,
                brushStartTime: costCommonPageFilter &&  costCommonPageFilter.brushStartTime ? costCommonPageFilter.brushStartTime : costCommonPageFilter.start_time,
                brushEndTime: costCommonPageFilter.brushEndTime ? costCommonPageFilter.brushEndTime : costCommonPageFilter.end_time,
                anomalyListLoader: true,
                callListCostAnomalyResults: true,
                costCommonPageFilter,
            }))
        }
    }, [costCommonPageFilter, state.costCommonPageFilter])

    useEffect(() => {
        if(state.callListCostAnomalyResults) {
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
            params.aggregate_by = state.groupedBy
            if(state.groupType !== 'All') {
                params.anomaly_type = state.groupType
            }

            if(params.start_time && params.end_time)  {
                dispatch(listCostAnomalyResults(params))
                    .then((response) => {
                        if(response) {
                            let data = response.results && response.results.details ? response.results.details : []
                            setState(prevState => ({ ...prevState, anomalyResponse: data, callListCostAnomalyResults: false }))
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, anomalyResponse: [], callListCostAnomalyResults: false }))
            }
        }
    }, [state.callListCostAnomalyResults, dispatch, state.brushEndTime, state.brushStartTime, state.groupType, state.groupedBy])

    useEffect(() => {
        
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(state.anomalyResponse && state.anomalyResponse.length) {
            totalCount = state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        setState(prevState => ({ 
            ...prevState, 
            groupAllAnomaly,
            groupAnomalycategory: orderedArray, 
            // groupAnomalyTotalCount, 
            anomalyListLoader: false 
        }))
    }, [state.anomalyResponse]);

    useEffect(() => {
        let filterdCategoryAnomaly = state.groupAllAnomaly
        if(state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = state.anomalyResponse.filter(arr => arr.label === state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }
        setState(prevState => ({ ...prevState, groupAnomaly: filterdCategoryAnomaly }))
        
    }, [state.selectedCategory, state.groupAllAnomaly, state.anomalyResponse])

    useEffect(() => {        
        let data = state.groupAnomaly ? state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > state.perPage) {
			totalPages = Math.ceil(data.length / state.perPage)
		}

        let priceUnit = ""
        if(data.length) {
            priceUnit = data[0].additional_details && data[0].additional_details.pricing_unit ? data[0].additional_details.pricing_unit : ""
        }

        setState(prevState => ({ ...prevState, totalFilteredArray: data, filteredArray: data, priceUnit, totalPages }))

        // let scroll = document.getElementById('scrollTop');
        // scroll.scrollIntoView();
    }, [state.groupAnomaly, state.perPage])

    
	useEffect(() => {
		let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			setState(prevState => ({ ...prevState, headers }))
		}
	}, [state.filteredArray])

    useEffect(() => {
        // let ExpectedCost = "Expected Cost "+(<span className="text-lightGray f12">{state.priceUnit}</span>)
        let dynamicTableColumn = []
        let dataRow = {}

        dataRow.Header = ' '
        dataRow.accessor = 'severity'
        dataRow.Cell = cellInfo => (
            <span className={`rounded-full text-white text-center py-2 px-4 ${getStatusBgColor(cellInfo.row.original.severity)}`}>
                {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
            </span>)
        dataRow.width = 60
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Date'
        dataRow.accessor = 'anomaly_time'
        dataRow.width = 160
        dataRow.HeaderUnit = "local"
        dataRow.Cell = cellInfo => (
            <span>{cellInfo.row.original.anomaly_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.anomaly_time, 'DD MMM YYYY HH:mm:ss') : ''}</span>
        )
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Duration'
        dataRow.accessor = d => d.additional_details && d.additional_details.duration
        dataRow.Cell = cellInfo => (<div className="flex"><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration ? cellInfo.row.original.additional_details.duration : ''}</span><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration_unit ? cellInfo.row.original.additional_details.duration_unit : ''}</span></div>)
        dataRow.width = 140
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Impact Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_impact_cost
        dataRow.Cell = cellInfo => (<div className="flex"><span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_cost ? cellInfo.row.original.additional_details.total_impact_cost : ''}</span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_percentage ? <span className="mr-1 text-success f12 self-center">({cellInfo.row.original.additional_details.total_impact_percentage}%)</span> : ""}</div>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        // dataRow.Header = "Actual Cost "+(<span className="text-lightGray f12">({state.priceUnit})</span>)
        dataRow.Header = "Actual Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_actual_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_actual_cost ? cellInfo.row.original.additional_details.total_actual_cost : ''}</span>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Expected Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_expected_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_expected_cost ? cellInfo.row.original.additional_details.total_expected_cost : ''}</span>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 180
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Description"
        dataRow.accessor = d => d.anomaly_message ? d.anomaly_message : ""
        dataRow.className = "whiteSpaceUnset"
        dataRow.width = 600
        dynamicTableColumn.push(dataRow)

        setState(prevState => ({ ...prevState, dynamicTableColumn }))
    }, [state.priceUnit, state.filteredArray])

    const handleChange = (value) => {
        let groupType = value
        let groupedBy = state.groupedBy
        if(state.groupType !== "All" && state.groupedBy === "anomaly_type") {
            groupedBy =  "severity"
        }
        setState(prevState => ({ ...prevState, groupType, groupedBy, callListCostAnomalyResults: true, anomalyListLoader: true }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    const getStatusBgColor = (status) => {
        let color = 'bg-darkPurple-50'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'bg-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'bg-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'bg-primaryPurple-600' } else
            if(status.toLowerCase() === 'low') { color = 'bg-appleGreen-600' }
        }
        return color;
    }

    const getStatusColor = (status) => {
        let color = 'text-CyanBlueAzure'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'text-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'text-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'text-primaryPurple-600' } else
            if(status.toLowerCase() === 'low') { color = 'text-appleGreen-600' }
        }
        return color;
    }

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`flex my-6 ${state.alertListLoader ? 'disabled' : ''}`}>
                <p className="m-0 self-end mr-2 font-medium text-lg">Show </p>
                <div className={`self-center mx-2`}>
                    <SelectOption
                        label={""}
                        options={state.anomalyTypes}
                        fields={["value", "label"]}
                        selectedValues={state.groupType ? state.groupType : ''}
                        callbackMultiSelect={(value) => {
                            handleChange(value)
                        }}
                        singleSelection={true}
                        dropdownWidth={'min-w-32'}
                        classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                    />
                </div>
                <p className="m-0 self-end small mr-2 font-medium text-lg"> alerts groupd by</p>
                <div className={`self-center mx-2`}>
                    <SelectOption
                        label={""}
                        options={state.groupOptions}
                        fields={["value", "label"]}
                        selectedValues={state.groupedBy ? state.groupedBy : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, groupedBy: value, anomalyListLoader: true, selectedCategory: "Important / All", callListCostAnomalyResults: true, groupAnomalycategory: [] }))
                        }}
                        singleSelection={true}
                        dropdownWidth={'min-w-32'}
                        classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                    />
                </div>
            </div>
            
            <div className="flex flex-wrap">
                <div className={`mt-[13px] w-full h-full`}>
                    <div className="border border-PrimaryTintsDeepPurpleTints-600 rounded-2xl bg-white">
                        <div className="flex justify-between">
                            <div className="self-center mr-2 lg:w-3/5 w-full pl-6 pt-5 pb-6">
                                <p className='mb-0 self-center text-[28px] font-semibold'>Anomalies List</p>
                                <p className="mb-0 self-center">Showing 
                                    <span className='mx-1'>{getPaginationShowingDetails(state.filteredArray && state.filteredArray.length, state.totalFilteredArray && state.totalFilteredArray.length, state.currentPage, state.perPage)}</span>
                                        out of total {state.totalFilteredArray && state.totalFilteredArray.length} alert(s)
                                    <span className="mb-0 self-center ml-1 font-bold">{state.brushStartTime  ? momentDateGivenFormat(state.brushStartTime , 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}<span className="ml-2">(local time)</span></span>
                                </p>
                            </div>
                            <div className="flex justify-end lg:w-1/3 md:1/2 w-full self-center pt-5 pr-[22px] pb-6">
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <Search
                                        data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        filteredData={(filteredArray) => {
                                            setState(prevState => ({ ...prevState, filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] }))
                                        }}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white mr-4'}
                                        className={'rounded-full'}
                                    />
                                : null}
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <CSVLink 
                                        data={state.filteredArray} 
                                        headers={state.headers ? state.headers : []}
                                        filename={"cost-anomalies.csv"}
                                        className={"self-center mr-2"}
                                        target="_blank"
                                    >
                                       <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center ml-2' />
                                    </CSVLink>
                                : null}
                            </div>
                        </div>
                        {state.filteredArray && state.filteredArray.length > state.perPage ?
                            <div className='flex justify-end text-black w-full pb-4 pr-3'>
                                <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                <div className="flex text-sm">
                                    <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-500' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                                    <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                                </div>
                            </div>
                        : null}

                        {state.totalFilteredArray && state.totalFilteredArray.length ? 
                            <div className="flex flex-wrap border-t border-PrimaryTintsDeepPurpleTints-600">
                                {state.groupAnomalycategory ? 
                                <div className="lg:w-1/6 md:1/3 w-full pr-2 mt-6">
                                    <div className="bg-NeutralGray-400 rounded-lg border border-NeutralGray-600 ml-6 mt-6 px-3 py-4">
                                        {state.groupedBy === "severity" ?
                                            state.groupAnomalycategory.map(item => {
                                                return(
                                                    item.category === "Important / All" ?
                                                        <div className={`flex justify-between ${state.selectedCategory === item.category ? `${getStatusBgColor(item.category)} pointer-events-none`: ''}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category }))
                                                            }
                                                        }}>
                                                            <div className="flex w-full max-w-md">
                                                                <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                    <p className="text-base font-medium mb-0" style={{ minWidth: '75px' }}>{item.category}</p>
                                                                    <p className="text-base font-bold mb-0">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className={`flex justify-between mt-2 ${!item.count ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category }))
                                                            }
                                                        }}>
                                                            <div className="flex w-full max-w-md">
                                                                <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                    <p className={`text-base font-medium mb-0 ${getStatusColor(item.category)}`} style={{ minWidth: '75px' }}>{item.category}</p>
                                                                    <p className={`text-base font-bold mb-0 ${getStatusColor(item.category)}`}>{item.count}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        : 
                                            state.groupAnomalycategory.map((item, index) => {
                                                return(
                                                    item.category === "Important / All" ?
                                                    <div className={`flex justify-between ${state.selectedCategory === item.category ? `${getStatusBgColor(item.category)} 'pointer-events-none'}` : ''}`} onClick={() => {
                                                        if(state.selectedCategory !== item.category) {
                                                            setState(prevState => ({ ...prevState, selectedCategory: item.category }))
                                                        }
                                                    }}>
                                                        <div className="flex w-full max-w-md">
                                                            <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                <p className="text-base font-medium mb-0" style={{ minWidth: '75px' }}>{item.category}</p>
                                                                <p className="text-base font-bold mb-0">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className={`flex justify-between mt-2 ${!item.count ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => {
                                                        if(state.selectedCategory !== item.category) {
                                                            setState(prevState => ({ ...prevState, selectedCategory: item.category }))
                                                        }
                                                    }}>
                                                       <div className="flex w-full max-w-md">
                                                            <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                <p className={`text-base font-medium mb-0 truncate ${getStatusColor(item.category)}`} style={{ minWidth: '75px' }}>{item.category}</p>
                                                                <p className={`text-base font-bold mb-0 ${getStatusColor(item.category)}`}>{item.count}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            : null}
                            <div className={`${state.groupAnomalycategory && state.groupAnomalycategory.length ? 'lg:w-5/6 md:2/3' : ''} w-full`}>
                                <div className={`bg-white ml-5 mt-6 mr-6`}>
                                    {state.anomalyListLoader ? 
                                        <div className='flex justify-center m-4'>
                                            <LoadingCircle />
                                        </div>
                                    : state.filteredArray && state.dynamicTableColumn.length && state.filteredArray.length ?
                                        <ResizeableDarkThemeTable
                                            columns={state.dynamicTableColumn}
                                            data={state.filteredArray}
                                            perPage={10}
                                            selectedColor={'bg-backGroundGray'}
                                            dashboard={true}
                                            sortByColumn={"anomaly_time"}
                                            riskTooltip={[]}
                                            onClickRow={tableRow => {}}
                                        />
                                    :
                                        <div className='flex justify-center m-4'>
                                            <p>{getCommonInformationMessage('anomalies')}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnomalyDashboard