/*************************************************
 * Tvastar
 * @exports
 * @file AssocationDetails.js
 * @author Prakash // on 06/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import { useLocation } from 'react-router-dom';
import { qosListAccounts } from '../../../actions/Collider/AccountAction';
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';

const AssocationDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		callUserList: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)

	const location = useLocation();
	const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedUsers: receivedData?.selectedData?.user_name || [],
				selectedGroup: receivedData?.selectedData?.account || '',
				// groupUsers: state.accountsList.filter(e => e.account === value).length && state.accountsList.filter(e => e.account === value)[0].user_name ? state.accountsList.filter(e => e.account === value)[0].user_name : []
			}))

			dispatch(setHpcPropsDetails('qosCreateEditInputs', receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [receivedData, dispatch])
	useEffect(() => {
		if(props.selectedCluster) {
			let params = {}
			params.provider = qosCreateEditInputs.provider
			params.account_id = qosCreateEditInputs.account_id
			params.region = qosCreateEditInputs.region
			params.cluster_name = props.selectedCluster
			if(params.cluster_name) {
				dispatch(qosListAccounts(params))
					.then((response) => {
						if(response) {
							let results = response.results ? response.results : []
							setState(prevState => ({ ...prevState, accountsList: results }));
						}
					})
			}
		}
    }, [dispatch, qosCreateEditInputs.provider, qosCreateEditInputs.account_id, qosCreateEditInputs.region, props.selectedCluster])

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
		handleInputChange('requested_user_name', state[field].filter(e => e !== value))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		props.onChildPageClick(event)

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => props.onChildPageClick(event)}>
			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
				<p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Associations</p>
				<div className='flex flex-wrap'>
					<div className='flex justify-start w-1/2 pr-4'>
						<SelectOption
							label={"Group"}
							fields={["account", "account"]}
							options={state.accountsList}
							selectedValues={state.selectedGroup ? state.selectedGroup : ''}
							callbackMultiSelect={(value) => {
								setState(prevState => ({ ...prevState, 
										selectedGroup: value,
										groupUsers: state.accountsList.filter(e => e.account === value).length && state.accountsList.filter(e => e.account === value)[0].user_name ? state.accountsList.filter(e => e.account === value)[0].user_name : []
									}))
								handleInputChange('account', value)
							}}
							singleSelection={true}
							dropdownWidth={'w-full'}
						/>
					</div>
					<div className='flex flex-col justify-start w-1/2 pl-4'>
						<SelectOption
							label={"User"}
							// fields={["account_id", "account_name"]}
							options={state.groupUsers}
							selectedValues={state.selectedUsers ? state.selectedUsers : []}
							callbackMultiSelect={(value) => {
								setState(prevState => ({ ...prevState, selectedUsers: value }))
								handleInputChange('requested_user_name', value)
							}}
							dropdownWidth={'w-full'}
						/>
						
					</div>
				</div>
				<div className='flex flex-wrap mt-4'>
				<div className='flex justify-start w-1/2 pr-4'></div>
				{state.selectedUsers && state.selectedUsers.length ? 
					<div className='flex flex-wrap justify-start pl-4 w-1/2'>
						{state.selectedUsers.map(row => {
							return(
								<span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{row}
									<Icon icon="jam:close" width="20" height="20" className='text-black ml-2 self-center' onClick={ () => removeItem('selectedUsers', row) } />
								</span>
							)
						})}
					</div>
				: null}
				</div>
			</div>
		</div>
	)
}

export default AssocationDetails
