import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Store as CommonNotification } from 'react-notifications-component';
import { getLifecycleEndTime, lifecycleNotification } from '../../actions/commonActionNew' 
import { URL_PATH } from '../../config/urlPath';
import { onlyNumeric } from '../../utils/utility';
import Button from './Form/Button';

let getSesssionTime = null
const LifecycleNotifications = () => {

    const [state, setState] = useState({
    confirmAction: true
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function

    const navigatoTo = useCallback(() => {
    navigate(URL_PATH.JOB_STATUS)
    }, [navigate])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params <= 0) {
            navigatoTo()
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            // Create an object to store the extracted parameters and values
            // Get values from the URL
            const resourceId = urlParams.has('resource_id') ? urlParams.get('resource_id') : null;
            const expirationTime = urlParams.has('expiration_time') ? urlParams.get('expiration_time') : null;
            // const action = urlParams.has('action') ? urlParams.get('action') : null;

            setState(prevState => ({
                ...prevState, resourceId, expirationTime, confirmAction: true
            }))
        }
    },[navigatoTo])

    useEffect(() => {
        if (!state.confirmAction) {
            navigatoTo()
        }
    },[state.confirmAction, navigatoTo])

    const handleConfirmation = () => {
        // Get the search params from the current URL
        let hasError = false

        let params = {} //resource_id:"64ab0933d6ee3cb295c35b2ee7c66f77",expiration_time:"1714646797", action: "approved"}
        params.resource_id = state.resourceId
        params.expiration_time = state.expirationTime
        // params.action = state.action
        // if(params.action === 'extended') {
        if (state.approve_shutdown) {
            params.action = 'approved'
        } else {
            params.action = 'extended'
            params.extended_duration_in_hrs = parseInt(state.extended_duration_in_hrs)
            if (state.extended_duration_in_hrs < 1 || state.extended_duration_in_hrs > 96) {
                hasError = true
            }
        }
        // }
        setState(prevState => ({
            ...prevState, hasError, showConfirmActionLoading: hasError ? false : true
        }))

        if (!hasError) {
            dispatch(lifecycleNotification(params))
                .then((response) => {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : 'Error in notificaiton'
                    if (response && !response.error) {
                        if (response.status && response.status !== 500) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Notified'
                            navigatoTo()
                        } else {
                            setState(prevState => ({
                                ...prevState, showConfirmActionLoading: false
                            }))
                        }
                    } else {
                        setState(prevState => ({
                            ...prevState, showConfirmActionLoading: false
                        }))
                    }

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                })
        }
    }

    const getNextScheduleTime = (hours) => {
        let params = {}
        const urlParams = new URLSearchParams(window.location.search);
        params.resource_id = urlParams.has('resource_id') ? urlParams.get('resource_id') : null;
        params.expiration_time = urlParams.has('expiration_time') ? urlParams.get('expiration_time') : null;
        params.extended_duration_in_hrs = hours
        params.action = 'extended'
        let hasError = false
        if (hours < 1 || hours > 96) {
            hasError = true
        }

        setState(prevState => ({
            ...prevState, hasError
        }))

        if (!hasError) {
            dispatch(getLifecycleEndTime(params))
                .then((response) => {
                    if (response) {
                        setState(prevState => ({
                            ...prevState, nextScheduleResponse: response, scheduleLoading: false
                        }))
                    } else {
                        setState(prevState => ({
                            ...prevState, nextScheduleResponse: {}, scheduleLoading: false
                        }))
                    }
                })
        }
    }
    // Render the child components normally
    return (
        <div id="popup-modal" tabIndex="-1" className={`flex ${state.confirmAction ? '' : ''} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow-lg border border-NeutralGray-400 dark:bg-gray-700">
                    <button type="button" className="absolute top-3 end-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => {
                            setState(prevstate => ({ ...prevstate, confirmAction: false, showDeleteLoading: false, deleteDepartment: {}
                    }))
                    }}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-black w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-black">Please confirm the action</h3>
                        <div className="flex">
                            <label className="mb-0">
                                <input type="checkbox"
                                    className="mt-1 w-4 h-4"
                                    checked={state.approve_shutdown ? true : false}
                                    disabled={state.extended_duration_in_hrs ? true : false}
                                    onChange={(e) => setState(prevState => ({
                                        ...prevState, approve_shutdown: !state.approve_shutdown, extended_duration_in_hrs: ''
                                    }))}
                                />
                            </label>
                            <span className="mb-0 text-black ml-2 self-center">Approve Shutdown</span>
                        </div>
                        <p className='flex my-2 text-NeutralGray-600'>(or)</p>
                        <div className='flex flex-wrap'>
                            <label className='mr-2 self-end mb-0 text-black'>Extend Session</label>
                            <input
                                type='text'
                                placeholder={`${state.approve_shutdown ? '' : 'Enter hrs'}`}
                                className={`"text-black bg-white border-b border-NeutralGray-800 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-1/4"  ${state.approve_shutdown ? 'bg-NeutralGray-600 pointer-events-none disabled' : ''}`}
                                // maxlength='128'
                                value={state.extended_duration_in_hrs ? state.extended_duration_in_hrs : ''}
                                onChange={e => {
                                    if (!state.approve_shutdown) {
                                        clearTimeout(getSesssionTime)
                                        setState(prevState => ({
                                            ...prevState, extended_duration_in_hrs: onlyNumeric(e.target.value)
                                        }))
                                        getSesssionTime = setTimeout(() => {
                                            getNextScheduleTime(e.target.value)
                                        }, 2000);
                                    }
                                }}
                            />
                            <span className='ml-2 self-end text-black'>hours</span>
                        </div>

                        {state.hasError && state.extended_duration_in_hrs ?
                            <p className='my-2 text-danger flex'>Extend session should be between 1 and 96 hours.</p>
                            : state.nextScheduleResponse && state.nextScheduleResponse.message ?
                                <p className='my-2 text-danger flex'>{state.nextScheduleResponse.message
                                }</p>
                                : null
                        }

                        <div className='flex mt-4 justify-center'>
                            <div className={`self-center flex justify-end`}>
                                <Button 
                                    label={"No, cancel"}
                                    classDetails={{bg:'bg-ferrariRed-600', rounded:'rounded-lg', text: 'text-white text-base', width: 'min-w-24', others: 'cursor-pointer text-center'}}
                                    callback={() => {
                                        if (!state.showConfirmActionLoading) {
                                            setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction, name: '' }))
                                        }
                                    }}
                                />
                                {state.showConfirmActionLoading ?
                                    <Button
                                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-24', others: 'cursor-pointer text-center'}}
                                        label={'Confirming'}
                                        loading={true}
                                    />
                                :
                                    <Button
                                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-24', others: 'cursor-pointer text-center'}}
                                        label={'Confirm'}
                                        callback={() => {
                                            if (!state.showConfirmActionLoading && !state.scheduleLoading) {
                                                setState(prevState => ({ ...prevState, showConfirmActionLoading: true }))
                                                handleConfirmation()
                                            }
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LifecycleNotifications;
