/*
 * @exports
 * @file BudgetAction.js
 * @author parakash
*/

import ApiCalls from '../../components/ApiCalls'

import {
    LIST_BUDGETS,
	LIST_DEPARTMENTS,
    GET_DEPARTMENT_BUDGET,
    UPDATE_BUDGET,
	GET_BUDGET_DASHBOARD,
	GET_TOP_SPENDERS,
	GET_TOP_OVERSPENDERS,
	GET_OVERSPENDERS_FORECAST,
	GET_TOP_SPENDERS_FORECAST,
	GET_BUDGET_SUMMARY,
	LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS,
	GET_SPEND_COMPARISION_WITH_PREVIOUS_YEAR,
	GET_CLOUD_COMMITMENT_VS_SPEND,
	GET_CLOUD_PROVIDER_BUDGET,
	INSERT_CLOUD_PROVIDER_BUDGET,
	GET_CURRENT_CLOUD_COMMITMENT_VS_SPEND,
	GET_CLOUD_PROVIDER_COMMITMENT,
	GET_BUDGET_OBSERVATIONS,
	LIST_DEPARTMENTS_MAPPINGS,
	CREATE_DEPARTMENT,
	EDIT_DEPARTMENT,
	DELETE_DEPARTMENT,
	GET_UNTAGGED_RESOURCES,
	GET_CURRENCY_CONVERSION_DETAILS,
	INSERT_PROJECT_CREDITS,
	GET_PROJECT_CREDITS
} from '../../config'
import { ACTION_TYPES } from '../types';

/**
 * Action to list-budgets
 * @param {Object} body
 * @param {Function} callback
 */
export const listBudgets = (params) => {
	return async () => {
		try {
			const url = LIST_BUDGETS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-departments
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartments = (params) => {
	return async dispatch => {
		try {
			const url = LIST_DEPARTMENTS;			
			const response = await ApiCalls.post(url, params)
			dispatch({ type: ACTION_TYPES.SET_LIST_DEPARTMENT, payload: response.data && response.data.results ? response.data.results : [] });
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-dpeartment-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const getDepartmentBudget = (params) => {
	return async () => {
		try {
			const url = GET_DEPARTMENT_BUDGET;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to update-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const updateBudget = (params) => {
	return async () => {
		try {
			const url = UPDATE_BUDGET;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-budget-dashboard
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetDashboard = (params) => {
	return async () => {
		try {
			const url = GET_BUDGET_DASHBOARD;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-top-spenders
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopSpenders = (params) => {
	return async () => {
		try {
			const url = GET_TOP_SPENDERS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-top-overspenders
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopOverspenders = (params) => {
	return async () => {
		try {
			const url = GET_TOP_OVERSPENDERS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-overspenders-forecast
 * @param {Object} body
 * @param {Function} callback
 */
export const getOverspendersForecast = (params) => {
	return async () => {
		try {
			const url = GET_OVERSPENDERS_FORECAST;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}


/**
 * Action to get-top-spenders-forecast
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopSpendersForecast = (params) => {
	return async () => {
		try {
			const url = GET_TOP_SPENDERS_FORECAST;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getSummary = (params) => {
	return async () => {
		try {
			const url = GET_BUDGET_SUMMARY;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-departments-initiatives-projects
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartmentsInitiativesProjects = (params) => {
	return async (dispatch) => {
		try {
			const url = LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS;			
			const response = await ApiCalls.post(url, params)
			
			dispatch({ type: ACTION_TYPES.SET_LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS, payload: response.data && response.data.results ? response.data.results : [] });
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-spend-comparison-with-previous-year
 * @param {Object} body
 * @param {Function} callback
 */
export const getSpendComparisionWithPreviousYear = (params) => {
	return async () => {
		try {
			const url = GET_SPEND_COMPARISION_WITH_PREVIOUS_YEAR;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudCommitmentVsSpend = (params) => {
	return async (dispatch) => {
		try {
			const url = GET_CLOUD_COMMITMENT_VS_SPEND;			
			const response = await ApiCalls.post(url, params)
			dispatch({ type: ACTION_TYPES.SET_CLOUD_COMMITMENT_VS_SPEND, payload: response.data });
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to insert-cloud-provider-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const insertCloudProviderBudget = (params) => {
	return async () => {
		try {
			const url = INSERT_CLOUD_PROVIDER_BUDGET;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cloud-provider-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudProviderBudget = (params) => {
	return async () => {
		try {
			const url = GET_CLOUD_PROVIDER_BUDGET;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-current-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCurrentCloudCommitmentVsSpend = (params) => {
	return async (dispatch) => {
		try {
			const url = GET_CURRENT_CLOUD_COMMITMENT_VS_SPEND;			
			const response = await ApiCalls.post(url, params)
			dispatch({ type: ACTION_TYPES.SET_CURRENT_CLOUD_COMMITMENT_VS_SPEND_OBSERVATIONS, payload: response.data });
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-current-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudProviderCommitment = (params) => {
	return async () => {
		try {
			const url = GET_CLOUD_PROVIDER_COMMITMENT;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-observations
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservations = (params) => {
	return async (dispatch) => {
		try {
			const url = GET_BUDGET_OBSERVATIONS;			
			const response = await ApiCalls.post(url, params)
			dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-departments-mappings
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartmentsMappings = (params) => {
	return async () => {
		try {
			const url = LIST_DEPARTMENTS_MAPPINGS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-observations
 * @param {Object} body
 * @param {Function} callback
 */
export const createDepartment = (params) => {
	return async () => {
		try {
			const url = CREATE_DEPARTMENT;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const editDepartment = (params) => {
	return async () => {
		try {
			const url = EDIT_DEPARTMENT;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const deleteDepartment = (params) => {
	return async () => {
		try {
			const url = DELETE_DEPARTMENT;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const getUntaggedResources = (params) => {
	return async () => {
		try {
			const url = GET_UNTAGGED_RESOURCES;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const getCurrencyConversionDetails = (params) => {
	return async () => {
		try {
			const url = GET_CURRENCY_CONVERSION_DETAILS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const insertProjectCredits = (params) => {
	return async () => {
		try {
			const url = INSERT_PROJECT_CREDITS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

export const getProjectCredits = (params) => {
	return async () => {
		try {
			const url = GET_PROJECT_CREDITS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}
	