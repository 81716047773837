import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import { validateMountDir, validateVolumeName } from "../../../../utils/utility";
import SelectOption from "../../../common/Form/SelectOption";
import Textbox from "../../../common/Form/Textbox";
import Checkbox from "../../../common/Form/Checkbox";

const NewStorageSection = (props) => {
	const [state, setState] = useState({
		volumeArray: [0],
	});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

	const dispatch = useDispatch(); // Create a dispatch function

	const callPageVist = useCallback(() => {
		let storageConfiguration = createUpdateCluster?.storage_configuration || [{
			arrayIndex : 0,
		}];
		let volumeArray = [0];
		if (storageConfiguration.length) {
			volumeArray = [];
			storageConfiguration.forEach((row, index) => {
				volumeArray.push(index);
				setState((prevState) => ({
					...prevState,
					[`storage_type_${index}`]: row.storage_type,
					...Object.entries(row).reduce((acc, [key, value]) => {
						acc[`${key}_${index}`] = value;
						return acc;
					}, {})
				}));
			});

			setState((prevState) => ({
				...prevState,
				volumeArray
			}));
		}
	}, [createUpdateCluster]);
	useEffect(() => callPageVist(), [callPageVist]);

	const addSection = (array) => {
		let rowList = state[array];
		if (state[array]) {
			let value = state[array][state[array].length - 1];
			value = value + 1;
			rowList.push(value);

			setState((prevState) => ({ ...prevState, [array]: rowList }));
		}
	};

	const removeSection = (array, index, i) => {
		setState((prevState) => ({
			...prevState,
			["volume_name_" + index]: "",
			["snapshot_policy_" + index]: "",
			["storage_efficiency_" + index]: "",
			["tiering_policy_name_" + index]: "",
			["cooling_period_" + index]: "",
			["volume_security_style_" + index]: "",
			["volume_size_" + index]: "",
			["fsx_volume_type_" + index]: ""
		}));

		let rowList = state[array];
		rowList.splice(i, 1);
		setState((prevState) => ({ ...prevState, [array]: rowList }));

		let obj = createUpdateCluster ? createUpdateCluster : {};
		let storageConfiguration =
			obj.storage_configuration && obj.storage_configuration.length
				? obj.storage_configuration
				: [];
		obj["storage_configuration"] = storageConfiguration.filter(
			(e) => e.arrayIndex !== index
		);
	};
	
	const handleStorageInput = (label, value, type) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let storageConfiguration = Array.isArray(obj.storage_configuration) ? obj.storage_configuration : [];
		if (storageConfiguration.length === 0) {
			storageConfiguration[0] = {
				arrayIndex: 0,
				storage_type: props.storageType,
				create: true,
			};
		}

		storageConfiguration[0][label] = type === "integer" ? parseInt(value) : value;
		obj["storage_configuration"] = storageConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));
		setState((prevState) => ({ ...prevState, [label]: value }));
	};


	const handleStorageVolumeInput = (index, label, value, type) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let storageConfiguration = obj.storage_configuration && obj.storage_configuration.length ?	obj.storage_configuration : [];

		state.volumeArray.forEach((sIndex) => {
			if (!storageConfiguration.filter((e) => e.arrayIndex === sIndex).length) {
				let dataRow = { 
					arrayIndex: sIndex,
					storage_type: props.storageType,
					create: true,
					root_volume_security_style: state.root_volume_security_style,
					storage_capacity: state.storage_capacity,
					throughput_capacity: state.throughput_capacity
				};
				storageConfiguration.push(dataRow);
			}
			let selectedVolumeInput = storageConfiguration.filter(
				(e) => e.arrayIndex === sIndex
			)[0];

			if (sIndex === index) {
				setState((prevState) => ({
					...prevState,
					[label + "_" + sIndex]: value,
				}));
			}

			let inputValue = sIndex === index ? value : state[label + "_" + sIndex];
			selectedVolumeInput[label] =
				type === "integer" ? parseInt(inputValue) : inputValue;
		});

		obj["storage_configuration"] = storageConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));
	};

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

	return (
		<div onClick={(event) => handleChildClick(event, "parent")}>
			<React.Fragment>
				{props.storageType === "Efs" ? (
					<React.Fragment>
						<div className='flex flex-wrap pt-8'>
							<div className='flex flex-col justify-between w-1/2 lg:pr-4'>
								<Textbox
									label={"File system name"}
									type="text"
									selectedValue={state["storage_name"] ? state["storage_name"] : ""}
									callback={(value) => {
										handleStorageInput("storage_name",validateVolumeName(value));
									}}
									disabled={props.pageMode === 'Edit'}
									placeholder={"Enter File system name (Eg. awuse1nprpc01efsdata)"}
									manditory={true}
									hasError={props.hasError}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.volume_name}</p>
							</div>
							<div className='flex flex-col justify-between w-1/2 lg:px-5'>
								<Textbox
									label={"Mount Directory"}
									type="text"
									selectedValue={state["mount_dir"] ? state["mount_dir"] : ""}
									callback={(value) => {
										handleStorageInput("mount_dir",validateMountDir(value));
									}}
									disabled={props.pageMode === 'Edit'}
									placeholder={"Enter mount directory (Eg. /data)"}
									manditory={true}
									hasError={props.hasError}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.mount_dir}</p>
							</div>
						</div>
						<div className='flex flex-wrap pt-8'>
							<div className='flex flex-col w-1/2 lg:pr-4'>
								<Checkbox
									label="Encryption"
									name="efa_enabled"
									selectedValue={state["encrytion"] ? true : false}
									callback={() => {
										let value = !state["encrytion"];
										setState((prevState) => ({...prevState,"encrytion": value}));
										handleStorageInput("encrytion", value);
									}}
									disabled={props.pageMode === "Edit"}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.encryption}</p>
							</div>
							<div className='flex flex-col justify-start w-1/2 px-5'>
								<SelectOption
									label={"Deletion Policy"}
									fields={["value", "key"]}
									options={ propsClusterFilters && propsClusterFilters.deletion_policy ? propsClusterFilters.deletion_policy.map( (d) => ({key: d, value: d}) ) : []}
									selectedValues={state["deletion_policy"] ? state["deletion_policy"] : ""}
									callbackMultiSelect={(value) => {
										handleStorageInput("deletion_policy",value);
                            			setState((prevState) => ({...prevState, deletion_policy: value}));
									}}
									// disabled={props.pageMode === 'Edit'}
									singleSelection={true}
									dropdownWidth={'w-full'}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.deletion_policy}</p>
							</div>
						</div>
					</React.Fragment>
				) : props.storageType === "FsxOntap" || props.storageType === "FsxLustre" ? (
					<React.Fragment>
						<React.Fragment>
						<div className='flex flex-wrap pt-8'>
							<div className='flex flex-col justify-start w-1/2 pr-4'>
								<SelectOption
									label={"Root Volume Security Style"}
									fields={["value", "key"]}
									options={ propsClusterFilters && propsClusterFilters.volume_security_style ? propsClusterFilters.volume_security_style : []}
									selectedValues={state["root_volume_security_style"] ? state["root_volume_security_style"]  : ""}
									callbackMultiSelect={(value) => {
										handleStorageInput("root_volume_security_style", value);
                              			setState((prevState) => ({ ...prevState, root_volume_security_style: value}));
									}}
									manditory={true}
									hasError={props.hasError}
									disabled={props.pageMode === 'Edit'}
									singleSelection={true}
									dropdownWidth={'w-full'}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.volume_security_style}</p>
							</div>
							<div className='flex flex-col justify-start w-1/2 px-5'>
								<SelectOption
									label={"Throughput Capacity"}
									fields={["value", "key"]}
									options={ propsClusterFilters && propsClusterFilters.throughput_capacity ? propsClusterFilters.throughput_capacity.map( (d) => (
										{key: d, value: d}
									)) : []
									}
									selectedValues={state["throughput_capacity"]  ? state["throughput_capacity"]  : ""}
									callbackMultiSelect={(value) => {
										handleStorageInput( "throughput_capacity", value, "integer" );
										setState((prevState) => ({...prevState, throughput_capacity: value}));
									}}
									singleSelection={true}
									dropdownWidth={'w-full'}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.throughput_capacity}</p>
							</div>
						</div>
						<div className='flex flex-wrap pt-8'>
							<div className='flex flex-col justify-between w-1/2 lg:pr-4'>
								<Textbox
									label={"Storage Capacity"}
									type="text"
									selectedValue={state["storage_capacity"] ? state["storage_capacity"] : ""}
									callback={(value) => {
										handleStorageInput("storage_capacity", value,"integer");
									}}
									placeholder={"Enter storage capacity (in GB)"}
								/>
								<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.storage_capacity}</p>
							</div>
						</div>
						</React.Fragment>

						<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
						<p className='font-bold text-NeutralGray-800 text-[22px] uppercase'>Volume options</p>
							<div onClick={(event) => handleChildClick(event, "parent")}>
								{state.volumeArray && state.volumeArray.map((index, j) => {
									return (
									<div key={index}>
										<div className="bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl mt-5 pl-8 pt-[30px] pb-[17px]">

											<div className='flex flex-wrap pt-8'>
												<div className='flex flex-col justify-between w-full lg:pr-5'>
													<Textbox
														label={"Volume name"}
														type="text"
														selectedValue={state["volume_name_" + index] ? state["volume_name_" + index] : ""}
														callback={(value) => {
															handleStorageVolumeInput(index,"volume_name",validateVolumeName(value));
														}}
														disabled={props.pageMode === 'Edit'}
														placeholder={"Enter volume name (Eg. data)"}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.volume_name}</p>
												</div>
											</div>

											<div className='flex flex-wrap pt-8'>
												<div className='flex flex-col justify-start w-1/2 pr-5'>
													<SelectOption
														label={"Snapshot Policy"}
														fields={["value", "key"]}
														options={ propsClusterFilters && propsClusterFilters.snapshot_policy ? propsClusterFilters.snapshot_policy.map( (d) => ({ key: d, value: d}) ) : []}
														selectedValues={state["snapshot_policy_" + index] ? state["snapshot_policy_" + index ] : ""}
														callbackMultiSelect={(value) => {
															handleStorageVolumeInput(index,"snapshot_policy",value);
                                      						setState((prevState) => ({...prevState,["snapshot_policy_" + index]: value}));
														}}
														manditory={true}
														hasError={props.hasError}
														disabled={props.pageMode === 'Edit'}
														singleSelection={true}
														dropdownWidth={'w-full'}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.snapshot_policy}</p>
												</div>
												<div className='flex flex-col w-1/2 self-center lg:px-5'>
													<Checkbox
														label="Encryption"
														checked={state["storage_efficiency_" + index] ? true: false}
														disabled={props.pageMode === "Edit" ? true : false}
														callback={() => {
															let value = !state[ "storage_efficiency_" + index ];
															setState((prevState) => ({ ...prevState, ["storage_efficiency_" + index]: value}));
															handleStorageVolumeInput(index,"storage_efficiency",value);
														}}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.storage_efficiency}</p>
												</div>
											</div>

											<div className='flex flex-wrap pt-8'>
												<div className='flex flex-col justify-start w-1/2 pr-5'>
													<SelectOption
														label={"Tiering Policy Name"}
														fields={["value", "key"]}
														options={ propsClusterFilters && propsClusterFilters.tiering_policy_name ? propsClusterFilters.tiering_policy_name.map( (d) => ({key: d, value: d}) ) : []}
														selectedValues={state["tiering_policy_name_" + index] ? state["tiering_policy_name_" + index] : ""}
														callbackMultiSelect={(value) => {
															handleStorageInput(index, "tiering_policy_name",value);
															setState((prevState) => ({...prevState, ["tiering_policy_name_" + index] : value}));
														}}
														singleSelection={true}
														dropdownWidth={'w-full'}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.deletion_policy}</p>
												</div>
												<div className='flex flex-col justify-between w-1/2 lg:px-5'>
													<Textbox
														label={"Tiering Policy Cooling Period"}
														type="text"
														selectedValue={state["cooling_period_" + index] ? state["cooling_period_" + index] : ""}
														callback={(value) => {
															handleStorageVolumeInput(index,"cooling_period",value,"integer");
														}}
														placeholder={"Enter cooling period (in days)"}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.cooling_period}</p>
												</div>
											</div>

											<div className='flex flex-wrap pt-8'>
												<div className='flex flex-col justify-start w-1/2 pr-5'>
													<SelectOption
														label={"Volume Security Style"}
														fields={["value", "key"]}
														options={ propsClusterFilters && propsClusterFilters.volume_security_style
															? propsClusterFilters.volume_security_style
															: [] }
														selectedValues={state["volume_security_style_" + index] ? state["volume_security_style_" + index ] : ""}
														callbackMultiSelect={(value) => {
															handleStorageVolumeInput(index,"volume_security_style",value);
                                      						setState((prevState) => ({...prevState,["volume_security_style_" + index]: value}));
														}}
														manditory={true}
														hasError={props.hasError}
														disabled={props.pageMode === 'Edit'}
														singleSelection={true}
														dropdownWidth={'w-full'}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.volume_security_style}</p>
												</div>
												<div className='flex flex-col justify-between w-1/2 lg:px-5'>
													<Textbox
														label={"Volume Size"}
														type="text"
														selectedValue={state["volume_size_" + index] ? state["volume_size_" + index] : ""}
														callback={(value) => {
															handleStorageVolumeInput(index,"volume_size",value,"integer");
														}}
														// disabled={props.pageMode === 'Edit'}
														placeholder={"Enter storage capacity (in MB)"}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.storage_capacity}</p>
												</div>
											</div>

											<div className='flex flex-wrap pt-8'>
												<div className='flex flex-col justify-start w-1/2 pr-5'>
													<SelectOption
														label={"Volume Type"}
														fields={["value", "key"]}
														options={ propsClusterFilters && propsClusterFilters.fsx_volume_type
															? propsClusterFilters.fsx_volume_type
															: [] }
														selectedValues={state["fsx_volume_type_" + index] ? state["fsx_volume_type_" + index ] : ""}
														callbackMultiSelect={(value) => {
															handleStorageVolumeInput(index,"fsx_volume_type_",value);
                                      						setState((prevState) => ({...prevState,["fsx_volume_type_" + index]: value}));
														}}
														// manditory={true}
														// hasError={props.hasError}
														// disabled={props.pageMode === 'Edit'}
														singleSelection={true}
														dropdownWidth={'w-full'}
													/>
													<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.fsx_volume_type_}</p>
												</div>
												<div className='flex w-1/5 justify-center'>
													{state.volumeArray.length > 1 ? 
														<span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() =>  removeSection("volumeArray", index, j)}> Remove</span>
													: null}
												</div>
											</div>
										</div>

										{state.volumeArray.length - 1 === j ? 
											<div className='flex justify-center mt-4'>
												<p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() =>  addSection("volumeArray")}>+ Add Volume</p>
											</div>
										: null}
									</div>
									);
								})}
							</div>
						</div>
					</React.Fragment>
				) : null}
			</React.Fragment>
		</div>
	);
};

export default NewStorageSection;
