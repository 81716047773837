/*************************************************
 * Collider
 * @exports
 * @file SearchComponent.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Icon} from "@iconify/react";
// import { InputGroup } from 'reactstrap'

class Search extends Component {
	constructor(props) {
		super(props)
		this.inputRef = React.createRef()
		this.state = { 
			searchText: '',
			hideInputSection: this.props.hideInputSection
		}
	}

	componentDidMount = () =>{
		this.inputRef.current.focus()
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.resetSearchBox && this.props.resetSearchBox !== prevProps.resetSearchBox) {
			this.setState({ searchText: '' }, () => this._handleSearch())
		}
	}

	/**
	 * Handles search using the search text
	 */
	_handleSearch = () => {
		let currentList = []
		let newList = []
		if (this.state.searchText !== '') {
			currentList = this.props.data
			if(Array.isArray(currentList) && currentList.length && typeof currentList[0] === 'string') {
				let searchTerm = this.state.searchText;
				newList = currentList.filter(e => typeof e === 'string' && e.toLowerCase().includes(searchTerm.toLowerCase()));
			} else {
				newList =
					currentList &&
					currentList.filter(item => {
						let isPresent = []
						isPresent = this.recursiveSearch(item, this.state.searchText)
							.flat(Infinity)
							.filter(bool => bool === true)  // for multi arrray [[1,2],[3,4],[4,5]]
						if (isPresent[0]) {
							return true
						} else {
							return false
						}
					})
			}
		} else {
			newList = this.props.data
			this.props.filteredData(newList, this.state.searchText)			
		}
		if (newList) {
			this.props.filteredData(newList, this.state.searchText)
		}
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return item && Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] && item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	render() {
		return (
			<div id={this.props.ID} className={`flex w-full ${this.props.topClassName ? this.props.topClassName : 'border border-NeutralGray-600 rounded-full bg-white'} ${this.state.hideInputSection ? '' : ''}`}>
				<Icon icon={`bitcoin-icons:search-outline`} className={`pl-2 text-black'} ${this.state.hideInputSection ? '' : ''} self-center`} width={40} height={40}
					onClick={() => 
						this.setState({ hideInputSection: !this.state.hideInputSection },
							() => {
								this.inputRef.current.focus()
							}	
						)
					}
				/>
				<div className={`w-full ${this.state.hideInputSection ? 'hidden': ''} text-base items-center self-center`}>
					<input
						type="text"
						value={this.state.searchText}
						ref={this.inputRef}
						onChange={e => {
							this.setState({ searchText: e.target.value }, () => this._handleSearch())
						}}
						className={`w-full self-center py-2.5 pl-2 ${this.props.className ? this.props.className : 'text-black'}`}
						placeholder="Search"
					/>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Search.propTypes = {
	data: PropTypes.array,
	filteredData: PropTypes.func,
}

export default Search
