/*************************************************
 * Tvastar
 * @exports
 * @file CreateUpdateQos.js
 * @author Prakash // on 07/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';

import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath'

import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createQosPolicy, editQosPolicy, getQosParameters, getQosPolicy } from '../../../actions/Collider/QosAction'
import { noSpace } from '../../../utils/utility';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import QosDetails from './QosDetails';
import QosAssocationDetails from './QosAssocationDetails';
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import Textarea from '../../common/Form/Textarea';
import SelectOption from '../../common/Form/SelectOption';


const CreateUpdateQos = () => {
	const [state, setState] = useState({
		listLoading: false,
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)

	const navigate = useNavigate();
	const location = useLocation();
    const receivedData = location.state || false
	
	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		if(state.selectedProvider && !qosCreateEditInputs.provider) {
			handleInputChange('provider', state.selectedProvider ? state.selectedProvider.toLowerCase() : "")
		}
		if(state.selectedAccount && !qosCreateEditInputs.account_id) {
			handleInputChange('account_id', state.selectedAccount)
		}
		if(state.selectedRegion && !qosCreateEditInputs.region) {
			handleInputChange('region', state.selectedRegion)
		}
		if(state.selectedCluster && !qosCreateEditInputs.cluster_name) {
			setTimeout(() => { handleInputChange('cluster_name', state.selectedCluster) }, 500);
		}
	}, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedCluster, state.selectedRegion, qosCreateEditInputs, handleInputChange])


	useEffect(() => {
		let params = {}
		if (providers) {
			setState(prevState => ({ ...prevState, 
				providers: providers, 
				selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""
			}));			
		} else {
			dispatch(listAllProviders(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, 
							providers: response, 
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
                        }));
					}
				})
		}
	}, [providers, dispatch])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: receivedData.selectedData ? receivedData.selectedData : '',
				pageType: receivedData.pageType ? receivedData.pageType : '',
			}))
		} else {
			setState(prevState => ({ ...prevState, pageType: 'Create' }))
		}
	}, [receivedData])


	useEffect(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			setState(prevState => ({ ...prevState,
				selectedProvider: state.selectedData.provider,
				selectedAccount: state.selectedData.account_id,
				selectedRegion: state.selectedData.region,
				selectedCluster: state.selectedData.cluster_name,
				qos_name: state.selectedData.qos_name
			}))

			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.qos_name = state.selectedData.qos_name
			dispatch(getQosPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						let parameters = response.qos_policy && response.qos_policy.parameters ? response.qos_policy.parameters : []
						let obj = response.qos_policy ? response.qos_policy : {}
						// dispatch(setHpcPropsDetails('qosParamaters', parameters))
						dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
						setState(prevState => ({ ...prevState, parameters }))
					}
				})
		}
	}, [state.selectedData, dispatch])

	// Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
		if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
				let selectedAccount = propProjAccounts[label].length ? propProjAccounts[label][0].account_id : ''
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
								let selectedAccount = response.length ? response[0].account_id : ''
                                setState(prevState => ({ ...prevState, accounts: response, selectedAccount }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]);

    useEffect(() => {
        if(state.selectedProvider && state.selectedAccount) {
			let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount && state.selectedAccount.length) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: propProjRegions[label].length ? propProjRegions[label][0].region : '' }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response, selectedRegion: response.length ? response[0].region : '' }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, state.selectedAccount, propProjRegions]);

	useEffect(() => {
        if (state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
			params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
						let selectedCluster = response && response.length ? response[0] : ''
                        setState(prevState => ({ ...prevState, clusters: response, selectedCluster }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

	useEffect(() => {
		if(state.pageType && state.pageType !== 'edit') {
			let params = {
				name: 'qos'
			}
			dispatch(getQosParameters(params))
				.then((response) => {
					if(response && !response.error) {
						let parameters = response.parameters ? response.parameters : []
						setState(prevState => ({ ...prevState, parameters }))
					}
				})
		}
	}, [state.pageType, dispatch])

	const onFinish = () => {
		let hasError = false
		let data = qosCreateEditInputs
		data.qos_name = state.qos_name

		if (!state.qos_name || !data.provider || !data.account_id || !data.region || !data.cluster_name) {
			hasError = true
		}
		// let parameters = data.parameters ? data.parameters : []
		// let parameterIssueMessage = ''
		// if(parameters.length) {
		// 	if(state.pageType === 'edit') {
		// 		state.parameters.forEach(row => {
		// 			if(row.value) {
		// 				if(!parameters.filter(e => e.paramter === row.paramter).length || !parameters.filter(e => e.paramter === row.paramter)[0].value) {
		// 					hasError = true	
		// 					parameterIssueMessage = 'Existing qos policy should not be left empty'
		// 				}
		// 			}
		// 		})
		// 	}
		// } else {
		// 	hasError = true
		// 	if(state.pageType === 'edit') {
		// 		parameterIssueMessage = 'Please fill the exising qos details to proceed'
		// 	} else {
		// 		parameterIssueMessage = 'Existing qos policy should not be left empty'
		// 	}
		// }

		setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

		if(!hasError) {
			if(!state.pageType || state.pageType !== 'edit') {
				setState(prevState => ({ ...prevState, callCreateQos: true, saveLoading: true }))
			} else {
				setState(prevState => ({ ...prevState, callUpdateQos: true, saveLoading: true }))
			}
		}
	}

	useEffect(() => {
		if(state.callCreateQos) {
			setState(prevState => ({ ...prevState, callCreateQos: false }))
			let params = qosCreateEditInputs
			dispatch(createQosPolicy(params))
				.then((response) => {
					if(response) {
						let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in saving Qos'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Qos saved successfully'
							dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
							navigate(URL_PATH.MANAGE_QOS)
                        } else {
							setState(prevState => ({ ...prevState, saveLoading: false }))
						}
						CommonNotification.addNotification({
							//title: 'Wonderful!',
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ['animate__animated', 'animate__fadeIn'],
							// animationOut: ['animate__animated', 'animate__fadeOut'],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});

						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callCreateQos, qosCreateEditInputs, dispatch, navigate])

	// To update the state
	const updateLocationState = useCallback(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.qos_name = state.selectedData.qos_name
			dispatch(getQosPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						navigate(URL_PATH.CREATE_QOS, { 
							state: {
								selectedData: response.qos_policy ? response.qos_policy : {},
								pageType: 'edit',
							}
						})
					}
				})
		}
	}, [state.selectedData, dispatch, navigate])

	useEffect(() => {
		if(state.callUpdateQos) {
			setState(prevState => ({ ...prevState, callUpdateQos: false }))
			let params = qosCreateEditInputs
			dispatch(editQosPolicy(params))
				.then((response) => {
					if (response) {
						let messageType = 'danger'
						let message = response.message ? response.message : 'Error in updating Qos'
						if(response.status) {
							messageType = 'success'
							message = response.message ? response.message : 'Qos updated successfully'
							// updateLocationState()
							navigate(URL_PATH.MANAGE_QOS)
						}
						setState(prevState => ({ ...prevState, saveLoading: false }))
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							},
						})
						
						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callUpdateQos, qosCreateEditInputs, dispatch, updateLocationState, navigate])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div className='' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
			<div className="bg-NeutralGray-200 px-8 py-10 text-NeutralGray-800 shadow-xl">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center ">
                        <p className="font-bold text-2xl">{state.pageType === 'edit' ? 'Modify' : 'Create'} Qos</p>
					</div>
                </div>
            </div>
			<div className='m-6 overflow-y-auto h-[calc(100vh-290px)] bg-white rounded-2xl p-6'>
                <div className='pb-6'>
                    <div className={`flex flex-wrap`}> 
						<div className='lg:w-1/2 w-full lg:pr-4'>
							<Textbox
								label={"Qos Name"}
								type="text"
								selectedValue={state.qos_name}
								callback={(value) => {
									setState(prevState => ({ ...prevState, qos_name: noSpace(value)}))
									handleInputChange('qos_name', noSpace(value))
								}}
								placeholder={'Enter qos name'}
								manditory={true}
								hasError={state.hasError}
								disabled={state.pageType === 'edit'}
							/>
							<p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.category}</p>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='w-full pt-4 lg:pt-0'>
							<Textarea
								label={'Description'}
								placeholder={'Enter description'}
								rows={4}
								wordLength={1000}
								callback={(value) => {
									setState(prevState => ({ ...prevState, description: value }))
									handleInputChange("description", value)
								}}
							/>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='flex justify-start w-1/2 pr-4'>
							<SelectOption
								label={"Provider"}
								fields={["provider_name", "provider_name"]}
								options={state.providers}
								selectedValues={state.selectedProvider ? state.selectedProvider : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState,  selectedProvider: value, selectedAccount: '', selectedRegion: '', selectedCluster: '' }))
									handleInputChange('provider', value.toLowerCase())
								}}
								manditory={true}
								hasError={state.hasError}
								singleSelection={true}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
						<div className='flex justify-start w-1/2 pl-4'>
							<SelectOption
								label={"Account"}
								fields={["account_id", "account_name"]}
								options={state.accounts}
								selectedValues={state.selectedAccount ? state.selectedAccount : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({  ...prevState,  selectedAccount: value, selectedCluster: '' }))
									handleInputChange('accpunt_id', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
					</div>
					<div className='flex flex-wrap pt-8'>
						<div className='flex justify-start w-1/2 pr-4'>
							<SelectOption
								label={"Region"}
								fields={["region", "name"]}
								options={state.regions}
								selectedValues={state.selectedRegion ? state.selectedRegion : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: '' }))
									handleInputChange('region', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
						<div className='flex justify-start w-1/2 pl-4'>
							<SelectOption
								label={"Cluster Name"}
								options={state.clusters}
								selectedValues={state.selectedCluster ? state.selectedCluster : ""}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedCluster: value }))
									handleInputChange('cluster_name', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.hasError}
								dropdownWidth={'w-full'}
								disabled={state.pageType === 'edit'}
							/>
						</div>
					</div>
				</div>

				{!state.parameters || !state.parameters.length ? (
					<div className='flex justify-center m-4'>
						<LoadingCircle />
					</div>
				) : (
					<React.Fragment>
						<div className='rounded-lg bg-GhostWhite p-3 mt-3'>
							{state.parameterIssueMessage ?
								<small className='text-danger ml-2 self-center'>{state.parameterIssueMessage}</small>
							: null}
							<QosDetails 
								masterData={state.parameters}
								pageType={state.pageType}
								onChildPageClick={(event) => handleChildClick(event, 'parent')}
							/>
						</div>
						{state.selectedProvider && state.selectedCluster && state.parameters && state.pageType ?
							<div className='rounded-lg bg-GhostWhite p-3 mt-3'>
								<QosAssocationDetails 
									selectedCluster={state.selectedCluster}
									masterData={state.parameters}
									pageType={state.pageType}
									onChildPageClick={(event) => handleChildClick(event, 'parent')}
								/>
							</div>
						: null}
					</React.Fragment>
				)}
			</div>
			<div className="w-full p-8 shadow-lg bg-NeutralGray-700">
				<div className={`flex justify-center`}>
					{state.hasError ?
						<p className='text-white text-lg mr-3 self-center'>Please fill all the required fields</p>
					: null}
					<div className={`self-center flex justify-end`}>
						<Button
							classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
							label={'Cancel'}
							callback={() => 
								navigate(URL_PATH.MANAGE_QOS, { state: { selectedManage: 'QOS' } })
							}
						/>
						{state.pageType !== 'View' ?
							state.saveLoading ?
							<Button
								classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
								label={state.pageType === 'edit' ? 'Updating' : 'Submitting'}
								loading={true}
							/>
						:
							<Button
								classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
								label={state.pageType === 'edit' ? 'Update' : 'Submit'}
								callback={() => {
									onFinish()
								}}
							/>
						:null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateUpdateQos
