/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Recommendations.js
 * @author Prakash // on 10/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useRef } from 'react';
import FilterSection from '../../common/FiltersSection';
import RecommendationsLeftSection from './RecommendationsLeftSection'
import RecommendationsRightSection from './RecommendationsRightSection'

const Recommendations = () => {

    const clickOutside = useRef()

    const[state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region'],
    })

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion,
            callSearch: true,
            showLoading: true,
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: '', selectedAccount: [], selectedRegion: [] }));
    }

    return (
        <div className='px-6'>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="w-full text-white text-[28px] text-semibold">Recommendations</p>
                        <p className="text-white text-base">Summary of analysis and observations of the chosen Cloud resources</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={()=>{}}
                            onReset={onReset}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className={`lg:w-5/12 w-full h-100`}>
                        <RecommendationsLeftSection 
                            showLoading={state.showLoading}
                            callSearch={state.callSearch}
                            selectedProvider={state.selectedProvider}
                            selectedAccount={state.selectedAccount}
                            selectedRegion={state.selectedRegion}
                    />
                    </div>
                    <div className={`lg:w-7/12 w-full pl-4 h-100 mt-[100px]`}>
                        <RecommendationsRightSection/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recommendations