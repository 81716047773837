/*************************************************
 * Collider
 * @exports
 * @file CreateProviderAllcoation.js
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders } from '../../../actions/commonActionNew';
import { insertProjectCredits, listDepartments } from '../../../actions/cost/BudgetAction';
import { Store as CommonNotification } from 'react-notifications-component';
import { currentLocaltime, momentDateGivenFormat, yearOptions } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import Textbox from '../../common/Form/Textbox';
import Button from '../../common/Form/Button';
import { setCostPropsDetails } from '../../../actions/cost/CostAction';
import Textarea from '../../common/Form/Textarea';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import CreateProviderDepartmentCredit from './CreateProviderDepartmentCredit';

const queryDateTime = current => {
	return current.isAfter(currentLocaltime())
}

const CreateProviderCredits = (props) => {
	const[state, setState] = useState({
		selectedTab: 'Create',
		departmentSectionArray: [0]
	})
	
	const providers = useSelector(state => state.filters.providers);
	const createInputs = useSelector(state => state?.cost?.costPropsDetails?.createInputs || false);
	const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
            	})
        }
    }, [dispatch, providers]);

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	useEffect(() => {
		let params = {}
        dispatch(listDepartments(params))
            .then((response) => {
                let departmentsList =  response && response.results ? response.results : []
                setState(prevState => ({ ...prevState, departmentsList }))
            })
    }, [dispatch])

	useEffect(() => {
		if(state.structureResponse) {
			setState(prevState => ({ ...prevState, structureResponse: true }))
			let initiatives = state.deparmentCredits?.initiatives || []
			initiatives.forEach((inv, invIndex) => {
				if(inv.projects && inv.projects.length) {
					inv.projects.forEach((proj, projIndex) => {
						proj.provider_credits && Object.entries(proj.provider_credits).forEach(([key, value]) => {
							setState(prevState => ({
								...prevState,
								['credits_'+invIndex+'_'+key+'_'+projIndex]: value?.credits || 0
							}))
						})
					})
				}
			})
		}
	}, [state.deparmentCredits, state.structureResponse, state.selectedProvider])

	const onSaveFunction = () => {
		let params = createInputs

		let hasError = false
		if(!createInputs.year || !createInputs.department || !createInputs.selectedProvider) {
			hasError = true
		}
		setState(prevState => ({ ...prevState, hasError, onClickSave: hasError ? false : true }))
		console.log("params", params)
		if(!hasError) {
			dispatch(insertProjectCredits(params))
				.then((response) => {
					setState(prevState => ({ ...prevState, onClickSave: false}))
					let messageType = 'danger'
					let message = response.message
					if(response) {
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
						if(response.status) {
							messageType = 'success'
							props.refreshApi()
						}
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
					}
				})
		}
	}

	const onChangeHandleValidation = useCallback((field, value, index, subIndex, selectedProviderLower) => {
		let hasError = false
		let obj = createInputs ? createInputs : {}
		
		let providerTotal = {}
		let initiatives = obj.initiatives ? obj.initiatives : []
		if(field === 'credits' && initiatives.length) {
			initiatives.forEach((inv, invIndex) => {
				let projects = inv?.projects || []
				projects.forEach((proj, projIndex) => {
					let providerCredits = proj?.provider_credits || {}
					providerCredits && Object.entries(providerCredits).forEach(([key, cred]) => {
						if(invIndex === index && projIndex === subIndex && key === selectedProviderLower) {
							value = parseInt(value)
						} else {
							value = cred.credits
						}
						providerCredits[key] = value
						providerTotal[key] = (providerTotal?.[key] || 0) + value;
					})
				})
			})

			let totalProviderCredits = createInputs?.provider_credits?.[selectedProviderLower]?.credits || 0

			if(totalProviderCredits < providerTotal[selectedProviderLower]) {
				hasError = true
			}
		} else {
			obj[field] = (field === 'provider' ? value.toLowerCase() : value)
		}
		
		setState(prevState => ({ ...prevState, hasError }))

		console.log("obj", obj)

		dispatch(setCostPropsDetails('createInputs', obj))
		

		// const totals = initiatives.reduce((acc, initiative) => {
		// 	initiative.projects.forEach(project => {
		// 		const creditProviders = project.provider_credits || {};
		// 		console.group("creditProviders", creditProviders)
		// 		for (const credProvider in creditProviders) {
		// 			alert(credProvider)
		// 			acc[credProvider] = (acc[credProvider] || 0) + (creditProviders[credProvider].credits || 0);
		// 		}
		// 	});
		// 	return acc;
		// }, {});
		
	}, [createInputs, dispatch])
	
	const addDeparmentSection = (field) => {
		let rowList = state[field]
		let value = state[field][state[field].length - 1]
		value = value + 1
		rowList.push(value)
		setState(prevState => ({ ...prevState, [field]: rowList }))
	}
	
	const removeDepartmentSection = (field, index) => {
		let rowList = state[field] ? state[field] : [0]
		rowList.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: rowList }))
	}

	const handleExpiryTime = (date) => {
		let expiry_on = momentDateGivenFormat(date, 'YYYY-MM-DD 23:59:59')
		setState(prevState => ({ ...prevState, expiry_on }))
	}

	return (
		<div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Provider Credits</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className={`text-black ${state.onClickSave ? "pointer-events-none" : "cursor-pointer"} self-center`} width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
					</div>
					<div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap text-sm text-center">
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Create' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} 
                                    onClick={() => setState(prevState => ({ ...prevState, credit_name: '', description: '', year: '', department: '', selectedProvider: '', selectedTab: 'Create', pageMode: 'Create' }))}
                                >Create</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Schedules' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Credits' }))}>Credits</span>
                            </li>
                        </ul>
						{state.selectedTab === 'Create' ?
							<React.Fragment>
							<div className="overflow-y-auto h-[calc(100vh-300px)] border border-NeutralGray-500 bg-white rounded-r-2xl rounded-b-2xl">
								<div className='p-8 h-full'>
									<div className='flex flex-wrap'>
										<div className="md:w-1/2 md:pr-3 w-full pb-8">
											<Textbox
												label={'Name'}
												selectedValue={state.credit_name ? state.credit_name : ""}
												callback={(value) => {
													setState(prevState => ({ ...prevState, credit_name: value }))
													onChangeHandleValidation('credit_name', value)
												}}
												inputType={state.pageType}
												manditory={true}
												hasError={state.hasError}
												placeholder={"Enter name"}
											/>
										</div>
										<div className="w-full pb-8">
											<Textarea
												label={'Description'}
												selectedValue={state.description ? state.description : ''}
												callback={(value) => {
													setState(prevState => ({ ...prevState, description: value }))
													onChangeHandleValidation('description', value)
												}}
												placeholder={"Enter description"}
												rows={4}
											/>
										</div>
										<div className="md:w-1/2 md:pr-3 w-full pb-8">
											<SelectOption
												label={"Year"}
												options={yearOptions()}
												selectedValues={state.year ? state.year : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, year: value, department: '' }))
													onChangeHandleValidation('year', value)
												}}
												manditory={true}
												hasError={state.hasError}
												singleSelection={true}
											/>
										</div>
										<div className="md:w-1/2 w-full pb-8">
											<SelectOption
												label={"Provider"}
												fields={['provider_name', 'provider_name']}
												options={state.providers}
												selectedValues={state.selectedProvider ? state.selectedProvider : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, selectedProvider: value, selectedProviderLower: value.toLowerCase(), getDeparmentDetails: true }))
													onChangeHandleValidation('provider', value)
												}}
												manditory={true}
												hasError={state.hasError}
												singleSelection={true}
											/>
										</div>
										<div className="md:w-1/2 md:pr-3 w-full pb-8">
											<Textbox
												label={'Credits'}
												selectedValue={state.credit_amount ? state.credit_amount : ""}
												callback={(value) => {
													setState(prevState => ({ ...prevState, credit_amount: value }))
													onChangeHandleValidation('credit_amount', value)
												}}
												inputType={state.pageType}
												manditory={true}
												hasError={state.hasError}
												placeholder={"Enter credits"}
											/>
										</div>
										<div className="md:w-1/2 w-full costBudgetcredit">
											<p className={`text-NeutralGray-800 text-base flex w-full`}>Expiry on</p>
											<Datetime 
												value={momentDateGivenFormat(state.expiry_on, 'DD MMM YYYY')}
												onChange={handleExpiryTime}
												dateFormat={'DD MMM YYYY'}
												timeFormat={false}
												inputProps={{ readOnly: true, placeholder: 'DD MM YYYY', style: {width: '100%', fontSize: '16px'} }}
												className="border border-NeutralGray-400 px-3 py-2 rounded-lg text-lg rounded-2xl w-full text-NeutralGray-600"
												isValidDate={queryDateTime}
												closeOnSelect={true}
											/>
										</div>
									</div>
									{state.year && state.selectedProvider ? 
										state.departmentSectionArray.map((row, rowindex) => {
											return(
												<React.Fragment>
												<CreateProviderDepartmentCredit 
													year={state.year}
													selectedProvider={state.selectedProvider}
												/>

												<div className='flex justify-end pb-4'>
													{state.departmentSectionArray.length > 1 ?
														<span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => {
															removeDepartmentSection('departmentSectionArray', row)
														}} >Remove
														</span>
													: null}
													{(state.departmentSectionArray.length-1) === rowindex ?
														<p className='ml-3 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' 
														onClick={() => addDeparmentSection('departmentSectionArray')}
														>+ Add Department</p>
													: null}
												</div>
												</React.Fragment>

											)
										})
									: null}
								</div>
							</div>
							</React.Fragment>
						: 
							<div className="overflow-y-auto h-[calc(100vh-200px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 bg-white rounded-r-2xl rounded-b-2xl">
								<div className="overflow-x-scroll p-6">
									<div className='relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600'>
										<table className="w-full text-left">
											<thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-2xl text-base font-semibold text-NeutralGray-800'>
												<tr className="flex-1 w-full">
													<th className={`tracking-wider py-2 pl-4 text-left`}>Name</th>
													<th className={`tracking-wider py-2 pl-4 text-left`}>Total</th>
													<th className={`tracking-wider py-2 pl-4 text-left`}>Available</th>
													<th className={`tracking-wider py-2 pl-4 text-left`}>Expiry</th>
													<th className={`tracking-wider py-2 pl-4 text-left`}>&nbsp;</th>
												</tr>
											</thead>
											<tbody className='text-sm text-NeutralGray-900'>
												{state.filteredListResponse && state.filteredListResponse.length ?
													state.filteredListResponse.map((col, colIndex) => {
														return(
															<tr key={"head1_"+colIndex} className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
																<td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.name}</td>
																<td className={`text-black py-2.5 pl-4 text-left text-md`}>{col?.total || 0}</td>
																<td className={`text-black py-2.5 pl-4 text-left text-md`}>{col?.available || 0}</td>
																<td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.expiry}</td>
																<td>
																	<div className='flex'>
																		<Icon icon="octicon:pencil-24" width={24} height={24} className="mr-1 self-center cursor-pointer text-black" 
																		// onClick={() => onClickEditRule(col)} 
																	/>
																		<Icon icon="mdi:trash" width={24} height={24} className="mr-1 self-center cursor-pointer text-ferrariRed-600"
																			// onClick={() => onDelete(col)} 
																		/>
																	</div>
																</td>
															</tr>
														)
													})
												: 
													<tr>
														<td colSpan={7}>
															<p className='text-center'>No schedule found</p>
														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						}
					</div>
					{state.selectedTab === 'Create' ?
						<div className="w-full p-8 shadow-lg bg-NeutralGray-700">
							<div className='self-start flex justify-center'>
								<Button
									classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
									label={'Cancel'}
									callback={() => props.closeSidePanel()}
								/>
								{state.onClickSave ?
									<Button
										classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36' }}
										loading={true}
										label={state.pageType === 'Edit' ? 'Updating' : 'Saving'}
									/>
								:
									<div className='flex'>
										<Button
											classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36' }}
											label={state.pageType === 'Edit' ? 'Update' : 'Save'}
											callback={() => {
												setState(prevState => ({ ...prevState, onClickSave: true }))
												onSaveFunction('save')
											}}
										/>
									</div>
								} 
							</div>
						</div>
					: null}
				</div>
			</div>
		</div>
	)
}

export default CreateProviderCredits