/*************************************************
 * Collider
 * @exports
 * @file ApexHorizontalStackedBarChart.js
 * @author Prakash // on 09/02/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import { countFormater, capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'

const ApexHorizontalStackedBarChart = (props) => {

    const [state, setState] = useState({})

    const drawGraph = useCallback((graphData) => {
        let graphLables = graphData.labels
        let highestValue = 0
        let series = []
        let categories = []

        Object.entries(graphData.data).forEach(([key, value]) => {
            let dataArray =[]
            if(key !== 'labels' && key !== 'total' && key !== 'belowLabelToolTip' && key !== 'belowLabelToolTip') {
                if(props.xaxisFormat === "categoryString") {
                    dataArray = value
                    categories = graphLables
                }

                if(value && highestValue < value.reduce( function(a, b){ return a + b;}, 0)) {
                    highestValue = value.reduce( function(a, b){ return a + b;}, 0)
                }
                
                let seriesRow = {}
                seriesRow.name = capitalizeFirstLetter(key)
                seriesRow.data = dataArray
                seriesRow.prefixUnit = props.graphData.prefixUnit ? props.graphData.prefixUnit : ''
                seriesRow.belowLabelToolTip = props.graphData.belowLabelToolTip ? props.graphData.belowLabelToolTip : []
                series.push(seriesRow)
            }
        })

        let barDataLabels = {}
        if(props.dataLabelPosition && props.dataLabelPosition !== "") {
            barDataLabels.position = props.dataLabelPosition
            // barDataLabels.enabled = false
            barDataLabels.total = {
                enabled: true,
                offsetX: highestValue ? -10 : 0,
                style: {
                    fontSize: '13px',
                    fontWeight: 900,
                    color: '#FFF',
                }
              }
        }
        let xaxisTitle = {}
        if(props.showTitle) {
            xaxisTitle.text = props.graphData && props.graphData.unit ? props.graphData.unit : ''
            xaxisTitle.style = {
                color: props.axisLabelColor,
                fontSize:  '14px',
                fontWeight:  'bold',
                fill:  {
                    colors: [props.axisLabelColor],
                }
            }
        }
        let yaxisTitle = {}
        if(props.yaxisTitle) {
            yaxisTitle.text = props.yaxisTitle
            yaxisTitle.style = {
                color: props.axisLabelColor,
                fontSize:  '14px',
                fontWeight:  'bold',
                fill:  {
                    colors: [props.axisLabelColor],
                }
            }
        }

        let events = {}
        if(props.dataPointSelection) {
            events.dataPointSelection = function(e, chartContext, config) {
                if(chartContext.w.config.series && chartContext.w.config.series[config.seriesIndex] && chartContext.w.config.series[config.seriesIndex].data && chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex] && chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex].x) {
                    props.selectedGraphData(chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex].x)
                }
            }
        }
        let gridXaxisBorder = {}
        if(props.gridXaxisBorder) {
            gridXaxisBorder = {
                show: true,
                color: '#434B5E',
            }
        }

        let tooltip = {}

        let tooltipFixed = {}
        if(props.tooltipFixed) {
            tooltipFixed =  {
                enabled: true,
                position: props.tooltipFixed,
                // offsetX: 0,
                // offsetY: 0,
            }
        }

        if(props.xaxisFormat === "categoryString") {
            tooltip = {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let returnData = ''
                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                            let prefixUnit = w.globals.initialSeries[index].prefixUnit !== '' ? w.globals.initialSeries[index].prefixUnit : ''
                            let belowLabelToolTip = w.globals.initialSeries[index].belowLabelToolTip !== '' ? w.globals.initialSeries[index].belowLabelToolTip : ''

                            let totalValue = 0
                            Object.entries(w.globals.initialSeries).forEach(([key, value], sumIndex) => {
                                totalValue += w.globals.initialSeries[sumIndex].data[dataPointIndex]
                            })

                            if(!index) {
                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                                returnData += '<span class="f16">'+w.globals.labels[index]+'</span>'
                                if(!props.hideTotal) {
                                    returnData += '<span style="color: #999999"> ('+totalValue+')<span>'
                                }
                                if(belowLabelToolTip && belowLabelToolTip.length) {
                                    belowLabelToolTip.forEach(tool => {
                                        returnData += '<div class="mr-2 f14" style="color: #999999">'+tool.label+': <span class="ml-2 text-white f14">'+tool.data[dataPointIndex]+'<span></div>'
                                    })
                                }
                                returnData += '</div>'
                            }
                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label mr-2">'+w.globals.initialSeries[index].name+'</span>'
                            if(prefixUnit !== '') {
                                returnData += '<span> ' + prefixUnit + '</span>'
                            }
                            returnData += '<span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex]+'</span></div></div></div>'
                        })
                    }

                    returnData += '</div>'
                    return returnData
                },
                style: {
                    fontSize: '9px',
                },
                fixed: tooltipFixed
                // x: {
                //     show: true,
                //     offset: 10, // Adjust the x-offset as needed
                // },
                // y: {
                //     show: true,
                //     offset: 10, // Adjust the y-offset as needed
                // },
            }
        } 

        let options = {
            tooltip: tooltip,
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: props.zoom,
                },
                sparkline: {
                    enabled: props.sparkline
                },
                animations: {
                    enabled: false
                },
                type: 'bar',
                height: 150,
                stacked: props.stacked,
                events: events
            },
            colors: props.colors ? props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: props.horizontal,
                    dataLabels: barDataLabels
                },       
            },
            dataLabels: {
                enabled: false
            }   ,
            // fill: fillData,        
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: props.gridXaxisBorder ? props.gridXaxisBorder : false
                    },
                    axisBorder: gridXaxisBorder,
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: props.paddingLeft ? props.paddingLeft : 10
                },
            },
            xaxis: {
                show: props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: props.xaxisFormat && (props.xaxisFormat === "date" || props.xaxisFormat === "datetime") ? "datetime" : (props.xaxisFormat ? props.xaxisFormat : "datetime"),
                categories: (props.xaxisFormat === "category" || props.xaxisFormat === "categoryString") ? categories : [],
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    datetimeUTC: false,
                    show: props.xaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                
                crosshairs: {
                    show: false,
                    width: 0,
                    position: 'back',
                    opacity: 0,
                    stroke: {
                        color: '#b6b6b6',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'solid',
                        color: '#B1B9C4',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
                title: xaxisTitle
            },
            yaxis: {
                show: props.yaxis,
                // props.tickAmount: props.yaxisTickAmont ? props.yaxisTickAmont : 4,
                axisBorder: {
                    show: true,
                    color: '#969AA7',
                },
                tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                labels: {
                    show: props.yaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                    formatter: (value) => {
                        if (typeof value === "string") {
                            return value
                        } else {
                            if(highestValue <= 1) {
                                return parseInt(value)

                            } else {
                                return countFormater(value, "round")
                            }
                        }
                        // if(Number(value) === value && value % 1 === 0) {
                        // } else {
                        //     return Math.ceil(value.toFixed(1))
                        // }
                    },
                },
                title: yaxisTitle
            },
            legend: {
                show: props.legend,
                position: props.legendPostion ? props.legendPostion : "bottom",
                horizontalAlign: props.legendHorizontalAlign ? props.legendHorizontalAlign : 'center',
                // offsetX: 40,
                labels: {
                    // colors: [props.axisLabelColor],
                    useSeriesColors: props.legendUseSeriesColor
                }
            },
        }
        setState(prevState => ({ ...prevState, series, options, graphLables }));
    }, [props])

    useEffect(() => {
        let graphData = props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        } else if(graphData === 'mockDataString') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64]
            graphData.labels = ["Dep 1", "Dep 2", "Dep 3", "Dep 4", "Dep 5", "Dep 6", "Dep 7"]
        } else if(graphData === "top5MockData") {
            graphData = {}
            graphData.data = [45,67,34,71,41]
            graphData.labels = ["Data 1", "Data 2", "Data 3", "Data 4", "Data 5"]
        }
        
        if(graphData && Object.entries(graphData).length) {
            drawGraph(graphData)
        }
    }, [props, drawGraph])

    return (
        state.series ?
            <div className={`${props.className}`} id="chart-container">
                <Chart options={state.options} series={state.series} type="bar" height={props.height} width={props.width ? props.width : "100%"}/>
            </div>
        : null
    )
}

export default ApexHorizontalStackedBarChart