/*************************************************
 * Collider
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2020 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'

const ApexStackedBarChart = (props) => {

    const [state, setState] = useState({
        tooltipType: props.tooltipType
    })
    
    useEffect(() => {
        let totalValue = 0

        let graphData = props.graphData;
        // if(graphData && !Object.entries(graphData).length) {
        //     let labels = [props.start_time, props.end_time]
        //     graphData = {
        //         labels,
        //         status: [0, 0]
        //     }
        // }
        if(graphData){
            let colors = props.colors ? props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let formatedColorsArray = []
            let series = []
            let graphLables = graphData.labels
            
            let highestValue = 0
            let categories = []
            if(props.xaxisFormat === "categoryDateString") {
                graphLables.forEach(item => {
                    categories.push(momentDateGivenFormat(item, props?.xaxisDateFormat || 'DD MMM'))
                })
            }
            Object.entries(graphData).forEach(([key, value]) => {
                let dataArray =[]
                if(key !== 'labels' && key !== 'tooltipLabel') {
                    if(props.xaxisFormat === "categoryString") {
                        dataArray = value
                        categories = graphLables
                    } else {
                        if(props.xaxisFormat === "categoryDateString") {
                            graphLables && graphLables.forEach((item, i) => {
                                let dataItems = {}
                                dataItems.x = momentDateGivenFormat(item, props?.xaxisDateFormat || 'DD MMM')
                                dataItems.y = value[i] ? value[i] : 0
                                dataArray.push(dataItems)
                                
                                if(highestValue < value[i]) {
                                    highestValue = value[i]
                                }
                            })
                        } else {
                            graphLables && graphLables.forEach((item, i) => {
                                categories.push(momentDateGivenFormat(graphLables[i], 'MMM Y'))
                                let date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                if(props.tooltipType && props.tooltipType === "dontConvertDate") {
                                    if(props.xaxisFormat === "category") {
                                        date = momentDateGivenFormat(graphLables[i], 'MMM Y')
                                    } else if(props.xaxisFormat ==="date") {
                                        date = momentDateGivenFormat(graphLables[i], 'DD MMM Y')
                                    } else if(props.xaxisFormat ==="datetime") {
                                        date = momentDateGivenFormat(graphLables[i], 'DD MMM YYYY HH:mm')
                                    }
                                } else {
                                    if(props.xaxisFormat ==="date") {
                                        date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM Y')
                                    } else if(props.xaxisFormat ==="datetime") {
                                        date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                    } else {
                                        date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                    }
                                }
    
                                let dataItems = {}
                                dataItems.x = date
                                dataItems.y = value[i] ? value[i] : 0
                                dataArray.push(dataItems)
                                
                                if(highestValue < value[i]) {
                                    highestValue = value[i]
                                }
                            })
                        }
                    }

                    if(props.formatedColors && Object.entries(props.formatedColors).length) {
                        if(props.formatedColors.hasOwnProperty(key.toLowerCase())) {
                            formatedColorsArray.push(props.formatedColors[key.toLowerCase()])
                        } else {
                            formatedColorsArray.push("#7052BC")
                        }
                    } 
                    
                    let seriesRow = {}
                    seriesRow.name = capitalizeFirstLetter(key)
                    seriesRow.data = dataArray
                    series.push(seriesRow)
                } else if(key === "labels" && Object.entries(graphData).length === 1){
                    graphLables && graphLables.forEach((item, i) => {
                        let dataItems = {}
                        dataItems.x = momentDateGivenFormat(item, props?.xaxisDateFormat || 'DD MMM')
                        dataItems.y = 0
                        dataArray.push(dataItems)
                    })

                    let seriesRow = {}
                    seriesRow.name = "No data"
                    seriesRow.data = dataArray
                    series.push(seriesRow)
                }
            })

            if(formatedColorsArray && formatedColorsArray.length) {
                colors = formatedColorsArray
            }
        
            let backgroundColors = {}
            if(props.showBackgroundBarColors) {
                backgroundColors = {
                    backgroundBarColors: props.backgroundBarColors ? props.backgroundBarColors : ['#E6E6E6'],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: props.backgroundBarShape && props.backgroundBarShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
                }
            }

            let tooltip = {}

            let labelFormat = (props.xaxisFormat === 'datetime' ? (props.xaxisDateFormat ? props.xaxisDateFormat : 'DD MMM YYY HH:mm') : '')

            if(props.xaxisFormat === "categoryString") {
                tooltip = {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {

                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                                    returnData +=  w.globals.labels[index]
                                    returnData += '</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'
                                if(props.unit) {
                                    returnData += '<span>'+props.unit+'</span>'
                                }
                                returnData += w.globals.initialSeries[index].data[dataPointIndex]+'</span></div></div></div>'
                            })
                        }

                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                }
            } else {
                tooltip = {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.values(w.globals.initialSeries).forEach((item, index) => {
                                
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title">'
                                    returnData += (labelFormat ? momentDateGivenFormat(item.data[dataPointIndex].x , labelFormat) : item.data[dataPointIndex].x)
                                    if(totalValue > 0) {
                                        returnData += '<span style="color: #999999"> ('+totalValue+')<span>'
                                    }
                                    returnData += '</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'
                                if(props.unit) {
                                    returnData += '<span>'+props.unit+'</span>'
                                }
                                returnData += item.data[dataPointIndex].y+'</span></div></div></div>'
                            })
                        }

                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                }   
            }   
            let stroke = {}
            if(!props.stroke || props.stroke !== "hide") {
                stroke = {
                    show:  true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: [colors[0]],
                    width: 0,
                    dashArray: 0,      
                }
            }

            let options = {
                tooltip: tooltip,
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: props.zoom,
                    },
                    sparkline: {
                        enabled: props.sparkline
                    },
                    animations: {
                        enabled: props.animation
                    },
                    type: 'bar',
                    height: 150,
                    stacked: props.stacked,
                },
                stroke: stroke,
                colors: colors,
                plotOptions: {
                    bar: {
                        horizontal: props.horizontal,
                        barHeight: props.barHeight,
                        borderRadius: props.barEndShape && props.barEndShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
                        endingShape: props.barEndShape ? props.barEndShape : 'flat',
                        columnWidth: props.columnWidth ? props.columnWidth : graphLables.length < 3 ? "5%" : "60%",
                        // columnWidth: "1%",
                        colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },                
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                },
                xaxis: {
                    show: props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: props.xaxisFormat && (props.xaxisFormat === "date" || props.xaxisFormat === "datetime") ? "datetime" : (props.xaxisFormat ? (props.xaxisFormat === 'categoryDateString' ? 'category' : props.xaxisFormat) : "datetime"),
                    categories: (props.xaxisFormat === "category" || props.xaxisFormat === "categoryString") ? categories : [],
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    tickAmount: categories.length > 6 ? 6 : categories.length,
                    // tickPlacement: "on",
                    labels: {
                        show: props.xaxisLabel,
                        rotate: 360,
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        },
                        // offsetY: !props.legend ? (categories.length < 7 ? 0 : 0) : 0,
                        // offsetX: !props.legend ? (categories.length < 7 ? 20 : 0) : 0
                    },
                    crosshairs: {
                        show: false,
                        width: 0,
                        position: 'back',
                        opacity: 0,
                        stroke: {
                            color: '#b6b6b6',
                            width: 1,
                            dashArray: 0,
                        },
                        // fill: {
                        //     type: 'solid',
                        //     color: '#B1B9C4',
                        //     // gradient: {
                        //     //     colorFrom: '#D8E3F0',
                        //     //     colorTo: '#BED1E6',
                        //     //     stops: [0, 100],
                        //     //     opacityFrom: 0.4,
                        //     //     opacityTo: 0.5,
                        //     // },
                        // },
                        // dropShadow: {
                        //     enabled: false,
                        //     top: 0,
                        //     left: 0,
                        //     blur: 1,
                        //     opacity: 0.4,
                        // },
                    },
                },
                yaxis: {
                    show: props.yaxis,
                    axisBorder: {
                        show: props.hideYaxisLine ? false : true,
                        color: '#434B5E',
                    },
                    tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                    labels: {
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val, index) {
                            if (val > 999) {
                                val = val / 1000

                                if(val % 1 !== 0) {
                                    val = val.toFixed(1);
                                    return val + "K";
                                }
                            } else {
                                if(val > 0 && val < 1) {
                                    return val.toFixed(1);
                                } else {
                                    return parseInt(val)
                                }
                            }
                        },
                        offsetX: -15
                    },
                },
                legend: {
                    show: series.length < 30 && props.legend ? true : false,
                    position: props.legendPosition ? props.legendPosition : "bottom",
                    formatter: function (seriesName, opts) {
                        return `<table>
                        <tr>
                            <th>${seriesName}</th>
                        </tr>
                        </table>`;
                    },
                    offsetY: 7,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: props.forecast ? [props.colors[0]] : [props.colors[0]],
                        useSeriesColors: props.forecast ? false : true
                    },
                },
            }
            
            setState(prevState => ({ ...prevState, series, options }));
        }
    }, [props])

    return (
        <div className={`${props.className}`}>
            {state.series ?
                <Chart options={state.options} series={state.series} type="bar" height={props.height} />
            : null}
        </div>
    )
}

export default ApexStackedBarChart