
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {Icon} from "@iconify/react";
import { getCommonInformation, momentConvertionUtcToLocalTime } from '../../../../utils/utility';
import { getDownloadUrl, listDownloads } from '../../../../actions/files/FileAction';
import { LoadingCircle } from '../../../common/LoadingCiricle';

const FileDownloadList = ({state, newState, closeSidePanel}) => {
    const clickOut = useRef()
    const [downloadState, setDownloadState] = useState({
        showLoading: true,
        callDownloadList: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel()
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        if(downloadState.callDownloadList) {
            setDownloadState(prevState => ({ ...prevState, callDownloadList: false }))
            let params = {}
            dispatch(listDownloads(params))
                .then((response) => {
                    if(response) {
                        setDownloadState(prevState => ({ ...prevState, listDownloadResponse: response, filterDownlaodResponse: response, showLoading: false }))

                        if(response.filter(e => (e.status.toLowerCase() === 'initiated'))) {
                            setTimeout(() => { setDownloadState(prevState => ({ ...prevState, callDownloadList: true, showLoading: true }))  }, 30000);
                        }
                    }
                })
        }
    }, [downloadState.callDownloadList, dispatch])

    const enableDownload = (row) => {
        if(row.download_id) {
            let params = {
                download_id: row.download_id
            }
            dispatch(getDownloadUrl(params))
                .then((response) => {
                    if(response) {
                        if(response.status) {
                            let fileUrl = response.url
                            // Open the new tab immediately
                            const newTab = window.open("", "_blank"); // This avoids being blocked
            
                            if (newTab) {
                                // Update the new tab's URL once the response is received
                                newTab.location.href = fileUrl;
                            } else {
                                alert("Popup blocked by the browser.");
                            }
                        }
                    }
                })
        }
    }

    const getStatusBgColor = (status) => {        
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'completed') { color = 'bg-green-50 border-2 border-green-600 text-green-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    return (
        <div className="overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0">
                <div className='h-full flex flex-col bg-white w-1/3' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='flex text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Downloads</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)]'>
                        <div className='p-8'>
                            <div className='relative overflow-x-auto rounded-t-2xl border border-NeutralGray-400'>
                                <table className="w-full text-left">
                                    <thead className='uppercase bg-NeutralGray-400 text-tableHeading-600 font-semibold rounded-t-2xl text-base'>
                                        <tr className="flex-1 w-full">
                                            <th className='tracking-wider py-2 pl-4 text-left w-1/2'>File</th>
                                            <th className='tracking-wider py-2 pl-4 text-left'>Date</th>
                                            <th className='tracking-wider py-2 pl-4 text-left'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-sm text-NeutralGray-900'>
                                        {!downloadState.showLoading && downloadState.filterDownlaodResponse && downloadState.filterDownlaodResponse.length ? 
                                            downloadState.filterDownlaodResponse.map((row, rowIndex) => {
                                                return(
                                                    <tr key={"row_"+rowIndex} className={`text-base ${rowIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                        <td className={`py-2.5 pl-4 text-left w-1/2 ${row.status.toLowerCase() === 'completed' ? 'text-byzantineBlue cursor-pointer' : 'text-black'}`}
                                                            onClick={() => {
                                                                if(row.status.toLowerCase() === 'completed') {
                                                                    enableDownload(row)
                                                                }
                                                            }}
                                                        >{row.path ? row.path : ''}</td>
                                                        <td className={`py-2.5 pl-4 text-left`}>{row.completed_at ? momentConvertionUtcToLocalTime(row.completed_at, 'DD MMM YYYY HH:mm') : ''}</td>
                                                        <td className={`py-2.5 pl-4 text-left`}>
                                                            <span className={`rounded-full px-2 py-1 ${getStatusBgColor(row.status)}`}>{row.status}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : 
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className='flex justify-center my-4'>
                                                        {downloadState.showLoading ?
                                                            <LoadingCircle />
                                                        :
                                                            getCommonInformation('downloads')
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex justify-center w-full p-8 shadow-lg bg-NeutralGray-700">
                        <button type="button" className="text-white inline-flex items-center bg-ferrariRed-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" onClick={ () => closeSidePanel()}>Cancel</button>
                    </div> */}
                </div>
            </div>
        </div>
    
    )
}
export default FileDownloadList;
    