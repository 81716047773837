import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import { Icon } from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';
import SelectOption from '../../../common/Form/SelectOption';
import {validateUri, validateDatabaseUsername} from "../../../../utils/utility";

const NewDatabaseSection = (props) => {
  const [state, setState] = useState({});
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};

        if (databaseConfiguration) {
          Object.entries(databaseConfiguration).forEach(([key, value]) => {
              setState((prevState) => ({ ...prevState, [key]: value }));
          });
  
    // if (databaseConfiguration && databaseConfiguration.length) {
    //   Object.keys(databaseConfiguration).map(([key, value]) => {
    //     setState((prevState) => ({ ...prevState, [key]: value }));
    //   });
    }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleDatabaseInput = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let databaseConfiguration = obj.database_configuration ? obj.database_configuration : {};
    databaseConfiguration[label] = value;

    databaseConfiguration[label] = type === "integer" ? parseInt(value) : value;
    obj["database_configuration"] = databaseConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const removefromList = (value, listField) => {
    let filteredListItem = state[listField+''].filter((e) => e !== value);

    let array = [];
    filteredListItem.forEach((row) => {
      let rowValue = {
        value: row,
        label: row,
      };
      array.push(rowValue);
    });
	let databaseConfiguration = createUpdateCluster && createUpdateCluster.database_configuration ? createUpdateCluster.database_configuration : {};
	databaseConfiguration.subnet_ids = filteredListItem

    setState((prevState) => ({ ...prevState, [listField+'']: filteredListItem }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return  (
    <div onClick={(event) => handleChildClick(event, "parent")}>
		<div className='flex flex-wrap pt-8'>
			<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
				<Textbox
					label={"Database name"}
					type="text"
					selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : "" }
					callback={(value) => {
						handleDatabaseInput('database', validateUri(value))
					}}
					placeholder={'Enter database name (Eg. database-1)'}
				/>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.password_secret_arn}</p>
			</div>
			<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pl-4'>
				<Textbox
					label={"Username"}
					type="text"
					selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : "" }
					callback={(value) => {
						handleDatabaseInput('user_name', validateDatabaseUsername(value))
					}}
					placeholder={'Enter username (Eg. admin)'}
					manditory={true}
                	hasError={props.hasError}
				/>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.user_name}</p>
			</div>
		</div>
		<div className='flex flex-wrap pt-8'>
			<div className='flex flex-col justify-start w-1/2 pr-4'>
				<SelectOption
					label={"VPC Id"}
					options={propsClusterFilters && propsClusterFilters.vpc_ids ? Object.keys(propsClusterFilters.vpc_ids) : []}
					selectedValues={state.vpc_id ? state.vpc_id : ""}
					callbackMultiSelect={(value) => {
						setState((prevState) => ({ ...prevState, vpc_id: value}));
                      	handleDatabaseInput("vpc_id", value);
					}}
					singleSelection={true}
					dropdownWidth={'w-full'}
					manditory={true}
                	hasError={props.hasError}
					disabled={props.pageMode === 'Edit'}
				/>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.vpc_id}</p>
			</div>
			<div className='flex flex-col justify-start w-1/2 pl-4'>
				{/* <p className="mb-0 text-danger ml-2">
                  Select atleast 2 subnet ids
                </p> */}
				<SelectOption
					label={"Subnet Ids"}
					options={propsClusterFilters && propsClusterFilters?.vpc_ids?.[state?.vpc_id] ? propsClusterFilters?.vpc_ids?.[state?.vpc_id] : []}
					selectedValues={state.subnet_ids ? state.subnet_ids : []}
					callbackMultiSelect={(value) => {
						handleDatabaseInput("subnet_ids", value);
						setState((prevState) => ({ ...prevState, subnet_ids: value}));
					}}
					dropdownWidth={'w-full'}
					manditory={true}
                	hasError={props.hasError}
					// disabled={props.pageMode === 'Edit'}
				/>
				<div className='flex flex-wrap mt-2'>
					{state.subnet_ids && state.subnet_ids.length ? 
						state.subnet_ids.map(row => {
							return(
								<span className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row}
									<Icon icon="jam:close" width="20" height="20"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'subnet_ids') }/>
								</span>
							)
						})
					: null}
				</div>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.subnet_id}</p>
			</div>
		</div>
		<div className='flex flex-wrap pt-8'>
			<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
				<Textbox
					label={"Minimum Capacity"}
					type="text"
					selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.min_capacity ? createUpdateCluster.database_configuration.min_capacity : "" }
					callback={(value) => {
						handleDatabaseInput("min_capacity", value, "integer")
					}}
					placeholder={'Enter minimum capacity units'}
					// disabled={props.pageMode === 'Edit'}
				/>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.min_capacity}</p>
			</div>
			<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pl-4'>
				<Textbox
					label={"Maximum Capacity"}
					type="text"
					selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : "" }
					callback={(value) => {
						handleDatabaseInput("max_capacity", value, "integer")
					}}
					placeholder={'Enter maximum capacity units'}
					// disabled={props.pageMode === 'Edit'}
				/>
				<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.max_capacity}</p>
			</div>
		</div>
    </div>
  );
};

export default NewDatabaseSection;
