import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../../actions/commonActionNew';
import { listLifecyclePolicies, putLifecyclePolicy, listCoveredAssets, listLifecycleRules } from '../../../actions/CatalogAction' //deleteLifecyclePolicies

import { momentDateGivenFormat, momentTimeGivenFormat, capitalizeFirstLetter, getAccountNameFromId, getRegionName, capitalizeAllLetter, onlyNumeric } from '../../../utils/utility'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Store as CommonNotification } from 'react-notifications-component';
import PageLoading from '../../common/PageLoading';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import {Icon} from "@iconify/react";
import Button from '../../common/Form/Button';
import SelectOption from '../../common/Form/SelectOption';
import Textarea from '../../common/Form/Textarea';
import { LoadingCircle } from '../../common/LoadingCiricle';

const ViewSidePanel = (props) => {

    const tagsRef = useRef();
	const tagKeyRef = useRef();
	const tagValueRef = useRef()
    const clickOut = useRef()
    
	const [state, setState] = useState({
		selectedTab: "Policy",
		showLoading: true,
		shutdownOptions: [
			{label: "Daily/Weekly", value: "daily"},
			{label: "Monthly", value: "monthly"},
		],
		dailyArray: [0],
		notificationArray: ["slack", "email"],
		dayOptions: [
			{label: "Monday", value: "Monday"},
			{label: "Tuesday", value: "Tuesday"},
			{label: "Wednesday", value: "Wednesday"},
			{label: "Thursday", value: "Thursday"},
			{label: "Friday", value: "Friday"},
			{label: "Saturday", value: "Saturday"},
			{label: "Sunday", value: "Sunday"},
		],
		// retain_dependencies: true,
		// retain_configuration: true,

        selectedProvider: props.selectedDetails && props.selectedDetails.provider ? props.selectedDetails.provider : "",
        selectedAccount: props.selectedDetails && props.selectedDetails.account_id ? props.selectedDetails.account_id : "",
        selectedRegion: props.selectedDetails && props.selectedDetails.region ? props.selectedDetails.region : "",
        selectedService: props.selectedDetails && props.selectedDetails.resource_type ? props.selectedDetails.resource_type : "",
        selectedPolicy: props.selectedDetails && props.selectedDetails.policy_type ? props.selectedDetails.policy_type : "",
        tagsSelected: props.selectedDetails && props.selectedDetails.tags ? props.selectedDetails.tags : "",
        selectedServiceDetails: props.selectedDetails ? props.selectedDetails : {},
        // selectedServiceDetails: state.coveredServices.filter(e => e.label === value).length ? state.coveredServices.filter(e => e.label === value)[0] : {},

        selectedAsset: props.selectedDetails && props.selectedDetails.asset_id ? props.selectedDetails.asset_id : "",
        selectedAssetName: props.selectedDetails && props.selectedDetails.asset_name ? props.selectedDetails.asset_name : "",
        selectedAssetDetails: props.selectedDetails ? props.selectedDetails : {},
        description: props.selectedDetails && props.selectedDetails.description ? props.selectedDetails.description : "",

        policyTypes: ["Asset", "Service", "Tags"]
	})

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);
    const location = useLocation()
    const currentPath = location.pathname;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
            let actionsAllowed = []
            userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
                    // else {
                    //     if(row.action) {
                    //         actionsAllowed = row.action
                    //     }
                    // }
                }
            })
            setState(prevState => ({ ...prevState, actionsAllowed }))
        }
    }, [userMenu, currentPath])

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    // Component lifecycle method
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callOnSearch: true,
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callOnSearch: true
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
                            let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            params.monitoring_enabled_services = true
            params.assets_present = true    
            params.include_request = false
            params.aggregate_by = ["resource_type", "service_name"]
            params.radial = true

            dispatch(listLifecycleRules(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        setState(prevState => ({ ...prevState, coveredServices: results }));
                    }
            })
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.pageType, state.selectedService, dispatch])

    useEffect(() => {
        if (state.getAssetDetails) {
            setState(prevState => ({ ...prevState, getAssetDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }

            params.resource_type = state.selectedServiceDetails.resource_type
            params.service_name = state.selectedServiceDetails.service_name

            dispatch(listCoveredAssets(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ 
                            ...prevState, 
                            coveredAssets: response && response.results ? response.results : [],
                            selectedAsset: state.pageType !== "Create" ? state.selectedAsset : "",
                            selectedAssetName: state.pageType !== "Create" ? state.selectedAssetName : "",
                            showLoadingCircle: false
                        }));
                    }
            })
        }
    }, [state.getAssetDetails, state.selectedServiceDetails, state.pageType, state.selectedAccount, state.selectedAsset, state.selectedAssetName, state.selectedProvider, state.selectedRegion, dispatch])

    useEffect(() => {
        if(!tagKeys || !tagKeys.length) {
			let params = {}
			dispatch(getAllTagsKeys(params))
		}
    }, [dispatch, tagKeys])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

    const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			state.tag_value.forEach(row => {
				let dataRow = {
					key: state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			setState(prevState => ({ ...prevState, tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true, callListLifecyclePolicies: filteredTags.length ? true : false }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, tagsSelected: filteredResult }))
	}

    useEffect(() => {
		let monthOptions = []		
		for(let i=1;i<=31;i++) {
			let dataRow = {}
			dataRow.label = i
			dataRow.value = i

			monthOptions.push(dataRow)
		}

		setState(prevState => ({ 
            ...prevState, 
            monthOptions, 
            callListLifecyclePolicies: props.pageType === "Create" ? false : true,
            showLoading: props.pageType === "Create" ? false : true
        }))

	}, [props.pageType])

	useEffect(() => {
		if(state.callListLifecyclePolicies) {
            setState(prevState => ({ ...prevState, callListLifecyclePolicies: false}))
			let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }

            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }

            if(state.listPolicies && state.listPolicies.policy_id && props.pageType !== "Create") {
                params.policy_id = state.listPolicies.policy_id //"i-034019eb63e8dd1a1"
            }

            let callApi = false
            if(state.selectedPolicy === 'Asset') {
                if(state.selectedAsset) {
                    callApi = true
                    params.asset_id = state.selectedAsset
                }
            } else if(state.selectedPolicy === 'Tags') {
                if(state.tagsSelected && state.tagsSelected.length) {
                    callApi = true
                    params.tags = state.tagsSelected
                }
            } else {
                callApi = true
            }


			if(state.selectedServiceDetails && state.selectedServiceDetails.service_name) {
                params.service_name = state.selectedServiceDetails.service_name
            } 
            
            if(state.selectedServiceDetails && state.selectedServiceDetails.resource_type) {
				params.resource_type = state.selectedServiceDetails.resource_type
			}
            
            if(callApi) {
                dispatch(listLifecyclePolicies(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results ? response.results : {}
            
                            let rules = response && response.rules && response.rules.length ? response.rules[0] : {}
                
                            setState(prevState => ({
                                ...prevState,
                                listPolicies: results && results.length ? results[0] : {},
                                editPolicyAction: results.length && Object.entries(results[0]).length ? true : false,
                                listPolicyRules: rules,
                                showLoading: true,
                                ruleOptions: [],
                                callStructurePolicy: true,
                            }))
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, listPolicies: {}, listPolicyRules: {}, showLoading: true, ruleOptions: [] }))
            }
		}
	}, [state.callListLifecyclePolicies, state.selectedPolicy, state.tagsSelected, state.selectedServiceDetails, state.selectedProvider, props.pageType, state.selectedAssetDetails, state.selectedAsset, dispatch, props.selectedDetails, state.selectedAccount, state.selectedRegion, state.listPolicies])

	const validateTimeRange = useCallback((time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"
			} else {
				data += firstMinute
			}
		}

		setState(prevState => ({ ...prevState, [state]: data }))
	}, [])

	useEffect(() => {
		if(state.callStructurePolicy) {
            setState(prevState => ({ ...prevState, callStructurePolicy: false }))
			let data = state.listPolicies
	
			let dailyArray = []

			if(data && Object.entries(data).length) {
				let dailyPolicies = data.policies && data.policies.filter(e => e.schedule === "daily").length ? data.policies.filter(e => e.schedule === "daily") : []
				if(dailyPolicies && dailyPolicies.length) {
					dailyPolicies.forEach((item, index) => {
						dailyArray.push(index)
						
						let selectedStopOptions = ""
						let selectedStartOptions = ""
						if(item.action && Object.entries(item.action).length) {
							Object.entries(item.action).forEach(([key, value]) => {
								selectedStartOptions = key
								selectedStopOptions = value
							})
						}
						
						setState(prevState => ({
							...prevState, 
							["start_time_"+index]: momentTimeGivenFormat(item.start_time, "HH:mm"),
							["end_time_"+index]: momentTimeGivenFormat(item.end_time, "HH:mm"),
							["selectedDays_"+index]: item.days_of_the_week ? item.days_of_the_week : [],
							["selectedStopOptions_"+index]: selectedStopOptions,
							["selectedStartOptions_"+index]: selectedStartOptions,
							["selectedStartStopOption_"+index]: selectedStartOptions+"/"+selectedStopOptions,
							["first_notification_"+index]: momentTimeGivenFormat(item.first_notification, "HH:mm"),
							["second_notification_"+index]: momentTimeGivenFormat(item.second_notification, "HH:mm"),
							showPolicies: true
						}))
                        if(item.start_time) {
                            validateTimeRange(momentTimeGivenFormat(item.start_time, "HH:mm"), "start_time_"+item)
                        }
                        if(item.end_time) {
                            validateTimeRange(momentTimeGivenFormat(item.end_time, "HH:mm"), "end_time_"+item)
                        }
                        if(item.first_notification) {
                            validateTimeRange(momentTimeGivenFormat(item.first_notification, "HH:mm"), "first_notification_"+item)
                        }
                        if(item.second_notification) {
                            validateTimeRange(momentTimeGivenFormat(item.second_notification, "HH:mm"), "second_notification_"+item)
                        }
					})
				} else {
					dailyArray = [0]
				}
	
				let monthlyPolicy = data.policies && data.policies.filter(e => e.schedule === "monthly").length ? data.policies.filter(e => e.schedule === "monthly") : []
	
				if(monthlyPolicy && monthlyPolicy.length) {
					monthlyPolicy.forEach(item => {
						let monhlySelectedStopOptions = ""
						let monthlySelectedStartOptions = ""
						if(item.actions && Object.entries(item.actions).length) {
							Object.entries(item.actions).forEach(([key, value]) => {
								monthlySelectedStartOptions = key
								monhlySelectedStopOptions = value
							})
						}
	
						setState(prevState => ({
							...prevState, 
							start_time: momentTimeGivenFormat(item.start_time, "HH:mm"),
							end_time: momentTimeGivenFormat(item.end_time, "HH:mm"),
							selectedStartDay: item.start_day,
							selectedEndDay: item.end_day,
							selectedStartOptions: monthlySelectedStartOptions,
							selectedStopOptions: monhlySelectedStopOptions,
							showPolicies: true
						}))
					})
				}
	 
				// if(data.notifications) {
				// 	state.notificationArray.forEach(row => {
				// 		this.setState({ ["shared_details_"+row]:  data.notifications[row] ? data.notifications[row] : [] })
				// 	})
				// }
	
				setState(prevState => ({
					...prevState, 
					notification_enabled: data.notification_enabled,
					retain_configuration: data.retain_configuration,
					retain_dependencies: data.retain_dependencies,
					expiration_time: data.expiration_time ? momentDateGivenFormat(data.expiration_time, "YYYY-MM-DD HH:mm") : "",
					CPU: data.metrics && data.metrics.threshold && data.metrics.threshold.CPU ? data.metrics.threshold.CPU : '',
					Memory: data.metrics && data.metrics.threshold && data.metrics.threshold.Memory ? data.metrics.threshold.Memory : '',
					// Disk: data.metrics && data.metrics.threshold && data.metrics.threshold.Disk ? data.metrics.threshold.Disk : '',
					GPU: data.metrics && data.metrics.threshold && data.metrics.threshold.GPU ? data.metrics.threshold.GPU : '',
					// duration_in_hrs: data.metrics && data.metrics.duration_in_hrs ? data.metrics.duration_in_hrs : '',
					extended_duration_in_hrs: data.extended_duration_in_hrs ? data.extended_duration_in_hrs : '',
				}))
			}
	
			let ruleOptions = []
			let dailyRuleOptions = []
			let rules = state.listPolicyRules
			// if(rules && rules.actions && Object.entries(rules.actions).length) {
			// let actions = [{
			// 	"launch": ["shutdown", "snapshot-shutdown"],
			// 	"snapshot-restore": ["shutdown 1", "snapshot-shutdown 1"]
			// }]
			if(rules && rules.actions && rules.actions.length) {
				rules.actions.forEach(row => {
					Object.entries(row).forEach(([key, value]) => {
						let dataRow = {}
						dataRow.label = key
						dataRow.value = key
						dataRow.endValue = value
						dataRow.startValue = key
						ruleOptions.push(dataRow)
					})
				})
	
				rules.actions.forEach(row => {
					Object.entries(row).forEach(([key, value]) => {
						if(Array.isArray(value)) {
							value.forEach(valRow => {
								let dataRow = {}
								dataRow.label = key+"/"+valRow
								dataRow.value = key+"/"+valRow
								dataRow.endValue = valRow
								dataRow.startValue = key
								dailyRuleOptions.push(dataRow)
							})
						} else {
							let dataRow = {}
							dataRow.label = key+"/"+value
							dataRow.value = key+"/"+value
							dataRow.endValue = value
							dataRow.startValue = key
							dailyRuleOptions.push(dataRow)						
						}
					})
				})
	
				if(ruleOptions && ruleOptions.length === 1) {				
					setState(prevState => ({ ...prevState, selectedStartStopOption: ruleOptions[0].startValue, selectedStopOptions: ruleOptions[0].endValue, selectedStartOptions: ruleOptions[0].startValue }))
				}
			}
	
			if(!dailyArray.length) {
				dailyArray = [0]
			}
	
			setState(prevState => ({ ...prevState, dailyArray,  ruleOptions,  dailyRuleOptions,  showLoading: false }))
		}
	}, [state.callStructurePolicy, state.listPolicies, state.listPolicyRules, validateTimeRange])

	
	const handleExpiationChange = (date) => {
		let expiration_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		setState(prevState => ({ ...prevState, expiration_time}))
	}

	const addNewSharedDetails = (item) => {
		let sharedDetails = state["shared_details_"+item] ? state["shared_details_"+item] : []
        if(state["shared_to_"+item] && state["shared_to_"+item] !== "") {
			sharedDetails.push(state["shared_to_"+item])
		}

		setState(prevState => ({ ...prevState,["shared_details_"+item]: sharedDetails, ["shared_to_"+item]: "" }))
    }

	const removeSharedDetails = (item, sharedTo) => {
		let filteredResult = state["shared_details_"+item].filter(e => e !== sharedTo)
        setState(prevState => ({ ...prevState,["shared_details_"+item]: filteredResult }))
    }

	const addSection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }	

    const removeSection = (item) => {
        setState(prevState => ({ ...prevState, ["start_time_"+item]: "", ["end_time_"+item]: "", ["selectedDays_"+item]: "", ["selectedStartOptions_"+item]: "", ["selectedStopOptions_"+item]: "" }))

		let rowList = state.dailyArray;
		rowList.splice(item, 1);
		setState(prevState => ({ ...prevState, dailyArray: rowList }))
    }

	const removeDays = (item, day) => {
		let filteredResult = state["selectedDays_"+item].filter(e => e !== day)
        setState(prevState => ({ ...prevState,["selectedDays_"+item]: filteredResult }))
    }

	const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTimeRange(str, state)
		} else {
			validateTimeRange(time, state)
		}
	}

    useEffect(() => {
        if(state.resetState) {
            state.dailyArray.forEach(item => {
                setState(prevState => ({
                    ...prevState,
                    ["start_time_"+item]: "",
                    ["end_time_"+item]: "",
                    ["selectedDays_"+item]: [],
                    ["selectedStopOptions_"+item]: "",
                    ["selectedStartOptions_"+item]: "",
                    ["selectedStartStopOption_"+item]: ""
                }))
            })
            
            setState(prevState => ({
                ...prevState,
                dailyArray: [0],
                start_time: "",
                end_time: "",
                selectedStartDay: "",
                selectedEndDay: "",
                selectedStartOptions: "",
                selectedStopOptions: "",
                showPolicies: false,
                // notification_enabled: false,
                // retain_dependencies: true,
		        // retain_configuration: true,
                expiration_time: "",
                resetState: false,
                callListLifecyclePolicies: true
            }))
        }

    }, [state.resetState, state.dailyArray])

    

	// const handleInput = e => {
	// 	const { name, value } = e.target;
	// 	setState(prevState => ({ ...prevState, [name]: value }))
	// }

	const createUpdatePolicy = () => {
		let notifications = {}
		state.notificationArray.forEach(row => {
			notifications[row] = state["shared_details_"+row] && state["shared_details_"+row].length ? state["shared_details_"+row] : []
		})

		let hasError = false

		let policies = []
        state.dailyArray && state.dailyArray.forEach(item => {
            if(state["end_time_"+item] && state["selectedDays_"+item] && state["selectedStartOptions_"+item] && state["selectedStopOptions_"+item]) {
                if(state["start_time_"+item] && (state["start_time_"+item] > state["end_time_"+item])) {
                    hasError = true
                }

                let notifciationErrorMessage = []
                if(state["start_time_"+item] && state["end_time_"+item]){
					if(state["first_notification_"+item] && (state["first_notification_"+item] < state["start_time_"+item] || state["first_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('First notification time should be between the start and end time of the policy')
						hasError = true
					}

					if(state["second_notification_"+item] && (state["second_notification_"+item] < state["start_time_"+item] || state["second_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('Second notification time should be between the start and end time of the policy')
						hasError = true
					}
				} else if (state["end_time_"+item]){
					if(state["first_notification_"+item] && (state["first_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('First notification time should be less than end time of the policy')
						hasError = true
					}

					if(state["second_notification_"+item] && (state["second_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('Second notification time should be less than end time of the policy')
						hasError = true
					}
				}

                if(state["first_notification_"+item] && state["second_notification_"+item]) {
                    if(state["first_notification_"+item] > state["second_notification_"+item]) {
                        notifciationErrorMessage.push('First notification time should not be greater than second notification time')
                        hasError = true
                    }
                }

                setState(prevState => ({ ...prevState, ['notifciationErrorMessage_'+item]: notifciationErrorMessage }))

                let dataRow = {}
                dataRow.schedule = "daily"
                dataRow.start_time = state["start_time_"+item] ? momentTimeGivenFormat(state["start_time_"+item], "HH:mm:00") : ""
                dataRow.end_time = state["end_time_"+item] ? momentTimeGivenFormat(state["end_time_"+item], "HH:mm:00") : ""
                dataRow.days_of_the_week = state["selectedDays_"+item]
                dataRow.first_notification = state["first_notification_"+item] ? momentTimeGivenFormat(state["first_notification_"+item], "HH:mm:00") : ""
                dataRow.second_notification = state["second_notification_"+item] ? momentTimeGivenFormat(state["second_notification_"+item], "HH:mm:00") : ""
                let action = {}
                action[state["selectedStartOptions_"+item]] = state["selectedStopOptions_"+item]
                dataRow.action = action

                policies.push(dataRow)
            } else {
                hasError = true
            }
        });

		if(state.start_time && state.end_time && state.selectedStartDay && state.selectedEndDay && state.selectedStartOptions && state.selectedStopOptions) {
			let dataRow = {}
			dataRow.schedule = "monthly"
			dataRow.start_time = state.start_time ? momentTimeGivenFormat(state.start_time, "HH:mm:00") : ""
			dataRow.end_time = state.end_time ? momentTimeGivenFormat(state.end_time, "HH:mm:00") : ""
			dataRow.start_day = state.selectedStartDay
			dataRow.end_day = state.selectedEndDay
			let action = {}
			action[state.selectedStartOptions] = state.selectedStopOptions
			dataRow.action = action

			policies.push(dataRow)
		}
		
		let monthlyErroMessage = ''
		if(state.start_time > state.end_time) {
			hasError = true
			monthlyErroMessage = 'start time should be less that end time'
		}

		let params = {}
        params.description = state.description ? state.description : ""
		params.policies = policies
		
		if(state.extended_duration_in_hrs) {
			params.extended_duration_in_hrs = parseInt(state.extended_duration_in_hrs)
		}
		let metrics = {}
		// if(state.duration_in_hrs) {
		// 	metrics.duration_in_hrs = parseInt(state.duration_in_hrs)
		// }
		let threshold = {}
		if(state.CPU) {
			threshold.CPU = parseInt(state.CPU)
			if(state.CPU > 100) {
				hasError = true
			}
		}
		if(state.Memory) {
			threshold.Memory = parseInt(state.Memory)
			if(state.Memory > 100) {
				hasError = true
			}
		}
		// if(state.Disk) {
		// 	threshold.Disk = parseInt(state.Disk)
		// 	if(state.Disk > 100) {
		// 		hasError = true
		// 	}
		// }
		if(state.GPU) {
			threshold.GPU = parseInt(state.GPU)
			if(state.GPU > 100) {
				hasError = true
			}
		}
		metrics.threshold = threshold

		params.metrics = metrics
		if(state.expiration_time) {
			params.expiration_time = momentDateGivenFormat(state.expiration_time, "YYYY-MM-DD HH:mm:00")
		}
        params.retain_configuration = true
        params.retain_dependencies = true
        
        if(state.selectedAsset && state.selectedAsset.length && props.pageType === "Create") {
            params.asset_id = state.selectedAsset
        } else if(state.listPolicies && state.listPolicies.policy_id && props.pageType !== "Create") {
            params.policy_id = state.listPolicies.policy_id //"i-034019eb63e8dd1a1"
        }

        params.policy_type = state.selectedPolicy

        if(state.selectedPolicy === 'Asset') {
            if(state.selectedAsset) {
                params.asset_id = state.selectedAsset
            }
        } else if(state.selectedPolicy === 'Tags') {
            if(state.tagsSelected && state.tagsSelected.length) {
                params.tags = state.tagsSelected
            }
        }

        if(state.selectedServiceDetails && state.selectedServiceDetails.service_name) {
            params.service_name = state.selectedServiceDetails.service_name
        } 
        
        if(state.selectedServiceDetails && state.selectedServiceDetails.resource_type) {
            params.resource_type = state.selectedServiceDetails.resource_type
        }

        params.account_id = state.selectedAccount
        params.region = state.selectedRegion
        params.provider = state.selectedProvider.toLowerCase()

        
		setState(prevState => ({ ...prevState, hasError, monthlyErroMessage, submitLoading: hasError ? false : true }))
        
		if(!hasError && policies && policies.length) {
			dispatch(putLifecyclePolicy(params))
				.then((response) => {
					let messageType = ""
					if(response.status) {
						messageType = 'success'
						setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
                        props.closeSidePanel()
                        props.refreshlist()
					} else {
						messageType = 'danger'
					}
                    setState(prevState => ({ ...prevState, submitLoading: false }))
		
					let message = response && response.message ? response.message : 'Error in inserting the schedule policy '
		
					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				})
		}
	}    

	// const deletePolicy = () => {
    //     let params = {}
    //     params.policy_id = state.listPolicies.policy_id
    //     dispatch(deleteLifecyclePolicies(params))
    //         .then((response) => { 
    //             let messageType = "danger"
    //             if(response.status) {
    //                 messageType = 'success'
	// 				state.dailyArray.forEach(item => {
	// 					setState(prevState => ({
	// 						...prevState,
	// 						["start_time_"+item]: "",
	// 						["end_time_"+item]: "",
	// 						["selectedDays_"+item]: [],
	// 						["selectedStopOptions_"+item]: "",
	// 						["selectedStartOptions_"+item]: "",
	// 						["selectedStartStopOption_"+item]: ""
	// 					}))
	// 				})
					
	// 				setState(prevState => ({
	// 					...prevState, 
	// 					dailyArray: [0],
	// 					callListLifecyclePolicies: true
	// 				}))
    //                 props.refreshlist()
    //             }
	// 			setState(prevState => ({ ...prevState, deleteLoading: false }))

    //             let message = response && response.message ? response.message : 'Error in deleting lifecycle policy '
                
    //             CommonNotification.addNotification({
    //                 message: message,
    //                 type: messageType,
    //                 insert: "top",
    //                 container: "top-center",
    //                 dismiss: {
    //                     duration: 5000,
    //                     onScreen: false,
    //                     pauseOnHover: true,
    //                     showIcon: true,
    //                 }
    //             });
    //         })

	// }

    useEffect(() => {
        if(state.clearTime) {
            setState(prevState => ({ ...prevState, clearTime: false, hideDateTime: false }))
        }
    }, [state.clearTime])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }

		if (tagsRef.current &&  !tagsRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showTagFilter: false }))
		} else if (tagsRef.current) {
			setState(prevState => ({ ...prevState, showTagFilter: true }))
		}
		
		if (tagKeyRef.current && !tagKeyRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, isTagKeyOpen: false }))
		} else if (tagKeyRef.current) {
			setState(prevState => ({ ...prevState, isTagKeyOpen: true }))
		}

		if (tagValueRef.current && !tagValueRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, isTagValueOpen: false }))
			addNewTag("tags")
		} else if (tagValueRef.current) {
			setState(prevState => ({ ...prevState, isTagValueOpen: true }))
		}

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>

            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-black'>Create Lifecycle</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
                    </div>

                    <div className='overflow-y-auto h-[calc(100vh-100px)] bg-white rounded-2xl p-6'>
                        <div className='p-8 h-full'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProvider: value,changesInFilter: true }))
                                        }}
                                        manditory={true}
                                        hasError={state.hasError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value,changesInFilter: true, coveredAssets: [] }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.hasError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-wrap pt-8'>
                                {state.selectedAccount ?
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <SelectOption
                                            label={"Region"}
                                            fields={["region", "name"]}
                                            options={state.regions}
                                            selectedValues={state.selectedRegion ? state.selectedRegion : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, selectedRegion: value, changesInFilter: true, coveredAssets: [] }))
                                            }}
                                            manditory={true}
                                            hasError={state.hasError}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}

                                        />
                                    </div>
                                : null}
                                {state.selectedRegion && state.coveredServices?.length && props.pageType === "Create" ?
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <SelectOption
                                            label={"Services"}
                                            fields={["label", "label"]}
                                            options={state.coveredServices}
                                            selectedValues={state.selectedService ? state.selectedService : ''}
                                            callbackMultiSelect={(value) => {
                                                let selectedServiceDetails = state.coveredServices.filter(e => e.label === value).length ? state.coveredServices.filter(e => e.label === value)[0] : {}
                                                setState(prevState => ({ ...prevState, selectedService: value, selectedServiceDetails, selectedAsset: '', listPolicies: {}, listPolicyRules: {} }))
                                            }}
                                            manditory={true}
                                            hasError={state.hasError}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                : null}
                            </div>

                            <div className='flex flex-wrap pt-8'>
                                {state.selectedService ?
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <SelectOption
                                            label={"Policy Type"}
                                            // fields={["label", "label"]}
                                            options={state.policyTypes}
                                            selectedValues={state.selectedPolicy ? state.selectedPolicy : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState,  showLoadingCircle: true, selectedPolicy: value, getAssetDetails: value === 'Asset' ? true : false, selectedAsset: '', tagsSelected: [], callListLifecyclePolicies: value === 'Service' ? true : false }))
                                            }}
                                            manditory={true}
                                            hasError={state.hasError}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                : null}
                                {state.selectedService && state.selectedPolicy === 'Asset' ?
                                    state.showLoadingCircle ?
                                        <LoadingCircle/>
                                    :
                                        <div className='flex justify-start w-1/2 pr-4'>
                                            <SelectOption
                                                label={"Asset"}
                                                fields={["asset_id", "asset_name"]}
                                                options={state.coveredAssets}
                                                selectedValues={state.selectedAsset ? state.selectedAsset : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedAsset: value, selectedAssetName: state.coveredAssets.filter(e => e.asset_id === value).length ? state.coveredAssets.filter(e => e.asset_id === value)[0].asset_name : '', selectedAssetDetails: state.coveredAssets.filter(e => e.asset_id === value).length ? state.coveredAssets.filter(e => e.asset_id === value)[0] : {}, ruleOptions: [], dailyRuleOptions: [], resetState: true }))
                                                }}
                                                manditory={true}
                                                hasError={state.hasError}
                                                singleSelection={true}
                                                dropdownWidth={'w-full'}
                                            />
                                        </div>
                                : null}
                            </div>

                            {state.selectedService && state.selectedPolicy === 'Tags' ?
                            <React.Fragment>
                                <div className='flex flex-wrap pt-8'>
                                    <div className='flex justify-start w-1/3 pr-4'>
                                        <SelectOption
                                            label={"Tags"}
                                            options={tagKeys}
                                            selectedValues={state.tag_key ? state.tag_key : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, tag_key: value, isTagKeyOpen: false, tagValues: [] }))
                                            }}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                    <div className='flex justify-start w-1/3 pr-4'>
                                        <SelectOption
                                            options={state.tagValues}
                                            selectedValues={state.tag_value ? state.tag_value : []}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, tag_value: value }))
                                            }}
                                            singleSelection={false}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                    <div className='flex justify-end pt-8 pl-4'>
                                        <Button
                                            classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                            label={'+'}
                                            callback={() => {
                                                addNewTag("tags")
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-wrap mt-2'>
                                    {state.tagsSelected && state.tagsSelected.length ? 
                                        state.tagsSelected.map((tag, index) => {
                                            return(
                                                <span key={"tag_"+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{tag.key +" : "+tag.value}
                                                    <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                            </React.Fragment>
                            : null}

                        <div>
                            {state.showLoading ?
                                <LoadingCircle/>
                            : !state.showPolicies ?
                                !state.ruleOptions || !state.ruleOptions.length ?
                                    <div className='flex justify-center m-4'>
                                        <p>There are no Policies for this asset</p>
                                    </div>
                                :
                                    <div className='flex justify-end'>
                                        <button className="bg-DeepPurpleTints-600 text-white text-nowrap text-base font-semibold mt-4 mx-4 py-2.5 px-[19px] rounded-full self-center" onClick={() => setState(prevState => ({ ...prevState, showPolicies: !state.showPolicies }))}>+ Add Policy</button>
                                    </div>
                            :
                                <div className="mt-2">
                                    <div className='flex flex-wrap pt-8'>
                                        <div className='w-full pt-4 lg:pt-0'>
                                            <Textarea
                                                label={'Description'}
                                                placeholder={'Enter policy description'}
                                                rows={4}
                                                selectedValue={state.selectedValue ? state.selectedValue : ''}
                                                wordLength={1000}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, description: value }))
                                                }}
                                            />
                                        </div>
                                    </div>

                                <div className="rounded-2xl p-3 mt-6 border border-NeutralGray-600 bg-NeutralGray-100 text-sm">
                                    <div className="flex justify-between text-black cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showDailySection: !state.showDailySection }))}>
                                        <div className="flex">
                                            <p className="mb-0 text-base font-semibold text-primaryPurple-600 ">Daily/Weekly</p>
                                        </div>
                                        <Icon icon={`fa:angle-${state.showDailySection ? 'up' : 'down'}`} width={24} height={24} className='text-NeutralGray-600 ml-4 cursor-pointer self-center' />
                                    </div>
                                    {state.showDailySection ?
                                        <React.Fragment>
                                        {state.dailyArray.map((item, iIndex) => {
                                            return(
                                                <div className={`${iIndex ? 'border-t border-DeepPurpleTints-600 mt-6 pt-6' : ''}`} key={"dail_"+item}>
                                                    {state.hasError ?
                                                        // !state["start_time_"+item] ?
                                                        //     <p className='mb-1 text-ferrariRed-600'>Please enter start time</p>
                                                        !state["end_time_"+item] ?
                                                            <p className='mb-1 text-ferrariRed-600'>Please enter end time</p>
                                                        : state["start_time_"+item] && state["start_time_"+item] > state["end_time_"+item] ?
                                                            <p className='mb-1 text-ferrariRed-600'>Start time should not exceed end_time</p>
                                                        : (!state["selectedDays_"+item] || !state["selectedDays_"+item].length) ?
                                                            <p className='mb-1 text-ferrariRed-600'>Please select hrs on</p>
                                                        : !state["selectedStartStopOption_"+item] ?
                                                            <p className='mb-1 text-ferrariRed-600'>Please select lifecycle method</p>
                                                        : null
                                                    : null}
                                                    <div className="flex justify-between">
                                                        <div className="flex flex-wrap mb-4">
                                                            <p className={`mb-0 mr-1 mt-2 self-center text-black`}>Resource should be available between business hours</p>
                                                            {/* ${state.hasError && !state["start_time_"+item] ? 'dottedDangerTextboxLine' : ''} */}
                                                            <input 
                                                                type="text" 
                                                                className="text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-24" 
                                                                style={{minHeight: "38px"}}
                                                                placeholder={"HH:MM"}
                                                                value={state["start_time_"+item]}
                                                                maxLength="5"
                                                                onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "start_time_"+item)}
                                                                onChange={e => validateTimeRange(e.target.value, "start_time_"+item)}
                                                                disabled={!state.actionsAllowed.includes('update')}
                                                            />

                                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>to</p>

                                                            <input 
                                                                type="text" 
                                                                className="text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-24"
                                                                style={{minHeight: "38px"}}
                                                                placeholder={"HH:MM"}
                                                                value={state["end_time_"+item]}
                                                                maxLength="5"
                                                                onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "end_time_"+item)}
                                                                onChange={e => validateTimeRange(e.target.value, "end_time_"+item)}
                                                                disabled={!state.actionsAllowed.includes('update')}
                                                            />
                                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-gray-500 text-sm`}> (utc time 24-hour format) </p>

                                                            <p className={`mb-0 ml-2 mr-1 mt-3 self-center text-black`}>hrs on</p>
                                                            <div className={`self-center mx-2`}>
                                                                <SelectOption
                                                                    label={""}
                                                                    fields={["value", "label"]}
                                                                    options={state.dayOptions}
                                                                    selectedValues={state["selectedDays_"+item] ? state["selectedDays_"+item] : []}
                                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["selectedDays_"+item]: value }))}
                                                                    singleSelection={false}
                                                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                                    disabled={!state.actionsAllowed.includes('update')}
                                                                />
                                                            </div>
                                                            {state["selectedDays_"+item] && state["selectedDays_"+item].length ? 
                                                                state["selectedDays_"+item].map((day, index) => {
                                                                    return(
                                                                        <span key={"day_"+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{day}
                                                                            <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={ () => removeDays(item, day) } />
                                                                        </span>
                                                                    )
                                                                })
                                                            : null}
                                                            <p className={`mb-0 ml-1 mr-1 mt-2 self-center text-black`}>.  Apply</p>
                                                            <div className={`self-center mx-2`}>
                                                                <SelectOption
                                                                    label={""}
                                                                    fields={["value", "label"]}
                                                                    options={state.dailyRuleOptions}
                                                                    selectedValues={state["selectedStartStopOption_"+item] ? state["selectedStartStopOption_"+item] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        setState(prevState => ({ 
                                                                            ...prevState, 
                                                                            ["selectedStartStopOption_"+item]: value, 
                                                                            ["selectedStopOptions_"+item]: state.dailyRuleOptions.filter(e => e.value === value)[0].endValue, 
                                                                            ["selectedStartOptions_"+item]: state.dailyRuleOptions.filter(e => e.value === value)[0].startValue
                                                                        }))
                                                                    }}
                                                                    singleSelection={true}
                                                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                                    disabled={!state.actionsAllowed.includes('update')}
                                                                />
                                                            </div>
                                                            <p className={`mb-0 ml-1 mr-1 mt-2 self-center text-black`}>lifecycle method</p>
                                                        </div>	
                                                        {/* {state.dailyArray.length > 1 ?
                                                            <span className={`far fa-trash cursor-pointer f18 self-center ml-4 w-5`} onClick={() => removeSection(item)}></span>
                                                        : null} */}
                                                    </div>
                                                    
                                                    <div className="flex mt-2">
                                                        <div className="flex flex-wrap mb-2">
                                                            <p className={`mb-0 mr-1 mt-2 self-center text-black min-w-[200px]`}>Send first notification at :</p>
                                                            {/* ${state.hasError && !state["start_time_"+item] ? 'dottedDangerTextboxLine' : ''} */}
                                                            {!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
                                                                <input 
                                                                    type="text" 
                                                                    // disabled={!state['end_time_'+item] ? true : false}
                                                                    className={`w-1/4 text-black bg-white border-b border-NeutralGray-600 py-2.5 pl-2 shadow-sm focus:outline-none bg-transparent`}
                                                                    style={{minHeight: "38px"}}
                                                                    placeholder={"HH:MM"}
                                                                    value={state["first_notification_"+item]}
                                                                    maxLength="5"
                                                                    onKeyDown={e => {
                                                                        onKeyDownTimeRange(e, e.target.value, "first_notification_"+item)
                                                                        setState(prevState => ({ ...prevState, ['firstNotificationError_'+item]: '' }))
                                                                    }}
                                                                    onChange={e => {
                                                                        validateTimeRange(e.target.value, "first_notification_"+item)
                                                                    }}
                                                                />
                                                            :
                                                                state["first_notification_"+item] ?
                                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center text-info`}>{state["first_notification_"+item]}</p>
                                                                :
                                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center border-b border-NeutralGray-600 w-5`}></p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex mt-2">
                                                        <div className="flex flex-wrap mb-2">
                                                            <p className={`mb-0 mr-1 mt-2 self-center text-black min-w-[200px]`}>Send second notification at :</p>
                                                            {!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
                                                                <input 
                                                                    type="text" 
                                                                    // disabled={!state['end_time_'+item] ? true : false}
                                                                    className={`w-1/4 text-black bg-white border-b border-NeutralGray-600 py-2.5 pl-2 shadow-sm focus:outline-none bg-transparent`}
                                                                    style={{minHeight: "38px"}}
                                                                    placeholder={"HH:MM"}
                                                                    value={state["second_notification_"+item]}
                                                                    maxLength="5"
                                                                    onKeyDown={e => {
                                                                        onKeyDownTimeRange(e, e.target.value, "second_notification_"+item)
                                                                        setState(prevState => ({ ...prevState, ['secondNotificationError_'+item]: '' }))
                                                                    }}
                                                                    onChange={e => {
                                                                        validateTimeRange(e.target.value, "second_notification_"+item)
                                                                    }}
                                                                />
                                                            :
                                                                state["second_notification_"+item] ?
                                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center text-info`}>{state["second_notification_"+item]}</p>
                                                                :
                                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center border-b border-NeutralGray-600 w-5`}></p>
                                                            }
                                                        </div>
                                                    </div>
                                                    {state.hasError && state["notifciationErrorMessage_"+item] && state["notifciationErrorMessage_"+item].length ? 
                                                        <div className='mt-2'>
                                                            {state["notifciationErrorMessage_"+item].map(not => {
                                                                return(
                                                                    <p className='mb-2 text-ferrariRed-600'>{not}</p>
                                                                )
                                                            })}
                                                        </div>
                                                    : null}

                                                    {state['secondNotificationError_'+item] || state['firstNotificationError_'+item] ?
                                                        <p className='flex text-ferrariRed-600 mb-2'>{state['secondNotificationError_'+item] ? state['secondNotificationError_'+item] : (state['firstNotificationError_'+item] ? state['firstNotificationError_'+item] : '')}</p>
                                                    : null}
                                                    {((state.editPolicyAction && state.actionsAllowed.includes('update')) || (!state.editPolicyAction && state.actionsAllowed.includes('create'))) ?
										
                                                        <div className='flex justify-end mb-2'>
                                                            {state.dailyArray.length === iIndex+1 ?
                                                                <span className="text-base ml-3 px-4 py-2.5 self-center border border-DeepPurpleTints-600 bg-DeepPurpleTints-600 rounded-full text-white cursor-pointer" onClick={() => addSection("dailyArray")}>Add New Policy</span>
                                                            : null}
                                                            {state.dailyArray.length > 1 ?
                                                                <span className="text-base ml-3 px-4 py-2.5 self-center border border-ferrariRed-600 bg-red-600 rounded-full text-white cursor-pointer" onClick={() => removeSection(item)}> Remove Policy</span>
                                                            : null}
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })}
                                        {/* <span className="text-info mt-2 mb-5 cursor-pointer" onClick={() => addSection("dailyArray")}>+ Add New Policy</span> */}
                                        </React.Fragment>
                                    : null}
                                </div>

                                <div className="rounded-2xl p-3 mt-6 border border-NeutralGray-600 bg-NeutralGray-100 text-sm">
                                    <div className="flex justify-between text-black cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showMonthlySection: !state.showMonthlySection }))}>
                                        <div className="flex">
                                            <p className="mb-0 text-base font-semibold text-primaryPurple-600 " >Monthly</p>
                                        </div>
                                        <Icon icon={`fa:angle-${state.showMonthlySection ? 'up' : 'down'}`} width={24} height={24} className='text-NeutralGray-600 ml-4 cursor-pointer self-center' onClick={() => setState(prevState => ({ ...prevState, showMonthlySection: !state.showMonthlySection }))}/>
                                    </div>
                                    {state.showMonthlySection ?
                                        <div className="flex flex-wrap mb-2">
                                            <p className={`mb-0 mr-1 mt-2 self-center text-black`}>Resource should be</p>
                                            <div className={`self-end mx-2`}>
                                                <SelectOption
                                                    label={""}
                                                    fields={["value", "label"]}
                                                    options={state.ruleOptions}
                                                    selectedValues={state.selectedStartOptions ? state.selectedStartOptions : []}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ 
                                                        ...prevState, 
                                                        selectedStartOptions: value, 
                                                        selectedStopOptions: Array.isArray(state.ruleOptions.filter(e => e.value === value)[0].endValue) ? "" : state.ruleOptions.filter(e => e.value === value)[0].endValue, 
                                                        stopRuleOptions: Array.isArray(state.ruleOptions.filter(e => e.value === value)[0].endValue) ? state.ruleOptions.filter(e => e.value === value)[0].endValue : []
                                                    }))}
                                                    singleSelection={true}
                                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                    disabled={!state.actionsAllowed.includes('update')}
                                                />
                                            </div>
                                            
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>at</p>

                                            <input 
                                                type="text" 
                                                className={`text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-24`}
                                                // style={{minHeight: "38px"}}
                                                placeholder={"HH:MM"}
                                                value={state.start_time}
                                                maxLength="5"
                                                onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "start_time")}
                                                onChange={e => validateTimeRange(e.target.value, "start_time")}
                                            />											
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-gray-500 text-sm`}> (utc time 24-hour format) </p>
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>hrs on day</p>
                                            
                                            <div className={`self-end mx-2`}>
                                                <SelectOption
                                                    label={""}
                                                    fields={["value", "label"]}
                                                    options={state.monthOptions}
                                                    selectedValues={state.selectedStartDay ? state.selectedStartDay : ''}
                                                    callbackMultiSelect={(value) =>setState(prevState => ({ ...prevState, selectedStartDay: value }))}
                                                    singleSelection={true}
                                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                    disabled={!state.actionsAllowed.includes('update')}
                                                />
                                            </div>
                                            
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>and </p>

                                            {state.stopRuleOptions && state.stopRuleOptions.length ?
                                                <div className={`self-end mx-2`}>
                                                    <SelectOption
                                                        label={""}
                                                        // fields={["value", "label"]}
                                                        options={state.stopRuleOptions}
                                                        selectedValues={state.selectedStopOptions ? state.selectedStopOptions : ''}
                                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedStopOptions: value }))}
                                                        singleSelection={true}
                                                        classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                        disabled={!state.actionsAllowed.includes('update')}
                                                    />
                                                </div>
                                            :
                                                <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state.selectedStopOptions}</p>
                                            }
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>at</p>

                                            <input 
                                                type="text" 
                                                className="text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-24"
                                                style={{minHeight: "38px"}}
                                                placeholder={"HH:MM"}
                                                value={state.end_time}
                                                maxLength="5"
                                                onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "end_time")}
                                                onChange={e => validateTimeRange(e.target.value, "end_time")}
                                            />
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-gray-500 text-sm`}> (utc time 24-hour format) </p>
                                            <p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>hrs on day</p>

                                            <div className={`self-end mx-2 mt-2`}>
                                                <SelectOption
                                                    label={""}
                                                    fields={["value", "label"]}
                                                    options={state.monthOptions}
                                                    selectedValues={state.selectedEndDay ? state.selectedEndDay : []}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedEndDay: value }))}
                                                    singleSelection={true}
                                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                                    disabled={!state.actionsAllowed.includes('update')}
                                                />
                                            </div>
                                        </div>
                                    : null}
                                </div>

                                <div className="flex flex-wrap py-3 text-sm">
                                    <p className={`mb-0 mr-2 mt-2 self-center text-black text-base min-w-[200px]`}>Expire the Resources on: </p>
                                    {!state.hideDateTime ?
                                        <Datetime 
                                            value={state.expiration_time && state.expiration_time !== "" ? momentDateGivenFormat(state.expiration_time, 'DD MMM YYYY HH:mm') : ""}
                                            onChange={handleExpiationChange}
                                            dateFormat={'DD MMM YYYY'}
                                            timeFormat={'HH:mm'}
                                            inputProps={{ readOnly: true, class: "" }}
                                            className="mt-3 text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent"
                                            // isValidDate={disableFutureDt}
                                            disabled={!state.actionsAllowed.includes('update')}
                                        />
                                    : null}
                                    {state.actionsAllowed.length > 0 ?
                                        <p className="mb-0 mt-2 ml-2 text-primaryPurple-600 self-center cursor-pointer" 
                                            onClick={() => setState(prevState => ({ ...prevState, expiration_time: "", hideDateTime: true, clearTime: true }))}
                                        >
                                            Clear
                                        </p>
                                    : null}
                                </div>

                                <div className="flex flex-wrap py-3 text-sm">
                                    <p className={`mb-0 mr-1 mt-1 text-black self-center text-base min-w-[200px]`}>Notification Reminder in hrs: </p>
                                    <div className="w-1/5">
                                        <input 
                                            type="text" 
                                            className={`text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent`}
                                            placeholder={"hrs"}
                                            value={state.extended_duration_in_hrs}
                                            onChange={e => setState(prevState => ({ ...prevState, extended_duration_in_hrs: onlyNumeric(e.target.value) }))}
                                            disabled={!state.actionsAllowed.includes('update')}
                                        />
                                    </div>
                                </div>

                                <p className='text-black pt-3 text-base min-w-[200px]'>Metrics
                                    <span className='mb-0 ml-2 mr-1 mt-2 self-center text-gray-500 text-sm'>(applied for workstation)</span>
                                {state.actionsAllowed.length > 0 ?
                                    <span className="text-primaryPurple-600 ml-4 text-sm cursor-pointer" 
                                        onClick={() => setState(prevState => ({ ...prevState, CPU: '', GPU: '', Memory: '' }))}
                                    >
                                        Clear
                                    </span>
                                : null}
                                </p>
                                <div className='rounded-lg mt-4 p-3 border border-NeutralGray-600 bg-NeutralGray-200'>
                                    <div className="flex mt-2">
                                        <div className='flex w-33'>
                                            <p className={`mb-0 mr-1 mt-1 self-center text-black`}>CPU</p>
                                            {!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
                                                <input 
                                                    type="text" 
                                                    className={`text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-36`}
                                                    style={{minHeight: "28px"}}
                                                    placeholder={"cpu"}
                                                    value={state.CPU}
                                                    onChange={e => setState(prevState => ({ ...prevState, CPU: onlyNumeric(e.target.value) }))}
                                                />	
                                            :
                                                state.CPU ?
                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center text-info`}>{state.CPU}</p>
                                                :
                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center border-b border-NeutralGray-600 w-10`}></p>
                                            }
                                            <span className='text-NeutralGray-600 self-center mt-1'>%</span>
                                        </div>
                                        {/* {props.selectedDetails && props.selectedDetails.gpu ? */}
                                            <div className='flex w-33 pl-3'>
                                                <p className={`mb-0 mr-1 mt-1 self-center text-black`}>GPU</p>
                                                {!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
                                                    <input 
                                                        type="text" 
                                                        className={`text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-36`}
                                                        style={{minHeight: "28px"}}
                                                        placeholder={"gpu"}
                                                        value={state.GPU}
                                                        onChange={e => setState(prevState => ({ ...prevState, GPU: onlyNumeric(e.target.value) }))}
                                                    />	
                                                :
                                                    state.GPU ?
                                                        <p className={`mb-0 ml-2 mr-1 mt-4 self-center text-info`}>{state.GPU}</p>
                                                    :
                                                        <p className={`mb-0 ml-2 mr-1 mt-4 self-center border-b border-NeutralGray-600 w-10`}></p>
                                                }
                                                <span className='text-NeutralGray-600 self-center mt-1'>%</span>
                                            </div>
                                        {/* : null} */}
                                        <div className='flex w-50 pl-3'>
                                            <p className={`mb-0 mr-1 mt-1 self-center text-black`}>Memory</p>
                                            {!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
                                                <input 
                                                    type="text" 
                                                    className={`text-black bg-white border-b border-NeutralGray-600 pb-1 pl-2 shadow-sm focus:outline-none bg-transparent w-36`}
                                                    style={{minHeight: "28px"}}
                                                    placeholder={"memory"}
                                                    value={state.Memory}
                                                    onChange={e => setState(prevState => ({ ...prevState, Memory: onlyNumeric(e.target.value) }))}
                                                />
                                            :
                                                state.Memory ?
                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center text-info`}>{state.Memory}</p>
                                                :
                                                    <p className={`mb-0 ml-2 mr-1 mt-4 self-center border-b border-NeutralGray-600 w-10`}></p>
                                            }
                                            <span className='text-NeutralGray-600 self-center mt-1'>%</span>
                                        </div>
                                    </div>
                                    {state.hasError && state.CPU > 100 ?
                                        <p className='ml-2 my-2 text-ferrariRed-600 self-center'>CPU should be between 0 and 100</p>
                                    : null}
                                    {props.selectedDetails && props.selectedDetails.gpu && state.hasError && state.GPU > 100 ?
                                        <p className='ml-2 text-ferrariRed-600 self-center'>GPU should be between 0 and 100</p>
                                    : null}
                                    {state.hasError && state.Memory > 100 ?
                                        <p className='ml-2 text-ferrariRed-600 self-center'>Memory should be between 0 and 100</p>
                                    : null}
                                    <div className="flex flex-wrap mt-2 hidden">
                                        <div className='flex w-50'>
                                            <p className={`mb-0 mr-1 mt-2 self-center text-black`}>Disk</p>
                                            <input 
                                                type="text" 
                                                className={`text-black bg-white border border-NeutralGray-600 py-2.5 pl-2 shadow-sm rounded-md`}
                                                style={{minHeight: "38px"}}
                                                placeholder={"disk"}
                                                value={state.Disk}
                                                onChange={e => setState(prevState => ({ ...prevState, Disk: onlyNumeric(e.target.value) }))}
                                            />
                                            {state.hasError && state.Disk > 100 ?
                                                <span className='ml-2 mt-2 text-ferrariRed-600 self-center'>Disk should be between 0 and 100</span>
                                            : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="rounded-lg bg-NeutralGray-300 border border-NeutralGray-900 mt-4 p-3 hidden">
                                    <div className="flex">
                                        <div className={`form-check form-check-inline self-center mr-2 ${state.retain_configuration ? "" : "checkboxGrayColor"}`}>
                                            <input
                                                className='form-check-input m-0'
                                                type='checkbox'
                                                checked={state.retain_configuration}
                                                onChange={e => setState(prevState => ({ ...prevState, retain_configuration: !state.retain_configuration }))}	
                                                // title={state.retain_configuration ? "not enable" : "enable"}
                                            />
                                            {!state.retain_configuration ?
                                                <span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, retain_configuration: !state.retain_configuration}))}></span>
                                            : null}
                                        </div>
                                        <span className="mt-2p f16 text-black">Retain configuration</span>
                                    </div>
                                </div>

                                <div className="rounded-lg bg-NeutralGray-300 border border-NeutralGray-900 mt-4 p-3 hidden">
                                    <div className="flex">
                                        <div className={`form-check form-check-inline self-center mr-2 ${state.retain_dependencies ? "" : "checkboxGrayColor"}`}>
                                            <input
                                                className='form-check-input m-0'
                                                type='checkbox'
                                                checked={state.retain_dependencies}
                                                onChange={e => setState(prevState => ({ ...prevState, retain_dependencies: !state.retain_dependencies }))}	
                                                // title={state.retain_dependencies ? "not enable" : "enable"}								
                                            />
                                            {!state.retain_dependencies ?
                                                <span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, retain_dependencies: !state.retain_dependencies}))}></span>
                                            : null}
                                        </div>
                                        <span className="mt-2p f16 text-black">Retain Dependencies</span>
                                    </div>
                                </div>

                                <div className="rounded-lg bg-NeutralGray-300 border border-NeutralGray-900 mt-4 p-3 hidden">
                                    <div className="flex">
                                        <div className={`form-check form-check-inline self-center mr-2 ${state.notification_enabled ? "" : "checkboxGrayColor"}`}>
                                            <input
                                                className='form-check-input m-0'
                                                type='checkbox'
                                                checked={state.notification_enabled}
                                                onChange={e => setState(prevState => ({ ...prevState, notification_enabled: !state.notification_enabled }))}	
                                                // title={state.notification_enabled ? "not enable" : "enable"}								
                                            />
                                            {!state.notification_enabled ?
                                                <span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, notification_enabled: !state.notification_enabled}))}></span>
                                            : null}
                                        </div>
                                        <span className="mt-2p f16 text-black">Enable Notifications</span>
                                    </div>
                                </div>

                                <div className="rounded-lg bg-NeutralGray-300 border border-NeutralGray-900 mt-4 p-3 hidden">
                                    <div className="flex justify-between">
                                        <p className="mb-0 f16 text-black font-weight-bold">Notifications</p>
                                        {/* <h5 className="mb-0 text-black cursor-pointer flex">Notifications</h5> */}
                                        {/* <span className={`ml-4 fa fa-angle-${state.showNotificationSection ? "up" : "down"} f22 text-black cursor-pointer`} onClick={() => this.setState({ showNotificationSection: !state.showNotificationSection })}></span> */}
                                    </div>
                                    {state.notificationArray.map(item => {
                                        return(
                                            <div key={"day_"+item} className="flex flex-wrap mb-3">
                                                <p className={`mb-0 mr-1 mt-2 self-center text-black`}>{capitalizeFirstLetter(item)} details</p>
                                                <div className="selectBoxBottomColor w-1/5">
                                                    <input 
                                                        type="text" 
                                                        className={`bg-transparent border-b border-NeutralGray-600 px-2 py-1 text-black w-32`}
                                                        style={{minHeight: "38px"}}
                                                        placeholder={"Enter details"}
                                                        value={state["shared_to_"+item]}
                                                        onChange={e => setState(prevState => ({ ...prevState, ["shared_to_"+item]: e.target.value }))}
                                                    />
                                                </div>
                                            
                                                <span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 self-end mr-4`} onClick={() => addNewSharedDetails(item)}></span>
                                                {state["shared_details_"+item] && state["shared_details_"+item].length ? 
                                                    state["shared_details_"+item].map((share, index) => {
                                                        return(
                                                            <span key={"shared_"+index} className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{share}
                                                                <i className='ml-2 f14 fas fa-times-circle cursor-pointer text-gray3' onClick={ () => removeSharedDetails(item, share) } ></i>
                                                            </span>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            }
                            </div>
                        </div>
                    </div>

                    <div className="w-full mt-4 p-8 shadow-lg bg-NeutralGray-700">
                        <div className={'flex justify-center'}>
                            <Button
                                classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                label={'Cancel'}
                                callback={() => 
                                    props.closeSidePanel()
                                }
                            />
                            {state.submitLoading ?
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', margin: 'ml-4', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Updating' : 'Submitting'}
                                    loading={true}
                                />
                            :
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', margin: 'ml-4', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Update' : 'Submit'}
                                    callback={() => {
                                        setState(prevState => ({ ...prevState, submitLoading: true }))
                                        createUpdatePolicy()
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewSidePanel;