/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tachyon
 * @exports
 * @file ExpandedDetails.js
 * @author Prakash
 * @copyright Tachyon. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useRef } from 'react';
import {Icon} from "@iconify/react";
import ExpandedDetails from './ExpandedDetails';
import { toggleScroll } from '../../../utils/utility';

const ExpandedSideBarDetails = ({ selectedProvider, selectedAccount, selectedRegion, selectedClusters, selectedItem, selectedType, startDate, endDate, detailsFormat, closeSidePanel }) => {
    const clickOut = useRef();
    

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {            
            closeSidePanel()
        }
    }

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    // useEffect(() => { toggleScroll(true) }, [])

    return (
        <div className="overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={handleClickOutside}>
                <div className='h-full flex flex-col bg-lavender-600 lg:w-4/5 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 flex justify-between pl-6 py-4'>
                        <span className='font-semibold text-2xl text-NeutralGray-400 capitalize'>{selectedType}: <span className='pl-3 capitalize font-bold text-2xl text-white'>{selectedType === 'cluster' ? selectedItem.cluster_name : selectedType === 'queue' ? selectedItem.queue_name : selectedType === 'project' ? selectedItem.project : selectedItem.userName}</span></span>
                        <Icon icon="jam:close" className='text-white cursor-pointer self-center' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                    <div className="overflow-y-auto h-[calc(100vh-10px)]">
                        <ExpandedDetails 
                            selectedType={selectedType}
                            selectedItem={selectedItem}
                            selectedProvider={selectedProvider}
                            selectedAccount={selectedAccount}
                            selectedRegion={selectedRegion}
                            selectedClusters={selectedClusters}
                            selectedUser={selectedItem.selectedUser}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExpandedSideBarDetails