/*
 * @exports
 * @file ObservabilityAction.js
 * @author Prakash
*/
import ApiCalls from '../../components/ApiCalls'

import {
	LIST_DISK_DETAILS,
	GET_DISK_DETAILS,
	GET_DISK_METRICS,
	GET_QUEUE_METRICS,
	GET_CLUSTER_METRICS,
	GET_CLUSTER_HEAD_NODE_METRICS,
	LIST_TROUBLESHOOT_RESULTS,
} from '../../config'
/**
 * Action to list-disk-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listDiskDetails = (params) => {
	return async () => {
		try {
			const url = LIST_DISK_DETAILS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

/**
 * Action to get-disk-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getDiskDetails = (params) => {
	return async () => {
		try {
			const url = GET_DISK_DETAILS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

/**
 * Action to get-disk-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getDiskMetrics = (params) => {
	return async () => {
		try {
			const url = GET_DISK_METRICS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

/**
 * Action to get-queue-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueMetrics = (params) => {
	return async () => {
		try {
			const url = GET_QUEUE_METRICS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

/**
 * Action to get-cluster-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterMetrics = (params) => {
	return async () => {
		try {
			const url = GET_CLUSTER_METRICS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

/**
 * Action to get-cluster-head-node-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterHeadNodeMetrics = (params) => {
	return async () => {
		try {
			const url = GET_CLUSTER_HEAD_NODE_METRICS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
}

// Action to fetch troubleshoot results
export const listTroubleshootResults = (params) => {
	return async () => {
		try {
			const url = LIST_TROUBLESHOOT_RESULTS;			
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error.response?.data || error;
		}
	};
};