/*************************************************
 * Collider
 * @exports
 * @file LeftSection.js
 * @author Prakash
 *************************************************/
import React, { useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { listDepartments, getCloudProviderCommitment, updateBudget, getDepartmentBudget } from '../../../../actions/cost/BudgetAction'
import { capitalizeAllLetter, onlyNumeric, thousandSeparator } from '../../../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../../config/urlPath';
import {Icon} from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';
import SelectOption from '../../../common/Form/SelectOption';
import Checkbox from '../../../common/Form/Checkbox';
import Textarea from '../../../common/Form/Textarea';

const LeftSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        expandMenuId: 'Department',
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],        
        quarters: [
            [{label: 'Jan', value: 0}, {label: 'Feb', value: 1}, {label: 'Mar', value: 2}],
            [{label: 'Apr', value: 3}, {label: 'May', value: 4}, {label: 'Jun', value: 5}],
            [{label: 'Jul', value: 6}, {label: 'Aug', value: 7}, {label: 'Sep', value: 8}],
            [{label: 'Oct', value: 9}, {label: 'Nov', value: 10}, {label: 'Dec', value: 11}]
        ],
        currencyUnit: 'usd'
	})
    const createInputs = useSelector(state => state?.cost?.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state?.cost?.costPropsDetails.onClickSave || false);
    const departmentList = useSelector(state => state?.cost?.listDepartments || false);

    const navigate = useNavigate();
	const dispatch = useDispatch();

    useEffect(() => {
        setState(prevState => ({ ...prevState, hasError: props.hasError }))
    }, [props.hasError])
    
    useEffect(() => {
        const currentYear = new Date().getUTCFullYear();
        const next10Years = Array.from({ length: 11 }, (_, index) => currentYear + index);
        setState(prevState => ({ ...prevState, yearOptions: next10Years }))

        if(!departmentList || !departmentList.length) {
            let depParams = {}
            dispatch(listDepartments(depParams))
                .then((response) => {
                    let results =  response && response.results ? response.results : []
                    setState(prevState => ({ ...prevState, departmentsList: results }))
                })
        } else {
            setState(prevState => ({
                ...prevState,
                departmentsList: departmentList,
                filteredDepartment: departmentList,
                selectedDepartment: departmentList && departmentList.length ? departmentList[0] : ""
            }))
        }
    }, [dispatch, departmentList])

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                department: props.receivedData.department ? props.receivedData.department : '',
                year: props.receivedData.year ? props.receivedData.year : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData, dispatch])

    const createBudget = useCallback(() => {
        let params = createInputs

        dispatch(updateBudget(params))
            .then((response) => {
                let messageType = 'danger'
                let errorMessage = 'Error in inserting budget'
                if(response.status) {
                    messageType = 'success'
                    errorMessage = response.message ? response.message : 'Budget updated successfully'
                    navigate(URL_PATH.COST_BUDGET_LIST);
                }

                dispatch(setCostPropsDetails('onClickSave', 'stopLoading'))

                let message = response && response.results ? response.results : errorMessage

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }, [createInputs, dispatch, navigate])

    const onChangeHandleValidation = useCallback((value, mnt) => {
        let hasError = false
        let obj = createInputs ? createInputs : {}
        
        let depProviders = []
        if(obj.cloud_budget && Object.entries(obj.cloud_budget).length) {
            let totalAllocation = 0
            let totalDistributionSum = 0
            let departmentInitiativeMonthlyTotalError = []
            let departmentInitiativeAllocationError = []
            let initiativeDepartmentAllocationError = []
            Object.entries(obj.cloud_budget).forEach(([key, value], objIndex) => {
                let departmentDistrubutition = value.monthly_distribution
                let distributionSum = 0
                if(departmentDistrubutition) {
                    distributionSum = departmentDistrubutition.reduce((a, b) => {
                        return a + parseFloat(b);
                    }, 0)
                }

                totalAllocation += Math.round(value.allocation) 
                totalDistributionSum += Math.round(distributionSum)

                let dataRow = {}
                dataRow.provider = key
                dataRow.allocation = Math.round(value.allocation)
                dataRow.distributuion = Math.round(distributionSum)
                dataRow.balance = Math.round(value.allocation - distributionSum)

                let departmentAllocationError = ''
                let departmentOrgAllocationError = []

                depProviders.push(dataRow)

                if(Object.entries(obj.cloud_budget).length-1 === objIndex) {
                    let totalDepRow = {}
                    totalDepRow.provider = 'Total'
                    totalDepRow.allocation = totalAllocation
                    totalDepRow.distributuion = totalDistributionSum
                    totalDepRow.balance = totalAllocation - totalDistributionSum
                    depProviders.unshift(totalDepRow);
                }                
                
                setState(prevState => ({ ...prevState, ['departmentAllocationError_'+key]: departmentAllocationError, departmentProviders: depProviders, ['departmentOrgAllocationError_'+key]: departmentOrgAllocationError }))

                let totalInitAllocation = 0                

                let initiatives = obj.initiatives ? obj.initiatives : []
                if(initiatives.length) {
                    initiatives.forEach(row => {
                        if(row.cloud_budget.hasOwnProperty(key)) {
                            let initData = row.cloud_budget[key]                            
                            totalInitAllocation += initData.allocation
                        }
                    })
                    if(value.allocation < totalInitAllocation) {
                        hasError = true
                        let depError = 'Department Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total initiative annual commitment.'
                        departmentInitiativeAllocationError.push(depError)

                        let intError = 'Intitative total Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the department annual commitment.'
                        initiativeDepartmentAllocationError.push(intError)
                    }
                }
                
                setState(prevState => ({ ...prevState, departmentInitiativeAllocationError, initiativeDepartmentAllocationError }))

                state.months && state.months.forEach((month, mIndex) => {
                    let departmentMonthlyDistrubutition = departmentDistrubutition[mIndex] ? departmentDistrubutition[mIndex] : 0
                    
                    let initiativeMonthlyAlllcationSum = 0
                    let initiatives = obj.initiatives ? obj.initiatives : []
                    initiatives.forEach(init => {
                        if(init.cloud_budget.hasOwnProperty(key)) {
                            let initData = init.cloud_budget[key]
                            let initDistrubutition = initData.monthly_distribution
                            initiativeMonthlyAlllcationSum += initDistrubutition[mIndex] ? initDistrubutition[mIndex] : 0
                        }
                    })
    
                    let departmentInitiativeMonthlyError = ''
                    if(parseFloat(departmentMonthlyDistrubutition) < parseFloat(initiativeMonthlyAlllcationSum)) {
                        hasError = true
                        departmentInitiativeMonthlyError = 'The total amount for initiative for '+key+ ' in '+ month.label+' month ('+parseFloat(initiativeMonthlyAlllcationSum)+') should not exceed the department '+ month.label+' month ('+parseFloat(departmentMonthlyDistrubutition)+').'
    
                        departmentInitiativeMonthlyTotalError.push(departmentInitiativeMonthlyError)                            
                    }
                })
            })            
            
            setState(prevState => ({ ...prevState, departmentInitiativeMonthlyTotalError }))
            
            let initiatives = obj.initiatives ? obj.initiatives : []
            if(initiatives.length) {
                initiatives.forEach((row, rIndex) => {                    
                    let initiProviders = []
                    let initProjectAllocationAmountError = []
                    let initiativeProjectMonthlyTotalError = []
                    let totalInitAllocation = 0
                    let totalInitDistributionSum = 0
                    Object.entries(row.cloud_budget).forEach(([key, value]) => {
                        let allocation = value.allocation 
                        let initiativetDistrubutition = value.monthly_distribution        
                        
                        totalInitAllocation += value.allocation 
                        
                        let distributionSum = 0
                        if(initiativetDistrubutition) {
                            distributionSum = initiativetDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalInitDistributionSum += Math.round(distributionSum)
        
                        let initiativeAllocationError = ''
                        if(allocation < distributionSum) {
                            hasError = true
                            initiativeAllocationError ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                        }
                        
                        setState(prevState => ({ ...prevState, ['initiativeAllocationError_'+row.initiative]: initiativeAllocationError, ['initiativeProviders_'+row.initiative]: initiProviders }))

                        let initRow = {}
                        initRow.provider = key
                        initRow.allocation = value.allocation 
                        initRow.distributuion = Math.round(distributionSum)
                        initRow.balance = Math.round(value.allocation - distributionSum)        
                        initiProviders.push(initRow)
                        
                        let projects = row.projects ? row.projects : []
                        
                        let projectTotal = 0
                        projects.forEach(proj => {
                            if(proj.cloud_budget.hasOwnProperty(key)) {
                                let projData = proj.cloud_budget[key]
                                let allocation = projData.allocation 
                                let projectDistrubutition = projData.monthly_distribution

                                projectTotal += allocation 

                                let projDistributionSum = 0
                                if(projectDistrubutition) {
                                    projDistributionSum = projectDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }
        
                                let projAllocationError = ''
                                if(allocation < projDistributionSum) {
                                    hasError = true
                                    projAllocationError ='Annual commitment amount should be more than the total monthly commitment.'
                                }

                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project+'_'+key]: projAllocationError}))
                            }  
                        })

                        let initProjectAllocationAmount = ''
                        if(projectTotal > value.allocation) {
                            hasError = true
                            initProjectAllocationAmount = 'The total commitment amount for the '+capitalizeAllLetter(key)+' projects ('+ projectTotal +') should not exceed the annual commitment for the initiative ('+value.allocation+').'

                            initProjectAllocationAmountError.push(initProjectAllocationAmount)
                        }
                        
                        setState(prevState => ({ ...prevState, ['initProjectAllocationAmount_'+row.initiative]: initProjectAllocationAmountError }))

                        state.months && state.months.forEach((month, mIindex) => {
                            let initiativeMonthlyAllocation = initiativetDistrubutition[mIindex] ? initiativetDistrubutition[mIindex] : 0
                            
                            let projectMonthlyAlllcationSum = 0
                            let projects = row.projects ? row.projects : []
                            projects.forEach(proj => {
                                if(proj.cloud_budget.hasOwnProperty(key)) {
                                    let projData = proj.cloud_budget[key]
                                    let projectDistrubutition = projData.monthly_distribution
                                    projectMonthlyAlllcationSum += projectDistrubutition[mIindex] ? projectDistrubutition[mIindex] : 0
                                }
                            })

                            let initiativeProjectMonthlyError = ''
                            if(parseFloat(initiativeMonthlyAllocation) < parseFloat(projectMonthlyAlllcationSum)) {
                                hasError = true
                                initiativeProjectMonthlyError = 'The total amount for projects for '+key+ ' in '+ month.label+' month ('+parseFloat(projectMonthlyAlllcationSum)+') should not exceed the initiative '+ month.label+' month ('+parseFloat(initiativeMonthlyAllocation)+').'

                                initiativeProjectMonthlyTotalError.push(initiativeProjectMonthlyError)                            
                            }
                        })
                    })

                    if(initiatives.length-1 === rIndex) {
                        let totalinitRow = {}
                        totalinitRow.provider = 'Total'
                        totalinitRow.allocation = totalInitAllocation
                        totalinitRow.distributuion = totalInitDistributionSum
                        totalinitRow.balance = totalInitAllocation - totalInitDistributionSum
                        initiProviders.unshift(totalinitRow);
                    }

                    setState(prevState => ({ ...prevState, ['initiativeProviders_'+row.initiative]: initiProviders, initiativeProjectMonthlyTotalError }))                    
                })
            }
        }

        let orgLevelCommitment = state.orgLevelCommitmentState ? state.orgLevelCommitmentState : []
        let orgLevelCommitmentState = []
        let totalCommitment = 0
        let totalAllocated = 0
        let totalBalance = 0
        if(depProviders && depProviders.length && orgLevelCommitment.length) {
            orgLevelCommitment.forEach(item => {
                if(depProviders.filter(e => e.provider === item.cloudProviderId && e.provider !== 'Total').length) {
                    let allocated = item.otherDepAllocation + (depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation ? depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation : 0)

                    item.allocated = allocated
                    item.balance = item.commitment - allocated
                    
                    totalCommitment += item.commitment
                    totalAllocated += allocated
                    totalBalance += item.commitment - allocated

                    orgLevelCommitmentState.push(item)
                }
            })

            
            let dataRow = {}
            dataRow.cloudProviderId = 'Total'
            dataRow.commitment = totalCommitment
            dataRow.allocated = totalAllocated
            dataRow.balance = totalBalance
            orgLevelCommitmentState.unshift(dataRow)
            
        }
        
        setState(prevState => ({ ...prevState, orgLevelCommitmentState }))

        if(onClickSave === 'save' && !hasError) {
            createBudget()
        } else if(onClickSave) {
            dispatch(setCostPropsDetails('onClickSave', 'stopLoading'))
        }
    }, [createInputs, state, createBudget, dispatch, onClickSave])

    useEffect(() => {
        if(onClickSave && onClickSave !== 'stopLoading') {
            dispatch(setCostPropsDetails('onClickSave', ''))
            onChangeHandleValidation()
        }
    }, [onClickSave, onChangeHandleValidation, dispatch])

    useEffect(() => {
        if(state.year && state.currencyUnit) {
            let params = {}
            params.year = state.year
            params.currency_unit = state.currencyUnit
            dispatch(getCloudProviderCommitment(params))
                .then((response) => {   
                    setState(prevState => ({
                        ...prevState,
                        cloudProviderCommitment: response,
                    }))
                })
        }
    }, [state.year, dispatch, state.currencyUnit])

    useEffect(() => {
        if(state.department && state.year && state.currencyUnit) {
            let params = {}
            params.department = state.department
            params.year = state.year
            params.currency_unit = state.currencyUnit

            dispatch(getDepartmentBudget(params))
                .then((response) => {
                    let initiativesOptions = response && response.initiatives ? response.initiatives : []
                    setState(prevState => ({
                        ...prevState,
                        departmentDetails: response,
                        initiativesOptions,
                        formatDepartmentResponse: true,
                        description: response && response.description ? response.description : ''
                    }))

                    dispatch(setCostPropsDetails('createInputs', response))
                    if(initiativesOptions.length) {
                        let dataRow = initiativesOptions[0]
                        dataRow.iIndex = 0

                        dispatch(setCostPropsDetails("selectedInitiative", dataRow))
                    }
                })
        }
    }, [state.department, state.year, state.currencyUnit, dispatch])

    const departmentCommitedBalanceDetails = useCallback((allocation, monthly_distribution, selectedDepCloudProvider) => {
        let departmentMontlyDistrubutition = monthly_distribution
        if(departmentMontlyDistrubutition && departmentMontlyDistrubutition.length) {
            let departmentMonthlySum = departmentMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0);

            let departmentBalance = allocation - departmentMonthlySum

            var quarters = [];
            for (var i = 0; i < departmentMontlyDistrubutition.length; i += 3) {
                var quarter = departmentMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['department_quater_'+qIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < departmentMontlyDistrubutition.length; i += 6) {
                let halfYearly = departmentMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['department_half_'+hIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['departmentMonthlySum_'+selectedDepCloudProvider]: Math.round(departmentMonthlySum), ['departmentBalance_'+selectedDepCloudProvider]: Math.round(departmentBalance) }))
        }
    }, [])

    const initiativeCommitedBalanceDetails = useCallback((allocation, monthly_distribution, iIndex, selectedInitCloudProvider) => {
        let initiativeMontlyDistrubutition = monthly_distribution
        if(initiativeMontlyDistrubutition && initiativeMontlyDistrubutition.length) {
            let initiativeMonthlySum = initiativeMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0);

            let initiativeBalance = allocation - initiativeMonthlySum

            var quarters = [];
            for (var i = 0; i < initiativeMontlyDistrubutition.length; i += 3) {
                var quarter = initiativeMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['initiative_quater_'+qIndex+'_'+iIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < initiativeMontlyDistrubutition.length; i += 6) {
                let halfYearly = initiativeMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['initiative_half_'+hIndex+'_'+iIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['initiativeMonthlySum_'+iIndex]: Math.round(initiativeMonthlySum), ['initiativeBalance_'+iIndex]: Math.round(initiativeBalance) }))
        }
    }, [])

    useEffect(() => {
        if(state.formatDepartmentResponse) {
            setState(prevState => ({ ...prevState, formatDepartmentResponse: false }))
            let res = state.departmentDetails

            let orgLevelCommitment = res.org_level_commitment

            if(res.cloud_budget && Object.entries(res.cloud_budget).length) {
                let depProviders = []
                let totalAllocation = 0
                let totalDistributionSum = 0
                Object.entries(res.cloud_budget).forEach(([key, value], objIndex) => {
                    let departmentDistrubutition = value.monthly_distribution
                    let distributionSum = 0
                    if(departmentDistrubutition) {
                        distributionSum = departmentDistrubutition.reduce((a, b) => {
                            return a + parseFloat(b);
                        }, 0)
                    }
                    totalAllocation += Math.round(value.allocation) 
                    totalDistributionSum += Math.round(distributionSum)
                    let dataRow = {}
                    dataRow.provider = key
                    dataRow.allocation = Math.round(value.allocation)
                    dataRow.distributuion = Math.round(distributionSum)
                    dataRow.balance = Math.round(value.allocation - distributionSum)    
                    depProviders.push(dataRow)

                    if(Object.entries(res.cloud_budget).length-1 === objIndex) {
                        let totalDepRow = {}
                        totalDepRow.provider = 'Total'
                        totalDepRow.allocation = totalAllocation
                        totalDepRow.distributuion = totalDistributionSum
                        totalDepRow.balance = totalAllocation - totalDistributionSum
                        depProviders.unshift(totalDepRow);
                    }
                    
                    setState(prevState => ({ ...prevState, departmentProviders: depProviders, ['departmentAllocationError_'+key]: '', departmentInitiativeMonthlyTotalError: [], departmentInitiativeAllocationError: [], initiativeDepartmentAllocationError: [] }))                    
                })
                let orgLevelCommitmentState = []
                let totalCommitment = 0
                let totalAllocated = 0
                let totalBalance = 0
                if(depProviders && depProviders.length && orgLevelCommitment && orgLevelCommitment.length) {
                    orgLevelCommitment.forEach(item => {
                        if(depProviders.filter(e => e.provider === item.cloudProviderId && e.allocation >= 0).length) {
                            
                            //balance amout can be allocated
                            item.balance = item.available_for_allocation
                            
                            //remainaing available balance include the current department
                            let availablePlusDepartmentAllocation = item.available_for_allocation+(depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation ? depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation : 0)
                            item.availablePlusDepartmentAllocation = availablePlusDepartmentAllocation

                            //get other department allcoations of provider
                            item.otherDepAllocation = item.commitment - availablePlusDepartmentAllocation
                            
                            orgLevelCommitmentState.push(item)

                            totalCommitment += item.commitment
                            totalAllocated += item.allocated
                            totalBalance += item.available_for_allocation
                        }
                    })

                    let dataRow = {}
                    dataRow.cloudProviderId = 'Total'
                    dataRow.commitment = totalCommitment
                    dataRow.allocated = totalAllocated
                    dataRow.balance = totalBalance
                    orgLevelCommitmentState.unshift(dataRow)                    

                    setState(prevState => ({ ...prevState, orgLevelCommitmentState }))
                }
            }
            
            let initiativesProvider = res.initiatives ? res.initiatives : []
            if(initiativesProvider && initiativesProvider.length) {
                let totalInitAllocation = 0
                let totalInitDistributionSum = 0
                initiativesProvider.forEach((row, rIndex) => {
                    let initiProviders = []
                    Object.entries(row.cloud_budget).forEach(([key, value]) => {
                        let initiativetDistrubutition = value.monthly_distribution                            
                        
                        let distributionSum = 0
                        if(initiativetDistrubutition) {
                            distributionSum = initiativetDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalInitAllocation += value.allocation 
                        totalInitDistributionSum += Math.round(distributionSum)

                        let initRow = {}
                        initRow.provider = key
                        initRow.allocation = value.allocation 
                        initRow.distributuion = Math.round(distributionSum)
                        initRow.balance = Math.round(value.allocation - distributionSum)
        
                        initiProviders.push(initRow)
                    })

                    if(initiativesProvider.length-1 === rIndex) {
                        let totalinitRow = {}
                        totalinitRow.provider = 'Total'
                        totalinitRow.allocation = totalInitAllocation
                        totalinitRow.distributuion = totalInitDistributionSum
                        totalinitRow.balance = totalInitAllocation - totalInitDistributionSum
                        initiProviders.unshift(totalinitRow);
                    }
    
                    setState(prevState => ({ ...prevState, ['initiativeProviders_'+row.initiative]: initiProviders, ['initiativeAllocationError_'+row.initiative]: '', ['initProjectAllocationAmount_'+row.initiative]: [], initiativeProjectMonthlyTotalError: [] }))
                })
            }
                
            
            let departmentCloudBudget = state.departmentDetails && state.departmentDetails.cloud_budget ? state.departmentDetails.cloud_budget : {}
            
            let departmentCloudProviders = []
            if(departmentCloudBudget && Object.entries(departmentCloudBudget).length) {
                departmentCloudProviders = Object.keys(departmentCloudBudget)
                let selectedDepCloudProvider = departmentCloudProviders[0]
                let selectedCloudProviderBudget = departmentCloudBudget[selectedDepCloudProvider]
                let distribution = selectedCloudProviderBudget && selectedCloudProviderBudget.monthly_distribution ? selectedCloudProviderBudget.monthly_distribution : []
                
                state.months.forEach((mnt, mIndex) => {
                    setState(prevState => ({ ...prevState, ['department_'+selectedDepCloudProvider+'_'+mnt.label]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                })
                let allocation = selectedCloudProviderBudget && selectedCloudProviderBudget.allocation ? selectedCloudProviderBudget.allocation : 0

                departmentCommitedBalanceDetails(allocation, distribution, selectedDepCloudProvider)

                setState(prevState => ({ ...prevState, departmentCloudProviders, selectedDepCloudProvider }))
            }
            let initiatives = state.departmentDetails && state.departmentDetails.initiatives ? state.departmentDetails.initiatives : []
            if(initiatives && initiatives.length) {
                initiatives.forEach((init, iIndex) => {
                    let initiativeCloudProviders = []
                    let initiativeCloudBudget = init && init.cloud_budget ? init.cloud_budget : {}
                    if(initiativeCloudBudget && Object.entries(initiativeCloudBudget).length) {
                        initiativeCloudProviders = Object.keys(initiativeCloudBudget)
                        let selectedInitCloudProvider = initiativeCloudProviders[0]
                        let selectedCloudInitBudget = initiativeCloudBudget[selectedInitCloudProvider]

                        let distribution = selectedCloudInitBudget && selectedCloudInitBudget.monthly_distribution ? selectedCloudInitBudget.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedCloudInitBudget && selectedCloudInitBudget.allocation ? selectedCloudInitBudget.allocation : 0

                        initiativeCommitedBalanceDetails(allocation, distribution, iIndex, selectedInitCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['initiativeCloudProviders_'+iIndex]: initiativeCloudProviders, ['selectedInitCloudProvider_'+iIndex]: selectedInitCloudProvider, ['initiativeAlloation_'+iIndex]: allocation }))
                    }
                })
            }
        }
    }, [state.formatDepartmentResponse, state, departmentCommitedBalanceDetails, initiativeCommitedBalanceDetails])

    //for departemnt
    useEffect(() => {
        if(state.onChangeDeparmentProvider) {
            setState(prevState => ({ ...prevState, onChangeDeparmentProvider: false }))
            let departmentCloudBudget = state.departmentDetails && state.departmentDetails.cloud_budget ? state.departmentDetails.cloud_budget : {}

            if(departmentCloudBudget && Object.entries(departmentCloudBudget).length) {
                let selectedDepCloudProvider = state.selectedDepCloudProvider
                let selectedCloudProviderBudget = departmentCloudBudget[selectedDepCloudProvider]
                let distribution = selectedCloudProviderBudget && selectedCloudProviderBudget.monthly_distribution ? selectedCloudProviderBudget.monthly_distribution : []
                state.months.forEach((mnt, mIndex) => {
                    setState(prevState => ({ ...prevState, ['department_'+selectedDepCloudProvider+'_'+mnt.label]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                })
                let allocation = selectedCloudProviderBudget && selectedCloudProviderBudget.allocation ? selectedCloudProviderBudget.allocation : 0

                departmentCommitedBalanceDetails(allocation, distribution, selectedDepCloudProvider)
            }
            
            let initiatives = state.departmentDetails && state.departmentDetails.initiatives ? state.departmentDetails.initiatives : []
            if(initiatives && initiatives.length) {
                initiatives.forEach((init, iIndex) => {
                    let initiativeCloudProviders = []
                    let initiativeCloudBudget = init && init.cloud_budget ? init.cloud_budget : {}
                    if(initiativeCloudBudget && Object.entries(initiativeCloudBudget).length) {
                        initiativeCloudProviders = Object.keys(initiativeCloudBudget)
                        let selectedInitCloudProvider = initiativeCloudProviders[0]
                        let selectedCloudInitBudget = initiativeCloudBudget[selectedInitCloudProvider]

                        let distribution = selectedCloudInitBudget && selectedCloudInitBudget.monthly_distribution ? selectedCloudInitBudget.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedCloudInitBudget && selectedCloudInitBudget.allocation ? selectedCloudInitBudget.allocation : 0

                        initiativeCommitedBalanceDetails(allocation, distribution, iIndex, selectedInitCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['initiativeCloudProviders_'+iIndex]: initiativeCloudProviders, ['selectedInitCloudProvider_'+iIndex]: selectedInitCloudProvider, ['initiativeAlloation_'+iIndex]: allocation }))
                    }
                })
            }
        }
    }, [state.onChangeDeparmentProvider, state.months, state.departmentDetails, departmentCommitedBalanceDetails, initiativeCommitedBalanceDetails, state.selectedDepCloudProvider])
    
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))
		let obj = createInputs ? createInputs : {}
        obj[label] = value

        dispatch(setCostPropsDetails('createInputs', obj))
	}

    //for departemnt
    const handleDepartmentInputChange = (label, value) => {        
		let obj = createInputs ? createInputs : {}
        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}
        if(label === 'allocation') {
            value = parseFloat(value)
            selectedDepProviderDetails.allocation = value

            if(!state.splitEqual) {
                departmentCommitedBalanceDetails(value, selectedDepProviderDetails.monthly_distribution, state.selectedDepCloudProvider)
            }

            setState(prevState => ({ ...prevState, [label+'_'+state.selectedDepCloudProvider]: value }))
        }
        
        dispatch(setCostPropsDetails('createInputs', obj))
	}

    //for departemnt
    const callEqualMonthlyDistribution = useCallback(() => {
        let obj = createInputs ? createInputs : {}

        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}

		let allocation = selectedDepProviderDetails.allocation ? selectedDepProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(allocation/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })
        
        selectedDepProviderDetails.monthly_distribution = monthly_distribution

        dispatch(setCostPropsDetails('createInputs', obj))
        departmentCommitedBalanceDetails(allocation, monthly_distribution, state.selectedDepCloudProvider)
    }, [state.months, createInputs, state.selectedDepCloudProvider, dispatch, departmentCommitedBalanceDetails])

    //for departemnt
    useEffect(() => {
        if(state.departmentMonthlyDistribution) {
            setState(prevState => ({ ...prevState, departmentMonthlyDistribution: false }))

            let obj = createInputs ? createInputs : {}
            let allocation = obj.cloud_budget && obj.cloud_budget[state.selectedDepCloudProvider] && obj.cloud_budget[state.selectedDepCloudProvider].allocation ? obj.cloud_budget[state.selectedDepCloudProvider].allocation : 0
            if(allocation) {
                state.months.forEach(mnt => {
                    let amount = parseFloat(allocation/12).toFixed(2)
                    setState(prevState => ({ ...prevState, ['department_'+state.selectedDepCloudProvider+'_'+mnt.label]: parseFloat(amount) }))
                })

                callEqualMonthlyDistribution()
                onChangeHandleValidation()
            }
        }
    }, [state.departmentMonthlyDistribution, state, createInputs, callEqualMonthlyDistribution, onChangeHandleValidation])

    //for departemnt
    const handleMonthlyDistributionChange = (label, value) => {
        let obj = createInputs ? createInputs : {}
		
        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}
        let monthly_distribution = selectedDepProviderDetails && selectedDepProviderDetails.monthly_distribution ? selectedDepProviderDetails.monthly_distribution : []
            
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            }
        })
        
        selectedDepProviderDetails.monthly_distribution = monthly_distribution

		let allocation = selectedDepProviderDetails.allocation ? selectedDepProviderDetails.allocation : 0
        departmentCommitedBalanceDetails(allocation, monthly_distribution, state.selectedDepCloudProvider)
    }

    //for Initiative
	const handleInitiativeInputChange = (label, iIndex, value) => {
        
		let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
        
        if(label === 'allocation') {
            value = parseFloat(value)

            selectedInitProviderDetails[label] = value

            if(!state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]) {
                initiativeCommitedBalanceDetails(value, selectedInitProviderDetails.monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])
            }
            
        }
	}
    
    //for Initiative// 
    const onChangeInitCloudProvider = (selectedInitCloudProvider, iIndex) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[selectedInitCloudProvider] ? initiatives.cloud_budget[selectedInitCloudProvider] : {}
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0

        let distribution = selectedInitProviderDetails && selectedInitProviderDetails.monthly_distribution ? selectedInitProviderDetails.monthly_distribution : []
        state.months.forEach((mnt, mIndex) => {
            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
        })

        initiativeCommitedBalanceDetails(allocations, distribution, iIndex, selectedInitCloudProvider)
        
        setState(prevState => ({ ...prevState, ['initiativeAlloation_'+iIndex]: allocations }))
    }

    //for Initiative
    const callInitiativeEqualMonthlyDistribution = useCallback((iIndex, value) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(value/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })
        
        selectedInitProviderDetails.monthly_distribution = monthly_distribution

        initiativeCommitedBalanceDetails(allocations, monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])
    }, [createInputs, state, initiativeCommitedBalanceDetails])

    //for Initiative
    const handleInitiativeMonthlyEqualSplit = (initiativeSplitEqual, iIndex, value) => {
        if(initiativeSplitEqual) {
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
            let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}

            let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0
            
            let amount = parseFloat(allocations/12).toFixed(2)
            state.months.forEach(mnt => {
                setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: parseFloat(amount) }))
            })

            callInitiativeEqualMonthlyDistribution(iIndex, allocations)
        }
    }

    //for Initiative
    const handleInitiativeMonthlySplit = (label, iIndex, value) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
		
        let monthly_distribution = selectedInitProviderDetails && selectedInitProviderDetails.monthly_distribution ? selectedInitProviderDetails.monthly_distribution : []
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                let amount = parseFloat(state['initiative_'+mnt.label+'_'+iIndex] ? state['initiative_'+mnt.label+'_'+iIndex] : 0).toFixed(2)
                monthly_distribution[mnt.value] = parseFloat(amount)
            }
        })
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0
        selectedInitProviderDetails.monthly_distribution = monthly_distribution

        initiativeCommitedBalanceDetails(allocations, monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])
    }

    //for Initiative
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    //for Department
    const expandCollapse = (section) => {
        setState(prevState => ({ ...prevState, expandMenuId: state.expandMenuId === section ? "" : section }))
    }

    //for Initiative
    const expandInitiatives = (row, iIndex) => {
        setState(prevState => ({ ...prevState, expandInit: state.expandInit === row.initiative ? "" : row.initiative }))
        let selectedInitiative = ''
        if(state.expandInit !== row.initiative) {
            row.iIndex = iIndex
            selectedInitiative = row
        }
        dispatch(setCostPropsDetails("selectedInitiative", selectedInitiative))
    }
	
	return (
        <div className='pr-2' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap text-black border border-DeepPurpleTints-600 rounded-2xl bg-white p-6'>
                <div className='flex flex-wrap justify-start w-full'>
                    {state.pageType === 'View' ?
                        <div className="w-full items-center">
                            <label className="inline-flex items-center cursor-pointer w-full flex justify-start">
                                <input type="checkbox" className="sr-only peer" onChange={() => {
                                    setState(prevState => ({ ...prevState, currencyUnit: state.currencyUnit === 'usd' ? 'gbp' : 'usd', showLoading: true }))
                                    dispatch(setCostPropsDetails('currencyUnit', 'usd'))
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 pl-5"></div>
                                <span className="mx-3 text-sm font-medium text-gray-900 dark:text-gray-300 text-black">Currency is in <span className='uppercase'>{(state.currencyUnit)}</span></span>
                            </label>
                        </div>
                    :
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-300 text-black">Currency is in <span className='uppercase'>{(state.currencyUnit)}</span></p>
                    }
                </div>

                { state.orgLevelCommitmentState && state.orgLevelCommitmentState.length ?
                    <div className='py-5 w-full'>
                        <div className='flex justify-between text-base font-medium'>
                            <p className="text-black self-center">Cloud Provider</p>
                            <p className="text-primaryPurple-600 self-center cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showProviderSplitUp: !state.showProviderSplitUp }))}>{state.showProviderSplitUp ? 'Hide' : 'Show All'} Provider details</p>
                        </div>
                        <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                            <table className="w-full text-left">
                                <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                    <tr className="flex-1 w-full">
                                        <th className='tracking-wider py-2 pl-4 text-left'>&nbsp;</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Comitted</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Allocated</th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm text-NeutralGray-900'>
                                    {state.showProviderSplitUp ?
                                        state.orgLevelCommitmentState.map((row, index)=> {
                                            return(
                                                <tr key={"head1_"+index} className={`text-base ${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                    <td className={`py-2.5 pl-4 text-left`}>{row.cloudProviderId ? capitalizeAllLetter(row.cloudProviderId) : ''}</td>
                                                    <td className={`py-2.5 pl-4 text-left`}>{row.commitment ? thousandSeparator(row.commitment) : 0}</td>
                                                    <td className={`py-2.5 pl-4 text-left`}>{row.allocated ? thousandSeparator(row.allocated) : 0}</td>
                                                </tr>
                                            )
                                        })
                                    : 
                                        state.orgLevelCommitmentState && state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total')).length ?
                                            <tr className={`text-base bg-white w-full`}>
                                                <td className={`py-2.5 pl-4 text-left`}>{state.selectedDepCloudProvider ? capitalizeAllLetter(state.selectedDepCloudProvider) : ''}</td>
                                                <td className={`py-2.5 pl-4 text-left`}>
                                                    {state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].commitment ? thousandSeparator(state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].commitment) : 0}
                                                </td>
                                                <td className={`py-2.5 pl-4 text-left`}>
                                                    {state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].allocated ? thousandSeparator(state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].allocated) : 0}
                                                </td>
                                            </tr>
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null}

                <div className="p-2 rounded-md bg-BlockWhite w-full">
                    <div className="flex flex-wrap mb-3">
                        <div className="lg:w-1/2 lg:pr-3 w-full">
                            <Textbox
                                label={'Name'}
                                selectedValue={createInputs && createInputs.budget_name ? createInputs.budget_name : ""}
                                callback={(value) => {
                                    handleInputChange("budget_name", value)
                                }}
                                inputType={state.pageType}
                                manditory={true}
                                hasError={state.hasError}
                            />
                        </div>
                        <div className="lg:w-1/2 lg:pl-3 w-full">
                            <SelectOption
                                label={"Year"}
                                options={state.yearOptions}
                                selectedValues={createInputs.year ? createInputs.year : ''}
                                callbackMultiSelect={(value) => {
                                    handleInputChange('year', value)
                                }}
                                manditory={true}
                                hasError={state.hasError}
                                singleSelection={true}
                                dropdownWidth={'min-w-32'}
                                classDetails={{padding: "py-2.5 pr-4 pl-3", bg: ' ', rounded: 'rounded-lg' }}
                            />
                        </div>
                        <div className="w-full pt-6">
                            <Textarea
                                label={'Description'}
                                selectedValue={state.description ? state.description : ''}
                                callback={(value) => {
                                    setState(prevState => ({ ...prevState, description: value }))
                                    handleInputChange("description", value)
                                }}
                                placeholder={"Enter description"}
                                rows={4}
                            />
                            {/* <p className="text-NeutralGray-800 text-base flex w-full">Description</p> */}
                            {/* <textarea 
                                id="description" 
                                name="description" 
                                rows="4" 
                                readOnly={state.pageType === 'View' ? true : false}
                                className={`w-full px-2 py-1 bg-transparent border border-NeutralGray-600 rounded-md text-black`}
                                onChange={e => {
                                    handleInputChange("description", e.target.value)
                                }}
                                value={createInputs && createInputs.description ? createInputs.description : ""}
                                placeholder='Enter Policy Description'
                                maxLength='256'
                            /> */}
                        </div>
                    </div>

                    {state.year ?
                        <div className={`mt-6 py-5 pl-5 bg-white border border-NeutralGray-600 rounded-2xl`}> 
                            <React.Fragment>
                                <div className='flex text-base font-bold'>
                                    <Icon icon={`${state.expandMenuId === 'Department' ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-white bg-PrimaryLightOrange-600 rounded-full`} width={24} height={24} onClick={() => expandCollapse('Department')} />
                                    Step 1 Department
                                </div>
                                {state.providerAllocationError ?
                                    <p className='mb-0 text-danger'>{state.providerAllocationError}</p>
                                : null}
                                {state.expandMenuId !== 'Department' ?
                                    state.depInitAllocationAmount ?
                                        <p className='mb-0 text-danger'>{state.depInitAllocationAmount}</p>
                                    : null
                                : null}                                
                            </React.Fragment>
                            {state.expandMenuId === 'Department' && state.year ?
                                <div className='border border-lightGray rounded-md p-3 mt-2'>
                                    <div className="flex flex-wrap mb-3">
                                        <div className="py-1 lg:w-1/2 lg:pr-3 w-full">
                                            <SelectOption
                                                label={"Department"}
                                                options={state.departmentsList}
                                                selectedValues={createInputs.department ? createInputs.department : ''}
                                                callbackMultiSelect={(value) => {
                                                    handleInputChange('department', value)
                                                }}
                                                manditory={true}
                                                hasError={state.hasError}
                                                singleSelection={true}
                                                dropdownWidth={'min-w-32'}
                                                classDetails={{padding: "py-2.5 pr-4 pl-3", bg: ' ', rounded: 'rounded-lg' }}
                                            />
                                        </div>
                                        <div className="py-1 lg:w-1/2 lg:pl-3 w-full">
                                            <SelectOption
                                                label={"Cloud Provider"}
                                                options={state.departmentCloudProviders}
                                                selectedValues={state.selectedDepCloudProvider ? state.selectedDepCloudProvider : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedDepCloudProvider: value, splitEqual: false, onChangeDeparmentProvider: true }))
                                                }}
                                                singleSelection={true}
                                                dropdownWidth={'min-w-32'}
                                                classDetails={{padding: "py-2.5 pr-4 pl-3", bg: ' ', rounded: 'rounded-lg' }}
                                            />
                                        </div>
                                    </div>
                                    {state.department ?
                                        <React.Fragment>                                        
                                        {state.departmentProviders && state.departmentProviders.length ?
                                            <div className="rounded-t-2xl overflow-hidden border border-lightGray-600 shadow-lg">
                                                <table className="w-full text-left">
                                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                                        <tr className="flex-1 w-full">
                                                            <th className='tracking-wider py-2 pl-4 text-left'>&nbsp;</th>
                                                            <th className='tracking-wider py-2 pl-4 text-left'>Comitted</th>
                                                            <th className='tracking-wider py-2 pl-4 text-left'>Allocated</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-sm text-NeutralGray-900'>
                                                        {state.departmentProviders.map((row, index) => {
                                                            return(
                                                                <tr key={"dep_"+index} className={`text-base ${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                    <td className={`py-2.5 pl-4 text-left`}>{row.provider ? capitalizeAllLetter(row.provider) : ''}</td>
                                                                    <td className={`py-2.5 pl-4 text-left`}>{row.allocation ? thousandSeparator(row.allocation) : 0}</td>
                                                                    <td className={`py-2.5 pl-4 text-left`}>{row.distributuion ? thousandSeparator(row.distributuion) : 0}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        : null}
                                        {state['departmentAllocationError_'+state.selectedDepCloudProvider] ?
                                            <p className='mb-0 text-danger mt-2'>{state['departmentAllocationError_'+state.selectedDepCloudProvider]}</p>
                                        : null}
                                        {state.departmentInitiativeAllocationError && state.departmentInitiativeAllocationError.length ?
                                            state.departmentInitiativeAllocationError.map(err => {
                                                return(
                                                    err ?
                                                        <p className='mb-1 text-danger'>{err}</p>
                                                    : null
                                                )
                                            })
                                        : null}
                                        {state['departmentOrgAllocationError_'+state.selectedDepCloudProvider] && state['departmentOrgAllocationError_'+state.selectedDepCloudProvider].length ?
                                            state['departmentOrgAllocationError_'+state.selectedDepCloudProvider].map(err => {
                                                return(
                                                    err ?
                                                        <p className='mb-1 text-danger'>{err}</p>
                                                    : null
                                                )
                                            })
                                        : null}
                                        <div className="flex flex-wrap mt-3">
                                            <div className="flex lg:w-1/2 lg:pr-3 w-full">
                                                <Textbox
                                                    label={'Annual Budget'}
                                                    selectedValue={createInputs && createInputs.cloud_budget && createInputs.cloud_budget[state.selectedDepCloudProvider] && createInputs.cloud_budget[state.selectedDepCloudProvider].allocation ? createInputs.cloud_budget[state.selectedDepCloudProvider].allocation : ''}
                                                    callback={(value) => {
                                                        handleDepartmentInputChange("allocation", onlyNumeric(value))
                                                        setTimeout(() => { onChangeHandleValidation() }, 1000); 
                                                        if(state.splitEqual) {
                                                            setState(prevState => ({ ...prevState, departmentMonthlyDistribution: true }))
                                                        }
                                                    }}
                                                    placeholder={'Annula budget'}
                                                />
                                                <p className="text-black ml-3 pt-4 text-sm font-bold self-center">{state.currencyUnit}</p>
                                            </div>
                                            <div className='lg:w-1/2 lg:pl-4 w-full self-center pt-4'>
                                                <Checkbox
                                                    label="Equal Monthly Budget"
                                                    name="splitEqual"
                                                    selectedValue={state.splitEqual}
                                                    callback={() => {
                                                        setState(prevState => ({ ...prevState, splitEqual: !state.splitEqual, departmentMonthlyDistribution: true }))
                                                    }}
                                                />
                                            </div>
                                        </div>                                                                

                                        <div className='pt-10'>   
                                            {state.departmentMonthlyAllocationError ?
                                                <p className='mb-1 text-danger'>{state.departmentMonthlyAllocationError}</p>
                                            : state.depIniMonthlyError ?
                                                <p className='mb-0 text-danger'>{state.depIniMonthlyError}</p>
                                            : null}
                                            
                                            {state.departmentInitiativeMonthlyTotalError && state.departmentInitiativeMonthlyTotalError.length ?
                                                state.departmentInitiativeMonthlyTotalError.map(err => {
                                                    return(
                                                        err ?
                                                            <p className='mb-1 text-danger'>{err}</p>
                                                        : null
                                                    )
                                                })
                                            : null}
                                            <p className='text-NeutralGray-800 font-bold text-[22px] pb-5'>Monthly Budget</p>
                                            <div className='flex flex-wrap pt-5'>
                                                {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                    return(
                                                        <div className='w-1/4 pl-0' key={rowIndex}>
                                                            {quarter.map(mnt => {
                                                                return(
                                                                    <div className='mb-3 pr-3' key={'dep_'+mnt.label}>
                                                                        <Textbox
                                                                            type={'number'}
                                                                            label={mnt.label+' '+(state.year ? state.year : '')}
                                                                            selectedValue={state['department_'+state.selectedDepCloudProvider+'_'+mnt.label] ? state['department_'+state.selectedDepCloudProvider+'_'+mnt.label] : ""}
                                                                            callback={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['department_'+state.selectedDepCloudProvider+'_'+mnt.label]: value }))
                                                                                handleMonthlyDistributionChange(mnt.value, value)
                                                                                onChangeHandleValidation(value, mnt)
                                                                            }}
                                                                            inputType={state.pageType}
                                                                            placeholder={'Monthly budget'}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="flex justify-center w-4/5">
                                                                <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                            </div>
                                                            <span className='flex justify-center mt-2 w-4/5 text-lg font-medium'>Q{rowIndex+1} <span className='ml-2 text-primaryPurple-600'>{thousandSeparator(Math.round(state['department_quater_'+rowIndex] ? state['department_quater_'+rowIndex] : 0))}</span></span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='flex flex-wrap justify-between mt-4'>
                                                <div className='w-1/2'>
                                                    <div className="flex justify-center">
                                                        <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                    </div>
                                                    <span className='mt-2 flex justify-center text-lg font-medium'>H1 <span className='ml-2 text-primaryPurple-600'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_0)) : 0}</span></span>
                                                </div>
                                                <div className='w-1/2'>
                                                    <div className="flex justify-center">
                                                        <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                    </div>
                                                    <span className='mt-2 flex justify-center text-lg font-medium'>H2 <span className='ml-2 text-primaryPurple-600'>{state.department_half_1 ? thousandSeparator(Math.round(state.department_half_1)) : 0}</span></span>
                                                </div>
                                            </div>
                                            {/* <div className='flex justify-between mt-2'>
                                                <span className='w-50 flex justify-center'>H1 <span className='ml-2 text-warning'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_0)) : 0}</span></span>
                                                <span className='w-50 flex justify-center'>H2 <span className='ml-2 text-warning'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_1)) : 0}</span></span>
                                            </div>                                         */}
                                        </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                            : null}
                        </div>
                    : null}
                                
                    {state.department && state.year ?
                        <div className={`mt-6 py-5 pl-5 bg-white border border-NeutralGray-600 rounded-2xl`}> 
                            <div className=''>
                                <div className='flex text-base font-bold'>
                                    <Icon icon={`${state.expandMenuId === 'initiatives' ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-white bg-PrimaryLightOrange-600 rounded-full`} width={24} height={24} onClick={() => expandCollapse('initiatives')} />
                                    Step 2 Initiatives
                                </div>
                                {state['initDepAllocationAmount'] ?
                                    <p className='mb-1 text-danger'>{state['initDepAllocationAmount']}</p>
                                : null}
                            </div>
                            {state.expandMenuId === 'initiatives' ?
                                <div className='px-6'>
                                    {state.departmentDetails && state.departmentDetails.initiatives.length ?
                                        state.departmentDetails.initiatives.map((row, iIndex) => {
                                            return(
                                                <div key={'init_'+iIndex} className={`rounded-2xl p-5 mt-5 bg-NeutralGray-200`}> 
                                                {/* ${state.expandInit === row.initiative ? 'border-2 border-byzantineBlue' : 'border border-DeepPurpleTints-600'} */}

                                                    <div className={`flex cursor-pointer font-bold text-base`} onClick={() => expandInitiatives(row, iIndex)}>
                                                        <Icon icon={`${state.expandInit === row.initiative ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-primaryPurple-600 border border-primaryPurple-600 rounded-full`} width={24} height={24} />
                                                        {row.initiative}
                                                    </div>
                                                    {state.expandInit === row.initiative ?
                                                        <div className="pt-6">
                                                            {state['initiativeAllocationError_'+row.initiative] ?
                                                                <p className='mb-1 text-danger'>{state['initiativeAllocationError_'+row.initiative]}</p>
                                                            : null}
                                                            {state['initProjectAllocationAmount_'+row.initiative] && state['initProjectAllocationAmount_'+row.initiative].length ?
                                                                state['initProjectAllocationAmount_'+row.initiative].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            {state.initiativeDepartmentAllocationError && state.initiativeDepartmentAllocationError.length ?
                                                                state.initiativeDepartmentAllocationError.map(err => {
                                                                    return(
                                                                        err ?
                                                                            <p className='mb-1 text-danger'>{err}</p>
                                                                        : null
                                                                    )
                                                                })
                                                            : null}
                                                            <div className='flex flex-wrap'>
                                                                <div className="py-1 lg:w-1/2 w-full">
                                                                    <SelectOption
                                                                        label={"Cloud Provider"}
                                                                        options={state['initiativeCloudProviders_'+iIndex] ? state['initiativeCloudProviders_'+iIndex] : []}
                                                                        selectedValues={state['selectedInitCloudProvider_'+iIndex] ? state['selectedInitCloudProvider_'+iIndex] : ''}
                                                                        callbackMultiSelect={(value) => {
                                                                            setState(prevState => ({ ...prevState, ['selectedInitCloudProvider_'+iIndex]: value,
                                                                            ['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]]: state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]] ? true : false }))
                                                                            onChangeInitCloudProvider(value, iIndex)
                                                                        }}
                                                                        singleSelection={true}
                                                                        dropdownWidth={'min-w-32'}
                                                                        classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-wrap pt-4'>
                                                                <div className="flex py-1 lg:w-1/2 w-full">
                                                                    <Textbox
                                                                        label={'Annual Budget'}
                                                                        selectedValue={state['initiativeAlloation_'+iIndex] ? state['initiativeAlloation_'+iIndex] : 0}
                                                                        callback={(value) => {
                                                                            setState(prevState => ({ ...prevState, ['initiativeAlloation_'+iIndex]: value }))
                                                                            handleInitiativeInputChange('allocation', iIndex, onlyNumeric(value))
                                                                            if(state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]) {
                                                                                handleInitiativeMonthlyEqualSplit(true, iIndex, onlyNumeric(value))
                                                                            }
                                                                            onChangeHandleValidation()
                                                                        }}
                                                                        inputType={state.pageType}
                                                                        placeholder={'Annula budget'}
                                                                    />
                                                                    <p className="text-black ml-3 pt-4 text-sm font-bold self-center">{state.currencyUnit}</p>
                                                                </div>
                                                                <div className='lg:w-1/2 lg:pl-4 w-full self-center pt-4'>
                                                                    <Checkbox
                                                                        label="Equal Monthly Budget"
                                                                        name={'montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex}
                                                                        selectedValue={state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex] ? true : false}
                                                                        callback={() => {
                                                                            let monthlySplit = !state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]
                                                                            setState(prevState => ({ ...prevState, ['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]: monthlySplit }))
                                                                            handleInitiativeMonthlyEqualSplit(monthlySplit, iIndex, state['initiativeAlloation_'+iIndex])
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='py-4'>
                                                                {state.initiativeProjectMonthlyTotalError && state.initiativeProjectMonthlyTotalError.length ?
                                                                    state.initiativeProjectMonthlyTotalError.map(err => {
                                                                        return(
                                                                            err ?
                                                                                <p className='mb-1 text-danger'>{err}</p>
                                                                            : null
                                                                        )
                                                                    })
                                                                : null}
                                                                {state['initiativeProviders_'+row.initiative] && state['initiativeProviders_'+row.initiative].length ?
                                                                    <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                                                                        <table className="w-full text-left">
                                                                            <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                                                                <tr className="flex-1 w-full">
                                                                                    <th className='tracking-wider py-2 pl-4 text-left'>&nbsp;</th>
                                                                                    <th className='tracking-wider py-2 pl-4 text-left'>Comitted</th>
                                                                                    <th className='tracking-wider py-2 pl-4 text-left'>Allocated</th>
                                                                                    <th className='tracking-wider py-2 pl-4 text-left'>Balance</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='text-sm text-NeutralGray-900'>
                                                                                {state['initiativeProviders_'+row.initiative].map((ini, iniIndex) => {
                                                                                    return(
                                                                                        <tr key={"ini_"+iniIndex} className={`text-base ${iniIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                                            <td className={`py-2.5 pl-4 text-left`}>{ini.provider ? capitalizeAllLetter(ini.provider) : ''}</td>
                                                                                            <td className={`py-2.5 pl-4 text-left`}>{ini.allocation ? thousandSeparator(ini.allocation) : 0}</td>
                                                                                            <td className={`py-2.5 pl-4 text-left`}>{ini.distributuion ? thousandSeparator(ini.distributuion) : 0}</td>
                                                                                            <td className={`py-2.5 pl-4 text-left ${ini.balance > 0 ? '' : 'text-ferrariRed-600'}`}>{ini.balance ? thousandSeparator(ini.balance) : 0}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className='pt-5'>
                                                                <p className='text-NeutralGray-800 font-bold text-[22px]'>Monthly Budget</p>
                                                                <div className='flex flex-wrap pt-5'>
                                                                    {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                                        return(
                                                                            <div className='w-1/4' key={"montly_q_"+rowIndex}>
                                                                                {quarter.map(mnt => {
                                                                                    return(
                                                                                        <div className='mb-3 pr-3 text-black' key={'ini_'+mnt.label}>
                                                                                            <Textbox
                                                                                                type={'number'}
                                                                                                label={mnt.label+' '+(state.year ? state.year : '')}
                                                                                                selectedValue={state['initiative_'+mnt.label+'_'+iIndex] ? state['initiative_'+mnt.label+'_'+iIndex] : ''}
                                                                                                callback={(value) => {
                                                                                                    setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: onlyNumeric(value) }))
                                                                                                    handleInitiativeMonthlySplit(mnt.value, iIndex, onlyNumeric(value))
                                                                                                    onChangeHandleValidation(onlyNumeric(value), mnt)
                                                                                                }}
                                                                                                inputType={state.pageType}
                                                                                                placeholder={'amount'}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                
                                                                                <div className="flex justify-center w-4/5">
                                                                                    <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                                </div>
                                                                                <span className='flex justify-center mt-2 w-4/5 text-lg font-medium'>Q{rowIndex+1} <span className='ml-2 text-primaryPurple-600'>{state['initiative_quater_'+rowIndex+'_'+iIndex] ? Math.round(state['initiative_quater_'+rowIndex+'_'+iIndex],0) : 0}</span></span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                
                                                                <div className='flex flex-wrap justify-between mt-4'>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center text-lg font-medium'>H1 <span className='ml-2 text-primaryPurple-600'>{state['initiative_half_0_'+iIndex] ? Math.round(state['initiative_half_0_'+iIndex],0) : 0}</span></span>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center text-lg font-medium'>H2 <span className='ml-2 text-primaryPurple-600'>{state['initiative_half_1_'+iIndex] ? Math.round(state['initiative_half_1_'+iIndex],0) : 0}</span></span>
                                                                    </div>
                                                                </div>
                                                                
                                                                {/* <div className='flex justify-between mt-4'>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                </div>
                                                                <div className='flex justify-between mt-2'>
                                                                    <span className='w-50 flex justify-center'>H1 {state['initiative_half_0_'+iIndex] ? Math.round(state['initiative_half_0_'+iIndex],0) : 0}</span>
                                                                    <span className='w-50 flex justify-center'>H2 {state['initiative_half_1_'+iIndex] ? Math.round(state['initiative_half_1_'+iIndex],0) : 0}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            : null}
                        </div>
                    : null}
                </div>
			</div>
		</div>
	)
}

export default LeftSection