/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LogsTab.js
 * @author Prakash // on 06/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { convertToLowerCase, getAccountNameFromId, getCommonInformationMessage, getPaginationShowingDetails, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { getJobLogs, sartSystemJobLogs, getSystemJobLogs, downloadJobLogs, listDownloadResults, deleteDownloadResults } from '../../../actions/Collider/JobStatusAction'

import Search from '../../common/SearchWithHiddenInput'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
 import { Store as CommonNotification } from 'react-notifications-component';
 import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';


const LogsTab = ({selectedJobDetails}) => {
    
    const dispatch = useDispatch(); // Create a dispatch function
    const [isNavigating, setIsNavigating] = useState(false); // To prevent double-clicks
    const [state, setState] = useState({
        showLogLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
        logTypes: [
            {label: 'Job Output', value: 'job'},
            {label: 'Job Error', value: 'error'},
            {label: 'Compute Node', value: 'compute node'},
            {label: 'Head Node', value: 'head node'}
        ],
        selectedJobLog: 'job',
        downloadLogType: 'job',
        callJobLogs: true,
    })

    useEffect(() => {
        if(state.callJobLogs) {
            setState(prevState => ({ ...prevState, callJobLogs: false }))
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if (selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }
            
            params.job_id = selectedJobDetails.job_id;
            params.log_type = state.selectedJobLog

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            if(state.listResponse && state.listResponse.first_token) {
                params.first_token = state.listResponse && state.listResponse.first_token
            }
            if(state.listResponse && state.listResponse.last_token) {
                params.last_token = state.listResponse && state.listResponse.last_token
            }

            params.size = state.perPage
    
            dispatch(getJobLogs(params))
                .then((response) => {
                    if(response) {
                        
                        let results = response && response.results ? response.results : []
                        
                        setState(prevState => ({ ...prevState, listResponse: response, logsList: results, filteredLogsList: results, showLogLoading: false }));
                    }
                })
        }
    }, [state.callJobLogs, dispatch, selectedJobDetails, state.perPage, state.selectedJobLog, state.listResponse, state.token])

    

    const handleNavigation = (direction) => {
        if (isNavigating) return; // Prevent further clicks
    
        setIsNavigating(true); // Block further navigation
        const newPage = direction === 'previous' ? state.currentPage - 1 : state.currentPage + 1;
    
        // Update the state and simulate API call
        setState((prevState) => ({ ...prevState, showLogLoading: true, currentPage: newPage, token: direction, callJobLogs: true }));
    
        // Simulate async operation or API call
        setTimeout(() => { setIsNavigating(false); // Re-enable navigation
          setState((prevState) => ({ ...prevState, showLogLoading: false })); // Remove loading
        }, 1000); // Simulated delay
    };

    useEffect(() => {
        if(state.callNodeLogs) {
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if(selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }

            if(selectedJobDetails.start_time) {
                params.start_time = selectedJobDetails.start_time;
            }
            if(selectedJobDetails.end_time) {
                params.end_time = selectedJobDetails.end_time;
            }
            if(selectedJobDetails.created_at) {
                params.created_at = selectedJobDetails.created_at;
            }
            params.job_id = selectedJobDetails.job_id;    
            params.log_type = state.selectedJobLog
            dispatch(sartSystemJobLogs(params))
                .then((response) => {
                    if(response) {    
                        setState(prevState => ({ ...prevState, getSystemJobLogsInput: response,
                            callNodeLogs: false,
                            callGetNodeLogs: true,
                            // showLogLoading: true
                        }));
                    }
                })
        }
    }, [state.callNodeLogs, dispatch, selectedJobDetails, state.perPage, state.selectedJobLog])    

    useEffect(() => {
        if(state.callGetNodeLogs) {
            let params = state.getSystemJobLogsInput
            dispatch(getSystemJobLogs(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        if(response.status && response.status.toLowerCase() === "running" && !results.length) {
                            setState(prevState => ({ ...prevState, logResponse: response, showLogLoading: false, callGetNodeLogs: false }))
                            setTimeout(() => setState(prevState => ({ ...prevState, callGetNodeLogs: true })), 5000) //showLogLoading: true
                        } else {
                            let totalPages = 1
                            if(results.length > state.perPage) {
                                totalPages = Math.ceil(results.length / state.perPage)
                            }
                            if(response.status && response.status.toLowerCase() === "running") {
                                setTimeout(() => setState(prevState => ({ ...prevState, callGetNodeLogs: true })), 5000) //showLogLoading: true
                            }
        
                            setState(prevState => ({ ...prevState, logResponse: response, logsList: results, filteredLogsList: results, totalPages, startRecord: 0, currentPage: 1, showLogLoading: false, callGetNodeLogs: false }));
                        }
                    }
                })
        }
    }, [state.callGetNodeLogs, dispatch, selectedJobDetails, state.perPage, state.getSystemJobLogsInput])

    // Function to navigate between pages
    const navigateLogPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextLogToken) {            
            setState(prevState => ({ ...prevState, showLogLoading: true, callJobLogs: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }
    
    const downloadJobLogFunction = (item) => {
        let params = {
            provider: selectedJobDetails.provider,
            account_id: selectedJobDetails.account_id,
            region: selectedJobDetails.region,
        };

        if (selectedJobDetails.cluster_name) {
            params.cluster_name = selectedJobDetails.cluster_name;
        }

        if(selectedJobDetails.start_time) {
            params.start_time = selectedJobDetails.start_time;
        }
        if(selectedJobDetails.end_time) {
            params.end_time = selectedJobDetails.end_time;
        }
        if(selectedJobDetails.created_at) {
            params.created_at = selectedJobDetails.created_at;
        }
        params.job_id = selectedJobDetails.job_id;    
        if(item) {
            params.log_type = item.log_type
        } else {
            params.log_type = state.downloadLogType
        }
        
        // Download job logs here using params
        dispatch(downloadJobLogs(params))
            .then(response => {
                let fileUrl = response && response.url ? response.url : ''
                if(fileUrl) {
                    window.open(fileUrl, '_blank')
                } else {
                    let messageType = 'success'
                    let message = 'Download Initiated check download list'

                    setTimeout(() => { setState(prevState => ({ ...prevState, callDownloadList: true }))}, 1000)

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                }
            })
    };
    
    
    const handleChange = (value) => {
        let selectedJobLog = value
        if(selectedJobLog === "job" || selectedJobLog === "error") {
            setState(prevState => ({ ...prevState, selectedJobLog, filteredLogsList: [], logsList: [], nextLogToken: '', logResponse: {}, showLogLoading: true, callJobLogs: true }))
        } else {
            setState(prevState => ({ ...prevState, selectedJobLog, filteredLogsList: [], logsList: [], nextLogToken: '', logResponse: {}, showLogLoading: true, callNodeLogs: true }))
        }
    }

    // const onClickDownloadList = () => {
    //     setState(prevState => ({ ...prevState, showDownloadList: !state.showDownloadList, callDownloadList: !state.showDownloadList, showLogLoading: !state.showDownloadList }))
    // }
    
    useEffect(() => {
        if(state.callDownloadList) {
            setState(prevState => ({ ...prevState, callDownloadList: false }));
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if(selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }
            params.job_id = selectedJobDetails.job_id;
            // params.status = "Completed"
            params.log_type = 
            dispatch(listDownloadResults(params))
                .then((response) => {
                    if(response) {    
                        setState(prevState => ({ ...prevState, downloadList: response, filteredDownloadList: response, showLogLoading: false }));
                    }
                })
        }
    }, [state.callDownloadList, dispatch, selectedJobDetails])

    const handleDeleteDownload = (row) => {
        let params = {}
        params.download_id = row.download_id;
        dispatch(deleteDownloadResults(params))
            .then((response) => {
                // if(response) {    
                //     setState(prevState => ({
                //         ...prevState,
                //         downloadList: response,
                //         filteredDownloadList: response,
                //         callDownloadList: false,
                //         showLogLoading: false
                //     }));
                // }

                let messageType = 'success'
                let message = response && response.message ? response.message : 'Error in deleting the download'

                if(!response.status) {   
                    messageType = 'danger'  
                } else {
                    setTimeout(() => { setState(prevState => ({ ...prevState, callDownloadList: true }))}, 1000)
                }

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }

    const getStatusColor = (status) => {        
        let color = 'border border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'border border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'border border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'border border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }
    
    return (
        <div className='p-6'>
            <div className={`px-2`}>
                <ul className="flex flex-wrap text-sm text-white font-bold text-center mb-1 mt-3">
                    <li className="me-2">
                        <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${!state.showDownloadList ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, showDownloadList: false }))}>Logs</span>
                    </li>
                    <li className="me-2">
                        <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${state.showDownloadList ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, showDownloadList: true, callDownloadList: true }))}>Downloads</span>
                    </li>
                </ul>
            </div>
            <div className='pt-8'>
                {!state.showDownloadList ?
                    <div className='flex justify-between'>
                        <div className='flex mr-2 w-2/3'>
                            {state.selectedJobLog === 'job' || state.selectedJobLog === 'error' ?
                                <span className='text-lightGray text-sm m-0 self-center pt-1'>Showing {getPaginationShowingDetails(state.logsList && state.logsList.length, ((state.currentPage-1) * state.perPage + (state.logsList && state.logsList.length)), state.currentPage, state.perPage)}</span>
                            : 
                                <span className='text-lightGray text-sm m-0 self-center pt-1'>Showing {state.filteredLogsList && state.filteredLogsList.length} of total {state.logsList && state.logsList.length}</span>
                            }
                            <div className={`self-center mx-2`}>
                                <SelectOption
                                    label={""}
                                    fields={["value", "label"]}
                                    options={state.logTypes}
                                    selectedValues={state.selectedJobLog ? state.selectedJobLog : ''}
                                    callbackMultiSelect={(value) => {
                                        handleChange(value)
                                    }}
                                    singleSelection={true}
                                    dropdownWidth={'min-w-32'}
                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                />
                            </div>
                            <span className='text-lightGray text-sm self-center pt-1'> log&nbsp;event(s)</span>
                            {!state.showDownloadList && (state.selectedJobLog === 'job' || state.selectedJobLog === 'error') ?
                                <div className='flex text-black self-center'>
                                    <div className="flex text-sm">
                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-400'}`} onClick={() => {
                                            if(state.listResponse && state.listResponse.previous_token) {
                                                setState(prevState => ({ ...prevState, showLogLoading: true, disablePagination: true }))
                                                setTimeout(() => {
                                                    handleNavigation('previous');
                                                }, 1000); 
                                            }
                                        }}/></span> 
                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-400'}`} onClick={() => {
                                            if(state.listResponse && state.listResponse.next_token) {
                                                handleNavigation('next');
                                            }
                                        }}/></span>
                                    </div>
                                </div>
                            : null}
                            {state.logResponse && state.logResponse.status && state.logResponse.status.toLowerCase() === 'running' ?
                                <span className='self-center ml-3'>
                                    <LoadingCircle />
                                </span>
                            : null}
                            {state.logResponse && state.logResponse.status ?
                                <span className={`ml-3 self-center px-2 py-1 rounded-full ${getStatusColor(state.logResponse.status)}`}>{state.logResponse.status}</span>
                            : null}

                            {state.logsList && state.logsList.length && state.logResponse && convertToLowerCase(state.logResponse.status) !== "completed" && convertToLowerCase(state.logResponse.status) !== "failed" ?
                                state.selectedJobLog !== "log" ?
                                    null
                                    // <span className="ml-3 flex far fa-redo text-xs cursor-pointer text-info self-center" onClick={() => setState(prevState => ({ ...prevState, currentPage: 1, startRecord: 0, nextLogToken: '', showLogLoading: true, callGetNodeLogs: true }))}> <span className="ml-2">refresh </span></span>
                                    // <span className={`ml-3 self-center text-info`} onClick={() => setState(prevState => ({ ...prevState, callGetNodeLogs: true }))}>get latest logs</span>
                                :                                     
                                    <span className="ml-3 flex text-xs cursor-pointer text-SlateBlue self-center" onClick={() => setState(prevState => ({ ...prevState, currentPage: 1, startRecord: 0, nextLogToken: '', callJobLogs: true }))}> 
                                        <Icon icon="ooui:reload" color="white" width={24} height={24} />
                                        <span className="ml-2">refresh </span>
                                    </span>
                                    // <span className={`ml-3 self-center text-info`} onClick={() => setState(prevState => ({ ...prevState, callJobLogs: true }))}>get latest logs</span>
                            : null}
                        </div>
                        <div className='w-1/3 mb-n2'>
                            <div className='flex justify-end self-center'>
                                {state.logsList && state.logsList.length ?
                                    <Search
                                        data={state.logsList ? state.logsList : []}
                                        topClassName={'border border-gray-600 rounded-full bg-white'}
                                        className={'rounded-full w-full'}
                                        filteredData={(filteredLogsList) => {
                                            let totalPages = 1
                                            if(filteredLogsList.length > state.perPage) {
                                                totalPages = Math.ceil(filteredLogsList.length / state.perPage)
                                            }
                                            setState(prevState => ({ ...prevState, filteredLogsList, totalPages, currentPage: 1, startRecord: 0 }))
                                        }}
                                    />
                                : null}
                            </div>
                            
                            {!state.showDownloadList && state.filteredLogsList && state.filteredLogsList.length > state.perPage ?
                                <div className='flex justify-end text-black w-full pb-4 pr-3'>
                                    <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                    <div className="flex text-sm">
                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-500' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigateLogPage('previous', state.currentPage)}/></span> 
                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => navigateLogPage('next', state.currentPage)}/></span>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                :
                    <div className='flex justify-between'>                        
                        <div className='flex justify-between w-full'>
                                <div className='flex justify-start'>
                                    <p className='cursor-pointer m-0 pt-1 mr-2 text-slateBlue-600 self-center' onClick={() => downloadJobLogFunction()}> Click Here</p>
                                    <p className='m-0 self-center pt-1 mr-2'>to Initiate download for </p>
                                    <div className={`self-center mx-2`}>
                                        <SelectOption
                                            label={""}
                                            fields={["value", "label"]}
                                            options={state.logTypes}
                                            selectedValues={state.downloadLogType ? state.downloadLogType : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, downloadLogType: value}))
                                            }}
                                            singleSelection={true}
                                            dropdownWidth={'min-w-32'}
                                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                        />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {state.downloadList && state.downloadList.length ?
                                        <div className='flex justify-end self-center'>
                                            {state.downloadList && state.downloadList.length ?
                                                <Search
                                                    data={state.logsList ? state.logsList : []}
                                                    topClassName={'border border-gray-600 rounded-full bg-white'}
                                                    className={'rounded-full w-full'}
                                                    filteredData={(filteredDownloadList) => {
                                                        setState(prevState => ({ ...prevState, filteredDownloadList }))
                                                    }}
                                                />
                                            : null}
                                        </div>
                                    : null}
                                    {state.downloadList && state.downloadList.length && state.downloadList.filter(e => convertToLowerCase(e.status) !== "completed").length && state.downloadList.filter(e => convertToLowerCase(e.status) !== "failed").length ?
                                        <span className={`mx-3 self-center text-slateBlue-600 cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, callDownloadList: true }))}>Refresh Downloads</span>
                                    : null}
                                </div>
                        </div>
                    </div>
                }
                
                <div className='flex overflow-auto h-screen bg-white border border-NeutralGray-500 rounded-2xl mt-2'>
                    <div className={`w-full`}>
                        <div className={`p-5`} >
                            {state.showLogLoading ? 
                                <div className='flex justify-center m-4'>
                                    <LoadingCircle />
                                </div>
                            : !state.showDownloadList ?
                                state.filteredLogsList && state.filteredLogsList.length ?
                                    state.filteredLogsList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`flex mr-2 px-2 mb-2 py-1`} key={'key_'+i}>
                                                <div className='flex'>
                                                    <p className='mb-0'>
                                                        <span className='mb-0 mr-2 text-sm text-black'>{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        <span className='mb-0 mr-2 text-sm text-black'>{item.message}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-white'>
                                        {getCommonInformationMessage('log')}
                                    </div>
                            : 
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: "Date",
                                            accessor: "date",
                                            Cell: cellInfo => (<span>{cellInfo.row.original.timestamp ? momentConvertionUtcToLocalTime(cellInfo.row.original.timestamp, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                            width: 150
                                        },
                                        {
                                            Header: "Job Details",
                                            accessor: "job_name",
                                            Cell: () => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {selectedJobDetails && selectedJobDetails.job_name ? selectedJobDetails.job_name : ""}
                                                </span>
                                                <span className='ml-2'>
                                                    ({selectedJobDetails && selectedJobDetails.job_id ? selectedJobDetails.job_id : ""})
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 200
                                        },
                                        {
                                            Header: "Cluster",
                                            accessor: "cluster_name",
                                            width: 200
                                        },
                                        {
                                            Header: "Log Type",
                                            accessor: "log_type",
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {cellInfo.row.original.log_type && state.logTypes.filter(e => e.value === cellInfo.row.original.log_type).length ? state.logTypes.filter(e => e.value === cellInfo.row.original.log_type)[0].label : cellInfo.row.original.log_type}
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 130
                                        },
                                        {
                                            Header: "Resource",
                                            accessor: d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region,
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 220
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            Cell: cellInfo => (
                                                cellInfo.row.original.status ?
                                                    <span className={`${getStatusColor(cellInfo.row.original.status)} mr-2 mt-2 self-center rounded-full text-sm px-3 py-2.5`}>{cellInfo.row.original.status}</span>
                                                : null
                                            )
                                        },
                                        {
                                            Header: " ",
                                            Cell: cellInfo => (
                                                cellInfo.row.original.status ?
                                                    <div className='flex'>
                                                        <span className="text-slateBlue-600 text-sm cursor-pointer" onClick={() => downloadJobLogFunction(cellInfo.row.original)}>Download</span>
                                                        <span className="text-black mx-2 text-sm">/</span>
                                                        <span className="text-red-600 mr-2 text-sm cursor-pointer" onClick={() => handleDeleteDownload(cellInfo.row.original)}>Delete</span>
                                                    </div>
                                                : null
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={state.filteredDownloadList ? state.filteredDownloadList : []}
                                    perPage={10}
                                    selectedColor={''}
                                    dashboard={true}
                                    sortByColumn={"date"}
                                    riskTooltip={[]}
                                    onClickRow={tableRow => {}}
                                    emptyMessage={"There is no data on this criteria"}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogsTab