/*************************************************
 * Collider
 * @exports
 * @file CreateMappingRight.js
 * @author Prakash // on 19/03/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getAccountNameFromId } from '../../../utils/utility';
import _ from 'lodash'
import { getAllTagsKeys, getAllTagsValues, listAllAccounts, listAllProviders, setCommonPropsDetails } from '../../../actions/commonActionNew';
import AlertConfirmation from '../../common/AlertConfirmation';
import {Icon} from "@iconify/react";
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';

const CreateMappingRight = (props) => {
	const[state, setState] = useState({
		showDetails: false
	})

	const dispatch = useDispatch(); // Create a dispatch function

    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const selectedMappingInitiative = useSelector(state => state?.hpc?.hpcPropsDetails?.selectedMappingInitiative || false);
    const createMappingInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createMappingInputs || false);
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);

    useEffect(() => {
        let params = {}
        if(!providers) {
            dispatch(listAllProviders(params))
        }
    }, [dispatch, providers])

    const getProviderAccountDetails = useCallback((value, prv, proj, init) => {
        let params = {};
        // let label = ''
        // params.aggregate_by = 'account_id';
        params.provider = value.toLowerCase();

        if(propProjAccounts && propProjAccounts.hasOwnProperty(value)) {
            // setState(prevState => ({ ...prevState, ['account_'+prv+'_'+proj+'_'+init]: [], ['accountOptions_'+prv+'_'+proj+'_'+init]: propProjAccounts[label] }));
            setState(prevState => ({ ...prevState, ['accountOptions_'+prv+'_'+proj+'_'+init]: propProjAccounts[value] }));
        } else {
            dispatch(listAllAccounts(params))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[value] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        // setState(prevState => ({ ...prevState, ['account_'+prv+'_'+proj+'_'+init]: [], ['accountOptions_'+prv+'_'+proj+'_'+init]: response }));
                        setState(prevState => ({ ...prevState, ['accountOptions_'+prv+'_'+proj+'_'+init]: response }));
                    }
                })
        }
    }, [dispatch, propProjAccounts])
    
    useEffect(() => {
        if(selectedMappingInitiative && !_.isEqual(selectedMappingInitiative, state.selectedMappingInitiative)) {
            setState(prevState => ({ ...prevState, selectedMappingInitiative, initiativeName: selectedMappingInitiative.initiative, init: selectedMappingInitiative.init}))

            setTimeout(() => { setState(prevState => ({ ...prevState, changesinInitiative: true })) }, 1000);
        }
    }, [selectedMappingInitiative, state.selectedMappingInitiative, dispatch])

    useEffect(() => {
        if(!tagKeys) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }, [tagKeys, dispatch])

	const getAllTagFunction = (field, selectedKey, proj, init) => {
		if(selectedKey) {
			let params = {}
			params.tags = [{ "key": selectedKey }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
                        if(state['selectedTags_'+proj+'_'+init] && state['selectedTags_'+proj+'_'+init].length) {
                            let value = state['selectedTags_'+proj+'_'+init].filter(e => e.key === selectedKey).map(e => e.value)
                            setState(prevState => ({ ...prevState, ['tagValue_'+proj+'_'+init]: value }))
                        }
						setState(prevState => ({ ...prevState, [field]: response }))
					}
				})
		}
	}

    useEffect(() => {
		if(props.receivedData && Object.entries(props.receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: props.receivedData.selectedData ? props.receivedData.selectedData : '',
				pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
                // formatEditStructure: true
			}))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [props.receivedData, dispatch])

    useEffect(() => {
        if(state.formatEditStructure) {
            setState(prevState => ({ ...prevState, formatEditStructure: false }))
            let selectedData = state.selectedData

            // let projArray = []
            if(selectedData.initiatives && selectedData.initiatives.length) {
                selectedData.initiatives.forEach((init, iIndex) => {
                    if(!iIndex) {
                        setState(prevState => ({ ...prevState, initiativeName: init.initiative, init: iIndex, changesinInitiative: true }))
                    }
                    // if(init.projects && init.projects.length) {
                    //     init.projects.forEach((proj, pIndex) => {
                    //         projArray.push(pIndex)
                    //         if(!iIndex) {
                    //             setState(prevState => ({ ...prevState, initiativeName: init.initiative, init: iIndex, changesinInitiative: true }))

                    //             // setState(prevState => ({ ...prevState, ['project_'+pIndex+'_'+iIndex]: proj.project ? proj.project : '' }))
                    //             // proj.accounts && proj.accounts.forEach((row, rowIndex) => {
                    //             //     let provArray = [0]
                    //             //     let groupProviderSection = _.groupBy(state.selectedTags, "cloudProviderId")
                    //             //     Object.entries(groupProviderSection).forEach(([key, value]) => {
                    //             //         setState(prevState => ({ 
                    //             //             ...prevState, 
                    //             //             ['provider_'+init+'_'+rowIndex]: key,
                    //             //             ['account_'+init+'_'+rowIndex]: value,
                    //             //         }))
                    //             //     })
                    //             //     setState(prevState => ({ 
                    //             //         ...prevState, 
                    //             //         ['provider_'+iIndex+'_'+rowIndex]: row.cloudProviderId ? row.cloudProviderId : '',
                    //             //         // ['account_'+iIndex+'_'+rowIndex]: row.billingUnit ? row.billingUnit : '',
                    //             //         // ['selectedProjTags_'+iIndex+'_'+rowIndex]: row.tags ? row.tags : '',
                    //             //         selectedProjectSection: 'projectSection_0',
                    //             //         ['providerSection_'+rowIndex+'_'+iIndex]: provArray
                    //             //     }))
                    //             // })

                    //         }
                    //     })
                    // }
                })
            }
        }
    }, [state.formatEditStructure, state.selectedData])

    useEffect(() => {
        if(state.changesinInitiative) {
            setState(prevState => ({ ...prevState, changesinInitiative: false }))
            let projArray = [0]
            if(state.init >= 0) {
                projArray = []
                let obj = createMappingInputs ? createMappingInputs : {}
                let details = obj.initiatives && obj.initiatives.length ? obj.initiatives.filter(e => e.initiative === state.initiativeName)[0] : {}
                let init = state.init
                if(details && details.projects && details.projects.length) {
                    details.projects.forEach((row, rowIndex) => {
                        projArray.push(rowIndex)                        
                        let groupProviderSection = _.groupBy(row.accounts, "cloudProviderId")
                        let provArray = []
                        Object.entries(groupProviderSection).forEach(([key, value], pIndex) => {
                            provArray.push(pIndex)
                            getProviderAccountDetails(key, pIndex, rowIndex, init)
                            setState(prevState => ({ 
                                ...prevState, 
                                ['provider_'+pIndex+'_'+rowIndex+'_'+init]: key.toUpperCase(),
                                ['account_'+pIndex+'_'+rowIndex+'_'+init]: value.map(e => e.billingUnit),
                            }))
                        })

                        setState(prevState => ({ 
                            ...prevState, 
                            ['project_'+rowIndex+'_'+init]: row.project ? row.project : '',
                            ['selectedTags_'+rowIndex+'_'+init]: row.tags ? row.tags : '',
                            selectedProjectSection: 'projectSection_0',
                            // ['providerSection_0_'+init]: [0],
                            ['providerSection_'+rowIndex+'_'+init]: provArray
                        }))
                    })
                } else {
                    projArray = [0]
                    setState(prevState => ({  ...prevState, selectedProjectSection: 'projectSection_0', ['providerSection_0_'+init]: [0] }))
                }
            }

            setState(prevState => ({ ...prevState, projArray }))
        }
        
    }, [state, createMappingInputs, selectedMappingInitiative, getProviderAccountDetails])

    const handleInputChange = (label, value, selectedProj, type) => {
        let obj = createMappingInputs ? createMappingInputs : {}
        let projects = []
        state.projArray.forEach(proj => {
            let project = state['project_'+proj+'_'+state.init] ? state['project_'+proj+'_'+state.init] : ''
            if(proj === selectedProj && type === 'project') {
                project = value
            }
            
            let accounts = []
            state['providerSection_'+proj+'_'+state.init].forEach(prv => {
                if(label === 'account_'+prv+'_'+proj+'_'+state.init || (state['account_'+prv+'_'+proj+'_'+state.init] && state['account_'+prv+'_'+proj+'_'+state.init].length)) {
                    let provAccounts = state['account_'+prv+'_'+proj+'_'+state.init] ? state['account_'+prv+'_'+proj+'_'+state.init] : []
                    if(label === 'account_'+prv+'_'+proj+'_'+state.init) {
                        provAccounts = value
                    }
                    
                    provAccounts.forEach(col => {
                        let accRow = {}
                        accRow.cloudProviderId = state['provider_'+prv+'_'+proj+'_'+state.init] ? state['provider_'+prv+'_'+proj+'_'+state.init].toLowerCase() : ''
                        accRow.billingUnit = col
                        accounts.push(accRow)
                    })
                }

                // if(state['account_'+prv+'_'+proj+'_'+state.init] && state['account_'+prv+'_'+proj+'_'+state.init].length) {
                //     state['account_'+prv+'_'+proj+'_'+state.init].forEach(col => {
                //         let accRow = {}
                //         accRow.cloudProviderId = state['provider_'+prv+'_'+proj+'_'+state.init] ? state['provider_'+prv+'_'+proj+'_'+state.init].toLowerCase() : ''
                //         accRow.billingUnit = col
                //         accounts.push(accRow)
                //     })
                // }
            })

            const uniqueElements = {};

            // Filter array1 and store unique elements in the temporary object
            const filteredArray = accounts.filter(item => {
                if (!uniqueElements[item.billingUnit]) {
                    uniqueElements[item.billingUnit] = true;
                    return true;
                }
                return false;
            });

            let proRow = {
                project: project,
                accounts: filteredArray,
                tags: label === 'tags' && proj === selectedProj && value ? value : (state['selectedTags_'+proj+'_'+state.init] ? state['selectedTags_'+proj+'_'+state.init] : [])
            }
            projects.push(proRow)
        })

        let details = obj.initiatives && obj.initiatives.length ? obj.initiatives.filter(e => e.initiative === state.initiativeName)[0] : {}
        details.projects = projects ? projects : ''

        // dispatch(setHpcPropsDetails("createMappingInputs", obj))

        setState(prevState => ({ ...prevState, [label]: value}))
    }

    const addRemoveProviderSection = (action, prv, proj, init, proIndex) => {
        // let selectedProvider = state['selectedProviders_'+proj+'_'+state.init] ? state['selectedProviders_'+proj+'_'+state.init] : []
        if(action === 'add' && state['provider_'+prv+'_'+proj+'_'+state.init]) {
            // selectedProvider.push(state['provider_'+prv+'_'+proj+'_'+state.init])

            let rowList = state['providerSection_'+proj+'_'+init] ? state['providerSection_'+proj+'_'+init] : [0]
            if (rowList) {
                let value = state['providerSection_'+proj+'_'+init][state['providerSection_'+proj+'_'+init].length - 1]
                value = value + 1
                rowList.push(value)
                setState(prevState => ({ ...prevState, ['providerSection_'+value+'_'+init]: rowList}))
            }

        } else if(action === 'remove') {
            // selectedProvider = selectedProvider.filter(e => e !== state['provider_'+prv+'_'+proj+'_'+state.init])
            let rowList = state['providerSection_'+proj+'_'+init] ? state['providerSection_'+proj+'_'+init] : [0]

            rowList.splice(proIndex, 1);
            setState(prevState => ({ ...prevState, ['providerSection_'+proj+'_'+init]: rowList }))
            
        }

        // setState(prevState => ({ ...prevState, ['selectedProviders_'+proj+'_'+state.init]: selectedProvider }))
    }

	const addProjectTags = (proj, init) => {
		let selectedTags = state['selectedTags_'+proj+'_'+init] ? state['selectedTags_'+proj+'_'+init] : []

		if(state['tagKey_'+proj+'_'+init] && state['tagKey_'+proj+'_'+init] !== "") {
			let filteredTags = selectedTags.filter(e => e.key !== state['tagKey_'+proj+'_'+init])
			state['tagValue_'+proj+'_'+init].forEach(row => {
				let dataRow = {
					key: state['tagKey_'+proj+'_'+init],
					value: row,
				}
				filteredTags.push(dataRow)
			})

            handleInputChange('tags', filteredTags, proj)

			setState(prevState => ({ ...prevState, ['selectedTags_'+proj+'_'+init]: filteredTags, ['tagKey_'+proj+'_'+init]: '', ['tagValue_'+proj+'_'+init]: [], ['tagValueOptions_'+proj+'_'+init]: [] }))
		}
	}

	const removeTagSection = (tag, proj, init) => {
		let filteredTags = state['selectedTags_'+proj+'_'+init].filter(e => e !== tag)

        handleInputChange('tags', filteredTags, proj)
		setState(prevState => ({ ...prevState, ['selectedTags_'+proj+'_'+init]: filteredTags }))
	}

    const expandProjects = useCallback((selectedProjectSection) => {
        if(state.selectedProjectSection === selectedProjectSection) {
            selectedProjectSection = ''
        }
        setState(prevState => ({ ...prevState, selectedProjectSection }))        
    }, [state.selectedProjectSection])

    const addFilterArray = (field, init) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)

            expandProjects('projectSection_'+value, value)

            setState(prevState => ({ ...prevState, ['providerSection_'+value+'_'+init]: [0]}))
		}

		setState(prevState => ({ ...prevState, [field]: rowList }))

	}

	const removeFilterArray = (field, proj, index) => {
		let rowList = state[field] ? state[field] : [0]
		rowList.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: rowList, ['project_'+proj+'_'+state.init]: '',  ['selectedTags_'+proj+'_'+state.init]: '', ['providerSection_'+proj+'_'+state.init]: [0] }))

        state['providerSection_'+proj+'_'+state.init].forEach(prv => {
            setState(prevState => ({ 
                ...prevState, 
                ['provider_'+prv+'_'+proj+'_'+state.init]: '',
                ['account_'+prv+'_'+proj+'_'+state.init]: []
            }))
        })
        
        handleInputChange()
    }

    const confirmActionDetails = (field, proj, index) => {
        let obj = {
            field: field,
            proj: proj,
            index: index,
            message: 'Are you sure you want to remove the selected project',
            heading: 'Confirm Removal'
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, confirmAction: true }))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
        state.initiativeName ?
            <div className='overflow-y-auto h-[calc(100vh-200px)] border border-byzantineBlue bg-AzureishWhite-600 rounded-2xl p-4' onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='text-black mx-0 pb-2 rounded-2xl vh100'>
                    <p className='mb-4 text-[22px] text-NeutralGray-800 uppercase font-bold'>Projects
                    <span className='font-light'>{` (${state.initiativeName ? state.initiativeName : ''}) `}</span>
                    </p>
                    {state.projArray && state.projArray.length ?
                        state.projArray.map((proj, projIndex) => {
                            return(
                                <React.Fragment>
                                <div className='p-3 border border-NeutralGray-600 bg-white rounded-2xl' key={'proj_'+projIndex}>
                                    <div className={`mb-0 cursor-pointer`} 
                                        onClick={() => expandProjects('projectSection_'+proj, proj)}
                                    >
                                    <div className='flex justify-between'>
                                        <div className='flex justify-start self-center'>
                                            {state.selectedProjectSection === 'projectSection_'+proj ? 
                                                <Icon icon="mdi-light:minus" className={`ml-2 text-black self-center cursor-pointer`} width="20" height="20" />
                                            : 
                                                <Icon icon="iconoir:plus" width="20" height="20"  className='ml-2 text-black self-center cursor-pointer' />
                                            }<span className={`ml-4 text-lg font-bold`} >{state['project_'+proj+'_'+state.init]}</span>
                                        </div>
                                        {state.projArray.length > 1 ?
                                            <span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => {
                                                // removeFilterArray('initArray', initIndex)
                                                confirmActionDetails('projArray', proj, projIndex)
                                            }} >Remove
                                            </span>
                                        : null}
                                    </div>
                                </div>
                                    {state.selectedProjectSection === 'projectSection_'+proj ?
                                        <div className='p-3'>
                                            <div className='flex flex-wrap pt-8'>
                                                <div className='lg:pr-4 w-1/2'>
                                                    <Textbox
                                                        label={"Project name"}
                                                        type="text"
                                                        selectedValue={state['project_'+proj+'_'+state.init] ? state['project_'+proj+'_'+state.init] : ''}
                                                        callback={(value) => {
                                                            handleInputChange(['project_'+proj+'_'+state.init] , value)
                                                        }}
                                                        placeholder={'Enter project name'}
                                                        manditory={true}
                                                        hasError={state.hasError}
                                                        dropdownWidth={'w-full'}
                                                    />
                                                </div>
                                            </div>

                                            {state['providerSection_'+proj+'_'+state.init] ? 
                                                state['providerSection_'+proj+'_'+state.init].map((prv, proIndex) => {
                                                    return(
                                                        <React.Fragment>
                                                        <div className='flex flex-wrap pt-8'>
                                                            <div className='flex justify-start w-2/5 pr-4'>
                                                                <SelectOption
                                                                    label={"Provider"}
                                                                    fields={['provider_name', 'provider_name']}
                                                                    options={providers}
                                                                    selectedValues={state['provider_'+prv+'_'+proj+'_'+state.init] ? state['provider_'+prv+'_'+proj+'_'+state.init] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        setState(prevState => ({ ...prevState, ['provider_'+prv+'_'+proj+'_'+state.init]: value, ['account_'+prv+'_'+proj+'_'+state.init]: [] }))
                                                                        if(value) {
                                                                            getProviderAccountDetails(value, prv, proj, state.init)
                                                                        }
                                                                        setTimeout(() => handleInputChange(), 2000);
                                                                    }}
                                                                    singleSelection={true}
                                                                    dropdownWidth={'w-full'}
                                                                />
                                                            </div>
                                                            <div className='flex justify-start w-2/5 pl-4'>
                                                                <SelectOption
                                                                    label={"Account"}
                                                                    fields={["account_id", "account_name"]}
                                                                    options={state['accountOptions_'+prv+'_'+proj+'_'+state.init] ? state['accountOptions_'+prv+'_'+proj+'_'+state.init] : []}
                                                                    selectedValues={state['account_'+prv+'_'+proj+'_'+state.init] ? state['account_'+prv+'_'+proj+'_'+state.init] : []}
                                                                    callbackMultiSelect={(value) => {
                                                                        setState(prevState => ({ ...prevState, ['account_'+prv+'_'+proj+'_'+state.init]: value }))
                                                                        handleInputChange('account_'+prv+'_'+proj+'_'+state.init, value, proj);
                                                                    }}
                                                                    // singleSelection={true}
                                                                    dropdownWidth={'w-full'}
                                                                    manditory={true}
                                                                    hasError={state.hasError}
                                                                />
                                                            </div>
                                                            <div className='flex justify-end self-center pt-4 pl-4'>
                                                                {state['providerSection_'+proj+'_'+state.init].length === proIndex+1 && state['providerSection_'+proj+'_'+state.init].length < providers.length && state['provider_'+prv+'_'+proj+'_'+state.init] ?
                                                                    <Button
                                                                        classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                                                        label={'+'}
                                                                        callback={() => {
                                                                            addRemoveProviderSection('add', prv, proj, state.init)
                                                                        }}
                                                                    />
                                                                : null}
                                                                {state['providerSection_'+proj+'_'+state.init].length > 1 ?
                                                                    <Icon icon="mdi:trash" width="25" height="25" className="mr-1 self-center cursor-pointer text-danger ml-2 mt-2" onClick={() => addRemoveProviderSection('remove', prv, proj, state.init, proIndex)} />
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-wrap mt-2'>
                                                            {state['account_'+prv+'_'+proj+'_'+state.init] && state['account_'+prv+'_'+proj+'_'+state.init].length ? 
                                                                state['account_'+prv+'_'+proj+'_'+state.init].map((acc, index) => {
                                                                    return(
                                                                        <span key={'proj_acc_'+proj+prv+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{getAccountNameFromId(acc, state['accountOptions_'+prv+'_'+proj+'_'+state.init])}
                                                                            <Icon icon="jam:close" width="20" height="20" className='text-black ml-2 self-center' 
                                                                                onClick={ () => 
                                                                                    setState(prevState => ({ ...prevState, ['account_'+prv+'_'+proj+'_'+state.init]: state['account_'+prv+'_'+proj+'_'+state.init].filter(e => e !== acc) }))
                                                                                }
                                                                            />
                                                                        </span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                        </React.Fragment>
                                                        )
                                                    })
                                                : null}
                                        <div className='flex flex-wrap pt-8 justify-between'>
                                            <div className='flex justify-start w-2/5 pr-4'>
                                                <SelectOption
                                                    label={"Tags"}
                                                    options={tagKeys}
                                                    selectedValues={state['tagKey_'+proj+'_'+state.init] ? state['tagKey_'+proj+'_'+state.init] : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ['tagKey_'+proj+'_'+state.init]: value,  ['tagValueOptions_'+proj+'_'+state.init]: [], ['tagValue_'+proj+'_'+state.init]: []}))
                                                        getAllTagFunction('tagValueOptions_'+proj+'_'+state.init, value, proj, state.init)
                                                    }}
                                                    singleSelection={true}
                                                    dropdownWidth={'w-full'}
                                                />
                                            </div>
                                            <div className='flex justify-start w-2/5 pl-4'>
                                                <SelectOption
                                                    options={state['tagValueOptions_'+proj+'_'+state.init]}
                                                    selectedValues={state['tagValue_'+proj+'_'+state.init] ? state['tagValue_'+proj+'_'+state.init] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ['tagValue_'+proj+'_'+state.init]: value }))
                                                    }}
                                                    singleSelection={false}
                                                    dropdownWidth={'w-full'}
                                                />
                                            </div>
                                            <div className='flex justify-end pt-8 pl-4'>
                                                <Button
                                                    classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                                    label={'+'}
                                                    callback={() => {
                                                        addProjectTags(proj, state.init)
                                                    }}
                                                />
                                            </div>
                                            <div className='flex flex-wrap mt-2'>
                                                {state['selectedTags_'+proj+'_'+state.init] && state['selectedTags_'+proj+'_'+state.init].length ? 
                                                    state['selectedTags_'+proj+'_'+state.init].map((tag, index) => {
                                                        return(
                                                            <span key={'proj_tag_'+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{tag.key +" : "+tag.value}
                                                                <Icon icon="jam:close" width="20" height="20" className='text-black ml-2 self-center' onClick={ () => removeTagSection(tag, proj, state.init) } />
                                                            </span>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                    {state.confirmAction && state.confirmDetails ?
                                        <AlertConfirmation
                                            confirmDetails={state.confirmDetails}
                                            confirmation={(action) => {
                                                if(action === 'confirmed') {
                                                    removeFilterArray(state.confirmDetails.field, state.confirmDetails.proj, state.confirmDetails.index)
                                                }
                                                setState(prevState => ({ ...prevState, confirmAction: false, confirmDetails: false }))
                                            }}
                                        />
                                    : null}
                                </div>
                                <div className='flex justify-center mt-6 mb-4'>
                                {state.projArray.length === projIndex+1 && state['project_'+proj+'_'+state.init] ?
                                    <span className={` text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => addFilterArray('projArray', state.init)} >
                                        {/* <i className={`far fa-plus f16 mt-2 mb-1 self-end mr-2`}></i> */} 
                                        + Add Project
                                    </span>
                                : null}
                                </div>
                                </React.Fragment>
                            )
                        })
                    : null}
                </div>
            </div>
        : null
	)
}

export default CreateMappingRight