/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateUserPanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createUser, updateUser, listUserFilters } from '../../../actions/UserAction'
import _ from 'lodash'
import { qosListAccounts } from '../../../actions/Collider/AccountAction';
 import { Store as CommonNotification } from 'react-notifications-component';
import { validateEmail, removeUnderScore } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';
import Checkbox from '../../common/Form/Checkbox';
const CreateUpdateUserPanel = ({ closeSidePanel, refreshApi, pageMode, selectedUserDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        updateStrategies: ['drain', 'compute fleet stop', 'terminate', 'force update'],
        clusters: [],
        callUserFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false)
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
                })
        }
    }, [dispatch, providers]);

    const getMenuList = useCallback((applicationId, roleId) => {
        let data = state.applications
        const application = data.find(app => app.application_id === applicationId);
        if (!application) return null;
      
        const role = application.roles.find(role => role.role_id === roleId);
        if (!role) return null;
      
        return role.menulist;
    }, [state.applications])

    useEffect(() => {
        if(state.callUserFilters) {
            setState(prevState => ({ ...prevState, callUserFilters: false }))
            let params = {};
            dispatch(listUserFilters(params))
                .then((response) => {
                    if(response) {
                        
                        let groups = response.groups ? response.groups : []
                        let applications = response.applications ? response.applications : []

                        setState(prevState => ({ ...prevState, groups, applications }));
                    }
                })
        }
    }, [state.callUserFilters, dispatch, pageMode])

    useEffect(() => {
        if(state.applications && selectedUserDetails && selectedUserDetails.applications && selectedUserDetails.applications.length){
            let applicationId = []
            selectedUserDetails.applications.forEach((app, appIndex) => {
                applicationId.push(app.application_id)
                
                setState(prevState => ({ ...prevState, ['role_id_'+appIndex]: app.role_id, ['menulist_'+appIndex]: getMenuList(app.application_id, app.role_id) }))
            })

            setState(prevState => ({ ...prevState, applicationId }))
        }
    }, [state.applications, selectedUserDetails, getMenuList])

    const getAccountRegion = useCallback((provider, project, account, region, arrIndex, type) => { 
        if(!region || (region && !region.length)) {
            let params = {};
            params.provider = provider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(provider)) {
                setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: propProjects[provider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[provider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: response }))
                        }
                    });
            }
    
            let accParams = {};
            accParams.provider = provider.toLowerCase();
            accParams.aggregate_by = 'account_id';
            let label = provider
            if(project && project.length) {
                label = ''
                accParams.project_tags = project
            }
    
            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(accParams))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label) {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                            }
                            setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: response }));
                        }
                    })
            }
    
            let regParams = {};
            regParams.provider = provider.toLowerCase();
            regParams.aggregate_by = 'region';
            let regLabel = provider
            if(project && project.length) {
                regLabel = ''
                regParams.project_tags = project
            }
            if(account && account.length) {
                regLabel = ''
                regParams.account_id = account
            }
            
            if(propProjRegions && propProjRegions.hasOwnProperty(regLabel)) {
                setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: propProjRegions[regLabel] }));
            } else {
                dispatch(listClusterFilters(regParams))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[regLabel] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: response }))
                    })
            }
        }

        let clusParams = {};
        
        if(provider) {
            clusParams.provider = provider.toLowerCase();
        }
        if(project && project.length) {
            clusParams.project_tags = project
        }
		if(account && account.length) {
			clusParams.account_id = account
		}
		if(region && region.length) {
			clusParams.region = region
		}
        clusParams.aggregate_by = 'cluster_name';
        if(provider) {
            dispatch(listClusterFilters(clusParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ['clusters_'+arrIndex]: response }));
                        if(type !== 'edit') {
                            setState(prevState => ({ ...prevState, ["selectedClusters_"+arrIndex]: [] }))
                        }
                    }
            })
        }

    }, [dispatch, propProjAccounts, propProjRegions, propProjects])

    useEffect(() => {
        let accParams = {};
        accParams.provider = 'aws'
        accParams.aggregate_by = 'account_id'
        let label = 'AWS'
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerAccounts: propProjAccounts[label] }));
        } else {
            dispatch(listClusterFilters(accParams))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, providerAccounts: response }));
                    }
                })
        }

        let regParams = {};
        regParams.provider = 'aws'
        regParams.aggregate_by = 'region'        
        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerRegions: propProjRegions[label] }));
        } else {
            dispatch(listClusterFilters(regParams))
                .then((response) => {
                    if(response && response.length) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                    }
                    setState(prevState => ({ ...prevState, providerRegions: response }))
                })
        }
    }, [dispatch, propProjAccounts, propProjRegions])

    const getSlurmClusterDetailsFunction = useCallback((provider, account, region, slmIndex, selectedCluster) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.aggregate_by = 'cluster_name'

        dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: response, ["grpCluster_"+slmIndex]: selectedCluster ? selectedCluster : '', fetchingCluster: false }));
                } else {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: [], ["grpCluster_"+slmIndex]: '', fetchingCluster: false }));
                }
        })
    }, [dispatch])

    const getSlurmAccountDetails = useCallback((provider, account, region, cluster, slmIndex) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.cluster_name = cluster

        dispatch(qosListAccounts(params))
            .then((response) => {
                let results = []
                if(response) {
                    results = response.results ? response.results : []
                }
                setState(prevState => ({ ...prevState, ['groupList_'+slmIndex]: results, fetchingSlurmAccount: false }))
        })
    }, [dispatch])

    //for slurm account end

    useEffect(() => {
        if(state.onLoad && pageMode !== 'Create') {
            setState(prevState => ({ ...prevState, shortName: selectedUserDetails.shortName, emailId: selectedUserDetails.emailId, disabled: selectedUserDetails.disabled ? true : false, send_alert_notifications:selectedUserDetails.send_alert_notifications ? true : false, userName: selectedUserDetails.userName, roleId: selectedUserDetails.roleId, userType: selectedUserDetails.userType, allow_all: selectedUserDetails.allow_all ? true : false, onLoad: false }))

            let groupArray = []
            if(selectedUserDetails.account_list && selectedUserDetails.account_list.length) {
                selectedUserDetails.account_list.forEach((row, index) => {
                    groupArray.push(index)
                    setState(prevState => ({ ...prevState, ["grpAccount_"+index]: row.account_id ? row.account_id : '', ["grpRegion_"+index]: row.region ? row.region : '', ["grpCluster_"+index]: row.cluster_name ? row.cluster_name : '', ["selectedGroup_"+index]: row.account ? row.account : [] }))

                    if(row.provider && row.account_id && row.region) {
                        getSlurmClusterDetailsFunction('aws', row.account_id, row.region, index, row.cluster_name)
                    }
                    if(row.provider && row.account_id && row.region && row.cluster_name) {
                        getSlurmAccountDetails('aws', row.account_id, row.region, row.cluster_name, index)
                    }              
                })
            } else {
                groupArray = [0]
            }
            
            let groupId = []
            if(selectedUserDetails.groups && selectedUserDetails.groups.length) {
                selectedUserDetails.groups.forEach(app => {
                    groupId.push(app.group_id)
                })
            }
            setState(prevState => ({ ...prevState, groupId }))
        }
    }, [state.onLoad, pageMode, selectedUserDetails, getAccountRegion, getSlurmClusterDetailsFunction, getSlurmAccountDetails])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            if(!state.shortName || !state.userName) {
                hasError = true
            }
            params.shortName = state.shortName
            if(pageMode === 'Create') {
                if(!state.emailId || !validateEmail(state.emailId)) {
                    hasError = true
                }
                params.emailId = state.emailId
            }
            params.userName = state.userName
            params.userType = state.userType
            params.disabled = state.disabled ? true : false
            params.send_alert_notifications = state.send_alert_notifications ? true : false

            if(pageMode === 'Edit') {
                params.update_user_id = selectedUserDetails.userId
            }

            if(state.applicationId && state.applicationId.length) {
                let applications = []
                state.applicationId.forEach((app, appIndex) => {
                    let application = state.applications.find(e => e.application_id === app)
                    if (application && state["role_id_"+appIndex]) {
                        let dataRow = {}
                        dataRow.application_id = application.application_id
                        dataRow.application_name = application.application_name
                        let role = application.roles.find(role => role.role_id === state["role_id_"+appIndex]);
                        dataRow.role_id = role.role_id
                        dataRow.role_name = role.role_name
                        applications.push(dataRow)
                    } else if(!state["role_id_"+appIndex]) {
                        hasError = true
                    }

                })
                params.applications = applications
            }

            params.allow_all = state.allow_all ? true : false

            if(!state.allow_all) {
                let groups = []
                if(state.groupId && state.groupId.length) {
                    state.groups.forEach(grp => {
                        if(state.groupId.includes(grp.group_id)) {
                            groups.push(grp)
                        }
                    })
                }
                params.groups = groups
            }
            

            let grpAccount = []
            if(state.groupArray && state.groupArray.length) {
                state.groupArray.forEach(slmIndex => {
                    if(state["grpAccount_"+slmIndex] && state["grpRegion_"+slmIndex] && state["grpCluster_"+slmIndex] && state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length) {
                        let slurmRow = {}
                        slurmRow.provider = 'aws'
                        slurmRow.account_id = state["grpAccount_"+slmIndex]
                        slurmRow.region = state["grpRegion_"+slmIndex]
                        slurmRow.cluster_name = state["grpCluster_"+slmIndex]
                        slurmRow.account = state["selectedGroup_"+slmIndex]
                        grpAccount.push(slurmRow)
                    }
                })
            }
            if(grpAccount && grpAccount.length) {
                if(!_.isEqual(selectedUserDetails.account_list, grpAccount)) {
                    params.account_list = grpAccount
                }
            } else {
                params.account_list = []
            }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(createUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving User'
                            
                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'User Created Successfully'
                                
                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in updating User'
                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'User Updated Successfully'

                                refreshApi()
                            }

                            setTimeout(() => closeSidePanel(), setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedUserDetails])

    const removeSlurmAccount = (value, arrIndex) => {
		let filteredResult = state["selectedGroup_"+arrIndex].filter(e => e !== value)
		setState(prevState => ({ ...prevState, ["selectedGroup_"+arrIndex]: filteredResult }))
	}

    const addFilterArray = (field) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)
		}

		setState(prevState => ({ ...prevState, [field]: rowList }))
	}

	const removeFilterArray = (field, index, selItem) => {
		let rowList = state[field] ? state[field] : [0]
		rowList.splice(index, 1);
        if(field === 'groupArray') {
            if(!rowList.length) {
                rowList = [0]
            }
            setState(prevState => ({ ...prevState, ["grpAccount_"+selItem]: '', ["grpRegion_"+selItem]: '', ["grpCluster_"+selItem]: '', ["selectedGroup_"+selItem]: [] }))
        } else {
            setState(prevState => ({ ...prevState, ["moduleName_"+selItem]: '', ["selectedProvider_"+selItem]: '', ["selectedAccount_"+selItem]: [], ["selectedRegion_"+selItem]: [], ["selectedClusters_"+selItem]:  [] }))
        }
		setState(prevState => ({ ...prevState, [field]: rowList }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col w-4/5'>
                    <div className='bg-NeutralGray-200 px-8 py-8 text-black shadow-xl'>
                        <div className='flex justify-between'>
                            <div className='flex flex-col w-10/12'>
                                <div className='lg:w-5/12 w-full flex justify-start'>
                                    <span className='font-bold text-2xl'>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Create")} {pageMode === "View" ? 'Details' : 'User'}</span>
                                </div>
                                <p className='pt-2'>{selectedUserDetails.userName}</p>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={30} height={30} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Basic Info</p>
                            <div className='flex flex-wrap'>
                                <div className='lg:pr-4 w-1/2'>
                                    <Textbox
                                        label={"User Name"}
                                        type="text"
                                        selectedValue={state.userName ? state.userName : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, userName: value }))
                                        }}
                                        placeholder={'Enter user name'}
                                        manditory={true}
                                        hasError={state.hasError}
                                        disabled={pageMode === 'View'}
                                    />
                                </div>
                                <div className='lg:pl-4 pt-4 lg:pt-0 w-1/2'>
                                    <Textbox
                                        label={"AD Short Name"}
                                        type="text"
                                        selectedValue={state.shortName ? state.shortName : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, shortName: value }))
                                        }}
                                        placeholder={'Enter short name'}
                                        manditory={true}
                                        hasError={state.hasError}
                                        disabled={pageMode === 'View'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap mt-8'>
                                <div className='lg:pr-4 w-1/2'>
                                    <Textbox
                                        label={"Email ID"}
                                        type="text"
                                        selectedValue={state.emailId ? state.emailId : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, emailId: value }))
                                        }}
                                        placeholder={'Enter email id'}
                                        manditory={true}
                                        hasError={state.hasError}
                                        disabled={pageMode === 'View'}
                                    />
                                </div>
                                {/* <div className='flex flex-col justify-start w-1/2 pl-4'>
                                    <div className='w-full'>
                                        <SelectOption
                                            label={"Location"}
                                            // options={state.region}
                                            // selectedValues={state.applicationId ? state.applicationId : ''}
                                            // callbackMultiSelect={(value) => {
                                            //     setState(prevState => ({ ...prevState, applicationId: value }))
                                            // }}
                                            singleSelection={true}
                                            manditory={true}
                                            hasError={state.hasError}
                                            dropdownWidth={'w-full'}
                                            disabled={pageMode === 'Edit'}
                                        />
                                    </div>
                                </div> */}
                                <div className='flex flex-col justify-start w-1/2 pl-4'>
                                    <div className='w-full'>
                                        <SelectOption
                                            label={"Application name"}
                                            fields={["application_id", "application_name"]}
                                            options={state.applications}
                                            selectedValues={state.applicationId ? state.applicationId : []}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, applicationId: value }))
                                            }}
                                            singleSelection={false}
                                            manditory={true}
                                            hasError={state.hasError}
                                            dropdownWidth={'w-full'}
                                            disabled={pageMode === 'View'}
                                        />
                                    </div>
                                    {state.applicationId && state.applicationId.length ? 
                                        <div className='flex flex-wrap mt-2'>
                                            {state.applicationId.map((app, accIndex) => {
                                                return(
                                                    <span key={'acc1_'+accIndex} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].application_name ? state.applications.filter(e => e.application_id === app)[0].application_name : app}
                                                    <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={ () => setState(prevState => ({ ...prevState, applicationId: state.applicationId.filter(e => e !== app)})) } />
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    : null}
                                </div>
                            </div>

                            {state.applications && state.applicationId && state.applicationId.length ? 
                                state.applicationId.map((app, appIndex) => {
                                    return(
                                        <div key={'app_'+appIndex} className='border border-NeutralGray-600 bg-NeutralGray-100 rounded-2xl p-3 mt-3'>
                                            <p className='text-lg mb-0 cursor-pointer text-black font-semibold flex uppercase'>{state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].application_name ? state.applications.filter(e => e.application_id === app)[0].application_name : app}</p>
                                                <div className='flex flex-wrap pt-8'>
                                                    <div className='flex justify-start w-1/2 pr-4'>
                                                        <div className='w-full'>
                                                            <SelectOption
                                                                label={"Role Name"}
                                                                fields={["role_id", "role_name"]}
                                                                options={state.applications.filter(e => e.application_id === app)[0].roles}
                                                                selectedValues={state['role_id_'+appIndex] ? state['role_id_'+appIndex] : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, 
                                                                        ['role_id_'+appIndex]: value,
                                                                        ['menulist_'+appIndex]: getMenuList(app, value)
                                                                }))
                                                                }}
                                                                singleSelection={true}
                                                                manditory={true}
                                                                hasError={state.hasError}
                                                                dropdownWidth={'w-full'}
                                                                disabled={pageMode === 'View'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {state['role_id_'+appIndex] && state['menulist_'+appIndex] ?
                                                    <div className='mt-2'>
                                                        <p className='mb-0 cursor-pointer text-black font-semibold uppercase w-full'>Menu Access</p>
                                                        {state['menulist_'+appIndex].map(row => {
                                                            return(
                                                                <div key={'prd_'+appIndex} className={`flex flex-wrap ${appIndex ? "mt-1 pt-1 border-mediumDarkGray-top":  ""}`}>
                                                                    <div className="py-1 w-full">
                                                                        <p className={`flex bg-transparent justify-between mb-0 truncate font-bold text-black`}>
                                                                            {row.menu}
                                                                        </p>
                                                                    </div>
                                                                    {row.submenulist && row.submenulist.length ?
                                                                        <React.Fragment>
                                                                        {/* <p className='ml-3'>Sub Menus</p> */}
                                                                        <div className='ml-3 flex flex-wrap gap-2'>
                                                                            {row.submenulist.map((subMenu, smIndex) => {
                                                                                return(
                                                                                    <div className="py-1" key={subMenu.submenu}>
                                                                                        <p className={`flex bg-transparent mb-0 truncate text-black`}>
                                                                                            {subMenu.submenu} 
                                                                                            <span className='text-NeutralGray-600 ml-1'>({subMenu.link})</span>
                                                                                            <span className='mr-1 text-black'>{row.submenulist.length === smIndex+1 ? '' : ', '}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        </React.Fragment>
                                                                    : null}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                : null}
                                        </div>
                                    )
                                })
                            : null}

                            <div className='flex flex-wrap pt-8'>
                                <div className='flex flex-col justify-start w-1/2 pr-4'>
                                    <p className="text-base text-black self-center w-full">Status</p>
                                    <div className='pt-4 bg-white rounded-2xl'>
                                        <div className='flex'>
                                            <Checkbox
                                                label="Disable"
                                                name="disabled"
                                                selectedValue={state.disabled ? true : false}
                                                callback={() => {
                                                    setState(prevState => ({ ...prevState,  disabled: !state.disabled}))
                                                }}
                                                disabled={pageMode === 'View'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col justify-start w-1/2 pr-4'>
                                    <p className="text-base text-black self-center w-full">Alert Notification</p>
                                    <div className='pt-4 bg-white rounded-2xl'>
                                        <div className='flex'>
                                            <Checkbox
                                                label="Send alert notification"
                                                name="send_alert_notifications"
                                                selectedValue={state.send_alert_notifications ? true : false}
                                                callback={() => {
                                                    setState(prevState => ({ ...prevState,  send_alert_notifications: !state.send_alert_notifications}))
                                                }}
                                                disabled={pageMode === 'View'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`border-t flex flex-wrap border-NeutralGray-600 pt-8 mt-8`}>
                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Group Associations</p>
                                <div className='pl-4 pt-1 bg-white rounded-2xl'>
                                    <div className='flex'>
                                        <Checkbox
                                            label="Allow All"
                                            name="Allow All"
                                            selectedValue={state.allow_all ? true : false}
                                            callback={() => {
                                                setState(prevState => ({ ...prevState, allow_all: !state.allow_all}))
                                            }}
                                            disabled={pageMode === 'View'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {!state.allow_all ?
                                <div className='flex flex-wrap pt-2'>
                                    <div className='flex flex-col justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Select Group Associates"}
                                                fields={["group_id", "group_name"]}
                                                options={state.groups}
                                                selectedValues={state.groupId ? state.groupId : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, groupId: value }))
                                                }}
                                                singleSelection={false}
                                                // manditory={true}
                                                // hasError={state.hasError}
                                                dropdownWidth={'w-full'}
                                            />
                                        </div>
                                        {state.applicationId && state.applicationId.length ? 
                                            <div className='flex flex-wrap mt-2'>
                                                {state.applicationId.map((app, accIndex) => {
                                                    return(
                                                        <span key={'acc1_'+accIndex} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].application_name ? state.applications.filter(e => e.application_id === app)[0].application_name : app}
                                                        <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={ () => setState(prevState => ({ ...prevState, applicationId: state.applicationId.filter(e => e !== app)})) } />
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        : null}

                                    {state.groupId && state.groupId.length && state.groups ?
                                        state.groups.map((grp, grpIndex) => {
                                            return(
                                                state.groupId.includes(grp.group_id) ?
                                                    <div key={'grp_'+grpIndex} className='px-3 mt-3 bg-NeutralGray-100 rounded-2xl border border-NeutralGray-600'>
                                                        <div className='flex'>
                                                            <div className="py-1 lg:w-1/2 md:w-1/2 w-full px-3">
                                                                <p className="mb-0 text-black text-lg font-bold uppercase">
                                                                    {grp.group_name ? grp.group_name : <span>&nbsp;</span>}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {grp.associations && grp.associations.map((gAss, gAssIndex) => {
                                                            return(
                                                                <div key={'gAss_'+gAssIndex} className='px-3 mt-3'>
                                                                    {Object.entries(gAss).map(([key, value], objIndex) => {
                                                                        return(
                                                                            key !== 'project_tags' ?
                                                                                <div key={'pbj_'+objIndex} className={`${objIndex ? 'mt-2' : ''}`}>
                                                                                    {key === 'tags' ?
                                                                                        value && value.length ?
                                                                                            <div className='flex mt-2 pt-2'>
                                                                                                <div className="py-1 w-full">
                                                                                                    <p className="b-block text-base font-semibold py-2">{removeUnderScore(key)}</p>
                                                                                                    <p className="mb-0 text-black">
                                                                                                        {value.map((tag, tagIndex) => {
                                                                                                            return(
                                                                                                                <span key={'tag_'+tagIndex} className="flex w-fit border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
                                                                                                                    {tag.key+' : '}<span className='font-medium'>{tag.value}</span></span>
                                                                                                            )
                                                                                                        })}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : null
                                                                                    : Array.isArray(value) ?
                                                                                        value.every(item => typeof item === 'string') ?
                                                                                            <React.Fragment>
                                                                                            <p className="b-block text-base font-semibold py-2">{removeUnderScore(key)}</p>
                                                                                            {value.map((arr, arrIndex) => {
                                                                                                return(
                                                                                                    <span key={'arr_'+arrIndex} className="flex w-fit border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{arr}</span>
                                                                                                )
                                                                                            })}
                                                                                            </React.Fragment>
                                                                                        : value && value.length ?
                                                                                            <div className='flex mt-2 pt-2'>
                                                                                                <div className="py-1 w-100">
                                                                                                    <p className="b-block text-base font-semibold py-2">{removeUnderScore(key)}</p>
                                                                                                    <p className="mb-0 text-black">
                                                                                                        {value.map((tag, tagIndex) => {
                                                                                                            return(
                                                                                                                <span key={'tag_'+tagIndex} className="flex w-fit border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{tag.key+' : '+tag.value}</span>
                                                                                                            )
                                                                                                        })}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        : null
                                                                                    : typeof value === 'string' ?
                                                                                        <p className="flex w-fit border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className=' mr-1'>{removeUnderScore(key)} :</span>{value}</p>
                                                                                    : typeof value === 'boolean' ?
                                                                                        <p className="flex w-fit border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className=' mr-1'>{removeUnderScore(key)} :</span>{value ? 'True' : 'False'}</p>
                                                                                    : null}
                                                                                </div>
                                                                            : null

                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                : null
                                            )
                                        })
                                    : null}
                                    </div>
                                </div>
                            : null}

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                <p className='font-bold text-NeutralGray-800 text-[22px] uppercase'>HPC CLuster Group Associations</p>
                                {state.groupArray.map((slmIndex, sIndex) => {
                                    return(
                                    <div key={'prd_'+slmIndex}>
                                        <div className='border border-NeutralGray-600 bg-NeutralGray-100 rounded-2xl p-3 mt-4'>
                                        <div className='flex flex-wrap'>
                                            <div className='flex justify-start w-1/3 pr-4'>
                                                <SelectOption
                                                    label={"Account"}
                                                    fields={["account_id", "account_name"]}
                                                    options={state.providerAccounts}
                                                    selectedValues={state["grpAccount_"+slmIndex] ? state["grpAccount_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ["grpAccount_"+slmIndex]: value }))
                                                    }}
                                                    singleSelection={true}
                                                    dropdownWidth={'w-full'}
                                                    disabled={pageMode === 'View'}
                                                />
                                            </div>
                                            <div className='flex justify-start w-1/3 pl-4'>
                                                <SelectOption
                                                    label={"Region"}
                                                    fields={["region", "name"]}
                                                    options={state.providerRegions}
                                                    selectedValues={state["grpRegion_"+slmIndex] ? state["grpRegion_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpRegion_"+slmIndex]: value,
                                                            fetchingCluster: true
                                                        }))
                                                        getSlurmClusterDetailsFunction('aws', state["grpAccount_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    dropdownWidth={'w-full'}
                                                    disabled={pageMode === 'View'}
                                                />
                                            </div>
                                            <div className='flex justify-start w-1/3 pl-4'>
                                                <SelectOption
                                                    label={"Cluster"}
                                                    // fields={["region", "name"]}
                                                    options={state['groupClustersOptions_'+slmIndex]}
                                                    selectedValues={state["grpCluster_"+slmIndex] ? state["grpCluster_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpCluster_"+slmIndex]: value,
                                                            fetchingSlurmAccount: true
                                                        }))
                                                        getSlurmAccountDetails('aws', state["grpAccount_"+slmIndex], state["grpRegion_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    dropdownWidth={'w-full'}
                                                    disabled={pageMode === 'View'}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col pt-8'>
                                            <div className='flex justify-between w-full pr-4'>
                                                <SelectOption
                                                    label={"Group"}
                                                    // fields={["account_id", "account_name"]}
                                                    fields={["account", "account"]}
                                                    options={state["groupList_"+slmIndex]}
                                                    selectedValues={state["selectedGroup_"+slmIndex] ? state["selectedGroup_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ["selectedGroup_"+slmIndex]: value }))
                                                    }}
                                                    singleSelection={false}
                                                    dropdownWidth={'w-1/2'}
                                                    disabled={pageMode === 'View'}
                                                />
                                            {pageMode !== 'View' && state.groupArray.length > 1 ?
                                                    <span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-end cursor-pointer text-base`} onClick={() => removeFilterArray('groupArray', sIndex, slmIndex)}>Remove</span>
                                            : null}
                                            </div>
                                            {state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ?
                                                <div className='flex flex-wrap mt-2'>
                                                    {state["selectedGroup_"+slmIndex].filter(e => e.value !== "All").map((reg, regIndex) => {
                                                        return(
                                                            <span key={'slurmReg_'+regIndex} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{reg}
                                                                {!state.editMode ?
                                                                    <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={ () => removeSlurmAccount(reg, slmIndex) } />
                                                                : null}
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            : null}

                                        </div>
                                        </div>
                                        {pageMode !== 'View' && state.groupArray.length === sIndex+1 ?
                                            <div className='flex justify-center'>
                                                <p className='mt-6 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() =>  addFilterArray('groupArray')}>+ Add Association</p>
                                            </div>
                                        : null}
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                            <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
                                <div className={'flex justify-center'}>
                                    <Button
                                        classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', width: 'min-w-36', text: 'text-white text-base', others: 'cursor-pointer'}}
                                        label={'Cancel'}
                                        callback={() => closeSidePanel()}
                                    />
                                    {state.saveLoading ?
                                        <Button 
                                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', width: 'min-w-36', text: 'text-white text-base' }}
                                            loading={true}
                                            label={pageMode === 'Edit' ? 'Updating' : 'Saving'}
                                        />
                                    :  pageMode !== 'View' ? 
                                        <Button
                                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', width: 'min-w-36', text: 'text-white text-base', others: 'cursor-pointer'}}
                                            label={pageMode === 'Edit' ? 'Update' : 'Save'}
                                            callback={() => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}
                                        />
                                    : null}
                                </div>
                            </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateUserPanel