import React, { useCallback, useEffect, useState } from 'react';
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { useSelector, useDispatch } from 'react-redux';
import { onlyNumeric } from '../../../../utils/utility';
import Textbox from '../../../common/Form/Textbox';
import Checkbox from '../../../common/Form/Checkbox';
import SelectOption from '../../../common/Form/SelectOption';

const LoginNodeSection = (props) => {
  const [state, setState] = useState({});

  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const handleLoginNodeInput = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let loginNodeConfiguration = obj.login_node ? obj.login_node : [{arrayIndex : 0}];

    if (label !== "enable_login_node"){
        loginNodeConfiguration[0][label] = type === "integer" ? parseInt(value) : value;
    }

    if (label === "enable_login_node" && value === false) {
        loginNodeConfiguration =  [{arrayIndex : 0}];
        setState((prevState) => ({ ...prevState, instance_type: [], instance_count: ''}));
    } else {
        setState((prevState) => ({ ...prevState, [label]: value }));
    }

    obj["login_node"] = loginNodeConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

  };
  const callPageVist = useCallback(() => {
    let loginNodeConfiguration = createUpdateCluster && createUpdateCluster.login_node ? createUpdateCluster.login_node : [{}];

    Object.entries(loginNodeConfiguration[0]).forEach(([key, value]) => {
        if (key === "instance_type" || key === "instance_count"){
        setState((prevState) => ({ ...prevState, [key]: value, enable_login_node: true}));
        }
    });

  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);
  
  const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };
  
    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
                
            <div className='flex flex-wrap'>
                {/* <p className="text-base font-medium text-black self-center w-full">Encrypt Root Volume</p> */}
                  <div className='flex pt-3 w-1/2'>
                        <Checkbox
                          label="Enable login node"
                          name="efa_enabled"
                          selectedValue={state.enable_login_node ? true : false}
                          callback={() => {
                            setState(prevState => ({ ...prevState, enable_login_node: !state.enable_login_node}))
                          }}
                        />
                  </div>
            </div>
            {state.enable_login_node ?
                <div className='flex flex-wrap justify-between pt-4'>
                    <div className='flex w-full'>
                        <div className='flex flex-col justify-start w-1/2 pr-4'>
                            <SelectOption
                                label={"Instance Type"}
                                fields={["instance_type", "instance_type"]}
                                options={propsClusterFilters && propsClusterFilters.instance_type ? propsClusterFilters.instance_type : []}
                                selectedValues={state.instance_type ? state.instance_type : ''}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState,  instance_type: value }))
                                    handleLoginNodeInput('instance_type', value)
                                }}
                                singleSelection={true}
                                manditory={true}
                                hasError={props.hasError}
                                dropdownWidth={'w-full'}
                            />
                        </div>
                        <div className='lg:pr-4 w-1/2 pl-4'>
                            <Textbox
                                label={"Instance count"}
                                type="number"
                                selectedValue={state.instance_count ? state.instance_count : ''}
                                callback={(value) => {
                                    handleLoginNodeInput('instance_count', onlyNumeric(value), 'integer')
                                }}
                                placeholder={'Enter instance count'}
                                manditory={true}
                                hasError={props.hasError}
                            />
                        </div>
                    </div>
                </div>
            : null}
        </div>
    )
}


export default LoginNodeSection