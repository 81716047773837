/*
 * @exports
 * @file AlertAction.jsx
 * @author Prakash
*/
import ApiCalls from '../../components/ApiCalls'

import {
	LIST_ALERTS,
	GET_ALERTS_DAILY_TREND,
	LIST_ALERT_TEMPLATES,
	LIST_ALERT_RULES,
	CREATE_ALERT_RULE,
	DELETE_ALERT_RULE,
	UPDATE_ALERT_RULE
} from '../../config'

/*
 * Action to list-alerts
 * @param {Object} body
*/
export const listAlerts = (params) => {
	return async () => {
		try {
			const url = LIST_ALERTS;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to get-alerts-daily-trend
 * @param {Object} body
*/
export const getAlertsDailyTrend = (params) => {
	return async () => {
		try {
			const url = GET_ALERTS_DAILY_TREND;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to list-alert-templates
 * @param {Object} body
*/
export const listAlertTemplates = (params) => {
	return async () => {
		try {
			const url = LIST_ALERT_TEMPLATES;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to list-alert-rules
 * @param {Object} body
*/
export const listAlertRules = (params) => {
	return async () => {
		try {
			const url = LIST_ALERT_RULES;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to create-alert-rule
 * @param {Object} body
*/
export const createAlertRule = (params) => {
	return async () => {
		try {
			const url = CREATE_ALERT_RULE;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			console.log("error", error)
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to update-alert-rule
 * @param {Object} body
*/
export const updateAlertRule = (params) => {
	return async () => {
		try {
			const url = UPDATE_ALERT_RULE;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			console.log("error", error)
			return error?.response?.data || error;
		}
	};
}

/*
 * Action to delete-alert-rule
 * @param {Object} body
*/
export const deleteAlertRule = (params) => {
	return async () => {
		try {
			const url = DELETE_ALERT_RULE;
			const response = await ApiCalls.post(url, params)
			return response.data;
		} catch (error) {
			console.log("error", error)
			return error?.response?.data || error;
		}
	};
}