/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * @exports
 * @file ClusterDetails.js
 * @author Prakash
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listClusterDetails } from '../../../actions/Collider/ClusterAction'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { getCommonInformationMessage, getPaginationShowingDetails } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import ExpandedSideBarDetails from './ExpandedSideBarDetails';

const ClusterDetails = (props) => {
    const [isNavigating, setIsNavigating] = useState(false); // To prevent double-clicks
    const [state, setState] = useState({
        showLoading: true,

        currentPage: 1,
        perPage: 5,

        clusterDetails: [],
        selectedClusterDetails: [],
        clusterheaders: [],
        callSearch: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showLoading: true }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.reports = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            params.size = state.perPage

            dispatch(listClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        
                        setState(prevState => ({ ...prevState, listResponse: response, clusterDetails: results, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false, callTrenSection: true, totalRecords: response.total }));

                        props.callBack()
                    }
                })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.token, state.listResponse])

    useEffect(() => {
        if(state.callConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callConcurrentCpuSection: false }))

            state.clusterDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+index]: graphData, ['selected_Period_'+index]: 'daily' }))
            })
        }
    }, [state.callConcurrentCpuSection, state.clusterDetails])

    useEffect(() => {
        if(state.callTrenSection) {
            
            setState(prevState => ({ ...prevState, callTrenSection: false }))

            state.clusterDetails.forEach((item, index) => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        graphData.tooltipLabel = item.title[trKey]
                        setState(prevState => ({ ...prevState, [trKey+'_'+index]: graphData }))
                    })
                }
            })
        }
    }, [state.callTrenSection, state.clusterDetails])

    const handleNavigation = (direction) => {
        if (isNavigating) return; // Prevent further clicks
    
        setIsNavigating(true); // Block further navigation
        const newPage = direction === 'previous' ? state.currentPage - 1 : state.currentPage + 1;
    
        // Update the state and simulate API call
        setState((prevState) => ({ ...prevState, showLoading: true, currentPage: newPage, token: direction, callSearch: true }));
    
        // Simulate async operation or API call
        setTimeout(() => { setIsNavigating(false); // Re-enable navigation
          setState((prevState) => ({ ...prevState, showLoading: false })); // Remove loading
        }, 1000); // Simulated delay
    };

    return (
        <div className='bg-transparent'>
            <div className={`${(state.showLoading || state.downloading) ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            
            {state.showEpandedDetails ?                                       
                <ExpandedSideBarDetails
                    selectedType={props.selectedType}
                    selectedItem={state.selectedDetails}
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    selectedClusters={props.selectedClusters}
                    selectedUser={props.selectedUser}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    detailsFormat={state.detailsFormat}
                    closeSidePanel={() => {
                        // toggleScroll(false)
                        setState(prevState => ({ ...prevState, showEpandedDetails: false }))
                    }}
                />
            : null}
            <div className='overflow-auto'>
                <div className='flex flex-wrap justify-between mb-1'>
                    <p className='mb-0 self-center text-black lg:w-1/2 w-full'>
                        Showing 
                        <span className='mx-1'>{getPaginationShowingDetails(state.clusterDetails && state.clusterDetails.length, state.totalRecords, state.currentPage, state.perPage)}</span> 
                        out of total {state.totalRecords} cluster(s)
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black lg:w-1/2 w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.previous_token) {
                                        handleNavigation('previous');
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.next_token) {
                                        handleNavigation('next');
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null}
                </div>
                {state.clusterDetails && state.clusterDetails.length ?
                    state.clusterDetails.map((item, index) => {
                        return(
                            <div className={`bg-white rounded-2xl mb-4`} key={"row_" + index}>
                                <div className='flex flex-wrap'>
                                    <div className="text-base w-full bg-PrimaryTintsPurple-600 rounded-l-md px-3 py-4 lg:w-1/4 md:w-1/2 flex flex-col justify-between">
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Cluster</p>
                                                <p className="mb-0 text-black">
                                                {item.cluster_name ? item.cluster_name : ''}
                                                {item.cluster_version ?
                                                    <span className="mb-0 mr-2 text-lg text-black">
                                                        (v {item.cluster_version})
                                                    </span>
                                                : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Asset</p>
                                                <p className="mb-0 text-black">{item.asset_name ? item.asset_name : ''}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Resources</p>
                                                <p className="mb-0 text-black">
                                                {item.account_id ? <span>{' ' + item.account_id}</span> : null}
                                                {item.region ? <span>{' ' + item.region}</span> : null}
                                                {item.resource_type ? <span>{' ' + item.resource_type}</span> : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {state['showLoading_'+index] ?
                                        <div className="w-full lg:w-3/4 flex justify-center self-center py-4">
                                            <LoadingCircle />
                                        </div>
                                    :
                                        <div className="w-full lg:w-3/4 flex flex-wrap p-3">
                                            <div className='flex w-full justify-end'>
                                                <p className='mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Cluster', showSidePanel: true }))}>View Details</p>
                                                <span className='flex justify-center'>
                                                    <Icon icon="basil:expand-outline" width={24} height={24} className={`text-white bg-DeepPurpleTints-600 h-8 w-8 rounded-full cursor-pointer self-center ml-3`}
                                                        onClick={() => {
                                                            setState(prevState => ({ ...prevState, selectedDetails: item, showEpandedDetails: true  }))
                                                        }} 
                                                    />
                                                </span>
                                            </div>
                                            {item.trend && Object.entries(item.trend).length ? 
                                                item.order && item.order.slice(0,4).map((key, keyIndex) => {
                                                    return(
                                                        <React.Fragment key={'tr_'+keyIndex}>
                                                        {state[key+'_'+index] && Object.entries(state[key+'_'+index]).length ?
                                                            <div className="w-full lg:w-1/4 md:w-1/2 pr-3 cursor-pointer">
                                                                <p className='text-black pl-4 pt-3 text-sm font-semibold'>{item.title && item.title[key] ? item.title[key] : key}</p>
                                                                <div className='flex'>
                                                                    <div className='self-center w-full'>
                                                                        <ApexLineChartSpendBoard
                                                                            graphData={state[key+'_'+index]}
                                                                            sparkline={false}
                                                                            yaxis={true}
                                                                            yaxisLabel={true}
                                                                            xaxis={true}
                                                                            xaxisFormat={'datetime'}
                                                                            // xaxisTitle={item.title && item.title[key] ? item.title[key] : key}
                                                                            xaxisLabel={true}
                                                                            axisLabelColor={'#666666'}
                                                                            paddingLeft={10}
                                                                            legend={false}
                                                                            stacked={false}
                                                                            height={200}
                                                                            customHpcDashboardTooltip={true}
                                                                            className={'transparentTooltip'}
                                                                            gradient={true}
                                                                            gradientColor={['#FDA272', '#FFF3ED']}
                                                                            colors={['#FC6D22']}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center m-4 text-black'>
                        {getCommonInformationMessage('cluster')}
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='flex justify-end text-black w-full'>
                        <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                        <div className="flex text-sm">
                            <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 rounded-md ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                if(state.listResponse && state.listResponse.previous_token) {
                                    setState(prevState => ({ ...prevState, currentPage: state.currentPage-1, token: 'previous', callSearch: true }))
                                }
                            }}/></span> 
                            <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                if(state.listResponse && state.listResponse.next_token) {
                                    setState(prevState => ({ ...prevState, currentPage: state.currentPage+1, token: 'next', callSearch: true }))
                                }
                            }}/></span>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default ClusterDetails