import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

const SimplePagination = (props) => {
    const [state, setState] = useState({
        currentPage: 1,
        startRecord: 0
    })

    useEffect(() => {
        setState(prevState => ({ 
            ...prevState,            
            perPage: props.perPage,
            data: props.data,
            totalPages: Math.ceil(props.data.length / props.perPage)
        }))
    }, [props.perPage, props.data])

    const navigatePage = (action) => {
		let startRecord = state.startRecord
        let currentPage = state.currentPage
		if(action === 'next' && currentPage !== state.totalPages) {
			startRecord = startRecord + state.perPage;
            currentPage = currentPage + 1
		} else if(action === 'previous') {
            currentPage = currentPage - 1
			startRecord = startRecord - state.perPage;
		} else if(action === 'start') {
            currentPage = 1
			startRecord = 0;
		} else if(action === 'end') {
            currentPage = state.totalPages
			startRecord = (state.totalPages - 1) * state.perPage;
		}
		setState(prevState => ({ ...prevState, currentPage, startRecord }))
        props.navigate(action, startRecord)

    }

    
    return(
        <div className='flex justify-end text-black w-full'>
            <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
            <div className="flex text-sm">
                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage > 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-400'}`} onClick={() => navigatePage('previous')}/></span> 
                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage < state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-400'}`} onClick={() => navigatePage('next')}/></span>
            </div>
        </div>
    )
}

export default SimplePagination