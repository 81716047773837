/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/06/2023
 * @copyright © 2021 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCostPropsDetails, getCostAnomalyDailyTrend, getCostTopAnomalies } from '../../../actions/cost/CostAction'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, addHours, subHours, twoDateDiffrence } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import AnomalyDashboard from './AnomalyDashboard'
import ApexBarChart from '../../common/charts/ApexBarChart'
import FilterSection from '../../common/FiltersSection';
import { HOURLY_DURATION } from '../../../utils/constants';

let timer = null;

const LandingPage = () => {
    
    const clickOutside = useRef() 
    const prevCostCommonPageFilter = useRef();

    const[state, setState] = useState({
        showLoading: true,
        topSectionFilters: ['provider', 'account', 'duration'],
        callOnSearch: true
    })
    
    const dispatch = useDispatch(); // Create a dispatch function
    
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            sliderStartDate: filter.datePickerStartDate,
            sliderEndDate: filter.datePickerEndDate,
            callOnSearch: true,
            showLoading: true,
            changesInFilter: true,
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: '', selectedAccount: [], selectedDuration: '+3d', changesInFilter: false, datePickerStartDate: '',  datePickerEndDate: '', callOnSearch: true }));
    }

    useEffect(() => {
        if(state.callOnSearch) {
            prevCostCommonPageFilter.current = ""    
            setState(
                prevState => ({
                    ...prevState,
                    callOnSearch: false,
                })
            );
        }
    }, [state.callOnSearch, state.selectedDuration])
    
    // Call onSearch when changesInFilter is updated
    useEffect(() => {
        if (state.changesInFilter) {
            setState(prevState => ({
                ...prevState,
                brushStartTime: '',
                brushEndTime: '',
                alertSeries: [], alertOptions: {},
                eventSeries: [], eventOptions: {},
                errorSeries: [], errorOptions: {},
                changesInFilter: false,
                showLoading: true
            }))
            let obj = {}
            obj.start_time = state.sliderStartDate
            obj.end_time = state.sliderEndDate
            dispatch(setCostPropsDetails('costCommonPageFilter', obj))

            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
        }
    }, [state.changesInFilter, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedDuration, state.sliderEndDate, state.sliderStartDate]);

    useEffect(() => {
        if(state.brushMoved && state.brushSelectionValue) {
            clearTimeout(timer)

            let xaxisStartTime = momentDateGivenFormat(state.brushSelectionValue.min, 'YYYY-MM-DD HH:mm:ss');
            let xaxisEndTime = momentDateGivenFormat(state.brushSelectionValue.max, 'YYYY-MM-DD HH:mm:ss');
            prevCostCommonPageFilter.current = state.brushSelectionValue;
    
            if(prevCostCommonPageFilter && prevCostCommonPageFilter !== state.brushSelectionValue) {
                // if(state.brushStartTime !== xaxisStartTime && state.brushEndTime !== xaxisEndTime) {
                timer = setTimeout(() => {
                    setState(prevState => ({ ...prevState, brushStartTime: xaxisStartTime, brushEndTime: xaxisEndTime }));

                    if(state.brushStartTime && state.brushEndTime) {
                        let obj = {}
                        obj.brushStartTime = xaxisStartTime
                        obj.brushEndTime = xaxisEndTime
                        dispatch(setCostPropsDetails('costCommonPageFilter', obj))
                    }
                }, 1000)
            }

            setState(prevState => ({ ...prevState, brushMoved: false }))
        }
    }, [dispatch, state.brushSelectionValue, state.brushStartTime, state.brushEndTime, state.brushMoved])

    // const brushSelection = useCallback((xaxis) => {
    //     clearTimeout(timer)
    //     let xaxisStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss');
    //     let xaxisEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss');
    //     prevCostCommonPageFilter.current = xaxis;

    //     if(state.brushStartTime) {
    //         if(state.brushStartTime !== xaxisStartTime && state.brushEndTime !== xaxisEndTime) {
    //             timer = setTimeout(() => costCommonPageFilterProps(xaxisStartTime, xaxisEndTime, true), 1000)
    //         }
    //         // timer = setTimeout(() => setState(prevState => ({ ...prevState, brushStartTime: xaxisStartTime, brushEndTime: xaxisEndTime })), 1500);
    //     } else if(!state.brushStartTime) {
    //         costCommonPageFilterProps(state.sliderStartDate, state.sliderEndDate, false)
    //     }

        
    // }, [state.brushStartTime, state.brushEndTime, state.sliderStartDate, state.sliderEndDate, costCommonPageFilterProps])

    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
            params.duration_aggregate_by = 'seconds'
            
            dispatch(getCostAnomalyDailyTrend(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, anomalyDailyTrend: response && response.results && response.results.length ? response.results[0] : {}, trendSpinner: false }))
                    }
                })
        }
    }, [dispatch, state.sliderEndDate, state.sliderStartDate])

    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let brushSeries = [];
            let brushRow = {};
            brushRow.data = [
                { x: momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0 },
                { x: momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0 }
            ];
            brushSeries.push(brushRow);
        
            let endTIme = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss');
            let time = endTIme;
            let brushHours = 1;
            let diff = twoDateDiffrence(state.sliderEndDate, state.sliderStartDate);
        
            if (diff > 1 && diff <= 3) {
                brushHours = 6;
            } else if (diff > 3 && diff <= 7) {
                brushHours = 24; //3
            } else if (diff > 7 && diff <= 14) {
                brushHours = 36; //6
            } else if (diff > 14 && diff <= 30) {
                brushHours = 48; //12
            } else if (diff > 30) {
                brushHours = 96; //24
            }
        
            let brushStartTime = subHours(time, brushHours);
            let brushEndTime = time;
        
            let optionsBrush = {
                chart: {
                    redrawOnParentResize: false,
                    background: '#D9D9D6',
                    id: 'chart1',
                    type: 'line',
                    sparkline: {
                        enabled: true
                    },
                    zoom: {
                        enabled: false
                    },
                    brush: {
                        target: '',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: '#7551c2', //info
                            opacity: 1
                        },
                        xaxis: {
                            min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        }
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            // brushSelection(xaxis);
                            setState(prevState => ({ ...prevState, brushSelectionValue: xaxis, brushMoved: true }))
                        },
                    }
                },
                markers: {
                    size: 0
                },
                colors: ['#D9D9D6'],
                fill: {
                    type: 'solid',
                    color: '#000',
                    opacity: 1
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    show: false,
                    tickAmount: 2
                },
                annotations: {
                    yaxis: [{
                        y: 1,
                        borderColor: '#000',
                        label: {
                        borderColor: '#00E396',
                        style: {
                            color: '#fff',
                            background: '#00E396'
                        },
                        text: ''
                        }
                    }]
                }
            };
        
            setState(prevState => ({ ...prevState, brushSeries, optionsBrush }));
    
            // getCostAnomalyDailyTrendFunction()
        }
    }, [state.sliderStartDate, state.sliderEndDate]);

    useEffect(() => {
        let trendGraphData = {}
        // let colors = ['#FF0000']
        let colors = ['#8e3a80']
        let anomalySeries = []
        // let columnWidth = ''
        let anomalyCount = 0
        let graphDetails = state.anomalyDailyTrend
        if(graphDetails && graphDetails.data && graphDetails.data) {
            trendGraphData.data = graphDetails.data
            trendGraphData.labels = graphDetails.dates

            
            anomalyCount = graphDetails.data.reduce((a, b) => a + b, 0)
            
            let dataArray = []
            let graphLabels = graphDetails.dates

            graphDetails.data.forEach((val, index) => {
                let dataRow = {}
                dataRow = {}
                dataRow.x =  momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataRow.y = val
                dataArray.push(dataRow)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0 
                dataArray.push(dataRow) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0
                dataArray.push(dataRow) //to initialize the end time in the graph
            }
            let seriesRow = {}
            seriesRow.name = ''
            seriesRow.data = dataArray

            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph
            // trendGraphData.data = [0, 0]
            // trendGraphData.labels = [state.sliderStartDate, state.sliderEndDate]

            let seriesRow = {}
            seriesRow.name = 'No Data'
            seriesRow.data = dataArray
            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        // let barOptions = {                
        //     // rangeBarOverlap: false,
        //     // rangeBarGroupRows: false,
        //     columnWidth: '100%'
        // }

        // if(columnWidth !== '') {
        //     barOptions.columnWidth = '0%'
        // }
        
        let anomalyOptions = {
            chart: {
                id:'',
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '100%',   
                }
                // bar: {
                //     horizontal: false,
                //     columnWidth: columnWidth,
                //     distributed: false,
                //     rangeBarOverlap: false,
                //     rangeBarGroupRows: false,
                // },       
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,
            },
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Anomalies </span> ' + val + ' </span></div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        setState(prevState => ({ ...prevState, anomalySeries, anomalyOptions, anomalyCount, trendGraphData }))

    }, [state.anomalyDailyTrend, state.sliderEndDate, state.sliderStartDate])
    
    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let params = {
                'start_time': momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'top': 5,            
                'aggregate_by': [['asset_name', 'anomaly_name']],
            }

            // let response = [
            //     {
            //         'data': {
            //             'asset_name': {},
            //             'anomaly_name': {
            //                 '573921696245:us-east-2:S3:GlacierStorage': 38,
            //                 '573921696245:us-east-2:SystemsManager:AdvancedParameterStore': 36,
            //                 '573921696245:us-east-2:KMS:GenerateDataKey': 35,
            //                 '573921696245:us-east-1': 27,
            //                 '573921696245:us-east-1:EC2': 26
            //             }
            //         }
            //     }
            // ]

            // setState(prevState => ({ ...prevState, topCostAnomalies: response.length && response[0].data ? response[0].data : {}  }))

            dispatch(getCostTopAnomalies(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, topCostAnomalies: response.length && response[0].data ? response[0].data : {}  }))
                    }
                })
        }
    }, [dispatch, state.sliderEndDate, state.sliderStartDate])

    useEffect(() => {
        if(state.topCostAnomalies && state.topCostAnomalies.asset_name) {
            let list = state.topCostAnomalies.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            setState(prevState => ({ ...prevState, topAssets: topStatusData }))
        }
        
        if(state.topCostAnomalies && state.topCostAnomalies.anomaly_name) {
            let list = state.topCostAnomalies.anomaly_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            setState(prevState => ({ ...prevState, topAnomalies: topStatusData }))
        }        
    }, [state.topCostAnomalies])


    return (
        <div className='px-6'>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="w-full text-white text-[28px] text-semibold">Anomalies</p>
                        <p className="text-white text-base">Unified view of cost Anomlies in your Cloud</p>
                    </div>
                    
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={()=>{}}
                            onReset={onReset}
                            durationOptions={HOURLY_DURATION}
                            selectedDuration={'+3d'}
                        />
                    </div>
                </div>
            </div>
            <div className='overflow-auto'>
                <div className='bg-white shadow p-6 rounded-2xl border border-DeepPurpleTints-600'>
                    <div className='flex flex-col justify-between pb-4'>
                        <p className='text-base text-lightGray'>Showing data for 
                            <span className='text-info ml-1'>
                                {state.brushTime ?
                                    state.brushStartTime ? 
                                        momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                    : ''
                                : 
                                    state.sliderStartDate ? 
                                        momentDateGivenFormat(state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.sliderEndDate, 'DD MMM YYYY HH:mm') 
                                    : ''
                                }
                            </span>
                            <span className='ml-2'>(local time)</span>
                        </p>
                        <div className={`${!state.minimizeGraphSection && state.anomalySeries && state.anomalySeries.length ? '-mt-4' : 'mt-4'}`}> 
                            {state.trendSpinner ?
                                <LoadingCircle/>
                                // <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                                //     <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                //     <path d="M4 12a8 8 0 0112-6.9" />
                                // </svg>
                            : 
                                <div className='flex flex-wrap mt-8'>
                                    <div className='lg:w-4/6 w-full pr-4'>
                                    <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-500 p-5 shadow rounded-2xl h-full'>
                                        <div className='w-11/12'>
                                            {state.trendGraphData && Object.entries(state.trendGraphData).length ?
                                                <ApexBarChart
                                                    graphData={state.trendGraphData}
                                                    sparkline={false}
                                                    yaxis={false}
                                                    yaxisLabel={false}
                                                    xaxis={true}
                                                    xaxisFormat={'dateWithtime'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#495057'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={false}
                                                    stacked={false}
                                                    height={200}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    columnWidth={'45%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarColors={['#333947']}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {false}
                                                    colors={['#8e3a80']}
                                                    showBackgroundBarColors={true}
                                                    barEndShape={'flat'}
                                                    backgroundBarShape={'flat'}
                                                />
                                            : null}
                                        </div>
                                        <div className='w-1/12 self-center justify-center'>
                                            <p className='text-black f14 mb-1'>{state.anomalyCount && state.anomalyCount}</p>
                                            <p className='text-purple-2 mb-0'>Anomalies </p>
                                        </div>
                                        <div className='w-full'>
                                            <div className='flex w-full'>
                                                {state.brushSeries && state.brushSeries.length && state.optionsBrush && Object.entries(state.optionsBrush).length ?
                                                    <React.Fragment>
                                                    <div className='removeSvgPoint w-10/12 jsutify-end pr-2'>
                                                        <Chart options={state.optionsBrush} series={state.brushSeries} type='line' height={8} />
                                                        <div className='flex justify-between mt-3 text-info'>
                                                            <small className='mb-0 self-center f10'>{momentDateGivenFormat(state.sliderStartDate, 'DD MMM HH:mm')}</small>
                                                            <small className='mb-0 self-center f10'>{momentDateGivenFormat(state.sliderEndDate, 'DD MMM HH:mm')}</small>
                                                        </div>
                                                    </div>
                                                    <small className='mb-0 self-start -mt-1 w-2/10 text-info'>{momentDateGivenFormat(state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(state.brushEndTime, 'DD MMM HH:mm')}</small>
                                                    </React.Fragment>
                                                : null}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='lg:w-2/6 w-full'>
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 shadow rounded-2xl h-full flex flex-col'>
                                            <p className={`text-base font-semibold text-black w-full`}>Top 5 Anomalies</p>
                                            {state.topAnomalies && Object.entries(state.topAnomalies).length ?
                                                <div className='w-full'> 
                                                    <ApexBarChart
                                                        graphData={state.topAnomalies}
                                                        sparkline={true}
                                                        yaxis={false}
                                                        yaxisLabel={false}
                                                        xaxis={false}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#495057'}
                                                        paddingLeft={-10}
                                                        legend={false}
                                                        stacked={false}
                                                        height={200}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'25%'}
                                                        gradient={true}
                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                    />
                                                </div>
                                            :
                                                <div className="flex-grow flex justify-center items-center self-center mt-[55px]">
                                                    <div className="w-full text-center text-base">
                                                    No job trend data for this period.
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='hidden'>
                            <div className='flex w-full'>
                                {state.brushSeries && state.brushSeries.length && state.optionsBrush && Object.entries(state.optionsBrush).length ?
                                    <React.Fragment>
                                    <div className='removeSvgPoint w-4/5 jsutify-end pr-2'>
                                        <Chart options={state.optionsBrush} series={state.brushSeries} type='line' height={8} />
                                        <div className='flex justify-between mt-3 text-info'>
                                            <small className='mb-0 self-center f10'>{momentDateGivenFormat(state.sliderStartDate, 'DD MMM HH:mm')}</small>
                                            <small className='mb-0 self-center f10'>{momentDateGivenFormat(state.sliderEndDate, 'DD MMM HH:mm')}</small>
                                        </div>
                                    </div>
                                    <small className='mb-0 self-start -mt-1 w-1/5 text-info'>{momentDateGivenFormat(state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(state.brushEndTime, 'DD MMM HH:mm')}</small>
                                    </React.Fragment>
                                : null}
                            </div>
                        </div>
                    </div>
                    
                    <AnomalyDashboard 
                        loading={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                    />
                </div>
            </div>
        </div>
    )
}

export default LandingPage