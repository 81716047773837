/*************************************************
 * Collider
 * @exports
 * @file SavedFiltersSidePane.js
 * @author Prakash // on 18/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listCostTemplates, saveTemplate } from '../../../actions/cost/CostAction'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime, momentDateGivenFormat } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import Textarea from '../../common/Form/Textarea';
import { LoadingCircle } from '../../common/LoadingCiricle';

const SavedFiltersSidePane = (props) => {
	const clickOut = useRef()
    const[state, setState] = useState({
		minMaxHeight: 700,
		showLoading: true,
		template_name: "",
		description: "",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		perPage: 15,
	})
	
	// const tagKeys = useSelector(state => state.filters.tagKeys);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return() => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

	const templateViewFunction = useCallback(() => {		
		let inputFilters = props.selectedFilters
		let selectedFilters = []
		if(inputFilters.Tags) {
			selectedFilters.push(inputFilters.Tags)
		}
		if(inputFilters.Duration) {
			selectedFilters.push(inputFilters.Duration)
		}
		selectedFilters = [...selectedFilters, ...inputFilters.filters]
		setState(prevState => ({ ...prevState, savedFilters: props.selectedFilters, selectedFilters, template_name: props.selectedFilters.template_name, description: props.selectedFilters.description }))
				
	}, [props.selectedFilters])

	const listCostTemplatesFunction = useCallback(() => {
		let params = {}
		params.source = props.selectedProvider.toLowerCase();
		dispatch(listCostTemplates(params))
			.then((response) => {
				if(response) {
					let totalPages = 1
					if(response.length > state.perPage) {
						totalPages = Math.ceil(response.length / state.perPage)
					}
					
					setState(prevState => ({ ...prevState, listData: response, filteredListData: response, totalPages }))
				}
			})
	}, [dispatch, props.selectedProvider, state.perPage])

	useEffect(() => {
        // const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		setState(prevState => ({ ...prevState, minMaxHeight: outerHeight }))

		if(props.templateType === "view") {
			templateViewFunction()
		} else {
			setState(prevState => ({ ...prevState, selectedFilters: props.selectedFilters }))
		}
		listCostTemplatesFunction()

	}, [props.selectedFilters, props.templateType, templateViewFunction, listCostTemplatesFunction])

    const navigatePage = (action, currentPage) => {
		let startRecord = state.startRecord
		if(action === 'next' && currentPage !== state.totalPages) {
			startRecord = startRecord + state.perPage;
			setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
		} else if(action === 'previous' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
			startRecord = startRecord - state.perPage;
		} else if(action === 'start' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: 1 }));
			startRecord = 0;
		} else if(action === 'end' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
			startRecord = (state.totalPages - 1) * state.perPage;
		}
		setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

	useEffect(() => {
		if(state.callSaveFunction) {
			let hasError = false
			let params = {}
			params.source = props.selectedSource
			params.provider = props.selectedProvider		
			
			if(state.template_name && state.template_name !== "") {
				params.template_name = state.template_name
			} else {
				hasError = true
			}
	
			if(state.description && state.description !== "") {
				params.description = state.description
			} else {
				hasError = true
			}
	
			let selectedFilters = state.selectedFilters
	
			let filters = []
			if(selectedFilters.filter(e => e.label !== "Duration" && e.label !== "Tags").length) {
				selectedFilters.forEach(row => {
					if(row.label !== "Duration" && row.label !== "Tags") {
						filters.push(row)
					} else {
						params[row.label] = row
					}
				})
			}
	
			params.filters = filters
			
			if(!hasError) {
				dispatch(saveTemplate(params))
					.then((response) => {
						let messageType = 'danger'		
						if(response.status) {
							messageType = 'success'
						}
						let message = response && response.message
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						if(response.status) {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
							setTimeout(() => { listCostTemplates() }, 2000)
							props.closeSidePanel()
						} else {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
						}
					})
			} else {
				setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, hasError }))
			}
		}

	}, [dispatch, props.selectedProvider, props.selectedSource, state.description, state.selectedFilters, state.template_name, state.callSaveFunction])


    const handleChildClick = (event, type, dropdownType, section) => {	

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div className='bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.queryLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>{props.templateType === "save" ? "Save Filter (as Template)" : props.templateType === "view" ? "View Template" : "Templates"}</span>
								</div>
							</div>
							<div className='self-center'>
								<Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => props.closeSidePanel()} />
							</div>
						</div>
					</div>
					<div className='overflow-y-auto h-[calc(100vh-100px)] bg-white'>
						<div className='p-6 h-full'>
							{props.templateType !== "template" ?
								<div className="bg-white m-2 p-4 rounded-lg border border-NeutralGray-800">
									<div className={`flex flex-wrap`}> 
										<div className='lg:w-1/2 w-full lg:pr-4'>
											<Textbox
												label={"Title"}
												type="text"
												name="template_name"
												selectedValue={state.template_name ? state.template_name : ''}
												placeholder={"Enter template name"}
												callback={(value) => {
													setState(prevState => ({ ...prevState, template_name: value}))
												}}
												manditory={true}
												hasError={state.hasError}
											/>
											<p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.category}</p>
										</div>
									</div>
									<div className='flex flex-wrap pt-8'>
										<div className='w-full pt-4 lg:pt-0'>
											<Textarea
												label={'Description'}
												placeholder={'Enter description'}
												rows={4}
												wordLength={1000}
												disabled={state.pageType === 'View' ? true : false}
												selectedValue={state.description ? state.description : '' }
												callback={(value) => {
													setState(prevState => ({ ...prevState, description: value}))
												}}
												manditory={true}
												hasError={state.hasError}
											/>
										</div>
									</div>
									<div className='w-full mt-4'>
										<div className="flex justify-between">
											<label className="">Source </label>
										</div>
										<p className="mb-0 text-black">{capitalizeFirstLetter(props.selectedProvider)}</p>
									</div>
									<div className='bg-NeutralGray-100 rounded-lg p-4 my-4 border border-NeutralGray-800'>
										<div className='flex justify-between mb-2'>
											<p className="mb-2 w-full text-base font-semibold text-NeutralGray-800">Selected Filters</p>
										</div>
										<div className="flex flex-wrap self-center">
											{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Account").length ?
												<span className="font-semibold cursor-pointer text-NeutralGray-900 mr-2 mt-2">Account : <span className='text-info'>All</span></span>
											: null}

											{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Region").length ?
												<span className="font-semibold cursor-pointer text-NeutralGray-900 mx-2 mt-2">Region : <span className='text-info'>All</span></span>
											: null}
											
											{state.selectedFilters && state.selectedFilters.length ? 
												state.selectedFilters.map((filters, index) => {
													return(
														<React.Fragment key={'sel_'+index}>
														{filters.label === "Duration" ?
															<React.Fragment>
																<span className="font-semibold mt-2 cursor-pointer text-NeutralGray-900 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </span>
															</React.Fragment>
														: filters.value && !Array.isArray(filters.value) ?
															<span className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className='text-extraLightGray'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.label}</span>
														: filters.label === "Tags" ?
															<div className='flex flex-wrap self-center'>
															{filters.value.map(row => {
																return(
																	<span key={'tag_'+row.key} className="bg-info py-1 rounded-full mb-2 mr-2 mt-2 self-center f14 px-2">
																		<span className='text-lightGray'>Tag :</span> 
																		{row.key +" : "+row.value}
																	</span>
																)
															})}
															</div>
														:
															<div className='flex flex-wrap self-center'>
																{filters.value.map(row => {
																	return(
																		<span key={'row_'+row} className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
																			<span className='text-lightGray'>{filters.type+" : "}</span> 
																			{filters.label +" : "+row}
																		</span>
																	)
																})}
															</div>
														}
														</React.Fragment>
													)
												})
											: null}
										</div>
									</div>
									<div className={`flex my-4 justify-end`}>
										<div className={`self-center flex justify-end`}>
											<Button
												classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
												label={'Cancel'}
												callback={() => 
													props.closeSidePanel()
												}
											/>
											{props.templateType === "save" ?
												state.saveLoading ?
												<Button
													classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'pointer-events-none text-center'}}
													label={'Saving'}
													loading={true}
												/>
											:
												<Button
													classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
													label={'Save'}
													callback={() => {
														setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
													}}
												/>
											:null}
										</div>
									</div>
								</div>
							: null}
							<div className={`flex p-2 justify-end mt-4`}>
								<div className="flex mr-2 w-2/5">
									<p className="self-center">Showing {state.filteredListData && state.filteredListData.length} of total {state.listData && state.listData.length} template(s)</p>
								</div>
								<div className="flex justify-end w-3/5 mb-2">
									{state.listData && state.listData.length ?
										<Search
											data={state.listData ? state.listData : []}
											topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
											className={'rounded-full'}
											filteredData={(filteredListData) => {
												let totalPages = 1
												if(filteredListData.length > state.perPage) {
													totalPages = Math.ceil(filteredListData.length / state.perPage)
												}
												setState(prevState => ({ ...prevState, filteredListData, startRecord: 0, currentPage: 1, totalPages }))
											}}
										/>
									: null}

									{state.filteredListData && state.filteredListData.length > state.perPage ?
										<div className="pagination errorPagePagination mt-1">
											<span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
											<button><i className={`fal fa-arrow-to-left cursor-pointer text-NeutralGray-900 ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
											<button><i className={`fal fa-angle-left cursor-pointer text-NeutralGray-900 ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
											<button><i className={`fal fa-angle-right cursor-pointer text-NeutralGray-900 ${!state.nextErrorListToken && state.nextErrorListToken ==="" && state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
											<button><i className={`fal fa-arrow-to-right cursor-pointer text-NeutralGray-900 ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
										</div>
									: null}
								</div>
							</div>
							{!state.filteredListData ?
								<LoadingCircle/>
							:
								<div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg mt-4">
									<table className="w-full text-sm text-left rtl:text-right dark:text-NeutralGray-400">
										<thead className="text-md bg-lightGray-600 text-NeutralGray-800 font-semibold text-base">
											<tr>
												<th scope="col" className="px-3 py-2 w-1/12">Date</th>
												<th scope="col" className="px-3 py-2 w-2/12">Title</th>
												<th scope="col" className="px-3 py-2 w-3/12">Description</th>
												<th scope="col" className="px-3 py-2 w-6/12">Filters</th>
											</tr>
										</thead>
										<tbody className='text-sm text-NeutralGray-900 bg-white'>
											{state.filteredListData && state.filteredListData.length ?
												state.filteredListData.slice(state.startRecord, state.startRecord + state.perPage).map((row, i) => {
													return(
														<tr key={'sft_'+i} className={`bg-white border-b dark:bg-NeutralGray-800 border-NeutralGray-400`}>
															<td className="px-3 py-2 w-1/12 text-sm"><span className='text-nowrap'>{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, 'DD MMM YYYY HH:mm') : ""}</span></td>
															<td className="px-3 py-2 w-1/12 text-sm">{row.template_name ? row.template_name : ""}</td>
															<td className="px-3 py-2 w-1/12 text-sm">{row.description ? row.description : ""}</td>
															<td className="px-3 py-2 w-1/12 text-sm">
																<div className='flex flex-wrap self-center'>
																	{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Account").length ?
																		<small className="font-semibold cursor-pointer text-NeutralGray-900 mr-2">Account : <span className='text-info'>All</span></small>
																	: null}	

																	{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Region").length ?
																		<small className="font-semibold cursor-pointer text-NeutralGray-900 mx-2">Region : <span className='text-info'>All</span></small>
																	: null}

																	{row["Duration"] ?
																		<div className='flex ml-2'>
																			<small className="font-semibold cursor-pointer text-NeutralGray-900 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
																		</div>
																	: null}

																	{row["Tags"] ?
																		<div className='flex flex-wrap self-center'>
																			{row["Tags"].value.map(tag => {
																				return(
																					<span key={'tt_'+row} className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className='text-lightGray'>Tag :</span> {tag.key +" : "+tag.value}</span>
																				)
																			})}
																		</div>
																	: null}

																	{row.filters ? 
																		Array.isArray(row.filters) ? 
																			row.filters.map(flt => {
																			return(
																				<React.Fragment key={'flt_'+flt.label}>
																				{Array.isArray(flt.value) ?
																					<div className='flex flex-wrap self-center'>
																						{flt.value.map(item => {
																							return(
																								<span key={'fltii_'+item} className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
																									<span className='text-lightGray'>{flt.type} : </span> 
																									{flt.label +" : "+item}
																								</span>
																							)
																						})}
																					</div>
																				: 
																					<span className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className='text-lightGray'>{flt.label+" : "}</span> {flt.output_label ? flt.output_label : flt.value}</span>
																				}
																				</React.Fragment>
																			)
																			})
																		: typeof(row.filters) === "object" ?
																			Object.entries(row.filters).map(([fKey, fValue], index) => {
																			return(
																				<span key={'fValue_'+index+'_'+fValue}>
																				{Array.isArray(fValue) ?
																					<div className='flex flex-wrap self-center'>
																						{fValue.map(item => {
																							return(
																								<span key={'dddd_'+item+'_'+fKey} className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
																									{fKey +" : "+item}
																								</span>
																							)
																						})}
																					</div>
																				: 
																					<span className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all"><span className='text-lightGray'>{fKey+" : "+fValue}</span></span>
																				}
																				</span>
																			)
																			})
																		: null
																	: null}
																</div>
															</td>
														</tr>
													)
												})
											:  
												<tr>
													<td  colSpan="4" className='mb-0 text-black text-center'>There are no data on this criteria. Please try adjusting your filter.</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SavedFiltersSidePane