import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import FileList from "./file-list";
import FileFilter from './file-filter';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import RequestStoragesidePanel from './RequestStoragesidePanel'
import _ from 'lodash'
import { getUserQuotaDetails} from '../../../actions/files/FileAction'
import { capitalizeAllLetter } from "../../../utils/utility"
import Button from '../../common/Form/Button';

export default function Files() {
	const clickOutside = useRef();

	const[state, setState] = useState({
		showLoading: true,
	})
	const fileManageFilterChange = useSelector(state => state?.filters?.commonPropsDetails?.fileManageFilterChange || false);
	const uploadProgressBar = useSelector(state => state?.filters?.commonPropsDetails?.uploadProgressBar || 0);
	const location = useLocation();
    // const receivedData = location.state;
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;
	const dispatch = useDispatch(); // Create a dispatch function

	const handleClickOutside = (event) => {
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
			dispatch(setCommonPropsDetails('clickedonParent', true))
		}
	}

	useEffect(() => {
		if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
				if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath
					if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
						actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
					}
				} else if(row.action) {
					actionsAllowed = row.action
				}
			})
			setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            

    useEffect(() => {
		let params = {}
		params.provider = state.selectedProvider?.toLowerCase()

		if(state.selectedAccount && state.selectedAccount.length) {
			params.account_id = state.selectedAccount
		} if(state.selectedRegion && state.selectedRegion.length) {
			params.region = state.selectedRegion
		} if(state.selectedClusters && state.selectedClusters.length) {
			params.cluster_name = state.selectedClusters
		}

		if(state.selectedAccount && state.selectedRegion && state.selectedClusters){
			dispatch(getUserQuotaDetails(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, storageDetails: response?.results, showLoading: false }))
				}
			})
		}

	}, [dispatch, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedProvider])

		useEffect(() => {
			if(!_.isEqual(state.fileManageFilterChange, fileManageFilterChange)) {
          setState(prevState => ({ 
            ...prevState, 
            selectedProvider: fileManageFilterChange.selectedProvider,
            selectedAccount: fileManageFilterChange.selectedAccount ? fileManageFilterChange.selectedAccount : '',
            selectedRegion: fileManageFilterChange.selectedRegion ? fileManageFilterChange.selectedRegion : '',
            selectedClusters: fileManageFilterChange.selectedClusters ? fileManageFilterChange.selectedClusters : '',
            fileManageFilterChange,
            filteredFiles: []
          }))

          if(fileManageFilterChange.selectedClusters) {
            setState(prevState => ({ ...prevState, callUserFiles: true, filesDataLoading: true, statsDetail: {} }))
          }
        }
      }, [fileManageFilterChange, state.fileManageFilterChange])
	
	return (
		<div className='px-6'>
			<div className="file-browser top-0 relative z-10 py-6" onClick={(event) => handleClickOutside(event)}>
				<div className="flex justify-between bg-DeepPurpleTints-600 rounded-2xl px-6 py-[19px]">
					<div className="lg:w-1/8 self-center">
						<p className="text-white text-[28px] text-semibold">File Manager</p>
					</div>									
					<div className='lg:w-3/8'>
						<div className="flex justify-end">
							<FileFilter
								onFilterChange={(cluster) => {
									// setSelectedCluster(cluster)
									// setState(prevState => ({
									// ...prevState,
									// selectedClusters: cluster
									// }))
								}}
							/>
							{state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedClusters ?
								state.storageDetails?.total && state.actionsAllowed && state.actionsAllowed.includes("storage_request") ?
									<div className='flex justify-end lg:mx-3 md:mx-3 mx-0 pt-2 lg:mt-6 md:mb-2 mb-2'>
										<Button
											classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', margin: 'mx-2', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer'}}
											label={'Request Storage'}
											callback={() => {
												setState(prevState => ({ ...prevState, showRequestStoragePanel: true, pageMode: 'Create' }))
											}}
										/>
									</div>
								: null
							: null}
						</div>
						{state.showRequestStoragePanel ?
							<RequestStoragesidePanel 
								closeSidePanel={() => setState(prevState => ({...prevState, showRequestStoragePanel: false }))}
								pageMode={state.pageMode}
								refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
								selectedProvider={state.selectedProvider}
								selectedAccount={state.selectedAccount}
								selectedRegion={state.selectedRegion}
								selectedClusters={state.selectedClusters}
								storageDetails={state.storageDetails.user}
							/>
						: null}
					</div>
				</div>
				<div className='flex flex-wrap self-center pt-6 w-full'>
					{state.actionsAllowed?.includes("storage_request") && state.storageDetails?.total && state.storageDetails?.total.show_details ?
						<div className={`pr-2 text-base ${state.storageDetails?.total.show_details ? 'lg:w-1/2 md:w-full': 'flex justify-center w-full'}`}>
							<div className='flex start border border-NeutralGray-600 p-3 rounded-lg'>
								<p className='font-medium text-black border-r border-NeutralGray-500 pr-3'>Total</p>
								<div className='flex self-center mx-3 text-black'>
									<span className='mr-2'>Allocated&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.total.allocated_storage}
										<span className='ml-1 font-semibold'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : 'GB' }</span>
									</span>
								</div>
								<div className='flex self-center mr-3 text-black'>
									<span className='mr-2'>Used&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.total.storage_used}
										<span className='ml-1 font-semibold'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : 'GB' }</span>
									</span>
								</div>
								<div className='flex self-center mr-3 text-black'>
									<span className='mr-2'>Available&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.total.available_storage}
										<span className='ml-1 font-semibold'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : 'GB' }</span>
									</span>
								</div>
							</div>
						</div>
					: null}
					{state.storageDetails?.user && state.storageDetails?.user.show_details ?
						<div className={`pr-2 text-base ${state.storageDetails?.total.show_details ? 'lg:w-1/2 md:w-full' : 'flex justify-center w-full'} `}>
							<div className={`flex justify-start border border-NeutralGray-600 p-3 rounded-lg`}>
								<p className='font-medium text-black border-r border-NeutralGray-500 pr-3'>User</p>
								<div className='flex self-center mx-3 text-black'>
									<span className='mr-2'>Allocated&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.user.allocated_storage}
										<span className='ml-1 font-semibold'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
									</span>
								</div>
								<div className='flex self-center mr-3 text-black'>
									<span className='mr-2'>Used&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.user.storage_used}
										<span className='ml-1 font-semibold'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
									</span>
								</div>
								<div className='flex self-center mr-3 text-black'>
									<span className='mr-2'>Available&nbsp;Storage</span>
									<span className='mr-2'>:</span>
									<span className='font-semibold'>
										{state.storageDetails?.user.available_storage}
										<span className='ml-1 font-semibold'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
									</span>
								</div>
							</div>
						</div>
					: null}
				</div>
				<div className='flex flex-wrap justify-end pt-3 w-full'>
					{uploadProgressBar > 0 && (
						<div className='w-80 text-left'>
							<p className='text-lg mt-2 w-full'>Upload Progress: {uploadProgressBar}%</p>
							<div className="relative w-60  mt-2 h-4 bg-gray-300 rounded-lg">
								<div className="absolute top-0 h-4 bg-green-600 rounded-lg" style={{ width: uploadProgressBar+"%" }}></div>
							</div>
						</div>
                    )}
				</div>
				<div className="flex flex-wrap" ref={clickOutside}>
					<FileList />
				</div>
			</div>
		</div>
	);
}