/*************************************************
 * Collider
 * @exports
 * @file OnboardClusterSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { onboardClusters, listOnboardingClusters, getOnboardedClusterDetails } from '../../../actions/AccountAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { getCommonInformationMessage } from '../../../utils/utility';
import SelectOption from '../../common/Form/SelectOption';
import Button from '../../common/Form/Button';
import RadioOption from '../../common/Form/RadioOption';

const OnboardClusterSidePanel = ({ closeSidePanel, refreshApi, pageMode, selectedAccountDetails, AccountDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        clusters: [],
        callClusterFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.callClusterFilters) {
            setState(prevState => ({ ...prevState, callClusterFilters: false }))
            let params = {};
            params.account_id = AccountDetails?.account_id
            dispatch(listOnboardingClusters(params))
                .then((response) => {
                    if(response) {
                        let clusters = response.results ? response.results : []
                        setState(prevState => ({ ...prevState, clusters }));
                    }
                })
        }
    }, [state.callClusterFilters, dispatch, pageMode, selectedAccountDetails, AccountDetails?.account_id])

    useEffect(() => {
        if(state.callClusterFilters) {
            setState(prevState => ({ ...prevState, callClusterFilters: false }))
            let params = {};
            params.account_id = AccountDetails?.account_id
            dispatch(getOnboardedClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let clusterDetails = response.results ? response.results : []
                        setState(prevState => ({ ...prevState, clusterDetails }));
                    }
                })
        }
    }, [state.callClusterFilters, dispatch, pageMode, selectedAccountDetails, AccountDetails?.account_id])


    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            if(!state.selectedCluster) {
                hasError = true
            }
            // if(pageMode === 'Create') {
            //     if(!state.emailId || !validateEmail(state.emailId)) {
            //         hasError = true
            //     }
            //     params.emailId = state.emailId
            // }
            // params.accounts_info[0].provider = convertToLowerCase(state.selectedCluster)
            params.cluster_name = state.selectedCluster

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(onboardClusters(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Account'

                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'Account Created Successfully'

                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedAccountDetails])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                <p className="font-bold text-2xl text-white">
                                    {pageMode === "View" ? "" : pageMode === "Edit" ? <span>Edit&nbsp;Cluster</span> : <span>Onboard&nbsp;Cluster</span>}
                                </p>
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                {AccountDetails && AccountDetails.account_name ?
                                    <p className='mb-1 text-NeutralGray-400 pr-4'>Name: <span className='text-white font-bold'>{AccountDetails.account_id}</span></p>
                                : null}
                                {AccountDetails && AccountDetails.account_id ?
                                    <div className="pl-4">
                                        <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{AccountDetails.account_name}</span></p>
                                    </div>
                                : null}
                            </div>
                        </div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                    </div>
                </div>
            </div>

            <div className='p-6'>
                <div className='flex flex-wrap mt-2 pt-2'>
                    <div className='p-3 mb-3' onClick={(event) => handleChildClick(event, 'parent')}>
                        <p className="text-base font-medium text-black self-center w-full">Cluster Options</p>
                        <div className='flex pt-3'>
                            <RadioOption
                                label="Parallel cluster"
                                value="parallel_cluster"
                                selected={state.clusterOption === "parallel_cluster"}
                                callback={() => setState(prevState => ({ ...prevState, clusterOption: "parallel_cluster"}))}
                            />
                            <RadioOption
                                label="Slurm native cluster"
                                value="slurm_native_cluster"
                                selected={state.clusterOption === "slurm_native_cluster"}
                                callback={() => setState(prevState => ({ ...prevState, clusterOption: "slurm_native_cluster"}))}
                                className="pl-8"
                            />
                        </div>
                    </div>
                </div>
                {state.clusterOption === "parallel_cluster" ? (
                    <React.Fragment>
                        <div className='flex flex-wrap pt-8'>
                            <div className='flex justify-start w-1/2 pl-4'>
                                <SelectOption
                                    label={"Cluster Name"}
                                    options={state.clusters}
                                    selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, selectedCluster: value }))
                                    }}
                                    singleSelection={true}
                                    manditory={true}
                                    hasError={state.hasError}
                                    dropdownWidth={'w-full'}
                                    disabled={state.pageType === 'edit'}
                                />
                            </div>
                        </div>
                        <div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
                            <div className={`flex justify-end w-full`}>
                                <div className={`self-center flex justify-end`}>
                                    <Button
                                        classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                        label={'Cancel'}
                                        callback={() => 
                                            closeSidePanel()
                                        }
                                    />
                                    {state.pageType !== 'View' ?
                                        state.saveLoading ?
                                        <Button
                                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                            label={pageMode === 'Edit' ? 'Updating' : 'Submitting'}
                                            loading={true}
                                        />
                                    :
                                        <Button
                                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                            label={pageMode === 'Edit' ? 'Update' : 'Submit'}
                                            callback={() => {
                                                setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                            }}
                                        />
                                    :null}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
                <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                    <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Image Configurations</p>
                    <div className="w-full">
                        <div className="relative overflow-x-auto rounded-t-lg shadow">
                        {state.clusterDetails && state.clusterDetails.length ?
                        <ResizeableDarkThemeTable
                            columns={[
                                {
                                    Header: 'Resource type',
                                    accessor: 'resource_type',
                                    width: 150,
                                },
                                {
                                    Header: 'Cluster name',
                                    accessor: 'cluster_name',
                                    width: 200,
                                },
                                {
                                    Header: 'Headnode instance Id',
                                    accessor: 'headnode_instance_id',
                                    width: 180,
                                },
                                {
                                    Header: 'Headnode ip',
                                    accessor: 'headnode_ip',
                                    width: 100,
                                },
                                {
                                    Header: 'Headnode status',
                                    accessor: 'headnode_status',
                                    width: 200,
                                },
                                {
                                    Header: 'Proxy instance id',
                                    accessor: 'proxy_instance_id',
                                    width: 200,
                                },
                                {
                                    Header: 'Proxy ip',
                                    accessor: 'proxy_ip',
                                    width: 100,
                                },
                                {
                                    Header: 'Proxy status',
                                    accessor: 'proxy_status',
                                    width: 180,
                                }
                            ]}
                            data={state.clusterDetails}
                            perPage={10}
                            selectedColor={'bg-backGroundGray'}
                            dashboard={true}
                            showPaginationTop={true}
                            sortByColumn={''}
                            riskTooltip={[0]}
                            onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListAccounts: tableRow }))}
                        />
                    : 
                        <div className='flex justify-center m-4'>
                            <p>{getCommonInformationMessage('Cluster')}</p>
                        </div>
                    }
                        </div>
                    </div>
                </div>
            </div>

                </div>
            </div>
        </div>
    )
}

export default OnboardClusterSidePanel