/*************************************************
 * Collider
 * @exports
 * @file BudgetDashboard.js
 * @author Prakash
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders } from '../../../../actions/commonActionNew'
import { UncontrolledTooltip } from 'reactstrap'
import { listDepartmentsInitiativesProjects, getTopSpenders, getTopOverspenders, getSummary, getObservations, getCloudCommitmentVsSpend, getTopSpendersForecast, getUntaggedResources, getCurrencyConversionDetails } from '../../../../actions/cost/BudgetAction'
import _ from 'lodash'
import { capitalizeTheFirstLetterOfEachWord, defaultDateRangeValue, momentDateGivenFormat, splitString, thousandSeparator } from '../../../../utils/utility'

import ApexBarChart from '../../../common/charts/ApexBarChart'
import ApexNonStackedBarChart from '../../../common/charts/ApexNonStackedBarChart';
import ApexDonutChartNew from '../../../common/charts/ApexDonutChartNew';

import Search from '../../../common/SearchWithHiddenInput'
import { setCostPropsDetails } from '../../../../actions/cost/CostAction';
import CloudCommitmentSpend from './CloudCommitmentSpend';
import ApexHorizontalStackedBarChart from '../../../common/charts/ApexHorizontalStackedBarChart';
import {Icon} from "@iconify/react";
import PageLoading from '../../../common/PageLoading';
import SelectOption from '../../../common/Form/SelectOption';
import SmartDateRangePicker from '../../../common/SmartDateRangePicker';
import { LoadingCircle } from '../../../common/LoadingCiricle';
import BreakupDetails from './BreakupDetails';
import NewCheckbox from '../../../common/Form/NewCheckbox';

let callSingleClick = null
const LandingPage = () => {
    const durationRef = useRef();
    const searchRef = useRef();
    
    const [dateRange, setDateRange] = useState(defaultDateRangeValue());
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue());

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',
        selectionRange: [{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }],
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],
        selectedSearchItem: 'Department',
        // cloudProviderId: ['aws', 'gcp']
        currencyUnit: 'usd',
        currencyTypes: ['usd', 'gbp']
    })

    const [clickCount, setClickCount] = useState(0)

    const ExcelJS = require('exceljs');
    const dispatch = useDispatch(); // Create a dispatch function
    const departmentInitiativeProjectsAccounts = useSelector(state => state?.cost?.costPropsDetails?.departmentInitiativeProjectsAccounts);
    const departmentDetails = useSelector(state => state?.cost?.costPropsDetails?.departmentDetails);
    const currentCloudCommitSpendObservations = useSelector(state => state?.cost?.currentCloudCommitSpendObservations);
    const cloudCommitVsSpend = useSelector(state => state?.cost?.costPropsDetails?.cloudCommitVsSpend);

    useEffect(() => {
        let params = {}
        dispatch(listAllProviders(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({
                        ...prevState,
                        providers: response,
                        cloudProviderId: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? ['AWS'] : [response[0].provider_name]) : ''
                    }));
                }
            })

        let currPrams = {
            convert_from: 'usd', 
            convert_to: state.currencyUnit
        }

        dispatch(getCurrencyConversionDetails(currPrams))
			.then((response) => {
				setState(prevState => ({ ...prevState, conversionDetails: response.conversion_details ? response.conversion_details : '' }))
			})
            
    }, [dispatch, state.currencyUnit]);

    useEffect(() => {
        if(state.showSummaryLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showSummaryLoading: false })) }, 10000);
        }
    }, [state.showSummaryLoading])

    useEffect(() => {
        if(state.callSummaryFunction) {
            setState(prevState => ({ ...prevState, callSummaryFunction: false, showSummaryLoading: true }))
            let aggregateBy = 'department'
            let labelName = state.selectedDepartment
            let params = {
                "department": state.selectedDepartment,
                'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),                
            }   
            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem !== 'Department') {
                let splitValue = splitString(state.selectedSearchItem, ':')
                department = splitValue[0]

                isAccount = splitValue[splitValue.length-1]
                if(isAccount === 'accounts') {
                    if(splitValue.length === 3) {
                        initiative = splitValue[1]
                    }
                } else {
                    if(splitValue.length >= 2) {
                        initiative = splitValue[1]
                    }
                    if(splitValue.length >= 3) {
                        project = splitValue[2]
                    }
                }
            }

            if(department) {
                aggregateBy = 'initiative'
            }
            if(initiative) {
                aggregateBy = 'project'
            }

            if(state.selectedInitiative) {
                params.initiative = state.selectedInitiative
                labelName = state.selectedDepartment+'_'+state.selectedInitiative
            }

            if(state.selectedProject) {
                params.project = state.selectedProject
                labelName = state.selectedDepartment+'_'+state.selectedInitiative+'_'+state.selectedProject
            }

            if(state.selectedAccount) {
                // params.account_id = account
                labelName = state.selectedDepartment+'_'+state.selectedInitiative+'_'+state.selectedProject+'_'+state.selectedAccount
            }

            if(project || isAccount === "accounts") {
                // aggregateBy = 'billingUnit'
                if(department) {
                    aggregateBy = 'department   '
                }
                if(initiative) {
                    aggregateBy = 'initiative'
                }
                if(project) {
                    aggregateBy = 'project'
                }
                params.accounts_view = true
                params.billingUnit = state.selectedAccount
            }

            params.aggregate_by = aggregateBy
            if(state.searchProvider && state.searchProvider.length) {
                let provider = state.searchProvider.map(v => v.toLowerCase());
                params.cloud_provider = provider
            } else if(state.cloudProviderId && state.cloudProviderId.length) {
                let provider = state.cloudProviderId.map(v => v.toLowerCase());
                params.cloud_provider = provider
            }
            if(state.without_tax) {
                params.without_tax = state.without_tax
            }
            if(state.without_savings) {
                params.without_savings = state.without_savings
            }
            if(state.without_credits_discounts) {
                params.without_credits_discounts = state.without_credits_discounts
            }

            params.currency_unit = state.currencyUnit

            labelName += '_'+state.currencyUnit
            

            if(departmentDetails && departmentDetails.hasOwnProperty(labelName)) {
                setState(prevState => ({
                    ...prevState,
                    summaryDetails: departmentDetails[labelName],
                    distributionDonutData: {},
                    tableData: [],
                    callDistributionDonutDetails: true,
                    spendCommitment: {},
                    aggregateBy,                 
                    callSpendCommitmentDetails: true,  
                    showLoading: false,
                    showSummaryLoading: false
                }));
            } else {
                dispatch(getSummary(params))
                    .then((response) => {
                        if(response && !response.error) {
                            let obj = departmentDetails ? departmentDetails : {}
                            obj[labelName] = response && response.results ? response.results : {}
                            dispatch(setCostPropsDetails('departmentDetails', obj))
                            setState(prevState => ({
                                ...prevState,
                                summaryDetails: response && response.results ? response.results : {},
                                distributionDonutData: {},
                                callDistributionDonutDetails: true,
                                spendCommitment: {},
                                aggregateBy,
                                callSpendCommitmentDetails: true,
                                showLoading: false,
                                showSummaryLoading: false
                            }));
                        }
                    })
            }
        }
    }, [state.callSummaryFunction, state.selectedDepartment, state.selectedInitiative, state.selectedProject, state.selectedAccount, state.datePickerStartDate, state.datePickerEndDate, state.selectedSearchItem, dispatch, departmentDetails, state.searchProvider, state.cloudProviderId, state.without_savings, state.without_tax, state.without_credits_discounts, state.currencyUnit])

    useEffect(() => {
        if(state.callTopGraphSection && state.datePickerStartDate && state.datePickerEndDate) {
            setState(prevState => ({ ...prevState, callTopGraphSection : false, graphTopSpenders: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, taggedNonTaggedDistribution: {}, distributionDonutData: {}, spendCommitment: {} }))
            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem !== 'Department') {
                let splitValue = splitString(state.selectedSearchItem, ':')
                department = splitValue[0] 

                isAccount = splitValue[splitValue.length-1]
                if(isAccount === 'accounts') {
                    if(splitValue.length === 3) {
                        initiative = splitValue[1]
                    }
                } else {
                    if(splitValue.length > 1) {
                        initiative = splitValue[1]
                    }
                    if(splitValue.length > 2) {
                        project = splitValue[2]
                    }
                }
            }
            let aggregateBy = 'department'

            let overallParams = {
                'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),                
            }
            if(department) {
                aggregateBy = 'initiative'
                overallParams.department = department
            }
            if(initiative) {
                aggregateBy = 'project'
                overallParams.initiative = initiative
            }
            if(project) {
                aggregateBy = 'project'
                overallParams.project = project
            }

            if(isAccount === 'accounts' || project) {
                // aggregateBy = 'billingUnit'
                if(department) {
                    aggregateBy = 'department'
                }
                if(initiative) {
                    aggregateBy = 'initiative'
                }
                if(project) {
                    aggregateBy = 'project'
                }
                overallParams.accounts_view = true
            }

            overallParams.aggregate_by = aggregateBy
            
            let spenders = {}
            if(state.searchProvider && state.searchProvider.length) {
                let provider = state.searchProvider.map(v => v.toLowerCase());
                spenders.cloud_provider = provider
            } else if(state.cloudProviderId && state.cloudProviderId.length) {
                let provider = state.cloudProviderId.map(v => v.toLowerCase());
                spenders.cloud_provider = provider
            }
            if(state.without_tax) {
                spenders.without_tax = state.without_tax
            }
            if(state.without_savings) {
                spenders.without_savings = state.without_savings
            }
            if(state.without_credits_discounts) {
                spenders.without_credits_discounts = state.without_credits_discounts
            }

            spenders.currency_unit = state.currencyUnit

            spenders = {...overallParams, ...spenders}

            spenders.start_time = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            spenders.end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

            spenders.start_time = state.datePickerStartDate
            spenders.end_time = state.datePickerEndDate
    
            dispatch(getTopSpenders(spenders))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}
                        if(isAccount !== 'accounts' && !project) {
                            let graphTopSpenders = {}
                            graphTopSpenders.labels = results.labels ? results.labels : []
                            graphTopSpenders.data = results.data ? results.data : []
                            graphTopSpenders.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            setState(prevState => ({ ...prevState, topSpenders: results, graphTopSpenders }));
                        } else {
                            let graphTopSpenders = {}
                            graphTopSpenders.labels = results.labels ? results.labels : []
                            graphTopSpenders.data = results.data ? results.data : []
                            graphTopSpenders.total = results.total_spend ? results.total_spend : []
                            graphTopSpenders.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            graphTopSpenders.belowLabelToolTip = results.tooltip ? results.tooltip : []
                            
                            let data = results.donut_chart && results.donut_chart.labels.length ? results.donut_chart.data : []
                            
                            let donut = []
                            let donut_total_count = 0
                            results.donut_chart && results.donut_chart.labels && results.donut_chart.labels.forEach((lab, index) => {
                                let donutRow = {}
                                donutRow.item_name = lab
                                donutRow.item_count = data[index] ? data[index] : 0
                                donut.push(donutRow)
                                donut_total_count += data[index] ? data[index] : 0 
                            })
                            donut = _.orderBy(donut, ['item_count'], ['desc'])
                    
                            let taggedNonTaggedDistribution = {
                                'items': donut,
                                'label': 'Total',
                                'total_count': donut_total_count
                            }
                            
                            setState(prevState => ({ ...prevState, topSpenders: results, graphTopSpenders, taggedNonTaggedDistribution }));
                        }
                    }
                })
            
            if(isAccount !== 'accounts') {
                dispatch(getTopOverspenders(spenders))
                    .then((response) => {
                        if(response && !response.error) {
                            // let results = response.results ? response.results : {}
                            // let graphOverSpenders = {}
                            // graphOverSpenders.labels = results.labels ? results.labels : []
                            // graphOverSpenders.data = results.data ? results.data : []
                            // graphOverSpenders.unit = '%'
                            // setState(prevState => ({
                            //     ...prevState,
                            //     topOverSepnders: results,
                            //     graphOverSpenders
                            // }));

                            let results = response.results ? response.results : {}

                            // let results = {
                            //     "data": {
                            //       "overspending": [
                            //         900,
                            //         700
                            //       ],
                            //       "underspenindg": [
                            //           0,
                            //           1823
                            //         ]
                            //     },
                            //     "labels": [
                            //       "RDD",
                            //       "CITO"
                            //     ]
                            // }
                            
                            let graphData = {}
                            graphData.labels = results.labels ? results.labels : []
                            graphData.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            graphData.unit = '%'
                            results.data && Object.entries(results.data).length && Object.entries(results.data).forEach(([key, value]) => {
                                graphData[key] = value
                            })
                            let additionalTooltip = {}
                            if(results.spend && results.spend.length) {
                                additionalTooltip.spend = results.spend
                            }
                            if(results.budget && results.budget.length) {
                                additionalTooltip.budget = results.budget
                            }
                            graphData.additionalTooltip = additionalTooltip

                            setState(prevState => ({ ...prevState, topOverSepnders: results, graphOverSpenders: graphData }));
                        }
                    })
            }

            // if(!currentCloudCommitSpendObservations || !currentCloudCommitSpendObservations.results ) {
                dispatch(getObservations(spenders))
                    .then((response) => {
                        if(response && !response.error) {
                            let results = response.results ? response.results : {}
                            setState(prevState => ({
                                ...prevState,
                                currentObsevations: results,
                            }));
                        }
                    })
            // } else {
            //     let results = currentCloudCommitSpendObservations.results ? currentCloudCommitSpendObservations.results : {}
            //     setState(prevState => ({
            //         ...prevState,
            //         currentObsevations: results,
            //     }));
            // }

            let overSpenders = {}
            overSpenders.start_time = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            overSpenders.end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

            overSpenders = {...spenders, ...overSpenders}
    
            dispatch(getTopSpendersForecast(overSpenders))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}

                        let graphOverSpendersForecast = {}
                        graphOverSpendersForecast.labels = results.labels ? results.labels : []
                        graphOverSpendersForecast.data = results.forecast_data ? results.forecast_data : []
                        graphOverSpendersForecast.total = results.total_spend ? results.total_spend : []
                        graphOverSpendersForecast.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                        graphOverSpendersForecast.belowLabelToolTip = results.tooltip ? results.tooltip : []
                        
                        setState(prevState => ({ ...prevState, graphOverSpendersForecast, overSepndersForecast: results }))
                    }
                })

        }
    }, [state.callTopGraphSection, state.selectedSearchItem, state.datePickerStartDate, state.datePickerEndDate, state.selectedAccount, dispatch, currentCloudCommitSpendObservations, state.searchProvider, state.cloudProviderId, state.without_savings, state.without_tax, state.without_credits_discounts, state.currencyUnit])

    useEffect(() => {
        if(state.callSearch) {
            dispatch(setCostPropsDetails('departmentDetails', {}))
            dispatch(setCostPropsDetails('departmentDetailsYearlyComparision', {}))
            dispatch({ type: 'SET_CLOUD_COMMITMENT_VS_SPEND', payload: {} });
            
            setState(prevState => ({ 
                ...prevState,
                callSearch: false,
                graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {},
                showCloudProviderSection: state.selectedSearchItem === 'Provider' ? true : false,
                showLoading: true
            }))
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 4000);
            if(!departmentInitiativeProjectsAccounts || !departmentInitiativeProjectsAccounts.length) {
                let depParams = {}
                if(state.searchProvider && state.searchProvider.length) {
                    let provider = state.searchProvider.map(v => v.toLowerCase());
                    depParams.cloud_provider = provider
                } else if(state.cloudProviderId && state.cloudProviderId.length) {
                    let provider = state.cloudProviderId.map(v => v.toLowerCase());
                    depParams.cloud_provider = provider
                }
                dispatch(listDepartmentsInitiativesProjects(depParams))
                    .then((response) => {
                        let results =  response && response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            departmentInitiativeProjectsAccounts: results,
                            callDepartmentStructure: true,
                            callCloudProviderDetailApi: true
                        }))
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    departmentInitiativeProjectsAccounts,
                    callDepartmentStructure: true
                }))
            }
        }
    }, [state.callSearch, state.datePickerStartDate, state.datePickerEndDate, dispatch, departmentInitiativeProjectsAccounts, state.selectedSearchItem, state.searchProvider, state.cloudProviderId])

    useEffect(() => {
        if(state.callCloudProviderDetailApi) {
            setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
            
            if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
                setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend.results }));
            } else {
                let depParams = {
                    'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                    'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
                }
                if(state.without_tax) {
                    depParams.without_tax = state.without_tax
                }
                if(state.without_savings) {
                    depParams.without_savings = state.without_savings
                }
                if(state.without_credits_discounts) {
                    depParams.without_credits_discounts = state.without_credits_discounts
                }
                depParams.currency_unit = state.currencyUnit
                dispatch(getCloudCommitmentVsSpend(depParams))
                    .then((response) => {
                        if(response && !response.error) {
                            let results =  response && response.results ? response.results : {}
                            setState(prevState => ({ ...prevState, cloudCommitment: results }))

                            if(results && Object.entries(results).length) {
                                let obj = cloudCommitVsSpend ? cloudCommitVsSpend : {}
                                obj[state.currencyUnit] = results
                                dispatch(setCostPropsDetails('cloudCommitVsSpend', obj))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, cloudCommitment: {} }))
                        }
                    })
            }
        }
    }, [state.callCloudProviderDetailApi, state, cloudCommitVsSpend, dispatch])

    useEffect(() => {
        if(state.callDepartmentStructure) {
            setState(prevState => ({ ...prevState, callDepartmentStructure: false, isSearchOpen: false }))
            let listItems = []

            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem === 'Provider') {
                setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedItem: department,selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callCloudProviderDetailApi: true, showCloudProviderSection: true }))
            } else {
                let splitState = ['Department']
                if(state.selectedSearchItem !== 'Department') {
                    let splitValue = splitString(state.selectedSearchItem, ':')
                    splitState = splitValue
                    department = splitValue[0]
                    isAccount = splitValue[splitValue.length-1]
                    if(isAccount === 'accounts') {
                        if(splitValue.length === 3) {
                            initiative = splitValue[1]
                        }
                    } else {
                        if(splitValue.length >= 2) {
                            initiative = splitValue[1]
                        }
                        if(splitValue.length >= 3) {
                            project = splitValue[2]
                        }
                    }
                }

                if(state.selectedSearchItem !== "Department") {
                    if(isAccount === 'accounts')  {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = []
                        if(departDetails && departDetails.length) {
                            if(initiative) {
                                let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                                projectDetails = initiatives.filter(e => e.initiative === initiative)
                                let projects = projectDetails[0].projects ? projectDetails[0].projects : []
                                projects.length && projects.forEach(proj => {
                                    proj.accounts && proj.accounts.forEach(acc => {
                                        if(!listItems.filter(e => e.label === acc).length) {
                                            let dataRow = {}
                                            dataRow.label = acc
                                            listItems.push(dataRow)
                                        }
                                    })
                                })
                            } else {
                                departDetails.forEach(dep => {
                                    if(dep.initiatives && dep.initiatives.length) {
                                        dep.initiatives.forEach(int => {
                                            if(int.projects && int.projects.length) {
                                                int.projects.forEach(proj => {
                                                    proj.accounts && proj.accounts.forEach(acc => {
                                                        if(!listItems.filter(e => e.label === acc).length) {
                                                            let dataRow = {}
                                                            dataRow.label = acc
                                                            listItems.push(dataRow)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        let selectedAccount = ''
                        if(listItems && listItems.length) {
                            selectedAccount = listItems[0].label
                        }
    
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: project, selectedAccount, selectedItem: selectedAccount, callSummaryFunction: true, getUntaggedResourcesDetails: true, callTopGraphSection: true }))
                    } else if(project) {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = [] 
                        if(departDetails && departDetails.length) {
                            let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                            projectDetails = initiatives.filter(e => e.initiative === initiative)
                        }
                        let accountDetails = []
                        if(projectDetails && projectDetails.length) {
                            let projects = projectDetails[0].projects ? projectDetails[0].projects : []
                            accountDetails = projects.filter(e => e.project === project)
                        }
    
                        let selectedAccount = ''
                        if(accountDetails && accountDetails.length) {
                            accountDetails[0].accounts && accountDetails[0].accounts.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    selectedAccount = row
                                }
                                let dataRow = {}
                                dataRow.label = row
                                listItems.push(dataRow)
                            })
                        }
    
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: project, selectedAccount, selectedItem: selectedAccount, callSummaryFunction: true, callTopGraphSection: true, getUntaggedResourcesDetails: true }))
                    } else if(initiative) {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = [] 
                        if(departDetails && departDetails.length) {
                            let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                            projectDetails = initiatives.filter(e => e.initiative === initiative)
                        }
                        let selectedProject = ''
                        if(projectDetails && projectDetails.length) {
                            projectDetails[0].projects && projectDetails[0].projects.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    selectedProject = row.project
                                }
                                let dataRow = {}
                                dataRow.label = row.project
                                listItems.push(dataRow)
                            })
                        }
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject, selectedAccount: '', selectedItem: selectedProject, callSummaryFunction: true, callTopGraphSection: true }))
                    } else {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        if(departDetails && departDetails.length) {
                            departDetails[0].initiatives && departDetails[0].initiatives.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    initiative = row.initiative
                                }
                                let dataRow = {}
                                dataRow.label = row.initiative
                                listItems.push(dataRow)
                            })
                        }
                        
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: '', selectedProjectTags: [], selectedAccount: '', selectedItem: initiative, callSummaryFunction: true, callTopGraphSection: true }))
                    }
                } else {
                    state.departmentInitiativeProjectsAccounts.forEach((row, rIndex) => {
                        if(!rIndex) {
                            department = row.department
                        }
                        let dataRow = {}
                        dataRow.label = row.department
                        listItems.push(dataRow)
                    })
    
                    setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedItem: department, selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true, callTopGraphSection: true, getUntaggedResourcesDetails: true }))
                }

                setState(prevState => ({ ...prevState, splitState }))
            }
        }

    }, [state.callDepartmentStructure, state.selectedSearchItem, state.departmentInitiativeProjectsAccounts, state.selectedInitiative, state.selectedDepartment])

    useEffect(() => {
        if(state.departmentInitiativeProjectsAccounts) {
            let structedSearchData = []
            let searchRow = {}
            searchRow.label = 'Provider'
            structedSearchData.push(searchRow)
            searchRow = {}
            searchRow.label = 'Department'
            structedSearchData.push(searchRow)
            state.departmentInitiativeProjectsAccounts.forEach(row => {
                searchRow = {}
                searchRow.label = row.department
                structedSearchData.push(searchRow)

                searchRow = {}
                searchRow.label = row.department+':accounts'
                structedSearchData.push(searchRow)
                if(row.initiatives && row.initiatives.length) {
                    row.initiatives.forEach(int => {
                        searchRow = {}
                        searchRow.label = row.department+':'+int.initiative
                        structedSearchData.push(searchRow)

                        searchRow = {}
                        searchRow.label = row.department+':'+int.initiative+':accounts'
                        structedSearchData.push(searchRow)
                        if(int.projects && int.projects.length) {
                            int.projects.forEach(proj => {
                                searchRow = {}
                                searchRow.label = row.department+':'+int.initiative+':'+proj.project
                                structedSearchData.push(searchRow)
                                // if(proj.accounts && proj.accounts.length) {
                                //     proj.accounts.forEach(acc => {
                                //         searchRow = {}
                                //         searchRow.label = row.department+':'+int.initiative+':'+proj.project+':'+acc
                                //         structedSearchData.push(searchRow)
                                //     })
                                // }
                            })
                        }
                    })
                }
            })
            setState(prevState => ({ ...prevState, structedSearchData, filteredSearchItem: structedSearchData }))
        }
    }, [state.departmentInitiativeProjectsAccounts])

    useEffect(() => {
        if(state.callDistributionDonutDetails) {
            let donut = []
            let donut_total_count = 0

            let data = state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.data && state.summaryDetails.distribution.data.length ? state.summaryDetails.distribution.data : []

            state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.labels && state.summaryDetails.distribution.labels.forEach((lab, index) => {
                let donutRow = {}
                donutRow.item_name = lab
                donutRow.item_count = data[index] ? data[index] : 0
                donut.push(donutRow)
                donut_total_count += data[index] ? data[index] : 0 
            })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let distributionDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }

            setState(prevState => ({ ...prevState, distributionDonutData, callDistributionDonutDetails: false }));
        }
    }, [state.callDistributionDonutDetails, state.summaryDetails])

    useEffect(() => {
        if(state.callSpendCommitmentDetails) {
            let spendCommitment = state.summaryDetails && state.summaryDetails.spend_vs_commitment ? state.summaryDetails.spend_vs_commitment : {}

            let graphData = {}
            graphData.labels = spendCommitment.dates ? spendCommitment.dates : []
            graphData.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
            spendCommitment.data && Object.entries(spendCommitment.data).length && Object.entries(spendCommitment.data).forEach(([key, value]) => {
                if(key === 'commitment' || key === 'spend') {
                    graphData[key] = value
                }
            })

            let tableDetails = state?.summaryDetails?.table_details?.details || []
            let tableNotes = state?.summaryDetails?.table_details?.note || ''
            
            let tableData = tableDetails && tableDetails.length ? _.orderBy(tableDetails, ['order'], ['asc']) : []
            setState(prevState => ({ ...prevState, spendCommitment: graphData, callSpendCommitmentDetails: false, tableData, tableNotes }))
        }
    }, [state.callSpendCommitmentDetails, state.summaryDetails, state.aggregateBy, state.currencyUnit])

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isSearchOpen: false }));
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const onClickDepartment = (value) => {
        if(state.selectedItem !== value) {
            setState(prevState => ({ ...prevState, previousYear: false, previousMonth: false, searchProvider: [] }))
        }

        if(state.selectedSearchItem !== 'Department') {
            let splitValue = splitString(state.selectedSearchItem, ':')
            let isAccount = splitValue[splitValue.length-1]
            if(isAccount === 'accounts')  {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue.length > 2 ? splitValue[1] : '', selectedProject: splitValue.length > 3 ? splitValue[2] : '', selectedAccount: value, selectedItem: value, callSummaryFunction: true, getUntaggedResourcesDetails: true }))
            } else if(splitValue.length > 2) {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue[1], selectedProject: splitValue[2], selectedAccount: value, selectedItem: value, callSummaryFunction: true, getUntaggedResourcesDetails: true }))
            } else if(splitValue.length > 1) {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue[1], selectedProject: value, selectedAccount: '', selectedItem: value, callSummaryFunction: true }))
            } else {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: value, selectedItem: value, selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true }))
            }
        } else {
            setState(prevState => ({ ...prevState, selectedDepartment: value, selectedItem: value, selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true }))
        }
    }

    const handleBreadCrumb = (type, splIndex) => {
        let selectedSearchItemList = type !== 'Department' ? type.slice(0, splIndex) : type
        let selectedSearchItem = ''
        if(type !== 'Department') {
            selectedSearchItemList.forEach((ser, serIndex) => {
                if(serIndex) {
                    selectedSearchItem += ':'+ser
                } else {
                    selectedSearchItem = ser
                }
            })
        } else {
            selectedSearchItem = type
        }
        setState(prevState => ({ 
            ...prevState,
            graphTopSpenders: {},
            taggedNonTaggedDistribution: {},
            graphOverSpenders: {},
            graphOverSpendersForecast: {},
            selectedSearchItem,
            callDepartmentStructure: true,
        }))
    }

    useEffect(() => {
        let dataResult = state.tableData && state.tableData.length ? state.tableData : [];
        if (dataResult.length) {
            let headers = [];
            let headerRow = { label: 'Label', key: 'label' };
            headers.push(headerRow);

            headerRow = { label: 'Year', key: 'year' };
            headers.push(headerRow);

            state.months.forEach((mnt) => {
                headerRow = { label: mnt.label, key: mnt.label };
                headers.push(headerRow);
            });

            setState(prevState => ({ ...prevState, tableHeaders: headers }));
        }
    }, [state.tableData, state.months]);

    const spendboardExcelExport = async () => {
        const data = state.tableData || [];
        const headers = state.tableHeaders || [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        worksheet.addRow(headers.map(header => header.label));
    
        data.forEach(item => {
            let formattedItem = {};

            if (!item?.split || item?.split === false) {
                formattedItem = { label: item.label, year: item.year };
                if (Array.isArray(item.data)) {
                    state.months.forEach((mnt, index) => {
                        // Replace null or blank values with 0
                        const value = item.data[index];
                        formattedItem[mnt.label] = (value === null || value === undefined || value === '') ? 0 : value;
                    });
                } else {
                    state.months.forEach(mnt => {
                        formattedItem[mnt.label] = 0; // Default to 0 if no data
                    });
                }
            } else {
                formattedItem = { label: item.label };
            }
        const row = worksheet.addRow(Object.values(formattedItem));

        if (formattedItem.label === 'Budget') {
            row.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCCB' } // Light red for highlighting
                };
            });
        }

        const yearIndex = headers.findIndex(header => header.label === 'Year') + 1;
        const yearCell = row.getCell(yearIndex);
        if (yearCell.value === 2024) {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCCB' } // Light purple for 2024
            };
        } else if (yearCell.value === 2023) {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFCC' } // Light yellow for 2023
            };
        } else {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCCCCC' } // Gray for other years
            };
        }

        if (item.right_align) {
            row.getCell(1).alignment = { horizontal: 'right' };
          }
    });

        // Set border style
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const firstColumn = worksheet.getColumn(1);
        data.forEach((item, rowIndex) => {
            const cell = worksheet.getCell(rowIndex + 2, 1);
            if (item.split) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ADD8E6' }
                };
            }
                cell.border = {
                    right: { style: 'thick' }
                };
        });

        // Highlight the first row
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
            cell.border = borderStyle;
        });

        const lastRow = worksheet.lastRow.number;
        const lastColumn = headers.length;

        // Set the border style
        const bottomBorderStyle = {
            bottom: { style: 'thick' }};

        const rightBorderStyle = {
            right: { style: 'thick' }
        };

        for (let col = 1; col <= lastColumn; col++) {
            const cell = worksheet.getCell(lastRow, col);
            cell.border = bottomBorderStyle;
            if (col === 1){
                cell.border = {bottom: { style: 'thick' }, right: { style: 'thick' } }
            }
        }

        // Apply borders to the last column
        for (let row = 2; row <= lastRow; row++) {
            const cell = worksheet.getCell(row, lastColumn);
            cell.border = rightBorderStyle;
            if (row === lastRow){
                cell.border = {bottom: { style: 'thick' }, right: { style: 'thick' } }
            }
        }
        

        firstColumn.width = 30; // Set width of first column
        for (let col = 2; col <= headers.length; col++) {
            worksheet.getColumn(col).width = 10; // Set width for other columns
        }

        const currencyNote = state.summaryDetails?.table_details?.currency_note || '';
        if (currencyNote) {
            worksheet.addRow()
            worksheet.addRow()
            worksheet.addRow(["Note: " + currencyNote]); // Adjust the number of empty strings based on your header count
        }
    
        // Save the workbook
        const fileName = state.summaryDetails?.table_details?.download
            ? `${state.summaryDetails.table_details.download}-budget-spendboard.xlsx`
            : 'budget-spendboard.xlsx';
    
        await workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const untaggedResourceExcelExport = async () => {
        // const data = state.untagggedDownloads || [];
        const headers = state.untaggedHeaders || [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        worksheet.addRow(headers.map(header => header.label));

        // Set border style
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        // Highlight the first row
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
            cell.border = borderStyle;
        });

        // Get the last row with values
        const lastRow = worksheet.actualRowCount;
        const lastColumn = headers.length;

        const rightBorderStyle = {
            right: { style: 'thick' }
        };

        for (let row = 2; row <= lastRow; row++) {
            for (let col = 1; col <= lastColumn; col++) {
                const cell = worksheet.getCell(row, col);
                cell.border = rightBorderStyle;
            }
        }

        for (let col = 1; col <= headers.length; col++) {
            if (col === 2){
                worksheet.getColumn(col).width = 200;
            } else {
                worksheet.getColumn(col).width = 30;
            }
        }

        // Save the workbook
        const fileName = state.untaggedDownlaodLabel ? `${state.untaggedDownlaodLabel+'.xlsx'}` : 'untaggged-resource-downloads.xlsx';

        await workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const automaiotnUpdateStatus = (field) => {
		setState(prevState => ({ ...prevState, [field]: state[field] ? false : true, searchProvider: [], callSearch: true }))
    }

    useEffect(() => {
        if(state.getUntaggedResourcesDetails) {
            setState(prevState => ({ ...prevState, getUntaggedResourcesDetails: false }))
            let params = {
                account_id: state.selectedItem
            }
            dispatch(getUntaggedResources(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        let untaggedHeaders = []
                        if(results.length) {
                            Object.keys(results[0]).forEach(key => {
                                if (key !== "createdBy"){
                                    let headerRow = {}
                                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                                    headerRow.key = key
                                    untaggedHeaders.push(headerRow)
                                }
                            })
                        }
                        setState(prevState => ({ ...prevState, untagggedDownloads: results, untaggedHeaders, untaggedDownlaodLabel: response.download }))
                    }
                })
        }

    }, [state.getUntaggedResourcesDetails, dispatch, state.selectedItem])

    const checkForDoubleClick = (label) => {
        let selectedBar = label

        setClickCount((prevCount) => prevCount + 1);
        // Check if it's a double-click
        if (clickCount === 1) {            
            clearTimeout(callSingleClick)
          // Reset the click count and perform the action for double-click (e.g., open the folder)
          if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
              if(state.selectedSearchItem !== "Department") {
                  selectedBar = state.selectedSearchItem+':'+label
              }
              setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, selectedItem: label, searchProvider: [], callDepartmentStructure: true  }))
          }
          setClickCount(0);
        } else {
            callSingleClick = setTimeout(() => { onClickDepartment(selectedBar) }, 400 )
            setTimeout(() => { setClickCount(0) }, 300)        
        }

    }

    useEffect(() => {
        let datePickerStartDate = momentDateGivenFormat(queryDate[0], 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = momentDateGivenFormat(queryDate[1], 'YYYY-MM-DD 23:59:59')
        setState(prevState => ({
            ...prevState,
            datePickerStartDate,
            datePickerEndDate,
            graphTopSpenders: {},
            taggedNonTaggedDistribution: {},
            graphOverSpenders: {},
            graphOverSpendersForecast: {},
            showCloudProviderSection: false,
            currentObsevations: [],
            searchProvider: [],
            callSearch: true,
            isDurationOpen: false,
            showDateRangePicker: false,
        }));
    }, [queryDate])

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="w-full text-white text-[28px] text-semibold">Budget Status</p>
                        <p className="text-sm text-white">Consolidated view of budgets</p>
                    </div>
                    <div className="lg:w-3/4 w-full items-center flex flex-wrap justify-end">
                        <div className='flex flex-wrap mx-0 justify-end gap-2'>   
                            <div className='flex self-center mr-3 pt-4'>
                                <NewCheckbox
                                    label="Without Tax"
                                    name="without_tax"
                                    labelClass={'text-white'}
                                    className={'self-center'}
                                    selectedValue={state.without_tax}
                                    callback={() => {
                                        automaiotnUpdateStatus('without_tax')
                                    }}
                                />
                                <NewCheckbox
                                    label="Without Savings Plan"
                                    name="without_savings"
                                    className={'ml-3'}
                                    labelClass={'text-white'}
                                    selectedValue={state.without_savings}
                                    callback={() => {
                                        automaiotnUpdateStatus('without_savings')
                                    }}
                                />
                                <NewCheckbox
                                    label="Without Credits & Discounts"
                                    name="without_credits_discounts"
                                    className={'mx-3'}
                                    labelClass={'text-white'}
                                    selectedValue={state.without_credits_discounts}
                                    callback={() => {
                                        automaiotnUpdateStatus('without_credits_discounts')
                                    }}
                                />
                            </div>
                            <SelectOption
                                label={"Provider"}
                                labelClass={{color: 'text-white'}}
                                fields={["provider_name", "provider_name"]}
                                options={state.providers}
                                selectedValues={state.cloudProviderId ? state.cloudProviderId : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, cloudProviderId: value, searchProvider: [], callSearch: true }))
                                }}
                                dropdownWidth={'min-w-32'}
                                classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
                            />
                            <div className='self-end'>
                                <label className='text-white text-base font-medium'>Duration</label>
                                <SmartDateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    setQueryDate={setQueryDate}
                                    queryDate={queryDate}
                                />
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            {state.structedSearchData && state.structedSearchData.length ?
                <div className='self-end' onClick={() => { setState(prevState => ({ ...prevState, isSearchOpen: true}))}} ref={searchRef}>
                    <div className='w-[300px]'>
                        <Search
                            data={state.structedSearchData}
                            topClassName={"border border-NeutralGray-600 rounded-xl bg-white"}
                            className={'rounded-xl'}
                            filteredData={(filteredSearchItem, searchText ) => {
                                setState(prevState => ({ ...prevState, filteredSearchItem: searchText === "" ? state.structedSearchData : filteredSearchItem, emptySearchText: false }))
                            }}
                            resetSearchBox={state.emptySearchText}
                        />
                    </div>

                    {state.isSearchOpen ?
                        <div className={`bg-white border border-byzantineBlue rounded-md shadow-[0_4px_6px_rgba(33,37,41,0.2),_0_0_1px_rgba(33,37,41,0.32)] z-10 min-w-[220px] max-h-[300px] absolute overflow-auto mt-3`}>
                            {state.filteredSearchItem && state.filteredSearchItem.map((ser, sIndex) => {
                                return(
                                    <div key={'drop_'+sIndex}>
                                        <div className={`flex py-1 optionDiv cursorPointer ${state.selectedSearchItem === ser.label ? 'bg-primaryPurple-600 text-black' : 'bg-white text-black'}`} 
                                            onClick={() => setState(prevState => ({ ...prevState, searchProvider: state.providers.map(e => e.provider_name.toLowerCase()), graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: ser.label, callDepartmentStructure: true, emptySearchText: true, previousMonth: false, previousYear: false }))}
                                        >
                                            <p className={`ml-1 mb-0 text-left text-black`}>{ser.label}</p>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    : null}
                </div>
            : null}
            {state.selectedSearchItem === 'Provider' && state.showCloudProviderSection ?
                <div className='mt-2'>
                    <CloudCommitmentSpend
                        currencyUnit={state.currencyUnit}
                        currencyTypes={state.currencyTypes}
                        datePickerStartDate={state.datePickerStartDate}
                        datePickerEndDate={state.datePickerEndDate}
                        without_tax = {state.without_tax}
                        without_savings = {state.without_savings}
                        without_credits_discounts = {state.without_credits_discounts}
                        updateCurrency={(currencyUnit) => setState(prevState => ({ ...prevState, currencyUnit }))}
                    />
                </div>
            : state.selectedSearchItem !== 'Provider' ?
                <React.Fragment>
                    <div className='flex justify-between'>
                        <div className='w-1/2'>
                            <nav>
                                <ol className="flex breadcrumb bg-transparent mb-0">
                                    <li className="text-lg cursor-pointer text-black font-bold">Organization</li>
                                    {state.splitState && state.splitState.length >= 1 && state.splitState[0] !== 'Department' ?
                                        <React.Fragment>
                                        <li className='mx-1 text-black font-bold text-lg'>/</li>
                                        <li className="cursor-pointer text-black font-bold text-lg self-end" onClick={() => handleBreadCrumb('Department')}>Departments</li>
                                        </React.Fragment>
                                    : null}
                                    {state.splitState && state.splitState.length ?
                                        state.splitState.map((spl, splIndex) => {
                                            return(
                                                spl !== 'accounts' ?
                                                    <React.Fragment key={'spl_'+splIndex}>
                                                        <li  className='mx-1 text-black font-bold text-lg self-end'>/</li>
                                                        <li key={'split_'+splIndex} className={`font-bold text-lg self-end ${state.splitState[splIndex+1] && state.splitState[splIndex+1] !== 'accounts' ? 'cursor-pointer text-black' : 'active text-byzantineBlue'}`}
                                                        onClick={() => handleBreadCrumb(state.splitState, splIndex+1)}
                                                        >
                                                            {spl === 'Department' ? 'Departments' : spl}
                                                        </li>
                                                    </React.Fragment>
                                                : null
                                            )
                                        })
                                    : null}
                                </ol>
                            </nav>
                        </div>
                        <div className="w-1/2 items-center flex flex-wrap justify-end self-end">
                            <div className='flex w-full justify-end'>
                                <span className="mr-3 self-center text-sm font-medium text-gray-900 dark:text-gray-300 text-black">Currency is in</span>
                                <SelectOption
                                    label={""}
                                    options={state.currencyTypes}
                                    selectedValues={state.currencyUnit ? state.currencyUnit : []}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, currencyUnit: value, showLoading: true, callSummaryFunction: true, callTopGraphSection: true, callCloudProviderDetailApi: true }))
                                    }}
                                    singleSelection={true}
                                    makeLabelUpperCase={'capitalizeAllLetter'}
                                    dropdownWidth={'min-w-20'}
                                    classDetails={{common:'uppercase', padding: "py-0 px-2", bg: 'bg-white', rounded: 'rounded-full', optionPosition: 'right-0', optionWidth: 'min-w-25' }}
                                />  
                            </div>
                            {state.conversionDetails ?
                                <div className='flex mb-1 text-right text-black text-xs w-full flex justify-end'>{state.conversionDetails}</div>
                            : null}
                        </div>
                    </div>
                    {state.selectedSearchItem !== 'Provider' && state.currentObsevations && Object.entries(state.currentObsevations).length ? 
                        <div className='bg-white rounded-2xl border border-DeepPurpleTints-600 mt-4 p-6'>
                            <p className='mb-1 text-[22px] font-bold text-black'>Cloud Provider Commitment Observations</p>
                            <div className='flex justfy-between'>
                                {Object.entries(state.currentObsevations).map(([oKey, oValue], oIndex) => {
                                    return(
                                        <div className={`text-base font-medium rounded-md w-full pl-6`} key={'obs_'+oIndex}>
                                            <ul className={`list-disc`}>
                                                {oValue.map((obs, obsIndex) => {
                                                    return(
                                                        <li key={'obs_'+obsIndex} className="list-group-item bg-transparent text-lightGray pr-0 custom-list-group-item pt-3">{obs}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    : null}                    

                    <div className='pt-8'>
                        <div className='bg-white rounded-2xl border border-DeepPurpleTints-600 p-6'>
                            <p className='text-[22px] font-bold uppercase'>Overview</p>
                            {state.topSpenders && state.topSpenders.note ?
                                <p className='pt-4 pb-8 text-sm'>Note: <span className='text-black'>{state.topSpenders.note}</span></p>
                            : null}
                            <div className='flex flex-wrap'>
                                {state.graphTopSpenders && Object.entries(state.graphTopSpenders).length ?
                                    <div className={`lg:w-1/3 md:w-1/2 w-full md:pr-3`}>
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                            <div className=''>
                                                <p className='text-lg text-medium text-black'>{state.topSpenders && state.topSpenders.title ? state.topSpenders.title : 'Top 5 High Spenders'} </p>
                                                <p className='text-sm text-lightGray'>{state.topSpenders && state.topSpenders.description ? state.topSpenders.description : <span>&nbsp;</span> }</p>
                                            </div>

                                            {state.selectedAccount ?
                                                <div className='mb-4'>
                                                    <ApexHorizontalStackedBarChart
                                                        graphData={state.graphTopSpenders}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'categoryString'}
                                                        xaxisLabel={true}
                                                        showTitle={true} //xaxis title
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={true}
                                                        height={300}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        columnWidth={'25%'}
                                                        gradient={false}
                                                        gradientColor={['#009fdf', '#CD5A9F']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels= {true}
                                                        dataLabelsTextAnchor = {'end'}
                                                        dataLabelPosition={'right'}
                                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                        dataLabelsOffsetX={10}
                                                        chartDistributed={true}
                                                        dataPointSelection={true}
                                                        tooltipFixed={'left'}
                                                        hideTotal={true}
                                                        selectedGraphData={(item) => {
                                                            let selectedBar = item
                                                            if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                                if(state.selectedSearchItem !== "Department") {
                                                                    selectedBar = state.selectedSearchItem+':'+item
                                                                }
                                                                setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            :           
                                                <div className='mb-4'>
                                                    <ApexBarChart
                                                        graphData={state.graphTopSpenders}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        showTitle={true} //xaxis title
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={290}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        columnWidth={'25%'}
                                                        gradient={false}
                                                        gradientColor={['#009fdf', '#CD5A9F']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels= {true}
                                                        dataLabelsTextAnchor = {'end'}
                                                        dataLabelPosition={'right'}
                                                        colors={['#009FDF']}
                                                        dataLabelsOffsetX={0}
                                                        chartDistributed={true}
                                                        dataPointSelection={true}
                                                        selectedGraphData={(item) => {
                                                            let selectedBar = item
                                                            if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                                if(state.selectedSearchItem !== "Department") {
                                                                    selectedBar = state.selectedSearchItem+':'+item
                                                                }
                                                                setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                : null}
                            
                                {!state.selectedAccount && state.graphOverSpenders && Object.entries(state.graphOverSpenders).length ?
                                    <div className='lg:w-1/3 md:w-1/2 w-full md:pl-3' id="overSpending">
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                            <div className=''>
                                                <p className='text-lg text-medium text-black'>{state.topOverSepnders && state.topOverSepnders.title ? state.topOverSepnders.title : 'Top 5 High Spenders'} </p>
                                                <p className='text-sm text-lightGray'>{state.topOverSepnders && state.topOverSepnders.description ? state.topOverSepnders.description : <span>&nbsp;</span> }</p>
                                            </div>
                                            <div className='mb-3'>
                                                <ApexNonStackedBarChart 
                                                    graphData={state.graphOverSpenders}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    yaxisTitle={'Percentage'}
                                                    xaxis={false}
                                                    xaxisFormat={'categoryString'}
                                                    xaxisLabel={false}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={15}
                                                    legend={true}
                                                    stacked={false}
                                                    height={270}
                                                    horizontal={false}
                                                    // barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'55%'}
                                                    gradient={true}
                                                    stroke={'hide'}
                                                    // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                    // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                    colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            
                                {state.graphOverSpendersForecast && Object.entries(state.graphOverSpendersForecast).length ?
                                    <div className='lg:w-1/3 md:w-1/2 w-full lg:pl-3' id="forecastSpending">
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                            <div className=''>
                                                <p className='text-lg text-medium text-black'>{state.overSepndersForecast && state.overSepndersForecast.title ? state.overSepndersForecast.title : 'Top 5 High Spenders'} </p>
                                                <p className='text-sm text-lightGray'>{state.overSepndersForecast && state.overSepndersForecast.description ? state.overSepndersForecast.description : <span>&nbsp;</span> }</p>
                                            </div>

                                            <div className='mb-4'>
                                                <ApexBarChart
                                                    graphData={state.graphOverSpendersForecast}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    xaxis={true}
                                                    xaxisFormat={'string'}
                                                    xaxisLabel={true}
                                                    showTitle={true} //xaxis title
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={true}
                                                    stacked={false}
                                                    height={290}
                                                    horizontal={true}
                                                    barHeight={'40%'}
                                                    barEndShape={'rounded'}
                                                    columnWidth={'25%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'rounded'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {true}
                                                    dataLabelsTextAnchor = {'end'}
                                                    dataLabelPosition={'right'}
                                                    colors={['#009FDF']}
                                                    dataLabelsOffsetX={0}
                                                    chartDistributed={true}
                                                    dataPointSelection={true}
                                                    selectedGraphData={(item) => {
                                                        let selectedBar = item
                                                        if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                            if(state.selectedSearchItem !== "Department") {
                                                                selectedBar = state.selectedSearchItem+':'+item
                                                            }
                                                            setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : null}

                                {state.selectedAccount ?
                                    <div className='lg:w-1/3 md:w-1/2 w-full md:pl-3 flex flex-col flex-grow' id="taggedDistribution">
                                        <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                            <p className='text-lg text-medium text-black w-full'>Tagged Non-tagged Distribution</p>
                                            <div className='flex justify-center'>
                                                {state.taggedNonTaggedDistribution && Object.entries(state.taggedNonTaggedDistribution).length ?
                                                    <ApexDonutChartNew
                                                        labelColor={"#000000"}
                                                        valueColor={"#000000"}
                                                        labelSize={"16px"}
                                                        valueSize={"16px"}
                                                        graphData={state.taggedNonTaggedDistribution}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        showGraphHeading={false}
                                                        height={300}
                                                        width={300}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        // gradientColor={['#A88CCC', '#D88ACF']}
                                                        colors={["#8E3A80", "#873B8C", "#7F3C98", "#783CA4", "#703DB0", "#693EBC"]}
                                                        className={'transparentTooltip'}
                                                    />
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-wrap pt-8'>
                        <div className='lg:w-1/4 md:w-1/2 w-full md:pr-2 flex flex-col flex-grow'>
                            <div className="bg-DeepPurpleTints-600 rounded-2xl h-full">
                                <div className="p-3">
                                    <p className="font-semibold text-sm text-white pb-3">Showing {state.filteredListItems && state.filteredListItems.length} of Total {state.listItems && state.listItems.length} {state.selectedSearchItem === 'Department' ? 'Departments' : (splitString(state.selectedSearchItem, ':').length > 2 ? 'Accounts' : splitString(state.selectedSearchItem, ':').length > 1 ? 'Projects' : 'Initiatives')}</p>

                                    {state.listItems && state.listItems.length ?
                                        <Search
                                            data={state.listItems}
                                            className={'rounded-full'}
                                            filteredData={(filteredListItems) => setState(prevState => ({ ...prevState, filteredListItems }))}
                                        />
                                    : null}
                                    
                                    {state.filteredListItems && state.filteredListItems.length ? 
                                        state.filteredListItems.map((item, i) => {
                                            return(
                                                <div key={'dep_'+i} className={`bg-NeutralGray-200 ${state.selectedItem === item.label ? 'border-2 border-byzantineBlue' : ''} rounded-md cursor-pointer mt-4 p-2`} 
                                                    onClick={() => {
                                                        checkForDoubleClick(item.label)
                                                    }}
                                                >
                                                    <div className={`flex flex-wrap justify-between`}>
                                                        <div className="w-full self-center">
                                                            <h6 className={`font-semibold text-nowrap truncate text-base ${state.selectedItem === item.label ? ' text-byzantineBlue' : 'text-black'}`}>{item.label}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                        <div className='flex justify-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your search.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-3/4 md:w-1/2 w-full md:pl-2 flex flex-col flex-grow rounded-2xl h-full' id='summaryDetails'>
                            <div className='bg-white rounded-2xl p-6'>
                                {state.showSummaryLoading ? 
                                    <div className='flex justify-center self-center mt-5 pt-5'>
                                        <LoadingCircle />
                                    </div>
                                :
                                    <React.Fragment>
                                    <div className='flex flex-wrap'>
                                        <div className='lg:w-1/5 w-full flex flex-col flex-grow mb-2'>
                                            <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                                <p className='text-lg text-medium text-black w-full'>{state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.title ? state.summaryDetails.distribution.title : 'Spend Distribution'}</p>
                                                <div className='flex justify-center self-center mt-3'>
                                                    {state.distributionDonutData && Object.entries(state.distributionDonutData).length ?
                                                        <ApexDonutChartNew
                                                            labelColor={"#000000"}
                                                            valueColor={"#000000"}
                                                            labelSize={"16px"}
                                                            valueSize={"16px"}
                                                            graphData={state.distributionDonutData}
                                                            legend={false}
                                                            showTotal={true}
                                                            showTotalLable={true}
                                                            showGraphHeading={false}
                                                            legendWidth={200}
                                                            size={'70%'}
                                                            gradient={false}
                                                            colors={["#fb6d23", "#f9b133", "#55ab38", "#2d60a6", "#319795", "#693EBC"]}
                                                            className={'transparentTooltip'}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='lg:w-2/5 w-full lg:pl-3 flex flex-col flex-grow mb-2'>
                                            <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                                <p className='text-lg text-medium text-black w-full'>Monthly Spend {state.selectedAccount ? '' : 'vs Commitment'}</p>
                                                {state.spendCommitment && Object.entries(state.spendCommitment).length ? 
                                                    <div className='mt-n2 mb-n3 w-full'>
                                                        <ApexNonStackedBarChart 
                                                            graphData={state.spendCommitment}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'datetime'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#666666'}
                                                            paddingLeft={-5}
                                                            legend={true}
                                                            stacked={false}
                                                            height={270}
                                                            horizontal={false}
                                                            // barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'55%'}
                                                            gradient={true}
                                                            stroke={'hide'}
                                                            // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                            // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                            colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        <div className='lg:w-2/5 w-full lg:pl-3 flex flex-col flex-grow mb-2'>
                                            <div className='bg-NeutralGray-100 border border-NeutralGray-500 px-6 rounded-2xl h-full'>
                                                <div className='flex flex-wrap'>
                                                    <div className='py-2 w-1/2 px-2'>
                                                        <p className='text-black text-center text-lg font-medium mb-1'>YTD</p>
                                                        <p className='text-center flex justify-between font-bold text-lg'>
                                                            <span className='font-semibold text-base text-NeutralGray-600'>Budget</span> 
                                                            {state.summaryDetails && state.summaryDetails.ytd_budget ? thousandSeparator(state.summaryDetails.ytd_budget) : 0}
                                                        </p>
                                                        <p className='text-center flex justify-between font-bold text-lg'>
                                                            <span className='font-semibold text-lg text-NeutralGray-600'>Spend</span> 
                                                            {state.summaryDetails && state.summaryDetails.ytd ? thousandSeparator(state.summaryDetails.ytd) : 0}
                                                        </p>
                                                    </div>
                                                    <div className='p-2 w-1/2 border-l border-NeutralGray-600'>
                                                        <p className='text-black text-center text-lg font-medium mb-1'>MTD</p>
                                                        <p className='text-center flex justify-between font-bold text-lg'>
                                                            <span className='font-semibold text-base text-NeutralGray-600'>Budget</span> 
                                                            {state.summaryDetails && state.summaryDetails.mtd_budget ? thousandSeparator(state.summaryDetails.mtd_budget) : 0}
                                                        </p>
                                                        <p className='text-center flex justify-between font-bold text-lg'>
                                                            <span className='font-semibold text-lg text-NeutralGray-600'>Spend</span>
                                                            {state.summaryDetails && state.summaryDetails.mtd ? thousandSeparator(state.summaryDetails.mtd) : 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                {state.summaryDetails && state.summaryDetails.observations ?
                                                    <div className='pt-8 border-t border-NeutralGray-600'>
                                                        <p className='text-black text-lg font-medium'>Observations</p>
                                                        <ul className="list-disc pl-5">
                                                            {state.summaryDetails.observations.map((obs, oIndex) => {
                                                                return(
                                                                    <li key={'obs_'+oIndex} className="pl-3 text-sm text-lightGray">{obs}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='w-full oveflow-auto'>
                                        <div className='flex flex-wrap justify-between'>
                                            <div className='w-1/2'>
                                                <p className='mb-0 lg:w-1/2 w-full text-lg font-medium'>Budget vs Spending vs Forecast</p>
                                                {state.tableNotes ? (
                                                    <p className='mb-1'>
                                                        Note: <span className='text-black'>{state.tableNotes}</span>
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className='flex justify-end lg:w-1/2 w-full lg:mt-0 mt-2'>
                                                <SelectOption
                                                    label={""}
                                                    options={['Compare Previous Month', 'Compare Previous Year']}
                                                    selectedValues={state.selectedComparision ? state.selectedComparision : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            previousMonth: value === 'Compare Previous Month' ? true : false,
                                                            previousYear: value === 'Compare Previous Year' ? true : false,
                                                            selectedComparision: value
                                                        }))
                                                    }}
                                                    dropdownWidth={'min-w-36'}
                                                    classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-primaryPurple-100', rounded: 'rounded-full', border: 'border border-primaryPurple-600', optionPosition: 'right-0' }}
                                                />
                                                <div className='flex self-end'>
                                                    {state.selectedAccount && state.untagggedDownloads && state.untagggedDownloads.length ?
                                                        <div className="relative group cursor-pointer inline-block" onClick={untaggedResourceExcelExport}>
                                                            <Icon icon="charm:download" className='ml-3 text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center self-end' width={24} height={24} />
                                                            <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm px-2 py-1 rounded">
                                                                Untagged Resources
                                                            </div>
                                                        </div>
                                                    : null}
                                                    {state.tableData && state.tableData.length ? (
                                                        <div className="relative group cursor-pointer inline-block" onClick={spendboardExcelExport}>
                                                            <Icon icon="charm:download" className='ml-3 text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center self-end' width={24} height={24} />
                                                            
                                                            <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm px-2 py-1 rounded">
                                                                Cost Summary
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    
                                        {state.showBreakup ?
                                            <BreakupDetails
                                                data={state.breakupDetails}
                                                dates={state?.summaryDetails?.table_details?.dates || []}
                                                selectedProject={state.selectedProject}
                                                billingUnit={state.selectedAccount}
                                                department={state.selectedDepartment}
                                                closeSidePanel={() => setState(prevState => ({...prevState, showBreakup: false }))}
                                            />
                                        : null}

                                        <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg mt-6">
                                            <table className="w-full text-left">
                                                <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                                    <tr>
                                                        <th scope="col" className="tracking-wider py-2 pl-4 text-left">&nbsp;</th>
                                                        {state.months.map(mnt => {
                                                            return(
                                                                <th key={'mnt_'+mnt.value} className='tracking-wider py-2 pl-4 text-left'>{mnt.label}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className='text-sm text-NeutralGray-900'>
                                                    {state.tableData && state.tableData.length ?
                                                        state.tableData.map((row, rIndex) => {
                                                            return(
                                                                row.year && (!row.previous_year_data || (row.previous_year_data && state.previousYear)) ?
                                                                    <tr key={'tab_'+rIndex} className={`text-base ${rIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                        <td className={`flex py-2.5 pl-4 text-left`}>
                                                                            {!row.split ?
                                                                                <span className='mr-2 text-slateBlue-600'>({row.year ? row.year : momentDateGivenFormat(state.datePickerEndDate, 'YYYY')})</span>
                                                                            : null}

                                                                            <span className={`${row.split ? 'text-byzantineBlue text-lg text-nowrap' : 'text-nowrap'} ${row.breakup && Object.entries(row.breakup).length ? 'text-byzantineBlue cursor-pointer' : ''}`} 
                                                                                onClick={() => {
                                                                                    if(row.breakup && Object.entries(row.breakup).length) {
                                                                                        setState(prevState => ({ ...prevState, breakupDetails: row, showBreakup: true }))
                                                                                    }
                                                                                }}
                                                                            >{row.label ? row.label : "Heading"}</span>
                                                                            {row.description ?
                                                                                <span className='self-center ml-1' id={'desc_info_'+rIndex}>
                                                                                    <Icon icon="fa:info-circle" className="text-byzantineBlue self-center cursor-pointer" />
                                                                                    <UncontrolledTooltip target={'desc_info_'+rIndex}>
                                                                                        <div className='bg-white text-black p-2 rounded-2xl shadow break-all'>
                                                                                            <div key={rIndex} className={`py-2 text-black break-all max-w-[350px]`}>
                                                                                                <ul className="pl-2 mb-0 f12 text-NeutralGray-600 font-semibold">
                                                                                                    {Array.isArray(row.description) ?
                                                                                                        row.description.map((des, desIndex) => {
                                                                                                            return(
                                                                                                                <li key={"des_"+desIndex}className='mb-2'> {des}</li>
                                                                                                            )
                                                                                                        })
                                                                                                    : 
                                                                                                        row.description
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </UncontrolledTooltip>
                                                                                </span>
                                                                            : null}
                                                                        </td>
                                                                        
                                                                        {row.data && row.data.length ?
                                                                            state.months.map((mnt, dIndex) => { 
                                                                                return(
                                                                                    <td key={'tab_'+rIndex+'_'+dIndex} className='py-2.5 px-4 text-left'>
                                                                                        <div key={'table_'+rIndex+'_'+dIndex} className='justify-end flex self-center text-black'>
                                                                                            {row.data[dIndex] ? thousandSeparator(row.data[dIndex]) : (typeof row.data[dIndex] === 'undefined' ? '' :  '-')}
                                                                                        </div>
                                                                                        {state.previousMonth && row.spend_comparison_with_previous_period && row.spend_comparison_with_previous_period[dIndex] ?
                                                                                            row.spend_comparison_with_previous_period[dIndex].percentage ?
                                                                                                <p className={`flex text-xs mb-0 text-black pt-1`}>
                                                                                                    {row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}

                                                                                                    <Icon icon={`typcn:arrow-${row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}`} className={`self-center ${row.spend_comparison_with_previous_period[dIndex].increase ? 'text-ferrariRed-600' : 'text-green-600'}`} width={16} height={16} /> 
                                                                                                    <span className={`${row.spend_comparison_with_previous_period[dIndex].increase ? 'text-ferrariRed-600' : 'text-green-600'}`}>
                                                                                                    {row.spend_comparison_with_previous_period[dIndex].percentage+'%'}</span>
                                                                                                </p>
                                                                                            : null
                                                                                        : state.previousYear && row.spend_comparison_with_previous_year && row.spend_comparison_with_previous_year[dIndex] ?
                                                                                            row.spend_comparison_with_previous_year[dIndex].percentage ?
                                                                                                <p className={`flex text-xs mb-0 text-black w-100 pt-1`}>
                                                                                                    {row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}
                                                                                                    <Icon icon={`typcn:arrow-${row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}`} className={`self-center ${row.spend_comparison_with_previous_year[dIndex].increase ? 'text-ferrariRed-600' : 'text-green-600'}`} width={16} height={16} />
                                                                                                    <span className={`text-${row.spend_comparison_with_previous_year[dIndex].increase ? 'text-ferrariRed-600' : 'text-green-600'}`}>{row.spend_comparison_with_previous_year[dIndex].percentage+'%'}</span>
                                                                                                </p>
                                                                                            : null
                                                                                        : null}
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        : 
                                                                            <td colSpan={12}>&nbsp;</td>
                                                                        }
                                                                    </tr>
                                                                : null
                                                            )
                                                        })
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>  
                </React.Fragment>
            : null}
        </div>
    );
};

export default LandingPage