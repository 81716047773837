/*************************************************
 * Tvastar
 * @exports
 * @file QueryEvents.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { UncontrolledTooltip } from 'reactstrap'
import { getDayFromSelectedDuration, momentDateGivenFormat, currentLocaltime, subHours, subDays, momentConvertionLocalToUtcTime, alphaNumeric, defaultDateRangeValue } from '../../../utils/utility'
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchWithHiddenInput'	
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { startQueryExection, setFavouriteQuery, unsetFavouriteQuery } from '../../../actions/QueryAction'
import { getCostQueries, getCostQueryResults } from '../../../actions/cost/CostQueryAction'

import { dynamicCallApi } from '../../../actions/DynamicLoadAction'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import _ from 'lodash'

import { Store as CommonNotification } from 'react-notifications-component';

import QueryResults from './QueryResults'
import { useCallback } from 'react';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import SmartDateRangePicker from '../../common/SmartDateRangePicker';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Textbox from '../../common/Form/Textbox';

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+3h', option: 'Last 3 hours' }, 
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

const QueryEvents = () => {
    const clickOutside = useRef()
	const dateRef = useRef();
	const daterRangeListRef = useRef();

    // State variables for filters
    const [state, setState] = useState({
		listLoading: true,

		selectedProvider: "",
		selectedAccount: [],
		selectedRegion: [],
		selectedDuration: "+7d",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

		selectedCategory: "All",
	})

	const [dateRange, setDateRange] = useState(defaultDateRangeValue());
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue());
	
	const observabilityQueryList = useSelector(state => state.observability.observabilityQueryList)
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false }))}, 3000);
    }, [])
  
    useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '' }));
                    }
            })
        }
    }, [dispatch, providers]);

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		if(state.listLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, listLoading: false }))}, 3000);
		}
	}, [state.listLoading])
	
	useEffect(() => {
		if(state.callGetQueries && state.selectedProvider) {
			let params = {}
			params.aggregate_by = ["category", "sub_category"]
			params.provider = state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount.filter(e => e !== "All")
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion.filter(e => e !== "All")
			}
			dispatch(getCostQueries(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, queryListJson: response, listLoading: false, formQueryList: true, callGetQueries: false }))
					} else {
						setState(prevState => ({ ...prevState, queryListJson: [], listLoading: false, callGetQueries: false }))
					}
				})
		}
	}, [state.callGetQueries, dispatch, observabilityQueryList, state.selectedAccount, state.selectedProvider, state.selectedRegion])

	useEffect(() => {
		if(state.formQueryList && state.queryListJson && state.queryListJson.length) {
			let catArray = []
			let category = []
			state.queryListJson.forEach((item, index) => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
					categories.push(dataRow)
				})
				
			categories = _.orderBy(categories, ['count'], ['desc'])

			
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""
			categories.unshift(dataRow)
	
			setState(prevState => ({ ...prevState, totalQueryList: state.queryListJson, queryList: state.queryListJson, categories, getAllQueries: true }))
		}
	}, [state.formQueryList, state.queryListJson])

	useEffect(() => {
		if(state.filterQueryList) {
			let data = state.totalQueryList
			if(state.selectedSubCategory && state.selectedSubCategory !== "") {
				data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
			} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, filterQueryList: false }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.selectedSubCategory, state.totalQueryList])

	useEffect(() => {
		if(state.getAllQueries) {
			let data = state.totalQueryList	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, getAllQueries: false }))
		}
	}, [state.getAllQueries, state.perPage, state.totalQueryList])
	
	const handleMultiSelectChange = (field, arrayValue, stateOptions) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === "selectedQueryAccount") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else if(field === 'selectedRegion' || field === "selectedQueryRegion") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else {
			//for array			
			if(stateOptions) {
				let prevState = state[field] ? state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(stateOptions.filter(e => e.value).length) {
							selectedValue = stateOptions.map(e => e.value)
						} else {
							selectedValue = stateOptions
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (stateOptions.length -1)) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		}
	}

    useEffect(() => {
        let datePickerStartDate = momentDateGivenFormat(queryDate[0], 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = momentDateGivenFormat(queryDate[1], 'YYYY-MM-DD 23:59:59')
        setState(prevState => ({
            ...prevState,
            datePickerStartDate,
            datePickerEndDate,
			startTime: datePickerStartDate, 
			endTime: datePickerEndDate,
			showDateRangePicker: false,
			isDurationOpen: false,
			callGetQueries: true
        }));
    }, [queryDate])

	const handleChildClick = (event, type, dropdownType, section) => {	
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}
		if (dateRef.current && !dateRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		if (daterRangeListRef.current && !daterRangeListRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
    const navigatePage = (action, currentPage) => {		
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

	const querySelection = (i, item) => {
		if(state.selectedQuery !== item) {
			if(item.display_output && Object.entries(item.display_output).length) {
				Object.values(item.display_output).forEach(out => {
					if(out.default) {
						setState(prevState => ({ ...prevState, [item.query_id+"_"+out.output_field]: out.default }))
					}
					if(out.api_details && Object.entries(out.api_details).length) {
						let apiDetails = out.api_details
						let params = apiDetails.api_request
						if(apiDetails.additional_fields && apiDetails.additional_fields) {
							apiDetails.additional_fields.forEach(addFields => {
								if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
									params.account_id = state.selectedAccount
								}
								if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
									params.region = state.selectedRegion
								}
							})
						}
						let manditoryFieldsFilled = true
						if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
							apiDetails.mandatory_fields.forEach(manFields => {
								if(!state[item.query_id+"_"+manFields] || state[item.query_id+"_"+manFields] === "" || (Array.isArray(state[item.query_id+"_"+manFields]) && !state[item.query_id+"_"+manFields].length)) {
									manditoryFieldsFilled = false
								} else {
									params[manFields] = state[item.query_id+"_"+manFields]
								}
							})
						}
						if(manditoryFieldsFilled) {
							dynamicCallApiFunction(params, item, apiDetails, out.output_field, out.output_type)
						}
					} else if(out.type === "dropdown" && out.output_type === "list") {
						let options = ["All"]
						options =  options.concat(out.values)

						setState(prevState => ({ ...prevState, [item.query_id+"_drop_options_"+out.output_field]: options }))

						handleMultiSelectChange(item.query_id+"_"+out.output_field, options)
					}
				})
			}

			let selectedQueryIds = []
			let required_fields = item.required_fields ? item.required_fields : []
			required_fields.forEach(row => {
				selectedQueryIds.push(item.query_id+'_'+row)
			})

			setState(prevState => ({ 
				...prevState, 
				queryErrorMessage: "",
				selectedQuery: item,
				selectedQueryDuration: state.selectedDuration,
				queryStartTime: state.startTime,
				queryEndTime: state.endTime,
				showQueryDateTimePicker: false,
				hasError: false,
				formQueryBuilder: true,
				selectedQueryIds
			}))
		} else {
			setState(prevState => ({ ...prevState, selectedQuery: "", hasError: false }))
		}
	}

	useEffect(() => {
		if(state.formQueryBuilder) {
			let querybuilder = []
			let selectedQuery = state.selectedQuery
			let query = selectedQuery.display_query
			if(query) {
				let splitString = query.split("$")
				let re = /{(.*?)}/;
				splitString.forEach(str => {
					let result = str.split(re);
					if(result.length === 1) {
						let dataRow = {}
						dataRow.input = false
						dataRow.string = result[0]
						querybuilder.push(dataRow)
					} else {
						result.forEach((item, index) => {
							if(item !== "") {
								if(index === 1) {
									let dataRow = {}
									dataRow.input = true
									dataRow.field = item
									dataRow.string = result[0]
									querybuilder.push(dataRow)
								} else {
									let dataRow = {}
									dataRow.input = false
									dataRow.string = item
									querybuilder.push(dataRow)
								}
							}
						})
					}
				})
				setState(prevState => ({ ...prevState, querybuilder }))
			}
		}
	}, [state.formQueryBuilder, state.selectedQuery])

	useEffect(() => {
		if(state.getQueryDurationDetails) {

			let duration = state.selectedQueryDuration
			let period = duration.substring(duration.length, duration.length-1)
			let durationBtw = getDayFromSelectedDuration(duration)
			let startTime = ""
			if(period === "d") {
				startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			} else {
				startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			}
			
			let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		
			setState(prevState => ({ ...prevState, queryStartTime: startTime, queryEndTime: endTime, getQueryDurationDetails: false }))
		}
	}, [state.getQueryDurationDetails, state.selectedQueryDuration])
	
	const handleStartTimeChange = (date) => {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		setState(prevState => ({ ...prevState, queryStartTime, queryEndTime}))
	}

	const handleEndTimeChange = (date) => {
		let queryStartTime = state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			setState(prevState => ({ ...prevState, queryEndTime}))
		}
	}

	const getQueryResultFunction = useCallback((params) => {
		dispatch(getCostQueryResults(params))
			.then((response) => {
				if(response) {
					if(response.status === "RUNNING") {
						setTimeout(() => { getQueryResultFunction(response) }, 3000)
					} else if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						setTimeout(() => {
							let results = response.results && response.results.length ? response.results : []
							if(results.length) {
								setState(prevState => ({ ...prevState, queryResult: response, queryLoading: false, showQueryResult: true, executeQuery: false }))
							} else {
								let dataRow = {
									message: "No records to show for the selected criteria"
								}
								setState(prevState => ({ ...prevState, queryErrorMessage: dataRow, queryLoading: false, executeQuery: false }))
							}
						}, 2000)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false }))
				}
			})
	}, [dispatch])

	const startQueryExectionFunction = useCallback((params) => {
		dispatch(startQueryExection(params))
			.then((response) => {
				if(response) {
					if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						getQueryResultFunction(response)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
				}
			})
	}, [dispatch, getQueryResultFunction])

	useEffect(() => {
		if(state.executeQuery) {
			let selectedQuery  = state.selectedQuery
			let hasError = false
	
			let params = {}
			params.query_id = selectedQuery.query_id
			params.selectedCategory = state.selectedCategory && state.selectedCategory !== "All" ? state.selectedCategory : selectedQuery.category
			
			if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
				selectedQuery.required_fields.forEach(item => {
					if(item === "account_id" && state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length && !state[state.selectedQuery.query_id+"_selectedQueryAccount"].includes("All")) {
						// params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"].filter(e => e !== "All")
						params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"]
					} else if(item === "account_id")  {
						params.account_id = "ALL"
					}
					if(item === "region" && state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length && !state[state.selectedQuery.query_id+"_selectedQueryRegion"].includes("All")) {
						params.region = state[state.selectedQuery.query_id+"_selectedQueryRegion"]
					} else if(item === "region")  {
						params.region = "ALL"
					}
					if(item === "duration") {
						params.start_time = momentConvertionLocalToUtcTime(state.queryStartTime, "YYYY-MM-DD HH:mm:00")
						params.end_time = momentConvertionLocalToUtcTime(state.queryEndTime, "YYYY-MM-DD HH:mm:00")
					}
				})
			}
	
			if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
				Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
					if(value.type === "text") {
						if(value.output_type === "string") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || state[state.selectedQuery.query_id+"_"+value.output_field] === "") {
								hasError = true
							} else if(value.validation && Object.entries(value.validation).length) {
								let validation = value.validation
								if(validation.min && state[state.selectedQuery.query_id+"_"+value.output_field].length < validation.min) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else if(validation.max && state[state.selectedQuery.query_id+"_"+value.output_field].length > validation.max) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else {
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: "" }))
									params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
								}
							} else {
								params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
							}
						} else if(value.output_type === "list") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || !state[state.selectedQuery.query_id+"_"+value.output_field].length) {
								hasError = true
							} else {
								if(value.api_details && value.include_all) {
									if(state[state.selectedQuery.query_id+"_"+value.output_field] && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										params[value.output_field] = ["All"]
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								} else {
									if(state[state.selectedQuery.query_id+"_"+value.output_field].length > 1 && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										let data = state[state.selectedQuery.query_id+"_"+value.output_field]
										params[value.output_field] = data.filter(e => e !== 'All')
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								}
							}
						}
					} else {
						let output = state[state.selectedQuery.query_id+"_"+value.output_field]
						// if(value.output_type === "list") {
						// 	output = state[value.output_field]
						// }
						if(value.output_type === "list") {
							if(!output || !output.length ) {
								hasError = true
							}
						} else {
							if(!value || value === "") {
								hasError = true
							}
						}
						if(output.includes("All") && value.output_type === "list") {
							if(value.include_all) {
								output = ["All"]
							} else {
								output = output.filter(e => e !== 'All')
							}
						}
						params[value.output_field] = output
					}
				})
			}
	
			setState(prevState => ({ ...prevState, hasError, executeQuery: hasError ? false : true }))
	
			if(!hasError) {
	
				let queryProps = {
					account_id: state[state.selectedQuery.query_id+"_selectedQueryAccount"],
					region: state[state.selectedQuery.query_id+"_selectedQueryRegion"],
					duration: state.selectedQueryDuration,
					params: params,
					start_time: state.queryStartTime,
					end_time: state.queryEndTime,
					selectedQuery: state.selectedQuery,
					querybuilder: state.querybuilder
				}
	
				if(selectedQuery.async) {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, selectedInput: params, executeQuery: false }))
					startQueryExectionFunction(params)
				} else {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, executeQuery: false }))
					getQueryResultFunction(params)
				}
			}
		}
	}, [state.executeQuery, state, startQueryExectionFunction, getQueryResultFunction])

	const onKeyDown = (e, field, stateField) => {
		let array = state[stateField] && Array.isArray(state[stateField]) ? state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(state[field] && state[field] !== "") {
				array.push(state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
			}
		}
	}

	const onBlur = (e, field, stateField) => {
		let array = state[stateField] ? state[stateField] : []
		if(state[field] && state[field] !== "") {
			array.push(state[field].trim())
			setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
		}
	}

	const removeSelectedBadge = (field, value, index) => {
		let filteredReslt = state[field]
		filteredReslt.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: filteredReslt }))
	}

	const setFavouriteQueryFunction = (index, queryId, type) => {
		alert(2)
		let params = {
			query_id: queryId
		}

		if(type) {
			dispatch(setFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						
						CommonNotification.addNotification({
							message: "Query "+queryId+" added to favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		} else {
			dispatch(unsetFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						CommonNotification.addNotification({
							message: "Query "+queryId+" removed from favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		}
	}

	const updateQueryList = () => {
		let params = {}
		dispatch(getCostQueries(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, totalQueryList: response }),
						() => {
							let data = state.totalQueryList
							if(state.selectedSubCategory && state.selectedSubCategory !== "") {
								data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
							} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
								data = data.filter(e => e.category.includes(state.selectedCategory))
							}
	
							setState(prevState => ({ ...prevState, filteredArray: data }))
						}
					)
				}
			})
	}

	const onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation && Object.entries(validation).length) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else {
				setState(prevState => ({ ...prevState, ['error_message_'+field]: "" }))
			}
			
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		setState(prevState => ({ ...prevState, [field]: value, hasError }))
	}

	const onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_"+out.output_field]: selectedQuery.default }))
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
								params.account_id = state.selectedAccount
							}
							if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
								params.region = state.selectedRegion
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!state[selectedQuery.query_id+"_"+manFields] || state[selectedQuery.query_id+"_"+manFields] === "" || (Array.isArray(state[selectedQuery.query_id+"_"+manFields]) && !state[selectedQuery.query_id+"_"+manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = state[selectedQuery.query_id+"_"+manFields]
							}
						})
					}
					
					if(manditoryFieldsFilled) {
						setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+out.output_field]: out.output_type === "text" ? "" : [] }),
							() => dynamicCallApiFunction(params, selectedQuery, apiDetails, out.output_field, out.output_type)						
						)
					}
				}
			})
		}
	}

	const dynamicCallApiFunction = (params, selectedQuery, apiDetails, outputField, outputType) => {
		dispatch(dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params))
			.then((response) => {
				if(response) {
					let options = []
					if(outputType === "list") {
						let dataRow = {}
						dataRow.key = "All"
						dataRow.value = "All"
						options.push(dataRow)
					}
					if(response.results) {
						let results = response.results
						results.forEach(res => {
							if(apiDetails.field_name === "id_name_map") {
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								// options.push(res[apiDetails.field_name])
								options.push(dataRow)
							} else if(res[apiDetails.field_name]){
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								options.push(dataRow)
							}
						})
					}
					
					setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_options_"+outputField]: options }))
					if(outputType === "list") {
						handleMultiSelectChange(outputField, options)
					}
				}
			})
	}
	return (
		<div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
				<PageLoading />
            </div>
			<div className="top-0 relative z-10 py-6">
				<div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
					<div className="lg:w-1/4 w-full self-center">
						<p className="w-full text-white text-[28px] text-semibold">Queries</p>
						<p className="text-sm text-white">Cost queries on your Cloud</p>
					</div>
					{!state.showQueryResult ?
						<div className="lg:w-3/4 w-full items-center flex flex-wrap justify-end">
							<div className='flex mx-0 justify-end'>
								<div className='mr-3'>
									<SelectOption
										label={"Provider"}
										labelClass={{color: 'text-white'}}
										fields={["provider_name", "provider_name"]}
										options={state.providers}
										selectedValues={state.selectedProvider ? state.selectedProvider : ''}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], queryListJson: [], callGetQueries: true }))
										}}
										dropdownWidth={'min-w-32'}
										classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
									/>
								</div>
								<div className='mr-3'>
									<SelectOption
										label={"Account"}
										labelClass={{color: 'text-white'}}
										fields={["account_id", "account_name"]}
										options={state.accounts}
										selectedValues={state.selectedAccount ? state.selectedAccount : ''}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, selectedAccount: value }))
										}}
									/>
								</div>
								<div className='mr-3'>
									<SelectOption
										label={"Region"}
										labelClass={{color: 'text-white'}}
										fields={["region", "name"]}
										options={state.regions}
										selectedValues={state.selectedRegion ? state.selectedRegion : ''}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, selectedRegion: value }))
										}}
									/>
								</div>
								<div className='self-end'>
									<label className='text-white text-base font-medium'>Duration</label>
									<SmartDateRangePicker
										dateRange={dateRange}
										setDateRange={setDateRange}
										setQueryDate={setQueryDate}
										queryDate={queryDate}
									/>
								</div>
							</div>
						</div>
					: null}
				</div>
			</div>
			<div className='mt-4'>
                <div className='bg-white rounded-2xl border border-DeepPurpleTints-600 mr-1'>
					{state.showQueryResult ?
						<QueryResults 
							providers={state.providers}
							accounts={state.accounts}
							regions={state.regions}
							selectedQuery = {state.selectedQuery}
							queryStartTime = {state.queryStartTime}
							queryEndTime = {state.queryEndTime}
							selectedInput = {state.selectedInput}
							querybuilder = {state.querybuilder}
							queryResult = {state.queryResult}
							selectedProvider = {state.selectedProvider}
							selectedAccount = {state.selectedAccount}
							selectedRegion = {state.selectedRegion}
							duration = {state.selectedQueryDuration}
							account_id = {state[state.selectedQuery.query_id+"_selectedQueryAccount"]}
							region = {state[state.selectedQuery.query_id+"_selectedQueryRegion"]}
							hideQueryResults = {() => setState(prevState => ({ ...prevState, showQueryResult: false }))}
						/>
					:
						state.listLoading ?
							<div className='flex justify-center m-4'>
								<LoadingCircle />
							</div>
						:
							<React.Fragment>
							<div className={`flex flex-wrap justify-between px-6 py-8 border-b border-DeepPurpleTints-600`}>
								<div className='lg:w-1/2 w-full'>
									<p className='text-2xl font-semibold'>All Queries</p>
									<div className="flex text-sm lg:w-3/5 w-full">
										<p className="self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.queryList && state.queryList.length} query(s)</p>
									</div>
								</div>
								<div className='lg:w-1/2 w-full flex justify-end'>
									{state.queryList && state.queryList.length ?
										<div className='w-1/2'>
											<Search
												data={state.queryList ? state.queryList : []}
												topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
												className={'rounded-full'}
												filteredData={(filteredArray) => {
													setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
												}}
											/>
										</div>
									: null}
								</div>
								<div className='flex justify-end w-full'>
									{state.filteredArray && state.filteredArray.length > state.perPage ?
										<div className='text-sm flex justify-end text-black lg:w-1/2 w-full text-sm'>
											<span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
											<div className="flex text-sm">
												<span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
												<span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
											</div>
										</div>
									: null}
								</div>
							</div>
							<div className='flex flex-wrap pl-6 pr-3 py-8'>
								{state.categories ? 
									<div className="lg:w-1/5 md:w-1/5 w-full lg:pr-3 md:pr-3 bg-NeutralGray-400 border border-NeutralGray-600 rounded-2xl pt-[22px] px-3 overflow-y-auto h-[calc(100vh-360px)]">
										{state.categories.map((item, index) => {
                                    		return(
												<div className='pb-4' key={'cat_'+index}>
													{item.category === "All" ?
														<div className={`flex cursor-pointer text-base font-bold text-lg ${state.selectedCategory === item.category ? 'text-byzantineBlue pointer-events-none' : 'text-black'}`} onClick={() => {
															setState(prevState => ({ ...prevState, selectedCategory: "All", selectedSubCategory: "", getAllQueries: true }))
														}}>All Queries</div>
													:
														<React.Fragment>
														<div className={`bg-white rounded-full py-3 px-5 text-base font-medium flex flex-wrap justify-between text-black text-nowrap truncate ${!state.selectedSubCategory && item.category.includes(state.selectedCategory) ? 'pointer-events-none border-2 border-byzantineBlue' : 'cursor-pointer'}`}onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, selectedSubCategory: "", filterQueryList: true }))}>
															<p className={`w-3/5`}>{item.category}</p>
															<span className='font-bold'>{item.count !== '' ? item.count : ''}</span>
														</div>
														{item.category.includes(state.selectedCategory) && item.sub_category && item.sub_category.length ?
															item.sub_category.map(subCat => {
																return(
																	<div className={`flex justify-between mb-2 ml-2 ${subCat.sub_category === state.selectedSubCategory ? 'text-info pointer-events-none' : 'cursor-pointer'}`} style={{maxWidth: "230px"}} onClick={() => setState(prevState => ({ ...prevState, selectedSubCategory: subCat.sub_category, filterQueryList: true }))}>
																		<p className="mb-0 self-center w-3/5" id={'sub_category_'+index}>{subCat.sub_category}</p>
																		<UncontrolledTooltip target={'sub_category_'+index}>
																			<div className="bg-BlockWhite p-2 rounded-md">{subCat.sub_category}</div>
																		</UncontrolledTooltip>
																		<input className="bg-transparent border-b border-lightGray px-2 py-1 text-black w-1/4" style={{minWidth: "40px"}} type="text" placeholder="1" value={subCat.count !== '' ? subCat.count : ''} />
																	</div>
																)
															})															
														: null}
														</React.Fragment>
													}
												</div>
											)
										})}
									</div>
								: null}
						
								<div className={`pl-5 ${state.categories ? 'lg:w-4/5 md:w-4/5' : ''} w-full lg:mt-0 md:mt-0 mt-2 pr-3 overflow-y-auto h-[calc(100vh-360px)]`}>
									{state.filteredArray && state.filteredArray.length ?
										state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
											return(
												<div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6 mr-1' key={'filArr_'+i}>
													<div className="flex justify-between cursor-pointer w-full">
														<div className="font-semibold text-base flex">
															<span onClick={() => querySelection(i, item)}>
																{state.selectedQuery && state.selectedQuery.query_id === item.query_id ?
																	<Icon icon={`iconoir:minus`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
																:
																	<Icon icon={`stash:plus-solid`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
																}
															</span>
															
															{item.query_id +" - "+ (item.title ? item.title : "")}
														</div>
														<p className={`text-right cursor-pointer`} 
															onClick={e => {
																setState(prevState => ({
																	...prevState, 
																	['favourite_'+i]: typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false :  true)
																}))
																setFavouriteQueryFunction(i, item.query_id, typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false : true))
															}}
														>
															{(typeof state['favourite_'+i] === 'undefined' && item.favourite) || state['favourite_'+i]  ? 
																<Icon icon="emojione:star" className={`self-center cursor-pointer`} width={24} height={24} />
															: 
																<Icon icon="ph:star-thin" className={`text-black self-center cursor-pointer`} width={24} height={24} />
															}
														</p>
													</div>
													{!state.selectedQuery || state.selectedQuery.query_id !== item.query_id ?
														<p className="ml-9 mb-0 text-sm"> 
															<span className="mr-2 text-black">Query:</span>
															{item.display_query}

															{item.required_fields && item.required_fields.includes("account_id") && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All") ?
																	<span className="ml-1">for the account <span className="ml-1 text-info">{state.selectedAccount.join(", ")}</span></span>
																:
																	<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the accounts</span></span>
																}
																{item.required_fields && item.required_fields.includes("region") && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All") ?
																	<span className="ml-1">for the region <span className="ml-1 text-info">{state.selectedRegion.join(", ")}</span></span>
																:
																	<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the regions</span></span>
																}																
																{item.required_fields && item.required_fields.includes("duration") && state.selectedDuration ?
																	<span className="ml-1">for the duration 
																	{state.selectedDuration === "custom" ?
																		<span className="ml-1 text-info">{momentDateGivenFormat(state.startTime, "DD MMM YYYY hh:mm A") +" - "+momentDateGivenFormat(state.endTime, "DD MMM YYYY hh:mm A")}</span>
																	: 
																		<span className="ml-1 text-info">{state.selectedDuration && state.selectedDuration !== "" ? durationOptions.filter(e => e.value === state.selectedDuration)[0].option : ""}</span>
																	}
																	</span>
																: null}
														</p>
													: state.selectedQuery && state.selectedQuery.query_id === item.query_id ?
														<div className='ml-9 w-full'>
															{item.description && item.description !== "" ?
																<div className="flex flex-wrap text-md w-full mb-1 text-lightGray text-sm"> 
																	<span className="mr-2 text-sm">Description:</span>
																	{item.description.split("\n").map((newLine, dIndex) => {
																		return(
																			<span key={'des_'+dIndex} className="flex">
																			{newLine.split("\t").map((tab, i) => {
																				return(
																					<span key={'des_line_'+i} className={`${!i ? "" :""}`}>{tab}</span>
																				)
																			})}
																			</span>
																		)
																	})}
																</div>
															: null}
															{item.controls && Object.entries(item.controls).length ?
																<div className="flex mt-2 p-3 rounded-md bg-white">
																	{item.controls.hasOwnProperty("Tactic") ?
																		<div className={`mr-2 border-r border-black5 ${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Technique")) ? "w-full" : (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Technique")) ? "w-1/2" : "w-1/5"}
																		`}>
																			<label className="text-black mb-2">Tactic</label>
																			{item.controls.Tactic.map((ctrls, cIndex) => {
																				return(
																					<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																				)
																			})}
																		</div>
																	: null}
																	{item.controls.hasOwnProperty("Technique") ?
																		<div className={`mr-2 border-right-black5 ${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Tactic")) ? "w-full" : (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Tactic")) ? "w-1/2" : "w-1/5"}`}>
																			<label className="text-black mb-2">Technique</label>
																			{item.controls.Technique.map((ctrls, cIndex) => {
																				return(
																					<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																				)
																			})}
																		</div>
																	: null}
																	{item.controls.hasOwnProperty("Action/Impact") ?
																		<div className={`mr-2 ${(!item.controls.hasOwnProperty("Tactic") && !item.controls.hasOwnProperty("Technique")) ? "w-full" : (!item.controls.hasOwnProperty("Tactic") || !item.controls.hasOwnProperty("Technique")) ? "w-1/2" : "w-1/2"}`}>
																			<label className="text-black mb-2">Action/Impact</label>
																			{item.controls["Action/Impact"].map((ctrls, cIndex) => {
																				return(
																					<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																				)
																			})}
																		</div>
																	: null}
																</div>
															: null}
															<div className={`mt-2`}>
																<div className="flex flex-wrap">
																	{state.querybuilder && state.querybuilder.map((qry, j) => {
																		return(
																			<span key={'qry_'+j}>
																				{!qry.input ?
																					<p className={`mb-0 ${!j ? "mr-2" : "mx-2"} self-end text-info text-sm mt-3`}>{qry.string}</p>
																				:
																					<React.Fragment>
																					{state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "text" ?
																						state.selectedQuery.display_output[qry.field].output_type === "string" ?
																							<React.Fragment>
																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																								<div className='w-32 self-end mr-2'>
																									<SelectOption
																										label={""}
																										fields={["value", "value"]}
																										options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																										selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																										callbackMultiSelect={(value) => 
																											setState(prevState => ({ 
																												...prevState, 
																												[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																											}))
																										}
																										singleSelection={true}
																										classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																									/>
																								</div>
																							:
																								<React.Fragment>
																								<Textbox
																									label={""}
																									type="text"
																									selectedValue={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																									callback={(value) => {
																										onChangeTextString(state.selectedQuery.display_output[qry.field], state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, value)
																									}}
																									classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																									placeholder={qry.field}
																								/>
																								{state.selectedQuery.display_output[qry.field].validation ?
																									<React.Fragment>
																									<Icon icon="fa:info-circle" className="text-black self-center cursor-pointer" id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} />
																									<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																									<div className="bg-white p-2 rounded-md">
																										{state.selectedQuery.display_output[qry.field].validation.data_type ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Data Type:</span>
																												{state.selectedQuery.display_output[qry.field].validation.data_type}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.min ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Min length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.min}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.max ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Max length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.max}
																											</p>
																										: null}
																									</div>
																									</UncontrolledTooltip>
																									</React.Fragment>
																								: null}
																								</React.Fragment>
																							}
																							</React.Fragment>
																						: state.selectedQuery.display_output[qry.field].output_type === "list" ?
																							<React.Fragment>
																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																								<div className='w-32 self-end mr-2'>
																									<SelectOption
																										label={""}
																										fields={["value", "label"]}
																										options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																										selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : []}
																										callbackMultiSelect={(value) => 
																											setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value }))
																										}
																										classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																									/>
																								</div>
																							:
																								<React.Fragment>
																								<input 
																									type="text" 
																									className={`bg-transparent border-b border-lightGray px-2 py-1 text-black w-full w-32 ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
																									placeholder={qry.field}
																									value={state[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]}
																									onKeyDown={e => onKeyDown(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																									onBlur={e => onBlur(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																									onChange={e => setState(prevState => ({ ...prevState,[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]: e.target.value }))}
																								/>
																								{state.selectedQuery.display_output[qry.field].validation ?
																									<React.Fragment>
																									<Icon icon="fa:info-circle" className="text-black self-center cursor-pointer" id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} />
																									<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																									<div className="bg-white p-2 rounded-md">
																										{state.selectedQuery.display_output[qry.field].validation.data_type ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Data Type:</span>
																												{state.selectedQuery.display_output[qry.field].validation.data_type}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.min ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Min length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.min}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.max ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-black mr-1">Max length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.max}
																											</p>
																										: null}
																									</div>
																									</UncontrolledTooltip>
																									</React.Fragment>
																								: null}
																								</React.Fragment>
																							}

																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ? 
																								state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																									state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																										return(
																											<span key={'tab_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																												{state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => {
																													return(
																													res.value === tab ?
																														res.value +(res.key !== res.value ? " ("+res.key+")" : "")
																													: null
																													)
																												})}
																												<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																											</span>
																										)
																									})
																								: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																									<span className={`badge-square badge-secondary mt-3 f13 self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																								: null
																							:
																								state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																									return(
																										<span key={'tab1_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																										{tab}
																											<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																										</span>
																									)
																								})
																							}
																							</React.Fragment>
																						: null
																					: state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "dropdown" ?
																						state.selectedQuery.display_output[qry.field].output_type === "list" ?
																							<React.Fragment>
																							{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																							state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																								return(
																									<span key={'tab2_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																									{tab}
																										<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																									</span>
																								)
																							})
																							: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																								<span className={`badge-square badge-secondary mt-3 f13 self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																							: null}
																							</React.Fragment>
																						: state.selectedQuery.display_output[qry.field].output_type === "string" ?	
																							<div className='w-32 self-end mr-2'>
																								<SelectOption
																									label={""}
																									options={state.selectedQuery.display_output[qry.field].values}
																									selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																									callbackMultiSelect={(value) => 
																										setState(prevState => ({ 
																											...prevState,
																											[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																										}))
																									}
																									singleSelection={true}
																									classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																								/>
																							</div>
																						: null
																					: null}
																					</React.Fragment>
																				}
																			</span>
																		)
																	})}
																	{state.selectedQuery.required_fields ?
																		<React.Fragment>
																		{state.selectedQuery.required_fields.includes("account_id") ?
																			<React.Fragment>
																			<p className="mb-0 mr-2 text-info mt-3">for Account</p>
																			<div className='w-32 self-end mr-2'>
																				<SelectOption
																					label={""}
																					fields={["account_id", "account_name"]}
																					options={state.accounts}
																					selectedValues={state[state.selectedQuery.query_id+"_selectedQueryAccount"] ? state[state.selectedQuery.query_id+"_selectedQueryAccount"] : []}
																					callbackMultiSelect={(value) => 
																						setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryAccount"]: value }))
																					}
																					classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																				/>
																			</div>
																			</React.Fragment>
																		: null}
																		{state.selectedQuery.required_fields.includes("region") ?
																			<React.Fragment>
																			<p className="mb-0 mx-2 text-info mt-3">Region</p>
																			<div className='w-32 self-end mr-2'>
																				<SelectOption
																					label={""}
																					fields={["region", "name"]}
																					options={state.regions}
																					selectedValues={state[state.selectedQuery.query_id+"_selectedQueryRegion"] ? state[state.selectedQuery.query_id+"_selectedQueryRegion"] : []}
																					callbackMultiSelect={(value) => 
																						setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryRegion"]: value }))
																					}
																					classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																				/>
																			</div>
																			</React.Fragment>
																		: null}
																		{state.selectedQuery.required_fields.includes("duration") ?
																			<React.Fragment>
																			<p className="mb-0 mx-2 text-info mt-3">Duration</p>
																			<div className='w-32 self-end mr-2'>
																				<SelectOption
																					label={""}
																					fields={["value", "option"]}
																					options={durationOptions}
																					selectedValues={state.selectedQueryDuration ? state.selectedQueryDuration : ''}
																					callbackMultiSelect={(value) => {
																						if(value !== 'custom') {
																							setState(prevState => ({ 
																								...prevState,
																								selectedQueryDuration: value,
																								showQueryDateTimePicker: false,
																								getQueryDurationDetails: true
																							}))
																						} else {
																							setState(prevState => ({ 
																								...prevState,showQueryDateTimePicker: true, 
																								selectedQueryDuration: value
																							}))
																						}	
																					}}
																					singleSelection={true}
																					classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
																				/>
																			</div>
																			{state.showQueryDateTimePicker ?
																				<div className="flex mt-2">
																					<p className="mb-0 mx-2 text-info mt-2 pt-1">start&nbsp;time</p>
																					<Datetime 
																						value={momentDateGivenFormat(state.queryStartTime, 'DD MMM YYYY hh:mm A')}
																						onChange={handleStartTimeChange}
																						dateFormat={'DD MMM YYYY'}
																						timeFormat={'hh:mm A'}
																						inputProps={{ readOnly: true, class: "inputField" }}
																						className="border-b border-NeutralGray-400 w-100 mt-2"
																						isValidDate={queryDateTime}
																					/>
																					<p className="mb-0 mx-2 self-end text-info mt-2 pt-1">end&nbsp;time</p>
																					<Datetime
																						value={momentDateGivenFormat(state.queryEndTime, 'DD MMM YYYY hh:mm A')}
																						onChange={handleEndTimeChange}
																						dateFormat={'DD MMM YYYY'}
																						timeFormat={'hh:mm A'}
																						inputProps={{ readOnly: true, class: "inputField" }}
																						className="border-b border-NeutralGray-400 w-100 mt-2"
																						isValidDate={queryDateTime}
																					/>
																				</div>
																			: null}
																			</React.Fragment>
																		: null}
																		</React.Fragment>
																	: null}
																</div>
															</div>
															
															<div className={`flex justify-end w-full mt-2 ${state.queryLoading ? "pointer-events-none" : ""}`}>
																{state.queryErrorMessage && state.queryErrorMessage !== "" ?
																	// <div className="w-100">
																	<React.Fragment>
																		<p className="mb-0 self-center text-danger mr-3">{state.queryErrorMessage.message ? state.queryErrorMessage.message : state.queryErrorMessage.errorMessage ? state.queryErrorMessage.errorMessage : ""}</p>
																	</React.Fragment>
																: null}
																<span className={`flex mb-0 text-white bg-primaryPurple-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3 min-w-28 ${state.hasError ? "" : ""}`} onClick={() => {
																	if(!state.queryLoading) {
																		setState(prevState => ({ ...prevState, queryErrorMessage: "", executeQuery: true }))
																	}
																}}>
																	{state.queryLoading ?
																		<LoadingCircle />
																	: null}
																	Run Query
																</span>
																<span className={`mb-0 text-white bg-DeepPurpleTints-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base min-w-28 text-center`}onClick={() => setState(prevState => ({ ...prevState, ['expand_'+i]: false, selectedQuery: "" }))}>Close</span>
															</div>
														</div>
													: null}
												</div>
											)
										})
									:
										<div className='flex justify-center m-4 text-black'>
											There are no data on this criteria. Please try adjusting your filter.
										</div>
									}
								</div>
							</div>
						</React.Fragment>
					}
				</div>
			</div>
		</div>
	)
}

export default QueryEvents