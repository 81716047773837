import React, { useEffect, useRef, useState } from 'react';
import formatedDate from '../../utils/formatedDate';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import {Icon} from "@iconify/react";
// import {
//   CalendarIcon,
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   CloseIcon,
// } from '../../globalData/IconMasterMap';
import { defaultDateRangeValue } from './FiltersSection';
import { useForm } from 'react-hook-form';
import { formatDate, momentDateGivenFormat } from '../../utils/utility';

const SmartDateRangePicker = ({ dateRange, setDateRange, setQueryDate, queryDate }) => {
  // ************************************* outside click functionality **********************************
  const [calendarVisible, setCalendarVisible] = useState(false);
  const calendarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      if ((!dateRange[0] && !dateRange[1]) || isDefaultValue) {
        setDateRange([queryDate[0], queryDate[1]]);
      }
      setCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  // ************************************* outside click functionality **********************************

  const openStartCalendar = () => {
    setCalendarVisible(true);
  };

  const clearStartDate = () => {
    const [startDate, endDate] = defaultDateRangeValue();
    if (
      dateRange?.toString() !== [startDate, endDate]?.toString() &&
      queryDate?.toString() !== [startDate, endDate]?.toString()
    ) {
      setQueryDate([startDate, endDate]);
      setDateRange([startDate, endDate]);
    }
  };

  const onDateChange = (dates) => {
    const endDate = dates?.[1];
    if (endDate) {
      const today = new Date();
      const isToday = endDate.toDateString() === today.toDateString();
      if (isToday) {
        endDate.setHours(today.getHours(), today.getMinutes(), today.getSeconds(), 999);
      } else {
        endDate.setHours(23, 59, 59, 999);
      }
    }
    setDateRange(dates);
  };

  const isDefaultValueComparison = () => {
    const compareVal1 = queryDate;
    const compareVal2 = defaultDateRangeValue();
    const str1 = [
      new Date(compareVal1?.[0]).toDateString(),
      new Date(compareVal1?.[1]).toDateString(),
    ]?.toString();

    const str2 = [
      new Date(compareVal2?.[0]).toDateString(),
      new Date(compareVal2?.[1]).toDateString(),
    ]?.toString();
    return str1 === str2;
  };

  const isDefaultValue = isDefaultValueComparison();
  return (
    <div className='smart-date-range-picker self-center flex justify-between border border-lightGray rounded py-2.5 pr-4 pl-3 text-[16px]'>
      <div className='bg-white rounded relative w-full'>
        <button className='start-date-input' onClick={openStartCalendar}>
          <div onClick={(e) => e.stopPropagation()} className='relative' ref={calendarRef}>
            {calendarVisible && (
              <DateRange
                selectsRange
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                maxDate={dateRange[1] || new Date()}
                value={dateRange[0]}
                onChange={onDateChange}
                title={'Select Start Date'}
                setCalendarVisible={setCalendarVisible}
                setDateRange={setDateRange}
                setQueryDate={setQueryDate}
              />
            )}
          </div>
          <Icon icon="carbon:calendar" width={20} height={20} className='text-lightGray cursor-pointer'/>

          <span className='from-label font-sm'>
            {queryDate?.[0]
              ? formatedDate(queryDate[0], '-', false, 'short', true)
              : '-- / -- / ----'}
          </span>
          {/* <label>-</label> */}
          <span className='to-label ml-2'>
            {queryDate?.[1]
              ? formatedDate(queryDate[1], '-', false, 'short', true)
              : '-- / -- / ----'}
          </span>

          {/* {!isDefaultValue && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                clearStartDate();
              }}
              className='clear-icon'
            >
              <Icon icon="jam:close" width="20" height="20" className='text-lightGray cursor-pointer'/>
            </span>
          )} */}
        </button>
      </div>
    </div>
  );
};

export default SmartDateRangePicker;

const DateRange = ({
  startDate,
  endDate,
  value,
  setDateRange,
  title,
  setCalendarVisible,
  onChange,
  setQueryDate,
  ...props
}) => {
  const [showPreviousMonth, setShowPreviousMonth] = useState(true);
  const {
    setValue,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { 'startDate-datepicker-input': startDate, 'endDate-datepicker-input': endDate },
  });
  const watchData = watch();
  const onLastSevenClick = () => {
    const today = new Date();
    const lastWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
    const lastWeekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    lastWeekEndDate.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    setDateRange([lastWeekStartDate, lastWeekEndDate]);
    setValue('startDate-datepicker-input', lastWeekStartDate);
    setValue('endDate-datepicker-input', lastWeekEndDate);
  };

  const getLastMonthClick = () => {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0 is January, so this is zero-indexed
    const currentYear = today.getFullYear();

    // Calculate the first and last day of the previous month
    let previousMonth = currentMonth - 1; // Previous month
    let previousYear = currentYear;

    if (previousMonth < 0) {
      previousMonth += 12; // Adjust for December if current month is January
      previousYear--; // Go back a year
    }

    // First day of the previous month
    const startDate = new Date(previousYear, previousMonth, 1); 

    // Last day of the previous month
    const endDate = new Date(currentYear, currentMonth, 0); // Setting day to 0 gives the last day of the previous month

    setDateRange([startDate, endDate]);
    setValue('startDate-datepicker-input', startDate);
    setValue('endDate-datepicker-input', endDate);

    return [startDate, endDate];

  };

  const onCurrentQuaterClick = () => {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0 is January
    const currentYear = today.getFullYear();

    // Initialize start and end month for the current quarter
    let startMonth, endMonth;

    // Determine the start and end month of the current quarter
    if (currentMonth >= 0 && currentMonth <= 2) { // Q1: January - March
      startMonth = 0; // January
      endMonth = 2;   // March
    } else if (currentMonth >= 3 && currentMonth <= 5) { // Q2: April - June
      startMonth = 3; // April
      endMonth = 5;   // June
    } else if (currentMonth >= 6 && currentMonth <= 8) { // Q3: July - September
      startMonth = 6; // July
      endMonth = 8;   // September
    } else { // Q4: October - December
      startMonth = 9;  // October
      endMonth = 11;   // December
    }

    // Calculate the start date as the first day of the quarter
    const startDate = new Date(currentYear, startMonth, 1);

    // Calculate the end date as the last day of the quarter
    const endDate = new Date(currentYear, endMonth + 1, 0); // 0th day gives the last day of the previous month

    // Set the date range
    setDateRange([startDate, endDate]);
    setValue('startDate-datepicker-input', startDate);
    setValue('endDate-datepicker-input', endDate);

    return [startDate, endDate];

  }

  const onCurrentHalfClick = () => {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0 is January
    const currentYear = today.getFullYear();

    // Initialize start and end month for the current half-year
    let startMonth, endMonth;

    // Determine the start and end month of the current half-year
    if (currentMonth >= 0 && currentMonth <= 5) { // H1: January - June
      startMonth = 0; // January
      endMonth = 5;   // June
    } else { // H2: July - December
      startMonth = 6;  // July
      endMonth = 11;   // December
    }

    // Calculate the start date as the first day of the half-year
    const startDate = new Date(currentYear, startMonth, 1);

    // Calculate the end date as the last day of the half-year
    const endDate = new Date(currentYear, endMonth + 1, 0); // 0th day gives the last day of the previous month

    // Set the date range
    setDateRange([startDate, endDate]);
    setValue('startDate-datepicker-input', startDate);
    setValue('endDate-datepicker-input', endDate);

    return [startDate, endDate];

  }

  const onLastMonthClick = (numberOfMonths) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Calculate the start month and year
    let startMonth = currentMonth - numberOfMonths; // Adjusted to start from the previous three months
    let startYear = currentYear;

    // Adjust start month and year if it's negative
    if (startMonth < 0) {
      startMonth += 12;
      startYear--;
    }

    // Calculate the end date as today's date
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    endDate.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    // Calculate the start date as three months before today's date
    const startDate = new Date(startYear, startMonth, today.getDate()); // Keep the same day as today
    setDateRange([startDate, endDate]);
    setValue('startDate-datepicker-input', startDate);
    setValue('endDate-datepicker-input', endDate);

    return [startDate, endDate];
  };

  const onMonthToDateClick = () => {
    const today = new Date();
    // Get the current month and year from the provided date
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Construct the starting date of the current month
    const currentMonthStartDate = new Date(currentYear, currentMonth, 1);
    today.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    setDateRange([currentMonthStartDate, today]);
    setValue('startDate-datepicker-input', currentMonthStartDate);
    setValue('endDate-datepicker-input', today);
  };

  const onYearToDateClick = () => {
    const today = new Date();
    const currentYear = today.getFullYear();

    // Construct the starting date of the current month
    const currentYearStartDate = new Date(currentYear, 0, 1);
    today.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    setDateRange([currentYearStartDate, today]);
    setValue('startDate-datepicker-input', currentYearStartDate);
    setValue('endDate-datepicker-input', today);
  };

  const clearStartDate = (e) => {
    reset({
      'endDate-datepicker-input': '',
      'startDate-datepicker-input': '',
    });
    setDateRange([]);
  };
  const applyChanges = () => {
    if (!startDate && !endDate) {
      setQueryDate(onLastMonthClick(3));
      setValue('startDate-datepicker-input', onLastMonthClick(3)[0]);
      setValue('endDate-datepicker-input', onLastMonthClick(3)[1]);
    } else {
      setQueryDate([startDate, endDate]);
      setValue('startDate-datepicker-input', startDate);
      setValue('endDate-datepicker-input', endDate);
    }

    setCalendarVisible(false);
  };

  const isWithinOneMonth = (startDate, endDate) => {
    const differenceInMilliseconds = Math.abs(endDate - startDate);
    const millisecondsInOneMonth = 30 * 24 * 60 * 60 * 1000;
    return differenceInMilliseconds <= millisecondsInOneMonth;
  };

  useEffect(() => {
    setShowPreviousMonth(() =>
      !watchData['startDate-datepicker-input'] && !watchData['endDate-datepicker-input']
        ? true
        : isWithinOneMonth(
            watchData['startDate-datepicker-input'],
            watchData['endDate-datepicker-input'],
          ),
    );
    if (
      watchData['startDate-datepicker-input'] <= new Date() &&
      watchData['endDate-datepicker-input'] <= new Date()
    ) {
      if (watchData['startDate-datepicker-input'] <= watchData['endDate-datepicker-input']) {
        clearErrors();
      }
    }
  }, [watchData['startDate-datepicker-input'], watchData['endDate-datepicker-input']]);

  const handleDateChange = (e, label) => {
    const inputValue = e.target.value;
    const formattedValue = formatDate(inputValue);
    setValue(label, formattedValue)
  };

  return (
    <div className='date-range-container flex flex-wrap bg-white z-50 p-4'>
      <div className='w-full flex align-items-start'>
        <DatePicker
          showIcon
          inline
          toggleCalendarOnIconClick
          isClearable={true}
          wrapperClassName={'datepicker'}
          className='form-control filter-date'
          placeholderText='DD/MM/YYYY'
          strictParsing
          dateFormat='dd/MM/yyyy'
          customInput={
            <MaskedTextInput
              type='text'
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          }
          startDate={startDate}
          endDate={endDate}
          // minDate={props.selectsStart ? null : startDate}
          maxDate={new Date()}
          onChange={(date) => {
            onChange(date);
            date?.[0] && setValue('startDate-datepicker-input', date[0]);
            date?.[1] && setValue('endDate-datepicker-input', date[1]);
          }}
          renderCustomHeader={({
            date,
            monthDate,
            decreaseMonth,
            increaseMonth,
            customHeaderCount,
          }) => {
            return (
              <div className='calendar-header mb-2'>
                <div className='show-month flex justify-content-between '>
                    {customHeaderCount === 0 && (
                    <span
                      onClick={() => {
                        decreaseMonth();
                      }}
                    >
                        <Icon icon={`fa:angle-left`} width="20" height="20" className='text-lightGray cursor-pointer' />
                    </span>
                  )}
                  <span className='w-full month-name'>
                    {monthDate.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  {customHeaderCount === 1 && (
                    <span
                      onClick={() => {
                        increaseMonth();
                      }}
                    >
                      <Icon icon={`fa:angle-right`} width="20" height="20" className='text-lightGray cursor-pointer' />
                    </span>
                  )}
                </div>
              </div>
            );
          }}
          selected={startDate}
          selectsStart
          selectsRange
          monthsShown={2}
          showPreviousMonths={showPreviousMonth}
          {...props}
        />
      </div>
      
      <div className='flex flex-wrap w-full selectedDate-input-wrapper px-2 border-y border-NeutralGray-600 py-6 my-6'>
        <div className='startDate'>
          <label htmlFor='' className='text-base font-medium input-label flex justify-start'>
            Start date
          </label>
          <div className="react-datepicker__input-container">
            <input type="text" 
              name="startDate-datepicker-input" 
              placeholder="DD/MM/YYYY" 
              className="react-datepicker-ignore-onclickoutside border border-NeutralGray-500 py-2.5 px-4 rounded-lg pointer-events-none" 
              value={momentDateGivenFormat(watchData['startDate-datepicker-input'], 'DD-MM-YYYY')}
              onChange={(e) => {
                // handleDateChange(e, "startDate-datepicker-input")
              }} // Use onChange instead of onKeyUp
              maxLength="10"
            />
          </div>
        </div>
        <div className='flex flex-column align-items-start w-1/2 endDate pl-3'>
          <div className='endDate'>
            <label htmlFor='' className='text-base font-medium input-label flex justify-start'>
              End date
            </label>
            <div className="react-datepicker__input-container">
              <input type="text" 
                name="endDate-datepicker-input" 
                placeholder="DD/MM/YYYY" 
                className="react-datepicker-ignore-onclickoutside border border-NeutralGray-500 py-2.5 px-4 rounded-lg pointer-events-none" 
                value={momentDateGivenFormat(watchData['endDate-datepicker-input'], 'DD-MM-YYYY')}
                onChange={(e) => {
                  // handleDateChange(e, "endDate-datepicker-input")
                }} // Use onChange instead of onKeyUp
                maxLength="10"
              />
            </div>
            {errors['endDate-datepicker-input'] && (
              <small className='text-red mt-1'>{`${errors['endDate-datepicker-input'].message}`}</small>
            )}
          </div>
        </div>
      </div>
      <div className='bg-white text-base'>
        <p className='text-black flex font-semibold justify-start mx-2'>Select</p>
        <div className='flex flex-wrap gap-2 m-2'>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={onMonthToDateClick}>Month to Date</span>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={onYearToDateClick}>Year to Date</span>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={() => getLastMonthClick()}>Last Month</span>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={() => onCurrentQuaterClick()}>Current Quarter</span>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={() => onCurrentHalfClick()}>Current Half Year</span>
          <span className='bg-NeutralGray-300 border border-NeutralGray-600 shadow cursor-pointer px-3 py-2.5 rounded-lg' onClick={() => onLastMonthClick(12)}>1 Year</span>
          {/* <span className='bg-extraLightGray px-2 py-1 rounded-md' onClick={onLastSevenClick}>7 Days</span> */}
          {/* <span className='bg-extraLightGray px-2 py-1 rounded-md' onClick={() => onLastMonthClick(1)}>Last Month</span> */}
          {/* <span className='bg-extraLightGray px-2 py-1 rounded-md' onClick={() => onLastMonthClick(3)}>3 Months</span> */}
          {/* <span className='bg-extraLightGray px-2 py-1 rounded-md' onClick={() => onLastMonthClick(6)}>6 Months</span> */}
        </div>
      </div>
      <div className='flex justify-end w-full'>
        <span className='self-center text-white bg-NeutralGray-600 px-3 py-2.5 rounded-full' onClick={clearStartDate}>
          Clear
        </span>
        <span className='self-center text-white bg-PrimaryLightOrange-600 px-3 py-2.5 rounded-full ml-2' onClick={applyChanges}>
          Apply
        </span>
      </div>
    </div>
  );
};
