/*
 * @exports
 * @file LandingPage.js
 * @author Prakash
 * @copyright Tachyon. All rights reserved.
 */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { costGetCostTrend, getCostFilters, getCostFilterValues, listCostTemplates, getCostAllTagsKeys, getCostAllTagsValues, setCostPropsDetails, getCostAllocationTags } from '../../../actions/cost/CostAction'

import _ from 'lodash'
import moment from 'moment'
import { currentUTCtime, momentDateGivenFormat, defaultDateRangeValue } from '../../../utils/utility'
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import { CSVLink } from 'react-csv';
import Search from '../../common/SearchWithHiddenInput'
import SavedFiltersSidePanel from './SavedFiltersSidePanel';
import { useCallback } from 'react';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Button from '../../common/Form/Button';
import SmartDateRangePicker from '../../common/SmartDateRangePicker';
import ObjectKeyValueSelectOption from './ObjectKeyValueSelectOption';
import RadioOption from '../../common/Form/RadioOption';
import Checkbox from '../../common/Form/Checkbox';

const LandingPage = () => {
	const durationRef = useRef();
	const tagRef = useRef();
	const searchRef = useRef();

	const [dateRange, setDateRange] = useState(defaultDateRangeValue());
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue());
	
	const[state, setState] = useState({
		showLoading: true,
		providers: [],
		selectedDuration: 'currentMonth',
		colors: ["#8e3a80", "#ff8200", "#D2291C", "#43B02A", "#0057b8", "#FFCD00"],
		transColor: ["#8e3a80", "#000000"],
		granualrityOptions: [
			{label: 'Daily', value: "daily"},
			{label: 'Hourly', value: "hourly"},
			{label: 'Monthly', value: "monthly"}
		],
		selectedGranularity: 'daily'
	})

	const tagKeys = useSelector(state => state?.cost?.costTagKeys || false);
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const costFilterValues = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.costFilterValues ? state.cost.costPropsDetails.costFilterValues : {});

    const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
			let params = {}
			dispatch(listAllProviders(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({
							...prevState,
							providers: response,
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
							callSearch: true,
							showLoading: response && !response.length ? false : true
						}));
					}
				})
		}
		
    }, [dispatch, providers]);
	
	useEffect(() => {
		if(state.showLoading) {
			setTimeout(() => { setTimeout(prevState => ({ ...prevState, showLoading: false })) }, 5000);
		}
	}, [state.showLoading])

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
        let datePickerStartDate = momentDateGivenFormat(queryDate[0], 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = momentDateGivenFormat(queryDate[1], 'YYYY-MM-DD 23:59:59')
		setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, callFormSelectedFitlersFunction: true }))
    }, [queryDate])

	const getCostFiltersFunction = useCallback(() => {
		let params = {}
		params.cost_source = "cost_explorer"
		dispatch(getCostFilters(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, costFiltersResponse: response }))
				}
			})
	}, [dispatch])

	// Call getCostFilters whenever the providers state is updated
    useEffect(() => {
        if (state.providers && state.providers.length) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			if(state.costFiltersResponse && Object.entries(state.costFiltersResponse).length) {
				let resResult = {}
				let selectedSource = ""
				if(selectedProvider && state.costFiltersResponse[selectedProvider]) {
					let results = state.costFiltersResponse[selectedProvider]
					if(results && Object.entries(results).length) {
						resResult = results[Object.keys(results)[0]]
						selectedSource = Object.keys(results)[0]
					}
				}

				//sort object
				const sortedArray = Object.entries(resResult)
				.sort(([, a], [, b]) => a.order - b.order);
			
				const sortedObject = Object.fromEntries(sortedArray);

				setState(prevState => ({ ...prevState, costFilters: sortedObject, selectedSource, showLoading: selectedSource ? true : false, onChangeProvider: true }))
			} else {
				getCostFiltersFunction();
			}
        }
    }, [state.providers, state.selectedProvider, state.costFiltersResponse, getCostFiltersFunction]);

	useEffect(() => {
		if(state.costFilters && Object.entries(state.costFilters).length) {
			setState(prevState => ({ ...prevState, callStructureCostFilterResponse: true, callListCostTemplatesFunction: true }))
		}
	}, [state.costFilters])

	useEffect(() => {
		if(state.selectedSource && state.callListCostTemplatesFunction) {
			let params = {}
			dispatch(listCostTemplates(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listData: response, filteredListData: response, searchResultState: response, callListCostTemplatesFunction: false }))
					}
				})
		}
	}, [dispatch, state.selectedSource, state.callListCostTemplatesFunction])
	
	useEffect(() => {
		if(state.callStructureCostFilterResponse) {
			setState(prevState => ({ ...prevState, callStructureCostFilterResponse: false }))
			let costFilters = state.costFilters
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					setState(prevState => ({ ...prevState, showLoading: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))
				}
			})
		}
	}, [state.callStructureCostFilterResponse, state.costFilters])

	useEffect(() => {
		if(state.onChangeProvider) {
			let costFilters = state.costFilters
	
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					let selectedProvider = state.selectedProvider.toLowerCase()
					let costSources = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
					
					let resResult = {}
					let selectedSource = ""
					if(costSources && Object.entries(costSources).length) {
						resResult = costSources[Object.keys(costSources)[0]]
						selectedSource = Object.keys(costSources)[0]
					}

					//sort object
					const sortedArray = Object.entries(resResult)
					.sort(([, a], [, b]) => a.order - b.order);
				
					const sortedObject = Object.fromEntries(sortedArray);
	
					setState(prevState => ({ ...prevState, costSources, costFilters: sortedObject, selectedSource, onChangeProvider: false }))
				}
			})
		}
	}, [state.onChangeProvider, state.costFilters, state.costFiltersResponse, state.selectedProvider])

	// function to search every object in array by recurseive
	const recursiveSearch = useCallback((item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}, [])

	useEffect(() => {
		let results = state.listData
		if (state.tempSearchText && state.tempSearchText.length > 2) {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.tempSearchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

		setState(prevState => ({ ...prevState, searchResultState: results }))
	}, [state.tempSearchText, recursiveSearch, state.listData])

	const clearFilter = () => {
		let costFilters = state.costFilters
		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			state["filteredDropOptions_"+cIndex] && state["filteredDropOptions_"+cIndex].forEach((row, index) => {
				setState(prevState => ({ ...prevState, ["input_change_"+cIndex+"_"+index]: false }))
			})
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "", ["selected_value_"+cIndex]: "" }))
				}
			} else {
				setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				if(cValue.output_type === "string") {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: "" }))
				} else {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: [], ["select_all_"+cIndex]: false }))
				}
			}
		})

		setState(prevState => ({ ...prevState, clearFilter: true, tagsSelected: [], selectedTags: [], selectedGranularity: 'daily' }))
	}

	useEffect(() => {
		if(state.clearFilter) {
			setState(prevState => ({ ...prevState, clearFilter: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.clearFilter])

	useEffect(() => {
		if(state.callCostGetCostTrendFunction && state.selectedSource) {
			setState(prevState => ({ ...prevState, callCostGetCostTrendFunction: false }))
			let params = {}
			params.provider = state.selectedProvider.toLowerCase()
			params.source = state.selectedSource
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion
			}
			params.duration_aggregate_by = state.selectedGranularity

			params.start_time = state.datePickerStartDate
			params.end_time = state.datePickerEndDate
			

			if(state.tagsSelected && state.tagsSelected) {
				params.tags = state.tagsSelected
			}
			if(state.forecast) {
				params.forecast = state.forecast
			}
			let excludes = {}
			let groupBy = ""
			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue && (cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values"))) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						if(cValue.output_field === "aggregate_by") {
							Object.entries(cValue.mapped_values).length && Object.entries(cValue.mapped_values).forEach(([mKey, mValue]) => {
								if(state["input_change_"+cIndex] === mValue) {
									groupBy = mKey
								}
							})
						}
						params[cValue.output_field] = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						if(state["includes_"+cIndex] === "Includes") {
							params[cValue.output_field] = state["selected_value_"+cIndex]
						} else {
							excludes[cValue.output_field] = state["selected_value_"+cIndex]
						}
					}
				}
			})
	
			if(excludes && Object.entries(excludes).length) {
				params.excludes = excludes
			}
			if(params.aggregate_by && params.aggregate_by !== "") {
				params.radial = true				
			}
			
			let granualrity = ""
			if(params.duration_aggregate_by && params.duration_aggregate_by !== "") {
				granualrity = params.duration_aggregate_by
			}
			params.cost_source = "cost_explorer"
	
			dispatch(costGetCostTrend(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, trendData: response.results ? response.results : [], applyLoading: false, trendGraphData: {}, groupBy, granualrity, showLoading: false }))
					}
				})

		}
	}, [state.callCostGetCostTrendFunction, dispatch, state])
	

	const trendGraphStructure = useCallback(() => {
        let graphData = {}
		let labels = []
		let forcastMessage = ""
        state.trendData && state.trendData.forEach(item => {
            if(item.data && Array.isArray(item.data)) {
				let spend = []
			    let forecast = []
			    item.dates.forEach((lab, index) => {
				    labels.push(lab)
				    spend.push(item.data[index])
					forecast.push(0)
			    })
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        labels.push(lab)
				        spend.push(0)
					    forecast.push(item.forecast.data[index])
				    })
				}
				graphData.labels = labels
				graphData.spend = spend
				if(item.forecast && item.forecast.dates) {
				    graphData.forecast = forecast
				}
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				labels.push()
				Object.entries(item.data).forEach(([key, value]) => {
					graphData[key] = value
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && !Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				let data = []
				graphData.labels.forEach(row => {
					data.push(0)
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
				graphData["cost"] = data
			} else {
				graphData["cost"] = item.data
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			}
        })
		
        setState(prevState => ({ ...prevState, trendGraphData: graphData, forcastMessage }))
    }, [state.trendData])

	const trendTableData = useCallback(() => {
		var trendTable = []
		state.trendData && state.trendData.forEach(item => {
            let labels = item.dates ? item.dates : []
            if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				Object.entries(item.data).forEach(([key, value]) => {
					// let totalCount = value.reduce(function(a, b){
					// 	return a + b;
					// }, 0);
					var result = {
						"service": key,
						'total': item.count && item.count[key] ? item.count[key] : 0
					}
				
					value.forEach((item,index) => {
						result[labels[index]] = item
					})
					trendTable.push(result);
				})
			} else if(item.data && Array.isArray(item.data)) {

				let data = item.data
				// let totalCount = item.data.reduce(function(a, b){
				// 	return a + b;
				// }, 0);
				var result = {
					"service": "Cost",
					'total': item.total
				}
				
				data.forEach((item,index) => {
					if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						result[labels[index]] = item
					}
				})
				
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        result[lab] = item.forecast.data[index]
				    })
				}

				trendTable.push(result);
			}
		})
		
		if(trendTable.length) {
			const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
				let sticky = false
				let header = ''
				let width = '100%'
				let textColor = ''
				// if(state.durationSelector > 7) {
				// 	width = 75
				// }
				if(tKey === 'service') {
					sticky = true
					header = state.groupBy
					width = 300
				} else if(tKey === 'total') {
					sticky = true
					header = 'Total'
					width = 75
				} else {
					if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						textColor = "text-slateBlue-600"
					}
					if(state.granualrity === 'monthly') {
						header = moment(tKey).format('MMM YY')
						width = 75
					} else  if(state.granualrity === 'daily') {
						header = moment(tKey).format('MMM DD')
						width = 75
					} else  {
						header = moment(tKey).format('HH mm')
						width = 75
					}
				}
				return {
					Header: header,
					accessor: tKey,
					width: width,
					sticky: sticky ? "left" : "",
					textColor: textColor
				}
			})
			
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		} else {
			const columns = []	
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		}
	}, [state.granualrity, state.groupBy, state.trendData])

	useEffect(() => {
		trendGraphStructure()
		trendTableData()
	}, [state.trendData, trendTableData, trendGraphStructure])

	useEffect(() => {
		if(state.filteredArray && state.filteredArray.length) {
			let headers = [] 
			Object.keys(state.filteredArray[0]).forEach(item => {
				let dataRow = {}
				dataRow.label = item
				dataRow.key = item
				headers.push(dataRow)
			})

			setState(prevState => ({ ...prevState, headers }))
		}
	}, [state.filteredArray])

	useEffect(() => {
		if(state.callOnChangeSourceFunction) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			let resResult = {}
		
			let results = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
			if(results && Object.entries(results)) {
				resResult = results[state.selectedSource]
			}

			//sort object
			const sortedArray = Object.entries(resResult)
			.sort(([, a], [, b]) => a.order - b.order);
		
			const sortedObject = Object.fromEntries(sortedArray);
		
			setState(prevState => ({ ...prevState, costFilters: sortedObject, callOnChangeSourceFunction: false }))
		}
	}, [state.callOnChangeSourceFunction, state.costFiltersResponse, state.selectedProvide, state.selectedSource, state.selectedProvider])
	//on change filter items

	useEffect(() => {
		if(!tagKeys) {
			let params = {}
			params.cost_source = "cost_explorer"
			dispatch(getCostAllTagsKeys(params))
		}
	}, [dispatch, tagKeys])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.provider = state.selectedProvider.toLowerCase()
			params.tags = [{ "key": state.tag_key }]
			params.cost_source = "cost_explorer"
			params.aggregate_by = "TAG"
			params.aggregate_by_tag = state.tag_key
			dispatch(getCostAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [dispatch, state.selectedProvider, state.tag_key])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			state.tag_value && state.tag_value.length && state.tag_value.forEach(row => {
				let dataRow = {
					key: state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			setState(prevState => ({ ...prevState, tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, tagsSelected: filteredResult }))
	}

	const onClickGetDropOptions = (cKey, cValue, cIndex) => {
		
		let params = {}
		if(state.selectedProvider) {
			params.provider = state.selectedProvider.toLowerCase()
		}
		if(state.selectedSource) {
			params.source = state.selectedSource
		}
		if(state.selectedAccount && state.selectedAccount.length) {
			params.account_id = state.selectedAccount
		}
		if(state.selectedRegion && state.selectedRegion.length) {
			params.region = state.selectedRegion
		}
		let filterSelected = []
		state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {			
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
					if(cValue.output_field === "duration_aggregate_by") {
						params[cValue.output_field] = state["input_change_"+cIndex]
						filterSelected.push(cValue.output_field)
					}
				}
			}
			
			if(cValue.output_field === "resource_type") {
				if(state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				} else if(state["selected_value_"+cIndex] && !Array.isArray(state["selected_value_"+cIndex])) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				}
			}
		})

		params.aggregate_by = cValue.output_field
		params.start_time = state.datePickerStartDate
		params.end_time = state.datePickerEndDate
		params.cost_source = "cost_explorer"
		// removeSpacebtwString
		if(!cValue.hasOwnProperty("mapped_values") && !cValue.hasOwnProperty("values")) {
			if(!costFilterValues.hasOwnProperty(state.selectedSource+"_"+cValue.output_field)) {
				dispatch(getCostFilterValues(params))
					.then((response) => {
                        if(response) {
							let results = response.results ? response.results : []
							setState(prevState => ({
								...prevState, 
								["hideDropLoading_"+cIndex]: true, 
								["dropOptions_"+cIndex]: results, 
								["filteredDropOptions_"+cIndex]: results,
								filterSelected,
							}))

							if(results.length && _.isEqual(filterSelected, state[filterSelected])) {
								let obj = costFilterValues
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							} else {
								let obj = {}
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							}
						}
					})
			} else {
				setState(prevState => ({
					...prevState, 
					["hideDropLoading_"+cIndex]: true, 
					["dropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field], 
					["filteredDropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field]
				}))
			}
		}

		state.costFilters && Object.entries(state.costFilters).forEach(([loopKey, loopValue], loopIndex) => {
			if(loopIndex !== cIndex) {
				setState(prevState => ({ ...prevState, ["showDetails_"+loopIndex]: false }))
			} else {
				setState(prevState => ({ ...prevState,  ["showDetails_"+cIndex]: !prevState["showDetails_"+cIndex] }))
			}
		})

		setState(prevState => ({ ...prevState, showDateRangePicker: false, showTagFilter: false }))
	}

    const searchData  = (e, searchKey, cIndex, state) => {
		searchKey = searchKey.toLowerCase()
        let newList = []
		if (searchKey !== "" && searchKey.length >=3 ) {
			let currentList = state
			if(Array.isArray(currentList) && currentList.every(item => typeof item === 'string')) {
				let searchTerm = searchKey.toLowerCase();
				newList = currentList.filter(e => e.toLowerCase().includes(searchTerm));
			} else if(typeof currentList === 'object') {
				newList = Object.entries(currentList).filter(([key, value]) => 
					key.toLowerCase().includes(searchKey) || value.toLowerCase().includes(searchKey)
				).reduce((obj, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {});
			} else {
				newList =
					currentList &&
					currentList.filter(item => {
						let isPresent = []
						isPresent = recursiveSearch(item, searchKey)
							.flat(Infinity)
							.filter(bool => bool === true)  // for multi arrray [[1,2],[3,4],[4,5]]
						if (isPresent[0]) {
							return true
						} else {
							return false
						}
					})
			}
		} else {
            newList = state
		}
        setState(prevState => ({ ...prevState, ["filteredDropOptions_"+cIndex]: newList }))
    }

	const onChangeSelectInput = (state, value, field, index, cIndex, cKey) => {
		if(cKey === 'Group by' && value === 'TAG') {
			dispatch(getCostAllocationTags({}))
				.then((response) => {
					let tagDropOPtions = response && response.results ? response.results : []

					setState(prevState => ({ ...prevState, ["tagDropOPtions_"+cIndex]: tagDropOPtions }))
				})
		} else if(cKey === 'Group by' && value !== 'TAG') {
			let aggregate_by_tag = ''
			setState(prevState => ({ ...prevState, aggregate_by_tag }))
			setState(prevState => ({ ...prevState, ["tagDropOPtions_"+cIndex]: [] }))
		}
		
		setState(prevState => ({ ...prevState, [field+"_"+cIndex]: value, ["selected_value_"+cIndex]: value, callFormSelectedFitlersFunction: true, ["showDetails_"+cIndex]: false }))
	}

	const onChangeMultiSelectInput = (options, value, field, index, cIndex) => {
		let selectedValue = state["selected_value_"+cIndex] ? state["selected_value_"+cIndex] : []
		if(value !== "All") {
			if(state[field+"_"+cIndex+"_"+index]) {
				selectedValue = selectedValue.filter(e => e !== value)
				setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			} else {
				selectedValue.push(value)
				setState(prevState => ({ ...prevState, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			}
		} else {
			if(state["select_all_"+cIndex]) {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+rIndex]: false, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			} else {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					selectedValue.push(row)
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: true, [field+"_"+cIndex+"_"+rIndex]: true, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			}
		}
	}

	useEffect(() => {
		if(state.callFormSelectedFitlersFunction) {
			let selectedFilters = []
			
			if(state.datePickerStartDate) {
				let dataRow = {}
				dataRow.label = "Duration"
				dataRow.start_time = state.datePickerStartDate
				dataRow.end_time = state.datePickerEndDate

				selectedFilters.push(dataRow)
			}

			if(state.tagsSelected && state.tagsSelected.length) {
				let dataRow = {}
				dataRow.label = "Tags"
				dataRow.output_field = "tags"
				dataRow.value = state.selectedTags

				selectedFilters.push(dataRow)
			}

			if(state.selectedAccount && state.selectedAccount.length) {
				let dataRow = {}
				dataRow.label = "Account"
				dataRow.output_field = "account_id"
				dataRow.type = 'Includes'
				dataRow.value = state.selectedAccount

				selectedFilters.push(dataRow)
			}

			if(state.selectedGranularity) {
				let dataRow = {}
				dataRow.label = "Granularity"
				dataRow.output_field = "duration_aggregate_by"
				dataRow.value = state.selectedGranularity

				selectedFilters.push(dataRow)
			}

			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				let dataRow = {}
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						let outputLabel = state["input_change_"+cIndex]
						if(Object.entries(cValue.mapped_values).length) {
							outputLabel = Object.keys(cValue.mapped_values).find(key => cValue.mapped_values[key] === state["input_change_"+cIndex]);	
						}
						dataRow.label = cKey
						dataRow.output_label = outputLabel
						dataRow.output_field = cValue.output_field
						dataRow.value = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						dataRow.label = cKey
						dataRow.output_field = cValue.output_field
						dataRow.type = state["includes_"+cIndex]
						dataRow.value = state["selected_value_"+cIndex]
					}
				}
				
				if(Object.entries(dataRow).length) {
					selectedFilters.push(dataRow)
				}
			})

			if(state.aggregate_by_tag) {
				let dataRow = {}
				dataRow.label = "aggregate_by_tag"
				dataRow.output_field = "aggregate_by_tag"
				dataRow.value = state.aggregate_by_tag

				selectedFilters.push(dataRow)
			}

			setState(prevState => ({ ...prevState, selectedFilters, callFormSelectedFitlersFunction: false }))
		}
	}, [state.callFormSelectedFitlersFunction, state])

	const handleChildClick = (event, type, dropdownType, section) => {
        if (tagRef.current && !tagRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showTagFilterSection: false }))
		}

		if (durationRef.current && !durationRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }))
		}

		if (searchRef && !searchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const refreshFilters = (row) => {		
		let savedFilters = row.filters
		if(row["Duration"]) {
			setState(prevState => ({ 
				...prevState,
				datePickerStartDate: row["Duration"].start_time,
				datePickerEndDate: row["Duration"].end_time
			}))
		}

		if(row["Tags"]) {
			setState(prevState => ({ ...prevState, selectedTags: row["Tags"].value, tagsSelected: row["Tags"].value }))
		}


		let costFilters = state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
				}
			} else {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ 
						...prevState, 
						["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value, 
						["includes_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].type
					}))
				} else {
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				setState(prevState => ({ ...prevState, refereshFilters: true }))}
		})
	}

	useEffect(() => {
		if(state.refereshFilters) {
			setState(prevState => ({ ...prevState, refereshFilters: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.refereshFilters])

	const onClickMore = (row) => {
		setState(prevState => ({ ...prevState, savedFilters: row, templateType: "view", clickedMore: true }))
	}

	useEffect(() => {
		if(state.clickedMore){
			setState(prevState => ({ ...prevState, clickedMore: false, showTemplatesSection: true }))
		}
	}, [state.clickedMore])

	const handleSearchChange = e => {
        const { name, value } = e.target;
		// let params = {}
		// params.template_name = value
		// if(value.length > 3) {
		// 	dispatch(listCostTemplates(params))
		// 		.then((response) => {
		// 			if(response) {					
		// 				setState(prevState => ({ ...prevState, searchTemplateList: response }))
		// 			}
		// 		})
		// }

		setState(prevState => ({ ...prevState, [name]: value, searchResultState: [], isLoading: true }))
	}

	const removefromList = (value, listField) => {
		let filteredListItem = state[listField+''].filter((e) => e !== value);

		let array = [];
		filteredListItem.forEach((row) => {
			let rowValue = {
				value: row,
				label: row,
			};
			array.push(rowValue);
		});
		// let clusterConfiguration = createUpdateCluster && createUpdateCluster.cluster_configuration ? createUpdateCluster.cluster_configuration : {};
		// clusterConfiguration.security_group_ids = filteredListItem

		setState((prevState) => ({ ...prevState, [listField+'']: filteredListItem }));
	};

	/**
	 * Renders
	 */
	return (
		<div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading || !state.trendData || !state.trendData[0] ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
			{state.showTemplatesSection ?
				<SavedFiltersSidePanel
					closeSidePanel={() => setState(prevState => ({ ...prevState, showTemplatesSection: false, savedFilters: [], callListCostTemplatesFunction: true }))}
					templateType={state.templateType}
					selectedSource={state.selectedSource}
					selectedFilters={state.savedFilters && Object.entries(state.savedFilters).length ? state.savedFilters : state.selectedFilters}
					selectedProvider={state.selectedProvider}
				/>
			: null}
			
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Cost Explorer</p>
                        <p className="text-white text-base">New cost and usage report</p>
                    </div>
					<div className="lg:w-3/4 w-full flex justify-end">
						<div className='mr-3 self-end'>
							<SelectOption
								label={"Provider"}
								labelClass={{color: 'text-white'}}
								fields={["provider_name", "provider_name"]}
								options={state.providers}
								selectedValues={state.selectedProvider ? state.selectedProvider : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedProvider: value }))
								}}
								singleSelection={true}
								classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
							/>
						</div>
						<div className='mr-3 self-end'>
							<SelectOption
								label={"Source"}
								labelClass={{color: 'text-white'}}
								options={state.costSources ? Object.keys(state.costSources) : []}
								selectedValues={state.selectedSource ? state.selectedSource : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedSource: value, callOnChangeSourceFunction: true }))
								}}
								singleSelection={true}
								classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
							/>
						</div>
						<div className='mr-3 self-end'>
							<SelectOption
								label={"Account"}
								labelClass={{color: 'text-white'}}
								fields={["account_id", "account_name"]}
								options={state.accounts}
								selectedValues={state.selectedAccount ? state.selectedAccount : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedAccount: value }))
									clearFilter()
								}}
								classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
							/>
						</div>
						<div className='mr-3 self-end'>
							<SelectOption
								label={"Region"}
								labelClass={{color: 'text-white'}}
								fields={["region", "name"]}
								options={state.regions}
								selectedValues={state.selectedRegion ? state.selectedRegion : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedRegion: value }))
									clearFilter()
								}}
								classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
							/>
						</div>
						<div className='mr-3 self-end'>
							<SelectOption
								label={"Granularity"}
								labelClass={{color: 'text-white'}}
								fields={["value", "label"]}
								options={state.granualrityOptions}
								selectedValues={state.selectedGranularity ? state.selectedGranularity : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedGranularity: value, callOnChangeSourceFunction: true }))
								}}
								singleSelection={true}
								classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
							/>
						</div>
						<div className='mr-3 self-end' ref={tagRef}>
							<p className={`text-base text-white flex w-full`}>Tags</p>
							<p className={`flex justify-between border border-NeutralGray-400 bg-white py-2 pl-3 pr-4 rounded-lg`} onClick={() => setState(prevState => ({ ...prevState, showTagFilterSection: true }))} ref={tagRef}>
								<span className={`truncate text-textGray-600 text-lg`}>
									{state.selectedTags && state.selectedTags.length ? state.selectedTags.length + ' Selected' : 'Select'}
								</span>
								<Icon icon="icon-park-outline:down" className={`text-textGray-600 ml-1 self-center`} width={24} height={24}/>                        
							</p>

							{state.showTagFilterSection ?
								<div className="relative">
									<div className="absolute z-10 border-lightGray-600 pt-3 w-[600px] right-0">
										<div className="p-4 rounded-lg bg-white shadow-2xl">
											<div className={`w-full mt-1`}>
												<p className="mb-2 w-100 text-black text-base">Tags</p>
												<div className={`flex mt-3`}>
													<div className='w-5/12 pr-3'>
														<SelectOption
															label={"Key"}
															options={tagKeys}
															selectedValues={state.tag_key ? state.tag_key : ''}
															callbackMultiSelect={(value) => {
																setState(prevState => ({ ...prevState, tag_key: value, tagValues: [] }))
																// setState(prevState => ({ ...prevState, tag_key: value }))
															}}
															singleSelection={true}
															classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
														/>
													</div>
													{state.tag_key && state.tag_key !== "" && !state.tagValues.length ?
														<div className='ml-3 w-3/5'>
															<LoadingCircle />
														</div>
													:
														<React.Fragment>
														<div className='w-6/12 pr-3'>
															<SelectOption
																label={"Value"}
																options={state.tagValues}
																selectedValues={state.tag_value ? state.tag_value : []}
																callbackMultiSelect={(value) => {
																	setState(prevState => ({ ...prevState, tag_value: value }))
																}}
																classDetails={{common:'uppercase', padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
															/>
														</div>
														<div className='w-1/12 self-center pt-4'>
															<Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewTag("tags")} />
														</div>
														</React.Fragment>
													}
												</div>
											</div>
											{state.tagsSelected && state.tagsSelected.length ? 
												<div className='flex flex-wrap'>
													{state.tagsSelected.map((tag, index) => {
														return(
															<span key={'tt_'+index} className="flex border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center">{tag.key +(tag.value ? " : "+tag.value : "")}
																<Icon icon="ion:close-circle-sharp" width="16" height="16" className='cursor-pointer text-black ml-2 self-center' onClick={ () => removeTagSection(tag) } />
															</span>
														)
													})}
												</div>
											: null}
											
											<div className="flex justify-end mt-4">
												<Button
													classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-2 mr-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20'}}
													label={'Clear'}
													callback={() => {
														setState(prevState => ({ ...prevState, tagsSelected: [], selectedTags: [], tag_key: "", tag_value: [], tagValues: [] }))
													}}
												/>
												<Button
													classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20'}}
													label={'Select'}
													callback={() => {
														setState(prevState => ({ ...prevState, showTagFilter: false, selectedTags: state.tagsSelected, callFormSelectedFitlersFunction: true }))
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							: null}
						</div>
						<div className='self-end'>
							<label className='text-white text-base font-medium'>Duration</label>
							<SmartDateRangePicker
								dateRange={dateRange}
								setDateRange={setDateRange}
								setQueryDate={setQueryDate}
								queryDate={queryDate}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='p-6 bg-white rounded-2xl'>
                <div className='flex flex-wrap'>
					<div className='lg:w-3/5 w-full flex flex-col'>
						<p className='font-semibold m-0 f15 text-black text-lg'>Trends</p>
						{state.forcastMessage && state.forcastMessage !== "" ?
							<h6 className='text-danger mb-1'>{state.forcastMessage}</h6>
						: null}
						{!state.showSlectedFilters ?
							<div className='flex pt-2 justify-between'>
								<div className='flex'>
									{state.selectedFilters && state.selectedFilters.length ? 
										<React.Fragment>
											{state.selectedFilters.filter(e => e.label === "Account").length ?
												<div className='flex mr-2'>
													<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-md">Account : {state.selectedFilters.filter(e => e.label === "Account")[0].value} </small>
												</div>
											: 
												<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-sm mr-2">Account : <span className='text-slateBlue-600'>All</span></small>
											}	

											{state.selectedFilters.filter(e => e.label === "Region").length ?
												<div className='flex mx-2'>
													<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-md">Region : {state.selectedFilters.filter(e => e.label === "Region")[0].value} </small>
													{/* {state.selectedFilters.filter(e => e.label === "Region")[0].value.length > 1 ?
														<span className="text-fino mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
													: null} */}
												</div>
											: 
												<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-md mx-2">Region : <span className='text-slateBlue-600'>All</span></small>
											}
											{state.selectedFilters.filter(e => e.label === "Granularity").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-sm mr-2">Granularity : <span className="text-slateBlue-600">{state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label ? state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label : state.selectedFilters.filter(e => e.label === "Granularity")[0].value}</span> </small>
												</div>
											: null}
											{state.selectedFilters.filter(e => e.label === "Group by").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-sm mr-2">Group by : <span className="text-slateBlue-600">{state.selectedFilters.filter(e => e.label === "Group by")[0].output_label ? state.selectedFilters.filter(e => e.label === "Group by")[0].output_label : state.selectedFilters.filter(e => e.label === "Group by")[0].value}</span> </small>
												</div>
											: null}
											{state.selectedFilters.filter(e => e.label === "Duration").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-NeutralGray-700 text-sm mr-2">Duration : <span className='text-slateBlue-600'> {momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].end_time, 'DD MMM YYYY')}</span> </small>
												</div>
											: null}
										</React.Fragment>
									: null}
								</div>
								{state.selectedFilters && state.selectedFilters.length ? 
									<p className="text-slateBlue-600 mb-0 font-semibold self-center cursor-pointer mr-2 ml-4" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>{state.selectedFilters.length} Filters Selected</p>
								: null}
							</div>
						: null}
						{state.showSlectedFilters ?
							<div className="p-3 bg-NeutralGray-100 border border-NeutralGray-800 rounded-2xl">
								<div className='flex justify-between mb-1'>
									<p className="mb-0 w-100 text-black font-bold">Selected Filters</p>
									<Icon icon="ion:close-circle-sharp" width="16" height="16" className='cursor-pointer text-black ml-2 self-center' onClick={() => setState(prevState => ({ ...prevState, showSlectedFilters: false }))} />
								</div>
								<div className='flex flex-wrap'>
									{!state.selectedFilters.filter(e => e.label === "Account").length ?
										<small className="text-sm font-semibold self-center text-NeutralGray-700 cursor-pointer mr-2 mt-2">Account : <span className='text-slateBlue-600'>All</span></small>
									: null}

									{!state.selectedFilters.filter(e => e.label === "Region").length ?
										<small className="text-sm font-semibold self-center text-NeutralGray-700 cursor-pointer mx-2 mt-2">Region : <span className='text-slateBlue-600'>All</span></small>
									: null}
									
									{state.selectedFilters && state.selectedFilters.length ? 
										state.selectedFilters.map((filters, index) => {
											return(
												<React.Fragment key={'fil_'+index}>
												{filters.label === "Duration" ?
													<React.Fragment>
														<small className="text-sm font-semibold self-center text-NeutralGray-700 cursor-pointer mt-2 mr-2">Duration : <span className='text-slateBlue-600'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
													</React.Fragment>
												: filters.value && !Array.isArray(filters.value) ?
													<span className="bg-primaryPurple-100 rounded-full py-1 text-black mr-2 mt-2 self-center px-2"><span className='font-semibold'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.value}</span>
												: filters.label === "Tags" ?
													<div className='flex flex-wrap'>
														{filters.value.map(row => {
															return(
																<span className="bg-primaryPurple-100 px-2 py-1 rounded-full text-black mr-2 mt-2 self-center">
																	<span className='font-semibold'>Tag :</span> 
																	{row.key +" : "+row.value}
																</span>
															)
														})}
													</div>
												:
													<div className='flex flex-wrap'>
														{filters.value.map(row => {
															return(
																<span className="bg-primaryPurple-100 px-2 py-1 rounded-full text-black mr-2 mt-2 self-center">
																	<span className='font-semibold'>{filters.type+" : "}</span> 
																	{filters.label +" : "+row}
																</span>
															)
														})}
													</div>
												}
												</React.Fragment>
											)
										})
									: null}
								</div>
							</div>
						: null}
					</div>
					<div className='flex flex-col lg:w-2/5 w-full'>
						<div className='flex my-2 mr-4 self-end justify-end'>
							<span className='text-byzantineBlue cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showTemplatesSection: true, templateType: "template" }))}>View Templates</span>
						</div>
						<div className='flex flex-col self-end w-11/12'>
							<div className={`flex w-full self-center border border-NeutralGray-600 rounded-full bg-white`}>
								<Icon icon={`bitcoin-icons:search-outline`} className={`pl-2 text-black self-center`} width={40} height={40}/>
								<div className={`w-full text-base items-center self-center`}>
									<input
										ref={searchRef}
										type="text"
										className={`w-full self-center py-2.5 pl-2 rounded-full text-black`}
										name={"tempSearchText"}
										value={state.tempSearchText}
										onChange={handleSearchChange}
										placeholder='Search Template'
										onClick={(e => setState(prevState => ({ ...prevState, showDropdown: true })))}
									/>
								</div>
							</div>
							{state.showDropdown ?
							<div className={`bg-NeutralGray-100 shadow-lg border-b border-x border-NeutralGray-600 rounded-b-2xl mx-4 z-50 mt-12 absolute`} style={{ right: "3%", width: "30%" }}>
								{state.searchResultState && state.searchResultState.length ? 	
									<div className='p-2'>
									{state.searchResultState.map((row, index) => {
										return(
											<div key={index} className={`cursor-pointer border-b border-NeutralGray-800 p-2 hover:bg-aliceBlue`}>
												<div className="w-full" onClick={() => {
													clearFilter()
													refreshFilters(row)
												}}>
													<span className='font-semibold m-0 text-black text-left p-0'>{row.template_name ? row.template_name : ''}</span>
													<span className='small m-0 text-left p-0 text-NeutralGray-700'>{row.description ? " : "+row.description : ''}</span>
												</div>
												<div className='flex justify-between'>
													<div className="flex flex-wrap mt-2 w-100 self-center text-NeutralGray-700" onClick={() => {
														clearFilter()
														refreshFilters(row)
													}}>
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Account").length ?
															<div className='flex mr-2'>
																<small className="font-semibold self-center cursor-pointer text-black">Account : {row.filters.filter(e => e.label === "Account")[0].value} </small>
																{row.filters.filter(e => e.label === "Account").length > 1 ?
																	<span className="text-slateBlue-600 mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																: null}
															</div>
														: 
															<small className="font-semibold self-center cursor-pointer mr-2 text-black">Account : <span className='text-slateBlue-600'>All</span></small>
														}

														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Region").length ?
															<div className='flex mx-2'>
																<small className="font-semibold self-center cursor-pointer text-black">Region : {row.filters.filter(e => e.label === "Region")[0].value} </small>
																{row.filters.filter(e => e.label === "Region")[0].value.length > 1 ?
																	<span className="text-fino mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																: null}
															</div>
														: 
															<small className="font-semibold self-center cursor-pointer mx-2 text-black">Region : <span className='text-slateBlue-600'>All</span></small>
														}
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Granularity").length ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Granularity : <span className="text-slateBlue-600">{row.filters.filter(e => e.label === "Granularity")[0].output_label ? row.filters.filter(e => e.label === "Granularity")[0].output_label : row.filters.filter(e => e.label === "Granularity")[0].value}</span> </small>
															</div>	
														: null}
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Group by").length ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Group by : <span className="text-slateBlue-600">{row.filters.filter(e => e.label === "Group by")[0].output_label ? row.filters.filter(e => e.label === "Group by")[0].output_label : row.filters.filter(e => e.label === "Group by")[0].value}</span> </small>
															</div>
														: null}
														{row["Duration"] ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Duration : <span className='text-slateBlue-600'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
															</div>
														: null}
													</div>
													{Array.isArray(row.filters) && row.filters.length > 3 ?
														<span className="bg-slateBlue-600 rounded-full py-1 text-black mr-2 self-center px-2" onClick={() => onClickMore(row)}>more</span>
													: null}
												</div>
												{/* <div className="w-20 self-center">
													<span className="badge badge-outline-success mr-1">123</span>
													<span className="badge badge-outline-danger">123</span>
												</div> */}
												{/* <div className="w-10 self-center">
													<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
												</div> */}
											</div>
										)
									})}
									</div>
								: state.searchResultState && !state.searchResultState.length ? 
									<div className="cursor-pointer border-bottom p-2" >
										<div className="w-100 text-black"> No Templates</div>
									</div>
								:
									state.showDropdown && state.isLoading &&
										<LoadingCircle/>
								}
							</div>
							: null}
						</div>
					</div>
				</div>
				<div className='flex flex-wrap overflow-y-auto mt-4'>
					<div className={`${state.hideFilterPanel ? "lg:w-11/12 md:w-10/12" : "lg:w-4/5 md:w-4/6"} flex flex-col pr-4`}>
						<div className='bg-NeutralGray-100 border border-NeutralGray-800 p-4 rounded-2xl'>
							{state.trendData && state.trendData[0] ?
								<div className='flex justify-between'>
									<div className=''>
										<p className='mb-0'>Total Cost</p>
										<p className='mb-0 text-xl text-black'>
											${state.trendData[0].total && state.trendData[0].total}
										</p>
									</div>
									<div className='border-l-2 border-NeutralGray-600 pl-2'>
										<p className='mb-0'>Average Cost</p>
										<p className='mb-0 text-xl text-black'>
											${state.trendData[0].avg && state.trendData[0].avg}
										</p>
									</div>
								</div>
							: null}
							{state.trendGraphData && Object.entries(state.trendGraphData).length ?
								<ApexStackedBarChart
									tooltipType={"dontConvertDate"}
									start_time={state.datePickerStartDate}
									end_time={state.datePickerEndDate}
									graphData={state.trendGraphData}
									sparkline={false}
									yaxis={true}
									yaxisLabel={true}
									xaxis={true}
									xaxisFormat={state.granualrity === "monthly" ? "category" : state.granualrity === "hourly" ? "datetime" : 'date'}
									xaxisLabel={true}
									axisLabelFontSize={'9px'}
									axisLabelColor={'#495057'}
									paddingLeft={-5}
									legend={true}
									stacked={true}
									height={450}
									horizontal={false}
									barHeight={'40%'}
									barEndShape={'flat'}
									columnWidth={state.granualrity === "monthly" ? '5%' :  ""}
									gradient={true}
									gradientColor={['#039BE5', '#5F5BA2']}
									hideTooltipValue={true}
									backgroundBarShape={'flat'}
									backgroundBarColors={['#E6E6E6']}
									showBackgroundBarColors={true}
									className={"transparentTooltip"}
									// colors={["#EDDCD2", "#FFF1E6", "#FDE2E4", "#FAD2E1", "#C5DEDD", "#DBE7E4", "#F0EFEB", "#D6E2E9", "#BCD4E6", "#99C1DE", "#CDB4DB"]}
									colors={state.forecast && !state.forcastMessage ? state.transColor : state.colors}
									forecast={state.forecast && !state.forcastMessage ? true : false}
								/>
							: null}
						</div>
						{state.trendTable ?
							<div className="mt-3">
								<div className="flex justify-between self-center mt-4 py-2">
									<p className="mb-0 self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.trendTable && state.trendTable.length} Cost and usage breakdown</p>
									{state.trendTable && state.trendTable.length ?
										<div className="flex">
											<div className={`mr-4`}>
												<Search
													data={state.trendTable ? state.trendTable : []}
													filteredData={filteredArray => {
														setState(prevState => ({ ...prevState, filteredArray }))
													}}
													topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                            		className={'rounded-full'}
												/>
											</div>
											<CSVLink
												data={state.filteredArray ? state.filteredArray : []} 
												headers={state.headers ? state.headers : []}
												filename={"cost-explorers.csv"}
												className={"self-center"}
												target="_blank"
											>
												<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
											</CSVLink>
										</div>
									: null}
								</div>
								{state.columns && state.columns.length ? 
									<div className="overflow-x-scroll mt-4">
										<table className="w-full text-left">
											<thead className="">
												<tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
													{state.columns.map((col, colIndex) => {
														return(
															<th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 text-left ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
														)
													})}
												</tr>
											</thead>
											<tbody className="bg-white text-black">
												{state.filteredArray && state.filteredArray.length ?
													state.filteredArray.map((col, colIndex) => {
														return(
															<tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
																{state.columns.map((obj, objIndex) => {
																	return(
																		<td key={objIndex+'_'+colIndex} className={`p-4 text-black ${objIndex ? 'text-right' : 'text-left'} text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100 ' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
																	)
																})}
															</tr>
														)
													})
												: null}
											</tbody>
										</table>
									</div>
								: null}
							</div>
						: null}
					</div>
					<div className={`${state.hideFilterPanel ? "lg:w-1/12 md:w-2/12" : "lg:w-1/5 md:w-2/6"} flex flex-col`}>
						<div className='bg-NeutralGray-400 border border-NeutralGray-600 rounded-2xl p-3'>
							<div className={`flex flex-wrap justify-between ${!state.hideFilterPanel ? 'mb-2' : ''}`}>
								<p className="font-semibold text-base mb-1 lg:w-1/2 w-full" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: !state.hideFilterPanel }))}>Filters</p>
								{state.hideFilterPanel ?
									<Icon icon="basil:expand-outline" width={16} height={16} className={`text-white 	bg-PrimaryLightOrange-600 h-6 w-6 rounded-full self-center cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: false }))} />
								: 
									<div className='flex justify-end lg:w-1/2 w-full'>
										<p className="text-slateBlue-600 font-semibold self-center cursor-pointer mr-2 text-nowrap" onClick={ () => setState(prevState => ({ ...prevState, showTemplatesSection: !state.showTemplatesSection, templateType: "save" }))}>Save Template</p>
										<Icon icon="la:compress" width={20} height={20} className={`text-white bg-PrimaryLightOrange-600 h-6 w-6 rounded-full self-center cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: true }))} />
									</div>
								}
							</div>
							{!state.hideFilterPanel ?
								<React.Fragment>
								<div className="flex flex-wrap mb-2 justify-between">
									<div className='flex w-1/2'>
										{state.applyLoading ?
											<Button
												classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20'}}
												label={'Applying'}
												loading={true}
											/>
										:
											<Button
												classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20', margin: 'ml-0'}}
												label={'Apply'}
												callback={() => {
													setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))
												}}
											/>
										}
									</div>
									<p className="lg:w-1/2 text-slateBlue-600 font-semibold text-right self-center cursor-pointer" onClick={ () => clearFilter()}>CLEAR ALL</p>
								</div>

								<div className="">
									{state.costFilters && Object.entries(state.costFilters).map(([cKey, cValue], cIndex) => {
										return(
											<div key={'cosFIl_'+cIndex} className="mb-2">
												<div className="relative">
													<div className='flex flex-wrap bg-white rounded-lg border border-NeutralGray-400 py-2.5 pl-3 pr-4'>
														<p className={`flex justify-between w-full border-b border-NeutralGray-400 text-left cursor-default focus:outline-none sm:text-sm `} 
														onClick={() => onClickGetDropOptions(cKey, cValue, cIndex)}>
															<span className={`truncate text-base text-NeutralGray-600`}>
																{state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length ?
																	state["selected_value_"+cIndex].length +" "+cKey
																: !state["selected_value_"+cIndex] || (state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && !state["selected_value_"+cIndex].length) ?
																	"Choose "+cKey
																: state["selected_value_"+cIndex] ?
																	cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
																		cKey
																	: state["selected_value_"+cIndex]
																: null}
															</span>
															<Icon icon="icon-park-outline:down" className={`text-NeutralGray-600 self-center`} width={24} height={24} />
														</p>
														{cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
															<p className='mb-0 pt-2 text-slateBlue-600 text-sm'>{Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex])?.[0] || state["selected_value_"+cIndex]}</p>	
														: null}
													</div>

													{state["showDetails_"+cIndex] ?
														cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
															<div className=''>
																<ObjectKeyValueSelectOption
																	options={cValue.mapped_values}
																	selectedValues={state["selected_value_"+cIndex] ? state["selected_value_"+cIndex] : ''}
																	callbackMultiSelect={(value) => {
																		onChangeSelectInput(cValue.mapped_values, value, "input_change", "", cIndex, cKey)
																	}}
																	singleSelection={true}
																	// classDetails={{padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
																/>
															</div>
														: cValue.mapped_values && !Object.entries(cValue.mapped_values).length && cValue.values ?
															<div className='bg-white pb-2'>
																{/* <div className='border border-lightGray-600 mx-2 rounded-md max-h-[300px] overflow-y-auto'>
																	{cValue.values.map((vRow, vIndex) => {
																		return(
																			<div className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state["input_change_"+cIndex] === vRow ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== vIndex ? "border-b border-lightGray-600" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === vRow ? "text-slateBlue-600" : "text-white" }`} onClick={() => onChangeSelectInput(cValue.values, vRow, "input_change", vIndex, cIndex, cKey)}>{vRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div> */}
															</div>
														: cValue.output_type === "string" ?
															<div className='bg-white pb-2'>
																<div className='border border-lightGray-600 mx-2 rounded-md h-[300pz]'>
																	<div className='flex pt-3'>
																		<RadioOption
																			label="Includes"
																			value="Includes"
																			selected={state["includes_"+cIndex] === "Includes"}
																			callback={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))}
																		/>
																		<RadioOption
																			label="Excludes"
																			value="Excludes"
																			selected={state["includes_"+cIndex] === "Excludes"}
																			callback={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Excludes" }))}
																			className="pl-8"
																		/>
																	</div>
																	{state["filteredDropOptions_"+cIndex].map((row, sIndex) => {
																		return(
																			<div className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state["input_change_"+cIndex] === row ? "bg-white" : "" } ${state["filteredDropOptions_"+cIndex].length - 1 !== sIndex ? "border-b border-lightGray-600" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === row ? "text-slateBlue-600" : "text-black" }`} onClick={() => onChangeSelectInput(cValue.values, row, "input_change", sIndex, cIndex, cKey)}>{row}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															</div>
														:
															<div className='bg-NeutralGray-100 mb-2 p-2 border border-lightGray-600 rounded-lg'>
																<div className='flex pt-4 px-2'>
																	<RadioOption
																		label="Includes"
																		value="Includes"
																		selected={state["includes_"+cIndex] === "Includes"}
																		callback={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))}
																	/>
																	<RadioOption
																		label="Excludes"
																		value="Excludes"
																		selected={state["includes_"+cIndex] === "Excludes"}
																		callback={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Excludes" }))}
																		className="pl-8"
																	/>
																</div>
																{state["hideDropLoading_"+cIndex]  ?
																	state["dropOptions_"+cIndex] ?
																		<div className='flex flex-wrap pt-4'>
																			<div className='flex flex-col justify-start w-full px-2'>
																				<SelectOption
																					label={""}
																					options={state["filteredDropOptions_"+cIndex] ? state["filteredDropOptions_"+cIndex] : []}
																					selectedValues={state["selected_value_"+cIndex] ? state["selected_value_"+cIndex] : [] }
																					callbackMultiSelect={(value) => {
																						if(value !== "All") {
																							setState(prevState => ({...prevState,  ["selected_value_"+cIndex]: value}))
																						}
																					}}
																					dropdownWidth={'w-full'}
																				/>
																				<div className='flex flex-wrap mt-2'>
																					{state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length ? 
																						state["selected_value_"+cIndex].map(row => {
																							return(
																								<span key={row} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row}
																									<Icon icon="jam:close" width="20" height="20"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, ["selected_value_"+cIndex]) }/>
																								</span>
																							)
																						})
																					: null}
																				</div>
																			</div>
																			<div className={`topAuto mt-2 bg-NeutralGray-100 p-2 max-h-[300px] overflow-y-auto`}>
																				{state["dropOptions_"+cIndex].length === state["filteredDropOptions_"+cIndex].length ?
																					<div className="flex flex-wrap">
																						<div style={{ display: "inline" }} className="mr-2 self-end">
																							<div className='flex'>
																								<Checkbox
																									label=""
																									selectedValue={state["select_all_"+cIndex]}
																									callback={() => {
																										onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}
																									}
																								/>
																								<p className="form-check-label mb-0 mr-4 text-sm text-NeutralGray-600" onClick={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}>Select All({state["filteredDropOptions_"+cIndex].length})</p>
																							</div>
																						</div>
																					</div>
																				: null}
																			</div>
																		</div>
																	: null
																: !state["hideDropLoading_"+cIndex] ?
																	<LoadingCircle/>
																: null}
															</div>
													: 
														null
													}
													{state["input_change_"+cIndex] === 'TAG' ?
														<div className='bg-NeutralGray-100 pb-2'>
															<div className={`bg-NeutralGray-100 border-lightGray-600 text-black px-2`}
																onClick={() => {
																	setState(prevState => ({ ...prevState, ['show_ropOptions_'+cIndex]: !state['show_ropOptions_'+cIndex] }))
																}}
															>
																<div className='flex justify-between p-1 cursor-pointer'>
																	Choose Tag
																	<Icon icon={`gravity-ui:caret-${state["showDetails_"+cIndex] ? 'up' : 'down'}`} className={`text-NeutralGray-700`} width="16" height="16" />
																</div>
															</div>

															{state['show_ropOptions_'+cIndex] ?
																<div className='border border-lightGray-600 mx-2 rounded-md'>
																	<div className='max-h-[300px] overflow-y-auto'>
																		{state["tagDropOPtions_"+cIndex] ? 
																			state["tagDropOPtions_"+cIndex].map((tagItem, tIndex) => {
																				return(
																					<div key={'map_'+tIndex} className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state.aggregate_by_tag === tagItem ? "bg-white" : "" } ${state["tagDropOPtions_"+cIndex].length - 1 !== tIndex ? "border-b border-lightGray-600" : ""}`} onClick={() => {
																						setState(prevState => ({ ...prevState, aggregate_by_tag: tagItem, callFormSelectedFitlersFunction: true }))
																					}}>
																						<div style={{ display: "inline" }} className={`mr-2`}>
																							<div className="form-check">
																								<p className={`form-check-label mb-0 mr-2  ${state.aggregate_by_tag === tagItem ? "text-slateBlue-600" : "text-black" }`}>{tagItem}</p>
																							</div>
																						</div>
																					</div>
																				)
																			})
																		: null}
																	</div>
																</div>
															: null}
														</div>
													: null}
												</div>
											</div>
										)
									})}
								</div>
								<div className='p-3 bg-NeutralGray-100 my-3'>
									<div className="flex self-center">
										{/* <input type="checkbox" className="form-check-input" id="forecastCheck" /> */}
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="forecastCheck" 
											checked={state.forecast}
											onChange={e => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}
										/>
										<label className="ml-4 text-base cursor-pointer text-black" onClick={() => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}>Forecast Data</label>
									</div>
								</div>

								<div className="flex mb-2 justify-between">
									<div className='flex w-1/2'>
										{state.applyLoading ?
											<Button
												classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20'}}
												label={'Applying'}
												loading={true}
											/>
										:
											<Button
												classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-20', margin: 'ml-0'}}
												label={'Apply'}
												callback={() => {
													setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))
												}}
											/>
										}
									</div>
								</div>
								</React.Fragment>
							: null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage