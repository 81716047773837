import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Icon} from "@iconify/react";

import moment from 'moment';
// import _ from 'lodash'

import { currentUTCtime, momentDateGivenFormat, defaultDateRangeValue, getAccountNameFromId, getRegionName } from '../../../utils/utility'
import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew';
import { listQueues, listClusterDetails, listQueueDetails, listProjectDetails, listUsersByCluster } from '../../../actions/Collider/ClusterAction';
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import { CSVLink } from 'react-csv';
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SmartDateRangePicker from '../../common/SmartDateRangePicker';
import Search from '../../common/SearchWithHiddenInput';
import SimplePagination from '../../common/SimplePagination';

const timestamp = new Date().getTime();
const aggregateByOptions = [
    {
        "label": "Project",
        "value": "project"
    },
    {
        "label": "Cluster",
        "value": "cluster_name"
    },
    {
        "label": "Queue",
        "value": "queue_name"
    },
    {
        "label": "User",
        "value": "user_name"
    }
]


const RunCostReportSection = ({ selectedProvider, datePickerStartDate, datePickerEndDate , selectedReportInput, selectedReport, closeSidePanel }) => {
    const clickOut = useRef();
    const csvLinkRef = useRef();
    const csvLinkCreditRef = useRef();

    const [state, setState] = useState({
        startRecord: 0,
        perPage: 10,
        showLoading: true,
        typeOptions: ['User', 'Location', 'Software'],
        selectedDuration: 'lastMonth',
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
		],
        selectedPeriod: "monthly",
        colors: ['#7052BC', '#AA2217', '#57AF3E', '#2D60A6', '#FC6D22', '#CF9124' ],
        aggregateByOptions: aggregateByOptions
    })

    const [dateRange, setDateRange] = useState(defaultDateRangeValue);
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue);

    const dispatch = useDispatch(); // Create a dispatch function
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel()
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        setState(prevState => ({ 
            ...prevState, 
            selectedProvider: selectedProvider ? selectedProvider.toLowerCase() : '',
            selectedProject: selectedReportInput.selectedProject ? selectedReportInput.selectedProject : '',
            selectedAccount: selectedReportInput.selectedAccount ? selectedReportInput.selectedAccount : [],
            selectedRegion: selectedReportInput.selectedRegion ? selectedReportInput.selectedRegion : [],
            selectedClusters: selectedReportInput.selectedClusters ? selectedReportInput.selectedClusters : [],
            selectedQueues: selectedReportInput.selectedQueues ? selectedReportInput.selectedQueues : [],
            selectedUser: selectedReportInput.selectedUser ? selectedReportInput.selectedUser : '',
            selectedType: selectedReportInput.selectedType ? selectedReportInput.selectedType : '',
            selectedGroupBy: selectedReportInput.selectedGroupBy ? selectedReportInput.selectedGroupBy : '',
            datePickerStartDate,
            datePickerEndDate,
            callHpcReports: true,
            callClusterList: true
        }))
    }, [selectedReportInput.selectedProject, selectedReportInput.selectedAccount, selectedReportInput.selectedRegion, selectedReportInput.selectedClusters, selectedReportInput.selectedQueues, selectedReportInput.selectedUser, selectedReportInput.selectedType, selectedReportInput.selectedGroupBy, datePickerStartDate, datePickerEndDate, selectedProvider])

    
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider;
            if(state.selectedProject) {
                params.project = state.selectedProject
            }
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, state.selectedProject, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider;
            if(state.selectedProject) {
                params.project = state.selectedProject
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            
            dispatch(listUsersByCluster(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, users: response }));
                    }
            })

            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response }));
                    }
            })
        }
        
    }, [dispatch, state.selectedProject, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        setState(prevState => ({ ...prevState, 
            datePickerStartDate: momentDateGivenFormat(dateRange[0], "YYYY-MM-DD 00:00:00"),
            datePickerEndDate: momentDateGivenFormat(dateRange[1], "YYYY-MM-DD 23:59:59")
        }))
    }, [dateRange])
    
    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false, budgetSpendData: {}, budgetSpendBarLineGraphData: {}, spendStackedTrendData: {}, trendTable: [], showLoading: true }))
            
            let params = {}
            // params.report_id = selectedReport.report_id
            params.provider = selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedPeriod) {
                params.duration_aggregate_by = state.selectedPeriod
            }
            if(state.selectedGroupBy) {
                params.aggregate_by = state.selectedGroupBy
            }
            // params.credits = true
            if(state.selectedClusters && selectedReport.category_old && !selectedReport.category_old.includes('Project')) {
                params.cluster_name = state.selectedClusters
            }

            if(selectedReport.category_old && selectedReport.category_old.includes('Cluster')) {
                dispatch(listClusterDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            let selectedRecord = (results.length === 1 ? results : [])
                            if(state.selectedPeriod && state.selectedRecord && state.selectedRecord.length && state.selectedRecord[0].cluster_name) {
                                selectedRecord = results.filter(e => e.cluster_name === state.selectedRecord[0].cluster_name) ? results.filter(e => e.cluster_name === state.selectedRecord[0].cluster_name) : []
                            }
                            
                            setState(prevState => ({ ...prevState, totalResponse: results, responseDetails: results, selectedRecord, showLoading: false }));
                        }
                    })
            } else if(selectedReport.category_old && selectedReport.category_old.includes('Queue')) {
                params.queue_name = state.selectedQueues
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            let selectedRecord = (results.length === 1 ? results : [])
                            if(state.selectedPeriod && state.selectedRecord && state.selectedRecord.length && state.selectedRecord[0].queue_name) {
                                selectedRecord = results.filter(e => e.queue_name === state.selectedRecord[0].queue_name) ? results.filter(e => e.queue_name === state.selectedRecord[0].queue_name) : []
                            }
                            
                            setState(prevState => ({ ...prevState, totalResponse: results, responseDetails: results, selectedRecord, showLoading: false }));
                        }
                    })
            } else if(selectedReport.category_old && selectedReport.category_old.includes('User')) {
                // if(state.users) {
                //     params.requested_user_id = state.users.filter(e => e.userId === state.selectedUser)[0].userId
                // }
                dispatch(listUserDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            let selectedRecord = (results.length === 1 ? results : [])
                            if(state.selectedPeriod && state.selectedRecord && state.selectedRecord.length && state.selectedRecord[0].userId) {
                                selectedRecord = results.filter(e => e.userId === state.selectedRecord[0].userId) ? results.filter(e => e.userId === state.selectedRecord[0].userId) : []
                            }
                            
                            setState(prevState => ({ ...prevState, totalResponse: results, responseDetails: results, selectedRecord, showLoading: false }));
                        }
                    })
            } else if(selectedReport.category_old && selectedReport.category_old.includes('Project')) {
                // params.project = state.selectedProject
                dispatch(listProjectDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            let selectedRecord = (results.length === 1 ? results : [])
                            if(state.selectedPeriod && state.selectedRecord && state.selectedRecord.length && state.selectedRecord[0].userId) {
                                selectedRecord = results.filter(e => e.userId === state.selectedRecord[0].userId) ? results.filter(e => e.userId === state.selectedRecord[0].userId) : []
                            }
                            
                            setState(prevState => ({ ...prevState, totalResponse: results, responseDetails: results, selectedRecord, showLoading: false }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, showLoading: false }))
            }
        }
    }, [dispatch, state.callHpcReports, selectedProvider, selectedReport.category_old, selectedReport.report_id, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedPeriod, state.selectedProject, state.selectedQueues, state.selectedRegion, state.selectedUser, state.users, state.selectedGroupBy, state.selectedRecord])

    useEffect(() => {
        state.selectedRecord && state.selectedRecord.forEach(item => {
            if(item.trend) {
                let budgetSpendTrendData = item.trend ? item.trend : []
                //horizontal spend and budget bar
                let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]

                let overAllData = budgetSpendTrendData?.spend_total ||  {}

                let summaryTotal = overAllData


                let labels = ['Budget', 'Spend']
                let horiBarData = {}
                horiBarData.data = data
                horiBarData.labels = labels        
                horiBarData.unit = userMenu.currency_symbol ? userMenu.currency_symbol : ''
                
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
                graphData['Budget'] = budgetSpendTrendData.budget
                graphData['Spend'] = budgetSpendTrendData.spend
                
                setState(prevState => ({ ...prevState, budgetSpendData: horiBarData, budgetSpendBarLineGraphData: graphData, budgetSpendTrendData, summaryTotal }))
            }
        })

    }, [state.selectedRecord,  userMenu.currency_symbol])

    useEffect(() => {
        state.selectedRecord && state.selectedRecord.forEach((item, index) => {
            if(item.breakdown) {
                let spendStackedTrend = item.breakdown
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
        
                Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                    graphData[key] = value
                })
                
                setState(prevState => ({ ...prevState, spendStackedTrendData: graphData }))
            }
        })
    }, [state.selectedRecord])

    useEffect(() => {
        if(state.selectedRecord && state.selectedRecord.length) {
            var trendTable = []
            var creditTable = []
            state.selectedRecord.forEach((item, index) => {
                let selectedOption = '';
                if(selectedReport.category_old[0] === "Cluster") {
                    selectedOption = "Cluster_"+item?.cluster_name;
                } else if(selectedReport.category_old[0] === "Queue") {
                    selectedOption = "Queue_"+item?.queue_name;
                } else if(selectedReport.category_old[0] === "Project") {
                    selectedOption = "Project_"+item?.project;
                } else if(selectedReport.category_old[0] === "User") {
                    selectedOption = "User_"+item?.userName;
                }

                let budgetSpendTrendData = item.data ? item.data : []

                let jobsData = {}
                jobsData.labels = item.dates ? item.dates : []
                jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                
                let nodesData = {}
                nodesData.labels = item.dates ? item.dates : []
                nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                let vcpusData = {}
                vcpusData.labels = item.dates ? item.dates : []
                vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                let cpuHours = {}
                cpuHours.labels = item.dates ? item.dates : []
                cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                let nodeHours = {}
                nodeHours.labels = item.dates ? item.dates : []
                nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                setState(prevState => ({ 
                    ...prevState, 
                    ["nodes_"+state.selectedItemIndex]: nodesData,
                    ["cpus_"+state.selectedItemIndex]: vcpusData,
                    ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                    ["node_hours_"+state.selectedItemIndex]: nodeHours,
                    ["jobs_"+state.selectedItemIndex]: jobsData,                        
                }))

                // let clustredTotalValue = []
                let labels = item.dates ? item.dates : []
                if(item.spend_total && item.spend_total) {
                    
                    let result1 = {
                        "Title": 'Total',
                        'total': item?.trend?.spend_total || 0
                    }
                    // let clustredRowValue = []
                    // clustredRowValue.push('Title')                    
                    // clustredRowValue.push(item?.trend?.spend_total || 0)

                    item.spend_total.forEach((vItem, vIndex) => {
                        // clustredRowValue.push(item?.spend_total[vIndex] || 0)
                        result1[labels[vIndex]] = item?.spend_total[vIndex] || 0
                    })

                    trendTable.push(result1);
                    // clustredTotalValue.push(clustredRowValue)
                }                
                
                if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                    Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                        var result = {
                            "Title": key,
                            'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                        }

                        // let clustredRowValue = []
                        // clustredRowValue.push(key)
                        // clustredRowValue.push(item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0)
                        
                        value.forEach((vItem, vIndex) => {
                            result[labels[vIndex]] = vItem
                            // clustredRowValue.push(vItem)
                        })
                        // clustredTotalValue.push(clustredRowValue)
                        trendTable.push(result);
                    })
                }
                
                if(trendTable.length) {
                    let clusterHeaders = [{label: 'Title',key: 'Title'}]
                    const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                        let sticky = false
                        let header = ''
                        let width = 100
                        let textColor = ''
                        if(tKey === 'Title') {
                            sticky = true
                            header = tKey
                            width = 300
                        } else if(tKey === 'total') {
                            sticky = true
                            header = 'Total'
                            clusterHeaders.push({label: 'Total',key: 'total'})
                        } else {
                            if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                textColor = "text-info"
                            }
                            header = moment(tKey).format(state.selectedPeriod === 'monthly' ? 'MMM YYYY' : 'DD MMM')
                            clusterHeaders.push({label: header, key: tKey})
                        }
                        return {
                            Header: header,
                            accessor: tKey,
                            width: width,
                            sticky: sticky ? "left" : "",
                            textColor: textColor,
                            className: 'text-truncate text-right'
                        }
                    })

                    let filtredCsvArray = [
                        ...clusterHeaders,

                    ]


                    let csvFileName = `Tachyon_Report_${selectedReport?.title}_${selectedOption}_${timestamp}.csv`
                    

                    setState(prevState => ({ ...prevState, csvFileName, trendTable, filteredArray: trendTable, filtredCsvArray, columns, clusterHeaders }))
                } else {
                    const columns = []	
                    setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
                }

                if(item.credits && Object.entries(item.credits).length) {
                    var creditResult = {}
                    let creditLabels  = item.credits && item.credits.dates ? item.credits.dates : []

                    if(item.credits && item.credits.spend) {
                        creditResult = {
                            "Title": 'Spend',
                        }
                        
                        item.credits.spend.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.spend) {
                        creditResult = {
                            "Title": 'Forecast',
                        }
                        
                        item.credits && item.credits.forecast && item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.credits) {
                        creditResult = {
                            "Title": 'Credits',
                        }
                        
                        item.credits && item.credits.forecast && item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.total) {
                        creditResult = {
                            "Title": 'Total',
                        }
                        
                        item.credits && item.credits.forecast && item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(creditTable.length) {
                        let creditHeaders = ['Title']
                        const creditColumns = Object.entries(creditTable[0]).map(([tKey, tValue]) =>{
                            let sticky = false
                            let header = ''
                            let width = 100
                            let textColor = ''
                            if(tKey === 'Title') {
                                sticky = true
                                header = tKey
                                width = 300
                            } else {
                                if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                    textColor = "text-info"
                                }
                                header = moment(tKey).format(state.selectedPeriod === 'monthly' ? 'MMM YYYY' : 'DD MMM')
                                creditHeaders.push(tKey)
                            }
                            return {
                                Header: header,
                                accessor: tKey,
                                width: width,
                                sticky: sticky ? "left" : "",
                                textColor: textColor,
                                className: 'text-truncate text-right'
                            }
                        })
                        
                        let creditFileName = `Tachyon_credit_Report_${item?.title}_${selectedOption}_${timestamp}.csv`
                        setState(prevState => ({ ...prevState, creditFileName, creditTable, filteredCreditArray: creditTable, creditColumns, creditHeaders }))
                    }

                } else {
                    setState(prevState => ({ ...prevState, creditTable, filteredCreditArray: creditTable }))
                }
            })
            
        }
    }, [state.selectedRecord, state.selectedItemIndex, state.selectedPeriod, selectedReport.category_old, selectedReport])

    const downloadPDF = async (state) => {
        const chartContainerOne = document.getElementById('chartContainerOne');
        const chartContainerTwo = document.getElementById('chartContainerTwo');
        const chartContainerThree = document.getElementById('chartContainerThree');

        if (csvLinkRef.current) {
            csvLinkRef.current.link.click(); // Programmatically trigger the download
        }

        if (csvLinkCreditRef.current) {
            csvLinkCreditRef.current.link.click(); // Programmatically trigger the download
        }
    
        if (!chartContainerOne) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainerOne);
            const canvas2 = await html2canvas(chartContainerTwo);
            const canvas3 = chartContainerThree ? await html2canvas(chartContainerThree) : ''
            
            const pdf = new jsPDF('p', 'mm', 'a4');

            let heading = selectedReport && selectedReport.title ? selectedReport.title : ''
            const pageWidth = pdf.internal.pageSize.getWidth();
            const textWidth = pdf.getTextWidth(heading);
            const xPosition = (pageWidth - textWidth) / 2;
            pdf.text(heading, xPosition, 10); // Add the heading to the PDF

            let selectedOption = '';
            if(selectedReport.category_old[0] === "Cluster") {
                selectedOption = "Cluster_"+state.selectedRecord[0]?.cluster_name;
            } else if(selectedReport.category_old[0] === "Queue") {
                selectedOption = "Queue_"+state.selectedRecord[0]?.queue_name;
            } else if(selectedReport.category_old[0] === "Project") {
                selectedOption = "Project_"+state.selectedRecord[0]?.project;
            } else if(selectedReport.category_old[0] === "User") {
                selectedOption = "User_"+state.selectedRecord[0]?.userName;
            }

            const textBoxWidth = 180;
            const fontSize = 12;
            const lineHeight = 8;

            let account = state.selectedAccount && state.selectedAccount.length ? 'Account : '+ (state.selectedAccount.join(", ")) : ''
            let region = state.selectedRegion && state.selectedRegion.length ? 'Region : '+ (state.selectedRegion.join(", ")) : ''
            let cluster = state.selectedClusters ? state.selectedClusters : ''
            if(state.selectedClusters && Array.isArray(state.selectedClusters)) {
                cluster = 'Cluster : '+ (state.selectedClusters.join(", "))
            }

            let queues = state.selectedQueues && state.selectedQueues.length ? 'Queues : '+ (state.selectedQueues.join(", ")) : ''
            let user = state.selectedUser ? 'User : ' +state.users.filter(e => e.userId === state.selectedUser)[0].shortName : ''
            let groupBy = state.selectedGroupBy ? 'Group by : ' +state.selectedGroupBy : ''

            let longText = `Provider: ${state.selectedProvider.toUpperCase()} ${account} ${region} ${cluster} ${queues} ${user} ${groupBy}`

            let words = longText.split(' ');
            let text = '';
            let y = 22; // Starting y-coordinate for text
            for (const word of words) {
                const textWithWord = text + word + ' ';
                const textWidth = pdf.getStringUnitWidth(textWithWord) * fontSize / pdf.internal.scaleFactor;
                if (textWidth > textBoxWidth) {
                    pdf.setFontSize(12);
                    pdf.text(text, 10, y); // Draw the text
                    text = word + ' '; // Start a new line
                    y += lineHeight; // Move to the next line
                } else {
                    text = textWithWord; // Continue current line
                }
            }
            pdf.text(text, 10, y); 

            y = y+10
            let selectedPeriod = 'Period from '+ (momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY')+ ' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY'))
            pdf.text(selectedPeriod, 10, y); 

            let startHeight = y+8
            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, startHeight, 130, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 10, startHeight+70, 130, 60);
            pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 10, startHeight+140, 130, 60);

            let fileName = `Tachyon_Report_${selectedReport?.title}_${selectedOption}_${timestamp}`
            pdf.save(`${fileName}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

    const removeItem = (field, value) => {
        let results = state[field].filter(e => e !== value)
        setState(prevState => ({ ...prevState, [field]: results}))
    }

    useEffect(() => {
        let options = aggregateByOptions
        if(state.selectedProject) {
            options = aggregateByOptions.filter(e => e.label !== 'Project')
        }
        if(state.selectedClusters && state.selectedClusters.length) {
            options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster'))
        }
        if(state.selectedQueues && state.selectedQueues.length) {
            options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster' && e.label !== 'Queue'))
        }
        if(state.selectedUsers) {
            options = aggregateByOptions
        }
        setState(prevState => ({ ...prevState, aggregateByOptions: options }))
        
    }, [state.selectedProject, state.selectedClusters, state.selectedQueues, state.selectedUsers])

	const handleChildClick = (event, type, dropdownType, section) => {	
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Reports</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{selectedReport && selectedReport.report_id ? selectedReport.report_id : ''}</span></p>
                                <div className="pl-4">
                                    <p className='mb-1 text-NeutralGray-400 pr-4'>Description: <span className='text-white font-bold'>{selectedReport && selectedReport.title ? selectedReport.title : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-8 overflow-y-auto h-full'>
                        <div className="flex flex-wrap text-sm leading-7">
                            {state.selectedProvider ?
                                <span className="self-end">Showing report obtained from the <span className="font-bold"> {state.selectedProvider.toUpperCase()} </span> provider </span>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('project') ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'>for the project </span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={['project', 'project']}
                                        options={propProjects[selectedProvider]}
                                        selectedValues={state.selectedProject ? state.selectedProject : ''}
                                        callbackMultiSelect={(value) => 
                                            setState(prevState => ({ ...prevState, selectedProject: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))
                                        }
                                        singleSelection={true}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('account') ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'>account</span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["account_id", "account_name"]}
                                        options={propProjAccounts[selectedProvider] ? propProjAccounts[selectedProvider] : []}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))}
                                        singleSelection={false}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                {state.selectedAccount && state.selectedAccount.length ? 
                                    state.selectedAccount.map((tab, addIndex) => {
                                        return(
                                            <p key={'account_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                {getAccountNameFromId(tab, propProjAccounts[state.selectedProvider])}
                                                <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedAccount", tab)}>x</span>
                                            </p>
                                        )
                                    })
                                : null}
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('region') ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'>Region</span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["region", "name"]}
                                        options={propProjRegions[selectedProvider] ? propProjRegions[selectedProvider] : []}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))}
                                        singleSelection={false}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                {state.selectedRegion && state.selectedRegion.length ? 
                                    state.selectedRegion.map((tab, addIndex) => {
                                        return(
                                            <p key={'region_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                {getRegionName(tab, propProjRegions[state.selectedProvider])}
                                                <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedRegion", tab)}>x</span>
                                            </p>
                                        )
                                    })
                                : null}
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('cluster') ?
                                <React.Fragment>
                                    <span className='ml-1 self-end mr-2'>cluster</span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        options={state.clusters}
                                        selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value, selectedQueues: [], selectedUser: ''}))}
                                        singleSelection={false}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                {state.selectedClusters && Array.isArray(state.selectedClusters) ? 
                                    state.selectedClusters.map((tab, addIndex) => {
                                        return(
                                            <p key={'clus_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                {tab}
                                                <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedClusters", tab)}>x</span>
                                            </p>
                                        )
                                    })
                                : null}
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('queue') ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'> queue</span>
                                <div className='w-32 self-end'>
                                    <SelectOption
                                        label={""}
                                        options={state.queues}
                                        selectedValues={state.selectedQueues ? state.selectedQueues : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueues: value }))}
                                        singleSelection={false}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                {state.selectedQueues && state.selectedQueues.length ? 
                                    state.selectedQueues.map((tab, addIndex) => {
                                        return(
                                            <p key={'queue_tab_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                {tab}
                                                <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedQueues", tab)}>x</span>
                                            </p>
                                        )
                                    })
                                : null}
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('user') ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'> by the user</span>
                                <div className='w-32 self-end'>
                                    <SelectOption
                                        label={""}
                                        fields={["userId", "shortName"]}
                                        options={state.users}
                                        selectedValues={state.selectedUser ? state.selectedUser : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedUser: value, selectedUserName: state.users.filter(e => e.userId === value)[0].shortName }))
                                        }}
                                        singleSelection={true}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.filters && selectedReport.filters.includes('type') ?
                                <React.Fragment>
                                <span className='mx-2 self-end text-black text-base'>Type</span>
                                <div className='w-32 self-end mr-2'>
                                    <SelectOption
                                        label={""}
                                        options={state.typeOptions}
                                        selectedValues={state.selectedType ? state.selectedType : ''}
                                        callbackMultiSelect={(value) => 
                                            setState(prevState => ({ ...prevState, selectedType: value }))
                                        }
                                        singleSelection={true}
                                        addAllField={true}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                            {selectedReport && selectedReport.aggregate_by && selectedReport.aggregate_by.length ?
                                <React.Fragment>
                                <span className='mx-2 self-end text-black text-base'>group by</span>
                                <div className='w-32 self-end mr-2'>
                                    <SelectOption
                                        label={""}
                                        fields={["value", "label"]}
                                        options={state.aggregateByOptions}
                                        selectedValues={state.selectedGroupBy ? state.selectedGroupBy : ''}
                                        callbackMultiSelect={(value) => 
                                            setState(prevState => ({ ...prevState, selectedGroupBy: value }))
                                        }
                                        singleSelection={true}
                                        deSelect={true}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                        </div>
                        <div className="flex justify-between mb-6 mt-3">
                            <div className='smarDateRangerPickerRight'>
                                <SmartDateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    setQueryDate={setQueryDate}
                                    queryDate={queryDate}
                                />
                            </div>
                            <div className='flex'>
                                <p className="bg-pumpkin-600 rounded-lg py-2.5 px-2 min-w-28 text-center self-end text-white break-all mr-3 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, searchInput: true, callHpcReports: true, showLoading: true }))}>Search</p>
                                {state.selectedRecord && state.selectedRecord.length ?
                                    state.showDownloadLoading ?
                                        <p className="flex text-pumpkin-600 text-base text-center self-end break-all mt-2 mr-3 cursor-pointer">
                                            <LoadingCircle 
                                                color={"text-black"}
                                            />
                                            Downlaoding 
                                        </p>
                                    :
                                        <span className='self-end cursor-pointer' onClick={() => {
                                            if(!state.showDownloadLoading) {
                                                setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                                downloadPDF(state)
                                            }
                                        }}>
                                            <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full'/>
                                        </span>
                                : null}

                                {state.selectedRecord && state.selectedRecord.length && (state.totalResponse && state.totalResponse.length > 1) ?
                                    <p className={`bg-DeepPurpleTints-600 py-2.5 pr-4 pl-3 text-white rounded-full self-end cursor-pointer mr-3 min-w-28 text-center ml-3`} onClick={() => setState(prevState => ({ ...prevState, selectedRecord: [] }))}>Back</p>
                                : null}
                            </div>
                        </div>
                        {state.selectedRecord && state.selectedRecord.length ?
                            <div className="flex justify-end mb-6 mt-3">
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["value", "label"]}
                                        options={state.periodOptions ? state.periodOptions : []}
                                        selectedValues={state.selectedPeriod ? state.selectedPeriod : ''}
                                        callbackMultiSelect={(value) => setState((prevState) => ({ ...prevState, selectedPeriod: value, showLoading: true, callHpcReports: true }))}
                                        singleSelection={true}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                            </div>
                        : null}

                        {state.showLoading ?
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        :
                            state.selectedRecord && state.selectedRecord.length ?
                                <React.Fragment>
                                <p className="mb-2 self-center text-lg text-semibold">
                                    Showing cost details of
                                    <span className='ml-1 text-bold'>
                                        {selectedReport.category_old[0] === "Cluster" ?
                                            state.selectedRecord[0]?.cluster_name
                                        : selectedReport.category_old[0] === "Queue" ?
                                            state.selectedRecord[0]?.queue_name
                                        : selectedReport.category_old[0] === "Project" ?
                                            state.selectedRecord[0]?.project
                                        : selectedReport.category_old[0] === "User" ?
                                            state.selectedRecord[0]?.userName
                                        : null}
                                    </span>
                                </p>
                                <div className='flex flex-wrap'>
                                    <div className={`lg:w-1/3 md:w-1/2 w-full pr-2`} id={'chartContainerOne'}>
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                            {state.budgetSpendData && Object.entries(state.budgetSpendData).length ?
                                                <div className='-mt-2 -mb-2 mr-4 self-center'>
                                                    <ApexBarChart
                                                        graphData={state.budgetSpendData}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={false}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={false}
                                                        axisLabelColor={'#495057'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={200}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        columnWidth={'25%'}
                                                        gradient={false}
                                                        gradientColor={['#009fdf', '#CD5A9F']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels= {true}
                                                        dataLabelsTextAnchor = {'end'}
                                                        dataLabelPosition={'top'}
                                                        colors={['#57AF3E', '#3576BE']}
                                                        dataLabelsOffsetX={0}
                                                        chartDistributed={true}
                                                    />
                                                    <p className={`flex justify-center self-center`}>
                                                        {state.selectedRecord && state.selectedRecord.length ?
                                                            <span className={`${state.selectedRecord[0].underutilized ? 'text-green' : (state.selectedRecord[0].overutilized ? 'text-red' : '')})`}>
                                                                <span className='mr-2'>{state.selectedRecord[0].underutilized ? 'Underutilized' : (state.selectedRecord[0].overutilized ? 'Overutilized' : '')}</span>    
                                                                {state.selectedRecord[0].underutilized ? state.selectedRecord[0].underutilized : (state.selectedRecord[0].overutilized ? state.selectedRecord[0].overutilized : '')}
                                                            </span>
                                                        : null}
                                                    </p>
                                                    {/* <p className={`flex justify-center self-center ${state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ? 'text-green' : 'text-red'}`}>
                                                        <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ? 'Underutilized' : 'Overutilized'}</span>
                                                        {state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ?
                                                            <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)}%</span>
                                                        : 
                                                            <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round((state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)-100}%</span>
                                                        }
                                                    </p> */}
                                                </div>
                                            : null}
                                        </div>
                                    </div>

                                    <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0 pr-2`} id={'chartContainerTwo'}>
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                            {/* <span className='mt-1 mb-0 small text-lightGray'>{state.graphData2.description ? state.graphData2.description :''}</span> */}
                                            {state.budgetSpendBarLineGraphData && Object.entries(state.budgetSpendBarLineGraphData).length ?
                                                <div className='mb-n3'>
                                                    <ApexMixedBarLineChart
                                                        graphData={state.budgetSpendBarLineGraphData}
                                                        unit={userMenu.currency_symbol ? userMenu.currency_symbol : ''}
                                                        sparkline={false}
                                                        yaxis={false}
                                                        yaxisLabel={false}
                                                        xaxis={true}
                                                        xaxisFormat={'datetime'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={10}
                                                        legend={true}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={200}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'25%'}
                                                        gradient={true}
                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        colors={['#57AF3E', '#3576BE']}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>

                                    <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0 lg:mt-0 md:mt-0 mt-3`} id={'chartContainerThree'}>
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                            {/* <small className='mt-1 mb-0 small text-lightGray'>{state.graphData3.description ? state.graphData3.description :''}</small> */}
                                            {state.spendStackedTrendData && Object.entries(state.spendStackedTrendData).length ?
                                            <React.Fragment>
                                            <div className='-mt-3 -mb-3 mr-2'>
                                                <ApexStackedBarChart
                                                    graphData={state.spendStackedTrendData}
                                                    start_time={state.datePickerStartDate}
                                                    end_time={state.datePickerEndDate}
                                                    unit={userMenu.currency_symbol ? userMenu.currency_symbol : '$'}
                                                    sparkline={false}
                                                    yaxis={false}
                                                    yaxisLabel={true}
                                                    xaxis={true}
                                                    xaxisFormat={'categoryDateString'}
                                                    xaxisDateFormat={state.selectedPeriod === 'monthly' ? 'MMM YYYY' : 'DD MMM'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={-25}
                                                    legend={true}
                                                    legendPosition={'bottom'}
                                                    stacked={true}
                                                    height={225}
                                                    horizontal={false}
                                                    barHeight={'100%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'25%'}
                                                    gradient={true}
                                                    stroke={'hide'}
                                                    // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                    // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                    colors={state.colors}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#E6E6E6']}
                                                    showBackgroundBarColors={true}
                                                    className={'transparentTooltip'}
                                                />
                                            </div>
                                            {/* <ul className="w-1/4 self-center mb-0 mt-3 text-black">
                                                {state["trendBreakDownTotal_" + (state.startRecord + index)] && Object.values(state["trendBreakDownTotal_" + (state.startRecord + index)]).map((value, tIndex) => {
                                                    return(
                                                        <li key={'trend_' + tIndex} className='flex mb-2'><span className={`legend-circle bg-${state.trendBreakDownColor[tIndex]}`}></span> <span className="pt-1 ml-2 text-gray-400 d-inline-block"> &nbsp;{thousandSeparator(value)}</span></li>
                                                    )
                                                })}
                                            </ul> */}
                                            </React.Fragment>
                                            : null}
                                        </div>
                                    </div>
                                </div>

                                {state.creditTable && state.creditTable.length ?
                                    <div className="w-full mt-3 text-sm">
                                        <div className="flex justify-between pt-2 mb-1">
                                            <p className="mb-0 self-center">
                                                Showing budget, spend and credits    
                                                {userMenu.currency_type ? 
                                                    <span className='ml-2 text-sm'>({userMenu.currency_type})</span>
                                                : null}
                                            </p>
                                            <div className='flex'>
                                                <div className="flex">
                                                    <CSVLink
                                                        data={state.filteredCreditArray} 
                                                        headers={state.creditHeaders ? state.creditHeaders : []}
                                                        filename={state.creditFileName}
                                                        className={"mt-2 hidden"}
                                                        ref={csvLinkCreditRef} // Attach the reference
                                                        target="_blank"
                                                    >
                                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        {state.creditColumns && state.creditColumns.length ? 
                                            <div className="overflow-x-scroll">
                                                <table className="w-full text-left">
                                                    <thead className="bg-gray-50">
                                                        <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                            {state.creditColumns.map((col, colIndex) => {
                                                                return(
                                                                    <th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 ${colIndex ? 'text-right' : 'text-left'} ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {state.filteredCreditArray && state.filteredCreditArray.length ?
                                                            state.filteredCreditArray.map((col, colIndex) => {
                                                                return(
                                                                    <tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
                                                                        {state.creditColumns.map((obj, objIndex) => {
                                                                            return(
                                                                                <td key={objIndex+'_'+colIndex} className={`p-4 text-black ${objIndex ? 'text-right' : 'text-left'} text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                )
                                                            })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        : null}
                                    </div>
                                : null}

                                {state.trendTable ?
                                    <div className="w-full mt-3 text-sm">
                                        <div className="flex justify-between pt-2 mb-1">
                                            <p className="mb-0 self-center">
                                                Showing cost and usage breakdown
                                                {userMenu.currency_type ? 
                                                    <span className='ml-2 text-sm'>({userMenu.currency_type})</span>
                                                : null}
                                            </p>
                                            <div className='flex'>
                                                {state.trendTable && state.trendTable.length ?
                                                    <div className="flex">
                                                        <CSVLink
                                                            data={state.filteredArray ? state.filteredArray : []} 
                                                            headers={state.clusterHeaders ? state.clusterHeaders : []}
                                                            filename={state.csvFileName}
                                                            className={"mt-2 hidden"}
                                                            ref={csvLinkRef} // Attach the reference
                                                            target="_blank"
                                                        >
                                                            <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                                        </CSVLink>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        {state.columns && state.columns.length ? 
                                            <div className="overflow-x-scroll">
                                                <table className="w-full text-left">
                                                    <thead className="bg-gray-50">
                                                        <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                            {state.columns.map((col, colIndex) => {
                                                                return(
                                                                    <th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 ${colIndex ? 'text-right' : 'text-left'} ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {state.filteredArray && state.filteredArray.length ?
                                                            state.filteredArray.map((col, colIndex) => {
                                                                return(
                                                                    <tr className={`${colIndex % 2 === 0 ? 'bg-cultured-600' : 'bg-white'}`} key={'colval_'+colIndex}>
                                                                        {state.columns.map((obj, objIndex) => {
                                                                            return(
                                                                                <td key={objIndex+'_'+colIndex} className={`p-4 ${col.Title === 'Total' ? 'text-byzantineBlue text-lg' : 'text-black text-sm'} ${objIndex ? 'text-right' : 'text-left capitalize'} ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                )
                                                            })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        : null}
                                    </div>
                                : null}
                                </React.Fragment>
                            :
                                <div className="w-full mt-3 text-sm">
                                    <div className="flex justify-between pt-2 mb-1">
                                        <p className="mb-0 self-end text-lg text-semibold">
                                            Showing {state.totalResponse && state.totalResponse.length} {selectedReport.category_old[0]}(s)
                                        </p>
                                        <div className='lg:w-1/2 w-full flex flex-wrap justify-end'>
                                            {state.totalResponse && state.totalResponse.length ?
                                                <div className='w-1/2'>
                                                    <Search
                                                        data={state.totalResponse ? state.totalResponse : []}
                                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                                        className={'rounded-full'}
                                                        filteredData={(responseDetails) => {
                                                            setState(prevState => ({ ...prevState, responseDetails }))
                                                        }}
                                                    />
                                                </div>
                                            : null}
                                            {state.responseDetails && state.responseDetails.length > state.perPage ?
                                                <div className='flex justify-end text-black w-full pt-4'>
                                                    <SimplePagination
                                                        perPage={state.perPage}
                                                        data={state.totalResponse}
                                                        navigate={(startRecord) => {
                                                            setState(prevState => ({ ...prevState, startRecord }))
                                                        }}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="overflow-x-scroll">
                                        <table className="w-full text-left border border-lightGray-600">
                                            <thead className="bg-gray-50 rounded-t-2xl">
                                                <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                    <th className={`tracking-wider px-3 py-3 text-left text-base font-semisolid uppercase`}>Name</th>
                                                    <th className={`tracking-wider px-3 py-3 text-left text-base font-semisolid uppercase`}>Budget</th>
                                                    <th className={`tracking-wider px-3 py-3 text-left text-base font-semisolid uppercase`}>Spend</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {state.responseDetails && state.responseDetails.length ?
                                                    state.responseDetails.slice(state.startRecord, state.startRecord + state.perPage).map((col, colIndex) => {
                                                        return(
                                                            <tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex} onClick={() => {
                                                                setState(prevState => ({ ...prevState, selectedRecord: [col] }))
                                                            }}>
                                                                <td className={`p-4 text-black text-left text-sm whitespace-nowrap text-byzantineBlue cursor-pointer`}>{
                                                                    selectedReport.category_old[0] === "Cluster" ?
                                                                        col.cluster_name
                                                                    : selectedReport.category_old[0] === "Queue" ?
                                                                        col.queue_name
                                                                    : selectedReport.category_old[0] === "Project" ?
                                                                        col.project
                                                                    : selectedReport.category_old[0] === "User" ?
                                                                        col.userName
                                                                    : null
                                                                }</td>
                                                                <td className={`p-4 text-black text-left text-sm whitespace-nowrap`}>{userMenu.currency_symbol+' '+col?.trend?.budget_total || 0}</td>
                                                                <td className={`p-4 text-black text-left text-sm whitespace-nowrap`}>{userMenu.currency_symbol+' '+col?.trend?.spend_total || 0}</td>
                                                            </tr>
                                                        )
                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default RunCostReportSection;
    