import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { alphaNumeric, onlyNumeric } from '../../../../utils/utility';
import {Icon} from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';
import Checkbox from '../../../common/Form/Checkbox';
import SelectOption from '../../../common/Form/SelectOption';
import AlertConfirmation from '../../../common/AlertConfirmation';
import Textarea from '../../../common/Form/Textarea';
import Button from '../../../common/Form/Button';
import { validateIam } from "../../../../utils/utility";

const QuesectionSection = (props) => {
  const [state, setState] = useState({
    queueArray: [0],
    computeArray_0: [0],
	selectedQueue: 0
  });
  const createUpdateCluster = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
    );
    const propsClusterFilters = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
    );
  
    const dispatch = useDispatch(); // Create a dispatch function
  
    
    const callPageVist = useCallback(() => {
      let queueConfiguration = createUpdateCluster && createUpdateCluster.queue_configuration ? createUpdateCluster.queue_configuration : {};
  
      let queueArray = [0];
      if (queueConfiguration && queueConfiguration.length) {
        queueArray = [];
        queueConfiguration.forEach((row, index) => {
          queueArray.push(index);
          if (row.on_node_configured_script) {
            setState((prevState) => ({
              ...prevState,
              ["showNodeScript_" + index]: true,
              ["on_node_configured_script_" + index]: row.on_node_configured_script
            }));
          }
          setState((prevState) => ({
            ...prevState,
            ["queue_name_" + index]: row.queue_name ? row.queue_name : "",
            ["scaledown_idle_time_" + index]: row.scaledown_idle_time
              ? row.scaledown_idle_time
              : "",
            ["update_strategy_" + index]: row.update_strategy
              ? row.update_strategy
              : "",
            ["capacity_type_" + index]: row.capacity_type
              ? row.capacity_type
              : "",
            ["volume_type_" + index]: row.volume_type ? row.volume_type : "",
            ["volume_size_" + index]: row.volume_size ? row.volume_size : "",
            ["volume_encrypted_" + index]: row.volume_encrypted ? true : false,
            ["subnet_ids_" + index]: row.subnet_ids ? row.subnet_ids : [],
            ["policies_" + index]: row.policies ? row.policies : [],
            ["selected_tags_" + index]: row.tags ? row.tags : [],
          }));
  
          let computeArray = [0];
          if (row.compute_resources && row.compute_resources.length) {
            computeArray = [];
            row.compute_resources.forEach((com, cIndex) => {
              computeArray.push(cIndex);
              setState((prevState) => ({
                ...prevState,
                ["compute_resource_name_" + index + "_" + cIndex]:
                  com.compute_resource_name ? com.compute_resource_name : "",
                ["capacity_reservation_id_" + index + "_" + cIndex]: com.capacity_reservation_id
                  ? com.capacity_reservation_id
                  : "",
                ["instance_type_" + index + "_" + cIndex]: Array.isArray(com.instance_type) && com.instance_type.length
					? com.instance_type[0]
					: typeof com.instance_type === 'string' && com.instance_type.trim() !== ''
						? com.instance_type : "",
                ["max_instance_count_" + index + "_" + cIndex]:
                  com.max_instance_count ? com.max_instance_count : 0,
                ["min_instance_count_" + index + "_" + cIndex]:
                  com.min_instance_count ? com.min_instance_count : 0,
                ["efa_enabled_" + index + "_" + cIndex]: com.efa_enabled
                  ? true
                  : false,
                ["placement_group_enabled_" + index + "_" + cIndex]:
                  com.placement_group_enabled ? true : false,
                ["disable_simultaneous_multithreading_" + index + "_" + cIndex]:
                  com.disable_simultaneous_multithreading ? true : false,
              }));
            });
          }

          setState((prevState) => ({
            ...prevState,
            queueArray,
            ["computeArray_" + index]: computeArray
          }));
        });
      }
    }, [createUpdateCluster]);
  
    useEffect(() => callPageVist(), [callPageVist]);
  
    const removefromList = (index, value, listField) => {
		let filteredListItem = state[listField + "_" + index].filter((e) => e !== value);
		let array = [];

		filteredListItem.forEach((row) => {
			let rowValue = {
				value: row,
				label: row,
			};
			array.push(rowValue);
		});
		let queueConfiguration = createUpdateCluster && createUpdateCluster.queue_configuration ? createUpdateCluster.queue_configuration : {};
		queueConfiguration[index].subnet_ids = filteredListItem
	
		setState((prevState) => ({...prevState, [listField + "_" + index]: filteredListItem}));
    };
  
    const addNewTagValue = (index) => {
      let selectedTags = state["selected_tags_" + index]
        ? state["selected_tags_" + index]
        : [];
  
      if (
        state["tag_key_" + index] &&
        state["tag_key_" + index] !== "" &&
        state["tag_value_" + index] &&
        state["tag_value_" + index].length
      ) {
        let dataRow = {};
        dataRow.key = state["tag_key_" + index];
        dataRow.value = state["tag_value_" + index];
        selectedTags.push(dataRow);
      }
  
      setState((prevState) => ({
        ...prevState,
        ["selected_tags_" + index]: selectedTags,
        ["tag_key_" + index]: "",
        ["tag_value_" + index]: "",
      }));
  
      handleQueueInput(index, "tags", selectedTags);
    };
  
    const removeTagSection = (tag, index) => {
      let filteredResult =
        state["selected_tags_" + index] &&
        state["selected_tags_" + index].filter((e) => e !== tag);
      setState((prevState) => ({
        ...prevState,
        ["selected_tags_" + index]: filteredResult,
      }));
  
      handleQueueInput(index, "tags", filteredResult);
    };
  
    const addSection = (array) => {
      let rowList = state[array];
      if (state[array]) {
        let value = state[array][state[array].length - 1];
        value = value + 1;
        rowList.push(value);
      }
  
      setState((prevState) => ({ ...prevState, [array]: rowList }));
    };
  
    const removeCRSection = (array, index, cIndex, j) => {
      setState((prevState) => ({
        ...prevState,
        ["min_instance_count_" + index + "_" + cIndex]: "",
        ["max_instance_count_" + index + "_" + cIndex]: "",
        ["instance_type_" + index + "_" + cIndex]: "",
        ["capacity_reservation_id_" + index + "_" + cIndex]: "",
        ["compute_resource_name_" + index + "_" + cIndex]: "",
      }));
  
      let rowList = state[array + "_" + index];
      rowList.splice(j, 1);
      setState((prevState) => ({ ...prevState, [array + "_" + index]: rowList }));

      setTimeout(() => {
        handleComputeResourcesChange(index, cIndex);
      }, 500);
    };
  
    const removeSection = (array, index, i) => {
      setState((prevState) => ({
        ...prevState,
        ["queue_name_" + index]: "",
        ["subnet_ids_" + index]: [],
        ["security_group_ids_" + index]: [],
        ["on_node_configured_script_" + index]: "",
        ["showNodeScript_" + index]: false,
        ["volume_encrypted_" + index]: "",
        ["volume_encrypted_" + index]: false,
        ["policies_" + index]: [],
        ["selected_tags_" + index]: [],
      }));
  
      state["computeArray_" + index].forEach((cIndex) => {
        setState((prevState) => ({
          ...prevState,
          ["compute_resource_name_" + index + "_" + cIndex]: "",
          ["capacity_reservation_id_" + index + "_" + cIndex]: "",
          ["instance_type_" + index + "_" + cIndex]: "",
          ["max_instance_count_" + index + "_" + cIndex]: "",
          ["min_instance_count_" + index + "_" + cIndex]: "",
          ["efa_enabled_" + index + "_" + cIndex]: false,
          ["placement_group_enabled_" + index + "_" + cIndex]: false,
          ["disable_simultaneous_multithreading_" + index + "_" + cIndex]: false,
        }));
      });

      let rowList = state[array];
      rowList.splice(i, 1);
      setState((prevState) => ({ ...prevState, [array]: rowList }));
  
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let queueConfiguration =
        obj.queue_configuration && obj.queue_configuration.length
          ? obj.queue_configuration
          : [];
      obj["queue_configuration"] = queueConfiguration.filter(
        (e) => e.arrayIndex !== index
      );
    };
  
    const addSectionQuerySection = (array) => {
      let rowList = state[array];
      if (state[array]) {
        let value = state[array][state[array].length - 1];
        value = value + 1;
        rowList.push(value);
  
        setState((prevState) => ({
          ...prevState,
          [array]: rowList,
          ["computeArray_" + value]: [0]
        }));
      }
  
      setState((prevState) => ({ ...prevState, [array]: rowList }));
    };
  
    const handleQueueInput = (index, label, value, type) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let queueConfiguration =
        obj.queue_configuration && obj.queue_configuration.length
          ? obj.queue_configuration
          : [];
      let clusterConfiguration = obj.cluster_configuration
        ? obj.cluster_configuration
        : {};
      state.queueArray.forEach((qIndex) => {
        if (!queueConfiguration.filter((e) => e.arrayIndex === qIndex).length) {
          let dataRow = { arrayIndex: qIndex };
          queueConfiguration.push(dataRow);
        }
        let selectedQueueConfig = queueConfiguration.filter(
          (e) => e.arrayIndex === qIndex
        )[0];
        if (qIndex === index) {
          setState((prevState) => ({
            ...prevState,
            [label + "_" + qIndex]: value,
          }));
        }
        let inputValue = qIndex === index ? value : state[label + "_" + qIndex];
        
        if (label && label !== "tag_key" && label !== "tag_value") {
          selectedQueueConfig[label] =
            type === "integer" ? parseInt(inputValue) : inputValue;
        }
      });
    
      obj["queue_configuration"] = queueConfiguration;
    
      // Check if the label is 'scaledown_idle_time'
      if (label === 'scaledown_idle_time') {
        clusterConfiguration[label] = type === "integer" ? parseInt(value) : value;
      }
    
      obj["cluster_configuration"] = clusterConfiguration;
    
      dispatch(setHpcPropsDetails("createUpdateCluster", obj));
    };
    
  
    const handleComputeResourcesChange = (index, cIndex, label, value) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let queueConfiguration =
        obj.queue_configuration && obj.queue_configuration.length
          ? obj.queue_configuration
          : [];
      state.queueArray.forEach((qIndex) => {
        let computeResources = [];
        state["computeArray_" + qIndex].forEach((row, j) => {
          let comRow = {
            compute_resource_name:
              index === qIndex &&
              row === cIndex &&
              label === "compute_resource_name"
                ? value
                : state["compute_resource_name_" + qIndex + "_" + row],
            capacity_reservation_id:
              index === qIndex && row === cIndex && label === "capacity_reservation_id"
                ? value
                : state["capacity_reservation_id_" + qIndex + "_" + row],
            instance_type:
              index === qIndex && row === cIndex && label === "instance_type"
                ? value
                : state["instance_type_" + qIndex + "_" + row],
            min_instance_count:
              index === qIndex && row === cIndex && label === "min_instance_count"
                ? parseInt(value)
                : state["min_instance_count_" + qIndex + "_" + row]
                ? Math.round(
                    parseFloat(state["min_instance_count_" + qIndex + "_" + row])
                  )
                : 0,
            max_instance_count:
              index === qIndex && row === cIndex && label === "max_instance_count"
                ? parseInt(value)
                : state["max_instance_count_" + qIndex + "_" + row]
                ? Math.round(
                    parseFloat(state["max_instance_count_" + qIndex + "_" + row])
                  )
                : 0,
            efa_enabled:
              index === qIndex && row === cIndex && label === "efa_enabled"
                ? value
                : state["efa_enabled_" + qIndex + "_" + row]
                ? true
                : false,
            placement_group_enabled:
              index === qIndex &&
              row === cIndex &&
              label === "placement_group_enabled"
                ? value
                : state["placement_group_enabled_" + qIndex + "_" + row]
                ? true
                : false,
            disable_simultaneous_multithreading:
              index === qIndex &&
              row === cIndex &&
              label === "disable_simultaneous_multithreading"
                ? value
                : state[
                    "disable_simultaneous_multithreading_" + qIndex + "_" + row
                  ]
                ? true
                : false,
          };

          if (index === qIndex && row === cIndex) {
            if (label === "capacity_reservation_id") {
              const capacityReservation = propsClusterFilters?.capacity_reservations?.filter(
                (reservation) => reservation.capacity_reservation_id === value
              )[0];
              comRow.instance_type = capacityReservation.instance_type
              comRow.min_instance_count = capacityReservation.min_count
              comRow.max_instance_count = capacityReservation.max_count
              comRow.placement_group_enabled = capacityReservation.use_placement_group
              // Set instance_type, min_count, max_count, and placement_group_enabled based on the selected capacity reservation
              setState((prevState) => ({
                ...prevState,
                ["instance_type_" + qIndex + "_" + row]: capacityReservation.instance_type,
                ["min_instance_count_" + qIndex + "_" + row]: capacityReservation.min_count,
                ["max_instance_count_" + qIndex + "_" + row]: capacityReservation.max_count,
                ["placement_group_enabled_" + qIndex + "_" + row]: capacityReservation.use_placement_group,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                [label + "_" + index + "_" + cIndex]: value,
              }));
            }
          }

          computeResources.push(comRow);
        });
        if (queueConfiguration.length === state.queueArray.length) {
          queueConfiguration[qIndex]["compute_resources"] = computeResources;
        } else {
          let dataRow = {
            compute_resources: computeResources,
          };
          queueConfiguration.push(dataRow);
        }
      });
    };
  
    const addPolicies = (index) => {
      let policies = state["policies_" + index] ? state["policies_" + index] : [];
      policies.push(state["policy_" + index]);
  
      handleQueueInput(index, "policies", policies);
      setState((prevState) => ({
        ...prevState,
        ["policies_" + index]: policies,
        ["policy_" + index]: "",
      }));
    };
  
    const removePolicies = (index, value) => {
      let policies = state["policies_" + index].filter((e) => e !== value);
  
      handleQueueInput(index, "policies", policies);
      setState((prevState) => ({
        ...prevState,
        ["policies_" + index]: policies,
      }));
    };
  
    const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='flex justify-between'>
					<ul className="flex flex-wrap gap-2 text-sm text-center">
						{state.queueArray.map((queue, index) => (
							<li key={index} className="relative me-2" 
								onClick={() => 	setState(prevState => ({ ...prevState, selectedQueue: queue}))}
							>
								<span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 font-bold  ${state.selectedQueue === queue ? "bg-DeepPurpleTints-600 text-white underline" : "bg-NeutralGray-400 text-black cursor-pointer"}`}>
									Queue {index+1}
								</span>
								{state.queueArray.length > 1 && ( // Show close icon only if more than one tab exists
									<Icon icon="jam:close" width={24} height={24}  className='cursor-pointer top-0 right-0 h-4 w-4 absolute bg-orange-500 text-white rounded-full hover:bg-orange-600 transition duration-200' 
										onClick={() =>{
											let obj = {
												message: "If you remove, all data in this Queue will be lost. Are you sure you want to remove the queue",
												buttonLabel: "Yes, I'm sure",
											}
											setState(prevState => ({ ...prevState, confirmDetails: obj, removeModal: true, removeQueue: queue }))
										}}
									/>
								)}
							</li>
						))}
					</ul>
					<div className='flex justify-end'>
						<Button
							classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
							label={'+ Add Queue'}
							callback={() => {
								addSectionQuerySection('queueArray')
							}}
						/>
					</div>
				</div>

				<div className="overflow-y-auto h-[calc(100vh-325px)] border border-NeutralGray-500 rounded-r-2xl rounded-b-2xl">
					<div className='p-8'>

						<div key={'q_'+state.selectedQueue}>
							<div className='flex flex-wrap'>
								<div className='lg:w-1/2 w-full lg:pr-4'>
									<Textbox
										label={"Queue Name"}
										type="text"
										selectedValue={state['queue_name_'+state.selectedQueue]  ? state['queue_name_'+state.selectedQueue]  : ''}
										callback={(value) => {
											handleQueueInput(state.selectedQueue, 'queue_name', value)
										}}
										placeholder={'Enter queue name'}
										manditory={true}
										hasError={props.hasError}
										disabled={props.pageMode === "Edit"}
									/>
								</div>
								<div className='flex justify-start w-1/2 pl-4'>
									<Textbox
										label={"Scaledown idle time (minutes)"}
										type="number"
										selectedValue={state['scaledown_idle_time_'+state.selectedQueue] ? state['scaledown_idle_time_'+state.selectedQueue] : ''}
										callback={(value) => {
											handleQueueInput(state.selectedQueue, 'scaledown_idle_time', onlyNumeric(value), 'integer')
										}}
										placeholder={'Enter time'}
									/>
								</div>
							</div>

							<div className='flex flex-wrap pt-8'>
								<div className='flex flex-col justify-start w-1/2 pr-4'>
									<SelectOption
										label={"Subnet IDs"}
										options={propsClusterFilters && propsClusterFilters.subnet_ids ? propsClusterFilters.subnet_ids : []}
										selectedValues={state['subnet_ids_'+state.selectedQueue] ? state['subnet_ids_'+state.selectedQueue] : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, ['subnet_ids_'+state.selectedQueue]: value }))
											handleQueueInput(state.selectedQueue, 'subnet_ids', value)
										}}
										dropdownWidth={'w-full'}
										manditory={true}
										disabled={props.pageMode === "Edit"}
										hasError={props.hasError}
									/>
									<div className="flex flex-wrap pt-4">
										{state['subnet_ids_'+state.selectedQueue] && state['subnet_ids_'+state.selectedQueue].length ? state['subnet_ids_'+state.selectedQueue].map((row) => {
											return (
												<span key={'sub_'+state.selectedQueue+'_'+row} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
													{row}<Icon icon="jam:close" width="20" height="20"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(state.selectedQueue, row, 'subnet_ids') }/>
												</span>
											);
											})
										: null}
									</div>
								</div>
								{/* <div className='flex flex-col justify-start w-1/2 pl-4'>
									<SelectOption
										label={"Security Group IDs"}
										options={state.securityGroupIdOptions ? state.securityGroupIdOptions : []}
										selectedValues={state['security_group_ids_'+state.selectedQueue] ? state['security_group_ids_'+state.selectedQueue] : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, ['security_group_ids_'+state.selectedQueue]: value }))
										}}
										dropdownWidth={'w-full'}
									/>
									<div className="flex flex-wrap pt-4">
										{state['security_group_ids_'+state.selectedQueue] && state['security_group_ids_'+state.selectedQueue].length ? state['security_group_ids_'+state.selectedQueue].map((row) => {
											return (
												<span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
													{row}<Icon icon="jam:close" width="20" height="20"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, ['security_group_ids_'+state.selectedQueue]) }/>
												</span>
											);
											})
										: null}
									</div>
								</div> */}
							</div>

							<div className='flex flex-wrap pt-8'>
								<div className='flex justify-start w-1/2 pr-4'>
									<SelectOption
										label={"Queue update strategy"}
										options={propsClusterFilters && propsClusterFilters.update_strategy ? propsClusterFilters.update_strategy : []}
										selectedValues={state['update_strategy_'+state.selectedQueue] ? state['update_strategy_'+state.selectedQueue] : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, ['update_strategy_'+state.selectedQueue]: value }))
											handleQueueInput(state.selectedQueue, 'update_strategy', value)
										}}
										singleSelection={true}
										dropdownWidth={'w-full'}
									/>
								</div>
								<div className='flex justify-start w-1/2 pl-4'>
									<SelectOption
										label={"Capacity Type"}
										options={propsClusterFilters && propsClusterFilters.capacity_type ? propsClusterFilters.capacity_type : []}
										selectedValues={state['capacity_type_'+state.selectedQueue] ? state['capacity_type_'+state.selectedQueue] : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, ['capacity_type_'+state.selectedQueue]: value }))
											handleQueueInput(state.selectedQueue, 'capacity_type', value)
										}}
										singleSelection={true}
										dropdownWidth={'w-full'}
									/>
								</div>
							</div>

							<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
								<p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Compute Resources</p>
								{state['computeArray_'+state.selectedQueue] && state['computeArray_'+state.selectedQueue].map((cIndex, j) => {
									return(
										<div key={'compArr_'+state.selectedQueue+'_'+cIndex+'_'+j} className=''>
											<div className='bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-4'>
											{state['computeArray_'+state.selectedQueue].map(row => {
												return(
												(row !== cIndex && state['compute_resource_name_'+state.selectedQueue+'_'+cIndex] && state['compute_resource_name_'+state.selectedQueue+'_'+cIndex] === state['compute_resource_name_'+state.selectedQueue+'_'+row]) ?
													<p className='mb-1 text-danger'>
														Duplicate Name
													</p>
												: null
												)
											})}
											<div className='flex flex-wrap mt-2'>
												<div className='w-full'>
													<div className='flex flex-wrap'>
														<div className='lg:w-1/3 w-full lg:pr-4'>
															<Textbox
																label={"Name"}
																type="text"
																selectedValue={state['compute_resource_name_'+state.selectedQueue+'_'+cIndex]  ? state['compute_resource_name_'+state.selectedQueue+'_'+cIndex]  : ''}
																callback={(value) => {
																	handleComputeResourcesChange(state.selectedQueue, cIndex, 'compute_resource_name', alphaNumeric(value))
																}}
																placeholder={'Enter compute resource name'}
																manditory={true}
																hasError={props.hasError}
															/>
														</div>
														<div className='flex justify-start w-1/3 pr-4'>
															<SelectOption
																label={"Capacity Reservation Id"}
																fields={["capacity_reservation_id", "capacity_reservation_id"]}
																options={propsClusterFilters?.capacity_reservations?.length ? propsClusterFilters?.capacity_reservations : []}
																selectedValues={state["capacity_reservation_id_" + state.selectedQueue + "_" + cIndex]? state[ "capacity_reservation_id_" +  state.selectedQueue +  "_" +  cIndex ]: []}
																callbackMultiSelect={(value) => {
																	setState((prevState) => ({ ...prevState, ["capacity_reservation_id_" + state.selectedQueue + "_" + cIndex]: value }));
																	handleComputeResourcesChange(state.selectedQueue, cIndex, "capacity_reservation_id", value);
																}}
																singleSelection={true}
																dropdownWidth={'w-full'}
															/>
														</div>
														<div className='flex justify-start w-1/3 pr-4'>
															<SelectOption
																label={"Instance Type"}
																fields={["instance_type","instance_type"]}
																options={propsClusterFilters && propsClusterFilters.instance_type ? propsClusterFilters.instance_type: ''}
																selectedValues={state["instance_type_" + state.selectedQueue + "_" + cIndex] ? state[ "instance_type_" +  state.selectedQueue +  "_" +  cIndex ] : ''}
																callbackMultiSelect={(value) => {
																	setState((prevState) => ({
																		...prevState, ["instance_type_" + state.selectedQueue + "_" + cIndex]: value, 
																		["instanceTypeOptions_" + state.selectedQueue + "_" + cIndex]: propsClusterFilters.instance_type.filter(  
																			(e) => e.instance_type === value ).length  ? propsClusterFilters.instance_type.filter((e) =>
																				e.instance_type === value  )[0]  : {}})
																	);
																	handleComputeResourcesChange(state.selectedQueue, cIndex, "instance_type", value);
																	handleComputeResourcesChange(state.selectedQueue, cIndex, "efa_enabled", false);
																	handleComputeResourcesChange(state.selectedQueue, cIndex, "placement_group_enabled", false);
																}}
																singleSelection={true}
																manditory={true}
																hasError={props.hasError}
																dropdownWidth={'w-full'}
																disabled={ state["instanceTypeOptions_" +  state.selectedQueue +  "_" +  cIndex] && state[   "instanceTypeOptions_" +  state.selectedQueue +  "_" +  cIndex ].placement_group_enabled === false}
															/>
														</div>
													</div>
													<div className='flex justify-between self-center'>
														<div className='flex flex-col w-1/3'>
															<div className='w-full lg:pr-4 pt-6'>
																<Textbox
																	label={"Min Instance Count"}
																	type="number"
																	selectedValue={state['min_instance_count_'+state.selectedQueue+'_'+cIndex]  ? state['min_instance_count_'+state.selectedQueue+'_'+cIndex]  : ''}
																	callback={(value) => {
																		handleComputeResourcesChange(state.selectedQueue, cIndex, 'min_instance_count', onlyNumeric(value))
																	}}
																	placeholder={'Enter min instance count'}
																/>
															</div>
															<div className='w-full lg:pr-4 pt-6'>
																<Textbox
																	label={"Max Instance Count"}
																	type="number"
																	selectedValue={state['max_instance_count_'+state.selectedQueue+'_'+cIndex] ? state['max_instance_count_'+state.selectedQueue+'_'+cIndex]  : ''}
																	callback={(value) => {
																		handleComputeResourcesChange(state.selectedQueue, cIndex, 'max_instance_count', onlyNumeric(value))
																	}}
																	placeholder={'Enter max instance count'}
																/>
															</div>
														</div>

														<div className='flex flex-col w-1/3 pt-6'>
														<p className="text-base text-black self-center w-full">Select Preferences</p>
															<div className='border border-NeutralGray-400 p-3 mb-4 bg-white rounded-2xl'>
																<div className='flex'>
																	<Checkbox
																		label="Use EFA"
																		name="efa_enabled"
																		selectedValue={state[ "efa_enabled_" + state.selectedQueue + "_" + cIndex] ? true : false}
																		disabled={state[ "instanceTypeOptions_" + state.selectedQueue + "_" + cIndex] && state[ "instanceTypeOptions_" + state.selectedQueue + "_" + cIndex].efa_enabled === false}
																		callback={() => {
																			let value = !state[ "efa_enabled_" + state.selectedQueue + "_" + cIndex ];
																			if (state[ "instanceTypeOptions_" + state.selectedQueue + "_" + cIndex ] && state[ "instanceTypeOptions_" + state.selectedQueue + "_" + cIndex ].efa_enabled === false) 
																				{ value = false;}
																			handleComputeResourcesChange(state.selectedQueue, cIndex, "efa_enabled", value);
																		}}
																	/>
																</div>
																<div className='flex pt-6'>
																	<Checkbox
																		label="Use placement group"
																		name="efa_enabled"
																		selectedValue={state['placement_group_enabled_'+state.selectedQueue+'_'+cIndex] ? true : false}
																		disabled={ state["instanceTypeOptions_" +  state.selectedQueue +  "_" +  cIndex] && state[   "instanceTypeOptions_" +  state.selectedQueue +  "_" +  cIndex ].placement_group_enabled === false}
																		callback={() => {
																			let value = !state['placement_group_enabled_'+state.selectedQueue+'_'+cIndex]
																			// setState(prevState => ({ ...prevState, ['placement_group_enabled_'+state.selectedQueue+'_'+cIndex]: !state['placement_group_enabled_'+state.selectedQueue+'_'+cIndex]}))
																			if ( state["instanceTypeOptions_" +state.selectedQueue + "_" + cIndex] && state["instanceTypeOptions_" + state.selectedQueue +"_" +cIndex].placement_group_enabled === false ) {
																				value = false;
																			}
																			handleComputeResourcesChange(state.selectedQueue, cIndex, 'placement_group_enabled', value)
																		}}
																	/>
																</div>
																<div className='flex pt-6'>
																	<Checkbox
																		label="Turn off multithreading"
																		name="efa_enabled"
																		selectedValue={state['disable_simultaneous_multithreading_'+state.selectedQueue+'_'+cIndex] ? true : false}
																		callback={() => {
																			let value = !state['disable_simultaneous_multithreading_'+state.selectedQueue+'_'+cIndex]
																			// setState(prevState => ({ ...prevState, ['disable_simultaneous_multithreading_'+state.selectedQueue+'_'+cIndex]: !state['disable_simultaneous_multithreading_'+state.selectedQueue+'_'+cIndex]}))
																			handleComputeResourcesChange(state.selectedQueue, cIndex, 'disable_simultaneous_multithreading', value)
																		}}
																	/>
																</div>
															</div>
														</div>
														<div className='flex w-1/3 justify-end pt-6'>
															{state['computeArray_'+state.selectedQueue].length > 1 ?
																<span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => removeCRSection('computeArray', state.selectedQueue, cIndex, j)}> Remove</span>
															: null}
														</div>
													</div>
												</div>
											</div>
										</div>
											{(state['computeArray_'+state.selectedQueue].length - 1) === j ?
												<div className='flex justify-center'>
													<p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() =>  addSection('computeArray_'+state.selectedQueue)}>+ Add Resource</p>
												</div>
											: null}
										</div>
									)
								})}
							</div>
							<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
								<p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Scripts</p>
								<div className='flex pt-3'>
									<Checkbox
										label="Run script on node configured"
										name="efa_enabled"
										selectedValue={state['showNodeScript_'+state.selectedQueue] ? true : false}
										callback={() => {
											setState(prevState => ({ ...prevState,  ['showNodeScript_'+state.selectedQueue]: !state['showNodeScript_'+state.selectedQueue]}))
										}}
									/>
								</div>
								{state['showNodeScript_'+state.selectedQueue] ?
									<div className='flex flex-wrap pt-8'>
										<div className='w-full pt-4 lg:pt-0'>
											<Textarea
												label={'Enter Script'}
												placeholder={'Paste script here'}
												rows={4}
												wordLength={1000}
												className={ {borderClass: "border border-NeutralGray-400 rounded-lg"} }
												selectedValue={state['on_node_configured_script_'+state.selectedQueue] ? state['on_node_configured_script_'+state.selectedQueue] : ''}
												callback={(value) => {
													// setState(prevState => ({ ...prevState, on_node_configured_script: e.target.value}))
													handleQueueInput(state.selectedQueue, 'on_node_configured_script', value)
												}}
											/>
										</div>
									</div>
								: null}
							</div>

							<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
							<p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Root Volume</p>
								<div className='flex flex-wrap'>
									<div className='flex justify-start w-1/2 pr-4'>
										<SelectOption
											label={"Volume Type"}
											options={propsClusterFilters && propsClusterFilters.volume_type ? propsClusterFilters.volume_type : []}
											selectedValues={state['volume_type_'+state.selectedQueue] ? state['volume_type_'+state.selectedQueue] : ''}
											callbackMultiSelect={(value) => {
												// setState(prevState => ({ ...prevState,  ['volume_type_'+state.selectedQueue]: value }))
												handleQueueInput(state.selectedQueue, 'volume_type', value)
											}}
											singleSelection={true}
											dropdownWidth={'w-full'}
										/>
									</div>
									<div className='flex justify-start w-1/2 pl-4'>
										<Textbox
											label={"Root Volume Size"}
											type="number"
											selectedValue={state['volume_size_'+state.selectedQueue] ? state['volume_size_'+state.selectedQueue]  : ''}
											callback={(value) => {
												setState(prevState => ({ ...prevState, ['volume_size_'+state.selectedQueue] : value }))
												handleQueueInput(state.selectedQueue, 'volume_size',  onlyNumeric(value), 'integer')
											}}
											placeholder={'Enter volume size'}
											// manditory={true}
											// hasError={props.hasError}
										/>
									</div>
								</div>
							</div>
							<div className='flex flex-wrap pt-8'>
							<p className="text-base font-medium text-black self-center w-full">Encryption</p>
								<div className='flex pt-3 w-1/2'>
									<Checkbox
										label="Encrypt root volume"
										name="efa_enabled"
										selectedValue={state['volume_encrypted_'+state.selectedQueue] ? true : false}
										callback={() => {
											let value = !state['volume_encrypted_'+state.selectedQueue]
												// setState(prevState => ({ ...prevState, ['volume_encrypted_'+state.selectedQueue]: !state['volume_encrypted_'+state.selectedQueue]}))
												handleQueueInput(state.selectedQueue, 'volume_encrypted', value)
										}}
										disabled={props.pageMode === 'Edit'}
									/>
								</div>
							</div>

							<div className='flex flex-col mt-8'>
								<div className='flex flex-wrap justify-between'>
									<div className='lg:pr-4 w-4/5 pr-4'>
										<Textbox
											label={"IAM Policies"}
											type="text"
											selectedValue={state['policy_'+state.selectedQueue] ? state['policy_'+state.selectedQueue] : ''}
											callback={(value) => {
												setState(prevState => ({ ...prevState, ['policy_'+state.selectedQueue]: validateIam(value)}))
											}}
											placeholder={'arn:aws:iam::<account-id>:policy/<policy-name>'}
											// manditory={true}
											// hasError={props.hasError}
										/>
									</div>
									<div className='flex justify-end pt-4 pl-4'>
										<Button
											classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
											label={'+ Add'}
											callback={() => {
												addPolicies(state.selectedQueue)
											}}
										/>
									</div>
								{/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addDetails(state.selectedQueue)} /> */}
								</div>

								{state['policies_'+state.selectedQueue] && Array.isArray(state['policies_'+state.selectedQueue]) && state['policies_'+state.selectedQueue].length ? 
									<div className='flex flex-wrap pt-4'>
										{state['policies_'+state.selectedQueue].map(row => {
											return(
												<span key={'policies_'+state.selectedQueue+'_'+row} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row}
													<Icon icon="jam:close" width="20" height="20" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removePolicies(state.selectedQueue, row) } />
												</span>
											)
										})}
									</div>
								: null}
							</div>

							<div className='flex flex-wrap justify-between mt-8'>
								<div className='flex w-4/5'>
									<div className='lg:pr-4 w-full'>
										<Textbox
											label={"Tags"}
											type="text"
											selectedValue={state['tag_key_'+state.selectedQueue] ? state['tag_key_'+state.selectedQueue] : ''}
											callback={(value) => {
												setState(prevState => ({ ...prevState, ['tag_key_'+state.selectedQueue]: value }))
											}}
											placeholder={'Enter tag key'}
										/>
									</div>
									<div className='lg:pl-4 pt-4 mt-6 lg:pt-0 w-full'>
										<Textbox
											label={""}
											type="text"
											selectedValue={state['tag_value_'+state.selectedQueue]  ? state['tag_value_'+state.selectedQueue]  : ''}
											callback={(value) => {
												setState(prevState => ({ ...prevState, ['tag_value_'+state.selectedQueue]: value }))
											}}
											placeholder={'Enter tag value'}
										/>
									</div>
								</div>
								<div className='flex justify-end pt-4 pl-4'>
										<Button
											classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
											label={'+ Add'}
											callback={() => {
												addNewTagValue(state.selectedQueue)
											}}
										/>
									</div>
								{/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewTagValue()}/> */}
							</div>
							{state['selected_tags_'+state.selectedQueue] && state['selected_tags_'+state.selectedQueue].length ? 
								<div className='flex flex-wrap pt-4'>
									{state['selected_tags_'+state.selectedQueue].filter(e => e.value !== 'All').map((tag, tIndex) => {
										return(
											<span key={'tag_'+state.selectedQueue+'_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'tag_'+tIndex}>
												<div className='truncate'  style={{maxWidth: '250px'}}>
													{tag.key +' : '+tag.value}
												</div>
												{props.pageMode !== 'View' ?
													<Icon icon="jam:close" width="20" height="20" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removeTagSection(tag, state.selectedQueue) } />
												: null}
											</span>
										)
									})}
								</div>
							: null}
						</div>
						{state.confirmDetails && state.removeModal ?
							<AlertConfirmation 
								confirmDetails={state.confirmDetails}
								confirmation={(removeModal) => {
									if(removeModal) {
										removeSection('queueArray', state.removeQueue)
									}
									setState(prevState => ({ ...prevState, removeModal: false, confirmDetails: false }))
								}}
							/>
						: null}
					</div>
				</div>
        </div>
    )
}


export default QuesectionSection