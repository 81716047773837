import React, { useEffect, useState } from 'react';
import { noSpace } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const Textbox = ({ label, labelClass, type, callback, className, placeholder, labelPlaceholder, selectedValue, manditory, maxValue, classDetails, hasError, disabled, name, onBlurAction, onBlurCallback, validationFunction, readOnly }) => {
    const [state, setState] = useState('')

    useEffect(() => {
        if(selectedValue !== state){
            setState(selectedValue)
        }
    }, [selectedValue, state])

    return (
        <div className={`${classDetails && classDetails.width ? classDetails.width : ' w-full'} items-center ${className}`}>
            {label ?
                <div className='flex justify-between'>
                    <p className={`text-NeutralGray-800 text-base flex w-full 
                        ${labelClass && labelClass.color ? labelClass.color : 'text-NeutralGray-800'}
                    `}>
                        {label} 
                        {labelPlaceholder ? 
                            <span className={`text-NeutralGray-600 pl-2`}>({labelPlaceholder})</span>
                        : null}
                        {manditory ?
                            <Icon icon="fe:star" className='text-red-600 ml-1 self-center' width={12} height={12} />
                        : null}
                    </p>
                    {!state && hasError ?
                        <p className='text-sm text-ferrariRed-600'>Required</p>
                    : null}
                </div>
            : null}
            <input 
                className={`placeholder:italic placeholder:text-NeutralGray-600 text-base block py-2.5 pl-2 shadow-sm
                    ${classDetails && classDetails.common ? classDetails.common : ''}
                    ${classDetails && classDetails.bg ? classDetails.bg : ''} 
                    ${classDetails && classDetails.rounded ? classDetails.rounded : 'rounded-md'} 
                    ${classDetails && classDetails.border ? classDetails.border : 'border border-NeutralGray-400'} 
                    ${classDetails && classDetails.padding ? classDetails.padding : 'py-2.5 pl-3 pr-4'} 
                    ${classDetails && classDetails.width ? classDetails.width : ' w-full'} 
                `}
                placeholder={`${placeholder ? placeholder : "Enter Details"}`}
                value={state ? state : ''}
                readOnly={readOnly}
                onChange={e => {
                    const value = noSpace(e.target.value);
                    if(type === 'number' && maxValue) {
                        if(value <= maxValue) {
                            setState(value);
                            callback(value);
                        }
                    } else {
                        setState(value);
                        callback(value);
                    }
                }}
                onBlur={e=> {
                    if(onBlurAction){
                        onBlurCallback()
                    }
                }}
                type={`${type ? type : 'text'}`}
                name={name}
                disabled={disabled ? disabled : false} 
            />
        </div>
    );
};

export default Textbox;
