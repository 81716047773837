import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap'
import { capitalizeAllLetter, momentSlashConvertionLocalToUtcTime, toggleScroll } from '../../../utils/utility';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getJobFilters, submitJob, getJobBudget, getJobTaskValues } from '../../../actions/Collider/JobStatusAction';
 import { Store as CommonNotification } from 'react-notifications-component';
import Files from '../../hpc/files/file-list/index'
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import RadioOption from '../../common/Form/RadioOption';
import Textbox from '../../common/Form/Textbox';
import TimeInput from '../../common/Form/TimeInput';
// import DateTimeInput from '../../common/Form/DateTime';
import Textarea from '../../common/Form/Textarea';
import Checkbox from '../../common/Form/Checkbox';
import Button from '../../common/Form/Button';

const CreateJobPanel = ({ closeSidePanel, selectedJobDetails }) => {
    
    const [state, setState] = useState({
        showLoading: false,
        job_type: 'manual',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
        clusters: [],
        scheduleRadio: 'run_immediately',
        budgetDetails: false
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const callPageVist = useCallback(() => {
        let jobDetails = selectedJobDetails ? selectedJobDetails : {};
          if (jobDetails) {
            Object.entries(jobDetails).forEach(([key, value]) => {
            if (key === "provider" && value) {
                setState((prevState) => ({ ...prevState, selectedProvider: value }));
            } if (key === "account_id" && value) {
                setState((prevState) => ({ ...prevState, selectedAccount: value }));
            } if (key === "region" && value) {
                setState((prevState) => ({ ...prevState, selectedRegion: value }));
            } if (key === "cluster_name" && value) {
                setState((prevState) => ({ ...prevState, selectedCluster: value }));
            } if (key === "queue_name" && value) {
                setState((prevState) => ({ ...prevState, selectedQueue: value }));
            } if (key === "license_name" && value) {
                setState((prevState) => ({ ...prevState, selectedLincense: value }));
            } else {
                setState((prevState) => ({ ...prevState, [key]: value }));
            }
            });
          }
      }, [selectedJobDetails]);

      useEffect(() => callPageVist(), [callPageVist]);
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    

    useEffect(() => {
        // toggleScroll(true)
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
    }, [dispatch])

    useEffect(() => {        
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedCluster) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedCluster,
              }
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedCluster])
    
    useEffect(() => {
        let params = {}
        if(providers) {
            let selectedProvider = state.selectedProvider
            if(!selectedProvider) {
                selectedProvider = providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ''
            }
            setState(prevState => ({ ...prevState, providers, selectedProvider }));
        } else {
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        let selectedProvider = state.selectedProvider
                        if(!selectedProvider) {
                            selectedProvider = response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider }));
                    }
            })
        }
    }, [dispatch, providers, state.selectedProvider])

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label) ) {
                let selectedAccount = state.selectedAccount ? state.selectedAccount : []
                if(!selectedAccount.length) {
                    selectedAccount = propProjAccounts[label].length ? propProjAccounts[label][0].account_id : ''
                }
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                let selectedAccount = state.selectedAccount ? state.selectedAccount : []
                                if(!selectedAccount.length) {
                                    selectedAccount = response.length ? response[0].account_id : ''
                                }
                                setState(prevState => ({ ...prevState, accounts: response, selectedAccount }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedAccount]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                let selectedRegion = state.selectedRegion ? state.selectedRegion : []
                if(!selectedRegion.length) {
                    selectedRegion = propProjRegions[label].length ? propProjRegions[label][0].region : ''
                }
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
                            let selectedRegion = state.selectedRegion ? state.selectedRegion : []
                            if(!selectedRegion.length) {
                                selectedRegion = response.length ? response[0].region : ''
                            }
							setState(prevState => ({ ...prevState, regions: response, selectedRegion }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions, state.selectedRegion]);

    useEffect(() => {
        if(state.selectedCluster && state.job_type === 'manual') {        
            let jobParams = {}
            jobParams.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                jobParams.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                jobParams.region = state.selectedRegion
            }
            if(state.selectedCluster && state.selectedCluster.length) {
                jobParams.cluster_name = state.selectedCluster
            }
            
            dispatch(getJobFilters(jobParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            application: response.licenses?.application ? response.licenses.application  : [],
                            queues: response.queues ? response.queues : [],
                            licenses: response.licenses? response.licenses : [],
                            qosFilter: response.queue_qos_filters ? response.queue_qos_filters : {},
                            qosParameters: response.qos_parameters ? response.qos_parameters : {},
                            jobFilters: response,
                            queue_capacity: response.queue_capacity ? response.queue_capacity : {},
                            callToUpdateWorkingDirectory: true                            
                        }));
                    }
            })
        }
    }, [state.selectedCluster, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.job_type])

    useEffect(() => {
        if(state.callToUpdateWorkingDirectory) {
            setState(prevState => ({ ...prevState, callToUpdateWorkingDirectory: false, working_directory: state.working_directory ? state.working_directory : (state.jobFilters.working_directory ? state.jobFilters.working_directory : '') }))            
        }
    }, [state.callToUpdateWorkingDirectory, state.jobFilters, state.working_directory])

    useEffect(() => {
        if(state.selectedQueue) {
            // let qosFilter = state.qosFilter && state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].qos ? state.qosFilter[state.selectedQueue].qos :[]
            let qosMessage =  state.qosFilter && state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].message ? state.qosFilter[state.selectedQueue].message : ''
            setState(prevState => ({
                ...prevState,
                qos: state.jobFilters?.queue_qos_filters?.[state.selectedQueue]?.qos || [],
                selectedQos: state.jobFilters?.queue_qos_filters?.[state.selectedQueue]?.qos?.[0] || "",
                qosMessage,
            }));
        }
    }, [state.selectedQueue, state.qosFilter, state.jobFilters])

    // useEffect(() => {
    //     if(state.selectedQos) {
    //         let qosParValue = state.qosParameters[state.selectedQos] ? state.qosParameters[state.selectedQos] : {}
    //         setState(prevState => ({
    //             ...prevState,
    //             qosParValue
    //         }));
    //     }
    // }, [state.selectedQos, state.qosParameters])

    useEffect(() => {
        if (state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            params.create_job = true
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        let selectedCluster = state.selectedCluster ? state.selectedCluster : ''
                        if(!selectedCluster) {
                            selectedCluster = response.length ? response[0].cluster_name : ''
                        }
                        setState(prevState => ({ ...prevState, clusters: response, selectedCluster, fetchingCluster: false
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedCluster])

	const handleCommand = value => {
		setState(prevState => ({ ...prevState, command: value }))
	}

    const handleJobScriptCommand = value => {
		setState(prevState => ({ ...prevState, job_script: value, budgetDetails: false }))
	}

    useEffect(() => {
        if(state.getJobBudgetDetail) {
            setState(prevState => ({ ...prevState, getJobBudgetDetail: false }))

            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            let inputValidationError = false
            if(state.job_type && state.job_type === "manual") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.selectedQos
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if (state.selectedQueue && state.queue_capacity){
                        params.cpus_per_node = parseInt(state.queue_capacity?.[state.selectedQueue]?.node?.cpu)
                    }
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.selectedQos || !state.total_tasks || !state.cpus_per_task || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                    params.licenses_count = parseInt(state.licenses_count)
                }
            }
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true }))

            if(!inputValidationError) {
                dispatch(getJobBudget(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, budgetDetails: response, saveLoading: false }))
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, budgetDetails: [] }))
                        }
                    })
            }

        }
    }, [state, dispatch])

    useEffect(() => {
        if(state.callJobTasks) {
            setState(prevState => ({ ...prevState, callJobTasks: false }))
            if(state.selectedQueue && state.total_tasks && state.cpus_per_task) {
                let params= {
                    total_tasks: parseInt(state.total_tasks),
                    cpus_per_task: parseInt(state.cpus_per_task),
                    cpus_per_node: state.queue_capacity && state.queue_capacity[state.selectedQueue] && state.queue_capacity[state.selectedQueue].node && state.queue_capacity[state.selectedQueue].node.cpus ? state.queue_capacity[state.selectedQueue].node.cpus : 0
                }
                dispatch(getJobTaskValues(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, total_nodes: response.total_nodes, tasks_per_node: response.tasks_per_node }))
                        }
                    })
            }
        }
    }, [state.callJobTasks, state.total_tasks, state.cpus_per_task, state.queue_capacity, dispatch, state.selectedQueue])

    useEffect(() => {
        if(state.callSaveFunction) {
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.job_budget = state.budgetDetails
            let inputValidationError = false
            if(state.job_type && state.job_type === "manual") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.selectedQos
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if (state.selectedQueue && state.queue_capacity){
                        params.cpus_per_node = parseInt(state.queue_capacity?.[state.selectedQueue]?.node?.cpu)
                    }
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.selectedQos || !state.total_tasks || !state.cpus_per_task || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                    if(state.licenses_count) {
                        params.licenses_count = parseInt(state.licenses_count)
                    } else {
                        // inputValidationError = true
                    }
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                }
            }
            params.memory_unit = "GB"
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true, callSaveFunction: false }))
            
            if(!inputValidationError) {
                dispatch(submitJob(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in creating job'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Job created successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });

                        setState(prevState => ({ ...prevState, budgetDetails: false }))
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel('refresh'), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                        }
                        // setState(prevState => ({ ...prevState, createRespons: response }))
                    })
    
            }
        }
    },  [state.callSaveFunction, closeSidePanel, dispatch, state])
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const content = event.target.result;
          setState(prevState => ({ ...prevState, job_script: content, showLocalFileOptions: false }))
        };
    
        reader.readAsText(file);
    };

	const handleChildClick = (event, type, dropdownType, section) => {

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const onKeyDownDateTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateDateTime(str, state)
		} else {
			validateDateTime(time, state)
		}
	}

    const validateDateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let date = str.substring(0, 2)

        let scheduleAt = ''
        if(strLength > 1 && parseInt(date) > 31) {
			scheduleAt += '01/'
		} else if(strLength > 1 && parseInt(date) < 10) {
			if(date.length === 2) {
				scheduleAt = date+'/'
			} else {
				scheduleAt += '0'+date+'/'
			}
		} else if(strLength > 1 && parseInt(date) <= 31) {
			scheduleAt += date+'/'
		}

		if(scheduleAt === '') {
			scheduleAt = dateTime
		}
		
		let month = str.substring(2, 4)
		
		if(month.length) {
			if(strLength > 3 && parseInt(month) > 12) {
				scheduleAt += '01/'
			} else if(strLength > 3 && parseInt(month) > 10) {
				scheduleAt += month+'/'
			} else if(strLength > 3 && month.length === 2) {
				scheduleAt += month+'/'
			} else {
                scheduleAt += month
            }
		}
		
        let year = str.substring(4, 8)
		if(strLength > 4 && year.length < 4) {
            scheduleAt += year
		} else if(strLength > 5 && year.length === 4) {
            scheduleAt += year+' '
        }

		let firstHour = str.substring(8, 10)

		if(strLength > 8 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 9 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 8 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } 
		
		let firstMinute = str.substring(10, 12)
		
		if(firstMinute.length) {
			if(strLength > 10 && parseInt(firstMinute) > 59) {
				scheduleAt += '00'
			} else if(strLength > 10 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^[0-9:]*$]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTimeRange(str, state)
		} else {
			validateTimeRange(time, state)
		}
	}

    const validateTimeRange = useCallback((time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 99) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 99) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"
			} else {
				data += firstMinute
			}
		}

		setState(prevState => ({ ...prevState, [state]: data }))
	}, [])

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full shadow-2xl w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col w-4/5'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black shadow-xl'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Create Job</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={30} height={30} onClick={() => closeSidePanel() } />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedProjectTags: [], selectedAccount: '', selectedRegion: '', selectedCluster: "", selectedQueue: [] }))
                                        }}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value, selectedCluster: "", selectedQueue: "", selectedQos: "", fetchingCluster: true, budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: "", selectedQueue: "", selectedQos: "", fetchingCluster: true, budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                {console.log("state.selectedCluster", state.selectedCluster)}
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Cluster Name"}
                                        fields={['cluster_name', 'display_name']}
                                        options={state.clusters}
                                        selectedValues={state.selectedCluster ? state.selectedCluster : ""}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedCluster: value, selectedQueue: "", selectedQos: "", budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            {state.selectedProvider !== 'GCP' ?
                                <div className='pt-8'>
                                    {/* <p className="text-base font-medium text-black self-center w-full">Select Job Type </p> */}
                                    <div className='flex pt-3'>
                                        <Checkbox
                                            label="Run Script Job"
                                            name="Script"
                                            selectedValue={state.run_script}
                                            callback={() => {
                                                let runScript = !state.run_script
                                                let job_type = 'manual'
                                                if(runScript) {
                                                    job_type = 'scrpit'
                                                }
                                                setState(prevState => ({ ...prevState, run_script: !state.run_script, job_type }))

                                            }}
                                        />
                                    </div>
                                </div>
                            : null}
                            {state.job_type === "manual"  ?
                                <React.Fragment>
                                    <div className='flex flex-wrap pt-8'>
                                        <div className='flex justify-start w-1/2 pr-4'>
                                            <div className='w-full'>
                                                <SelectOption
                                                    label={"Queue Name"}
                                                    options={state.queues}
                                                    selectedValues={state.selectedQueue ? state.selectedQueue : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, selectedQueue: value, budgetDetails: false }))
                                                        // memory: state.queue_capacity?.[value]?.node?.memory
                                                    }}
                                                    singleSelection={true}
                                                    manditory={true}
                                                    hasError={state.inputValidationError}
                                                    dropdownWidth={'w-full'}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex justify-start w-1/2 pl-4'>
                                            <Textbox
                                                label={"Job Name"}
                                                selectedValue={state.job_name ? state.job_name : ''}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, job_name: value, budgetDetails: false }))
                                                }}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                            />
                                        </div>
                                    </div>
                                    {state.selectedQueue && state.queue_capacity && Object.entries(state.queue_capacity).length && state.queue_capacity[state.selectedQueue] ?
                                        <div className='mt-3'>
                                            {Object.entries(state.queue_capacity[state.selectedQueue]).map(([key, value], qIndex) => {
                                                return(
                                                    key !== 'status' ?
                                                        <div className='flex flex-wrap w-fit mt-2 border border-NeutralGray-600 bg-NeutralGray-200 rounded-2xl py-2.5 px-2' key={'queue_capacity_'+qIndex}>
                                                            <p className='text-lg text-primaryPurple-600 font-bold pr-3 flex self-center uppercase'>{key}</p>
                                                            {key === 'cpus' ?
                                                                <React.Fragment>
                                                                <span className="text-black text-base mr-3 self-center">Total: <span className='pl-1 text-lg font-semibold'>{value.total}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">Available: <span className='pl-1 text-lg font-semibold'>{value.available}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">Used: <span className='pl-1 text-lg font-semibold'>{value.used}</span></span>
                                                                </React.Fragment>
                                                            : key === 'memory' ?
                                                                <React.Fragment>
                                                                <span className="text-black text-base mr-3 self-center">Total: <span className='pl-1 text-lg font-semibold'>{value.total}</span><span className="pl-1">{value.unit ? value.unit : ""}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">Available: <span className='pl-1 text-lg font-semibold'>{value.available}</span><span className="pl-1">{value.unit ? value.unit : ""}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">Used: <span className='pl-1 text-lg font-semibold'>{value.used}</span><span className="pl-1">{value.unit ? value.unit : ""}</span></span>
                                                                </React.Fragment>
                                                            : key === 'node' ?
                                                                <React.Fragment>
                                                                <span className="text-black text-base mr-3 self-center">Memory: <span className='pl-1 text-lg font-semibold'>{value.memory}</span><span className="pl-1">{value.unit ? value.unit : ""}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">VCPUs: <span className='pl-1 text-lg font-semibold'>{value.vcpus}</span></span>
                                                                <span className="text-black text-base mr-3 self-center">CPUs: <span className='pl-1 text-lg font-semibold'>{value.cpus}</span></span>
                                                                </React.Fragment>
                                                            :null}
                                                        </div>
                                                    : null
                                                )
                                            })}
                                        </div>
                                    : null}
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className='flex flex-wrap pt-8'>
                                            <div className='flex flex-wrap justify-start self-center lg:w-1/2 w-full lg:pr-4'>
                                                <p className='text-lg flex'>Qos Name: <span className='pl-3 font-semibold'>{state.selectedQos}</span></p>
                                                {/* <SelectOption
                                                    label={"Qos Name"}
                                                    options={state.qos}
                                                    selectedValues={state.selectedQos ? state.selectedQos : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, selectedQos: value, budgetDetails: false }))
                                                    }}
                                                    singleSelection={true}
                                                    manditory={true}
                                                    dropdownWidth={'w-full'}
                                                /> */}
                                                {state.qosMessage ?
                                                    <p className='text-ferrariRed-600 w-full'>{state.qosMessage}</p>
                                                : null}
                                            </div>
                                            <div className='lg:w-1/2 w-full lg:pl-4 pt-4 lg:pt-0'>
                                                <Textbox
                                                    label={"Working Directory"}
                                                    selectedValue={state.working_directory ? state.working_directory : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, working_directory: value }))
                                                    }}
                                                    placeholder={'eg /data/home/<user>/jobname'}
                                                />
                                                <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.working_directory_description}</p>
                                            </div>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'selectedQos' || state.help_field === 'working_directory') ?
                                        <p className='border border-primaryPurple-600 rounded-full px-4 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}

                                    <div className='flex flex-wrap mt-2'>
                                        {state.qosParameters && state.qosParameters[state.selectedQos] && Object.entries(state.qosParameters[state.selectedQos]).length ?
                                            Object.entries(state.qosParameters[state.selectedQos]).map(([key, value], qIndex) => {
                                                return(
                                                    <span key={"row_"+qIndex} className="border border-primaryPurple-600 rounded-full px-4 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{key+' : '+value}
                                                    </span>
                                                )
                                            })
                                        : state.selectedQueue && state.selectedQos && state.qosParValue ?
                                            <p className="text-red-600 my-1 self-center">QOS parameters not found</p>
                                        : null}
                                    </div>
                                    
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Parameters</p>
                                            <div className='flex flex-wrap'>
                                                <div className='lg:w-1/2 w-full lg:pr-4'>
                                                    <Textbox
                                                        label={"Total Tasks"}
                                                        type="number"
                                                        selectedValue={state.total_tasks ? state.total_tasks : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, total_tasks: value, callJobTasks: true }))
                                                        }}
                                                        placeholder={'eg 192'}
                                                        manditory={true}
                                                        hasError={state.inputValidationError}
                                                    />
                                                    <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.total_tasks}</p>
                                                </div>
                                                <div className='lg:w-1/2 w-full lg:pl-4 pt-4 lg:pt-0'>
                                                    <Textbox
                                                        label={"CPUS Per Task"}
                                                        type="number"
                                                        selectedValue={state.cpus_per_task ? state.cpus_per_task : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, cpus_per_task: value, callJobTasks: true }))
                                                        }}
                                                        placeholder={'eg 1'}
                                                        manditory={true}
                                                        hasError={state.inputValidationError}
                                                    />
                                                    <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.cpus_per_task}</p>
                                                </div>
                                            </div>
                                            <div className='flex flex-wrap pt-8'>
                                                <div className='lg:w-1/2 w-full lg:pr-4'>
                                                    <Textbox
                                                        label={"Total Nodes"}
                                                        type="number"
                                                        selectedValue={state.total_nodes ? state.total_nodes : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, total_nodes: value }))
                                                        }}
                                                        placeholder={'eg 6'}
                                                    />
                                                    <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.total_nodes}</p>
                                                </div>
                                                <div className='lg:w-1/2 w-full lg:pl-4 pt-4 lg:pt-0'>
                                                    <Textbox
                                                        label={"Tasks Per Node"}
                                                        type="number"
                                                        selectedValue={state.tasks_per_node ? state.tasks_per_node : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, tasks_per_node: value }))
                                                        }}
                                                        placeholder={'eg 1'}
                                                    />
                                                    <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.tasks_per_node}</p>
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'total_tasks' || state.help_field === 'cpus_per_task' || state.help_field === 'total_nodes' || state.help_field === 'tasks_per_node') ?
                                        <p className='my-1 text-red-600'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}
                                    
                                    <div className='flex flex-wrap pt-8'>
                                        {state.selectedProvider !== 'GCP' ?
                                            <div className='lg:w-1/2 w-full lg:pr-4'>
                                                <Textbox
                                                    label={"Memory (GB)"}
                                                    type="number"
                                                    selectedValue={state.memory ? state.memory : "0"}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, memory: value }))
                                                    }}
                                                    placeholder={'eg 256 GB'}
                                                />
                                                <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.memory}</p>
                                            </div>
                                        : 
                                            <div className='lg:w-1/2 w-full lg:pr-4'>
                                                <Textbox
                                                    label={"Total CPUs"}
                                                    type="number"
                                                    selectedValue={state.cpus ? state.cpus : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, cpus: value }))
                                                    }}
                                                    placeholder={'eg 1'}
                                                    manditory={true}
                                                    hasError={state.inputValidationError}
                                                />
                                            </div>
                                        }
                                        <div className='lg:w-1/2 w-full lg:pl-4 pt-4 lg:pt-0'>
                                            {/* <TimeInput
                                                label={"Max job runtime"}
                                                selectedValu={state.walltime ? state.walltime : ""}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, walltime: value, budgetDetails: false }))
                                                }}
                                                placeholder={'eg 24:00 Hrs'}
                                            /> */}
                                            <p className="text-NeutralGray-800 text-base flex w-full">Max job runtime <span className={`text-NeutralGray-600 pl-2`}>(eg 48:00 Hrs)</span>
                                            </p>
                                            <input 
                                                type="text" 
                                                className="w-full border border-NeutralGray-400 rounded-md text-base py-2.5 pl-3 pr-4" 
                                                placeholder={"HH:MM"}
                                                value={state.walltime}
                                                maxLength="5"
                                                onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "walltime")}
                                                onChange={e => {
                                                    validateTimeRange(e.target.value, "walltime")
                                                    setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                }}
                                            />
                                            {/* <Textbox
                                                label={"Max job runtime"}
                                                selectedValu={state.walltime ? state.walltime : ""}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, walltime: value, budgetDetails: false }))
                                                }}
                                                labelPlaceholder={'eg 24:00 Hrs'}
                                                placeholder={"--:--"}
                                            /> */}
                                        </div>
                                    </div>
                                    {state.selectedProvider === 'AWS' ?
                                        <div className={`border-t border-NeutralGray-600 pt-8 my-8`}>
                                            {/* <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Licenses</p> */}
                                            <div className='flex flex-wrap'>
                                                <div className='flex justify-start w-1/2 pr-4'>
                                                    <SelectOption
                                                        label={"Application"}
                                                        fields={['license_name', 'license_name']}
                                                        options={state.licenses}
                                                        selectedValues={state.selectedLincense ? state.selectedLincense : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, selectedLincense: value, selectedApplication: state.licenses.filter(e => e.license_name === value)[0].application, budgetDetails: false }))
                                                        }}
                                                        hasError={state.inputValidationError}
                                                        singleSelection={true}
                                                        dropdownWidth={'w-full'}
                                                    />
                                                </div>
                                                {/* {state.selectedLincense ?
                                                    <div className='lg:w-1/2 w-full lg:pr-4'>
                                                        <Textbox
                                                            label={"Licenses Count"}
                                                            type="number"
                                                            selectedValue={state.licenses_count ? state.licenses_count : ''}
                                                            callback={(value) => {
                                                                setState(prevState => ({ ...prevState, licenses_count: value }))
                                                            }}
                                                            placeholder={'eg 1'}
                                                        />
                                                    </div>
                                                : null} */}
                                            </div>
                                            <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.license}</p>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'memory' || state.help_field === 'walltime') ?
                                        <p className='my-1 text-red-600'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}                                
                                </React.Fragment>
                            : null}
                            <div className='pt-8'>
                                <p className="text-base font-medium text-black self-center w-full">When it should start </p>
                                <div className='flex pt-3'>
                                    <RadioOption
                                        label="Run Immediately"
                                        value="run_immediately"
                                        selected={state.scheduleRadio === "run_immediately"}
                                        callback={() => setState(prevState => ({ ...prevState, scheduleRadio: "run_immediately" }))}
                                    />
                                    <RadioOption
                                        label="Schedule At"
                                        value="schedule_at"
                                        selected={state.scheduleRadio === "schedule_at"}
                                        callback={() => setState(prevState => ({ ...prevState, scheduleRadio: "schedule_at" }))}
                                        className="pl-8"
                                    />
                                </div>
                                {state.scheduleRadio === "schedule_at" ?
                                    <div className='justify-start w-1/2 pt-3'>
                                        <div className='flex'>
                                            <p className="mb-0 text-base">Schedule At</p>
                                            <Icon icon="fe:star" className='text-red-600 ml-1 self-center' width={12} height={12} />
                                        </div>
                                        <input 
                                            type="text" 
                                            className="border border-NeutralGray-400 rounded-md py-2.5 pl-2 shadow-sm text-black w-full" 
                                            placeholder='DD/MM/YYY HH:mm'
                                            value={state.scheduled_at ? state.scheduled_at : ''}
                                            onKeyDown={e => onKeyDownDateTimeRange(e, e.target.value, 'scheduled_at')}
                                            onChange={e => validateDateTime(e.target.value, 'scheduled_at')}
                                            name={'scheduled_at'}
                                        />
                                        {/* <DateTimeInput
                                            label={"Schedule At"}
                                            selectedValues={state.scheduled_at ? state.scheduled_at : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, scheduled_at: value }))
                                            }}
                                            manditory={true}
                                        /> */}
                                    </div>
                                : null}
                            </div>
                            {state.job_type === "manual" ?
                                <div className={`border-t border-NeutralGray-600 mt-8 py-8`}>
                                    <Textarea
                                        label={'Command'}
                                        placeholder={'Enter Command'}
                                        selectedValue={state.command}
                                        callback={handleCommand}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        rows={10}
                                        wordLength={2000}
                                        showRemainigCharacters={true}
                                    />
                                </div>
                            : 
                                <div className='mt-4 border-t border-darkGray'>
                                    <div className='flex justify-end w-full py-6'>
                                        <span className='cursor-pointer border border-NeutralGray-600 py-2 pl-4 pr-3 rounded-full text-NeutralGray-800 text-base' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: !state.showLocalFileOptions }))}>{!state.showLocalFileOptions ? 'Open' : 'Close'} a Local File</span>
                                        {state.selectedCluster ?
                                            <span className='cursor-pointer border border-NeutralGray-600 py-2 pl-4 pr-3 rounded-full text-NeutralGray-800 ml-3 text-base' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: false, showFileBrowser: !state.showFileBrowser }))}>Browse shared folder</span>
                                        : null}
                                    </div>

                                    {state.showLocalFileOptions ?
                                        <div className='bg-white border border-NeutralGray-600 rounded-2xl p-4 w-fit justify-center'>
                                            <p className='text-black'>Plese choose a file from your system</p>
                                            <input className='m-4' type="file" onChange={handleFileChange} />
                                        </div>
                                    : state.showFileBrowser ?
                                        <div className='file-browser bg-white py-2'>
                                            <Files 
                                                fromScreen="jobs"
                                                selectedFile={(fileContent) => {
                                                    let job_script = fileContent && fileContent.data ? fileContent.data : ''
                                                    setState(prevState => ({ ...prevState, job_script, showFileBrowser: false }))
                                                }}
                                            />
                                        </div>
                                    :
                                        // <div className='w-full pt-8'>
                                        //     <Textarea
                                        //         label={'Job Script'}
                                        //         callback={handleJobScriptCommand}
                                        //         manditory={true}
                                        //         rows={4}
                                        //     />
                                        
                                        // </div>
                                        <div className={`border-t border-NeutralGray-600 mt-8 py-8`}>
                                            <Textarea
                                                label={'Job Script'}
                                                selectedValue={state.job_script}
                                                placeholder={'Enter script'}
                                                callback={handleJobScriptCommand}
                                                manditory={true}
                                                rows={10}
                                                wordLength={2000}
                                                showRemainigCharacters={true}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
                        <div className={`flex justify-${state.budgetDetails ? 'between' : 'end'}`}>
                            {state.budgetDetails ?
                                <div>
                                    <div className='flex pl-4 text-base '>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Budget&nbsp;Type:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.budget_type ? state.budgetDetails.budget_type : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {userMenu.currencty_symbol ? userMenu.currencty_symbol : '$'}
                                                {state.budgetDetails && state.budgetDetails.total_budget ? state.budgetDetails.total_budget : 0 }
                                                {/* <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span> */}
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {userMenu.currencty_symbol ? userMenu.currencty_symbol : '$'}
                                                {state.budgetDetails && state.budgetDetails.budget_used ? state.budgetDetails.budget_used : 0 }
                                                {/* <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span> */}
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Available&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {userMenu.currencty_symbol ? userMenu.currencty_symbol : '$'}
                                                {state.budgetDetails && state.budgetDetails.available_budget ? state.budgetDetails.available_budget : 0 }
                                                {/* <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span> */}
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Job&nbsp;Cost:</span>
                                            <span className='text-yellowOrange-600'>
                                                {userMenu.currencty_symbol ? userMenu.currencty_symbol : '$'}
                                                {state.budgetDetails && state.budgetDetails.job_cost ? state.budgetDetails.job_cost : 0 }
                                                {/* <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex mt-3 pl-4 text-base'>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.total_licenses ? state.budgetDetails.total_licenses : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.licenses_used ? state.budgetDetails.licenses_used : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-5 text-white'>
                                            <span className='mr-2'>Available&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.available_licenses ? state.budgetDetails.available_licenses : 0 }
                                            </span>
                                        </div>
                                    </div>
                                    {state.budgetDetails && state.budgetDetails.message ?
                                        <p className='flex pl-4 text-danger'>{state.budgetDetails.message}</p>
                                    : null}
                                </div>
                            : null}
                            {state.inputValidationError ?
                                <p className='text-white text-lg mr-3 self-center'>Please fill all the required fields</p>
                            : null}
                            <div className={`self-center flex justify-center`}>
                                {state.saveLoading ?
                                    <Button
                                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                        label={'Submitting'}
                                        loading={true}
                                    />
                                :
                                    <div className='flex'>
                                        <Button
                                            classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                            label={'Cancel'}
                                            callback={() => closeSidePanel() }
                                        />
                                        {state.budgetDetails && state.budgetDetails ?
                                            <Button
                                                classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                                label={'Continue'}
                                                callback={() => {
                                                    if(state.budgetDetails.job_submission) {
                                                        setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                                    }
                                                }}
                                            />
                                        : !state.budgetDetails ?
                                            <Button
                                                classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                                label={'Submit'}
                                                callback={() => {
                                                    setState(prevState => ({ ...prevState, saveLoading: true, getJobBudgetDetail: true }))
                                                }}
                                            />
                                        : null}
                                    </div>
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateJobPanel;