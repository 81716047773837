/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file BudgetLandingPage.js
 * @author Prakash // on 17/05/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { listBudgets, getCloudProviderBudget, getProjectCredits, getCurrencyConversionDetails } from '../../../actions/cost/BudgetAction'
import { capitalizeTheFirstLetterOfEachWord, currentLocaltime, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import CreateProviderAllcoation from './CreateProviderAllcoation';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import SelectOption from '../../common/Form/SelectOption';
import { setCostPropsDetails } from '../../../actions/cost/CostAction';
import CreateProviderCredits from './CreateProviderCredits';


const ManageBudget = () => {	
	const[state, setState] = useState({
		showLoading: true,
		currencyUnit: 'usd',
        currencyTypes: ['usd', 'gbp']
	})

	// const providers = useSelector(state => state.filters.providers);
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		dispatch(setCostPropsDetails("selectedInitiative", false))
		dispatch(setCostPropsDetails("createInputs", false))
	}, [])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true, callCloudProviderBudget: true, callCloudProviderCreidts: true }));
		let params={
			convert_from: 'usd', 
			convert_to: state.currencyUnit
		}
		dispatch(getCurrencyConversionDetails(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, conversionDetails: response.conversion_details ? response.conversion_details : '' }))
			})
	}, [dispatch, state.currencyUnit])

	useEffect(() => {
		if(state.callSearch) {
			let params = {}
	
			dispatch(listBudgets(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, callSearch: false, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], callSearch: false, showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
		if(state.callCloudProviderBudget) {
			setState(prevState => ({ ...prevState, callCloudProviderBudget: false }))
			let params = {
				year: parseInt(momentDateFormat(currentLocaltime(), 'YYYY')),
				currency_unit: state.currencyUnit
			}
			// if(!state.allocationDetails || !state.allocationDetails.length) {
				dispatch(getCloudProviderBudget(params))
					.then((response) => {
						if(response && !response.error) {
							let results = response.results ? response.results : []
							if(results && results.length) {
								results.forEach(row => {
									setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
								})
							}
							setState(prevState => ({ ...prevState, allocationDetails: results, showLoading: false }))
						} else {
							setState(prevState => ({ ...prevState, allocationDetails: [], showLoading: false }))
						}
					})
			// }
		}
	}, [state.callCloudProviderBudget, dispatch, state.allocationDetails, state.currencyUnit])

	useEffect(() => {
		if(state.callCloudProviderCreidts) {
			setState(prevState => ({ ...prevState, callCloudProviderCreidts: false }))
			let params = {
				year: parseInt(momentDateFormat(currentLocaltime(), 'YYYY')),
				currency_unit: state.currencyUnit
			}
			dispatch(getProjectCredits(params))
				.then((response) => {
					if(response && !response.error) {
						let results = response.results ? response.results : []
						if(results && results.length) {
							results.forEach(row => {
								setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
							})
						}
						setState(prevState => ({ ...prevState, creditDetails: results, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, creditDetails: [], showLoading: false }))
					}
				})
		}
	}, [state.callCloudProviderCreidts, dispatch, state.currencyUnit])

	useEffect(() => {
		let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
	}, [state.filterListBudgets])

	const navigateTo = () => {
		navigate(URL_PATH.COST_BUDGET_CREATE); // Redirect to the specified path
	}

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='px-6'>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="md:w-1/2 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Budgets</p>
						<p className="text-white text-base">Showing the list of Budgets</p>
					</div>
					<div className="md:w-1/2 w-full self-center flex flex-wrap justrify-end">
						<div className='flex w-full justify-end'>
							<p className="mr-3 self-center text-sm font-medium text-gray-900 dark:text-gray-300 text-white">Currency is in</p>
							<SelectOption
								label={""}
								options={state.currencyTypes}
								selectedValues={state.currencyUnit ? state.currencyUnit : []}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, currencyUnit: value, showLoading: true, callCloudProviderBudget: true, callCloudProviderCreidts: true }))
								}}
								singleSelection={true}
								makeLabelUpperCase={'capitalizeAllLetter'}
								dropdownWidth={'min-w-20'}
								classDetails={{common:'uppercase', padding: "py-0 px-2", bg: 'bg-white', rounded: 'rounded-full', optionPosition: 'right-0', optionWidth: 'min-w-25' }}
							/>  
						</div>
						{state.conversionDetails && state.currencyUnit !== 'usd' ?
							<div className='flex mb-1 text-right text-white text-xs w-full flex justify-end w-full pt-2'>{state.conversionDetails}</div>
						: null}
					</div>
				</div>
			</div>
			<div className='bg-paleLavender-600 border border-DeepPurpleTints-600 p-6 rounded-2xl mb-8'>
				<div className={`flex justify-between`}>
					<p className={`text-[22px] text-PrimaryTintsDeepPurpleTints-600 font-bold uppercase`}>Cloud Provider Commitment</p>
					<div className='flex justify-end'>
						<Button
							classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', margin: 'mr-2', text: 'text-white text-base font-bold', others: 'cursor-pointer text-center'}}
							label={'Provider Budget'}
							callback={() => setState(prevState => ({ ...prevState, showProviderBudgetSection: true }))}
						/>
						{/* <Icon icon={`icon-park-outline:${state.showUpdateSection ? 'up' : 'down'}`} width={24} height={24} className='bg-DeepPurpleTints-600 h-6 w-6 text-white rounded-full cursor-pointer self-center' onClick={() => setState(prevState => ({ ...prevState, showCloudProviderCommitment: !state.showCloudProviderCommitment }))}/> */}
					</div>
				</div>
				{state.showProviderBudgetSection ?
					<CreateProviderAllcoation 
						closeSidePanel={() => setState(prevState => ({...prevState, showProviderBudgetSection: false }))}
						refreshApi={() => setState(prevState => ({...prevState, allocationDetails: [], callCloudProviderBudget: true }))}
					/>
				: null}
				{!state.showCloudProviderCommitment ?
					state.allocationDetails && state.allocationDetails.length ?
						<div className="flex justify-between mt-2">
							{state.allocationDetails.map((row, iRow) => {
								return(
									<div key={'allocat_'+iRow} className={`lg:w-1/2 lg:pr-2 w-full ${!iRow ? "border-r border-DeepPurpleTints-600" : " pl-6"}`}>
										<div className='flex flex-wrap'>
											<p className='text-black text-lg text-bold mb-1 w-full uppercase'>{row.cloudProviderId} Commitment<span className='ml-1 text-lightGray'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
											<p className='text-black text-[28px] w-full text-semibold'>{thousandSeparator(row.allocation)} <span className='ml-1 uppercase'>{state.currencyUnit}</span></p>
										</div>
									</div>
								)
							})}
						</div>
					:
						<div className='flex justify-center text-black w-full text-lg'>No cloud provider commitment</div>
				: null}
			</div>
			<div className='bg-paleLavender-600 border border-DeepPurpleTints-600 p-6 rounded-2xl mb-8'>
				<div className={`flex justify-between`}>
					<p className={`text-[22px] text-PrimaryTintsDeepPurpleTints-600 font-bold uppercase`}>Cloud Provider Credits</p>
					<div className='flex justify-end'>
						<Button
							classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', margin: 'mr-2', text: 'text-white text-base font-bold', others: 'cursor-pointer text-center'}}
							label={'Provider Credits'}
							callback={() => setState(prevState => ({ ...prevState, showProviderCreditSection: true }))}
						/>
						{/* <Icon icon={`icon-park-outline:${state.showUpdateSection ? 'up' : 'down'}`} width={24} height={24} className='bg-DeepPurpleTints-600 h-6 w-6 text-white rounded-full cursor-pointer self-center' onClick={() => setState(prevState => ({ ...prevState, showCloudProviderCommitment: !state.showCloudProviderCommitment }))}/> */}
					</div>
				</div>
				{state.showProviderCreditSection ?
					<CreateProviderCredits
						closeSidePanel={() => setState(prevState => ({...prevState, showProviderCreditSection: false }))}
						refreshApi={() => setState(prevState => ({...prevState, allocationDetails: [], callCloudProviderCreidts: true }))}
					/>
				: null}
				{!state.showCloudProviderCommitment ?
					state.allocationDetails && state.allocationDetails.length ?
						<div className="flex justify-between mt-2">
							{state.allocationDetails.map((row, iRow) => {
								return(
									<div key={'allocat_'+iRow} className={`lg:w-1/2 lg:pr-2 w-full ${!iRow ? "border-r border-DeepPurpleTints-600" : " pl-6"}`}>
										<div className='flex flex-wrap'>
											<p className='text-black text-lg text-bold mb-1 w-full uppercase'>{row.cloudProviderId} Credits<span className='ml-1 text-lightGray'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
											<p className='text-black text-[28px] w-full text-semibold'>{thousandSeparator(row.allocation)} <span className='ml-1 uppercase'>{state.currencyUnit}</span></p>
										</div>
									</div>
								)
							})}
						</div>
					:
						<div className='flex justify-center text-black w-full text-lg'>No cloud provider commitment</div>
				: null}
			</div>
			<div className={`flex flex-wrap pb-6 justify-between`}> 
				<div className="flex lg:w-3/5 w-full text-sm">
					<p className="self-center">Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s)</p>
				</div>
				<div className="flex flex-wrap justify-end lg:w-2/5 w-full mb-n2">
					{state.listBudgets && state.listBudgets.length && state.listBudgets.length ?
						<div className='w-1/2'>
							<Search
								data={state.listBudgets ? state.listBudgets : []}
								topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
								className={'rounded-full'}
								filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
							/>
						</div>
					: null}

					<Button
						classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-3', margin: 'mx-4', text: 'text-white text-base font-semibold', others: 'cursor-pointer text-center'}}
						label={'Create Budget'}
						callback={() => navigateTo()}
					/>
					{state.filterListBudgets && state.filterListBudgets.length ?
						<CSVLink 
							data={state.filterListBudgets ? state.filterListBudgets : []} 
							headers={state.tableHeaders ? state.tableHeaders : []}
							filename={'job-lists.csv'}
							className={'mr-2 self-center'}
							target='_blank'
						>	
							<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center cursor-pointer' width={24} height={24} />
						</CSVLink>
					: null}
				</div>
			</div>
			
			<div className="w-full">
				<div className="relative overflow-x-auto rounded-t-lg shadow border border-lightGray-600">
					{state.filterListBudgets && state.filterListBudgets.length ?
						<ResizeableDarkThemeTable
							columns={[
								{
									Header: 'Date',
									accessor: d => d.modified_at ? "modified_at" : 'created_at',
									Cell: cellInfo => (
									<span>
										{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
									</span>
									),
									width: 200
								},
								{
									Header: 'Name',
									accessor: 'budget_name',
									width: 200,
								},
								{
									Header: 'Allocation',
									accessor: 'allocation',
									width: 200,
								},
								{
									Header: 'Department',
									accessor: 'department',
									width: 200,
								},
								{
									Header: 'Year',
									accessor: 'year',
									width: 100,
								},
								{
									Header: 'Actions',
									Cell: cellInfo => (
										<div className="flex self-center">
											<Link 
												to={URL_PATH.COST_BUDGET_CREATE} 
												state={{
													department: cellInfo.row.original.department,
													year: cellInfo.row.original.year,
													pageType: 'Edit',
												}}
												className='text-primaryPurple-600'>
												Edit
											</Link>
										</div>
									),
									width: 100
								},
								{
									Header: 'Description',
									accessor: 'description',
									width: 450
								},
							]}
							data={state.filterListBudgets}
							perPage={20}
							selectedColor={'bg-backGroundGray'}
							dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
							onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
						/>
					: 
						<div className='flex justify-center m-4 text-black'>
							{/* <p>{getCommonInformationMessage('budget')}</p> */}
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default ManageBudget