import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactTable from "../../../designComponents/Table/FileManagerTable";
import { getStats, getUserFiles } from '../../../../actions/files/FileAction';
import FileActions from '../file-actions';
import Sidebar from '../side-bar';
import ErrorToast from '../../../common/Toast/ErrorToast';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';
import _ from 'lodash'
import { removeUnderScore, splitString } from '../../../../utils/utility';
import Search from '../../../common/SearchWithHiddenInput';
import {Icon} from "@iconify/react";

const createParams = (state, mount_path='') => {
  let params = {}
  if(mount_path) {
    params.path = mount_path
  }

  if (state) {
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() :''
    params.account_id = state.selectedAccount
    params.cluster_name = state.selectedClusters
    params.region = state.selectedRegion
  }
  return params
}

export default function FileList(props) {
  const filterRef = useRef();
  const dispatch = useDispatch();
  
  const [state, setState] = useState({
    showLoading: true,
    selectedProvider: '',
    selectedAccount: [],
    isAccountOpen: false,
    selectedRegion: [],
    isRegionOpen: false,
  });
  const [clickCount, setClickCount] = useState(0)


  const fileManageFilterChange = useSelector(state => state?.filters?.commonPropsDetails?.fileManageFilterChange || false);
  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const doubleClickOpenFileOpen = useSelector(state => state?.filters?.commonPropsDetails?.doubleClickOpenFileOpen || false);

  // const columns = [
  //   {
  //     Header: 'Name',
  //     accessor: 'name',
  //     Cell: ({ row }) => (
  //       <NameCellRenderer row={row} />
  //     ),
  //   },
  //   {
  //     Header: 'Last Modified',
  //     accessor: 'modified_at',
  //   },
  //   // {
  //   //   Header: 'Created On',
  //   //   accessor: 'date_created',
  //   // },
  //   {
  //     Header: 'Owner',
  //     accessor: 'owner',
  //   },
  //   // {
  //   //   Header: 'Action',
  //   //   Cell: cellInfo => (
  //   //     <div className="flex justify-between cursor-pointer self-center">
  //   //       {cellInfo.row.original.type === 'file' ?
  //   //         <div className='text-info mr-2'
  //   //           onClick={() => setState(prevState => ({ ...prevState, selectedUserDetails: cellInfo.row.original, showCreateUserPanel: true }))}
  //   //         >Edit </div>
  //   //       : null}
  //   //     </div>
  //   //   ),
  //   // },
  //   {
  //     Header: 'File Size',
  //     accessor: 'size',
  //   },
  // ]

  useEffect(() => {
    if(!_.isEqual(state.fileManageFilterChange, fileManageFilterChange)) {
      setState(prevState => ({ 
        ...prevState, 
        selectedProvider: fileManageFilterChange.selectedProvider,
        selectedAccount: fileManageFilterChange.selectedAccount ? fileManageFilterChange.selectedAccount : [],
        selectedRegion: fileManageFilterChange.selectedRegion ? fileManageFilterChange.selectedRegion : [],
        selectedClusters: fileManageFilterChange.selectedClusters ? fileManageFilterChange.selectedClusters : '',
        fileManageFilterChange,
        filteredFiles: []
      }))

      if(fileManageFilterChange.selectedClusters) {
        setState(prevState => ({ ...prevState, callUserFiles: true, filesDataLoading: true, statsDetail: {} }))        
      }
    }
  }, [fileManageFilterChange, state.fileManageFilterChange])

  // const NameCellRenderer = ({ row }) => {
  //   const { type, name } = row.original;
  //   return(
  //     <div className="flex">
  //       <Icon icon={`${type === 'directory' ? 'material-symbols-light:folder-outline' : 'mingcute:file-fill'} className="mr-2 self-center`} width={24} height={24} />
  //       <span>{name}</span>
  //     </div>
  //   )    
  // };

  const getFileFolderStats = useCallback((data) => {
    let params = {}
    params.path = data.path
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
    params.cluster_name = state.selectedClusters
    if(data.owner) {
      params.owner = data.owner
    }

    dispatch(getStats(params))
      .then((response) => {
        setState(prevState => ({ ...prevState, statsDetail: response }))
      })
  }, [dispatch, state])

  useEffect(() => {
    if(doubleClickOpenFileOpen && state.statsDetail && state.statsDetail.data && state.statsDetail.data.type && state.statsDetail.data.type === 'file' && Object.entries(state.statsDetail).length) {
      dispatch(setCommonPropsDetails('doubleClickOpenFile', true))
      dispatch(setCommonPropsDetails('doubleClickOpenFileOpen', false))
    }
  }, [doubleClickOpenFileOpen, state.statsDetail, dispatch])

  const handleRowSelect = (selectedRows, rowIndex) => {
    // dispatch(setCommonPropsDetails('currentLocation', selectedRows))
    getFileFolderStats(selectedRows)
    if(selectedRows && selectedRows.type === 'directory') {
      setState(prevState => ({ ...prevState, selectedPath: selectedRows.path, fileRowSelected: true, selectedRows, filesDataLoading: true }))
    } else {

      setState(prevState => ({ ...prevState, selectedItem: rowIndex }))
        // Increment the click count
        setClickCount((prevCount) => prevCount + 1);
        // Check if it's a double-click
        if (clickCount === 1) {
          // Reset the click count and perform the action for double-click (e.g., open the folder)
          setClickCount(0);
          // Add your logic to open the folder here
          dispatch(setCommonPropsDetails('doubleClickOpenFileOpen', true))
        }

    
        // Set a timeout to reset the click count after a short delay (e.g., 300 milliseconds)
        setTimeout(() => {
          setClickCount(0);

        }, 300);
    }
  };

  // const handleBreadcrumbClick = (item) => {
  //   setState(prevState => ({ ...prevState, filesDataLoading: true }))
  //   let params = createParams(state, item.path)
  //   dispatch(getUserFiles(params))
  //   .then((response) => {
  //     if(response.data) {
  //       // let splitPath = response.data[0].current_directory ? splitString(response.data[0].current_directory, "/") : 
  //       setState(prevState => ({ ...prevState, filesDataLoading: false, files: response.data[0].children, filteredFiles: response.data[0].children,  breadcrumbLinks: response.data[0].current_directory }))
  //       dispatch(setCommonPropsDetails('currentLocation', response.data[0]))
  //     }
  //   })
  // }

  const handleNodeClick = (node) => {
		if (node.path && node.type === 'directory') {
      setState(prevState => ({ ...prevState, filesDataLoading: true }))
      let params = createParams(state, node.path)
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response && response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
            setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            getFileFolderStats(files)
            dispatch(setCommonPropsDetails('currentLocation', responseData))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false, permissionError: 'Permission Denied!' }))
          }
        })
      }
    }
  }

  // const getLastObjectFromBreadcrumb = () => {
    // if (state.breadcrumbLinks.length === 0) {
    //   return null;
    // }

    // return state.breadcrumbLinks[state.breadcrumbLinks.length - 1];
  // };

  const handleBreadCrumb = (currentPath, selectedPath, index) => {
    let splitPath = splitString(currentPath, '/')
    let redirectPath = ''
    splitPath.forEach((row, rIndex) => {
      if(row && rIndex <= index) {
        redirectPath += '/'+row
      }
    })
    setState(prevState => ({ ...prevState, redirectPath, activePath: selectedPath, callUserFiles: true }))
  }

  const handleFileBrowserRefresh = () => {
    setState(prevState => ({ ...prevState, filesDataLoading: true }))
    // let getLastBreadcrumb = getLastObjectFromBreadcrumb()
    let currentPath = currentLocation.path ? currentLocation.path : ''
    let params = createParams(state, currentPath)
    if(params.provider && params.cluster_name) {
      dispatch(getUserFiles(params))
      .then((response) => {
        if(response && response.data) {
          let responseData = response.data && response.data.length ? response.data[0] : {}
          let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
          let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
          setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
          getFileFolderStats(responseData)
          dispatch(setCommonPropsDetails('currentLocation', response.data[0]))
        }
      })
    }
  }

  const closeErrorToast = () => {
    setState(prevState => ({ ...prevState, permissionError: null }))
  };

  /* get folder specific file structure */
  useEffect(() => {
    if (state.fileRowSelected && state.selectedPath) {
      let params = createParams(state, state.selectedPath)
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response && response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
            setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            dispatch(setCommonPropsDetails('currentLocation', responseData))
            getFileFolderStats(response.data[0])
            setState(prevState => ({ ...prevState, selectedPath: null, selectedRows: {}, fileRowSelected: false }))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false }))
            setState(prevState => ({ ...prevState, selectedPath: null, selectedRows: {}, fileRowSelected: false, permissionError: 'Permission Denied!' }))
          }
        })
      }
    }
  }, [dispatch, state, getFileFolderStats])
  
  /* get default file structure */
	useEffect(() => {
    if (state.callUserFiles) {
      setState(prevState => ({ ...prevState, callUserFiles: false }))
      // let params = createParams(state, "/data/home/hpcuser05")
      let params = createParams(state, state.redirectPath ? state.redirectPath : '')
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response && response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''

            setState(prevState => ({ ...prevState, filesDataLoading: false, fileManageError: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            dispatch(setCommonPropsDetails('currentLocation', responseData))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false, fileManageError: false, files: [], filteredFiles: [], breadcrumbLinks: '' }))
          }
        })
      }
    }
  }, [dispatch, state])

  // useEffect(() => {
  //   setState(prevState => ({ ...prevState, selectedPath: "/data/home/hpcuser05", callUserFiles: true }))
  // }, [])

  // useEffect(() => {
  //   if (state.selectedProvider && state.selectedAccount.length && state.selectedRegion.length && !selectedCluster) {
  //     setSelectedCluster('awuse1nprpc01')
  //     setState(prevState => ({
  //       ...prevState,
  //       selectedClusters: 'awuse1nprpc01'
  //     }))
  //   }
  //   console.log("asdfasdf")
  // }, [state])

  const handleClickOutside = (event) => {
    if (filterRef.current && filterRef.current.contains(event.target)) {
      dispatch(setCommonPropsDetails('closeFilters', true))
    }
  }
  
  return (
    <div className='w-full pt-6' ref={filterRef} onClick={(event) => handleClickOutside(event)}>
       {/* <div className='flex w-full'>
       {state.files && !state.files.length ?
        <p className='text-base text-black'>No storage information available for the cluster, Please contact admin</p>
       : null}
       </div> */}
       <div className="flex flex-wrap">
        <div className="w-2/12">
          <div className="bg-white border border-DeepPurpleTints-600 rounded-2xl overflow-auto lg:h-screen h-auto pt-4">
            <Sidebar
              state={state}
              onNodeClick={handleNodeClick}
            />
          </div>
        </div>
        <div className="lg:w-7/12 w-10/12 pl-2">
          <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl overflow-auto lg:h-screen h-auto'>
            <div className="p-6">
              {state.selectedClusters &&
                <React.Fragment>
                  {state.files && state.files.length ?
                    <div className='flex justify-between'>
                      <div className='flex justify-start self-end w-2/5'>
                        <Search
                            data={state.files ? state.files : []}
                            topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                            className={'rounded-full'}
                            filteredData={(filteredFiles) => {
                              setState(prevState => ({ ...prevState, filteredFiles }))
                            }}
                        />
                      </div>
                      
                      <div className={`flex justify-end self-end ${state.files ? '' : 'w-full'}`}>
                        <FileActions
                          handleFileBrowserRefresh={handleFileBrowserRefresh}
                          state={state}
                          getLastObjectFromBreadcrumb={handleFileBrowserRefresh}
                          fromScreen={props.fromScreen}
                          selectedFile={(fileContent) => {
                            if(props.fromScreen) {
                              props.selectedFile(fileContent)
                            }
                          }}
                        />
                      </div>
                    </div>
                  : null}
                  <div className='flex justify-between py-4 my-4 border-t border-NeutralGray-900 border-b border-NeutralGray-900'>
                    <nav aria-label="flex self-center">
                      <ol className="flex bg-transparent mb-0 p-1 text-base">
                          {state.breadcrumbLinks && splitString(state.breadcrumbLinks, '/') && splitString(state.breadcrumbLinks, '/').length ?
                              splitString(state.breadcrumbLinks, '/').map((spl, splIndex) => {
                                  return(
                                    spl ?
                                      <li key={'split_'+splIndex} className={`${splitString(state.breadcrumbLinks, '/').length-1 !== splIndex ? 'cursor-pointer text-black' : 'text-primaryPurple-600'}`}
                                        onClick={() => handleBreadCrumb(state.breadcrumbLinks, spl, splIndex)}
                                      >
                                        {spl}
                                        <span className='mx-1'>{splitString(state.breadcrumbLinks, '/').length !== splIndex+1 ? '/' : ''}</span>
                                      </li>
                                    : null
                                  )
                              })
                          : null}
                      </ol>
                    </nav>
                  </div>
                  {!state.fileManageError &&
                    <div className='w-full'>
                    {/* <p className='text-lg font-medium pb-3 w-full'>Recent Jobs</p> */}
                    {/* rounded-t-2xl border border-lightGray-600 shadow-lg*/}
                    <div className="overflow-x-auto">
                      <table className="w-full text-left">
                        {/* bg-lightGray-600 text-NeutralGray-800 */}
                        <thead className='uppercase text-black'>
                            <tr className="flex-1 w-full">
                                <th className='tracking-wider py-2 pl-4 text-left font-semibold text-base'>Name</th>
                                <th className='tracking-wider py-2 pl-4 text-left font-semibold text-base'>Last Modified</th>
                                <th className='tracking-wider py-2 pl-4 text-left font-semibold text-base'>Owner</th>
                                <th className='tracking-wider py-2 pl-4 text-left font-semibold text-base'>File Size</th>
                            </tr>
                        </thead>
                        <tbody className='text-sm text-NeutralGray-900'>
                            {state.filteredFiles && state.filteredFiles.length ?
                                <React.Fragment>
                                {state.filteredFiles.map((row, rowIndex) => {
                                    return(
                                        <tr key={"job_"+rowIndex} className={`text-base h-[60px] ${state.selectedItem === rowIndex ? "bg-cultured-600" : "bg-white"} w-full cursor-pointer`} onClick={() => {
                                          handleRowSelect(row, rowIndex)
                                        }}>
                                            <td className={`py-2.5 pl-4 text-left`}>
                                              <div className="flex">
                                                <Icon icon={`${row.type === 'directory' ? 'material-symbols-light:folder-outline' : 'mdi-light:file'}`} width={24} height={24} />
                                                <span>{row.name}</span>
                                              </div>
                                            </td>
                                            <td className={`py-2.5 pl-4 text-left`}>{row.modified_at ? row.modified_at : ''}</td>
                                            <td className={`py-2.5 pl-4 text-left`}>{row.modified_at ? row.modified_at : ''}</td>
                                            <td className={`py-2.5 pl-4 text-left`}>{row.size ? row.size : ''}</td>
                                        </tr>
                                    )
                                })}
                                </React.Fragment>
                            :
                                <tr className={`text-base`}>
                                  <td colSpan={4} className={`py-2.5 pl-4 text-center`}>No file storage information available for the cluster {state.selectedClusters}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
                    // <ReactTable 
                    //   columns={columns}
                    //   data={state.filteredFiles ? state.filteredFiles : []}
                    //   loading={state.filesDataLoading}
                    //   onRowSelect={handleRowSelect}
                    //   selectedColor={'bg-NeutralGray-200'}
                    // />
                  }
                </React.Fragment>
              }
              {state.fileManageError &&
                <div className="text-center">
                  <h5>File manager failed, please check admin</h5>
                </div>
              }
            </div>
          </div>
        </div>
        
        <div className="lg:w-3/12 w-full px-2">
          <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl overflow-auto p-6 lg:h-screen h-auto'>
            {state.statsDetail && state.statsDetail.data ?
              <React.Fragment>
                {state.statsDetail && state.statsDetail.details ?
                  state.statsDetail.details.map(det => {
                    return(
                    <div className="pb-5">
                      <p className="text-NeutralGray-600 text-base">{removeUnderScore(det)}:</p>
                      <p className="text-black text-base font-medium">{state.statsDetail.data[det] ? state.statsDetail.data[det] : ''}</p>
                    </div>
                    )
                  })
                : null}
              </React.Fragment>
            : null}
          </div>
        </div>
        {state.permissionError && <ErrorToast message={state.permissionError} onClose={closeErrorToast} />}
      </div>
    </div>
  );
}