/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsDashboard.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { CSVLink } from "react-csv";

import { listAlerts } from '../../../actions/Collider/AlertAction'

import { getAllTagsKeys, getAllTagsValues } from '../../../actions/commonActionNew'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage } from '../../../utils/utility'
import Button from '../../common/Form/Button';
import Search from '../../common/SearchWithHiddenInput'
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import AlertDetails from './AlertDetails';
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';
import { getPaginationShowingDetails } from '../../../utils/utility';
import AlertRules from './AlertRules';

const AlertsDashboard = (props) => {
    const [state, setState] = useState({

        showLoading: true,
        
        jumpDate: '',
        alertDetails: {},
        showAnnotation: true,
    
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        pageType: "Alerts",
        showRightSide: true,
    
        tagsArray: [0],
        alertTypes: ["All", "Application", "Infra"],
        groupedType: [
            {label: 'Severity', value: 'severity'},
            {label: 'Name', value: 'event_name'}
        ]
    })
    const dispatch = useDispatch(); // Create a dispatch function
    // const tagKeys = useSelector(state => state?.cost?.costTagKeys || false);
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);
    const hpcAlertPageFilters = useSelector(state => state?.hpc?.hpcPropsDetails?.hpcAlertPageFilters || false);
    const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);

	useEffect(() => {
        if(hpcAlertPageFilters && Object.entries(hpcAlertPageFilters).length && !_.isEqual(hpcAlertPageFilters, state.hpcAlertPageFilters)) {
            if(hpcAlertPageFilters.start_time && hpcAlertPageFilters.end_time && hpcAlertPageFilters.selectedProvider) {
                setState(prevState => ({
                    ...prevState, 
                    hpcAlertPageFilters,
                    emptySearchText: true,
                    // start_time: hpcAlertPageFilters.start_time ? hpcAlertPageFilters.start_time : '',
                    // end_time: hpcAlertPageFilters.end_time ? hpcAlertPageFilters.end_time : '',
                    // selectedProvider: hpcAlertPageFilters.selectedProvider ? hpcAlertPageFilters.selectedProvider : '',
                    // selectedAccount: hpcAlertPageFilters.selectedAccount ? hpcAlertPageFilters.selectedAccount : '',
                    // selectedRegion: hpcAlertPageFilters.selectedRegion ? hpcAlertPageFilters.selectedRegion : '',
                    showRightSide: false,
                    alertListLoader: true,
                    callAlerts: true
                }))
            }
        }
    }, [hpcAlertPageFilters, state.hpcAlertPageFilters])

    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    // const gotoPage = (pageNumber) => {
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callAlerts) {
            setState(prevState => ({ ...prevState, callAlerts: false }))
            let params = {}
            params.provider = hpcAlertPageFilters.selectedProvider.toLowerCase();

            if(hpcAlertPageFilters.selectedProjectTags && hpcAlertPageFilters.selectedProjectTags.length) {
                params.project_tags = hpcAlertPageFilters.selectedProjectTags
            }
            
            if(hpcAlertPageFilters.selectedAccount && hpcAlertPageFilters.selectedAccount.length) {
                params.account_id = hpcAlertPageFilters.selectedAccount
            }
            if(hpcAlertPageFilters.selectedRegion && hpcAlertPageFilters.selectedRegion.length) {
                params.region = hpcAlertPageFilters.selectedRegion
            }
            params.start_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.start_time, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.end_time, 'YYYY-MM-DD HH:mm:ss')
            params.aggregate_by = state.groupedBy
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }
            params.include_name = true
    
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false, structureAlerts: true }))
                        dispatch(setHpcPropsDetails('alertDetails', {}))
                        // this.props.setAiopsPropsDetails('tabOptions', {})
                    } else {
                        setState(prevState => ({ ...prevState, alertsList: [], alertListLoader: false }))
                    }
                })
        }
    }, [state.callAlerts, dispatch, state.alertType, state.groupedBy, hpcAlertPageFilters])

    useEffect(() => {
        if(state.structureAlerts) {
            setState(prevState => ({ ...prevState, structureAlerts: false }))
            let listAllAlerts = []
            
            let totalCount = 0
            let alertsCategory = []
            if(state.alertResponse && state.alertResponse.length) {
                totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
                state.alertResponse.forEach((item, index) => {
                    let dataRow = {}
                    dataRow.count = item.count
                    dataRow.category = item.label
                    dataRow.orderBy = index+1
                    let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                    let array = []
                    for(let i=1; i<=percentage; i++) {
                        array.push(i)
                    }
                    dataRow.totalBars = array
    
                    alertsCategory.push(dataRow)
                    item.alerts.forEach(alt => {
                        listAllAlerts.push(alt)
                    })
                })
            }
    
            let dataRow = {}
            dataRow.count = ''
            dataRow.category = 'Important / All'
            dataRow.orderBy = 0
            alertsCategory.push(dataRow)
    
            setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), getCategoryAlerts: true}))
        }
    }, [state.structureAlerts, state.alertResponse])
    
    const getAlertDetails = useCallback((alertItemDetails) => {
        alertItemDetails.group_id = alertItemDetails.event_id ? alertItemDetails.event_id : ''
        alertItemDetails.type = 'alert'
        setState(prevState => ({ ...prevState, selectedAlertId: alertItemDetails.event_id }))

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = alertItemDetails
        obj.merticsAssetNameInvolved = alertItemDetails.asset_id ? [alertItemDetails.asset_id] : []
        obj.datePickerStartDate = hpcAlertPageFilters.start_time 
        obj.datePickerEndDate = hpcAlertPageFilters.end_time 
        obj.pageType = "Alerts"
        dispatch(setHpcPropsDetails('alertDetails', obj))
    }, [hpcAlertPageFilters.end_time , hpcAlertPageFilters.start_time , dispatch])

    useEffect(() => {
        if(state.getCategoryAlerts) {
            setState(prevState => ({ ...prevState, getCategoryAlerts: false }))
            let filterdCategoryAlerts = state.listAllAlerts
            if(state.selectedCategory !== 'Important / All') {
                filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
                if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                    filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
                }
            }
    
            let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])
    
            let totalPages = 1
            if(orderedArray.length > state.perPage) {
                totalPages = Math.ceil(orderedArray.length / state.perPage)
            }
    
            setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlertId: '', totalPages, startRecord: 0, currentPage: 1, callFormHeaders: true }))
            if(orderedArray && orderedArray.length) {
                getAlertDetails(orderedArray[0])
            }
        }
    }, [state.getCategoryAlerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory, getAlertDetails])    
    
    const handleChange = (value) => {
        let groupedBy = state.groupedBy
        if(value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }   

        let groupedType = [
            {label: 'Severity', value: 'severity'},
            {label: 'Name', value: 'event_name'}
        ]
        if(value !== "Application") {
            groupedType.push({label: 'Assets', value: 'asset_name'})
            groupedType.push({label: 'Service', value: 'resource_type'})
            groupedType.push({label: 'Category', value: 'category'})
        }

        setState(prevState => ({ ...prevState, alertType: value, groupedBy, groupedType, selectedAlertId: '', alertListLoader: true, callAlerts: true }))
    }

    // handleClickOutside(event) {
    //     if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
    //         this.setState({ showPageTypeOptions: false })
    //     } else {
    //         this.setState({ showPageTypeOptions: true })
    //     }
    // }

    const getAllTagsKeysFunction = () => {
        if(!tagKeys) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }

    useEffect(() => {
		if(state.tag_key && state.tag_key.length) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [state.tag_key, dispatch])

    const addNewTag = (index) => {
		let selectedTags = state.selected_tags ? state.selected_tags : []

		if(state.tag_key && state.tag_key !== '' && state.tag_value && state.tag_value.length) {
			state.tag_value.forEach(tag => {
				if(!selectedTags.filter(e => e.key === state.tag_key && e.value === tag).length) {
					let dataRow = {}
					dataRow.key = state.tag_key
                    dataRow.value = tag
					selectedTags.push(dataRow)
				}
			})
		}

        setState(prevState => ({ ...prevState, selectedTags, tag_key: '', tag_value: [], tagValues: []}))
    }

	const removeTag = (tag) => {
		let filteredResult = state.selectedTags && state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}

    const resetFilterSelection = () => {
        state.tagsArray.forEach(item => {
            setState(prevState => ({ ...prevState, tag_key: "", tag_value: [], selectedTags: [] }))
        })

        setState(prevState => ({ ...prevState, tagsArray: [0], selectedService: [], assetCategory: [] }))
    }

    // const filteredResults = () => {
    //     let results = state.totalFilteredArray ? state.totalFilteredArray : []
        
    //     if(results && results.length && state.tagsArray && state.tagsArray.length) {
    //         let tagMatchedData = []
    //         let recordExist = false
    //         state.tagsArray.forEach(item => {
    //             if(state["tag_key_"+item] && state["tag_key_"+item] !== "" && state["tag_value_"+item] && state["tag_value_"+item] !== "") {
    //                 recordExist = true
    //                 if(tagMatchedData && tagMatchedData.length) {
    //                     let newTagsMatched = []
    //                     tagMatchedData.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             newTagsMatched.push(itm)
    //                         }
    //                     })
    //                     tagMatchedData = newTagsMatched
    //                 } else {
    //                     results.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             tagMatchedData.push(itm)
    //                         }
    //                     })                        
    //                 }
    //             }
    //         })
    //         if(recordExist) {
    //             results = tagMatchedData
    //         }
    //     }

    //     if(state.searchText && state.searchText !== '') {
	// 		results =
	// 			results &&
	// 			results.filter(item => {
	// 				let isPresent = []
	// 				isPresent = recursiveSearch(item, state.searchText)
	// 					.flat()
	// 					.filter(bool => bool === true)
	// 				if (isPresent[0]) {
	// 					return true
	// 				} else {
	// 					return false
	// 				}
	// 			})
	// 	}

    //     let totalPages = 1
    //     if(results && results.length > state.perPage) {
    //         totalPages = Math.ceil(results.length / state.perPage)
    //     }
    //     let showLoadMore = false
    //     if(results.length > state.endRecord) {
    //         showLoadMore = true
    //     } 

    //     setState(prevState => ({ ...prevState, filteredArray: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore }))
    //     // formHeaders()    
    // }

	useEffect(() => {
        if(state.callFormHeaders) {
            setState(prevState => ({ ...prevState, callFormHeaders: false }))
            let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
            if(dataResult.length) {
                let headers = []
                Object.entries(dataResult[0]).forEach(([key, value]) => {
                    if(typeof value === "string" && key !== "event_data") {
                        let headerRow = {}
                        headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                        headerRow.key = key
                        headers.push(headerRow)
                    }
                })
                setState(prevState => ({ ...prevState, headers }))
            }
        }
	}, [state.callFormHeaders, state.filteredArray])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const getStatusBgColor = (status) => {
        let color = 'bg-darkPurple-50'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'bg-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'bg-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'bg-primaryPurple-600' }
        }
        return color;
    }

    const getStatusColor = (status) => {
        let color = 'text-CyanBlueAzure'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'text-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'text-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'text-primaryPurple-600' }
        }
        return color;
    }

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            {state.showAlertRules ?
                <AlertRules
                    closeSidePanel={() => setState(prevState => ({...prevState, showAlertRules: false }))}
                />
            : null}
            <div className={`flex justify-between my-6 ${state.alertListLoader ? 'disabled' : ''}`}>
                <div className={`flex`}>
                    <p className="m-0 self-end mr-2 font-medium text-lg">Show </p>
                    <div className={`self-center mx-2`}>
                        <SelectOption
                            label={""}
                            options={state.alertTypes}
                            selectedValues={state.alertType ? state.alertType : ''}
                            callbackMultiSelect={(value) => {
                                handleChange(value)
                            }}
                            singleSelection={true}
                            dropdownWidth={'min-w-32'}
                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                        />
                    </div>
                    <p className="m-0 self-end small mr-2 font-medium text-lg"> alerts groupd by</p>
                    <div className={`self-center mx-2`}>
                        <SelectOption
                            label={""}
                            options={state.groupedType}
                            fields={["value", "label"]}
                            selectedValues={state.groupedBy ? state.groupedBy : ''}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, groupedBy: value, selectedAlertId: '', selectedCategory: "Important / All", alertListLoader: true, callAlerts: true }))
                            }}
                            singleSelection={true}
                            dropdownWidth={'min-w-32'}
                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                        />
                    </div>
                    <div className='bg-PrimaryLightOrange-600 cursor-pointer self-center rounded-full p-1' onClick={() => {
                            getAllTagsKeysFunction()
                            setState(prevState => ({ ...prevState, showFilterSection: !state.showFilterSection }))
                        }} >
                            <Icon icon="heroicons-solid:filter" width={18} height={18} className='text-white'/>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M18.4927 2.59754L18.4929 2.598C18.5725 2.78161 18.5316 2.99334 18.3925 3.13488L12.4951 9.13522V15.2064C12.4951 15.3939 12.3886 15.562 12.2231 15.6457L12.2224 15.646L8.22417 17.648L8.22278 17.6487C8.06903 17.7242 7.88778 17.7155 7.74239 17.6284C7.74238 17.6284 7.74238 17.6283 7.74237 17.6283L7.84516 17.4568C7.75735 17.4042 7.70302 17.3107 7.70154 17.2091L18.4927 2.59754ZM18.4927 2.59754C18.4135 2.41607 18.2328 2.3 18.0356 2.3H1.96111V2.29998L1.95864 2.30002C1.7629 2.30243 1.58445 2.41856 1.50648 2.60007L1.50648 2.60007C1.42865 2.78125 1.46657 2.98981 1.60167 3.13228L1.60165 3.1323L1.60415 3.13485L7.50154 9.1352L18.4927 2.59754ZM11.6405 8.59233L11.6402 8.59202L11.6317 8.60196C11.554 8.69211 11.5085 8.80478 11.5003 8.92169L11.4998 8.92166V8.93564V14.9043L8.49686 16.4086V8.93551C8.49686 8.92624 8.49622 8.91699 8.49494 8.90782C8.48851 8.86184 8.47938 8.81817 8.46787 8.77471C8.46333 8.75756 8.45653 8.74109 8.44764 8.72574C8.42612 8.68854 8.40183 8.65038 8.37388 8.61287C8.36845 8.60558 8.36253 8.59867 8.35615 8.59219L3.13909 3.28443H16.8576L11.6405 8.59233Z" fill="white" stroke="white" stroke-width="0.4" stroke-linejoin="round"/>
                        </svg> */}
                    </div>
                </div>
                <div className='flex justify-end'>
                    <Button
                        classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', margin: 'mr-2', text: 'text-white text-base font-bold', others: 'cursor-pointer text-center'}}
                        label={'Alert Rule'}
                        callback={() => setState(prevState => ({ ...prevState, showAlertRules: true }))}
                    />
                </div>
            </div>
            {state.showFilterSection ?
                <div className="shadow-lg border border-byzantineBlue rounded-2xl absolute w-1/2">
                    <div className="p-3 bg-white rounded-2xl">
                        <p className="mb-1">Tags</p>
                        <div className={`flex flex-wrap`}>
                            <div className='flex flex-wrap lg:w-5/6'>
                                <SelectOption
                                    label={""}
                                    options={tagKeys}
                                    selectedValues={state.tag_key ? state.tag_key : []}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, tag_key: value, tagValues: [] }))
                                    }}
                                    singleSelection={true}
                                    dropdownWidth={'min-w-48'}
                                    classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                />
                                {state.tag_key && !state.tagValues.length ?
                                    <div className='ml-3'>
                                        <LoadingCircle />
                                    </div>
                                :
                                    <div className='ml-3'>
                                        <SelectOption
                                            label={""}
                                            options={state.tagValues}
                                            selectedValues={state.tag_value ? state.tag_value : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
                                            singleSelection={false}
                                            dropdownWidth={'min-w-64'}
                                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-1", bg: ' ', rounded: ' ' }}
                                        />
                                    </div>
                                }
                            </div>
                            <Icon icon="iconoir:plus" width="25" height="25"  className='text-black pl-3 self-center lg:w-1/6 cursor-pointer' onClick={() => addNewTag()}/>
                        </div>
                        {state.selectedTags && state.selectedTags.length ? 
                            <div className={`flex flex-wrap gap-2 mt-2`}>
                                {state.selectedTags.map((tag, tIndex) => {
                                    return(
                                        <span key={'tag_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black py-1 break-all rounded-full' id={'tag_'+tIndex}>
                                            <div className='truncate'  style={{maxWidth: '250px'}}>
                                                {tag.key +' : '+tag.value}
                                            </div>
                                            <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={() => removeTag(tag)}/>
                                        </span>
                                    )
                                })}
                            </div>
                        : null}
                        <div className="flex justify-end mt-3">
                            <span className={`text-0059F3 font-sm mr-2 text-byzantineBlue self-center cursor-pointer`} onClick={() => resetFilterSelection()}>Clear</span>
                            <Button
                                classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                label={'Filter'}
                                callback={() => {
                                    setState(prevState => ({ ...prevState, showFilterSection: false }))
                                }}
                            />
                            <Button
                                classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                label={'Close'}
                                callback={() => {
                                    setState(prevState => ({ ...prevState, showFilterSection: false }))
                                }}
                            />
                        </div>
                    </div>
                </div>
            : null}

            <div className="flex flex-wrap">
                <div className={`mt-[13px] ${alertDetails && Object.entries(alertDetails).length ? 'lg:w-3/5 md:w-3/5 w-full' : 'w-full'} h-full`}>
                    <div className="border border-PrimaryTintsDeepPurpleTints-600 rounded-2xl bg-white">
                        <div className="flex justify-between">
                            <div className="self-center mr-2 lg:w-3/5 w-full pl-6 pt-5 pb-6">
                                <p className='mb-0 self-center text-[28px] font-semibold'>Alerts List</p>
                                <p className="mb-0 self-center ">Showing 
                                    <span className='mx-1'>{getPaginationShowingDetails(state.filteredArray && state.filteredArray.length, state.totalFilteredArray && state.totalFilteredArray.length, state.currentPage, state.perPage)}</span>
                                        out of total {state.totalFilteredArray && state.totalFilteredArray.length} alert(s)
                                    <span className="mb-0 self-center ml-1 font-bold">{hpcAlertPageFilters.start_time  ? momentDateGivenFormat(hpcAlertPageFilters.start_time , 'DD MMM YYYY') +' - '+ momentDateGivenFormat(hpcAlertPageFilters.end_time , 'DD MMM YYYY') : ''}</span>
                                </p>
                            </div>
                            <div className="flex justify-end lg:w-2/5 md:2/5 w-full self-center pt-5 pr-[22px] pb-6">
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <Search
                                        data={state.totalFilteredArray? state.totalFilteredArray: []}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredArray) => {
                                            setState(prevState => ({ ...prevState, filteredArray, alertDetails: {}, merticsAssetNameInvolved: [], emptySearchText: false }))
                                            if(filteredArray && filteredArray.length) {
                                                getAlertDetails(filteredArray[0])
                                            }
                                        }}
                                    />
                                : null}
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <CSVLink 
                                        data={state.filteredArray} 
                                        headers={state.headers ? state.headers : []}
                                        filename={"observability-alerts.csv"}
                                        className={"self-center mr-2"}
                                        target="_blank"
                                    >
                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center ml-2' />
                                    </CSVLink>
                                : null}
                            </div>
                        </div>
                        {state.filteredArray && state.filteredArray.length > state.perPage ?
                            <div className='flex justify-end text-black w-full pb-4 pr-3'>
                                <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                <div className="flex text-sm">
                                    <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-500' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                                    <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                                </div>
                            </div>
                        : null}

                        {state.totalFilteredArray && state.totalFilteredArray.length ? 
                            <div className="flex flex-wrap border-t border-PrimaryTintsDeepPurpleTints-600">
                                {state.alertsCategory ? 
                                    <div className="xl:w-1/4 lg:w-2/6 w-full pr-2">
                                        <div className="bg-NeutralGray-400 rounded-lg border border-NeutralGray-600 ml-6 mt-6 px-3 py-4">
                                            {state.groupedBy === "severity" ?
                                                state.alertsCategory.map(item => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div className={`flex justify-between ${state.selectedCategory === item.category ? `${getStatusBgColor(item.category)} 'pointer-events-none'`: ''}`} onClick={() => {
                                                                if(state.selectedCategory !== item.category) {
                                                                    setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                                }
                                                            }}>
                                                                <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                    <p className="text-base font-medium mb-0" style={{ minWidth: '75px' }}>{item.category}</p>
                                                                    <p className="text-base font-bold mb-0">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className={`flex justify-between mt-2 ${!item.count ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => {
                                                                if(state.selectedCategory !== item.category) {
                                                                    setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                                }
                                                            }}>
                                                                <div className="flex w-full max-w-md">
                                                                    <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                        <p className={`text-base font-medium mb-0 truncate ${getStatusColor(item.category)}`} style={{ minWidth: '75px' }}>{item.category}</p>
                                                                        <p className={`text-base font-bold mb-0 ${getStatusColor(item.category)}`}>{item.count}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                            :
                                                state.alertsCategory.map((item, index) => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                        <div className={`flex justify-between ${state.selectedCategory === item.category ? `${getStatusBgColor(item.category)} 'pointer-events-none'}` : ''}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                            }
                                                        }}>
                                                            <div className="flex w-full max-w-md">
                                                                <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                    <p className="text-base font-medium mb-0" style={{ minWidth: '75px' }}>{item.category}</p>
                                                                    <p className="text-base font-bold mb-0">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className={`flex justify-between mt-2 ${!item.count ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                            }
                                                        }}>
                                                           <div className="flex w-full max-w-md">
                                                                <div className={`flex justify-between text-medium border border-NeutralGray-600 bg-white rounded-2xl p-3 w-full`}>
                                                                    <p className={`text-base font-medium mb-0 ${getStatusColor(item.category)}`} style={{ minWidth: '75px' }}>{item.category}</p>
                                                                    <p className={`text-base font-bold mb-0 ${getStatusColor(item.category)}`}>{item.count}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                : null}
                                <div className={`${state.alertsCategory ? 'xl:w-3/4 lg:w-4/6' : ''} w-full`}>
                                    <div className={`bg-white ml-5 mt-6 mr-6`}>
                                        {state.alertListLoader ? 
                                            <div className='flex justify-center m-4'>
                                                <LoadingCircle />
                                            </div>
                                        : state.filteredArray && state.filteredArray.length ?
                                            state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`flex mr-2 mb-2 ${state.startRecord + state.perPage !== state.startRecord + i ? 'border-2  rounded-2xl' : ''} ${state.selectedAlert === i ? 'bg-aliceBlue-100 border-byzantineBlue' : 'bg-NeutralGray-100 border-aliceBlue-600 cursor-pointer'}`} 
                                                    onClick={() => {
                                                        setState(prevState => ({ ...prevState, selectedAlert: i }))
                                                        getAlertDetails(item)
                                                    }}
                                                    >  
                                                        <div className="flex">
                                                            <div className={`flex h-full w-6 rounded-l-2xl ${getStatusBgColor(item.severity)}`}>
                                                                <span className={`self-center text-base font-medium mr-2 mt-1 px-2 text-white`}>
                                                                    {item.severity ?    
                                                                        (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                    : 'U'}
                                                                </span>
                                                            </div>
                                                            <div className='flex px-[20px] p-2'>
                                                                <p className="mb-0 text-sm">
                                                                    <span className="mb-0 mr-2 font-bold text-NeutralGray-700">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    <span className={`mb-0 mr-2 font-semibold text-primaryPurple-600`}>
                                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                        {item.event_name ? ' : ' + item.event_name : ''}
                                                                        {item.event_type ? ' : '+ item.event_type : ''}</span>
                                                                    {item.asset_name ?
                                                                        <span className="mb-0 mr-2">{item.asset_name}</span>
                                                                    : null}
                                                                    <span className="mb-0 small mr-1 font-bold text-NeutralGray-700">Resource</span>
                                                                    {item.account_id ?
                                                                        <span className="mb-0 font-bold text-NeutralGray-700">{' '+item.account_id}</span>
                                                                    : null}
                                                                    {item.region ?
                                                                        <span className="mb-0 font-bold text-NeutralGray-700">{' : '+item.region}</span>
                                                                    : null}
                                                                    {item.resource_type ?
                                                                        <span className="mb-0 font-bold text-NeutralGray-700">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                    : null}
                                                                    {item.service_name ?
                                                                        <span className="mb-0 font-bold text-NeutralGray-700 mr-2">{' : '+item.service_name}</span>
                                                                    : null}
                                                                    {item.description ?
                                                                        <span className="mb-0 ml-2 text-black text-semisolid">{item.description}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                            <div className='flex justify-center m-4'>
                                                <p>{getCommonInformationMessage('alert')}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
                {alertDetails && Object.entries(alertDetails).length && !state.alertListLoader ?
                    <div className={`mt-[13px] lg:w-2/5 md:w-2/5 w-full overflow-auto pl-4`}>
                        <AlertDetails />
                    </div>
                : null}
            </div>
        </div>
    )
}

export default AlertsDashboard