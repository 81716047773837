/*************************************************
 * Collider
 * @exports
 * @file CreateProviderAllcoation.js
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getProjectCredits, listDepartments } from '../../../actions/cost/BudgetAction';
import { setCostPropsDetails } from '../../../actions/cost/CostAction';
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';
import Textbox from '../../common/Form/Textbox';
import { LoadingCircle } from '../../common/LoadingCiricle';


const CreateProviderDepartmentCredit = ({ year, selectedProvider }) => {
	const[state, setState] = useState({})
	
	const createInputs = useSelector(state => state?.cost?.costPropsDetails?.createInputs || false);
	const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		let params = {}
        dispatch(listDepartments(params))
            .then((response) => {
                let departmentsList =  response && response.results ? response.results : []
                setState(prevState => ({ ...prevState, departmentsList }))
            })
    }, [dispatch])

	useEffect(() => {
		if(year && selectedProvider && state.department) {
			alert(1)
			let params = {
				year: year,
				department: state.department,
				provider: selectedProvider.toLowerCase()
			}
			dispatch(getProjectCredits(params))
				.then((response) => {
					if(response && !response.error) {
						let results = response?.result || {}
						setState(prevState => ({ ...prevState, deparmentCredits: results, structureResponse: true, showLoading: false }))
						dispatch(setCostPropsDetails('createInputs', results))
					} else {
						setState(prevState => ({ ...prevState, deparmentCredits: [], showLoading: false }))
					}
				})
		}
	}, [dispatch, year, state.department, selectedProvider])

	useEffect(() => {
		if(state.structureResponse) {
			setState(prevState => ({ ...prevState, structureResponse: true }))
			let initiatives = state.deparmentCredits?.initiatives || []
			initiatives.forEach((inv, invIndex) => {
				if(inv.projects && inv.projects.length) {
					inv.projects.forEach((proj, projIndex) => {
						proj.provider_credits && Object.entries(proj.provider_credits).forEach(([key, value]) => {
							setState(prevState => ({
								...prevState,
								['credits_'+invIndex+'_'+key+'_'+projIndex]: value?.credits || 0
							}))
						})
					})
				}
			})
		}
	}, [state.deparmentCredits, state.structureResponse, state.selectedProvider])

	const onChangeHandleValidation = useCallback((field, value, index, subIndex, selectedProviderLower) => {
		let hasError = false
		let obj = createInputs ? createInputs : {}
		
		let providerTotal = {}
		let initiatives = obj.initiatives ? obj.initiatives : []
		if(field === 'credits' && initiatives.length) {
			initiatives.forEach((inv, invIndex) => {
				let projects = inv?.projects || []
				projects.forEach((proj, projIndex) => {
					let providerCredits = proj?.provider_credits || {}
					providerCredits && Object.entries(providerCredits).forEach(([key, cred]) => {
						if(invIndex === index && projIndex === subIndex && key === selectedProviderLower) {
							value = parseInt(value)
						} else {
							value = cred.credits
						}
						providerCredits[key] = value
						providerTotal[key] = (providerTotal?.[key] || 0) + value;
					})
				})
			})

			let totalProviderCredits = createInputs?.provider_credits?.[selectedProviderLower]?.credits || 0

			if(totalProviderCredits < providerTotal[selectedProviderLower]) {
				hasError = true
			}
		} else {
			obj[field] = (field === 'provider' ? value.toLowerCase() : value)
		}
		
		setState(prevState => ({ ...prevState, hasError }))

		console.log("obj", obj)

		dispatch(setCostPropsDetails('createInputs', obj))
		

		// const totals = initiatives.reduce((acc, initiative) => {
		// 	initiative.projects.forEach(project => {
		// 		const creditProviders = project.provider_credits || {};
		// 		console.group("creditProviders", creditProviders)
		// 		for (const credProvider in creditProviders) {
		// 			alert(credProvider)
		// 			acc[credProvider] = (acc[credProvider] || 0) + (creditProviders[credProvider].credits || 0);
		// 		}
		// 	});
		// 	return acc;
		// }, {});
		
	}, [createInputs, dispatch])

	return (
		<div className='pb-8'>
			<div className='bg-NeutralGray-100 border border-byzantineBlue rounded-2xl p-6'>
				<div className='flex flex-wrap'>
					<div className="md:w-1/2 md:pr-3 w-full">
						<SelectOption
							label={"Department"}
							options={state.departmentsList}
							selectedValues={state.department ? state.department : ''}
							callbackMultiSelect={(value) => {
								setState(prevState => ({ ...prevState, department: value, getDeparmentDetails: true }))
							}}
							manditory={true}
							hasError={state.hasError}
							singleSelection={true}
						/>
					</div>
				</div>
				{!state.showLoading ? 
					state.deparmentCredits && state.deparmentCredits.initiatives && state.deparmentCredits.initiatives.length ?
						state.deparmentCredits.initiatives.map((inv, invIndex) => {
							return(
								<div className='border border-NeutralGray-400 p-6 rounded-2xl mt-8'>
									<p className='flex text-lg text-black mb-4'>Initiative :
										<span className={`flex justify-between w-1/2 text-NeutralGray-600 text-base ml-3`}>{inv.initiative}</span>
									</p>
									{inv.projects && inv.projects.length ?
										inv.projects.map((pro, projIndex) => {
											return(
												<div key={'pro_'+projIndex}>
													<div className='flex flex-wrap p-2'>
														<div className='md:w-3/12 w-full self-center'>
															<div className='flex justify-start'>
																<p className="b-block mb-0 text-NeutralGray-800 text-base font-medium">
																{pro.project}
																</p>
															</div>
														</div>
														<div className='md:w-3/12 w-full mb-2 pl-0'>
															<Textbox
																label={''}
																selectedValue={state['credits_'+invIndex+'_'+state.selectedProviderLower+'_'+projIndex] ? state['credits_'+invIndex+'_'+state.selectedProviderLower+'_'+projIndex] : ''}
																callback={(value) => {
																	setState(prevState => ({ ...prevState, ['credits_'+invIndex+'_'+state.selectedProviderLower+'_'+projIndex]: value}))
																	onChangeHandleValidation("credits", value, invIndex, projIndex, state.selectedProviderLower)
																}}
																type={'number'}
																inputType={state.pageType}
																placeholder={'credits'}
															/>
														</div>
													</div>
												</div>
											)
										})
									: null}
								</div>
							)
						})
					: null
				:
					<div className='flex justify-center m-4'>
						<LoadingCircle />
					</div>
				}
			</div>
		</div>
	)
}

export default CreateProviderDepartmentCredit