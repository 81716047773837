/*
 * @exports
 * @file AlertRules.js
 * @author Prakash
*/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { Icon } from '@iconify/react';
import RuleTemplate from './RuleTemplate';
import ListRules from './ListRules';
import Button from '../../common/Form/Button';

const AlertRules = (props) => {
	const clickOut = useRef()
	const[state, setState] = useState({
		selectedSection: ''
	})

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	const handleChildClick = (event, type, dropdownType, section) => {
		if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
	}

	return (
		<div className="overflow-y-auto">            
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='h-full flex flex-col bg-white lg:w-4/5 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>{state.selectedSection === "template" ? 'Create' : 'Alert'} Rules</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className={`text-black ${state.onClickSave ? "pointer-events-none" : "cursor-pointer"} self-center`} width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
					</div>
					{state.selectedSection !== 'template' ?
						<div className='flex justify-end pt-3'>
							<Button
								classDetails={{bg: 'bg-pumpkin-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', margin: 'mr-2', text: 'text-white text-base foselectedSectionnt-bold', others: 'cursor-pointer text-center'}}
								label={'Create Rule'}
								callback={() => setState(prevState => ({ ...prevState, selectedSection: 'template' }))}
							/>
						</div>
					: null}
					{state.selectedSection === "template" ?
						<RuleTemplate 
							closeForm={() => setState(prevState => ({ ...prevState, selectedSection: '' }))}
							closeSidePanel={() => props.closeSidePanel}
						/>
					: 
						<ListRules
							closeSidePanel={() => props.closeSidePanel}
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default AlertRules