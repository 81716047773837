import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import Textbox from '../../../common/Form/Textbox';
import {validateUri, validateDatabaseUsername} from "../../../../utils/utility";

const ExistingDatabaseSection = (props) => {
	const [state, setState] = useState({});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

  const dispatch = useDispatch(); // Create a dispatch function

  	const callPageVist = useCallback(() => {
    	let databaseConfiguration = createUpdateCluster && createUpdateCluster.database_configuration ? createUpdateCluster.database_configuration : {};
        if (databaseConfiguration) {
          	Object.keys(databaseConfiguration).forEach(([key, value]) => {
            let clusterFields = [
              "database",
              "user_name",
              "password_secret_arn"
            ];
            if (clusterFields.includes(key)) {
              setState((prevState) => ({ ...prevState, [key]: value }));
            }
          });
        }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

	const handleDatabaseInput = (label, value) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let databaseConfiguration = obj.database_configuration ? obj.database_configuration : {};
		databaseConfiguration[label] = value;

		obj["database_configuration"] = databaseConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));

		setState((prevState) => ({ ...prevState, [label]: value }));
	};

  	const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      	if (state.clickedChild && state.clickedChild.length) {
			state.clickedChild.forEach((row) => {
				setState((prevState) => ({ ...prevState, [row]: false }));
			});
      	}
		clickedChild.push(section);
		setState((prevState) => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild}));
    } else if (type === "parent") {
		if (state.clickedChild && state.clickedChild.length) {
			state.clickedChild.forEach((row) => {
				setState((prevState) => ({ ...prevState, [row]: false }));
			});
      	}

      	setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
  <div onClick={(event) => handleChildClick(event, "parent")}>
    <div className='flex flex-wrap pt-8'>
		<div className='flex flex-col justify-start w-full lg:pr-4'>
			<Textbox
				label={"Database Uri"}
				type="text"
				selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : "" }
				callback={(value) => {
					handleDatabaseInput("database", validateUri(value))
				}}
				placeholder={'Enter database uri'}
                manditory={true}
                hasError={props.hasError}
                // disabled={props.pageMode === 'Edit'}
			/>
			<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.database}</p>
		</div>
	</div>
    <div className='flex flex-wrap pt-8'>
		<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
			<Textbox
				label={"Username"}
				type="text"
				selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : "" }
				callback={(value) => {
					handleDatabaseInput('user_name', validateDatabaseUsername(value))
				}}
				placeholder={'Enter username (Eg. admin)'}
                manditory={true}
                hasError={props.hasError}
                // disabled={props.pageMode === 'Edit'}
			/>
			<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.database_configuration?.user_name}</p>
		</div>
		<div className='flex flex-col justify-start lg:w-1/2 w-full lg:pl-4'>
			<Textbox
				label={"Password secret amazon resource name (ARN)"}
				type="text"
				selectedValue={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.password_secret_arn ? createUpdateCluster.database_configuration.password_secret_arn : "" }
				callback={(value) => {
					handleDatabaseInput('password_secret_arn', validateUri(value))
				}}
				placeholder={'Enter secret arn'}
                manditory={true}
                hasError={props.hasError}
                // disabled={props.pageMode === 'Edit'}
			/>
			<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.directory_configuration?.password_secret_arn}</p>
		</div>
	</div>
  </div>
);
};

export default ExistingDatabaseSection;
