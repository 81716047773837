import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Icon} from "@iconify/react";

import SelectOption from '../../common/Form/SelectOption';
import { listQueues, listUsersByCluster } from '../../../actions/Collider/ClusterAction';
import { listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew';
import { getAccountNameFromId, getRegionName } from '../../../utils/utility'
import RunReport from './RunReport';
import ScheduleReport from './ScheduleReport';
import RunCostReportSection from './RunCostReportSection';
import RunDiskReportSection from './RunDiskReportSection';

const aggregateByOptions = [
    {
        "label": "Project",
        "value": "project"
    },
    {
        "label": "Cluster",
        "value": "cluster_name"
    },
    {
        "label": "Queue",
        "value": "queue_name"
    },
    {
        "label": "User",
        "value": "user_name"
    }
]


const ViewReport = ({ selectedReport, selectedProjectView, selectedProvider, selectedAccountView, selectedRegionView, selectedClustersView, datePickerStartDate, datePickerEndDate, closeReport }) => {
    const [state, setState] = useState({
        aggregateByOptions: aggregateByOptions
    });
    const dispatch = useDispatch();
    
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    
    useEffect(() => {
        setState(prevState => ({ ...prevState, 
            selectedProvider , 
            selectedProject: selectedProjectView, 
            selectedAccount: selectedAccountView, 
            selectedRegion: selectedRegionView, 
            selectedClusters: selectedClustersView, 
            // ['selectedProject_'+selectedReport.report_id]: selectedProjectView,
            // ['selectedAccount_'+selectedReport.report_id]: selectedAccountView,
            // ['selectedRegion_'+selectedReport.report_id]: selectedRegionView,
            // ['selectedClusters_'+selectedReport.report_id]: selectedClustersView,
            getAccountRegionList: true, 
            getClusterList: true, 
            getQueuesUserList: true 
        }))
    }, [selectedProjectView, selectedProvider, selectedAccountView, selectedRegionView, selectedClustersView, selectedReport.report_id])

    useEffect(() => {
        if(state.getAccountRegionList) {
            setState(prevState => ({ ...prevState, getAccountRegionList: false }));
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            let label = state.selectedProvider
            if(state['selectedProject_'+selectedReport.report_id]) {
                params.project = state['selectedProject_'+selectedReport.report_id]
                label = state.selectedProvider+'_'+state['selectedProject_'+selectedReport.report_id]
            }
            
            let accountParams = {
                aggregate_by: 'account_id'
            }
            accountParams = {...params, ...accountParams}
            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ["accounts_"+selectedReport.report_id]: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(accountParams))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                            }
                            setState(prevState => ({ ...prevState, ["accounts_"+selectedReport.report_id]: response }));
                        }
                    })
            }

            let regionParams = {
                aggregate_by: 'region'
            }
            regionParams = {...params, ...regionParams}
            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ["regions_"+selectedReport.report_id]: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(regionParams))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjRegions ? propProjRegions : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjRegions', obj))
                            }
                            setState(prevState => ({ ...prevState, ["regions_"+selectedReport.report_id]: response }));
                        }
                        
                    })
            }
        }
    }, [state, dispatch, propProjAccounts, propProjRegions, selectedReport.report_id])

    useEffect(() => {
        if(state.getQueuesUserList) {
            setState(prevState => ({ ...prevState, getQueuesUserList: false }));
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state['selectedProject_'+selectedReport.report_id]) {
                params.project = state['selectedProject_'+selectedReport.report_id]
            }
            if(state['selectedAccount_'+selectedReport.report_id] && state['selectedAccount_'+selectedReport.report_id].length) {
                params.account_id = state['selectedAccount_'+selectedReport.report_id]
            }
            if(state['selectedRegion_'+selectedReport.report_id] && state['selectedRegion_'+selectedReport.report_id].length) {
                params.region = state['selectedRegion_'+selectedReport.report_id]
            }
            if(state['selectedClusters_'+selectedReport.report_id] && state['selectedClusters_'+selectedReport.report_id].length) {
                params.cluster_name = state['selectedClusters_'+selectedReport.report_id]
            }
            
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ["queues_"+selectedReport.report_id]: response }));
                    }
            })

            dispatch(listUsersByCluster(params))
                .then((response) => {
                    if(response && !response.error) {
                        setState(prevState => ({ ...prevState, ["users_"+selectedReport.report_id]: response }));
                    }
                })
        }
    }, [state, dispatch, selectedReport.report_id])

    useEffect(() => {
        if(state.getClusterList) {
            setState(prevState => ({ ...prevState, getClusterList: false }));
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state['selectedProject_'+selectedReport.report_id]) {
                params.project = state['selectedProject_'+selectedReport.report_id]
            }
            if(state['selectedAccount_'+selectedReport.report_id] && state['selectedAccount_'+selectedReport.report_id].length) {
                params.account_id = state['selectedAccount_'+selectedReport.report_id]
            }
            if(state['selectedRegion_'+selectedReport.report_id] && state['selectedRegion_'+selectedReport.report_id].length) {
                params.region = state['selectedRegion_'+selectedReport.report_id]
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ["clusters_"+selectedReport.report_id]: response }));
                    }
            })
        }
    }, [state.getClusterList, state, selectedReport.report_id, dispatch])

    useEffect(() => {
        if(state.getAggregateOptions) {
            setState(prevState => ({ ...prevState, getAggregateOptions: false }))
            let options = aggregateByOptions
            if(state['selectedProject_'+selectedReport.report_id]) {
                options = aggregateByOptions.filter(e => e.label !== 'Project')
            }
            if(state['selectedClusters_'+selectedReport.report_id] && state['selectedClusters_'+selectedReport.report_id].length) {
                options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster'))
            }
            if(state['selectedQueues_'+selectedReport.report_id] && state['selectedQueues_'+selectedReport.report_id].length) {
                options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster' && e.label !== 'Queue'))
            }
            if(state['selectedUser_'+selectedReport.report_id]) {
                options = aggregateByOptions
            }

            setState(prevState => ({ ...prevState, ['aggregateByOptions_'+selectedReport.report_id]: options, selectedGroupBy: '' }))
        }
        
    }, [state, selectedReport.report_id])


    const removeItem = (field, value) => {
        let results = state[field].filter(e => e !== value)
        setState(prevState => ({ ...prevState, [field]: results}))
    }

    return (
        <div className={`py-3 w-full`}>
            {state.showReportDetails ?
                <RunReport
                    selectedProvider={selectedProvider}
                    datePickerStartDate={datePickerStartDate}
                    datePickerEndDate={datePickerEndDate}
                    selectedReportInput={{
                        selectedProject: state['selectedProject_'+selectedReport.report_id],
                        selectedAccount: state['selectedAccount_'+selectedReport.report_id],
                        selectedRegion: state['selectedRegion_'+selectedReport.report_id],
                        selectedClusters: state['selectedClusters_'+selectedReport.report_id],
                        selectedQueues: state['selectedQueues_'+selectedReport.report_id],
                        // type: state.selectedType,
                        selectedUser: state['selectedUser_'+selectedReport.report_id],
                        selectedType: state['selectedType'+selectedReport.report_id],
                        selectedGroupBy: state['selectedGroupBy_'+selectedReport.report_id]
                    }}
                    selectedReport={selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showReportDetails: false }))}
                />
            : null}
            {state.showCostReportDetails ?
                <RunCostReportSection
                    selectedProvider={selectedProvider}
                    datePickerStartDate={datePickerStartDate}
                    datePickerEndDate={datePickerEndDate}
                    selectedReportInput={{
                        selectedProject: state['selectedProject_'+selectedReport.report_id],
                        selectedAccount: state['selectedAccount_'+selectedReport.report_id],
                        selectedRegion: state['selectedRegion_'+selectedReport.report_id],
                        selectedClusters: state['selectedClusters_'+selectedReport.report_id],
                        selectedQueues: state['selectedQueues_'+selectedReport.report_id],
                        // type: state.selectedType,
                        selectedUser: state['selectedUser_'+selectedReport.report_id],
                        selectedType: state['selectedType'+selectedReport.report_id],
                        selectedGroupBy: state['selectedGroupBy_'+selectedReport.report_id]
                    }}
                    selectedReport={selectedReport}
                    closeSidePanel={() => {
                        setState(prevState => ({ ...prevState, showCostReportDetails: false }))
                    }}
                />
            : null}

            {state.showDiskReport ? 
                <RunDiskReportSection
                    selectedProvider={selectedProvider}
                    datePickerStartDate={datePickerStartDate}
                    datePickerEndDate={datePickerEndDate}
                    selectedReportInput={{
                        selectedProject: state['selectedProject_'+selectedReport.report_id],
                        selectedAccount: state['selectedAccount_'+selectedReport.report_id],
                        selectedRegion: state['selectedRegion_'+selectedReport.report_id],
                        selectedClusters: state['selectedClusters_'+selectedReport.report_id],
                        selectedQueues: state['selectedQueues_'+selectedReport.report_id],
                        selectedUser: state['selectedUser_'+selectedReport.report_id],
                        selectedType: state['selectedType'+selectedReport.report_id],
                        selectedGroupBy: state['selectedGroupBy_'+selectedReport.report_id]
                    }}
                    selectedReport={selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showDiskReport: false }))}
                />
            : null}
            
            {state.showSchedule ?
                <ScheduleReport  
                    selectedDetails={selectedReport}
                    closeSidePanel={() => setState(prevState => ({...prevState, showSchedule: false }))}
                />
            : null}
            <div className='flex flex-wrap leading-5'>
                {selectedReport && selectedReport.filters && selectedReport.filters.includes('project') ?
                    <React.Fragment>
                        <span className='mx-2 self-end text-black text-base'>Project</span>
                        <div className='w-32 self-end mr-2'>
                            <SelectOption
                                label={""}
                                fields={['project', 'project']}
                                options={propProjects[state.selectedProvider]}
                                selectedValues={state["selectedProject_"+selectedReport.report_id] ? state["selectedProject_"+selectedReport.report_id] : ''}
                                callbackMultiSelect={(value) => 
                                    setState(prevState => ({ 
                                        ...prevState, 
                                        ["selectedProject_"+selectedReport.report_id]: value, 
                                        ["selectedClusters_"+selectedReport.report_id]: [], 
                                        ["selectedQueues_"+selectedReport.report_id]: [], 
                                        ["selectedUser_"+selectedReport.report_id]: '',
                                        getAccountRegionList: true,
                                        getClusterList: true,
                                        getQueuesUserList: true,
                                        getAggregateOptions: true
                                    }))
                                }
                                singleSelection={true}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                    </React.Fragment>
                : null}
                {selectedReport && selectedReport.filters && selectedReport.filters.includes('account') ?
                    <React.Fragment>
                        <span className='mr-2 self-end text-black text-base'>Account</span>
                        <div className='w-32 self-end mr-2'>
                            <SelectOption
                                label={""}
                                fields={["account_id", "account_name"]}
                                options={state["accounts_"+selectedReport.report_id]}
                                selectedValues={state["selectedAccount_"+selectedReport.report_id] ? state["selectedAccount_"+selectedReport.report_id] : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ 
                                        ...prevState, 
                                        ["selectedAccount_"+selectedReport.report_id]: value, 
                                        ["selectedClusters_"+selectedReport.report_id]: [], 
                                        ["selectedQueues_"+selectedReport.report_id]: [], 
                                        ["selectedUser_"+selectedReport.report_id]: '',
                                        getClusterList: true,
                                        getQueuesUserList: true
                                    }))
                                }}
                                singleSelection={false}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                        {state["selectedAccount_"+selectedReport.report_id] && state["selectedAccount_"+selectedReport.report_id].length ? 
                            state["selectedAccount_"+selectedReport.report_id].map((tab, addIndex) => {
                                return(
                                    <p key={'account_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                        {getAccountNameFromId(tab, state["accounts_"+selectedReport.report_id])}
                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedAccount_"+selectedReport.report_id, tab)}>x</span>
                                    </p>
                                )
                            })
                        : null}
                    </React.Fragment>
                : null}

                {selectedReport && selectedReport.filters && selectedReport.filters.includes('region') ?
                    <React.Fragment>
                        <span className='mx-2 self-end text-black text-base'>Region</span>
                        <div className='w-32 self-end'>
                            <SelectOption
                                label={""}
                                fields={["region", "name"]}
                                options={state["regions_"+selectedReport.report_id]}
                                selectedValues={state["selectedRegion_"+selectedReport.report_id] ? state["selectedRegion_"+selectedReport.report_id] : []}
                                callbackMultiSelect={(value) =>
                                    setState(prevState => ({ ...prevState, ["selectedRegion_"+selectedReport.report_id]: value, ["selectedClusters_"+selectedReport.report_id]: [], ["selectedQueues_"+selectedReport.report_id]: [], ["selectedUser_"+selectedReport.report_id]: '',getClusterList: true, getQueuesUserList: true }))
                                }
                                singleSelection={false}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                        {state["selectedRegion_"+selectedReport.report_id] && state["selectedRegion_"+selectedReport.report_id].length ? 
                            state["selectedRegion_"+selectedReport.report_id].map((tab, addIndex) => {
                                return(
                                    <p key={'region_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                        {getRegionName(tab, state["regions_"+selectedReport.report_id])}
                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedRegion_"+selectedReport.report_id, tab)}>x</span>
                                    </p>
                                )
                            })
                        : null}
                    </React.Fragment>
                : null}

                {selectedReport && selectedReport.filters && selectedReport.filters.includes('cluster') ?
                    <React.Fragment>
                        <span className='mx-2 self-end text-black text-base'>Cluster</span>
                        <div className='w-32 self-end'>
                            <SelectOption
                                label={""}
                                options={state["clusters_"+selectedReport.report_id]}
                                selectedValues={state["selectedClusters_"+selectedReport.report_id] ? state["selectedClusters_"+selectedReport.report_id] : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, ["selectedClusters_"+selectedReport.report_id]: value, ["selectedQueues_"+selectedReport.report_id]: [], ["selectedUser_"+selectedReport.report_id]: '', getQueuesUserList: true, getAggregateOptions: true }))
                                }}
                                singleSelection={false}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                        {state["selectedClusters_"+selectedReport.report_id] && Array.isArray(state["selectedClusters_"+selectedReport.report_id]) ? 
                            state["selectedClusters_"+selectedReport.report_id].map((tab, addIndex) => {
                                return(
                                    <p key={'clus_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                        {tab}
                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedClusters_"+selectedReport.report_id, tab)}>x</span>
                                    </p>
                                )
                            })
                        : null}
                    </React.Fragment>
                : null}
                
                {selectedReport && selectedReport.filters && selectedReport.filters.includes('queue') ?
                    <React.Fragment>
                        <span className='mx-2 self-end text-black text-base'>Queue</span>
                        <div className='w-32 self-end'>
                            <SelectOption
                                label={""}
                                options={state["queues_"+selectedReport.report_id]}
                                selectedValues={state["selectedQueues_"+selectedReport.report_id] ? state["selectedQueues_"+selectedReport.report_id] : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, ["selectedQueues_"+selectedReport.report_id]: value, getAggregateOptions: true }))
                                }}
                                singleSelection={false}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                        {state["selectedQueues_"+selectedReport.report_id] && state["selectedQueues_"+selectedReport.report_id].length ? 
                            state["selectedQueues_"+selectedReport.report_id].map((tab, addIndex) => {
                                return(
                                    <p key={'queue_tab_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                        {tab}
                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedQueues_"+selectedReport.report_id, tab)}>x</span>
                                    </p>
                                )
                            })
                        : null}
                    </React.Fragment>
                : null}

                {selectedReport && selectedReport.filters && selectedReport.filters.includes('user') ?
                    <React.Fragment>
                        <span className='mx-2 self-end text-black text-base'>User</span>
                        <div className='w-32 self-end'>
                            <SelectOption
                                label={""}
                                fields={["userId", "shortName"]}
                                options={state["users_"+selectedReport.report_id]}
                                selectedValues={state["selectedUser_"+selectedReport.report_id] ? state["selectedUser_"+selectedReport.report_id] : ''}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["selectedUser_"+selectedReport.report_id]: value, getAggregateOptions: true }))}
                                singleSelection={true}
                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                            />
                        </div>
                    </React.Fragment>
                : null}
                {selectedReport && selectedReport.type && selectedReport.type.length ?
                    <React.Fragment>
                    <span className='mx-2 self-end text-black text-base'>type</span>
                    <div className='w-32 self-end mr-2'>
                        <SelectOption
                            label={""}
                            fields={["value", 'label']}
                            options={selectedReport.type}
                            selectedValues={state["selectedType_"+selectedReport.report_id] ? state["selectedType_"+selectedReport.report_id] : ''}
                            callbackMultiSelect={(value) => 
                                setState(prevState => ({ 
                                    ...prevState, 
                                    ["selectedType_"+selectedReport.report_id]: value
                                }))
                            }
                            singleSelection={true}
                            addAllField={true}
                            classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                        />
                    </div>
                    </React.Fragment>
                : null}
                {selectedReport && selectedReport.aggregate_by && selectedReport.aggregate_by.length ?
                    <React.Fragment>
                    <span className='mx-2 self-end text-black text-base'>group by</span>
                    <div className='w-32 self-end mr-2'>
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={state["aggregateByOptions_"+selectedReport.report_id] ?state["aggregateByOptions_"+selectedReport.report_id] : state.aggregateByOptions}
                            selectedValues={state["selectedGroupBy_"+selectedReport.report_id] ? state["selectedGroupBy_"+selectedReport.report_id] : ''}
                            callbackMultiSelect={(value) => 
                                setState(prevState => ({ ...prevState, ["selectedGroupBy_"+selectedReport.report_id]: value }))
                            }
                            singleSelection={true}
                            deSelect={true}
                            classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                        />
                    </div>
                    </React.Fragment>
                : null}

                <div className={`flex justify-end w-full mt-6 ${state.queryLoading ? "pointer-events-none" : ""}`}>
                    <span className={`flex mb-0 text-white bg-primaryPurple-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3  min-w-28`} onClick={() => setState(prevState => ({ ...prevState, showSchedule: true }))}>
                        <Icon icon="mingcute:schedule-line" className={`mr-1 text-white cursor-pointer`} width={20} height={20} /> Schedule
                    </span>
                    <span className={`mb-0 text-white bg-pumpkin-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3  min-w-28`} 
                        onClick={() => {
                            if(selectedReport.category && selectedReport.category.length && selectedReport.category[0] === "Budget and Cost") {
                                setState(prevState => ({ ...prevState, showCostReportDetails: true }))
                            } else if(selectedReport.report_id === "HPC014") {
                                setState(prevState => ({ ...prevState, showDiskReport: true }))
                            } else {
                                setState(prevState => ({ ...prevState, showReportDetails: true }))
                            }
                        }}
                    >
                        Run Report
                    </span>
                    <span className={`mb-0 text-white bg-DeepPurpleTints-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base min-w-28 text-center`} onClick={() => closeReport()}>Close</span>
                </div>
            </div>
        </div>
    )
}
export default ViewReport;
    