
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 07/03/2024
 * @copyright © 2019-24 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setCommonPropsDetails, listClusterFilters } from '../../../actions/commonActionNew'
import { listQueues, getQueueDetails, getJobDetails, getQueueAvailabilityDetails } from '../../../actions/Collider/ClusterAction'

// import ApexSingleDonutChart from '../../common/charts/ApexSingleDonutChart';
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import { getCommonInformationMessage, momentDateFormat } from '../../../utils/utility';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        startRecordRunning: 0, 
        currentPageRunning: 1,
        totalPagesRunning: 1,
        perPage: 5,
        
        startRecordPending: 0, 
        currentPagePending: 1,
        totalPagesPending: 1,
        topSectionFilters: ['provider', 'account', 'region', 'cluster' ],
        donoutChartColors: {
            "CPU Cores": ['#ADB5BD', '#2D60A6'],
            "GPUs": ['#ADB5BD', '#57AF3E'],
            "Memory (GB)": ['#ADB5BD', '#FC6D22'],
            "Nodes": ['#ADB5BD', '#FFB534'],
            "vCPUs": ['#ADB5BD', '#7551C2'],
            "Used": ['#ADB5BD', '#ADB5BD']
        }
    })

    const providers = useSelector(state => state?.filters?.providers || false);

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // list all providers
    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true }));
    //     }
    // }, [providers]);

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            showLoading: true,
            callQueueList: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], showLoading: true, callSearch: true, startRecordRunning: 0, currentPageRunning: 1, totalPagesRunning: 1, startRecordPending: 0, currentPagePending: 1, totalPagesPending: 1, perPage: 20, callClusterList: true }));
    }                

    useEffect(() => {
        if (state.callClusterList) {
            setState(prevState => ({ ...prevState, callClusterList: false }))
            let params = {};
            params.aggregate_by = 'cluster_name';
            params.provider = state.selectedProvider.toLowerCase();

            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, selectedClusters: response.length ? response[0] : '',  callQueueList: true }));
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.onLoad])

    useEffect(() => {
        if (state.callQueueList) {
            setState(prevState => ({ ...prevState, callQueueList: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: response.length ? response[0] : '', callGetQueueDetails: true, callGetQueueAvailability: true, callGetJobDetails: true, showLoading: true }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if (state.callGetQueueDetails) {
            setState(prevState => ({ ...prevState, callGetQueueDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getQueueDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueDetails: results, structureQueueDetails: true, showLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])
    useEffect(() => {
        if (state.callGetQueueAvailability) {
            setState(prevState => ({ ...prevState, callGetQueueAvailability: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(getQueueAvailabilityDetails(params))
                .then((response) => {
                    if(response) {
                        // results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueAvailility: response.queue_details, showLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueAvailability, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])

    useEffect(() => {
        if (state.callGetJobDetails) {
            setState(prevState => ({ ...prevState, callGetJobDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getJobDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results : []
                        let runningJobs = results['Running Jobs'] ? results['Running Jobs'] : {}
                        let pendingJobs = results['Pending Jobs'] ? results['Pending Jobs'] : {}

                        let totalPagesRunning = 1
                        if(runningJobs.jobs_summary && runningJobs.jobs_summary.length > state.perPage) {
                            totalPagesRunning = Math.ceil(runningJobs.jobs_summary.length / state.perPage)
                        }
                        let totalPagesPending = 1
                        if(pendingJobs.jobs_summary && pendingJobs.jobs_summary.length > state.perPage) {
                            totalPagesPending = Math.ceil(pendingJobs.jobs_summary.length / state.perPage)
                        }
                        setState(prevState => ({
                            ...prevState,
                            jobDetails: results,
                            runningJobs,
                            pendingJobs,
                            filterdJobDetails: results,
                            totalPagesRunning,
                            totalPagesPending
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue, state.perPage])

    useEffect(() => {
        if (state.structureQueueDetails) {
            setState(prevState => ({ ...prevState, structureQueueDetails: false }))
            state.queueDetails && state.queueDetails.chart_details && Object.entries(state.queueDetails.chart_details).forEach(([key, value]) => {
                let donut = []
                let donut_total_count = 0				
                value.data.forEach((row, rIndex) => {
                    let donutRow = {}
                    donutRow.item_count = row > 0 ? row : 0
                    donutRow.item_name = {label: value.labels[rIndex], tooltip: value.tooltip[rIndex] }
                    donut.push(donutRow)
                    donut_total_count += row 
                })
        
                let donutData = {
                    'items': donut,
                    'label': 'Max ' + key,
                    'total_count': donut_total_count
                }        
                //services Donut start
                setState(prevState => ({ ...prevState, ['donutGraph_'+key]: donutData }))
            })
        }
    }, [state.structureQueueDetails, state.queueDetails])

    // Function to navigate between pages
    const navigateRunningPage = (action, currentPageRunning) => {
        // Update startRecordRunning and currentPageRunning based on action
        let startRecordRunning = state.startRecordRunning
        if(action === 'next' && currentPageRunning !== state.totalPagesRunning) {
            startRecordRunning = startRecordRunning + state.perPage;
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning+ 1 }));
        } else if(action === 'previous' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning - 1 }));
            startRecordRunning = startRecordRunning - state.perPage;
        } else if(action === 'start' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: 1 }));
            startRecordRunning = 0;
        } else if(action === 'end' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: state.totalPagesRunning }));
            startRecordRunning = (state.totalPagesRunning - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordRunning }))
    }
    const navigatePendingPage = (action, currentPagePending) => {
        // Update startRecordPending and currentPagePending based on action
        let startRecordPending = state.startRecordPending
        if(action === 'next' && currentPagePending !== state.totalPagesPending) {
            startRecordPending = startRecordPending + state.perPage;
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending+ 1 }));
        } else if(action === 'previous' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending - 1 }));
            startRecordPending = startRecordPending - state.perPage;
        } else if(action === 'start' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: 1 }));
            startRecordPending = 0;
        } else if(action === 'end' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: state.totalPagesPending }));
            startRecordPending = (state.totalPagesPending - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordPending }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPagesRunning) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPagesRunning) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const handleChildClick = (event, type, dropdownType, section) => {		
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-[27px] px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Queue Status</p>
                        <small className="text-white text-base">Consolidated view of queue status</small>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            onLoadFields={['selectedClusters']}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            showSelectedCluster={true}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center min-h-screen mb-6'>
                <div className="w-full lg:w-1/5 sm:w-1/3 bg-white h-screen overflow-auto border border-DeepPurpleTints-600 rounded-2xl">
                    <p className="text-[22px] text-DeepPurpleTints-600 mt-[22px] ml-6 mb-[30px] uppercase font-bold tracking-[0.4px]">Queues <span className='mt-1 truncate text-NeutralGray-600'>({state.selectedClusters})</span></p>
                    {state.queues && state.queues.length ?
                        state.queues.map((item, i) => {
                            return(
                                <div className="mt-6"  key={'queue_'+i}>
                                <div className={`py-[7px] pl-6 pr-10 cursor-pointer ${state.selectedQueue === item ? 'bg-white pointer-events-none' : "hover:bg-white"}`}
                                    onClick={() => {
                                        if(state.selectedQueue !== item) {
                                            setState(prevState => ({ ...prevState, selectedQueue: item, showLoading: true, callGetQueueDetails: true, callGetJobDetails:  true }))
                                        }
                                    }}
                                >
                                    <div className="flex overflow-hidden">
                                        <p className={`font-medium text-base ${state.selectedQueue && state.selectedQueue === item ? 'text-PrimaryLightOrange-600' : 'text-textGray-600'}`}
                                        >{item}</p>
                                    </div>
                                    <p className="mt-1 text-base">
                                        <span className={`font-semibold ${state.selectedQueue && state.selectedQueue === item ? 'text-black' : 'text-lightGray'} mr-2`}>Cores Available</span>
                                        <span  className={`font-semibold text-PrimaryLightOrange-600`}>{state.queueAvailility && state.queueAvailility.filter(e => e.queue_name === item).length && state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores ? state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores.available : ""}</span>
                                    </p>
                                </div>
                                </div>
                            )
                        })
                    : 
                        <div className='flex justify-center m-4 text-black'>
                            {getCommonInformationMessage('queue')}
                        </div>
                    }
                </div>
                <div className="w-full lg:w-4/5 sm:w-2/3 flex justify-center h-screen overflow-auto">
                    <div className="bg-white w-full vh100 overflow-auto ml-[17px] rounded-2xl border border-DeepPurpleTints-600">
                    <div className="flex justify-between items-center mt-[22px]">
                        <p className="text-[22px] font-bold text-DeepPurpleTints-600 ml-6 uppercase tracking-[0.4px]">Overview 
                            <span className='pl-2 text-NeutralGray-600'>({state.selectedClusters} - {state.selectedQueue})</span>
                        </p>
                        <div className="flex justify-end mr-[25px] mt-[2px]">
                            {state.queueDetails && state.queueDetails.chart_details && Object.keys(state.queueDetails.chart_details).map((key) => (
                                <div key={key} className="flex items-center mr-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                        <circle cx="6.5" cy="7" r="6.5" fill={state.donoutChartColors[key][1]} />
                                    </svg>
                                    <p className="pl-[6px] text-xs">{key}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                        <div className="flex flex-wrap border-b border-DeepPurpleTints-600 ml-6 mb-10 mr-[25px]">
                            {state.queueDetails && state.queueDetails.chart_details ?
                                Object.keys(state.queueDetails.chart_details).map((key, kIndex) => {
                                    return(
                                        <React.Fragment key={'key_'+kIndex}>
                                        {state['donutGraph_'+key] ? 
                                            <div className={`w-full ${Object.keys(state.queueDetails.chart_details).length > 4 ? 'lg:w-1/5' : 'lg:w-1/4'} flex flex-col items-center mt-[30px] mb-8`}>
                                                <div className="flex justify-center">
                                                    <ApexDonutChart
                                                        graphData={state['donutGraph_'+key]}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        totalLableType={'integer'}
                                                        showGraphHeading={false}
                                                        height={Object.keys(state.queueDetails.chart_details).length > 4 ? 180 : 220}
                                                        width={Object.keys(state.queueDetails.chart_details).length > 4 ? 180 : 220}
                                                        labelSize={Object.keys(state.queueDetails.chart_details).length > 4 ? '14px' : '16px'}
                                                        labelWeight={600}
                                                        valueSize={Object.keys(state.queueDetails.chart_details).length > 4 ? '24px' : '28px'}
                                                        valueWeight={500}
                                                        labelColor={'text-NeutralGray-700'}
                                                        labelStyle={state.labelStyle}
                                                        valueStyle={state.valueStyle}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        gradientColor={['#FFFFFF', '#ADB5BD']}
                                                        colors={state.donoutChartColors[key]}
                                                        hideTooltip={true}
                                                    />
                                                </div>
                                            </div>
                                        : null}
                                        </React.Fragment>
                                    )
                                })
                            : null}
                        </div>
                        <div className='flex flex-wrap ml-6 mb-[22px]'>
                            <div className='w-full lg:w-1/2'>
                                <div className='bg-white h-full rounded-2xl border border-NeutralGray-500'>
                                    <div className="bg-gray-200 py-[22px] pl-6 pr-15 rounded-t-2xl ">
                                        <p className='text-black w-1/3 font-medium text-lg'>Running Jobs</p>
                                        <p className='text-sm text-NeutralGray-700'>{state.runningJobs && state.runningJobs.description ? state.runningJobs.description : ""}</p>
                                        {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length > state.perPage ?
                                        <div className='flex justify-end text-black w-full'>
                                            <span className='mx-3 self-center'>Page {state.currentPageRunning} of {state.totalPagesRunning} </span>
                                            <div className="flex text-sm">
                                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md  ${state.currentPageRunning !== 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} 
                                                    onClick={() => navigateRunningPage('previous', state.currentPageRunning)}/>
                                                </span> 
                                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 border border-lightGray rounded-md ${state.currentPageRunning !== state.totalPagesRunning ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} 
                                                    onClick={() => navigateRunningPage('next', state.currentPageRunning)}/>
                                                </span>
                                            </div>
                                        </div>
                                        : null}
                                    </div>
                                    <div className="mt-6 mx-6">
                                    {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length ?
                                        state.runningJobs.jobs_summary.slice(state.startRecordRunning, state.startRecordRunning + state.perPage).map((row, rIndex )=> {
                                            return(
                                                <div key={'job_' + rIndex} className={`pt-[17px] ${rIndex ? 'border-t border-DeepPurpleTints-600' : ''}`}>
                                                    <p className="flex flex-wrap text-black text-sm">
                                                        {console.log("row", row)}
                                                        <span className="mb-0 mr-2 font-bold text-gray-600">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                        <span className={`mb-0 mr-2 text-byzantineBlue`}>{row.job_name ? row.job_name : ''}</span>
                                                        <span className="mb-0 mr-2 font-bold">job id <span className='text-black font-bold'>{row.job_id ? row.job_id : ''}</span></span>
                                                        <span className="mb-0 mr-2 text-byzantineBlue">submitted by <span className='text-byzantineBlue'>{row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                        <span className="mb-0 mr-2">Allocated</span>
                                                        <span className="mb-0 font-semibold mr-2">cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                        {row.gpus ? (
                                                            <span className="mb-0 font-semibold mr-2">gpu {row.gpus ? row.gpus : 0}</span>
                                                        ) : null}
                                                        <span className="mb-0 font-semibold">memory {row.memory ? row.memory : 0}</span>
                                                        {row.memory_unit ? (
                                                            <span className="mb-0 font-semibold ml-1 mr-2">({row.memory_unit})</span>
                                                        ) : null}
                                                        <span className="mb-0 font-semibold mr-2">nodes {row.nodes ? row.nodes : 0}</span>
                                                        <span className="mb-0 font-semibold mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                        <span className="mb-0 font-semibold mr-2">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                        {row.expected_completion_time ? (
                                                            <>
                                                            <span className="mr-2 mb-0 font-semibold">expected completion time</span>
                                                            <span className="mr-2 mb-0 font-semibold">{row.expected_completion_time} (utc time)</span>
                                                            </>
                                                        ) : null}
                                                    </p>

                                                    <div className='w-1/4 flex items-center'>
                                                        <div className='w-full flex justify-between'>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_critical_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${(row.used_vcpus_percentage && row.used_vcpus_percentage)}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_medium_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_memory_percentage && row.used_memory_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_high_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_cpu_cores_percentage && row.used_cpu_cores_percentage}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_low_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${row.used_nodes_percentage && row.used_nodes_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :   <div className='flex justify-center my-4 mx-2 self-center text-black'>
                                            <span className='text-base'>No running jobs in this queue</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className='w-full lg:w-1/2 px-[25px]'>
                                <div className='bg-white h-full rounded-2xl border border-NeutralGray-500'>
                                    <div className="bg-gray-200 py-[22px] pl-6 pr-15 rounded-t-2xl ">
                                        <p className='text-black w-1/3 font-medium text-lg'>Pending Jobs</p>
                                        <p className='text-sm text-NeutralGray-700'>{state.pendingJobs && state.pendingJobs.description ? state.pendingJobs.description : ""}</p>
                                        {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length > state.perPage ?
                                            <div className='flex justify-end text-black w-full'>
                                                <span className='mx-3 self-center'>Page {state.currentPagePending} of {state.totalPagesPending} </span>
                                                <div className="flex text-sm">
                                                    <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.currentPagePending !== 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} 
                                                        onClick={() => navigatePendingPage('previous', state.currentPagePending)}
                                                    /></span> 
                                                    <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 border border-lightGray rounded-md ${state.currentPagePending !== state.totalPagesPending  ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} 
                                                        onClick={() => navigatePendingPage('next', state.currentPagePending)}
                                                    /></span>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                    
                                    <div className="mt-6 mx-6">
                                    {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length ?
                                        state.pendingJobs.jobs_summary.slice(state.startRecordPending, state.startRecordPending + state.perPage).map((row, rIndex )=> {
                                            return(
                                                <div key={'job_' + rIndex} className={`pt-[17px] ${rIndex ? 'border-t border-DeepPurpleTints-600' : ''}`}>
                                                    <p className="flex flex-wrap text-black text-sm">
                                                            <span className="mb-0 mr-2 font-bold text-gray-600">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                            <span className={`mb-0 mr-2 text-byzantineBlue`}>{row.job_name ? row.job_name : ''}</span>
                                                            <span className="mb-0 mr-2 font-bold">job id <span className='text-black font-bold'>{row.job_id ? row.job_id : ''}</span></span>
                                                            <span className="mb-0 mr-2 text-byzantineBlue">submitted by <span className='text-byzantineBlue'>{row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                            <span className="mb-0 mr-2">Requested</span>
                                                            <span className="mb-0 font-normal mr-2">cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                            <span className="mb-0 font-normal">memory {row.memory ? row.memory : 0}</span>
                                                            {row.memory_unit ? (
                                                                <span className="mb-0 font-normal ml-1">({row.memory_unit})</span>
                                                            ) : null}
                                                            <span className="mb-0 font-normal mr-2">nodes {row.nodes ? row.nodes : 0}</span>
                                                            <span className="mb-0 font-normal mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                            <span className="mb-0 font-normal mr-2">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                            {row.expected_completion_time ? (
                                                                <>
                                                                <span className="mr-2 mb-0 font-normal">expected completion time</span>
                                                                <span className="mr-2 mb-0 font-normal">{row.expected_completion_time} (utc time)</span>
                                                                </>
                                                            ) : null}
                                                        </p>
                                                    
                                                    <div className='w-1/4 flex items-center'>
                                                        <div className='w-full flex justify-between'>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_critical_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${(row.used_vcpus_percentage && row.used_vcpus_percentage)}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_medium_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_memory_percentage && row.used_memory_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_high_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_cpu_cores_percentage && row.used_cpu_cores_percentage}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_low_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${row.used_nodes_percentage && row.used_nodes_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :   <div className='flex justify-center m-4 text-black'>
                                            <span className='text-base'>No pending jobs in this queue</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage