/*************************************************
 * Collider
 * @exports
 * @file utility.js
 * @author Rajasekar // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
*************************************************/
import moment from 'moment'

export let userSessionTimer
let sessionTime = 0
let hasReloaded = false


export function myLog(...message) {
	// console.log(...message)
}

export const refreshPage = () => {
	if(!hasReloaded) {
		hasReloaded = true
	}
	// window.location.reload(true);
}

export const dayOfThisWeek = () => {
	let dayofWeek = moment().isoWeekday() + 1
	if(dayofWeek === 8) {
		dayofWeek = 1
	}
	return '+'+dayofWeek +'d'
}
/**
 * To validate email is valid or not
 * @param {String} email
 */
export const validateEmail = email => {
	if(email !== undefined && email !== '') {
		// eslint-disable-next-line no-useless-escape
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	} else {
		return ''
	}
}

export const validatePhone = (input) => {
	var pattern = new RegExp(/^[0-9\b]+$/);
	
	if (!pattern.test(input) || input.length < 9) {
		return false
	} else {
		return true
	}
}

export const validateVolumeName = (input) => {
	let string = input.replace(/[^a-zA-Z0-9_]{0,202}/g, '');
    if (string.length > 0 && !/^[a-zA-Z_]/.test(string[0])) {
        string = string.slice(1);
    }
    return string
}

export const validateMountDir = (input) => {
	let string = input.replace(/[^a-zA-Z0-9/]{0,202}/g, '');
    if (string.length > 0 && !/^[a-zA-Z/]/.test(string[0])) {
        string = string.slice(1);
    }
    return string
}

export const validateDomainAddress = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9/:,.]/g,'');
	} else {
		return ''
	}
}

export const validateDomainName = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9,=]/g,'');
	} else {
		return ''
	}
}

export const validateUri = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9.:-]/g,'');
	} else {
		return ''
	}
}

export const validateDatabaseUsername = string => {
	if(string !== undefined && string !== '' && string.length <= 16) {
		return string.replace(/[^a-zA-Z0-9]/g,'');
	} else {
		return ''
	}
}

// export const validateClusterName = string => {
//     if (string !== undefined && string !== '' && string.length <= 40) {
// 		return string.replace(/[^a-zA-Z0-9-]/gi,'');
//     } else {
//         return '';
//     }
// };

export const thousandSeparator = (value) => {
	if(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}
	return value
}

// Format date as DD/MM/YYYY
export const formatDate = (value) => {
	// Remove non-digit characters
	const cleaned = value.replace(/\D/g, '');

	// Format to DD/MM/YYYY
	const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);

	if (match) {
	const formattedDate = [match[1], match[2], match[3]]
		.filter(Boolean) // Remove empty strings
		.join('-'); // Join parts with "/"
	return formattedDate;
	}

	return value;
};

/**
 * Change number format
 * @param {String} string
 */
export const numberFormater = string => {
	if(string !== undefined && string !== '') {
		if(parseInt(string) >= 10000 && parseInt(string) <= 99999) {
			return string.toString().slice(0,2) + 'k'	
		} else if(parseInt(string) >= 100000 && parseInt(string) <= 999999) {
			return string.toString().slice(0,3) + 'k'	
		} else if(parseInt(string) >= 1000000 && parseInt(string) <= 9999999) {
			return string.toString().slice(0,4) + 'k'	
		} else if(parseInt(string) >= 10000000 && parseInt(string) <= 99999999) {
			return string.toString().slice(0,5) + 'k'	
		} else {
			return string
		}
	} else {
		return ''
	}
}


/**
 * Change convert bytes format
 * @param {String} string
 */
export const convertBytes = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}				
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'GB'
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = string + 'TB'
			}
		}
	}
	return returnVal
}

/**
 * Change convert bytes format without unit
 * @param {String} string
 */
export const convertBytesWithoutUnit = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2)
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2)
				}				
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0)
			} else {
				returnVal = string
			}
		}
	}
	return returnVal
}

/**
 * Change Count format
 * @param {String} string
 */
export const countFormater = (string, valueType) => {	
	if(string !== undefined && string !== '') {
		// if(parseInt(string) > 99 && parseInt(string) <= 999) {
		// 	return parseFloat(string / 100).toFixed(2) + 'H'
		// } else 
		// string = parseInt(string)
		if(parseInt(string) >= 1000 && parseInt(string) < 1000000) {
			if(valueType === "round") {
				return parseFloat(string / 1000).toFixed(0) + 'K'
			} else {
				return parseFloat(string / 1000).toFixed(2) + 'K'
			}
		} else if(parseInt(string) >= 1000000 && parseInt(string) < 1000000000) {
			if(valueType === "round") {
				return parseFloat((string / 1000)/1000).toFixed(0) + 'M'
			} else {
				return parseFloat((string / 1000)/1000).toFixed(2) + 'M'
			}
		} else if(parseInt(string) >= 1000000000 && parseInt(string) <= 1000000000000) {
			if(valueType === "round") {
				return parseFloat(((string / 1000)/1000)/1000).toFixed(0) + 'B'
			} else {
				return parseFloat(((string / 1000)/1000)/1000).toFixed(2) + 'B'
			}
		} else if(parseInt(string) >= 1000000000000) {
			if(valueType === "round") {
				return parseFloat((((string / 1000)/1000)/1000)/1000).toFixed(0) + 'T'
			} else {
				return parseFloat((((string / 1000)/1000)/1000)/1000).toFixed(2) + 'T'
			}
		} else {
			if(valueType === "round") {
				return parseFloat(string).toFixed(0)
			} else {
				return parseFloat(string).toFixed(2)
			}
		}
	} else {
		return ''
	}
}

export const countFormaterWithoutUnit = (string, valueType) => {	
	if(string !== undefined && string !== '') {
		// if(parseInt(string) > 99 && parseInt(string) <= 999) {
		// 	return parseFloat(string / 100).toFixed(2) + 'H'
		// } else 
		// string = parseInt(string)
		if(parseInt(string) >= 1000 && parseInt(string) < 1000000) {
			string = (string / 1000)
		} else if(parseInt(string) >= 1000000 && parseInt(string) < 1000000000) {
			string = ((string / 1000)/1000)
		} else if(parseInt(string) >= 1000000000 && parseInt(string) <= 1000000000000) {
			string = (((string / 1000)/1000)/1000)
		} else if(parseInt(string) >= 1000000000000) {
			string = ((((string / 1000)/1000)/1000)/1000)
		}
		if(valueType && valueType === "round") {
			return parseInt(string)
		} else {
			return parseFloat(string).toFixed(2)
		}
	} else {
		return ''
	}
}

/**
 * Change convert seconds format
 * @param {String} string
 */
export const convertSeconds = (string, unit, valueType) => {
	if(string !== undefined && string !== '') {
		if(unit === 'Seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + 'ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + 'ms'
					} else {
						return string.toFixed(2) + 'ms'
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60) + 'M'
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2) + 'H'
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24) + 'D'
			} else {
				return string +'S'
			}
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + 'M'
				} else if(string >= 3600 && string < 86400) {
					return parseFloat((string / 60)/60).toFixed(2) + 'H'
				} else if(string >= 86400) {
					return parseFloat(((string / 60)/60)/24).toFixed(2) + 'D'
				} else {
					return string > 0 ? string.toFixed(1) : string + 'S'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + 'ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + 'M'
				} else if(string >= 3600 && string < 86400) {
					return parseFloat((string / 60)/60).toFixed(2) + 'H'
				} else if(string >= 86400) {
					return parseFloat(((string / 60)/60)/24).toFixed(2) + 'D'
				} else {
					return string > 0 ? string.toFixed(1) : string + 'S'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + 'ms'
			}
		}
	}
}

export const convertSecondsWithoutUnit = (string, unit) => {
	if(string !== undefined && string !== '') {
		//let int = parseInt(string).toString()
		if(unit === 'Seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(2)
				} else {
					if(string > 1) {
						return string.toFixed(2)
					} else {
						return string.toFixed(2)
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		} else if(unit === 'Microseconds') {
			string = (string /1000000)
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		} else if(unit === 'Milliseconds') {
			string = (string / 1000)
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		}
	}
}

/**
 * Change convert bits format
 * @param {String} string
 */
export const convertBits = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			}
		} else if(unit === 'Kilobits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			}
		} else if(unit === 'Megabits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			}
		} else if(unit === 'Gigabits') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'GB'
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = parseFloat(string).toFixed(2) + 'TB'
			}
		}
	}	
	return returnVal
}

export const convertBitsWithoutUnit = (string, unit) => {
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5 && int.length <= 8) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else if(int.length > 8 && int.length <= 12) {
				return parseFloat(((string / 1024)/1024)/1024).toFixed(2)
			} else if(int.length > 12) {
				return parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Kilobits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5 && int.length <= 8) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else if(int.length > 8) {
				return parseFloat(((string / 1024)/1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Megabits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Gigabits') {
			if(int.length >= 3) {
				return parseFloat(string / 1024).toFixed(2)
			} else {
				return string
			}
		} else {
			return parseFloat(string).toFixed(2)
		}
	} else {
		return ''
	}
}

export const getConvertedBytesUnit = (string, unit, valueType, retrunOnlyUnit) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'KB'
			} else if(int.length > 5 && int.length <= 8) {
				returnVal = 'Mb'
			} else if(int.length > 8 && int.length <= 12) {
				returnVal = 'GB'
			} else if(int.length > 12) {
				returnVal = 'TB'
			} else {
				returnVal = 'B'
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'Mb'
			} else if(int.length > 5 && int.length <= 8) {
				returnVal = 'GB'
			} else if(int.length > 8) {
				returnVal = 'TB'
			} else {
				returnVal = 'kb'
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'GB'
			} else if(int.length > 5) {
				returnVal = 'TB'
			} else {
				returnVal = 'Mb'
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				returnVal = 'TB'
			} else {
				returnVal = 'GB'
			}
		} else {
			returnVal = 'TB'
		}
	}
	return returnVal
}

export const convertBytesTo = (string, unit, valueType, convertTo) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(convertTo === "B") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			} else if(convertTo === "KB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}				
			} else if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(convertTo === "KB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			} else if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}				
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} 
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}				
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'Mb'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}				
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = string + 'TB'
			}
		}
	}
	return returnVal
}

/**
 * Change first letter to capital
 * @param {String} string
 */
export const capitalizeFirstLetter = string => {
	if(string !== undefined && string !== '') {
		return string.charAt(0).toUpperCase() + string.slice(1)
	} else {
		return ''
	}
}

export const capitalizeTheFirstLetterOfEachWord = string => {
	var seperatedString = string.toLowerCase().split(' ');
	for (var i = 0; i < seperatedString.length; i++) {
	   seperatedString[i] = seperatedString[i].charAt(0).toUpperCase() +
	   seperatedString[i].substring(1);
	}
	return seperatedString.join(' ');
}

export const convertToLowerCase = string => {
	if(Array.isArray(string)) {
		if(string.length > 0) {
			string = string[0]
		} else {
			string = ""
		}
	}
	if(string) {
		return string.toLowerCase()
	} else {
		return string
	}
}

export const capitalizeAllLetter = string => {
	if(string !== undefined && string !== '') {
		return string.toUpperCase()
	} else {
		return ''
	}
}

export const removeSpacebtwString = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/ +/g, "");
	} else {
		return ''
	}
}

// let text = name.replace(/[${}]/g,'')

export const onlyNumericSemiColon = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9:]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumeric = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumber = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9.]/gi,'');
	} else {
		return ''
	}
}

export const restrictCharacter = (string, restrictCharacter) => {
	if(string !== undefined && string !== '') {
		return string.replace(/[/]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumericWithNegative = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9-]/gi,'');
	} else {
		return ''
	}
}

export const removeUnderScore = string => {
	if(string !== undefined && string !== '') {
		string = string.replace(/[_]/gi,' ');

		let split = string.split(' ')
		let camelCaseString = ''
		split.forEach((item, i) => {
			camelCaseString += i === 0 ? ''+ item.charAt(0).toUpperCase() + item.slice(1) : ' '+ item.charAt(0).toUpperCase() + item.slice(1) 
		})

		return camelCaseString
	} else {
		return ''
	}
}

export const splitString = (string, splitter) => {
	if(string !== undefined && string !== '') {
		let split = string.split(splitter)
		return split
	} else {
		return ''
	}
}

export const alphaNumeric = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9]/gi,'');
	} else {
		return ''
	}
}

export const validateArn = string => {
    if (string !== undefined && string !== '') {
        return string.replace(/[^a-zA-Z0-9:]/g, '');
    } else {
        return '';
    }
};

export const validateIam = string => {
    if (string !== undefined && string !== '') {
        return string.replace(/[^a-zA-Z0-9:/]/g, '');
    } else {
        return '';
    }
};


export const validateAmiId = string => {
    if (string !== undefined && string !== '' && string.length <= 17) {
        return string.replace(/[^a-z0-9-]/gi, '');
    } else {
        return '';
    }
};

export const isNumber = string =>  { 
	return /^-?[\d.]+(?:e-?\d+)?$/.test(string); 
}

export const validateClusterName = string => {
    if (string !== undefined && string !== '' && string.length <= 40) {
		return string.replace(/[^a-zA-Z0-9-]/gi,'');
    } else {
        return '';
    }
};

export const getDayFromSelectedDuration = string => {
	if(string !== undefined && string !== '' ) {
		return string.replace(/[+dh]/g,'')
	} else {
		return ''
	}
}

export const currentUTCtime = () => {
	let currentTIme = moment()
	return moment.utc(currentTIme).format('YYYY-MM-DD HH:mm:ss')
}

export const currentLocaltime = () => {
	let currentTIme = moment()
	return moment(currentTIme).format('YYYY-MM-DD HH:mm:ss')
	// return moment.utc(currentTIme)local().format('YYYY-MM-DD HH:mm:ss')
}

export const previousDay = () => {
	return true
	// const today = moment();
	// const disableFutureDt = current => {
	//   return current.isBefore(today)
	// }
}

export const momentDateTime = dateTime => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(dateTime !== undefined && dateTime !== '' ) {
		var stillUtc = moment.utc(dateTime).toDate();
		return moment(stillUtc).local().format('DD MMM YYYY HH:mm')
	} else {
		return ''
	}
}

export const momentDateTimeSec = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentDateFormat = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		return moment(date).local().format(format)
	} else {
		return ''
	}
}

export const momentDateGivenFormat = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		// var stillUtc = moment.utc(date).toDate();
		// return moment(stillUtc).local().format(format)

		return moment(date).format(format)
	} else {
		return ''
	}
}

export const momentConvertionUtcToLocalTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentSlashConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		let parsedDate = moment(date, 'DD/MM/YYYY HH:mm');
		// var stillUtc = moment.local(date).toDate();
		return parsedDate.utc().format(format)
	} else {
		return ''
	}
}

export const momentConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		// var stillUtc = moment.local(date).toDate();
		return moment(date).utc().format(format)
	} else {
		return ''
	}
}

export const momentTimeConvertionUtcToLocalTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date,'HH:mm').toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentTimeConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		return moment(date,'HH:mm').utc().format(format)
	} else {
		return ''
	}
}

export const momentTimeGivenFormat = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		return moment(date,'HH:mm').format(format)
	} else {
		return ''
	}
}

export const momentDate = (date, format) => {
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentTime = dateTime => {
	if(dateTime !== undefined && dateTime !== '' ) {
		var stillUtc = moment.utc(dateTime).toDate();
		return moment(stillUtc).local().format('HH:mm')
	} else {
		return ''
	}
}

const calculateHour = (n, unit) => { //n in milliseconds
	if(unit !== "seconds") {
		n = n / 1000
	}
	// let month = n / (30 * 24 *)
	let day = n / (24 * 3600);
	n = n % (24 * 3600);
	let hour = n / 3600;
	n %= 3600;
	let minutes = n / 60;
	n %= 60;
	let seconds = n;
	// let data = parseInt(day) + " days " + parseInt(hour)+ " hours " + parseInt(minutes) +" minutes " + seconds + " seconds ";
	// let data = parseInt(day) + " " + "days " + parseInt(hour)
	// + " " + "hours " + parseInt(minutes) + " "
	// + "minutes " + seconds + " "
	// + "seconds ";

	return [parseInt(day), parseInt(hour), parseInt(minutes), parseInt(seconds)]
}

export const twoDateDiffrence = (start_time, end_time) => {
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let diffrence = ''
		let a = moment([ moment(start_time).format('M/D/YYYY') ], 'M/D/YYYY')
		let b = moment([ moment(end_time).format('M/D/YYYY') ], 'M/D/YYYY')
		diffrence = a.diff(b, 'days');

		return diffrence;
	} else {
		return ''
	}
}

export const currentStartofYear = () => {
	let data = moment().startOf('year').format('MM/DD/YYYY');
	return moment(data).local().format('YYYY-MM-DD')
	// return new Date(data).getTime()
}

export const addMonths = (date, days) => {
	let data = moment(date).add(days, 'months')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addDays = (date, days) => {
	let data = moment(date).add(days, 'days')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}
    
export const subDays = (date, days) => {
	var result = moment(date).subtract(days, 'days')
	return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
}

export const addHours = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}   

export const subHours = (date, hours) => {
	var result = moment(date).subtract(hours, 'hour')
	return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
}

export const addMinutes = (date, minutes) => {
	let data = moment(date).add(minutes, 'minutes')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const subMinutes = (date, minutes) => {
	let data = moment(date).subtract(minutes, 'minutes')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addSeconds = (date, seconds) => {
	let data = moment(date).add(seconds, 'seconds')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addHourGetDate = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addHourGetTime = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return new Date(data).getTime()
}

    
export const getTimeLocal = (time) => {
	time = new Date(time).getTime()
	return time
}

export const stringLength = (string, length) => {
	if(string.length > length) {
		return string.substr(0, length) + '...'
	} else {
		return string
	}
}

/**
 * To validate password is valid or not
 * @param {String} password
 */
export const validatePassword = password => {
	if(password !== undefined && password !== '') {
		return password.length >= 8
	} else {
		return false;
	}
}

/**
 * Logout user
 */
export const logout = () => {	
	window.location.href = '/signin'
}
/**
 * Method to get account name from id
 */
export const getAccountNameFromId = (accountId, accountList) => {
	if(accountId !== undefined && accountList && accountList.length) {
		return accountList.filter(account => account.account_id === accountId).length
			? accountList.filter(account => account.account_id === accountId)[0].account_name
			: accountId
	} else {
		return  accountId
	}
}

export const getRegionName = (region, regionLIst) => {
	if(region !== undefined && regionLIst && regionLIst.length) {
		return regionLIst.filter(reg => reg.region === region).length
			? regionLIst.filter(reg => reg.region === region)[0].name
			: region
	} else {
		return region
	}
}
/**
 * Generate uuid
 */
export const generateUuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

/**
 * shorten and split the user name
 */

export const splitterFirstPart = (name, spliter) => {
	if (name && name.length) {
		let split = name.split(spliter)
		return split[0];
	} else {
		return '-'
	}
}

export const trimmedSplitUserName1 = name => {
	if (name && name.length) {
		let test = name.split(':')[name.split(':').length - 1]
		return test.split('/')[0]
	} else {
		return '-'
	}
}

export const splitterSecondPart = (name, spliter) => {
	if (name && name.length) {
		let split = name.split(spliter)
		return ': '+split.slice(1).join(':')
	} else {
		return '-'
	}
}

export const trimmedSplitUserName2 = name => {
	if (name && name.length) {
		let test = name.split(':')[name.split(':').length - 1]
		let slash = test.split('/')
		return slash.slice(1).join('/');
	} else {
		return '-'
	}
}

export const convertToInt = string => {
	if (string !== '' && string !== undefined) {
		let split = string.split(',')
		let newString = ''
		split.forEach((item, i) => {
			newString += i === 0 ? ''+ parseInt(item) : ', ' + parseInt(item)
		})
		return newString+ ' bit'
	} else {
		return '-'
	}
}

export const MathRound = string => {
	if (string !== '' && string !== undefined) {
		return Math.round(string)
	} else {
		return '-'
	}
}

/**
 * Change convert seconds format
 * @param {String} string
 */
export const unitTimeConvertion = (string, unit) => {
	if(string !== undefined && string !== '' && unit !== undefined && unit !== '') {
		if(unit.toLowerCase() === 'seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + ' ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + ' ms'
					} else {
						return string.toFixed(2) + ' ms'
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return Math.round(string / 60) + ' m'
			} else if(string >= 3600) { //&& string < 86400
				return Math.round((string / 60)/60) + ' h'
			} else if(string >= 86400) {
				return Math.round(((string / 60)/60)/24) + ' d'
			} else {
				return string +'s'
			}
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		}
	}
}

export const unitTimeConvertionDetail = (string, unit) => {
	if(string !== undefined && string !== '' && unit !== undefined && unit !== '') {
		if(unit.toLowerCase() === 'seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + ' ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + ' ms'
					} else {
						return string.toFixed(2) + ' ms'
					}
				}
			}
			let hourCalculation = calculateHour(string, "seconds")
			
			let totalHours = 0
			if(hourCalculation[0] > 0) {
				totalHours = (24 * hourCalculation[0])
			}

			let hour = ""
			if(hourCalculation[1] > 0) {
				totalHours += hourCalculation[1]
				hour += totalHours +" h"
			}
			
			let minutes = ""
			if(hourCalculation[2] > 0) {
				minutes += hourCalculation[2] +" m "
			}

			let seconds = ""
			if(hourCalculation[3] > 0) {
				seconds += hourCalculation[3] +" s"
			}

			let returnData = ""
			if(hour !== "") {
				returnData += hour+" "		
			}
			if(minutes !== "") {
				returnData += minutes+" "
			}
			if(hour === "" && seconds !== "") {
				returnData += seconds
			}
			return returnData
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		}
	}
}



/**
 * shorten the user name
 */
export const trimmedUserName = name => {
	if (name && name.length) {
		return name.split(':')[name.split(':').length - 1]
	} else {
		return '-'
	}
}

// export const redirectLogOut = (error) => {	
// 	if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
// 		// await.inital
// 		instance.loginRedirect()
// 	}
// }
/**
 * logout user when user is not active for 20 mins
 */
export const scheduleTokenRenewal = () => {
	sessionTime = 0
	userSessionTimer = setInterval(() => {
		sessionTime++
		if (sessionTime > 2400) {
			logout()
		}
	}, 1000)
}

export const debounce = (fn, time) => {
	let timer;
  
	return function exec(...args) {
	  const later = () => {
		clearTimeout(timer);
		fn(...args);
	  };
	  timer && clearTimeout(timer);
	  timer = setTimeout(later, time);
	};
}

export const isValidDateTime = (dateString) => {
	// Define a regular expression to match the expected date and time format
	const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  
	// Check if the string matches the regular expression
	if (!dateRegex.test(dateString)) {
	  return false;
	}
  
	// Try creating a Date object from the string
	const date = new Date(dateString);
  
	// Check if the Date object represents a valid date and time
	return !isNaN(date.getTime());
  }
  

export const getStringBetween = (str, start, end) => {
	//exmaple str = "text {for the work} start = "{"  end = "}"
    let result = str.match(new RegExp(start + "(.*)" + end));

    return result;
}

export const convertTextToJson = (text) => {
	const lines = text.split('\n');
  
	const parseLine = (line) => {
	  const [key, value] = line.split(':');
	  return [key.trim(), value.trim()];
	};
  
	const buildObject = (lines) => {
	  const stack = [];
	  const json = {};
  
	  let currentObj = json;
  
	  for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		const indent = line.search(/\S/);
  
		if (indent === -1) {
		  continue;
		}
  
		const [key, value] = parseLine(line);
  
		if (indent === 0) {
		  currentObj[key] = value;
		} else {
		  while (indent < stack.length) {
			stack.pop();
		  }
  
		  const parentKey = stack[stack.length - 1];
		  currentObj = currentObj[parentKey];
  
		  currentObj[key] = {};
		  currentObj = currentObj[key];
  
		  stack.push(key);
		}
	  }
  
	  return json;
	};
  
	const json = buildObject(lines);
  
	return JSON.stringify(json, null, 2);
};

export const formatNumber = (number) => {
	if (Number.isInteger(number)) {
	  	return number; // Return integer as is
	} else {
	  	return number.toFixed(2); // Return with 2 decimal places
	}
}

export const noSpace = (text) => {
	return text.replace(/\s/g, '')
}

export const timeInput = (value) => {
	// Remove non-digit characters
	const cleaned = value.replace(/\D/g, '');

	// Format to DD/MM/YYYY
	const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

	if (match) {
		const formattedDate = [match[1], match[2]]
			.filter(Boolean) // Remove empty strings
			.join(':'); // Join parts with "/"
		return formattedDate;
	}

	return value;
}

export const getPaginationShowingDetails = (filteredList, totalList, currentPage, perPage) => {
	if(filteredList) {
		if(parseInt(currentPage * perPage) <= totalList) {
			return '('+parseInt(((currentPage-1) * perPage)+1) +' - '+parseInt(currentPage * perPage)+')'
		} else if(parseInt(currentPage * perPage) > totalList) {
			return '('+parseInt(((currentPage-1) * perPage)+1) +' - '+totalList+')'
		} else {
			return 0
		}
	}
	return 0
}

export const getCommonInformationMessage = (message) => {
	return  <span className='text-base'>No {message} information available for the selected period.</span>
}

export const getCommonInformationMessageFilter = (message) => {
	return  <span className='text-base'>No {message} information available for the selected filter.</span>
}

export const getCommonInformation = (message) => {
	return  <span className='text-base'>No {message} information available.</span>
}

export const validateJsonFields = (type, label, fields, selectedDetails) => {
	let invalidFields = [];
	fields && fields.forEach(fld => {
		if(selectedDetails[fld]) {
			invalidFields.push(selectedDetails[fld])
		}
	})
	return invalidFields.length ? true : false
}

export const toggleScroll = (shouldDisable) => {
	if (shouldDisable) {
		document.body.classList.add("overflow-hidden")
	} else {
		document.body.classList.remove("overflow-hidden")
	}
};

export const defaultDateRangeValue = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    let startMonth = currentMonth;
    let startYear = currentYear;
    if (startMonth < 0) {
      startMonth += 12;
      startYear--;
    }
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    endDate.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    const startDate = new Date(startYear, startMonth, 1);
    return [startDate, endDate];
};

export const yearOptions = () => {
	const currentYear = new Date().getUTCFullYear();
	// Calculate the next 10 years
	return Array.from({ length: 11 }, (_, index) => currentYear + index);
}

// const sessionTimeRenewal = () => {
// 	sessionTime = 0
// }

// const keyDownTextField = () => {
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

// const keyDownEvent = () => {
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

// const mouseMoveEvent = () => {	
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

const offLineEvent = () => {
	console.log("check your internet")

	// let active_page = window.location.href
	// localStorage.setItem('active_page', active_page);
	
	// window.location.href = '/internet'
}

const onLineEvent = () => {
	// window.location.reload()
	// if(!localStorage.getItem('active_page')) {
	// } else {
	// 	window.location.href = localStorage.getItem('active_page')
	// }
}

// Listen to user keypress and reset timerconvertSeconds
// document.addEventListener('keypress', keyDownTextField, false)

// Listen to user keypress and reset timer
// document.addEventListener('keydown', keyDownEvent, false)

// Listen to user keypress and reset timer
// document.addEventListener('mousemove', mouseMoveEvent, false)

window.addEventListener('offline', offLineEvent, false)

window.addEventListener('online', onLineEvent, false)


