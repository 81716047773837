/*************************************************
 * Collider
 * @exports
 * @file CreateMappingLeft.js
 * @author Prakash // on 19/03/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTagsKeys, getAllTagsValues } from '../../../actions/commonActionNew'
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import {Icon} from "@iconify/react";
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';
import AlertConfirmation from '../../common/AlertConfirmation';

const CreateMappingLeft = (props) => {
	const[state, setState] = useState({
		initArray: [0],
        selectedInitSection: 'initiativeSection_0'
	})

	const dispatch = useDispatch(); // Create a dispatch function
	
    const createMappingInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createMappingInputs || false);
    const onClickMappingSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickMappingSave || false);    
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);

    const passInitProps = useCallback((init, value) => {
        let obj = {}
        if(value) {
            obj.initiative = value
            obj.init = init
        }
        dispatch(setHpcPropsDetails('selectedMappingInitiative', obj))
    }, [dispatch])

    const expandInitiative = useCallback((selectedInitSection, selectedInitiative) => {
        if(selectedInitSection === state.selectedInitSection) {
            selectedInitiative = ''
            selectedInitSection = ''
        } else {
            if(state['initiative_'+selectedInitiative]) {
                passInitProps(selectedInitiative, state['initiative_'+selectedInitiative])
            }
        }
        setState(prevState => ({ ...prevState, selectedInitSection, selectedInitiative }))        
    }, [passInitProps, state])

    // useEffect(() => {
    //     let obj = {}
    //     obj.selectedInitSection = 'initiativeSection_0'
    //     obj.selectedInitiative = 0
    //     dispatch(setHpcPropsDetails('selectedMappingInitiative', obj))

    //     setState(prevState => ({ ...prevState, selectedInitSection: 'initiativeSection_0', selectedInitiative: 0 }))
    // }, [dispatch])

    useEffect(() => {
        if(!tagKeys || !tagKeys.length) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }, [tagKeys, dispatch])

	const getAllTagFunction = (field, selectedKey, init) => {
		if(selectedKey) {
			let params = {}
			params.tags = [{ "key": selectedKey }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
                        if(init >= 0 && state['selectedTags_'+init] && state['selectedTags_'+init].length) {
                            let value = state['selectedTags_'+init].filter(e => e.key === selectedKey).map(e => e.value)
                            setState(prevState => ({ ...prevState, ['tagValue_'+init]: value }))
                        } else if(state.selectedTags && state.selectedTags.length) {
                            let value = state.selectedTags.filter(e => e.key === selectedKey).map(e => e.value)
                            setState(prevState => ({ ...prevState, tagValue: value }))
                        }
						setState(prevState => ({ ...prevState, [field]: response }))
					}
				})
		}
	}

	useEffect(() => {
		if(props.receivedData && Object.entries(props.receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: props.receivedData.selectedData ? props.receivedData.selectedData : '',
				pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
                formatEditStructure: true
			}))

			dispatch(setHpcPropsDetails('onClickMappingSave', props.receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [props.receivedData, dispatch])

    useEffect(() => {
        if(state.formatEditStructure) {
            setState(prevState => ({ ...prevState, formatEditStructure: false }))
            let selectedData = state.selectedData
            let department = selectedData.department ? selectedData.department : ''
            let tags = selectedData.tags ? selectedData.tags : ''

            let initArray = []
            if(selectedData.initiatives && selectedData.initiatives.length) {
                selectedData.initiatives.forEach((init, iIndex) => {
                    initArray.push(iIndex)
                    setState(prevState => ({ ...prevState, ['initiative_'+iIndex]: init.initiative ? init.initiative : '', ['selectedTags_'+iIndex]: init.tags ? init.tags : [] }))

                    if(!iIndex) {
                        passInitProps(iIndex, init.initiative)
                    }
                })
            } else {
                initArray = [0]   
            }
            
            setState(prevState => ({ ...prevState, department, selectedTags: tags, initArray }))
        }
    }, [state.formatEditStructure, state.selectedData, passInitProps])

    const handleInputChange = (label, value) => {
		let obj = createMappingInputs ? createMappingInputs : {}
        obj[label] = value
        if(label !== 'tags') {
            setState(prevState => ({ ...prevState, [label]: value}))
        }
        dispatch(setHpcPropsDetails('createMappingInputs', obj))
    }
    const handleInitativeInputChange = (label, value, init) => {
        if(label === 'initiative') {
            setState(prevState => ({ ...prevState, [label+'_'+init]: value}))            
        }
		let obj = createMappingInputs ? createMappingInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        if(initiatives && initiatives.length && initiatives.filter(e => e.initiative === state['initiative_'+init]).length) {
            let selectedInitiativeData = initiatives.filter(e => e.initiative === state['initiative_'+init]).length ? initiatives.filter(e => e.initiative === state['initiative_'+init])[0] : {}
            selectedInitiativeData.initiative = (label === 'initiative' ? value : state['initiative_'+init])
            selectedInitiativeData.tags = (label === 'tags' ? value : state['selectedTags_'+init])
            // selectedInitiativeData.projects = selectedInitiativeData.
            // initiatives.push(selectedInitiativeData)
        } else {
            let dataRow = {}
            dataRow.initiative = (label === 'initiative' ? value : state['initiative_'+init])
            dataRow.tags = (label === 'tags' ? value : state['selectedTags_'+init])
            initiatives.push(dataRow)
            obj.initiatives = initiatives
        }
        if(label === 'initiative') {
            passInitProps(init, value)
        }
        dispatch(setHpcPropsDetails('createMappingInputs', obj))
    }

	const addDepartmentTags = () => {
		let selectedTags = state.selectedTags ? state.selectedTags : []

		if(state.tagKey && state.tagKey !== "") {
			let filteredTags = selectedTags.filter(e => e.key !== state.tagKey)
			state.tagValue.forEach(row => {
				let dataRow = {
					key: state.tagKey,
					value: row,
				}
				filteredTags.push(dataRow)
			})

            handleInputChange('tags', filteredTags)
			setState(prevState => ({ ...prevState, selectedTags: filteredTags, tagKey: '', tagValue: [], tagValueOptions: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}

    const addInitiativeTags = (init) => {
		let selectedTags = state['selectedTags_'+init] ? state['selectedTags_'+init] : []

		if(state['tagKey_'+init] && state['tagKey_'+init] !== "") {
			let filteredTags = selectedTags.filter(e => e.key !== state['tagKey_'+init])
			state['tagValue_'+init].forEach(row => {
				let dataRow = {
					key: state['tagKey_'+init],
					value: row,
				}
				filteredTags.push(dataRow)
			})
            handleInitativeInputChange('tags', filteredTags, init)
			setState(prevState => ({ ...prevState, ['selectedTags_'+init]: filteredTags, ['tagKey_'+init]: '', ['tagValue_'+init]: [], ['tagValueOptions_'+init]: [] }))
		}
	}

    const removeInitiativeTags = (tag, init) => {
		let filteredResult = state['selectedTags_'+init].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, ['selectedTags_'+init]: filteredResult }))
	}

    const addFilterArray = (field) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)

            // setState(prevState => ({ ...prevState, selectedInitSection: 'initiativeSection_'+value, selectedInitiative:  }))
            expandInitiative('initiativeSection_'+value, value)
		}

		setState(prevState => ({ ...prevState, [field]: rowList }))

	}

	const removeFilterArray = (field, index) => {
        setState(prevState => ({ ...prevState, ['initiative_'+rowList[index]]: '', ['selectedTags_'+rowList[index]]: '' }))
		let rowList = state[field] ? state[field] : [0]
        if(field === 'initArray') {
            setState(prevState => ({ ...prevState, ['proj_'+rowList[index]]: []}))
        }
		rowList.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: rowList }))

        let obj = createMappingInputs ? createMappingInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        obj.initiatives = initiatives.filter(e => e.initiative !== state['initiative_'+index])

        dispatch(setHpcPropsDetails('createMappingInputs', obj))

        dispatch(setHpcPropsDetails('selectedMappingInitiative', false))

    }

    useEffect(() => {
        if(onClickMappingSave) {
            if(onClickMappingSave !== 'save' && state.hasError) {
                dispatch(setHpcPropsDetails('onClickMappingSave', 'stopLoading'))
            } else if(onClickMappingSave === 'save') {
                // validateFunction()       
            }
        }
    }, [onClickMappingSave, state.hasError, dispatch])

    const confirmActionDetails = (field, index, initiativeName) => {
        let obj = {
            field: field,
            index: index,
            message: 'Are you sure you want to remove the selected initiative' + (initiativeName ? ' "'+initiativeName+'"' : ''),
            heading: 'Confirm Removal'
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, confirmAction: true }))
    }

	const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []            
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
        <div className='flex flex-col text-black py-5 px-8 bg-white border border-DeepPurpleTints-600 rounded-2xl w-full overflow-y-auto h-[calc(100vh-200px)]' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='px-6 pt-6 pb-8 border border-PrimaryTintsPurpleTints-80 bg-white rounded-2xl'>
            <p className='text-lg font-bold text-NeutralGray-800 text-[22px] uppercase'>Basic Info</p>
                <div className='flex flex-wrap pt-6'>
                    <div className='lg:pr-4 w-1/2'>
                        <Textbox
                            label={"Department name"}
                            type="text"
                            selectedValue={state.department ? state.department : ''}
                            callback={(value) => {
                                handleInputChange('department', value)
                            }}
                            placeholder={'Enter department name'}
                            manditory={true}
                            hasError={state.hasError}
                        />
                    </div>
                    {/* <div className='flex flex-col justify-start w-1/2 pl-4'>
                        <div className='w-full'>
                            <SelectOption
                                label={"Year"}
                                // options={state.region}
                                // selectedValues={state.applicationId ? state.applicationId : ''}
                                // callbackMultiSelect={(value) => {
                                //     setState(prevState => ({ ...prevState, applicationId: value }))
                                // }}
                                singleSelection={true}
                                manditory={true}
                                hasError={state.hasError}
                                dropdownWidth={'w-full'}
                                // disabled={pageMode === 'Edit'}
                            />
                        </div>
                    </div> */}
                </div>
                <div className='flex flex-wrap pt-8 justify-between'>
                    <div className='flex justify-start w-2/5 pr-4'>
                        <SelectOption
                            label={"Tags"}
                            options={tagKeys}
                            selectedValues={state.tagKey ? state.tagKey : ''}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, tagKey: value,  tagValueOptions: [], tagValue: []}))
                                getAllTagFunction('tagValueOptions', value)
                            }}
                            singleSelection={true}
                            dropdownWidth={'w-full'}
                        />
                    </div>
                    <div className='flex justify-start w-2/5 pl-4'>
                        <SelectOption
                            options={state.tagValueOptions}
                            selectedValues={state.tagValue ? state.tagValue : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, tagValue: value }))
                            }}
                            singleSelection={false}
                            dropdownWidth={'w-full'}
                        />
                    </div>
                    <div className='flex justify-end pt-8 pl-4'>
                        <Button
                            classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                            label={'+'}
                            callback={() => {
                                addDepartmentTags()
                            }}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap mt-2'>
                    {state.selectedTags && state.selectedTags.length ? 
                        state.selectedTags.map((tag, index) => {
                            return(
                                <span key={'dep_tag_'+index} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{tag.key +" : "+tag.value}
                                    <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                </span>
                            )
                        })
                    : null}
                </div>
            </div>

            <div className='mt-6 px-6 py-5 border border-NeutralGray-600 bg-white rounded-2xl'>
                <p className='text-lg font-bold text-NeutralGray-800 text-[22px] uppercase'>Initiatives</p>
                {state.initArray ?
                    state.initArray.map((init, initIndex) => {
                        return(
                            <React.Fragment>
                            <div className='mt-8 p-3 border border-byzantineBlue bg-NeutralGray-200 rounded-2xl' key={'init_'+initIndex}>
                                <div className={`mb-0 cursor-pointer`} 
                                    onClick={() => expandInitiative('initiativeSection_'+init, init)}
                                >
                                    <div className='flex justify-between'>
                                        <div className='flex justify-start self-center'>
                                        {state.selectedInitSection === 'initiativeSection_'+init ? 
                                            <Icon icon="mdi-light:minus" className={`ml-2 text-black self-center cursor-pointer`} width="20" height="20" />
                                        : 
                                            <Icon icon="iconoir:plus" width="20" height="20"  className='ml-2 text-black self-center cursor-pointer' />
                                        }
                                        <span className='ml-4 text-lg font-bold'>{state['initiative_'+init]}</span>
                                        </div>
                                        {state.pageType !== 'View' && state.initArray.length > 1 ?
                                            <span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => {
                                                // removeFilterArray('initArray', initIndex)
                                                confirmActionDetails('initArray', initIndex, state['initiative_'+init])
                                            }} >Remove
                                            </span>
                                        : null}
                                    </div>
                                </div>
                            {state.selectedInitSection === 'initiativeSection_'+init ?
                            <div className='p-3'>
                                <div className='flex flex-wrap pt-4'>
                                    <div className='lg:pr-4 w-1/2'>
                                        <Textbox
                                            label={"Initaitve name"}
                                            type="text"
                                            selectedValue={state['initiative_'+init] ? state['initiative_'+init] : ''}
                                            callback={(value) => {
                                                handleInitativeInputChange('initiative', value, init)
                                            }}
                                            placeholder={'Enter initaitve name'}
                                            manditory={true}
                                            hasError={state.hasError}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-wrap pt-4 justify-between'>
                                    <div className='flex justify-start w-2/5 pr-4'>
                                        <SelectOption
                                            label={"Tags"}
                                            options={tagKeys}
                                            selectedValues={state["tagKey_"+init] ? state["tagKey_"+init] : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, ["tagKey_"+init]: value,  ["tagValueOptions_"+init]: [], ["tagValue_"+init]: []}))
                                                getAllTagFunction("tagValues_"+init, value, init)
                                            }}
                                            singleSelection={true}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                    <div className='flex justify-start w-2/5 pl-4'>
                                        <SelectOption
                                            options={state['tagValues_'+init]}
                                            selectedValues={state["tagValue_"+init] ? state["tagValue_"+init] : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, ["tagValue_"+init]: value }))
                                            }}
                                            singleSelection={false}
                                            dropdownWidth={'w-full'}
                                        />
                                    </div>
                                    <div className='flex justify-end pt-4 pl-4'>
                                        <Button
                                            classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                            label={'+'}
                                            callback={() => {
                                                addInitiativeTags(init)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-wrap mt-2'>
                                {state["selectedTags_"+init] && state["selectedTags_"+init].length ? 
                                    state["selectedTags_"+init].map((tag, index) => {
                                        return(
                                            <span key={'tag_'+init+'_'+index}  className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{tag.key +" : "+tag.value}
                                                    <Icon icon="jam:close" width="20" height="20"  className='text-black ml-2 self-center' onClick={() => removeInitiativeTags(tag, init)}/>
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            : null}

                            </div>
                            <div className='flex justify-center mt-6 mb-4'>
                            {state.pageType !== 'View' && state.initArray.length === initIndex+1 && state['initiative_'+init] ?
                                <span className={`text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => addFilterArray('initArray')} >
                                    {/* <i className={`far fa-plus f16 mt-2 mb-1 self-end mr-2`}></i> */} 
                                    + Add Initaitve
                                </span>
                            : null}
                            </div>
                            </React.Fragment>
                        )
                    })
                : null}
                {state.confirmAction && state.confirmDetails ?
                    <AlertConfirmation
                        confirmDetails={state.confirmDetails}
                        confirmation={(action) => {
                            if(action === 'confirmed') {
                                removeFilterArray(state.confirmDetails.field, state.confirmDetails.index)
                            }
                            setState(prevState => ({ ...prevState, confirmAction: false, confirmDetails: false }))
                        }}
                    />
                : null}
            </div>
        </div>
	)
}

export default CreateMappingLeft