/*
 * @exports
 * @file ListRules.js
 * @author Prakash
*/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders } from '../../../actions/commonActionNew';
import { deleteAlertRule, listAlertRules, updateAlertRule } from '../../../actions/Collider/AlertAction';
import { Icon } from '@iconify/react';
import { Store as CommonNotification } from 'react-notifications-component';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Search from '../../common/SearchWithHiddenInput';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';
import { dynamicCallApi } from '../../../actions/DynamicLoadAction';
import AlertConfirmation from '../../common/AlertConfirmation';

const ListRules = (props) => {
	const[state, setState] = useState({
		selectedCategory: "All",
		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		perPage: 10,
		showLoading: true,
		callSearch: true
	})
	
	const providers = useSelector(state => state.filters.providers);
	// const hpcAlertPageFilters = useSelector(state => state?.hpc?.hpcPropsDetails?.hpcAlertPageFilters || false);
	// const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);
	const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
            	})
        }
    }, [dispatch, providers]);

	useEffect(() => {
		if(state.showLoading){
			setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
		}
	}, [state.showLoading])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listAlertRules(params))
				.then((response) => {
					let results =  response && response.results ? response.results : []
					setState(prevState => ({ ...prevState, listResponseArray: results, filteredListArray: results, structureResponse: true, categories: [], showLoading: false }))
				})
		}
    }, [state.callSearch, dispatch])

    const dynamicCallApiFunction = useCallback((params, item, apiDetails, outputField, outputType) => {
        dispatch(dynamicCallApi('', apiDetails.api_resource, params))
            .then((response) => {
                if(response) {
                    let options = []
                    // if(outputType === "list") {
                    //     let dataRow = {}
                    //     dataRow.label = "All"
                    //     dataRow.value = "All"
                    //     options.push(dataRow)
                    // }
                    response.forEach(res => {
                        if(apiDetails.field_name === "id_name_map") {
                            let dataRow = {}
                            dataRow.label = res[apiDetails.display_name]
                            dataRow.value = res[apiDetails.field_name]
                            // options.push(res[apiDetails.field_name])
                            options.push(dataRow)
                        } else if(apiDetails.field_name){
                            let dataRow = {}
                            dataRow.label = res[apiDetails.display_name]
                            dataRow.value = res[apiDetails.field_name]
                            options.push(dataRow)
                        } else {
                            let dataRow = {}
                            dataRow.label = res
                            dataRow.value = res
                            options.push(dataRow)
                        }
                    })
                    // console.log(outputField+'_options_'+item.alert_id, options)
                    setState(prevState => ({ ...prevState, [outputField+'_options_'+item.alert_id]: options }))
                }
            })  
    }, [dispatch])

	useEffect(() => {
		if(state.structureResponse) {
			setState(prevState => ({ ...prevState, structureResponse: false }))
			let categories = []
			let totalCatCount = 0
			state.listResponseArray.forEach(item => {
				if(!categories.filter(e => e.category === item.category).length) {
					let count = state.listResponseArray.filter(e => e.category === item.category).length
					totalCatCount += count 
					categories.push({
						category: item.category,
						count: count
					})
				}

				if(item.display_output && item.display_output.length) {
                    item.display_output.forEach(dis => {
                        setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.alert_id]: dis.value }))
					})
				}
			})
			
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			categories.unshift(dataRow)

			setState(prevState => ({ ...prevState, categories }))
		}
	}, [state.listResponseArray, state.structureResponse])

    useEffect(() => {
        if(state.selectedAlert) {
            if(Object.entries(state.selectedAlert)) {
                let params = {}
                if(state.selectedAlert.display_output && state.selectedAlert.display_output.length) {
                    state.selectedAlert.display_output.forEach(dis => {
                        let apiDetails = dis?.api_details || false
                        if(apiDetails?.api_request) {
                            params = apiDetails.api_request
                        }

                        if(apiDetails){
                            dynamicCallApiFunction(params, state.selectedAlert, apiDetails, dis.output_field, dis.output_type)
                        }
                    })
                }
            }
        }
    }, [state.selectedAlert, dynamicCallApiFunction])
	
	useEffect(() => {
		if(state.filterCategory) {
			setState(prevState => ({ ...prevState, filterCategory: false }))
			let data = state.listResponseArray
			if(state.selectedCategory !== "All") {
				data = data.filter(e => e.category === state.selectedCategory)
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredListArray: data, totalPages, startRecord: 0, currentPage: 1 }))
		}
	}, [state.filterCategory, state.listResponseArray, state.selectedCategory, state.perPage])

	// Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

	useEffect(() => {
		if(state.onSaveFunction) {
			setState(prevState => ({ ...prevState, onSaveFunction: false }))

			let item = state.selectedAlert
			let hasError = false
			if(item.display_output && item.display_output.length) {
				item.display_output.forEach(dis => {
					if(dis?.validation?.mandatory && !state[dis.output_field+'_'+item.alert_id]) {
						hasError = true
					} else {
						let value = state[dis.output_field+'_'+item.alert_id];
						if(dis?.validation?.data_type === 'number') {
							value = parseInt(value)
						}

						dis.value = value
                        item[dis.output_field] = value

					}
				})
			}

			setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true  }))

			if(!hasError) {
				dispatch((updateAlertRule(item)))
					.then((response) => {
						if(response) {
							let messageType = 'danger'
							let message = response && response.message ? response.message : 'Error in alert rule'
							if(response && !response.error) {
								if(response.status) {
									messageType = 'success'
									message = response.message ? response.message : 'alert rule submitted successfully'
								}
							}
			
							CommonNotification.addNotification({
								//title: 'Wonderful!',
								message: message,
								type: messageType,
								insert: 'top',
								container: 'top-center',
								// animationIn: ['animate__animated', 'animate__fadeIn'],
								// animationOut: ['animate__animated', 'animate__fadeOut'],
								dismiss: {
									duration: 5000,
									onScreen: false,
									pauseOnHover: true,
									showIcon: true,
								}
							});
			
							if(response && !response.error) {
								if(response.status) {
									// setTimeout(() => {props.closeSidePanel(), 1000)
									setState(prevState => ({ ...prevState, saveLoading: false, callSearch: true }))
								} else {
									setState(prevState => ({ ...prevState, saveLoading: false }))
								}
							} else {
								setState(prevState => ({ ...prevState, saveLoading: false }))
							}
						}
					})
			}

		}

	}, [dispatch, state.listResponseArray, state.onSaveFunction, state])

    const deleteFunction = () => {
        dispatch((deleteAlertRule(state.deleteRuleDetails)))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        // toggle()
                        setTimeout(() => { setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callSearch: true, confirmDetails: false }))}, 1000)
                    }

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                }
            })
    }
    
    const checkForDependentApiCall = (field, item, value) => {
        if(item.display_output && item.display_output.length) {
            item.display_output.forEach(dis => {
                let aditionalFields = dis?.api_details?.additional_fields || false
                if(aditionalFields && aditionalFields.includes(field)) {
                    let params = {}
                    let apiDetails = dis?.api_details || false
                    if(apiDetails?.api_request) {
                        params = apiDetails.api_request
                    }
                    if(apiDetails?.additional_fields) {
                        apiDetails?.additional_fields.forEach(fld => {
                            if(fld === field) {
                                params[fld] = value
                            } else if(state[fld+'_'+item.alert_id]) {
                                params[fld] = state[fld+'_'+item.alert_id]
                            }
                        })
                    }
                    if(apiDetails){
                        dynamicCallApiFunction(params, item, apiDetails, dis.output_field, dis.output_type)
                    }
                }
            })
        }
    }


	return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <LoadingCircle />
            </div>
        :
            <div className='pl-6 pr-3 py-8'>
                {state.listResponseArray && state.listResponseArray.length ?
                    <div className='flex justify-between'>
                        <div className="flex text-sm lg:w-3/5 w-full">
                            <p className="self-end">Showing {state.filteredListArray && state.filteredListArray.length} of total {state.listResponseArray && state.listResponseArray.length} alert rule(s)</p>
                        </div>
                        <div className='w-1/3'>
                            <Search
                                data={state.listResponseArray ? state.listResponseArray : []}
                                topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                className={'rounded-full'}
                                filteredData={(filteredListArray) => {
                                    setState(prevState => ({ ...prevState, filteredListArray, startRecord: 0, currentPage: 1 }))
                                }}
                            />
                        </div>
                    </div>
                : null}
                
                {state.filteredListArray && state.filteredListArray.length > state.perPage ?
                    <div className='text-sm flex justify-end text-black text-sm mt-2'>
                        <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                        <div className="flex text-sm self-center">
                            <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                            <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                        </div>
                    </div>
                : null}
                <div className='flex flex-wrap pt-8'>
                    {state.categories ? 
                        <div className="lg:w-1/5 md:w-1/5 w-full lg:pr-3 md:pr-3 bg-NeutralGray-400 border border-NeutralGray-600 rounded-2xl pt-[22px] px-3 overflow-y-auto h-[calc(100vh-280px)]">
                            {state.categories.map((item, index) => {
                                return(
                                    <div className='pb-4' key={'cat_'+index}>
                                        {item.category === "All" ?
                                            <div className={`flex cursor-pointer text-base font-bold text-lg ${state.selectedCategory === item.category ? 'text-byzantineBlue pointer-events-none' : 'text-black'}`} onClick={() => {
                                                setState(prevState => ({ ...prevState, selectedCategory: "All", fitlerRules: true }))
                                            }}>All Reports</div>
                                        :
                                            <div className={`bg-white rounded-full py-3 px-5 text-base font-medium flex flex-wrap justify-between text-black text-nowrap truncate ${item.category === state.selectedCategory ? 'pointer-events-none border-2 border-byzantineBlue' : 'cursor-pointer'}`} onClick={() => {
                                                if(item.count && item.category !== state.selectedCategory) {
                                                    setState(prevState => ({ ...prevState, selectedCategory: item.category, filterCategory: true }))
                                                }
                                            }}>
                                                <p className={`w-3/5`}>{item.category}</p>
                                                <span className='font-bold'>{item.count !== '' ? item.count : ''}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    : null}
                    <div className={`pl-5 ${state.categories ? 'lg:w-4/5 md:w-4/5' : ''} w-full lg:mt-0 md:mt-0 mt-2 pr-3 overflow-y-auto h-[calc(100vh-280px)]`}>
                        {state.filteredListArray && state.filteredListArray.length ?
                            state.filteredListArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                return(
                                    <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6 mr-1' key={'filArr_'+i}>
                                        <div id={'heading_'+i} className="cursor-pointer w-full"
                                            onClick={() => setState(prevState => ({ ...prevState, hasError: false, selectedAlert: state.selectedAlert !== item ? item : {} }))}
                                        >
                                            <div className="font-semibold text-base flex">
                                                {state.selectedAlert && state.selectedAlert.alert_id === item.alert_id ?
                                                    <Icon icon={`iconoir:minus`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                :
                                                    <Icon icon={`stash:plus-solid`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                }
                                                
                                                {item.title ? item.title : ""}
                                            </div>
                                        </div>
                                        
                                        {state.selectedAlert && state.selectedAlert.alert_id === item.alert_id ?
                                            state.selectedAlert.display_output && state.selectedAlert.display_output.length ?
                                                <React.Fragment>
                                                <div className='flex flex-wrap w-full'>
                                                    {state.selectedAlert.display_output.map((dis, disIndex) => {
                                                        return(
                                                            dis.type === 'text' ?
                                                                <div className='w-1/3 pr-8 mt-4' key={"text_"+i+'_'+disIndex}>
                                                                {/* <p className={`flex text-NeutralGray-800 text-base text-nowrap text-NeutralGray-800 self-end mr-2`}>
                                                                    {dis.label}
                                                                    
                                                                    {dis?.validation?.mandatory?
                                                                        <Icon icon="fe:star" className='text-red-600 ml-1 mb-3 self-center' width={12} height={12} />
                                                                    : null}
                                                                </p> */}
                                                                <Textbox
                                                                    label={dis.label}
                                                                    type={dis.output_type ? dis.output_type : 'text'}
                                                                    selectedValue={state[dis.output_field+'_'+item.alert_id] ? state[dis.output_field+'_'+item.alert_id] : ''}
                                                                    callback={(value) => {
                                                                        setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.alert_id]: value }))
                                                                    }}
                                                                    placeholder={'Enter '+dis.label}
                                                                    manditory={dis?.validation?.mandatory}
                                                                    hasError={state.hasError}
                                                                    // classDetails={{width: 'min-w-32', border: 'border border-NeutralGray-400'}}
                                                                />
                                                                </div>
                                                            : dis.type === 'dropdown' ?
                                                                <div className={`relative self-center w-1/3 pr-8 mt-4`}>
                                                                    <SelectOption
                                                                        label={dis.label}
                                                                        fields={['value', 'label']}
                                                                        options={state[dis.output_field+'_options_'+item.alert_id]}
                                                                        selectedValues={state[dis.output_field+'_'+item.alert_id] ? state[dis.output_field+'_'+item.alert_id] : []}
                                                                        callbackMultiSelect={(value) => {
                                                                            setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.alert_id]: value }))
                                                                            setTimeout(() => {
                                                                                checkForDependentApiCall(dis.output_field, item, value)
                                                                            }, 1000);
                                                                        }}
                                                                        singleSelection={dis.output_type === 'list' ? false : true}
                                                                        optionOverflow={'overflow-hidden'}
                                                                    />
                                                                </div>
                                                            : null
                                                        )
                                                    })}
                                                </div>
                                                <div className={`flex justify-end w-full mt-6 ${state.saveLoading ? "pointer-events-none" : ""}`}>
                                                    <span className={`mb-0 text-white bg-pumpkin-600 flex justify-center px-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3 min-w-28`} 
                                                        onClick={() => {
                                                            setState(prevState => ({ ...prevState, onSaveFunction: true }))
                                                        }}
                                                    >
                                                        <div className="flex">
                                                            {state.saveLoading ?
                                                                <LoadingCircle color={'text-white'}/>
                                                            : null}
                                                            Update Rule
                                                        </div>
                                                    </span>
                                                    
                                                    <span className={`mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base min-w-28 text-center mr-3`} onClick={() => {
                                                        let obj = {
                                                            message: 'Are you sure you want to delete the rule' +(item.title),
                                                            buttonLabel: "Yes, I'm sure",
                                                        }
                                                        setState(prevState => ({ ...prevState, confirmDetails: obj, deleteModal: true, deleteRuleDetails: item }))
                                                    }}>Delete</span>
                                                    <span className={`mb-0 text-white bg-DeepPurpleTints-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base min-w-28 text-center`} onClick={() => setState(prevState => ({ ...prevState, selectedAlert: {} }))}>Close</span>
                                                </div>
                                                </React.Fragment>
                                            : null
                                        : null}
                                    </div>
                                )
                            })
                        : null}
                    </div>
                </div>
                
                {state.confirmDetails && state.deleteModal ?
                    <AlertConfirmation
                        confirmDetails={state.confirmDetails}
                        confirmation={(deleteModal) => {
                            if(deleteModal) {
                                deleteFunction()  
                            } else {
                                setState(prevState => ({ ...prevState, deleteModal: false, confirmDetails: false,deleteAccountDetails: {} }))
                            }
                        }}
                    />
                : null}
            </div>
	)
}

export default ListRules