import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import _ from 'lodash'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Icon} from "@iconify/react";

import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexStackedBarReportChart from '../../common/charts/ApexStackedBarReportChart';

import { listQueues, listUsersByCluster } from '../../../actions/Collider/ClusterAction';
import SelectOption from '../../common/Form/SelectOption';
import { getAccountNameFromId, getRegionName, momentDateGivenFormat, defaultDateRangeValue } from '../../../utils/utility'

import { getHpcReorts } from '../../../actions/Collider/HpcReportAction'
import { LoadingCircle } from '../../common/LoadingCiricle';
import { listClusterFilters } from '../../../actions/commonActionNew';
import SmartDateRangePicker from '../../common/SmartDateRangePicker';
import { CSVLink } from 'react-csv';

const timestamp = new Date().getTime();

const aggregateByOptions = [
    {
        "label": "Project",
        "value": "project"
    },
    {
        "label": "Cluster",
        "value": "cluster_name"
    },
    {
        "label": "Queue",
        "value": "queue_name"
    },
    {
        "label": "User",
        "value": "user_name"
    }
]

const RunReport = ({ selectedProvider, datePickerStartDate, datePickerEndDate , selectedReportInput, selectedReport, closeSidePanel }) => {
    const clickOut = useRef();
    const tableRef = useRef();
    const csvLinkRef = useRef();

    const [state, setState] = useState({
        showLoading: true,
        aggregateByOptions: aggregateByOptions
    })
    
    const [dateRange, setDateRange] = useState(defaultDateRangeValue);
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue);

    const dispatch = useDispatch(); // Create a dispatch function
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel()
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        setState(prevState => ({ 
            ...prevState, 
            selectedProvider: selectedProvider ? selectedProvider.toLowerCase() : '',
            selectedProject: selectedReportInput.selectedProject ? selectedReportInput.selectedProject : '',
            selectedAccount: selectedReportInput.selectedAccount ? selectedReportInput.selectedAccount : [],
            selectedRegion: selectedReportInput.selectedRegion ? selectedReportInput.selectedRegion : [],
            selectedClusters: selectedReportInput.selectedClusters ? selectedReportInput.selectedClusters : [],
            selectedQueues: selectedReportInput.selectedQueues ? selectedReportInput.selectedQueues : [],
            selectedUser: selectedReportInput.selectedUser ? selectedReportInput.selectedUser : '',
            selectedType: selectedReportInput.selectedType ? selectedReportInput.selectedType : '',
            selectedGroupBy: selectedReportInput.selectedGroupBy ? selectedReportInput.selectedGroupBy : '',
            datePickerStartDate,
            datePickerEndDate,
            callHpcReports: true,
            callClusterList: true
        }))
    }, [selectedReportInput.selectedProject, selectedReportInput.selectedAccount, selectedReportInput.selectedRegion, selectedReportInput.selectedClusters, selectedReportInput.selectedQueues, selectedReportInput.selectedUser, selectedReportInput.selectedType, selectedReportInput.selectedGroupBy, datePickerStartDate, datePickerEndDate, selectedProvider])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider;
            if(state.selectedProject) {
                params.project = state.selectedProject
            }
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, state.selectedProject, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider;
            if(state.selectedProject) {
                params.project = state.selectedProject
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            
            dispatch(listUsersByCluster(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, users: response }));
                    }
            })

            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response }));
                    }
            })
        }
        
    }, [dispatch, state.selectedProject, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        setState(prevState => ({ ...prevState, 
            datePickerStartDate: momentDateGivenFormat(dateRange[0], "YYYY-MM-DD 00:00:00"),
            datePickerEndDate: momentDateGivenFormat(dateRange[1], "YYYY-MM-DD 23:59:59")
        }))
    }, [dateRange])

    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false }))
            let params = {}
            params.provider = state.selectedProvider
            if(state.selectedProject && state.selectedProject.length) {
                params.project = state.selectedProject
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            
            if(state.selectedQueues && state.selectedQueues.length) {
                params.queue_name = state.selectedQueues
            }

            if(state.selectedUser) {
                params.user_id = state.selectedUser
                params.user_name = state.selectedUserName
            }

            if(selectedReport && selectedReport.report_id) {
                params.report_id = selectedReport.report_id
            }
            
            if(state.selectedGroupBy) {
                params.aggregate_by = state.selectedGroupBy
            }

            // params.requested_user_id = state.clusterUserList.filter(e => e.userId === state.selectedUser)[0].userId

            if(state.selectedType) {
                params[state.selectedType] = true
                // params.type = state.selectedType
                // if(state.selectedType === 'Location') {
                //     params.region_wise = true
                // } else if(state.selectedType === 'User') {
                //     params.user_wise = true
                // } else if(state.selectedType === 'Software') {
                //     params.software_wise = true
                // }
            }
            
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate
            
            // if(state.reportDetails?.filters) {
            //     Object.entries(state.reportDetails.filters).forEach(([key, value]) => {
            //         if(value) {
            //             params[key] = value
            //         }
            //     })
            // }

            // let aggreateDetails = state.aggreateDetails ? state.aggreateDetails : []

            if(state.aggreateDetails && state.aggreateDetails.length) {
                state.aggreateDetails.forEach(row => {
                    Object.entries(row).forEach(([key, value]) => {
                        if(value) {
                            params[key] = value
                        }
                    })
                })
                // let aggregateBy = state.reportDetails?.aggregate_by
                // aggreateDetails.push({
                //     aggregateBy,
                //     [aggregateBy]: state.selectedDetails?.entity_name,
                //     user_id: state.selectedDetails?.user_id || false
                // })

                // if(aggregateBy === 'project') {
                //     params.project = state.selectedDetails?.entity_name
                // } else if(aggregateBy === 'cluster_name') {
                //     params.cluster_name = state.selectedDetails?.entity_name
                // } else if(aggregateBy === 'queue_name') {
                //     params.queue_name = state.selectedDetails?.entity_name
                // } else if(aggregateBy === 'user_name') {
                //     params.user_name = state.selectedDetails?.entity_name
                //     params.user_id = state.selectedDetails?.user_id
                // }
            }

            // console.log("aggreateDetails", aggreateDetails)

            // setState(prevState => ({ ...prevState, aggreateDetails }))
    
            dispatch(getHpcReorts(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : {}
                        setState(prevState => ({ ...prevState, reportDetails: results, structureReports: true, showLoading: false }))
                    }
                })
        }
    }, [state.callHpcReports, dispatch, state.selectedProject, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueues, state.selectedUser, state.selectedUserName, state.datePickerEndDate, state.datePickerStartDate, state.searchInput, state.selectedDetails, state.selectedType, selectedReport, state.reportDetails, state.selectedGroupBy, state.aggreateDetails])

    const onClickBack = () => {
        let aggreateDetails = state.aggreateDetails
        aggreateDetails.pop();
        setState(prevState => ({ ...prevState, aggreateDetails, showLoading: true, callHpcReports: true }))
    }

    useEffect(() => {
        if(state.structureReports) {
            setState(prevState => ({ ...prevState , structureReports: false }))
            let graph1 = state.reportDetails.graph1 ? state.reportDetails.graph1 : {}
            let graphData1 = {}
            graphData1.description = graph1.description ? graph1.description : ''
            graphData1.graph_type = graph1.graph_type ? graph1.graph_type : ''
            if(graph1.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph1.data ? graph1.data : []
                graph1 && graph1.labels && graph1.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData1.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                graphData1.labels = graph1.labels ? graph1.labels : []
                graphData1.data = graph1.data ? graph1.data : []
                graphData1.tooltip = graph1.tooltip ? graph1.tooltip : {}
                // graphData1.unit = '%'
                // graphData1.prefixUnit = '£'
            }

            let graph2 = state.reportDetails.graph2 ? state.reportDetails.graph2 : {}
            let graphData2 = {}
            graphData2.description = graph2.description ? graph2.description : ''
            graphData2.graph_type = graph2.graph_type ? graph2.graph_type : ''
            if(graph2.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph2.data ? graph2.data : []
                graph2 && graph2.labels && graph2.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData2.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                graphData2.labels = graph2.labels ? graph2.labels : []
                graphData2.data = graph2.data ? graph2.data : []
                graphData2.tooltip = graph2.tooltip ? graph2.tooltip : {}
            }
            
            let graph3 = state.reportDetails.graph3 ? state.reportDetails.graph3 : {}
            let graphData3 = {}
            if(graph3 && Object.entries(graph3).length) {
                graphData3.description = graph3.description ? graph3.description : ''
                graphData3.graph_type = graph3.graph_type ? graph3.graph_type : ''
                if(graph3.graph_type === 'donut') {
                    let donut = []
                    let donut_total_count = 0
                    let data = graph3.data ? graph3.data : []
                    graph3 && graph3.labels && graph3.labels.forEach((lab, index) => {
                        let donutRow = {}
                        donutRow.item_name = lab
                        donutRow.item_count = data[index] ? data[index] : 0
                        donut.push(donutRow)
                        donut_total_count += data[index] ? data[index] : 0 
                    })
                    donut = _.orderBy(donut, ['item_count'], ['desc'])
            
                    graphData3.graphValue = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                } else {
                    graphData3.labels = graph3.labels ? graph3.labels : []
                    graphData3.data = graph3.data ? graph3.data : []
                    graphData3.tooltip = graph3.tooltip ? graph3.tooltip : {}
                }
            }
            
            setState(prevState => ({ ...prevState, graphData1, graphData2, graphData3 }))

            let tableDetails = state.reportDetails.table ? state.reportDetails.table : {}

            let tableData = tableDetails.table_data ? tableDetails.table_data : []

            let tableFields = tableDetails.fields ? tableDetails.fields : []
            let dynamicTableColumn = []
            tableFields.forEach(row => {
                let width = 150
                if(row.length > 10) {
                    width = 200
                }
                let dataRow = {}
                dataRow.Header = row
                dataRow.accessor = row
                // dataRow.Cell = cellInfo => (
                //     <span className={`badge ${
                //         cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                //             'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                //         : 'badge-secondary'
                //         }`}>
                //         {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
                //     </span>)
                dataRow.width = width
                dynamicTableColumn.push(dataRow)
            })

            setState(prevState => ({ ...prevState, tableData, filterTableData: tableData, dynamicTableColumn, tableDetails, tableHeaders: tableFields }))
        }
    }, [state.structureReports, state.reportDetails])

    const downloadPDF = async (state) => {
        const chartContainer1 = document.getElementById('chartContainer1');
        const chartContainer2 = document.getElementById('chartContainer2');
        const chartContainer3 = document.getElementById('chartContainer3');
        if (csvLinkRef.current) {
            csvLinkRef.current.link.click(); // Programmatically trigger the download
        }
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = chartContainer3 ? await html2canvas(chartContainer3) : ''
            const pdf = new jsPDF('p', 'mm', 'a4');

            let heading = selectedReport && selectedReport.title ? selectedReport.title : ''
            const pageWidth = pdf.internal.pageSize.getWidth();
            const textWidth = pdf.getTextWidth(heading);
            const xPosition = (pageWidth - textWidth) / 2;
            pdf.text(heading, xPosition, 10); // Add the heading to the PDF

            const textBoxWidth = 180;
            const fontSize = 12;
            const lineHeight = 8;

            let account = state.selectedAccount && state.selectedAccount.length ? 'Account : '+ (state.selectedAccount.join(", ")) : ''
            let region = state.selectedRegion && state.selectedRegion.length ? 'Region : '+ (state.selectedRegion.join(", ")) : ''
            let cluster = state.selectedClusters ? state.selectedClusters : ''
            if(state.selectedClusters && Array.isArray(state.selectedClusters)) {
                cluster = 'Cluster : '+ (state.selectedClusters.join(", "))
            }

            let queues = state.selectedQueues && state.selectedQueues.length ? 'Queues : '+ (state.selectedQueues.join(", ")) : ''
            let user = state.selectedUser ? 'User : ' +state.users.filter(e => e.userId === state.selectedUser)[0].shortName : ''
            let groupBy = state.selectedGroupBy ? 'Group by : ' +state.selectedGroupBy : ''

            let longText = `Provider: ${state.selectedProvider.toUpperCase()} ${account} ${region} ${cluster} ${queues} ${user} ${groupBy}`
            let words = longText.split(' ');
            let text = '';
            let y = 22; // Starting y-coordinate for text
            for (const word of words) {
                const textWithWord = text + word + ' ';
                const textWidth = pdf.getStringUnitWidth(textWithWord) * fontSize / pdf.internal.scaleFactor;
                if (textWidth > textBoxWidth) {
                    pdf.setFontSize(12);
                    pdf.text(text, 10, y); // Draw the text
                    text = word + ' '; // Start a new line
                    y += lineHeight; // Move to the next line
                } else {
                    text = textWithWord; // Continue current line
                }
            }            
            pdf.text(text, 10, y);

            y = y+10
            let selectedPeriod = 'Period from '+ (momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY')+ ' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY'))
            pdf.text(selectedPeriod, 10, y);

            let startHeight = y+8
            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, startHeight, 130, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 9, startHeight+70, 130, 60);
            if(canvas3) {
                pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 10, startHeight+140, 130, 60);
            }
            
            let fileName = `Tachyon_Report_${selectedReport?.title}_${timestamp}`
            pdf.save(`${fileName}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

       	let datePickerStartDateNew = datePickerStartDate
        let datePickerEndDateNew = datePickerEndDate
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDateNew = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDateNew = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDateNew = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDateNew = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }

        setState(prevState => ({ ...prevState, datePickerStartDate: datePickerStartDateNew, datePickerEndDate: datePickerEndDateNew }));

	}, [state.selectedDuration, datePickerStartDate, datePickerEndDate])

    const removeItem = (field, value) => {
        let results = state[field].filter(e => e !== value)
        setState(prevState => ({ ...prevState, [field]: results}))
    }

    useEffect(() => {
        let options = aggregateByOptions
        if(state.selectedProject) {
            options = aggregateByOptions.filter(e => e.label !== 'Project')
        }
        if(state.selectedClusters && state.selectedClusters.length) {
            options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster'))
        }
        if(state.selectedQueues && state.selectedQueues.length) {
            options = aggregateByOptions.filter(e => (e.label !== 'Project' && e.label !== 'Cluster' && e.label !== 'Queue'))
        }
        if(state.selectedUser) {
            options = options.filter(e => e.label !== 'User')
        }

        setState(prevState => ({ ...prevState, aggregateByOptions: options }))
        
    }, [state.selectedProject, state.selectedClusters, state.selectedQueues, state.selectedUser])

	const handleChildClick = (event) => {	
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Reports</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{selectedReport && selectedReport.report_id ? selectedReport.report_id : ''}</span></p>
                                <div className="pl-4">
                                    <p className='mb-1 text-NeutralGray-400 pr-4'>Description: <span className='text-white font-bold'>{selectedReport && selectedReport.title ? selectedReport.title : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-8 overflow-y-auto h-full'>
                        {state.showLoading ?
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        :
                            <React.Fragment>
                                <div className="flex flex-wrap text-sm leading-7">
                                    {state.selectedProvider ?
                                        <span className="self-end">Showing report obtained from the <span className="font-bold"> {state.selectedProvider.toUpperCase()} </span> provider </span>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('project') ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'>for the project </span>
                                        <div className='w-32 self-end mx-1'>
                                            <SelectOption
                                                label={""}
                                                fields={['project', 'project']}                                                
                                                options={propProjects[selectedProvider]}
                                                selectedValues={state.selectedProject ? state.selectedProject : ''}
                                                callbackMultiSelect={(value) => 
                                                    setState(prevState => ({ ...prevState, selectedProject: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))
                                                }
                                                singleSelection={true}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                            />
                                        </div>
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('account') ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'>account</span>
                                        <div className='w-32 self-end mx-1'>
                                            <SelectOption
                                                label={""}
                                                fields={["account_id", "account_name"]}
                                                options={propProjAccounts[selectedProvider] ? propProjAccounts[selectedProvider] : []}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))}
                                                singleSelection={false}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                            />
                                        </div>
                                        {state.selectedAccount && state.selectedAccount.length ? 
                                            state.selectedAccount.map((tab, addIndex) => {
                                                return(
                                                    <p key={'account_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                        {getAccountNameFromId(tab, propProjAccounts[state.selectedProvider])}
                                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedAccount", tab)}>x</span>
                                                    </p>
                                                )
                                            })
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('region') ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'>Region</span>
                                        <div className='w-32 self-end mx-1'>
                                            <SelectOption
                                                label={""}
                                                fields={["region", "name"]}
                                                options={propProjRegions[selectedProvider] ? propProjRegions[selectedProvider] : []}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, selectedClusters: [], selectedQueues: [], selectedUser: '' }))}
                                                singleSelection={false}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                            />
                                        </div>
                                        {state.selectedRegion && state.selectedRegion.length ? 
                                            state.selectedRegion.map((tab, addIndex) => {
                                                return(
                                                    <p key={'region_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                        {getRegionName(tab, propProjRegions[state.selectedProvider])}
                                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedRegion", tab)}>x</span>
                                                    </p>
                                                )
                                            })
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('cluster') ?
                                        <React.Fragment>
                                            <span className='ml-1 self-end mr-2'>cluster</span>
                                        <div className='w-32 self-end mx-1'>
                                            <SelectOption
                                                label={""}
                                                options={state.clusters}
                                                selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value, selectedQueues: [], selectedUser: ''}))}
                                                singleSelection={false}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                            />
                                        </div>
                                        {state.selectedClusters && Array.isArray(state.selectedClusters) ? 
                                            state.selectedClusters.map((tab, addIndex) => {
                                                return(
                                                    <p key={'clus_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`} >
                                                        {tab}
                                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedClusters", tab)}>x</span>
                                                    </p>
                                                )
                                            })
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('queue') ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'> queue</span>
                                        <div className='w-32 self-end'>
                                            <SelectOption
                                                label={""}
                                                options={state.queues}
                                                selectedValues={state.selectedQueues ? state.selectedQueues : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueues: value }))}
                                                singleSelection={false}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                            />
                                        </div>
                                        {state.selectedQueues && state.selectedQueues.length ? 
                                            state.selectedQueues.map((tab, addIndex) => {
                                                return(
                                                    <p key={'queue_tab_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ml-2 my-2`}>
                                                        {tab}
                                                        <span class="flex items-center justify-center h-4 w-4 bg-black rounded-full self-center text-white text-sm ml-2 cursor-pointer" onClick={() => removeItem("selectedQueues", tab)}>x</span>
                                                    </p>
                                                )
                                            })
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.filters && selectedReport.filters.includes('user') ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'> by the user</span>
                                        <div className='w-32 self-end'>
                                            <SelectOption
                                                label={""}
                                                fields={["userId", "shortName"]}
                                                options={state.users}
                                                deSelect={true}
                                                selectedValues={state.selectedUser ? state.selectedUser : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedUser: value, selectedUserName: value ? state.users.filter(e => e.userId === value)[0].shortName : '' }))
                                                }}
                                                singleSelection={true}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                            />
                                        </div>
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.type && selectedReport.type.length ?
                                        <React.Fragment>
                                        <span className='mx-2 self-end text-black text-base'>Type</span>
                                        <div className='w-32 self-end mr-2'>
                                            <SelectOption
                                                label={""}
                                                fields={["value", 'label']}
                                                options={selectedReport.type}
                                                selectedValues={state.selectedType ? state.selectedType : ''}
                                                callbackMultiSelect={(value) => 
                                                    setState(prevState => ({ ...prevState, selectedType: value }))
                                                }
                                                singleSelection={true}
                                                addAllField={true}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                            />
                                        </div>
                                        </React.Fragment>
                                    : null}
                                    {selectedReport && selectedReport.aggregate_by && selectedReport.aggregate_by.length ?
                                        <React.Fragment>
                                        <span className='mx-2 self-end text-black text-base'>group by</span>
                                        <div className='w-32 self-end mr-2'>
                                            <SelectOption
                                                label={""}
                                                fields={["value", "label"]}
                                                options={state.aggregateByOptions}
                                                selectedValues={state.selectedGroupBy ? state.selectedGroupBy : ''}
                                                callbackMultiSelect={(value) => 
                                                    setState(prevState => ({ ...prevState, selectedGroupBy: value }))
                                                }
                                                singleSelection={true}
                                                deSelect={true}
                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                            />
                                        </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                                <div className="flex justify-between mb-6 mt-3">
                                    <div className='smarDateRangerPickerRight'>
                                        <SmartDateRangePicker
                                            dateRange={dateRange}
                                            setDateRange={setDateRange}
                                            setQueryDate={setQueryDate}
                                            queryDate={queryDate}
                                        />
                                    </div>
                                    <div className='flex'>
                                        <p className="bg-pumpkin-600 rounded-lg py-2.5 px-2 min-w-28 text-center self-end text-white break-all mr-3 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, searchInput: true, showLoading: true, callHpcReports: true }))}>Search</p>
                                        
                                        {state.showDownloadLoading ?
                                            <p className="flex text-pumpkin-600 text-base text-center self-end break-all mt-2 mr-3 cursor-pointer">
                                                <LoadingCircle 
                                                    color={"text-black"}
                                                />
                                                Downlaoding 
                                            </p>
                                        :
                                            <span className='self-end cursor-pointer' onClick={() => {
                                                if(!state.showDownloadLoading) {
                                                    setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                                    downloadPDF(state)
                                                }
                                            }}>
                                                <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full'/>
                                            </span>
                                        }
                                        {state.aggreateDetails && state.aggreateDetails.length ?
                                            <p className={`ml-3 bg-DeepPurpleTints-600 py-2.5 px-4 text-white rounded-full self-end cursor-pointer text-center`} onClick={() => onClickBack()}>Back</p>
                                        : null}
                                    </div>
                                </div>

                                <div className='flex flex-wrap'>
                                    <div className={`${state.graphData3 && Object.entries(state.graphData3).length ? 'lg:w-1/3 md:w-1/2 w-full' : 'lg:w-1/2 md:w-1/2 w-full'} pr-2`} id={'chartContainer1'}>
                                        {state.graphData1 && Object.entries(state.graphData1).length ?
                                            <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                                <span className='mt-1 mb-0 text-base text-black font-medium'>{state.graphData1.description ? state.graphData1.description :''}</span>
                                                <div className='mb-n4'> 
                                                    {state.graphData1.graph_type === 'horizontal' ?
                                                        <ApexBarChart
                                                            graphData={state.graphData1}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#999999'}
                                                            paddingLeft={0}
                                                            legend={false}
                                                            stacked={false}
                                                            height={275}
                                                            horizontal={true}
                                                            barHeight={'40%'}
                                                            barEndShape={'rounded'}
                                                            barRadius={8}
                                                            columnWidth={'25%'}
                                                            showTitle={true}
                                                            //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                            gradient={true}
                                                            gradientColor={['#0057b8', '#7551c2']}
                                                            hideTooltipValue={true}
                                                            // backgroundBarShape={'F'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                            dataLabels={true}
                                                            dataLabelsTextAnchor={'middle'}
                                                            dataLabelPosition={''}
                                                        />
                                                    : state.graphData1.graph_type === 'vertical_stacked' ?
                                                        <ApexStackedBarReportChart
                                                            graphData={state.graphData1}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'categoryString'}
                                                            xaxisLabel={true}
                                                            axisLabelFontSize={'9px'}
                                                            axisLabelColor={'#B8BBBE'}
                                                            paddingLeft={-25}
                                                            legend={false}
                                                            stacked={true}
                                                            height={275}
                                                            horizontal={false}
                                                            barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#0057b8', '#7551c2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={true}
                                                            className={"transparentTooltip"}
                                                            colors={["#9A77D1", "#2D323E"]}
                                                        />
                                                    : state.graphData1.graph_type === 'donut' ?
                                                        <div className='flex justify-center'>
                                                            <ApexDonutChart
                                                                graphData={state.graphData1.graphValue}
                                                                legend={false}
                                                                showTotal={true}
                                                                showTotalLable={true}
                                                                showGraphHeading={false}
                                                                height={250}
                                                                width={250}
                                                                size={'70%'}
                                                                gradient={false}
                                                                gradientColor={['#A88CCC', '#D88ACF']}
                                                                colors={['#d13737', '#037a2b', '#27B224', '#ba0000', '#d13737', '#1261AB', '#ffb534', '#6500B7']}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        : null}
                                    </div>

                                    <div className={`${state.graphData3 && Object.entries(state.graphData3).length ? 'lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0' : 'lg:w-1/2 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0'} pl-2`} id={'chartContainer2'}>
                                        {state.graphData2 && Object.entries(state.graphData2).length ?
                                            <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                                <span className='mt-1 mb-0 text-base text-black font-medium'>{state.graphData2.description ? state.graphData2.description :''}</span>
                                                <div className='mb-n4'>
                                                    {state.graphData2.graph_type === 'horizontal' ?
                                                        <ApexBarChart
                                                            graphData={state.graphData2}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#999999'}
                                                            paddingLeft={0}
                                                            legend={false}
                                                            stacked={false}
                                                            height={275}
                                                            horizontal={true}
                                                            barHeight={'40%'}
                                                            barEndShape={'rounded'}
                                                            barRadius={8}
                                                            columnWidth={'25%'}
                                                            showTitle={true}
                                                            //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                            gradient={true}
                                                            gradientColor={['#0057b8', '#8e3a80']}
                                                            hideTooltipValue={true}
                                                            // backgroundBarShape={'F'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                            dataLabels={true}
                                                            dataLabelsTextAnchor={'middle'}
                                                            dataLabelPosition={''}
                                                        />
                                                    : state.graphData2.graph_type === 'vertical_stacked' ?
                                                        <ApexStackedBarReportChart
                                                            graphData={state.graphData2}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'categoryString'}
                                                            xaxisLabel={true}
                                                            axisLabelFontSize={'9px'}
                                                            axisLabelColor={'#B8BBBE'}
                                                            paddingLeft={-5}
                                                            legend={false}
                                                            stacked={true}
                                                            height={275}
                                                            horizontal={false}
                                                            barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={"25%"}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={true}
                                                            className={"transparentTooltip"}
                                                            colors={['#693EBC', '#775BA2', '#9A77D1', '#039BE5', '#43B02A', '#F75E3F']}
                                                        />
                                                    : state.graphData2.graph_type === 'donut' ?
                                                        <div className='flex justify-center'>
                                                            <ApexDonutChart
                                                                graphData={state.graphData2.graphValue}
                                                                legend={false}
                                                                showTotal={true}
                                                                showTotalLable={true}
                                                                showGraphHeading={false}
                                                                height={250}
                                                                width={250}
                                                                size={'70%'}
                                                                gradient={false}
                                                                gradientColor={['#A88CCC', '#D88ACF']}
                                                                colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        : null}
                                    </div>

                                    {state.graphData3 && Object.entries(state.graphData3).length ?
                                        <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0`} id={'chartContainer3'}>
                                            <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                            <span className='mt-1 mb-0 text-base text-black font-medium'>{state.graphData3.description ? state.graphData3.description :''}</span>
                                                <div className='mb-n4'> 
                                                    {state.graphData3.graph_type === 'horizontal' ?
                                                        <ApexBarChart
                                                            graphData={state.graphData3}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'string'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#999999'}
                                                            paddingLeft={0}
                                                            legend={false}
                                                            stacked={false}
                                                            height={275}
                                                            horizontal={true}
                                                            barHeight={'40%'}
                                                            barEndShape={'rounded'}
                                                            barRadius={8}
                                                            columnWidth={'25%'}
                                                            showTitle={true}
                                                            //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                            gradient={true}
                                                            gradientColor={['#0057b8', '#8e3a80']}
                                                            hideTooltipValue={true}
                                                            // backgroundBarShape={'F'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                            dataLabels={true}
                                                            dataLabelsTextAnchor={'middle'}
                                                            dataLabelPosition={''}
                                                        />
                                                    : state.graphData3.graph_type === 'vertical_stacked' ? 
                                                        <ApexStackedBarReportChart
                                                            graphData={state.graphData3}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'categoryString'}
                                                            xaxisLabel={true}
                                                            axisLabelFontSize={'9px'}
                                                            axisLabelColor={'#B8BBBE'}
                                                            paddingLeft={-25}
                                                            legend={false}
                                                            stacked={true}
                                                            height={275}
                                                            horizontal={false}
                                                            barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={true}
                                                            className={"transparentTooltip"}
                                                            colors={["#9A77D1", "#2D323E"]}
                                                        />
                                                    : state.graphData3.graph_type === 'donut' ?
                                                        <div className='flex justify-center'>
                                                            <ApexDonutChart
                                                                graphData={state.graphData3.graphValue}
                                                                legend={false}
                                                                showTotal={true}
                                                                showTotalLable={true}
                                                                showGraphHeading={false}
                                                                height={250}
                                                                width={250}
                                                                size={'70%'}
                                                                gradient={false}
                                                                gradientColor={['#A88CCC', '#D88ACF']}
                                                                colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                                className={"transparentTooltip"}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                                
                                <div className='flex flex-wrap pt-6'  ref={tableRef} id={'table'}>
                                    <p className={`text-black text-lg font-medium pt-10 w-full`}>{state.tableDetails && state.tableDetails.description ? state.tableDetails.description : ''}</p>
                                    <div className='flex'>
                                        <div className="flex">
                                            <CSVLink
                                                data={state.tableDetails && state.tableDetails.table_data ? state.tableDetails.table_data : []} 
                                                headers={state.tableHeaders ? state.tableHeaders : []}
                                                filename={`Tachyon_Report_${selectedReport?.title}_${timestamp}.csv`}
                                                className={"mt-2 hidden"}
                                                ref={csvLinkRef} // Attach the reference
                                                target="_blank"
                                            >
                                                <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                            </CSVLink>
                                        </div>
                                    </div>
                                    <div className='relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600 w-full'>
                                        <table className="w-full text-left">
                                            <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-2xl text-base font-semibold text-NeutralGray-800'>
                                                <tr className="flex-1 w-full">
                                                    {state.tableDetails && state.tableDetails.fields ?
                                                        state.tableDetails.fields.map(head => {
                                                            return(
                                                                <th key={"head_"+head} className='tracking-wider py-2 pl-4 text-left'>{head}</th>
                                                            )
                                                        })
                                                    : null}
                                                </tr>
                                            </thead>
                                            <tbody className='text-sm text-NeutralGray-900'>
                                                {state.tableDetails && state.tableDetails.table_data ?
                                                    state.tableDetails.table_data.map((row, index)=> {
                                                        return(
                                                            <tr key={"head1_"+index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`} onClick={() => {
                                                                if(state.reportDetails?.aggregate_by && !row['Job Id']) {
                                                                    let aggreateDetails = state.aggreateDetails ? state.aggreateDetails : []
                                                                    let aggregateBy = state.reportDetails?.aggregate_by
                                                                    aggreateDetails.push({
                                                                        aggregateBy,
                                                                        [aggregateBy]: row?.entity_name,
                                                                        user_id: row?.user_id || false
                                                                    })
                                                                    
                                                                    setState(prevState => ({ ...prevState, selectedDetails: row, aggreateDetails, showLoading: true, callHpcReports: true, graphData1: {}, graphData2: {}, graphData3: {} }))
                                                                }
                                                            }}>
                                                                {state.tableDetails && state.tableDetails.fields ?
                                                                    state.tableDetails.fields.map((head, hIndex) => {
                                                                        return(
                                                                            <td key={"head2_"+hIndex} className={`${!hIndex && !row['Job Id'] ? 'text-byzantineBlue cursor-pointer' : 'text-black'} py-2.5 pl-4 text-left text-md ${!hIndex ? '' : ''}`}>
                                                                                {head === 'status1' ?
                                                                                    <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row[head]}</span>
                                                                                :
                                                                                    row[head]
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                : null}
                                                            </tr>
                                                        )
                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default RunReport;