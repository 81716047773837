/*************************************************
 * Collider
 * @exports
 * @file BreakupDetails.js
 * @author Prakash
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector } from 'react-redux'
import {Icon} from "@iconify/react";
import moment from 'moment';
import ApexDonutChart from '../../../common/charts/ApexDonutChart';
import ApexStackedBarChart from '../../../common/charts/ApexStackedBarChart';
import { CSVLink } from 'react-csv';

const BreakupDetails = (props) => {
	const[state, setState] = useState({
		colors: ['#7052BC', '#AA2217', '#57AF3E', '#2D60A6', '#FC6D22', '#CF9124' ]
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		if(props?.data?.breakup) {
			let tableData = []
			let labels = props.dates
			Object.entries(props.data.breakup).forEach(([key, value]) => {
				var result = {
					"Title": key,
					'total': props.data && props.data.total && props.data.total[key] ? props.data.total[key] : 0
				}
				labels.forEach((date, dateIndex) => {
					result[date] = value[dateIndex]
				})
				tableData.push(result)
			})
			
			let tableHeaders = [{label: 'Title',key: 'Title'}]
			const columns = Object.entries(tableData[0]).map(([tKey, tValue]) =>{
				let sticky = false
				let header = ''
				let width = 100
				let textColor = ''
				if(tKey === 'Title') {
					sticky = true
					header = tKey
					width = 300
				} else if(tKey === 'total') {
					sticky = true
					header = 'Total'
					tableHeaders.push({label: 'Total',key: 'total'})
				} else {
					header = moment(tKey).format('MMM YY')
					tableHeaders.push({label: header,key: header})
				}
				return {
					Header: header,
					accessor: tKey,
					width: width,
					sticky: sticky ? "left" : "",
					textColor: textColor,
					className: 'text-truncate text-right'
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders, tableData, columns }))
		}

	}, [props.data, props.dates])

	useEffect(() => {
		if(props?.data?.breakup) {
			let spendStackedTrend = props?.data?.breakup
			let graphData = {}
			graphData.labels = props?.dates || []
	
			let formattedColors = {}
			Object.entries(spendStackedTrend).length && Object.entries(spendStackedTrend).forEach(([key, value], tIndex) => {
				graphData[key] = value
				formattedColors[key.toLowerCase()] = state.colors[tIndex] ? state.colors[tIndex] : state.colors[tIndex - state.colors.length]
			})

			let spendDonutResponse = props?.data?.total || {}

			let donut = []
			let donut_total_count = 0		
			spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value], dIndex) => {
				let donutRow = {}
				donutRow.item_count = value
				donutRow.item_name = key
				donutRow.color = state.colors[dIndex] ? state.colors[dIndex] : state.colors[dIndex - state.colors.length]
				donut.push(donutRow)
				donut_total_count += value 
			})
	
			let spendDonutData = {
				'items': donut,
				'label': 'Total',
				'total_count': donut_total_count
			}
			
			setState(prevState => ({ ...prevState, spendDonutData, filteredSpendDonutData: spendDonutData,  spendStackedTrendData: graphData, filteredSpendStackedTrendData: graphData, formattedColors, trendBreakDownTotal: spendDonutResponse }))
		}
	}, [props.data, props.dates, state.colors])

    const getBgColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'bg-slateBlue-600'
        if(index === 1) { color = 'bg-red-600' } else 
        if(index === 2) { color = 'bg-appleGreen-600' } else
        if(index === 3) { color = 'bg-blue-600' } else
        if(index === 4) { color = 'bg-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'bg-LemonCurry-600' }
        return color
    }

    const getTextColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'text-slateBlue-600'
        if(index === 1) { color = 'text-red-600' } else 
        if(index === 2) { color = 'text-appleGreen-600' } else
        if(index === 3) { color = 'text-blue-600' } else
        if(index === 4) { color = 'text-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'text-LemonCurry-600' }
        return color
    }

	const onLegnedClick = (key) => {
        // Get the current filtered data for the index
        let filteredDonutData = { ...state.filteredSpendDonutData };

        setState(prevState => ({ ...prevState, filteredSpendDonutData: {} }));
    
        // Ensure the `items` array is a copy to avoid mutating the original state
        filteredDonutData.items = [...(filteredDonutData?.items || [])];
    
        const keyExists = filteredDonutData.items.some(e => e.item_name === key);
        if (keyExists) {
            filteredDonutData.items = filteredDonutData.items.filter(e => e.item_name !== key);
        } else {
            let spendDonutData = state.spendDonutData
            const newItem = spendDonutData.items.find(e => e.item_name === key);
            const originalIndex = spendDonutData.items.findIndex(e => e.item_name === key);

            // Insert it into the filtered data at the correct position
            filteredDonutData.items = [
                ...filteredDonutData.items.slice(0, originalIndex),
                newItem,
                ...filteredDonutData.items.slice(originalIndex),
            ]
        }
    
        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredSpendDonutData: filteredDonutData }));
        }, 1000)
    };

    const onLegendTrendClick = (key) => {
        let filteredData = { ...state.filteredSpendStackedTrendData };
        // Deep copy of the filtered data to ensure no reference to the original state
        filteredData[key] = filteredData[key] ? [...filteredData[key]] : [];
    
        if (filteredData[key].length > 0) {
            // let removeIndex = Object.keys(filteredData).findIndex(e => e === key);
            // formattedColors.splice(removeIndex, 1);
            delete filteredData[key]
        } else {
            // let formattedColors = []
            let originalData = { ...state.spendStackedTrendData };
            let dataRow = {}
            Object.entries(originalData).forEach(([oKey, oValue], oIndex) => {
                if(filteredData.hasOwnProperty(oKey) || oKey === key) {
                    // formattedColors.push(state.colors[oIndex])
                    dataRow[key] = oValue
                }
            })
    
            // Deep copy of the originalData to prevent reference sharing
            originalData[key] = originalData[key] ? [...originalData[key]] : [];
    
            // Assign the deep copied originalData to filteredData
            filteredData[key] = originalData[key];
			filteredData.labels = props.dates
        }
    
        // Set the filtered data back to state
        setState(prevState => ({ ...prevState, filteredSpendStackedTrendData: {} }));

        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredSpendStackedTrendData: filteredData }));
        }, 1000)
    };   

	const handleChildClick = (event) => {
		event.stopPropagation();
	}

	return (
		<div className="overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='h-full flex flex-col bg-white lg:w-4/5 w-10/12'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Breakup Details</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className={`text-black ${state.onClickSave ? "pointer-events-none" : "cursor-pointer"} self-center`} width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
					</div>
					<div className="overflow-y-auto h-[calc(100vh-200px)] bg-PrimaryTintsPurple-600 bg-white p-6">
						<div className='flex justify-between'>
							<p className="text-lg self-center text-NeutralGray-800 mb-1">
								Showing <span className='font-bold '>{props?.data?.label}</span> breakup Cost for the account <span className='font-bold '>{props.billingUnit}</span>
							</p>
							{state.tableData && state.tableData.length && state.tableHeaders ?
								<div className="flex justify-end">
									<CSVLink
										data={state.tableData ? state.tableData : []} 
										headers={state.tableHeaders ? state.tableHeaders : []}
										filename={'Tachyon-Cost-Breakup-'+props.department+'-'+props.billingUnit+'-'+props?.data?.label+'-'+new Date().getTime()+'.csv'}
										className={"mt-2"}
										target="_blank"
									>
										<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
									</CSVLink>
								</div>
							: null}

						</div>
						<div className="w-full">
							<ul className="w-1/4 text-xs self-center mb-0 mt-3 text-black flex">
								{state.trendBreakDownTotal && Object.entries(state.trendBreakDownTotal).map(([key, value], tIndex) => {
									return(
										<li key={'trend_' + tIndex} id={'legend_'+tIndex} className='flex mb-2 cursor-pointer' onClick={() => { 
											onLegnedClick(key) 
											onLegendTrendClick(key)
										}}>
											{state.filteredSpendDonutData?.items?.filter(e => e.item_name === key) && state.filteredSpendDonutData?.items?.filter(e => e.item_name === key).length ?
												<React.Fragment>
												<span className={`h-3 w-3 self-center ${getBgColor(tIndex)}`}></span> 
												<span className={`px-2 ${getTextColor(tIndex)} d-inline-block capitalize`}>{key}</span>
												</React.Fragment>
											:
												<React.Fragment>
												<span className={`h-3 w-3 self-center bg-NeutralGray-400`}></span>
												<span className={`px-2 text-NeutralGray-400 d-inline-block capitalize`}>{key}</span>
												</React.Fragment>
											}
										</li>
										
									)
								})}
							</ul>
							<div className='flex justify-between pb-8'>
								<div className='self-center mr-4 mt-3'>
									{state.filteredSpendDonutData && Object.entries(state.filteredSpendDonutData).length ? 
										<ApexDonutChart
											labelColor={"#000000"}
											valueColor={"#000000"}
											labelSize={"18px"}
											valueSize={"18px"}
											unit={userMenu.currency_symbol ? userMenu.currency_symbol : '$'}
											graphData={state.filteredSpendDonutData}
											legend={false}
											showTotal={true}
											showTotalLable={true}
											showGraphHeading={false}
											height={200}
											width={200}
											size={'70%'}
											gradient={false}
											gradientColor={['#A88CCC', '#D88ACF']}
											colors={state.colors}
											className={'transparentTooltip'}
										/>
									: null}
								</div>

								{state.filteredSpendStackedTrendData && Object.entries(state.filteredSpendStackedTrendData).length ?
									<React.Fragment>
									<div className='-mt-3 -mb-3 mr-2 w-3/4 self-end'>
										<ApexStackedBarChart
											graphData={state.filteredSpendStackedTrendData}
											start_time={props.startDate}
											end_time={props.endDate}
											unit={userMenu.currency_symbol ? userMenu.currency_symbol : '$'}
											sparkline={false}
											yaxis={false}
											yaxisLabel={true}
											xaxis={true}
											xaxisFormat={'datetime'}
											xaxisDateFormat={'MMM YY'}
											xaxisLabel={true}
											axisLabelColor={'#666666'}
											paddingLeft={-25}
											legend={false}
											stacked={true}
											height={220}
											horizontal={false}
											barEndShape={'flat'}
											columnWidth={'25%'}
											gradient={true}
											stroke={'hide'}
											colors={state.colors}
											formatedColors={state.formattedColors}
											hideTooltipValue={true}
											backgroundBarShape={'flat'}
											backgroundBarColors={['#E6E6E6']}
											showBackgroundBarColors={false}
											className={'transparentTooltip'}
										/>
									</div>                                                            
									</React.Fragment>
								: null}
							</div>
						</div>
						<div className="overflow-x-scroll">
							<table className="w-full text-left">
								<thead className="">
									<tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
										{state.columns && state.columns.map((col, colIndex) => {
											return(
												<th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 text-left ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase text-nowrap`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
											)
										})}
									</tr>
								</thead>
								<tbody className="bg-white text-black">
									{state.tableData && state.tableData.length ?
										state.tableData.map((col, colIndex) => {
											return(
												<tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
													{state.columns.map((obj, objIndex) => {
														return(
															<td key={objIndex+'_'+colIndex} className={`p-4 text-black ${objIndex ? 'text-right' : 'text-left'} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100 ' : ''} text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
														)
													})}
												</tr>
											)
										})
									: null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BreakupDetails