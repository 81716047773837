/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tachyon
 * @exports
 * @file ExpandedDetails.js
 * @author Prakash
 * @copyright Tachyon. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom';
import { listDashboardResults } from '../../../actions/Collider/ClusterAction'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import { momentConvertionLocalToUtcTime, removeUnderScore } from '../../../utils/utility';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { PERIOD_OPTIONS_NEW } from '../../../utils/constants';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import _ from 'lodash'
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import ApexBarChart from '../../common/charts/ApexBarChart';
import { URL_PATH } from '../../../config/urlPath';

const ExpandedDetails = ({ selectedProvider, selectedAccount, selectedRegion, selectedClusters, selectedItem, selectedType, startDate, endDate, isUserDashboard, showDetailsPanel }) => {
    const [selectedDetails, setSelectedDetails] = useState({})
    const [state, setState] = useState({
        showLoading: true,
        selectedPeriod: 'daily',

        clusterDetails: [],
        selectedClusterDetails: [],
        clusterheaders: [],
        callSearch: true,
        resourceGraphTotal: {},
        resourceGraph: ['CPU_cores', 'cumulative_CPU_hours', 'concurrent_CPU_cores', 'avg_cumulative_CPU_hours'],
        colors: ['#7052BC', '#AA2217', '#57AF3E', '#2D60A6', '#FC6D22', '#CF9124' ],
        jobStatusColor: ['#4A5766', '#7052BC', '#AA2217', '#2D60A6', '#9747FF', '#FC6D22', '#FFB534', '#1C0732', '#57AF3E']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const navigate = useNavigate();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showLoading: true }))
            let params = {}
            params.provider = selectedProvider ? selectedProvider.toLowerCase() : ''
            params.start_time = startDate
            params.end_time = endDate
            if(selectedAccount && selectedAccount.length) {
                params.account_id = selectedAccount
            }
            if(selectedRegion && selectedRegion.length) {
                params.region = selectedRegion
            }
            if(selectedClusters && selectedClusters.length) {
                params.cluster_name = selectedClusters
            }
            params.duration_aggregate_by = state.selectedPeriod

            // params.user_name = selectedItem.userName
            if(selectedType === 'cluster') {
                params.cluster_name = selectedItem.cluster_name
            } else if(selectedType === 'queue') {
                params.queue_name = selectedItem.queue_name
            } else if(selectedType === 'project') {
                params.project = selectedItem.project
            } else if(selectedType === 'user') {
                params.requested_user_id = selectedItem.userId
            }
            params.currency_conversion = userMenu.currency_type ? userMenu.currency_type : 'usd'
            params.type = selectedType

            dispatch(listDashboardResults(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results[0] : {}
                        setSelectedDetails(results)
                        setState(prevState => ({ 
                            ...prevState,
                            detailsFormat: response && response.user_details ? response.user_details : [],
                            structureResponse: true,
                            showLoading: false,
                        }));
                    }
                })
        }
    }, [state.callSearch, selectedAccount, selectedClusters, selectedProvider, selectedRegion, state.selectedPeriod, dispatch, selectedItem, selectedType, startDate, endDate, userMenu])

    useEffect(() => {
        if(state.structureResponse && selectedDetails && Object.entries(selectedDetails).length) {
            setState(prevState => ({ ...prevState, structureResponse: false }))

            let dates = selectedDetails.dates ? selectedDetails.dates :[]
            let recentJobTableData = []
            if(selectedDetails && selectedDetails.job_details && selectedDetails.job_details.length) {
                selectedDetails.job_details.forEach(row => {
                    recentJobTableData.push(row)
                })
            }
            for (let i = recentJobTableData.length; i < 3; i++) {
                recentJobTableData.push({})
            }

            let recentWorkstationTableData = []
            if(selectedDetails && selectedDetails.workstation_details && selectedDetails.workstation_details.length) {
                selectedDetails.workstation_details.forEach(row => {
                    recentWorkstationTableData.push(row)
                })
            }
            for (let i = recentWorkstationTableData.length; i < 3; i++) {
                recentWorkstationTableData.push({})
            }

            setState(prevState => ({ ...prevState, recentJobTableData, recentWorkstationTableData }))


            //jobOverview
        
            let rateStaus = selectedDetails && selectedDetails.total && selectedDetails.total.rate ? selectedDetails.total.rate : {}
            let jobOverviewDonut = []
            let job_overview_donut_total_count = 0
            let totalSuccessValue = rateStaus.success_total ? rateStaus.success_total : 0
            let totalFailureValue = rateStaus.failed_total ? rateStaus.failed_total : 0
            let totalSuccessRate = rateStaus.success_rate ? rateStaus.success_rate : 0
            let totalFailureRate = rateStaus.failure_rate ? rateStaus.failure_rate : 0
            
            let donutRow = {}
            donutRow.item_count = totalSuccessValue
            donutRow.item_name = "Passed"
            donutRow.item_value = totalSuccessValue
            jobOverviewDonut.push(donutRow)
            job_overview_donut_total_count += totalSuccessRate 

            donutRow = {}
            donutRow.item_count = totalFailureValue
            donutRow.item_name = "Failed"
            donutRow.item_value = totalFailureValue
            jobOverviewDonut.push(donutRow)
            job_overview_donut_total_count += totalFailureRate 
            
            jobOverviewDonut = _.orderBy(jobOverviewDonut, ['item_count'], ['desc'])
    
            let jobOverview = {
                'items': jobOverviewDonut,
                'label': 'Total',
                'total_count': job_overview_donut_total_count
            }

            setState(prevState => ({ ...prevState, jobOverview, totalSuccessValue, totalFailureValue, totalSuccessRate, totalFailureRate }))
        
            //jobsExecution

            let jobExecutedTotal = selectedDetails && selectedDetails.total && selectedDetails.total.jobs_executed ? selectedDetails.total.jobs_executed : {}
            let executionData = selectedDetails && selectedDetails.trend && selectedDetails.trend.jobs_executed ? selectedDetails.trend.jobs_executed : {}
            let jobsExecuted = {}
            jobsExecuted.labels = dates
            jobsExecuted.jobs_executed = executionData.data ? executionData.data : []
            setState(prevState => ({ ...prevState, jobsExecuted, jobExecutedTotal }))

            //jobStatusTrend

            let totalStaus = selectedDetails && selectedDetails.total && selectedDetails.total.status ? selectedDetails.total.status : {}
            
            let JobTrendDonut = []
            let job_trend_donut_total_count = 0
            totalStaus && Object.entries(totalStaus).forEach(([key, value]) => {
                let donutRow = {}
                donutRow.item_count = value
                donutRow.item_name = key
                JobTrendDonut.push(donutRow)
                job_trend_donut_total_count += value
            })
            
            JobTrendDonut = _.orderBy(JobTrendDonut, ['item_count'], ['desc'])
    
            let jobStausTrendDonut = {
                'items': JobTrendDonut,
                'label': 'Total',
                'total_count': job_trend_donut_total_count
            }

            let statusData = selectedDetails && selectedDetails.trend && selectedDetails.trend.status ? selectedDetails.trend.status : {}
            let jobStatusTrend = {}
            jobStatusTrend.labels = dates
            // if(statusData.dates) {
            //     jobStatusTrend.labels = statusData.dates ? statusData.dates : []
            // }
            statusData.data && Object.entries(statusData.data).length && Object.entries(statusData.data).forEach(([key, value]) => {
                jobStatusTrend[key] = value
            })
            setState(prevState => ({ ...prevState, jobStatusTrend, filteredJobStatusTrend: jobStatusTrend, statusData, filteredJobStausTrendDonut: jobStausTrendDonut, jobStausTrendDonut, totalStaus }))
            
            //budgetSpendTrend

            let budgetSpendTrendnData = selectedDetails && selectedDetails.trend && selectedDetails.trend.compute_cost && selectedDetails.trend.compute_cost.spend ? selectedDetails.trend.compute_cost.spend : {}
            let data = [budgetSpendTrendnData.budget ? budgetSpendTrendnData.budget : 0 , budgetSpendTrendnData.total ? budgetSpendTrendnData.total : 0]
            let labels = ['Budget', 'Spend']
            let dataUnit = userMenu.currency_symbol ? userMenu.currency_symbol : ''
        
            let budgetSpendTrend = {}
            budgetSpendTrend.data = data
            budgetSpendTrend.labels = labels
            budgetSpendTrend.unit = dataUnit
            setState(prevState => ({ ...prevState, budgetSpendTrend }))

            let jobWorkOverData = selectedDetails && selectedDetails.trend && selectedDetails.trend.compute_cost && selectedDetails.trend.compute_cost.total ? selectedDetails.trend.compute_cost.total : {}

            let formattedColors = {}
            
            let jwDonut = []
            let jw_donut_total_count = 0

            jobWorkOverData && Object.entries(jobWorkOverData).forEach(([item_name, item_count], dIndex) => {
                let jwDonutRow = {};
                jwDonutRow.item_count = item_count;
                jwDonutRow.item_name = item_name
                formattedColors[item_name] = state.colors[dIndex] ? state.colors[dIndex] : state.colors[dIndex - state.colors.length]
                donutRow.color = state.colors[dIndex] ? state.colors[dIndex] : state.colors[dIndex - state.colors.length]
                jwDonut.push(jwDonutRow);
                jw_donut_total_count += item_count;
            });
            jwDonut = _.orderBy(jwDonut, ['item_count'], ['desc'])
    
            let jobWorstationOverview = {
                'items': jwDonut,
                'label': 'Total',
                'total_count': jw_donut_total_count
            }

            let jobWorkData = selectedDetails && selectedDetails.trend && selectedDetails.trend.compute_cost && selectedDetails.trend.compute_cost ? selectedDetails.trend.compute_cost : {}
            let jobWorkstationTrend = {}
            jobWorkstationTrend.labels = dates
            
            jobWorkData.breakdown && Object.entries(jobWorkData.breakdown).length && Object.entries(jobWorkData.breakdown).forEach(([key, value]) => {
                jobWorkstationTrend[key] = value
                // jobStatusTrend.tooltipLabel = title[key]
            })
            setState(prevState => ({ ...prevState, filteredJobWorkstationTrend: jobWorkstationTrend, jobWorkstationTrend, jobWorkstationData: jobWorkData.data ? jobWorkData.data : {}, jobWorstationOverview, filteredJobWorstationOverview: jobWorstationOverview, jobWorkOverData, formattedColors }))

            //resourceGraph
            
            let trends = selectedDetails?.trend || false
            let resourceGraphTotal = selectedDetails?.total || false
            let resourceGraphTitle = selectedDetails?.title || false

            state.resourceGraph.forEach(row => {
                let dataRow = {}
                dataRow.labels = dates
                dataRow.data = trends?.[row]?.data || []

                setState(prevState => ({ ...prevState, [row+'_graphData']: dataRow }))
            })

            setState(prevState => ({ ...prevState, resourceGraphTotal, resourceGraphTitle }))

            //trendtable
            let trendLabels = []
            selectedDetails.trend && Object.entries(selectedDetails.trend).forEach(([key, value]) => {
                trendLabels = dates
            })
            let reports= []
            trendLabels.forEach((lab, labIndex) =>{ 
                let reportRow = {}
                reportRow.date = lab
                let trendData = selectedDetails.trend ? selectedDetails.trend : {}
                reportRow.jobs_executed = trendData.jobs_executed && trendData.jobs_executed.data && trendData.jobs_executed.data[labIndex] ? trendData.jobs_executed.data[labIndex] : 0
                reportRow.compute_cost = trendData.compute_cost && trendData.compute_cost.data && trendData.compute_cost.data[labIndex] ? trendData.compute_cost.data[labIndex] : 0
                reportRow.CPU_cores = trendData.CPU_cores && trendData.CPU_cores.data && trendData.CPU_cores.data[labIndex] ? trendData.CPU_cores.data[labIndex] : 0
                reportRow.avg_cumulative_CPU_hours = trendData.avg_cumulative_CPU_hours && trendData.avg_cumulative_CPU_hours.data && trendData.avg_cumulative_CPU_hours.data[labIndex] ? trendData.avg_cumulative_CPU_hours.data[labIndex] : 0
                reportRow.concurrent_CPU_cores = trendData.concurrent_CPU_cores && trendData.concurrent_CPU_cores.data && trendData.concurrent_CPU_cores.data[labIndex] ? trendData.concurrent_CPU_cores.data[labIndex] : 0
                reportRow.cumulative_CPU_hours = trendData.cumulative_CPU_hours && trendData.cumulative_CPU_hours.data && trendData.cumulative_CPU_hours.data[labIndex] ? trendData.cumulative_CPU_hours.data[labIndex] : 0
                // Object.entries(selectedDetails.trend).forEach(([key, value]) => {
                //     if(key !== 'status') {
                //         reportRow[key] = value[labIndex] ? value[labIndex] : 0
                //     }
                // })
                reports.push(reportRow)
            })

            setState(prevState => ({ ...prevState, reportTableData: reports }))
        }
    }, [state.structureResponse, state.resourceGraph, selectedDetails, userMenu.currency_symbol, state.colors])    

    const getStatusBgColor = (status) => {        
        let color = 'bg-slateBlue-600'
        if(status.toLowerCase() === 'failed') { color = 'bg-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'cancelled') { color = 'bg-independenceGray-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'bg-yellowOrange-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'bg-slateBlue-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-blue-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-LavenderIndigo-600' } else
        if(status.toLowerCase() === 'job') { color = 'bg-CyanBlueAzure' } else
        if(status.toLowerCase() === 'workstation') { color = 'bg-yellowOrange-600' }

        return color;
    }

    const getStatusTextColor = (status) => {
        let color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue-600'

        if(status.toLowerCase() === 'cancelled') { color = 'text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'text-LavenderIndigo-600' }

        return color;
    }

    const getStatusBadgeColor = (status) => {        
        let color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue-600'
        if(status.toLowerCase() === 'cancelled') { color = 'bg-independenceGray-50 border-2 border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-blue-50 border-2 border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-LavenderIndigo-50 border-2 border-LavenderIndigo-600 text-LavenderIndigo-600' }

        return color;
    }

    const navigateTo = (path) => {
        let provider= selectedProvider.toLowerCase()
        navigate(path, { state: { selectedProvider: provider, selectedAccount, selectedRegion, selectedClusters, datePickerStartDate: startDate, datePickerEndDate: endDate, fromScreen: 'hpd_dashboard' } } );
    }

    const onStatusLegnedClick = (key, index) => {
        // Get the current filtered data for the index
        let filteredDonutData = { ...state.filteredJobStausTrendDonut };

        setState(prevState => ({ ...prevState, filteredJobStausTrendDonut: {} }));
    
        // Ensure the `items` array is a copy to avoid mutating the original state
        filteredDonutData.items = [...(filteredDonutData?.items || [])];
    
        const keyExists = filteredDonutData.items.some(e => e.item_name === key);
        if (keyExists) {
            filteredDonutData.items = filteredDonutData.items.filter(e => e.item_name !== key);
        } else {
            let spendDonutData = state.jobStausTrendDonut
            const newItem = spendDonutData.items.find(e => e.item_name === key);
            const originalIndex = spendDonutData.items.findIndex(e => e.item_name === key);

            // Insert it into the filtered data at the correct position
            filteredDonutData.items = [ ...filteredDonutData.items.slice(0, originalIndex), newItem, ...filteredDonutData.items.slice(originalIndex) ]
        }
    
        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredJobStausTrendDonut: filteredDonutData }));
        }, 1000)
    };

    const onStatusLegendTrendClick = (key) => {
        // Create a deep copy of jobWorkOverData to avoid modifying the original state
        let filteredData = { ...state.filteredJobStatusTrend };
    
        // Check if the key exists in filteredData
        if (filteredData.hasOwnProperty(key)) {
            // If it exists, delete it to remove it from the filtered data
            delete filteredData[key];
        } else {
            // If it doesn't exist, we need to add it from the original data
            let originalData = { ...state.jobStatusTrend }; // Deep copy of original data
    
            // Only add the key if it exists in originalData
            if (originalData.hasOwnProperty(key)) {
                filteredData[key] = originalData[key]; // Add the corresponding value from originalData
                filteredData.labels = originalData.labels; // Preserve labels from original data
            }
        }
    
        // Update state with the new filtered data
        setState(prevState => ({ ...prevState, filteredJobStatusTrend: {} }));
    
        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredJobStatusTrend: filteredData }));
        }, 1000);
    };


    const getBgColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'bg-slateBlue-600'
        if(index === 1) { color = 'bg-red-600' } else 
        if(index === 2) { color = 'bg-appleGreen-600' } else
        if(index === 3) { color = 'bg-blue-600' } else
        if(index === 4) { color = 'bg-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'bg-LemonCurry-600' }
        return color
    }

    const getTextColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'text-slateBlue-600'
        if(index === 1) { color = 'text-red-600' } else 
        if(index === 2) { color = 'text-appleGreen-600' } else
        if(index === 3) { color = 'text-blue-600' } else
        if(index === 4) { color = 'text-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'text-LemonCurry-600' }
        return color
    }

    const onLegnedClick = (key, index) => {
        // Get the current filtered data for the index
        let filteredDonutData = { ...state.filteredJobWorstationOverview };

        setState(prevState => ({ ...prevState, filteredJobWorstationOverview: {} }));
    
        // Ensure the `items` array is a copy to avoid mutating the original state
        filteredDonutData.items = [...(filteredDonutData?.items || [])];
    
        const keyExists = filteredDonutData.items.some(e => e.item_name === key);
        if (keyExists) {
            filteredDonutData.items = filteredDonutData.items.filter(e => e.item_name !== key);
        } else {
            let spendDonutData = state.jobWorstationOverview
            const newItem = spendDonutData.items.find(e => e.item_name === key);
            const originalIndex = spendDonutData.items.findIndex(e => e.item_name === key);

            // Insert it into the filtered data at the correct position
            filteredDonutData.items = [ ...filteredDonutData.items.slice(0, originalIndex), newItem, ...filteredDonutData.items.slice(originalIndex) ]
        }
    
        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredJobWorstationOverview: filteredDonutData }));
        }, 1000)
    };

    const onLegendTrendClick = (key) => {
        // Create a deep copy of jobWorkOverData to avoid modifying the original state
        let filteredData = { ...state.filteredJobWorkstationTrend };
    
        // Check if the key exists in filteredData
        if (filteredData.hasOwnProperty(key)) {
            // If it exists, delete it to remove it from the filtered data
            delete filteredData[key];
        } else {
            // If it doesn't exist, we need to add it from the original data
            let originalData = { ...state.jobWorkstationTrend }; // Deep copy of original data
    
            // Only add the key if it exists in originalData
            if (originalData.hasOwnProperty(key)) {
                filteredData[key] = originalData[key]; // Add the corresponding value from originalData
                filteredData.labels = originalData.labels; // Preserve labels from original data
            }
        }
    
        // Update state with the new filtered data
        setState(prevState => ({ ...prevState, filteredJobWorkstationTrend: {} }));
    
        setTimeout(() => {
            setState(prevState => ({ ...prevState, filteredJobWorkstationTrend: filteredData }));
        }, 1000);
    };

    return (
        state.showLoading ?
            <div className="w-full flex justify-center self-center py-4">
                <LoadingCircle />
            </div>
        :
            <div className='p-6'>
                <div className='w-full flex flex-wrap items-stretch h-100 pb-6'>
                    <div className='md:w-1/2 w-full md:pr-2'>
                        <p className='text-lg font-medium pb-3 w-full'>Recent Jobs</p>
                        <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                            <table className="w-full text-left">
                                <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                    <tr className="flex-1 w-full">
                                        <th className='tracking-wider py-2 pl-4 text-left'>Job Name</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Job Id</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Status</th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm text-NeutralGray-900'>
                                    {state.recentJobTableData && state.recentJobTableData.length ?
                                        <React.Fragment>
                                        {state.recentJobTableData.map((row, rowIndex) => {
                                            return(
                                                <tr key={"job_"+rowIndex} className={`text-base h-[60px] ${row.job_name && rowIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full cursor-pointer`} onClick={() => {
                                                    if(row.job_name) {
                                                        navigateTo(URL_PATH.JOB_STATUS)
                                                    }
                                                }}>
                                                    <td className={`py-2.5 pl-4 text-left`}>{row.job_name ? row.job_name : ''}</td>
                                                    <td className={`py-2.5 pl-4 text-left`}>{row.job_id ? row.job_id : ''}</td>
                                                    <td className={`py-2.5 pl-4 text-left`}>
                                                        {row.status ?
                                                            <span className={`min-w-32 ${getStatusBadgeColor(row.status)} w-fit h-fit rounded-full text-sm font-medium p-2 flex justify-center items-center`}>{row.status}</span>
                                                        : null}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </React.Fragment>
                                    :
                                        <tr className={`text-base`}>
                                            <td colSpan={3} className={`py-2.5 pl-4 text-center`}>No information on recent jobs</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='md:w-1/2 w-full md:pl-2'>
                        <p className='text-lg font-medium pb-3 w-full'>Workstation Status</p>
                        <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                            <table className="w-full text-left">
                                <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                    <tr className="flex-1 w-full">
                                        <th className='tracking-wider py-2 pl-4 text-left'>Workstation</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Status</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Total Hours</th>
                                        <th className='tracking-wider py-2 pl-4 text-left'>Total Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm text-NeutralGray-900'>
                                {state.recentWorkstationTableData && state.recentWorkstationTableData.length ?
                                    state.recentWorkstationTableData.map((row, rowIndex) => {
                                        return(
                                            <tr key={"work_"+rowIndex} className={`text-base h-[60px] ${row.workstation_name && rowIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full cursor-pointer`} onClick={() => {
                                                if(row.workstation_name) {
                                                    navigateTo(URL_PATH.CATALOG_LANDINGPAGE)
                                                }
                                            }}>
                                                <td className={`py-2.5 pl-4 text-left`}>{row.workstation_name ? row.workstation_name : <span>&nbsp;</span>}</td>
                                                <td className={`py-2.5 pl-4 text-left`}>
                                                    {row.status ? 
                                                        <span className={`min-w-32 ${getStatusBadgeColor(row.status)} w-fit h-fit rounded-full text-sm font-medium p-2 flex justify-center items-center`}>{row.status}</span>
                                                    : null}
                                                </td>
                                                <td className={`py-2.5 pl-4 text-left`}>{row.total_hours}</td>
                                                <td className={`py-2.5 pl-4 text-left`}>{row.total_cost}</td>
                                            </tr>
                                        )
                                    })
                                :
                                    <tr className={`text-base`}>
                                        <td colSpan={3} className={`py-2.5 pl-4 text-center`}>No information on recent workstations</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='flex w-full justify-between'>
                    <p className='text-[22px] font-bold self-center'>Jobs & Workstations</p>
                    <div className='flex justify-end'>
                        {isUserDashboard ?
                            <p className='mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3' onClick={() => showDetailsPanel(selectedDetails) }>View Details</p>
                        : null}
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={PERIOD_OPTIONS_NEW}
                            selectedValues={state.selectedPeriod ? state.selectedPeriod : ''}
                            callbackMultiSelect={(value) => {
                                setState((prevState) => ({ ...prevState, selectedPeriod: value, showLoading: true, callSearch: true }));
                            }}
                            singleSelection={true}
                            dropdownWidth={'min-w-32'}
                            classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap items-stretch h-100 pt-6'>
                    <div className='lg:w-3/12 md:w-2/6 w-full md:pr-2 min-h-[200px]'>
                        <div className='bg-white shadow-lg rounded-2xl flex flex-wrap justify-center self-center h-full p-4 flex flex-col'>
                            <p className='text-lg font-medium pb-4 w-full'>Jobs Overview</p>
                            {state.jobOverview && Object.entries(state.jobOverview).length ?
                                <React.Fragment>
                                <div className="flex flex-wrap gap-2 w-full">
                                    <div className="flex w-full flex-row gap-2 items-center">
                                        <div className="w-4 h-4 rounded-full bg-green-600"></div>
                                        <p className="text-base text-nowrap">Succeeded : {state.totalSuccessValue ? state.totalSuccessValue : 0} ({state.totalSuccessRate && state.totalSuccessRate ? state.totalSuccessRate : 0}%)</p>
                                    </div>
                                    <div className="flex w-full mt-2 flex-row gap-2 items-center">
                                        <div className="w-4 h-4 rounded-full bg-red-600"></div>
                                        <p className="text-base text-nowrap">Failed : {state.totalFailureValue ? state.totalFailureValue : 0} ({state.totalFailureRate && state.totalFailureRate ? state.totalFailureRate : 0}%)</p>
                                    </div>
                                </div>
                                <div className='pt-4 flex justify-center'>
                                    <ApexDonutChart
                                        labelColor={"#000000"}
                                        valueColor={"#000000"}
                                        labelSize={"22px"}
                                        valueSize={"22px"}
                                        // valueType={'%'}
                                        graphData={state.jobOverview}
                                        showTotal={true}
                                        showTotalLable={true}
                                        totalLableType={'integer'}
                                        height={190}
                                        width={190}
                                        size={'70%'}                                        
                                        formatedColors={{'failed': '#AA2217', 'passed': '#357835'}}
                                        colors={['#357835', '#AA2217']}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                                </React.Fragment>
                            :
                                <div className="flex-grow flex justify-center items-center self-center">
                                    <div className="w-full text-center text-base">
                                        No information on jobs overview.
                                    </div>
                                </div>
                            }  
                                
                        </div>
                    </div>
                    <div className='md:w-4/12 w-full md:px-2 min-h-[200px]'>
                        <div className='bg-white shadow-lg rounded-2xl h-full p-4 flex flex-col'>
                            <div className='flex pb-4'>
                                <p className='text-lg font-medium'>Jobs Executed</p>
                                <p className='flex text-base text-black self-center pl-5'><span className='text-NeutralGray-600'>Total</span> ({state.jobExecutedTotal && state.jobExecutedTotal.total ? state.jobExecutedTotal.total : 0}) </p>
                                <p className='flex text-base text-black self-center pl-3'><span className='text-NeutralGray-600'>Max</span> ({state.jobExecutedTotal && state.jobExecutedTotal.max ? state.jobExecutedTotal.max : 0}) </p>
                            </div>
                            {state.jobsExecuted && Object.entries(state.jobsExecuted).length ?
                                <div className='-mb-4'>
                                    <ApexLineChartSpendBoard
                                        graphData={state.jobsExecuted}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'datetime'}
                                        // xaxisTitle={item.title && item.title[key] ? item.title[key] : key}
                                        xaxisLabel={true}
                                        axisLabelColor={'#666666'}
                                        paddingLeft={10}
                                        legend={false}
                                        stacked={false}
                                        height={250}
                                        customHpcDashboardTooltip={true}
                                        className={'transparentTooltip'}
                                        gradient={true}
                                        gradientColor={['#6C757D', '#6C757D']}
                                        colors={['#6C757D']}
                                    />
                                </div>
                            : 
                                <div className="flex-grow flex justify-center items-center self-center">
                                    <div className="w-full text-center text-base">
                                        No information on jobs executed.
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='md:w-5/12 w-full md:pl-2 min-h-[200px]'>
                        <div className='bg-white shadow-lg rounded-2xl h-full p-4 flex flex-col'>
                            <p className='text-lg font-medium'>Jobs by Status</p>
                            <ul className="w-full flex flex-wrap text-xs justify-start self-center mb-0 mt-3 text-black">
                                {state.statusData && state.statusData.data && Object.entries(state.statusData.data).map(([key, value], tIndex) => {
                                    return(
                                        <li key={'jwtrend_' + key} id={'jwtrend_legend'} className='flex mb-2 cursor-pointer' onClick={() => { 
                                            onStatusLegnedClick(key) 
                                            onStatusLegendTrendClick(key, state.colors)
                                        }}>
                                            {state.filteredJobStausTrendDonut?.items?.filter(e => e.item_name === key) && state.filteredJobStausTrendDonut?.items?.filter(e => e.item_name === key).length ?
                                                <React.Fragment>
                                                <span className={`h-3 w-3 self-center ${getStatusBgColor(key)}`}></span> 
                                                <span className={`px-2 ${getStatusTextColor(key)} d-inline-block capitalize`}>{key} <span className='text-black'>({state.totalStaus && state.totalStaus[key] ? state.totalStaus[key] : 0})</span></span>
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                <span className={`h-3 w-3 self-center bg-NeutralGray-400`}></span>
                                                <span className={`px-2 text-NeutralGray-400 d-inline-block capitalize`}>{key} <span className='text-black'>({state.totalStaus && state.totalStaus[key] ? state.totalStaus[key] : 0})</span></span>
                                                </React.Fragment>
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                            {state.filteredJobStatusTrend && Object.entries(state.filteredJobStatusTrend).length?
                                <div className='flex'>
                                    <div className='w-1/3 self-center flex justify-center'>
                                        {state.filteredJobStausTrendDonut && Object.entries(state.filteredJobStausTrendDonut).length? 
                                            <ApexDonutChart
                                                labelColor={"#000000"}
                                                valueColor={"#000000"}
                                                labelSize={"22px"}
                                                valueSize={"22px"}
                                                graphData={state.filteredJobStausTrendDonut ? state.filteredJobStausTrendDonut : {}}
                                                legend={false}
                                                showTotal={true}
                                                showTotalLable={true}
                                                showGraphHeading={false}
                                                totalLableType={'integer'}
                                                height={180}
                                                width={180}
                                                size={'70%'}
                                                gradient={false}
                                                gradientColor={['#A88CCC', '#D88ACF']}
                                                formatedColors={{'cancelled': '#4A5766', 'completed': '#7052BC', 'failed': '#AA2217', 'stopped':  '#2D60A6', 'pending': '#9747FF', 'suspended': '#FC6D22', 'timeout': '#FFB534', 'submitted:': '#1C0732', 'running': '#57AF3E'}}
                                                colors={state.jobStatusColor}
                                                className={'transparentTooltip'}
                                            />
                                        : null}
                                    </div>
                                    {state.filteredJobStatusTrend && Object.entries(state.filteredJobStatusTrend).length ? 
                                        <div className='w-2/3 -mb-4'>
                                            <ApexStackedBarChart
                                                graphData={state.filteredJobStatusTrend ? state.filteredJobStatusTrend : {}}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                hideYaxisLine={false}
                                                xaxis={true}
                                                xaxisFormat={'categoryDateString'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#495057'}
                                                paddingLeft={0}
                                                legend={false}
                                                stacked={true}
                                                height={230}
                                                horizontal={false}
                                                // offSetX={10}
                                                // offSetY={10}
                                                barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={'25%'}
                                                formatedColors={{'cancelled': '#4A5766', 'completed': '#7052BC', 'failed': '#AA2217', 'stopped':  '#2D60A6', 'pending': '#9747FF', 'suspended': '#FC6D22', 'timeout': '#FFB534', 'submitted:': '#1C0732', 'running': '#57AF3E'}}
                                                colors={['#4A5766', '#7052BC', '#AA2217', '#2D60A6', '#9747FF', '#FC6D22', '#FFB534', '#1C0732', '#57AF3E']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#E6E6E6']}
                                                className={'transparentTooltip'}
                                                showTooltipTotal={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            :
                                <div className="flex-grow flex justify-center items-center self-center">
                                    <div className="w-full text-center text-base">
                                        No information on jobs by status.
                                    </div>
                                </div>
                            }                                    
                        </div>
                    </div>
                    
                    <p className='text-[22px] font-bold pt-6 w-full'>Cost</p>
                    <div className='w-full flex flex-wrap items-stretch h-full pt-6'>
                        <div className='md:w-1/2 w-full md:pr-2 min-h-[300px]'>
                            <div className='bg-white shadow-lg rounded-2xl h-full p-4 flex flex-col'>
                                <p className='text-lg font-medium pb-4 w-full'>Budget vs Spend <span className='text-NeutralGray-600'>$</span></p>
                                {state.budgetSpendTrend && Object.entries(state.budgetSpendTrend).length ? 
                                    <div className=''>
                                        <ApexBarChart
                                            graphData={state.budgetSpendTrend}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#495057'}
                                            paddingLeft={0}
                                            height={260}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={'transparentTooltip'}
                                            dataLabels= {true}
                                            dataLabelsTextAnchor = {'end'}
                                            dataLabelPosition={'top'}
                                            colors={['#3576BE', '#57AF3E']}
                                            dataLabelsOffsetX={0}
                                            chartDistributed={true}
                                        />
                                    </div>
                                : 
                                    <div className="flex-grow flex justify-center items-center self-center">
                                        <div className="w-full text-center text-base">
                                            No information on Budget vs Spend.
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='md:w-1/2 w-full md:pl-2 min-h-[300px]'>
                            <div className='bg-white shadow-lg rounded-2xl h-full p-4 flex flex-col'>
                                <p className='text-lg font-medium pb-4 w-full'>{selectedType === 'user' ? 'Jobs vs Workstation' : 'Cost Breakup'}</p>
                                <ul className="w-full flex flex-wrap text-xs justify-start self-center mb-0 mt-3 text-black">
                                    {state.jobWorkOverData && Object.entries(state.jobWorkOverData).map(([key, value], tIndex) => {
                                        return(
                                            <li key={'jwtrend_' + key} id={'jwtrend_legend'} className='flex mb-2 cursor-pointer' onClick={() => { 
                                                onLegnedClick(key) 
                                                onLegendTrendClick(key, state.colors)
                                            }}>
                                                {state.filteredJobWorstationOverview?.items?.filter(e => e.item_name === key) && state.filteredJobWorstationOverview?.items?.filter(e => e.item_name === key).length ?
                                                    <React.Fragment>
                                                    <span className={`h-3 w-3 self-center ${getBgColor(tIndex)}`}></span> 
                                                    <span className={`px-2 ${getTextColor(tIndex)} d-inline-block capitalize`}>{key}</span>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                    <span className={`h-3 w-3 self-center bg-NeutralGray-400`}></span>
                                                    <span className={`px-2 text-NeutralGray-400 d-inline-block capitalize`}>{key}</span>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>
                                
                                {state.filteredJobWorstationOverview && Object.entries(state.filteredJobWorstationOverview).length ?
                                    <div className='flex pt-4'>
                                        <div className='w-1/3 flex justify-center self-center'>
                                            {state.filteredJobWorstationOverview && Object.entries(state.filteredJobWorstationOverview).length ? 
                                                <ApexDonutChart
                                                    labelColor={"#000000"}
                                                    valueColor={"#000000"}
                                                    labelSize={"22px"}
                                                    valueSize={"22px"}
                                                    unit={userMenu.currency_symbol ? userMenu.currency_symbol : '$'}
                                                    graphData={state.filteredJobWorstationOverview}
                                                    legend={false}
                                                    showTotal={true}
                                                    showTotalLable={true}
                                                    showGraphHeading={false}
                                                    height={200}
                                                    width={200}
                                                    size={'70%'}
                                                    gradient={false}
                                                    formatedColors={state.formattedColors}
                                                    colors={state.colors}
                                                    className={'transparentTooltip'}
                                                />
                                            : null}
                                        </div>
                                        {state.filteredJobWorkstationTrend && Object.entries(state.filteredJobWorkstationTrend).length ? 
                                            <div className='w-2/3 -mb-4'>
                                                <ApexStackedBarChart
                                                    graphData={state.filteredJobWorkstationTrend ? state.filteredJobWorkstationTrend : {}}
                                                    sparkline={false}
                                                    unit={userMenu.currency_symbol ? userMenu.currency_symbol : '$'}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    hideYaxisLine={false}
                                                    xaxis={true}
                                                    xaxisFormat={'categoryDateString'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#495057'}
                                                    // offSetX={20}
                                                    // offSetY={10}
                                                    paddingLeft={-15}
                                                    legend={false}
                                                    stacked={true}
                                                    height={230}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'25%'}
                                                    formatedColors={state.formattedColors}
                                                    // formatedColors={{'jobs': '#3576BE', 'workstation': '#ffb534'}}
                                                    colors={state.colors}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#E6E6E6']}
                                                    className={'transparentTooltip'}
                                                    showTooltipTotal={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                :
                                    <div className="flex-grow flex justify-center items-center self-center">
                                        <div>
                                        {selectedType === 'user' ?
                                            <p className="w-full text-center text-base">No information on job vs worksation details.</p>
                                        :
                                            <p className="w-full text-center text-base">No information on cost breakup details.</p>
                                        }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <p className='text-[22px] font-bold pt-6 w-full'>Resources</p>

                    <div className='w-full flex flex-wrap items-stretch h-100 pt-6'>
                        {state.resourceGraph.map((row, rowIndex) => {
                            return(
                                <div key={'rg_'+rowIndex} className={`md:w-1/2 w-full ${rowIndex % 2 === 0 ? "md:pr-2 md:mb-4" : "md:pl-2 md:mb-4"} min-h-[400px]`}>
                                    <div className='bg-white shadow-lg rounded-2xl self-center h-full p-4 flex flex-col'>
                                        <p className='mb-2 text-black'>
                                            <span className='text-base pr-5 capitalize'>{state.resourceGraphTitle && state.resourceGraphTitle[row] ? state.resourceGraphTitle[row] : removeUnderScore(row)}</span>
                                            <span className='text-base text-NeutralGray-600 pr-5'><span className=''>Total</span> <span className='text-black'>({state.resourceGraphTotal && state.resourceGraphTotal[row] && state.resourceGraphTotal[row].total})</span> </span>
                                            <span className='text-base text-NeutralGray-600 pr-5'><span className=''>Max</span> <span className='text-black'>({state.resourceGraphTotal && state.resourceGraphTotal[row] && state.resourceGraphTotal[row].max})</span></span>
                                        </p>
                                        {state[row+'_graphData'] ?
                                            <div className='w-full mt-n2 mr-4 self-center'> 
                                                <ApexLineChartSpendBoard
                                                    graphData={state[row+'_graphData']}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    yaxisBorder={true}
                                                    // yaxisTitle={selectedItem.labels && selectedItem.labels[key] ? selectedItem.labels[key] : ''}
                                                    gridXaxisBorder={false}
                                                    gridYaxisBorder={true}
                                                    gridColor={'#434B5E'}
                                                    axisLabelColor={'#495057'}
                                                    xaxis={true}
                                                    xaxisFormat={'datetime'}
                                                    xaxisLabel={true}
                                                    paddingLeft={10}
                                                    legend={false}
                                                    stacked={false}
                                                    height={300}
                                                    offSetX={15}
                                                    customHpcDashboardTooltip={true}
                                                    className={'transparentTooltip'}
                                                    // colors={['#43b02a', '#009FDF']}
                                                    gradient={true}
                                                    gradientColor={['#F4F1FA', '#A790D8']}
                                                    colors={['#7551C2']}
                                                />
                                            </div>
                                        :
                                            <div className="flex-grow flex justify-center items-center self-center">
                                                <div className="w-full text-center text-base">
                                                    No information on <span className="lowercase">{state.resourceGraphTitle && state.resourceGraphTitle[row] ? state.resourceGraphTitle[row] : removeUnderScore(row)}</span>.
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    
                    {state.reportTableData ?
                        <div className='w-full md:pr-2'>
                            {/* <p className='text-lg font-medium pb-3 w-full'>Recent Jobs</p> */}
                            <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                                <table className="w-full text-left">
                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                        <tr className="flex-1 w-full">
                                            {state.reportTableData.length ?
                                                Object.keys(state.reportTableData[0]).map((col, colIndex) => {
                                                    return(
                                                        <th key={'col_'+colIndex} className={`tracking-wider py-2 pl-4 ${!colIndex ? 'text-left': 'text-right'} ${colIndex+1 === Object.entries(state.reportTableData[0]).length ? 'pr-4' : ''}`}>{state.resourceGraphTitle && state.resourceGraphTitle[col] ? state.resourceGraphTitle[col] : removeUnderScore(col)}</th>
                                                    )
                                                })
                                            : null}
                                        </tr>
                                    </thead>
                                    <tbody className='text-sm text-NeutralGray-900'>
                                        {state.reportTableData.length ?
                                            state.reportTableData.map((row, rowIndex) => {
                                                return(
                                                    <tr key={"tt_"+rowIndex} className={`text-base ${rowIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                        {Object.entries(row).map(([key, value], kIndex) => {
                                                            return(
                                                                <td key={'tk_'+rowIndex+'_'+kIndex} className={`py-2.5 pl-4  ${!kIndex ? 'text-left': 'text-right'} ${kIndex+1 === Object.entries(row).length ? 'pr-4' : ''}`}>
                                                                    {key === 'date' ? momentConvertionLocalToUtcTime(value, 'DD MMM YYYY') : value}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })
                                        : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    : null}

                    {state['reports'] ?
                        <div className="relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600 w-full" id={'table'}>
                            <ResizeableDarkThemeTable
                                id={'table'}
                                data={state['filteredReports'].length ? state['filteredReports'] : state['filteredReports']}
                                columns = {state['report_columns']}
                                onClickRow={tableRow => {}}
                                dashboard = {state['filteredReports'].length ? true : false}
                                selectedColor={'bg-transparent'}
                                sortByColumn={"date"}
                            />
                        </div>
                    : null}
                </div>
            </div>
    )
}
export default ExpandedDetails