/*************************************************
 * Collider
 * @exports
 * @file ApexMixedBarLineChart.js
 * @author Prakash // on 26/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexMixedBarLineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tooltipType: this.props.tooltipType
        }
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        let unit = this.props.unit
        if(graphData){

            
            let colors = this.props.colors ? this.props.colors : ['#ffadad', '#ffd6a5', '#3F73AD', '#24A597']
            let series = []
            let graphLables = graphData.labels
            
            let highestValue = 0
            let categories = []
            
            Object.entries(graphData).forEach(([key, value]) => {
                let dataArray =[]
                if(key !== 'labels') {
                    if(this.props.xaxisFormat === "categoryString") {
                        dataArray = value
                        categories = graphLables
                    } else {
                        graphLables && graphLables.forEach((item, i) => {
                            categories.push(momentDateGivenFormat(graphLables[i], 'MMM Y'))
                            let date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                            if(this.props.tooltipType && this.props.tooltipType === "dontConvertDate") {
                                if(this.props.xaxisFormat === "category") {
                                    date = momentDateGivenFormat(graphLables[i], 'MMM Y')
                                } else if(this.props.xaxisFormat ==="date") {
                                    date = momentDateGivenFormat(graphLables[i], 'DD MMM Y')
                                } else if(this.props.xaxisFormat ==="datetime") {
                                    date = momentDateGivenFormat(graphLables[i], 'DD MMM YYYY HH:mm')
                                }
                            } else {
                                if(this.props.xaxisFormat ==="date") {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM Y')
                                } else if(this.props.xaxisFormat ==="datetime") {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                } else {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                }
                            }
                            
                            let dataItems = {}
                            dataItems.x = date
                            dataItems.y = value[i]
                            dataArray.push(dataItems)
                            
                            if(highestValue < value[i]) {
                                highestValue = value[i]
                            }
                        })                        
                    }
                    let type = "line"
                    if(key === "Budget") {
                        type = "column"
                    }
                    let seriesRow = {}
                    seriesRow.name = capitalizeFirstLetter(key)
                    seriesRow.type = type
                    seriesRow.data = dataArray
                    series.push(seriesRow)
                }
            })
        
            // let backgroundColors = {}
            // if(this.props.showBackgroundBarColors) {
            //     backgroundColors = {
            //         ranges: [{
            //             from: 1,
            //             to: 0.9,
            //             color: 'red'
            //         }],
            //         backgroundBarColors: this.props.backgroundBarColors ? this.props.backgroundBarColors : ['#EDEDED'],
            //         backgroundBarOpacity: 1,
            //         backgroundBarRadius: this.props.backgroundBarShape && this.props.backgroundBarShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
            //     }
            // }

            // let tooltip = {}

            // if(this.props.xaxisFormat === "categoryString") {
            //     tooltip = {
            //         enabled: true,
            //         custom: function({series, seriesIndex, dataPointIndex, w}) {
            //             let returnData = ''
            //             if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
            //                 Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
            //                     if(!index) {
            //                         returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
            //                         returnData += w.globals.labels[index]
            //                         returnData += '</div>'
            //                     }
            //                     returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex]+'</span></div></div></div>'
            //                 })
            //             }

            //             returnData += '</div>'
            //             return returnData
            //         },
            //         style: {
            //             fontSize: '9px',
            //         },
            //     }
            // } else {
                let tooltip = {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                                    returnData += w.globals.initialSeries[index].data[dataPointIndex].x
                                    returnData += '</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span>'
                                if(unit) {
                                    returnData += '<span class="ml-1">'+unit+'</span>'
                                }
                                returnData += '<span class="apexcharts-tooltip-text-value">'+w.globals.initialSeries[index].data[dataPointIndex].y+'</span></div></div></div>'
                            })
                        }

                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                }   
            // }   

            let options = {
                tooltip: tooltip,
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: this.props.zoom,
                    },
                    sparkline: {
                        enabled: this.props.sparkline
                    },
                    animations: {
                        enabled: this.props.animation
                    },
                    type: 'line',
                    height: 150,
                    stacked: this.props.stacked,
                },
                plotOptions: {
                    bar: {
                      columnWidth: '20%'
                    }
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: colors,
                    width: [0, 4],
                    dashArray: 0,      
                },
                dataLabels:{
                    enabled: false,
                },
                colors: colors,
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: this.props.paddingLeft ? this.props.paddingLeft : 10,
                        bottom: 0,
                        left: this.props.paddingLeft ? this.props.paddingLeft : 10
                    },
                },
                xaxis: {
                    show: this.props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: this.props.xaxisFormat && (this.props.xaxisFormat === "date" || this.props.xaxisFormat === "datetime") ? "datetime" : (this.props.xaxisFormat ? this.props.xaxisFormat : "datetime"),
                    categories: (this.props.xaxisFormat === "category" || this.props.xaxisFormat === "categoryString") ? categories : [],
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        show: this.props.xaxisLabel,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        },
                    },
                    crosshairs: {
                        show: false,
                        width: 0,
                        position: 'back',
                        opacity: 0,
                        stroke: {
                            color: '#b6b6b6',
                            width: 1,
                            dashArray: 0,
                        },
                        // fill: {
                        //     type: 'solid',
                        //     color: '#B1B9C4',
                        //     // gradient: {
                        //     //     colorFrom: '#D8E3F0',
                        //     //     colorTo: '#BED1E6',
                        //     //     stops: [0, 100],
                        //     //     opacityFrom: 0.4,
                        //     //     opacityTo: 0.5,
                        //     // },
                        // },
                        // dropShadow: {
                        //     enabled: false,
                        //     top: 0,
                        //     left: 0,
                        //     blur: 1,
                        //     opacity: 0.4,
                        // },
                    },
                },
                yaxis: [{
                    // show: this.props.hideYaxisLabel,
                    title: {
                        text: 'Budget',
                        style: {
                            color: this.props.axisLabelColor
                        }
                    },
                    labels: {
                        formatter: (value) => { return value },
                        // show: this.props.yaxis,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        }
                    }                
                }, {
                    // show: this.props.hideYaxisLabel,
                    opposite: true,
                    title: {
                        text: 'Spend',
                        style: {
                            color: this.props.axisLabelColor
                        }
                    },
                    labels: {
                        // show: this.props.yaxis,                        
                        formatter: (value) => { return value },
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        }
                    }
                }],
                // yaxis: {
                //     show: this.props.yaxis,
                //     axisBorder: {
                //         show: true,
                //         color: '#434B5E',
                //     },
                //     tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                //     labels: {
                //         style: {
                //             colors: this.props.axisLabelColor,
                //             fontSize: '11px',
                //             fontWeight: 'bolder',
                //         },
                //         formatter: function(val, index) {
                //             if (val > 999) {
                //                 val = val / 1000

                //                 if(val % 1 !== 0) {
                //                     val = val.toFixed(1);
                //                     return val + "K";
                //                 }
                //             } else {
                //                 if(val > 0 && val < 1) {
                //                     return val.toFixed(1);
                //                 } else {
                //                     return parseInt(val)
                //                 }
                //             }
                //         },
                //         offsetX: -15
                //     },
                // },
                legend: {
                    show: series.length < 30 && this.props.legend ? true : false,
                    position: "bottom",
                    formatter: function (seriesName, opts) {
                        return `<table>
                        <tr>
                            <th>${seriesName}</th>
                        </tr>
                        </table>`;
                    },
                    offsetY: 7,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: this.props.colors,
                        useSeriesColors: true
                    },
                },
            }
            
            this.setState({ series, options });
        }
    }

    render() {
        return(
            <div className={`${this.props.className}`}>
                {this.state.series ?
                    <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height} />
                : null}
            </div>
        )
    }
}
export default ApexMixedBarLineChart