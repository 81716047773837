/*
 * @exports
 * @file RuleTemplate.js
 * @author Prakash
*/
import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders } from '../../../actions/commonActionNew';
import { listAlertTemplates, createAlertRule } from '../../../actions/Collider/AlertAction';
import { Store as CommonNotification } from 'react-notifications-component';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';
import Button from '../../common/Form/Button';
import { dynamicCallApi } from '../../../actions/DynamicLoadAction';

const RuleTemplate = (props) => {
	const[state, setState] = useState({
		selectedCategory: "All",
		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		perPage: 10,
		showLoading: true,
		callSearch: true
	})
	
	const providers = useSelector(state => state.filters.providers);
	// const hpcAlertPageFilters = useSelector(state => state?.hpc?.hpcPropsDetails?.hpcAlertPageFilters || false);
	// const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);
	const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
            	})
        }
    }, [dispatch, providers]);

	useEffect(() => {
		if(state.showLoading){
			setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
		}
	}, [state.showLoading])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listAlertTemplates(params))
				.then((response) => {
					let results =  response && response.results ? response.results : []
					setState(prevState => ({ ...prevState, listResponseArray: results, filteredListArray: results, structureResponse: true, categories: [], showLoading: false }))
				})
		}
    }, [state.callSearch, dispatch])

    const dynamicCallApiFunction = useCallback((params, item, apiDetails, outputField, outputType) => {
        dispatch(dynamicCallApi('', apiDetails.api_resource, params))
            .then((response) => {
                if(response) {
                    let options = []
                    // if(outputType === "list") {
                    //     let dataRow = {}
                    //     dataRow.label = "All"
                    //     dataRow.value = "All"
                    //     options.push(dataRow)
                    // }
                    response.forEach(res => {
                        if(apiDetails.field_name === "id_name_map") {
                            let dataRow = {}
                            dataRow.label = res[apiDetails.display_name]
                            dataRow.value = res[apiDetails.field_name]
                            // options.push(res[apiDetails.field_name])
                            options.push(dataRow)
                        } else if(apiDetails.field_name){
                            let dataRow = {}
                            dataRow.label = res[apiDetails.display_name]
                            dataRow.value = res[apiDetails.field_name]
                            options.push(dataRow)
                        } else {
                            let dataRow = {}
                            dataRow.label = res
                            dataRow.value = res
                            options.push(dataRow)
                        }
                    })
                    setState(prevState => ({ ...prevState, [outputField+'_options_'+item.template_id]: options }))
                }
            })
    }, [dispatch])

	useEffect(() => {
		if(state.structureResponse) {
			setState(prevState => ({ ...prevState, structureResponse: false }))
			let categories = []
			let totalCatCount = 0
			state.listResponseArray.forEach(item => {
				if(!categories.filter(e => e.category === item.category).length) {
					let count = state.listResponseArray.filter(e => e.category === item.category).length
					totalCatCount += count 
					categories.push({
						category: item.category,
						count: count
					})
				}

				if(item.display_output && item.display_output.length) {
					item.display_output.forEach(dis => {
						// setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.template_id]: dis.value ? dis.value : 0 }))
                        let params = {}
                        let apiDetails = dis?.api_details || false
                        if(apiDetails?.api_request) {
                            params = apiDetails.api_request
                        }
                        if(apiDetails?.additional_fields) {
                            apiDetails?.additional_fields.forEach(field => {
                                if(state[field+'_'+item.template_id]) {
                                    params[field] = state[field+'_'+item.template_id]
                                }
                            })
                        }
                        if(apiDetails){
                            dynamicCallApiFunction(params, item, apiDetails, dis.output_field, dis.output_type)
                        }
					})
				}
			})
			
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			categories.unshift(dataRow)

			setState(prevState => ({ ...prevState, categories }))
		}
	}, [state.listResponseArray, state.structureResponse, dynamicCallApiFunction, state])
    
    const checkForDependentApiCall = (field, item, value) => {
        if(item.display_output && item.display_output.length) {
            item.display_output.forEach(dis => {
                let aditionalFields = dis?.api_details?.additional_fields || false
                if(aditionalFields && aditionalFields.includes(field)) {
                    let params = {}
                    let apiDetails = dis?.api_details || false
                    if(apiDetails?.api_request) {
                        params = apiDetails.api_request
                    }
                    if(apiDetails?.additional_fields) {
                        apiDetails?.additional_fields.forEach(fld => {
                            if(fld === field) {
                                params[fld] = value
                            } else if(state[fld+'_'+item.template_id]) {
                                params[fld] = state[fld+'_'+item.template_id]
                            }
                        })
                    }
                    if(apiDetails){
                        dynamicCallApiFunction(params, item, apiDetails, dis.output_field, dis.output_type)
                    }
                }
            })
        }
    }

	useEffect(() => {
		if(state.onSaveFunction) {
			setState(prevState => ({ ...prevState, onSaveFunction: false }))
            let params = {}
            let rules = []

			let hasError = false
            state.filteredListArray.forEach((item, i) => {
                if(state.selectedTemplates.includes(item.template_id)) {
                    if(item.display_output && item.display_output.length) {
                        item.display_output.forEach(dis => {
                            if(dis?.validation?.mandatory && !state[dis.output_field+'_'+item.template_id]) {
                                hasError = true
                            } else {
                                let value = state[dis.output_field+'_'+item.template_id];
                                if(dis?.validation?.data_type === 'number') {
                                    value = parseInt(value)
                                }
        
                                dis.value = value
                                if(value) {
                                    if((Array.isArray(value) && value.length) || typeof value === 'string' || typeof value === 'number') {
                                        item[dis.output_field] = value
                                    }
                                }
                            }
                        })
                    }
                    
                    rules.push(item)
                }
            })

            params.rules = rules

			setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true  }))

			if(!hasError) {
				dispatch((createAlertRule(params)))
					.then((response) => {
						if(response) {
							let messageType = 'danger'
							let message = response && response.message ? response.message : 'Error in alert rule'
							if(response && !response.error) {
								if(response.status) {
									messageType = 'success'
									message = response.message ? response.message : 'alert rule submitted successfully'
								}
							}
			
							CommonNotification.addNotification({
								//title: 'Wonderful!',
								message: message,
								type: messageType,
								insert: 'top',
								container: 'top-center',
								// animationIn: ['animate__animated', 'animate__fadeIn'],
								// animationOut: ['animate__animated', 'animate__fadeOut'],
								dismiss: {
									duration: 5000,
									onScreen: false,
									pauseOnHover: true,
									showIcon: true,
								}
							});
			
							if(response && !response.error) {
								if(response.status) {
									setTimeout(() => { props.closeForm() }, 1000)
									setState(prevState => ({ ...prevState, saveLoading: false, callSearch: true }))
								} else {
									setState(prevState => ({ ...prevState, saveLoading: false }))
								}
							} else {
								setState(prevState => ({ ...prevState, saveLoading: false }))
							}
						}
					})
			}

		}

	}, [dispatch, state, props])


	return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <LoadingCircle />
            </div>
        :   
            <React.Fragment>
            <div className='pl-6 pr-3 pt-8'>
                <div className='flex flex-wrap pt-8'>
                    <div className={`w-full lg:mt-0 md:mt-0 mt-2 pr-3 overflow-y-auto h-[calc(100vh-280px)]`}>
                        <div className={`self-center pb-8 w-1/3`}>
                            <SelectOption
                                label={"Select Templates"}
                                fields={['template_id', 'title']}
                                options={state.listResponseArray}
                                selectedValues={state.selectedTemplates ? state.selectedTemplates : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, selectedTemplates: value }))
                                }}
                            />
                        </div>
                        {state.selectedTemplates && state.selectedTemplates.length && state.filteredListArray && state.filteredListArray.length ?
                            state.filteredListArray.map((item, i) => {
                                return(
                                    <div key={'filArr_'+i}>
                                    {state.selectedTemplates.includes(item.template_id) ? 
                                        <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6 mr-1'>
                                            <div id={'heading_'+i} className="cursor-pointer w-full"
                                                onClick={() => setState(prevState => ({ ...prevState, hasError: false, selectedAlert: item }))}
                                            >
                                                <div className="font-semibold text-base flex">
                                                    {/* {state.selectedAlert && state.selectedAlert.template_id === item.template_id ?
                                                        <Icon icon={`iconoir:minus`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-start cursor-pointer mr-3' width={20} height={20} />
                                                    :
                                                        <Icon icon={`stash:plus-solid`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-start cursor-pointer mr-3' width={20} height={20} />
                                                    } */}
                                                    <div className='flex flex-wrap text-lg font-bold'>
                                                        {item.title ? item.title : ""}
                                                        {item.description ?
                                                            <p className='text-sm py-2 w-full'>{item.description}</p>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>                                            
                                            {item.display_output && item.display_output.length ?
                                                <React.Fragment>
                                                <div className='flex flex-wrap w-full'>
                                                    {item.display_output.map((dis, disIndex) => {
                                                        return(
                                                            dis.type === 'text' ?
                                                                <div className='w-1/3 pr-8 mt-4' key={"text_"+i+'_'+disIndex}>
                                                                {/* <p className={`flex text-NeutralGray-800 text-base text-nowrap text-NeutralGray-800 self-end mr-2`}>
                                                                    {dis.label}
                                                                    
                                                                    {dis?.validation?.mandatory?
                                                                        <Icon icon="fe:star" className='text-red-600 ml-1 mb-3 self-center' width={12} height={12} />
                                                                    : null}
                                                                </p> */}
                                                                <Textbox
                                                                    label={dis.label}
                                                                    type={dis.output_type ? dis.output_type : 'text'}
                                                                    selectedValue={state[dis.output_field+'_'+item.template_id] ? state[dis.output_field+'_'+item.template_id] : ''}
                                                                    callback={(value) => {
                                                                        setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.template_id]: value }))
                                                                    }}
                                                                    placeholder={'Enter '+dis.label}
                                                                    manditory={dis?.validation?.mandatory}
                                                                    hasError={state.hasError}
                                                                    // classDetails={{width: 'min-w-32', border: 'border border-NeutralGray-400'}}
                                                                />
                                                                </div>
                                                            : dis.type === 'dropdown' ?
                                                                <div className={`relative self-center w-1/3 pr-8 mt-4`}>
                                                                    <SelectOption
                                                                        label={dis.label}
                                                                        fields={['value', 'label']}
                                                                        options={state[dis.output_field+'_options_'+item.template_id]}
                                                                        selectedValues={state[dis.output_field+'_'+item.template_id] ? state[dis.output_field+'_'+item.template_id] : []}
                                                                        callbackMultiSelect={(value) => {
                                                                            setState(prevState => ({ ...prevState, [dis.output_field+'_'+item.template_id]: value }))
                                                                            setTimeout(() => {
                                                                                checkForDependentApiCall(dis.output_field, item, value)
                                                                            }, 1000);
                                                                        }}
                                                                        singleSelection={dis.output_type === 'list' ? false : true}
                                                                        optionOverflow={'overflow-hidden'}
                                                                    />
                                                                </div>
                                                            : null
                                                        )
                                                    })}
                                                </div>
                                                </React.Fragment>
                                            : null}
                                        </div>
                                    : null}
                                    </div>
                                )
                            })
                        : null}
                    </div>
                </div>
            </div>
            <div className="w-full p-8 shadow-lg bg-NeutralGray-700 self-start flex justify-center">
                <Button
                    classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                    label={'Cancel'}
                    callback={() => props.closeForm()}
                />
                {state.saveLoading ?
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                        label={state.pageType !== "edit" ?  "Saving"  : "Updating"}
                        loading={true}
                    />
                :
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                        label={state.pageType !== "edit" ?  "Save"  : "Update"}
                        callback={() => {
                            setState(prevState => ({ ...prevState, saveLoading: true, onSaveFunction: true }))
                        }}
                    />
                }
            </div>
            </React.Fragment>
	)
}

export default RuleTemplate