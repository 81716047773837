import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getHpcReportQueries } from '../../../actions/Collider/HpcReportAction'
import _ from 'lodash'

import Search from '../../common/SearchWithHiddenInput';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import ScheduleReport from './ScheduleReport';
import ViewReport from './ViewReport';


const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',

        selectedCategory: "All",
		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration']
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // useEffect(() => {
    //     let datePickerStartDate = ''
    //     let datePickerEndDate = ''
    //     if(state.selectedDuration === "currentMonth") {
    //         datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
    //         datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
    //     }
    //     setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
     
    // }, [state.selectedDuration])

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject ? filter.selectedProject : '', 
            selectedProjectTags: filter.selectedProjectTags ? filter.selectedProjectTags : [],
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],            
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        // setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedDuration: 'currentMonth', callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let params = {}
			params.provider = state.selectedProvider && state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion
			}
			
			if(state.selectedClusters && state.selectedClusters.length) {
				params.cluster_name = state.selectedClusters
			}
            
			dispatch(getHpcReportQueries(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, totalQueryList: response, formQueryList: true, showLoading: false }))
					}
				})
        }
    }, [state.callSearch, dispatch, state.selectedProvider, state.selectedProject, state.selectedAccount, state.selectedRegion, state.selectedClusters])
    
    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    useEffect(() => {
		if(state.formQueryList) {
            setState(prevState => ({ ...prevState, formQueryList: false }))
			let catArray = []
			let category = []
			state.totalQueryList.forEach(item => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
                    categories.push(dataRow)
				})
	
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""	
			categories.push(dataRow)
	
			setState(prevState => ({ ...prevState, categories: _.orderBy(categories, ['count'], ['desc']), filterQueryList: true }))
		}
	}, [state.formQueryList, state.totalQueryList])

    useEffect(() => {
		if(state.filterQueryList) {
            setState(prevState => ({ ...prevState, filterQueryList: false }))
			let data = state.totalQueryList
			if(state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1 }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.totalQueryList, state.selectedProject, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    const querySelection = (item) => {
        let selectedReport = {}
        if(item !== state.selectedReport) {
            selectedReport = item
        }
        if(selectedReport && selectedReport.report_id) {
            setState(prevState => ({ ...prevState, selectedReport }))
        } else {
            setState(prevState => ({  ...prevState, selectedReport }))
        }
    }

    const handleChildClick = (event) => {
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            {state.showSchedule ?
                <ScheduleReport  
                    selectedDetails={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({...prevState, showSchedule: false }))}
                />
            : null}
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/5 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Reports</p>
                        <p className="text-white text-base">Consolidated view of reports</p>
                    </div>
                    <div className="lg:w-4/5 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='bg-white rounded-2xl border border-DeepPurpleTints-600 mr-1'>
                    <div className={`flex flex-wrap justify-between px-6 py-8 border-b border-DeepPurpleTints-600`}>
                        <div className='lg:w-1/2 w-full'>
                            <p className='text-2xl font-semibold'>All Reports</p>
                            <div className="flex text-sm lg:w-3/5 w-full">
                                <p className="self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalQueryList && state.totalQueryList.length}{state.totalQueryList && state.totalQueryList.length > 1 ? ' queries' : ' query'}</p>
                            </div>
                        </div>
                        <div className='lg:w-1/2 w-full flex justify-end'>
                            {state.totalQueryList && state.totalQueryList.length ?
                                <div className='w-1/2'>
                                    <Search
                                        data={state.totalQueryList ? state.totalQueryList : []}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredArray) => {
                                            setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
                                        }}
                                    />
                                </div>
                            : null}
                        </div>
                        <div className='flex justify-end w-full'>
                            {state.filteredArray && state.filteredArray.length > state.perPage ?
                                <div className='text-sm flex justify-end text-black lg:w-1/2 w-full text-sm'>
                                    <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                    <div className="flex text-sm">
                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className='flex flex-wrap pl-6 pr-3 py-8'>
                        {state.categories ? 
                            <div className="lg:w-1/5 md:w-1/5 w-full lg:pr-3 md:pr-3 bg-NeutralGray-400 border border-NeutralGray-600 rounded-2xl pt-[22px] px-3 overflow-y-auto h-[calc(100vh-360px)]">
                                {state.categories.map((item, index) => {
                                    return(
                                        <div className='pb-4' key={'cat_'+index}>
                                            {item.category === "All" ?
                                                <div className={`flex cursor-pointer text-base font-bold text-lg ${state.selectedCategory === item.category ? 'text-byzantineBlue pointer-events-none' : 'text-black'}`} onClick={() => {
                                                    setState(prevState => ({ ...prevState, selectedCategory: "All", filterQueryList: true }))
                                                }}>All Reports</div>
                                            :
                                                <div className={`bg-white rounded-full py-3 px-5 text-base font-medium flex flex-wrap justify-between text-black text-nowrap truncate ${item.category.includes(state.selectedCategory) ? 'pointer-events-none border-2 border-byzantineBlue' : 'cursor-pointer'}`} onClick={() => {
                                                    if(item.count && !item.category.includes(state.selectedCategory)) {
                                                        setState(prevState => ({ ...prevState, selectedCategory: item.category, filterQueryList: true }))
                                                    }
                                                }}>
                                                    <p className={`w-3/5`}>{item.category}</p>
                                                    <span className='font-bold'>{item.count !== '' ? item.count : ''}</span>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        : null}
                        <div className={`pl-5 ${state.categories ? 'lg:w-4/5 md:w-4/5' : ''} w-full lg:mt-0 md:mt-0 mt-2 pr-3 overflow-y-auto h-[calc(100vh-360px)]`}>
                            {state.filteredArray && state.filteredArray.length ?
                                state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                    return(
                                        <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6 mr-1' key={'filArr_'+i}>
                                            <div id={'heading_'+i} className="cursor-pointer w-full" onClick={() => querySelection(item)}>
                                                <div className="font-semibold text-base flex">
                                                    {state.selectedReport && state.selectedReport.report_id === item.report_id ?
                                                        <Icon icon={`iconoir:minus`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                    :
                                                        <Icon icon={`stash:plus-solid`} className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                    }
                                                    
                                                    {item.report_id +" - "+ (item.title ? item.title : "")}
                                                </div>
                                            </div>
                                            
                                            {state.selectedReport && state.selectedReport.report_id === item.report_id ?
                                                <ViewReport 
                                                    selectedReport={item}
                                                    selectedProvider={state.selectedProvider}
                                                    selectedProjectView={state.selectedProject}
                                                    selectedProjectTags={state.selectedProjectTags}
                                                    selectedAccountView={state.selectedAccount}
                                                    selectedRegionView={state.selectedRegion}
                                                    selectedClustersView={state.selectedClusters}
                                                    datePickerStartDate={state.datePickerStartDate}
                                                    datePickerEndDate={state.datePickerEndDate}
                                                    closeReport={() => setState(prevState => ({ ...prevState, selectedReport: {} }))}
                                                />
                                            : null}
                                        </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage